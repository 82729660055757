<template>
  <FullPageLayout title="Pallet Labels">
    <template #default>
      <v-layout wrap>
        <v-flex xs6>
          <v-card outlined>
            <v-card-text>
              <v-text-field
                v-model="carrier_line1"
                v-validate="'required|min:3'"
                :error-messages="errs.collect('carrier_line1')"
                data-vv-name="carrier_line1"
                label="Carrier Line 1"
                hint="USPS, UPS, Canada Post, etc"
              />

              <v-text-field
                v-model="carrier_line2"
                v-validate="''"
                :error-messages="errs.collect('carrier_line2')"
                data-vv-name="carrier_line2"
                label="Carrier Line 2"
                hint="General, Mix 1, etc"
              />

              <v-text-field
                v-model="location_code"
                v-validate="'min:3|max:3'"
                :error-messages="errs.collect('location_code')"
                data-vv-name="location_code"
                label="Location Code"
                hint="SGA, MRK, LDN"
              />

              <v-text-field
                v-model="labelCount"
                v-validate="'required|min_value:1|max_value:100'"
                type="number"
                :error-messages="errs.collect('labelCount')"
                data-vv-name="labelCount"
                step="1"
                label="Number of Labels"
                :integer-only="true"
              />
              <v-btn
                color="primary"
                :disabled="generateLoading"
                :loading="generateLoading"
                @click="generateLabels()"
              >
                Generate
              </v-btn>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </template>
  </FullPageLayout>
</template>
  
<script>
export default {
  data() {
    return {
      labelCount: 1,
      generateLoading: false,
      menu1: false,
      carrier_line1: null,
      carrier_line2: null,
      location_code: null
    };
  },

  methods: {
    generateLabels() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          this.generateLoading = true;

          this.$http({
            method: 'post',
            url: '/admin/pallet-labels',
            data: { carrier_line1: this.carrier_line1, carrier_line2: this.carrier_line2, location_code: this.location_code, count: this.labelCount }
          })
          .then(response => {
            this.downloadFileUrl(response.data,'Pallet Labels.pdf');
          })
          .finally(() => {
            this.generateLoading = false;
          });
        } else {
          swal({
            title: 'Error',
            text: 'Some of the form fields are invalid.',
            icon: 'error'
          });
        }
      });
    }
  }
};
</script>
  