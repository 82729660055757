<template>
  <StallionDialog
    v-model="dialog"
    persistent
    :width="800"
    :title="edit ? 'Edit Rule' : 'Create Rule'"
  >
    <template #content>
      <v-container>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="rule.name"
              label="Rule Name"
              outlined
              dense
            />
          </v-col>
          <v-col cols="12">
            <v-switch v-model="rule.active">
              <template #label>
                <span v-if="rule.active">Active</span>
                <span v-else>Not Active</span>
              </template>
            </v-switch>
          </v-col>
          <v-col cols="12">
            <v-radio-group
              v-model="rule.selection"
              dense
            >
              <v-radio
                :value="'all'"
                label="Apply these actions to EVERY order that is imported"
              />
              <v-radio
                :value="'custom'"
                label="Only apply these actions to orders that match specific criteria"
              />
              <v-radio
                :value="'filter'"
                label="Only apply these actions to orders that match an existing filter"
              />
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row
          v-for="(criterion, index) in rule.filter?.filter_criteria"
          v-show="rule.selection == 'custom'"
          :key="index + 'c'"
          dense
        >
          <v-col v-if="rule.filter.filter_criteria[index].delete !== true">
            <criteria v-bind.sync="rule.filter.filter_criteria[index]" />
          </v-col>
          <v-col
            v-if="rule.filter.filter_criteria[index].delete !== true"
            cols="auto"
          >
            <v-btn
              color="primary"
              icon
              @click="removeFilter(index, criterion)"
            >
              <v-icon dark>
                mdi-minus
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          v-if="rule.selection == 'custom'"
          dense
        >
          <v-col cols="12">
            <v-btn
              color="primary"
              outlined
              @click="addCriteria()"
            >
              Add Criteria
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          v-if="rule.selection == 'filter'"
          dense
        >
          <v-col cols="12">
            <v-select
              v-model="rule.filter_id"
              outlined
              dense
              :items="filters.filters"
              item-text="name"
              item-value="id"
              label="Filter"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-divider />
          </v-col>
        </v-row>

        <v-row
          v-for="(action, index) in rule.rule_actions"
          :key="index"
          dense
        >
          <v-col v-if="rule.rule_actions[index].delete !== true">
            <action v-bind.sync="rule.rule_actions[index]" />
          </v-col>
          <v-col
            v-if="rule.rule_actions[index].delete !== true"
            cols="auto"
          >
            <v-btn
              color="primary"
              icon
              @click="removeAction(index, action)"
            >
              <v-icon dark>
                mdi-minus
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn
              color="primary"
              outlined
              @click="addAction()"
            >
              <v-icon>mdi-plus</v-icon>Add Action
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template #actions>
      <v-spacer />
      <v-btn
        v-if="edit"
        color="primary"
        :loading="loading"
        @click="updateRule()"
      >
        Update
      </v-btn>
      <v-btn
        v-else
        color="primary"
        :loading="loading"
        @click="saveRule()"
      >
        Save
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
import { mapState } from 'vuex';
import Action from './Action.vue';
import Criteria from '../filters/Criteria.vue';
import Rules from '../../../shared/rules';
import Filters from '../../../shared/filters';
export default {
  components: {
    Action,
    Criteria
  },
  data() {
    return {
      edit: false,
      rule: {},
      criteria: [],
      active: true,
      selection: 'all',
      radioGroup: null,
      dialog: false,
      actions: [],
      filter: {},
      loading: false
    };
  },
  computed: {
    ...mapState(['filters'])
  },
  watch: {
    'rule.selection': function(newVal) {
      if (newVal === 'all') {
      this.rule.filter = Filters.defaultFilter();
      this.rule.filter_id = null;
      }
      else if (newVal === 'filter') {
        this.rule.filter = Filters.defaultFilter();
      }
      else if (newVal === 'custom') {
        this.rule.filter_id = null;
      }
    }
  },
  mounted() {
    if (this.filters.filters.length === 0) {
      this.$store.dispatch('filters/getFilters');
    }
  },
  methods: {
    startNew() {
      this.dialog = true;
      this.rule = Object.assign({}, Rules.defaultRule());
      this.edit = false;
    },
    startEdit(rule) {
      this.dialog = true;
      this.rule = Object.assign(Rules.defaultRule(), rule);

      if (this.rule.filter !== null && this.rule.filter.rule_only === 1) {
        this.rule.selection = 'custom';
        this.rule.filter_id = null;
      } else if (
        this.rule.filter !== null &&
        this.rule.filter.rule_only === 0
      ) {
        this.rule.selection = 'filter';
        this.rule.filter = Filters.defaultFilter();
      } else {
        this.rule.selection = 'all';
        this.rule.filter = Filters.defaultFilter();
      }

      this.edit = true;
    },
    duplicate(d) {
      let rule = Object.assign({}, Rules.defaultRule(), d);
      rule.id = null;

      if (rule.filter) {
        rule.filter.id = null;
      }

      rule.name = `${rule.name} (Copy)`;

      if (rule.filter) {
        for (
          let index = 0;
          index < rule.filter.filter_criteria.length;
          index++
        ) {
          rule.filter.filter_criteria[index].id = null;
        }
      }

      if (rule.rule_actions) {
        for (let index = 0; index < rule.rule_actions.length; index++) {
          rule.rule_actions[index].id = null;
        }
      }

      this.dialog = true;
      this.rule = Object.assign(Rules.defaultRule(), rule);

      if (this.rule.filter !== null && this.rule.filter.rule_only === 1) {
        this.rule.selection = 'custom';
        this.rule.filter_id = null;
      } else if (
        this.rule.filter !== null &&
        this.rule.filter.rule_only === 0
      ) {
        this.rule.selection = 'filter';
        this.rule.filter = Filters.defaultFilter();
      } else {
        this.rule.selection = 'all';
        this.rule.filter = Filters.defaultFilter();
      }

      this.edit = false;
    },
    addAction() {
      this.rule.rule_actions.push(Rules.defaultAction());
    },
    removeAction(index, action) {
      if (action.id === null) {
        this.rule.rule_actions.splice(index, 1);
      } else {
        this.$set(this.rule.rule_actions[index], 'delete', true);
      }
    },
    addCriteria() {
      this.rule.filter.filter_criteria.push(Filters.defaultCriterion());
    },
    saveRule() {
      this.loading = true
      this.$http
        .post(`/rules`, this.rule)
        .then(() => {
          this.dialog = false;
          this.$emit('update-rules');
        })
        .catch((err) => {
          if (
            err.response.status === 422 &&
            typeof err.response.data.errors !== 'undefined'
          ) {
            const errors = err.response.data.errors;
            let message = '';

            for (var field in errors) {
              message += `${errors[field][0]}\n`;
            }

            this.errorMessage(message);
          }
        })
        .finally(() => {
          this.loading = false
        });
    },
    updateRule() {
      this.loading = true
      this.$http
        .put(`/rules/${this.rule.id}`, this.rule)
        .then(() => {
          this.dialog = false;
          this.$emit('update-rules');
        })
        .catch((err) => {
          if (
            err.response.status === 422 &&
            typeof err.response.data.errors !== 'undefined'
          ) {
            const errors = err.response.data.errors;
            let message = '';

            for (var field in errors) {
              message += `${errors[field][0]}\n`;
            }

            this.errorMessage(message);
          }
        })
        .finally(() => {
          this.loading = false
        });
    },
    removeFilter(index, criterion) {
      if (criterion.id === null) {
        console.log('remove null');
        this.rule.filter.filter_criteria.splice(index, 1);
      } else {
        console.log('remov not null');
        this.$set(this.rule.filter.filter_criteria[index], 'delete', true);
      }
    }
  }
};
</script>
