// Make sure to call Vue.use(Vuex) first if using a module system
import Vue from 'vue';
import VuexPersistence from 'vuex-persist';
import Vuex from 'vuex';
import bulkImport from './modules/bulkImport';
import singleFlow from './modules/singleflow';
import main from './modules/main';
import imp from './modules/import';
import cart from './modules/cart';
import batches from './modules/batches';
import filters from './modules/filters';
import listCriteria from './modules/listCriteria';
import packingSlipTemplates from './modules/packingSlipTemplates';
import boxes from './modules/boxes';
import tags from './modules/tags';
import userStores from './modules/userStores';
import auth from './modules/auth';
import analytics from '@/components/analytics/store';
import qz from './modules/qz';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['auth', 'main', 'cart', 'userStores', 'tags']
});

const store = new Vuex.Store({
  strict: false,
  modules: {
    auth,
    bulkImport,
    singleFlow,
    main,
    cart,
    analytics,
    imp,
    batches,
    filters,
    listCriteria,
    packingSlipTemplates,
    boxes,
    userStores,
    tags,
    qz
  },
  actions: {
    clearAll({ commit }) {
      commit('auth/reset');
      commit('cart/reset');
      commit('bulkImport/reset');
      commit('singleFlow/reset');
      commit('main/reset');
      commit('batches/reset');
      commit('imp/reset');
      commit('filters/reset');
      commit('listCriteria/reset');
      commit('boxes/reset');
      commit('packingSlipTemplates/reset');
      commit('userStores/reset');
      commit('tags/reset');
      commit('qz/resetState');
      
    }
  },
  plugins: [vuexLocal.plugin]
});

export default store;
