<template>
  <StallionDialog
    v-model="show"
    :width="500"
    :disabled="disabled"
    title="Duplicate Orders"
  >
    <template #content>
      <v-layout column>
        <v-flex
          xs12
        >
          <div class="dialog-info">
            You are about to duplicate {{ orders.length }}
            {{ orders.length | pluralize(' order') }}.
          </div>
        </v-flex>
      </v-layout>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        :loading="duplicate_loading"
        :disabled="duplicate_loading"
        @click="duplicate()"
      >
        <v-icon
          small
          dark
        >
          mdi-content-copy
        </v-icon>Duplicate
      </v-btn>
    </template>
  </StallionDialog>
</template>
  
<script>
export default {
  props: {
    orders: {
      type: Array,
      default: () => []
    },
    disabled: {
      default: false,
      type: Boolean
    },
    value: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      duplicate_loading: false
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },

  methods: {
    duplicate: function() {
      this.duplicate_loading = true;
      const ids = this.orders.map(a => a.id);
      
      this.$http
        .post('/orders/duplicate', {
          ids
        })
        .then(response => {
          const displayOrderIds = response.data.display_order_ids.join(', ');
          this.successMessage('Order Ids: ' + displayOrderIds);
        })
        .catch(() => {
          this.errorMessage('Could not duplicate the selected orders');
        })
        .finally(() => {
          this.duplicate_loading = false;
          this.show = null;

          this.$emit('update');
        });

    }
  }
};
</script>
  