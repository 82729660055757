<template>
  <v-app id="inspire">
    <slot />
  </v-app>
</template>

<script>
// import moment from 'moment';
export default {
  data() {
    return {
      userName: '',
      availableCredits: '0.00',
      pendingFees: '0.00',
      postage: '0.00',
      delivery: '0.00',
      togglePendingFeeStatus: false,
      newlyRegistered: true
    };
  },
  mounted() {
    if (window.Laravel.accountSetupStatus) {
      this.newlyRegistered = false;
    }
  },

  methods: {
    pickupRequest: function() {
      var el = $('.pickup-request-modal');
      el.modal({
        backdrop: 'static',
        keyboard: false
      });
      this.$root.$emit('resetForms', true);
    }
  }
};
</script>
