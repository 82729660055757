<template>
  <v-layout justify-center>
    <v-flex
      xs12
      sm8
      md6
      mb-12
    >
      <v-card class="elevation-5">
        <v-col
          col-4
          text-left
          style="padding-bottom: unset"
        >
          <v-btn
            large
            text
            style="
              padding: unset;
              justify-content: start;
              align-items: start;
              align-content: start;
              height: unset;
              width: unset;
              margin: 0;
              text-align: center;
              min-width: unset;
              margin: 0 5px 0 0;
              padding: 0.5rem;
            "
            @click="setStep('recipient')"
          >
            <v-icon dark>
              mdi-arrow-left
            </v-icon> Back
          </v-btn>
        </v-col>
        <v-card-text style="margin: unset; padding-top: unset">
          <v-flex
            xs12
            text-center
          >
            <h2 class="font-weight-bold mt-3">
              Verify Address
            </h2>
          </v-flex>
          <v-form>
            <v-container>
              <v-layout wrap>
                <v-flex
                  xs12
                  md6
                >
                  <h3><i class="mdi mdi-pencil" /> Current Address</h3>
                  <v-radio-group v-model="addressSelected">
                    <v-radio value="inputted">
                      <div slot="label">
                        {{ shipment.address1 }}
                        <br>
                        <span v-if="shipment.address2">
                          {{ shipment.address2 }}<br>
                        </span>
                        {{ shipment.city }}, {{ shipment.province_code }}
                        {{ shipment.postal_code }}
                        <br>
                        {{ shipment.country_code }}
                      </div>
                    </v-radio>

                    <div v-if="suggestions && suggestions.length > 0 && !exact">
                      <div
                        v-for="(suggestion, index) in suggestions"
                        :key="index"
                        class="mt-4"
                      >
                        <h3>
                          <i class="mdi mdi-map-marker" />
                          Suggested Address {{ index + 1 }}
                        </h3>
                        <v-radio :value="index">
                          <div slot="label">
                            {{ suggestion.address1 }}
                            <br>
                            {{ suggestion.city }},
                            {{ suggestion.province_code }}
                            {{ suggestion.postal_code }}
                            <br>
                            {{ suggestion.country_code }}
                          </div>
                        </v-radio>
                      </div>
                    </div>
                  </v-radio-group>
                </v-flex>
                <transition name="fade">
                  <v-flex
                    v-if="match != null && match"
                    xs12
                    md6
                  >
                    <div style="text-align: center">
                      <h3>Address Valid!</h3>
                      <v-icon
                        size="150"
                        color="success"
                      >
                        mdi-check-circle-outline
                      </v-icon>
                    </div>
                  </v-flex>
                  <v-flex
                    v-else-if="match != null && !match"
                    xs12
                    md6
                  >
                    <div style="text-align: center">
                      <h3>Address Not Valid</h3>
                      <v-icon
                        size="150"
                        color="error"
                      >
                        mdi-minus-circle-outline
                      </v-icon>

                      <p class="mt-2">
                        The selected address cannot be processed.
                        <br>Please correct it to continue.
                      </p>

                      <v-alert
                        v-for="error in errors"
                        :key="error"
                        class="mt-3"
                        dense
                        icon="error"
                        color="#D06079"
                        outlined
                      >
                        {{ error }}
                      </v-alert>
                    </div>
                  </v-flex>
                </transition>
              </v-layout>
              <v-layout
                wrap
                mt-3
              >
                <!-- <v-flex
                  v-if="shipment.country_code == 'US'"
                  xs12
                  md6
                >
                  <h3>Is this an FBA shipment?</h3>

                  <v-radio-group
                    v-model="shipment.is_fba"
                    @change="flip"
                  >
                    <v-radio :value="true">
                      <div slot="label">
                        Yes
                      </div>
                    </v-radio>
                    <v-radio :value="false">
                      <div slot="label">
                        No
                      </div>
                    </v-radio>
                  </v-radio-group>
                </v-flex> -->
                <v-flex
                  v-if="
                    shipment.status_id == 3 &&
                      shipment.country_code != 'CA' && shipment.is_fba == false && $auth.user.enable_third_party_postage
                  "
                  xs12
                  md6
                >
                  <h3>Do you need Postage?</h3>

                  <v-radio-group v-model="shipment.needs_postage">
                    <v-radio :value="true">
                      <div slot="label">
                        Yes
                      </div>
                    </v-radio>
                    <v-radio :value="false">
                      <div slot="label">
                        No
                      </div>
                    </v-radio>
                  </v-radio-group>
                </v-flex>
              </v-layout>

              <v-layout
                align-center
                class="mt-5 mb-0"
              >
                <v-flex
                  xs12
                  text-center
                >
                  <v-btn
                    :disabled="!match"
                    depressed
                    large
                    color="primary"
                    dusk="sf_verification_next"
                    @click="updateShipment()"
                  >
                    <span v-if="shipment.status_id == 3">
                      Next
                      <v-icon dark>
                        mdi-arrow-right
                      </v-icon>
                    </span>
                    <span v-else>
                      Save
                    </span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      match: null,
      suggestions: null,
      addressSelected: 'inputted',
      initalAddressMatch: false,
      exact: false,
      errors: []
    };
  },
  computed: {
    shipment: {
      get() {
        return this.$store.state.singleFlow.shipment;
      },
      set(value) {
        this.$store.state.singleFlow.shipment = value;
      }
    },
    step: {
      get() {
        return this.$store.state.singleFlow.step;
      },
      set(value) {
        this.$store.state.singleFlow.step = value;
      }
    },
    processing: {
      get() {
        return this.$store.state.singleFlow.processing;
      },
      set(value) {
        this.$store.state.singleFlow.processing = value;
      }
    }
  },

  watch: {
    addressSelected: function (val) {
      if (val !== 'inputted') {
        this.match = true;
        this.addressSelected = val;
      } else {
        this.match = this.initalAddressMatch;
        this.addressSelected = 'inputted';
      }
    },
    step: {
      immediate: true,
      handler(newValue, oldValue) {
        if (
          (newValue == 2 && (oldValue < 2 || oldValue == 1)) ||
          (newValue >= 2 && (oldValue == undefined || oldValue == 1))
        ) {
          if (this.shipment.country_code != 'US') {
            this.shipment.needs_postage = true;
            this.shipment.is_fba = false;
          }

          if (this.shipment.is_fba == true) {
            this.shipment.package_type = 'FBA';
            this.shipment.needs_postage = false;
          } else if (this.shipment.country_code == 'CA') {
            this.shipment.package_type = 'Parcel';
          }

          this.addressSelected = 'inputted';

          this.verifyAddress();
        }
      }
    }
  },
  created() {
    this.$http
      .get('/countries')
      .then((res) => {
        this.countries = res.data;
      })
      .catch(() => {});

    // window.addEventListener('keydown', (e) => {
    //   if ((this.processing == 0 && this.step == 2 && e.key == 'Enter')) {
    //     this.updateShipment();
    //   }
    // });
  },
  methods: {
    flip() {
      if (this.shipment.is_fba == true) {
        this.shipment.package_type = 'FBA';
        this.shipment.needs_postage = false;
      } else if (this.shipment.country_code == 'CA') {
        this.shipment.package_type = 'Parcel';
      }
    },
    setStep() {
      this.$store.commit('singleFlow/previous');
    },
    verifyAddress() {
      this.processing++;

      this.$http
        .post('/shipments/verifyAddress', this.shipment)
        .then((response) => {
          this.match = response.data.valid;

          if (this.match == false) {
            this.match = false;
            this.initalAddressMatch = false;
          } else {
            this.initalAddressMatch = true;
          }
          this.suggestions = response.data.suggestions;
          this.errors = response.data.errors;
          if (
            this.suggestions &&
            this.shipment.address1 == this.suggestions.address1 &&
            this.shipment.city == this.suggestions.city &&
            this.shipment.postal_code == this.suggestions.postal_code &&
            this.shipment.province_code == this.suggestions.province_code
          ) {
            this.exact = true;
          }
        })
        .finally(() => {
          this.processing--;
        });
    },
    updateShipment() {
      if (this.addressSelected != 'inputted') {
        this.shipment.address1 =
          this.suggestions[this.addressSelected].address1;
        this.shipment.address2 =
          this.suggestions[this.addressSelected].address2;
        this.shipment.city = this.suggestions[this.addressSelected].city;
        this.shipment.postal_code =
          this.suggestions[this.addressSelected].postal_code;
        this.shipment.province_code =
          this.suggestions[this.addressSelected].province_code;
        this.match = true;
      }
      this.$validator.validate().then((valid) => {
        if (valid) {
          this.$http
            .put('/shipments/' + this.shipment.id, this.shipment)
            .then((res) => {
              this.shipment = res.data;

              if (this.shipment.status_id == 3) {
                // if the shipment status is in complete, go to the next step
                this.$store.commit('singleFlow/next');
              } else {
                // close the modal if the shipment status is not in complete
                Event.fire('close-singleflow');
              }
            })
            .catch((err) => {
              this.$notify({
                group: 'main',
                title: 'Error',
                text: err,
                duration: 5000,
                type: 'error'
              });
            });
        }
      });
    }
  }
};
</script>
