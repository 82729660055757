<template>
  <StallionDialog
    v-model="show"
    :width="800"
    persistent
    title="Report Technical Issue"
  >
    <template #content>
      <v-row>
        <v-col cols="12">
          <v-textarea
            v-model="comments"
            outlined
            label="Tell us what happened"
            hint="Please provide your feedback"
            rows="5"
            clearable
          />
        </v-col>

        <v-col cols="12">
          <v-alert
            type="info"
            outlined
          >
            A log of your activity will be logged and shared with our development team for debugging purposes. Your account information will automatically be included in the log.
          </v-alert>
        </v-col>
      </v-row>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        @click.prevent="submit()"
      >
        Save
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
import * as Sentry from '@sentry/browser';

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      comments: ''
    }
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.comments = '';
        this.$emit('input', value);
      }
    }
  },
  methods: {
    submit() {
      // const eventId = Sentry.lastEventId();
      const eventId = Sentry.lastEventId() || Sentry.captureMessage('User Feedback');

      const userFeedback = {
        event_id: eventId,
        name: this.$auth.user.name,
        email: this.$auth.user.email,
        comments: this.comments,
      }

      console.log(userFeedback);
      const res = Sentry.captureUserFeedback(userFeedback);
      console.log(res);
      this.successMessage('Thank you for your feedback!');
      this.show = false;
    }
  }
};
</script>
