<template>
  <FullPageLayout title="Users Groups">
    <template #default>
      <v-flex xs12>
        <v-form @submit.prevent>
          <v-container pa-0>
            <v-layout wrap>
              <v-flex xs4>
                <v-text-field
                  v-model="search"
                  pt-0
                  label="Search"
                  prepend-inner-icon="search"
                  @input="submitSearch()"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-flex>

      <v-data-table
        :headers="headers"
        :loading="loading"
        item-key="id"
        :items="items"
        :options.sync="options"
        class="table-striped"
        :server-items-length="totalItems"
        :footer-props="{
          'items-per-page-options': [50, 100, 200, 500]
        }"
        :items-per-page="25"
        @click:row="rowClick"
      >
        <template #item.created_at="{ item }">
          {{ item.created_at | date_pretty }}
          <br>
          ({{ item.created_at | datetime_from }})
        </template>

        <template #item.action="{ item }">
          <v-btn
            color="primary"
            @click.stop="remove(item.id)"
          >
            Delete
          </v-btn>
        </template>
      </v-data-table>
    </template>
    <template #footer>
      <v-menu>
        <template #activator="{ on }">
          <v-btn
            color="primary"
            v-on="on"
            @click="show = true"
          >
            <v-icon
              small
              dense
              left
            >
              mdi-plus
            </v-icon>
            New
          </v-btn>
        </template>
      </v-menu>
      <create-user-group v-model="show" />
    </template>
  </FullPageLayout>
</template>

<script>
export default {
  data() {
    return {
      start_date: null,
      end_date: null,
      search: null,
      selected: [],
      show: false,

      headers: [
        {
          text: 'ID',

          align: 'left',
          value: 'id'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Master Account',
          value: 'master.email'
        },
        {
          text: '# Users',
          value: 'users_count'
        },

        {
          text: 'Created At',
          value: 'created_at'
        },
        {
          text: '',
          value: 'action'
        }
      ],

      options: {
        sortBy: ['created_at'],
        sortDesc: [true]
      },

      totalItems: 0,
      items: [],

      loading: false,

      timer: null,
      cancelSource: null
    };
  },

  watch: {
    // '$route.query.action'(val) {
    //   this.updateTable();
    // },
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    }
  },
  mounted() {
    Event.listen('update-user-groups', () => {
      this.updateTable();
      this.main.selectedShipments = [];
    });
  },

  methods: {
    remove(id) {
      var self = this;
      swal({
        title: 'Confirm Delete',
        text: 'Do you really want to remove this group?',
        icon: 'warning',
        buttons: ['Cancel', 'Remove']
      }).then(function(isConfirm) {
        if (isConfirm) {
          self.$http
            .delete(`/admin/user-groups/${id}`)
            .then(response => response.data)
            .then(() => {
              self.updateTable();
              self.$notify({
                group: 'main',
                title: 'Group Removed',
                text: 'Group has been removed from our system.',
                duration: 5000,
                type: 'success'
              });
            })
            .catch(() => {
              self.$notify({
                group: 'main',
                title: 'Error removing group',
                text: 'Could not remove this group.',
                duration: 5000,
                type: 'error'
              });
            });
        }
      });
    },
    rowClick(item) {
      this.$router.push({
        name: 'admin-user-groups-show',
        params: {
          id: item.id
        }
      });
    },
    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.options.page = 1;
      this.timer = setTimeout(function() {
        self.updateTable();
      }, ms);
    },
    updateTable() {
      this.$store.state.main.selectedBatches = [];
      this.totalItems = 0;
      this.items = [];

      this.getDataFromApi().then(data => {
        this.items = data.items;
        this.totalItems = data.total;
      });
    },

    getDataFromApi() {
      this.loading = true;
      return new Promise(resolve => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let items = [];
        let total = 0;
        let pages = 0;

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        var params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sortBy[0],
          desc: sortDesc[0],
          search: this.search,
          start_date: this.start_date,
          end_date: this.end_date
        };

        this.$http
          .request(`/admin/user-groups`, {
            method: 'get',
            params: params,
            cancelToken: this.cancelSource.token
          })

          .then(response => response.data)
          .then(response => {
            items = response.data;
            total = response.total;
            pages = response.last_page;

            this.loading = false;
            resolve({
              items,
              total,
              pages
            });
          });
      });
    }
  }
};
</script>
