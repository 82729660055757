<template>
  <v-row v-if="store && order">
    <v-col cols="12">
      <div class="text-h4">
        Order #: {{ $route.query.order_id }}
      </div>
    </v-col>

    <v-col cols="12">
      <div class="text-h5">
        Select items to return
      </div>
    </v-col>
    
    <v-col cols="12">
      <v-form @submit.prevent>
        <v-row
          v-for="(item, index) in order.items"
          :key="item.id"
          dense
        >
          <v-col
            cols="12"
            md="6"
            :class="'d-flex align-center'"
          >
            <v-checkbox
              v-model="item.selected"
              color="primary"
              class="pa-0"
            />
          
          
            <v-img
              class="ma-3"
              :src="item.img_url || 'https://cannamazoo.com/assets/defaults/img/default-product-img.jpg'"
              :lazy-src="'https://cannamazoo.com/assets/defaults/img/default-product-img.jpg'"
              min-height="100"
              min-width="100"
              max-height="100"
              max-width="100"
              aspect-ratio="1"
            />
            <div class="text-h6">
              <p>
                <strong>{{ item.description }} </strong>
              </p>
              <p>
                <strong> Value: </strong>
                {{ item.value | currency }} {{ item.currency }}
              </p>
              <p><strong>Quantity:</strong> {{ item.quantity }}</p>
            <!-- <p>
                    <strong> Returned Quantity: </strong>
                    {{ getReturnedQty(item) }}
                  </p> -->
            </div>
          </v-col>


          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-if="item.selected"
              v-model="item.return_quantity"
              v-validate="'min_value:1|required'"
              dense
              outlined
              :name="'return_quantity_' + index"
              :clearable="true"
              :items="returnQtyOptions(item)"
              label="Quantity To Return"
              :data-vv-name="'return_quantity_' + index"
              :error-messages="errs.collect('return_quantity_' + index)"
              data-vv-validate-on="blur"
            />
          

            <v-textarea
              v-if="item.selected"
              v-model="item.return_reason"
              v-validate="'min:5|required'"
              dense
              outlined
              :name="'return_reason_' + index"
              :data-vv-name="'return_reason_' + index"
              :error-messages="errs.collect('return_reason_' + index)"
              label="Return Reason"
              type="text"
              data-vv-validate-on="blur"
              rows="3"
            />
          </v-col>
        <!-- <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="item.return_reason"
            v-validate="'min:5|required'"
            dense
            outlined
            :name="'return_reason_' + index"
            :data-vv-name="'return_reason_' + index"
            :error-messages="errs.collect('return_reason_' + index)"
            label="Return Reason"
            type="text"
            data-vv-validate-on="blur"
          />
        </v-col> -->
        </v-row>
   

         
        <v-row>
          <v-col :class="$vuetify.breakpoint.mdAndUp ? 'text-left' : 'text-center'">
            <v-btn
              :class="$vuetify.breakpoint.mdAndUp ? 'col-md-8' : 'col-md-12'"
              text
              color="primary"
              outlined
              @click="reset()"
            >
              Search another order
            </v-btn>
          </v-col>

          <v-col :class="$vuetify.breakpoint.mdAndUp ? 'text-right' : 'text-center'">
            <v-btn
              :class="$vuetify.breakpoint.mdAndUp ? 'col-md-8' : 'col-md-12'"
              :loading="loading"
              :disabled="loading"
              color="primary"
              name="login-button"
              @click="confirmSubmitRequest"
            >
              Submit Request
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    store: {
      type: Object,
      default: () => ({})
    },
    order: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      listModel: null
    };
  },
  computed: {
    orderItems() {
      return (this.order.items || []).filter((item) => {
        return item.quantity > this.getReturnedQty(item)
      })
    }
  },

  mounted() {},

  methods: {
    reset() {
      this.$emit('reset');
    },

    getReturnedQty(item) {
      return (this.order.items || []).find((returned) => {
        return returned.id == item.id
      })?.quantity || 0
    },

    returnQtyOptions(item) {
      const qty = item.quantity;

      return [...Array(qty > 0 ? qty : 0).keys()].map((i) => i + 1);
    },

    async confirmSubmitRequest() {
      // mixin.js
      if (!(await this.$validateAll())) {
        return;
      }

      // filter out empty qty
      const filteredItems = this.order.items.filter((item) => {
        return item.return_quantity > 0 && item.selected;
      });

      if (filteredItems.length == 0) {
        this.$notify({
          group: 'main',
          title: 'Specify a return Quantity!',
          text: 'Please select an item to return',
          duration: 5000,
          type: 'error'
        });
        return;
      }

      let message = '';
      filteredItems.forEach((item) => {
        message += `${item.description} - (Quantity: ${item.return_quantity}) \n`;
      });

      const self = this;
      window
        .swal({
          title: 'Are you sure you want to submit return request?',
          text: message,
          icon: 'warning',
          buttons: ['No', 'Yes']
        })
        .then(function (isConfirm) {
          if (isConfirm) {
            self.submitRequest(filteredItems);
          } else {
            window.swal.close();
          }
        });
    },

    submitRequest(filteredItems) {
      this.loading = true;
      this.$http
        .post(
          `/returns-portal/${this.store.returns_portal_code}/label`,
          {
            order_id: this.order.order_id,
            postal_code: this.order.postal_code,
            items: filteredItems
          }
        )
        .then((res) => {
          const data = res.data;
          if (data.success) {
            this.$emit('goToReturnLabel', { shipment: data.shipment });
          } else {
            this.errorMessage(data.message)
          }
        })
        .catch((err) => {
          let errTitle = '';
          let errMsg = 'Unknown Error Occurred';
          if (err.response.status === 422 && err.response.data.message) {
            errTitle = 'The given data is invalid.';
            errMsg = err.response.data.message;
          }

          this.$notify({
            group: 'main',
            title: errTitle,
            text: errMsg,
            duration: 5000,
            type: 'error'
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
