<template>
  <StallionDialog
    v-model="show"
    :width="500"
    persistent
    :title="'Open Batch '.concat(batch.id)"
  >
    <template
      v-if="batch"
      #content
    >
      <v-form>
        <v-container fluid>
          <v-row dense>
            <v-col cols="12">
              <div>
                Are you sure that you want to reopen batch {{ batch.id }}? The
                batch label will be invalidated and you will be required to
                close the batch again.
              </div>
            </v-col>

            <v-col
              v-if="batch === null"
              cols="12"
            >
              <v-text-field
                v-model="name"
                label="Name (Optional)"
                clearable
                outlined
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>

    <template #actions>
      <v-spacer />
      <v-btn
        :loading="loading"
        :disabled="loading"
        color="primary"
        @click="openBatch()"
      >
        Confirm
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    batch: {
      type: Object,
      default: null
    },
    value: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      closeout: true,
      loading: false
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },

  methods: {
    openBatch() {
      this.loading = true;
      this.$http
        .post(`/batches/${this.batch.id}/open`)
        .then(response => response.data)
        .then(() => {
          this.show = false;
          this.$store.dispatch('batches/getOpenBatches');
          this.$emit('update');
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
