<template>
  <FullPageLayout title="My Branch">
    <template #default>
      <v-flex
        xs12
        md6
      >
        <edit-employee-branch
          :user="$auth.user"
          @user-updated="updateMe"
        />
      </v-flex>
    </template>
  </FullPageLayout>
</template>

<script>
export default {
  methods: {
    updateMe() {
      this.getUser();
    }
  }
};
</script>