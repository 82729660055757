<template>
  <v-dialog
    v-model="show"
    width="500"
    :disabled="disabled"
    class="dialog-slot"
  >
    <slot v-on="on" />

    <v-card>
      <v-form @submit.prevent>
        <v-card-title
          class="text-h5"
          primary-title
        >
          Walmart CA
        </v-card-title>

        <v-divider />

        <v-card-text>
          <v-layout column>
            <v-flex xs12>
              Go to
              <a
                href="https://developer.walmart.com/generateKey"
                target="_blank"
              >Walmart API Settings</a>
              section from your Walmart account and generate an API key if none
              exists.
            </v-flex>
            <v-flex xs12>
              <v-text-field
                id="consumer_id"
                v-model="consumer_id"
                v-validate="'required'"
                :error-messages="errs.collect('consumer_id')"
                label="Consumer ID"
                data-vv-name="consumer_id"
              />
            </v-flex>
            <v-flex xs12>
              <v-text-field
                id="private_key"
                v-model="private_key"
                v-validate="'required'"
                :error-messages="errs.collect('private_key')"
                label="Private Key"
                data-vv-name="private_key"
              />
            </v-flex>
            <v-flex xs12>
              <v-text-field
                id="nickname"
                v-model="nickname"
                :error-messages="errs.collect('nickname')"
                label="Nickname(Optional)"
                data-vv-name="nickname"
              />
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="show = false"
          >
            Close
          </v-btn>
          <v-spacer />

          <v-btn
            type="submit"
            color="primary"
            :loading="isSubmitting"
            :disabled="isSubmitting"
            @click="connect()"
          >
            Connect
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },

  data() {
    return {
      consumer_id: null,
      private_key: null,
      nickname: null,
      isSubmitting: false
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    reset() {
      this.$validator.reset();
      this.consumer_id = null;
      this.private_key = null;
      this.nickname = null;
    },
    close() {
      this.$emit('update:show', false);
    },
    async validate() {
      return await this.$validator.validate().then((result) => {
        return result ? true : false;
      });
    },
    async connect() {
      if (!(await this.validate())) return;

      this.isSubmitting = true;
      this.$http
        .post('/stores/walmart-ca', {
          consumer_id: this.consumer_id,
          private_key: this.private_key,
          nickname: this.nickname,
        })
        .then((response) => response.data)
        .then(() => {
          this.$store.dispatch('userStores/getStores');
          this.show = false;
          this.$notify({
            group: 'main',
            title: 'Store added',
            text: 'Your account for Walmart has been added successfully.',
            duration: 5000,
            type: 'success'
          });

          this.reset();
        })
        .catch((err) => {
          if (err.response && err.response.status === 422) {
            this.$notify({
              group: 'main',
              title: 'Invalid Credentials',
              text: err.response.data.errors,
              duration: 5000,
              type: 'error'
            });
          } else {
            this.$notify({
              group: 'main',
              title: 'Error adding Walmart',
              text: err,
              duration: 5000,
              type: 'error'
            });
          }
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    }
  }
};
</script>
