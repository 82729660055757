<template>
  <div>
    <v-card outlined>
      <v-data-table
        class="table-striped"
        :headers="headers"
        :items="items"
        :loading="loading"
        :items-per-page="10"
      >
        <template #item.period="{ item }">
          {{ item.start_date }} - {{ item.end_date }}
        </template>

        <template #item.actions="{ item }">
          <v-btn
            color="primary"
            download
            small
            @click.prevent="download(item.path)"
          >
            <v-icon
              small
              dark
            >
              mdi-download
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      items: [],

      options: {
        sortBy: ['created_at'],
        sortDesc: [true]
      },

      headers: [
        {
          text: 'ID',
          sortable: false,
          value: 'invoice_id'
        },
        {
          text: 'Total',
          sortable: false,
          value: 'total'
        },

        {
          text: 'Period',
          align: 'right',
          value: 'period'
        },

        {
          text: '',
          sortable: false,
          align: 'right',
          value: 'actions'
        }
      ]
    };
  },
  watch: {
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    }
  },

  mounted() {
    this.updateTable();
    Event.listen('update-rules', () => {
      this.updateTable();
    });
  },
  methods: {
    updateTable() {
      this.items = [];

      this.$http
        .request(`/drop-off/invoices`, {
          method: 'get'
        })
        .then(response => response.data)
        .then(response => {
          this.items = response.data;
        });
    },
    download: function(data) {
      // var newfile;

      this.$http({
        method: 'post',
        url: '/drop-off/invoices/download',
        data: {
          file: data
        }
      }).then(response => {
        this.downloadFileUrl(response.data,data);
      });
    }
  }
};
</script>
