<template>
  <v-btn
    :text="isText"
    color="primary"
    :disabled="disabled"
    :loading="delete_loading"
    small
    @click="batchRemove()"
  >
    <v-icon
      v-if="showIcon"
      small
      dense
    >
      mdi-delete
    </v-icon>
    {{ removeLabel }}
  </v-btn>
</template>

<script>
export default {
  props: {
    shipments: {
      type: Array,
      default: () => []
    },
    batch: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isText: {
      type: Boolean,
      default: false
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    removeLabel: {
      type: String,
      default: 'Remove'
    }
  },
  data() {
    return {
      delete_loading: false
    };
  },
  computed: {},
  methods: {
    batchRemove: async function() {
      this.delete_loading = true;

      const ids = this.shipments.map(a => a.id);

      this.$http
        .post(`/batches/${this.batch.id}/remove`, {
          ids: ids
        })
        .then(() => {
          this.$emit('update');
        })
        .catch(() => {
          this.errorMessage(
            'Could not remove the selected shipments from the batch'
          );
        })
        .finally(() => {
          this.delete_loading = false;
          this.dialog = null;
          this.number = 0;
        });
    }
  }
};
</script>
