<template>
  <StallionDialog
    v-model="dialog"
    persistent
    :width="800"
    :title="'Create Container'"
  >
    <template #content>
      <v-form @submit.prevent>
        <v-container>
          <v-row dense>
            <v-col
              v-if="container.type != 'Trailer'"
              cols="12"
            >
              <v-select
                v-model="outputs"
                v-validate="'required'"
                multiple
                outlined
                
                :items="bins"
                item-value="output"
                item-text="text"
                :error-messages="errs.collect('output')"
                label="Output #"
                data-vv-name="output"
                required
                chips
                clearable
              />

              <v-btn
                color="secondary"
                @click="selectAll()"
              >
                Select All
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>

    <template #actions>
      <v-spacer />
      <v-btn
        v-if="edit"
        color="primary"
        :loading="submitting"
        :disabled="submitting"
        @click="updateContainer()"
      >
        Update
      </v-btn>
      <v-btn
        v-else
        color="primary"
        :loading="submitting"
        :disabled="!outputs.length || submitting"
        @click="saveContainer()"
      >
        Create
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  data() {
    return {
      container: this.getDefaultContainer(),
      edit: false,
      dialog: false,
      submitting: false,
      sortPlan: [],
      bins: [],
      outputs: [],
    };
   
  },
  computed: {
  },
  watch: {
   'dialog': function (val) {
     if(!val){
       this.outputs = [];
     }
     else{
       this.selectAll();
     }
   }
  },
  methods: {
    getSortPlan() {
  return new Promise((resolve, reject) => {
    this.$http
      .get(`/admin/npi/sort-plan`)
      .then(response => {
        const { sort_plan, bins } = response.data;
        this.sortPlan = sort_plan;
        this.bins = bins
          .filter(bin => bin.is_sortable)
          .map(bin => ({
            text: `${bin.output} - ${bin.name} - ${bin.type}`,
            output: bin.output,
            type: bin.type,
            name: bin.name,
            carrier: bin.carrier
          }));
        resolve(); // Resolve the promise when done
      })
      .catch(error => {
        this.onError(error);
        reject(error); // Reject the promise on error
      });
  });
},

    getDefaultContainer() {
      return  {
        name: '',
        type: '',
        output: ''
      };
    },
    initDialog() {
      this.getSortPlan();
      this.dialog = true;
      this.submitting = false;
      this.$validator.reset();
    },
    closeDialog() {
      this.dialog = false;
      this.$emit('update-containers');
    },
    startNew() {
      this.initDialog();
      this.container = { ...this.getDefaultContainer() };
      this.edit = false;
    },
    startEdit(container) {
      this.initDialog();
      this.container = { ...container };
      this.edit = true;
    },
    async validateContainer() {
      return this.$validator.validate().then((result) => {
        return result ? true : false;
      });
    },
    async saveContainer() {
      if (!(await this.validateContainer())) return;

      console.log(this.bins);
      console.log(this.outputs);

      const containers = this.bins.filter((container) => {
        return this.outputs.includes(container.output);
      });
      this.submitting = true;
      this.$http
        .post(`/admin/containers`, {containers})
        .then((data) => {
          this.printUrl(data.data.url, true);
          // this.closeDialog();
          this.outputs = [];
        })
        .catch((err) => {
          this.onError(err);
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    async updateContainer() {
      if (!(await this.validateContainer())) return;

      this.submitting = true;
      this.$http
        .put(`/boxes/${this.container.id}`, this.container)
        .then(() => {
          this.closeDialog();
          this.successMessage('Container successfully updated!');
        })
        .catch((err) => {
          this.onError(err);
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    onError(err) {
      if (
        err.response.status === 422 &&
        typeof err.response.data.errors !== 'undefined'
      ) {
        const errors = err.response.data.errors;
        let message = '';

        for (var field in errors) {
          message += `${errors[field][0]}\n`;
        }

        this.errorMessage(message);
      }
    },
    selectAll() {
  this.getSortPlan().then(() => {
    this.outputs = this.bins.map(bin => bin.output);
  }).catch(error => {
    // Handle any errors that occurred during getSortPlan
    console.error('Error in selectAll:', error);
  });
}
  }
};
</script>
