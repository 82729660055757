<template>
  <FullPageLayout title="Custom Pricing">
    <template #default>
      <rule-table :user-id="parseInt($route.params.user_id)" />
    </template>
  </FullPageLayout>
</template>

<script>
import RuleTable from './RuleTable.vue';
export default {
  components: {
    RuleTable
  },
  data() {
    return {
     
    };
  },
  methods: {
   
  }
};
</script>
