<template>
  <v-container
    grid-list-md
    mb-12
  >
    <v-layout wrap>
      <v-flex xs12>
        <h1>
          <i
            aria-hidden="true"
            class="mdi mdi-download"
          /> Export
        </h1>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex
        xs12
        md6
      >
        <v-card
          height="100%"
          outlined
        >
          <v-container>
            <v-layout wrap>
              <v-flex xs12>
                <h1>
                  <i class="mdi mdi-download" />
                  <span>Export Shipments</span>
                </h1>
       
                <v-flex
               
                  xs12
                >
                  <v-select
                    v-model="container"
                    :items="containers"
                    item-text="name"
                    item-value="id"
                    label="Container"
                    outlined
                  />
                </v-flex>

                <v-layout
                  align-center
                  class="mt-4"
                >
                  <v-flex
                    xs12
                    text-center
                  >
                    <v-btn
                      :loading="loading1"
                      :disabled="loading1"
                      color="primary"
                      @click="getExport"
                    >
                      Download
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
      <v-flex
        v-if="
          can('trips.manage') &&
            $auth.user.employee_location.can_closeout == true
        "
        xs12
        md6
        align-self-stretch
      >
        <v-card
          height="100%"
          outlined
        >
          <v-container
            container--fluid
            grid-list-lg
          >
            <v-layout wrap>
              <v-flex xs12>
                <h1>
                  <i class="mdi mdi-import" />
                  <span>Create Trip</span>
                </h1>
                <v-text-field
                  v-model="trip_number"
                  v-validate="'required|max:50'"
                  :error-messages="errs.collect('trip_number')"
                  label="Trip Number"
                  data-vv-name="trip_number"
                  prefix="SEIK"
                  placeholder=" "
                  outlined
                />
                <v-file-input
                  v-model="file"
                  v-validate="'required'"
                  accept=".csv, .xlsx, xls"
                  prepend-inner-icon="mdi-file"
                  prepend-icon
                  label="Border Connect File"
                  :error-messages="errs.collect('file')"
                  data-vv-name="file"
                  outlined
                />
                <v-layout
                  align-center
                  class="mt-4"
                >
                  <v-flex
                    xs12
                    text-center
                  >
                    <v-btn
                      :loading="loading2"
                      :disabled="loading2"
                      color="primary"
                      @click="uploadFile"
                    >
                      Create
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout>
      <v-flex xs12>
        <p
          v-for="(error, index) in errors"
          :key="index"
          class="alert alert-danger"
        >
          {{ error }}
        </p>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      trip_number: null,
      export_type: 'Border Connect',
      file: null,
      form: new FormData(),
      form2: new FormData(),
      start_dropoff_date: moment().format('YYYY-MM-DD'),
      end_dropoff_date: moment().format('YYYY-MM-DD'),
      menu1: false,
      menu2: false,
      menu3: true,
      items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
      errors: [],
      loading1: false,
      loading2: false,
      loading3: false,
      file2: null,
      containers: [],
      container: null
    };
  },
  mounted() {
    this.getContainers();

    this.trip_number =
      'S' +
      moment()
        .add(1, 'days')
        .format('YYYYMMDD') +
      'A';
  },
  methods: {
    getContainers() {
      const params = {
        types: ['Trailer'],
        per_page: 1000,
      };

      this.$http
          .request(`/admin/containers`, {
            method: 'get',
            params: params,
          })
          .then((response) => response.data)
          .then((response) => {
           this.containers = response.data;
          });
    },
    customFormatter(date) {
      return moment(date).format('MMMM Do YYYY, h:mm:ss a');
    },
    uploadFile() {
      // upload file
      this.loading2 = true;
      this.errors = [];
      this.form.append('import_file', this.file);
      this.form.append('trip_number', this.trip_number);
      this.$http
        .post('/admin/outbound-trips/create', this.form)
        .then(
          res => {
            this.disable = false;
            if (res.data.success) {
              this.$router.push({
                name: 'admin-outbound-trip-show',
                params: {
                  tripNumber: res.data.trip.trip_number
                }
              });
            } else {
              swal({
                title: 'Error',
                text: 'error',
                icon: 'error'
              });
            }
          },
          err => {
            this.disable = false;
            swal({
              title: 'Error',
              text: err,
              icon: 'error'
            });
          }
        )
        .then(() => {
          this.loading2 = false;
          this.file = null;
        });
    },
    setFile(e) {
      if (e.target.files.length) {
        this.file = e.target.files[0];
      }
    },
    getExport() {
      this.loading1 = true;

      this.$http
        .get(`/admin/outbound-trips/export?container_id=${this.container}`)
        .then(response => {
          this.downloadFileUrl(response.data,this.export_type +
            ' (' +
            moment().format('YYYYmmdHHMMSS') +
            ').csv', 'text/csv');
        })
        .catch(err => {
          if (err.response.status === 404) {
            swal({
              title: 'Error',
              text: 'Nothing found',
              icon: 'error'
            });
          }
        })
        .finally(() => {
          this.loading1 = false;
        });
    }
  }
};
</script>
