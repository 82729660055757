<template>
  <div>
    <StallionDialog
      v-model="show"
      clearable
      :width="800"
      title="Edit Shipment"
    >
      <template #content>
        <v-form v-if="shipment_local">
          <v-row dense>
            <v-col cols="12">
              <h2>Address</h2>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="shipment_local.name"
                clearable
                outlined
                dense
                :error-messages="errs.collect('name')"
                label="Name"
                data-vv-name="name"
              />
            </v-col>
            <v-col cols="6">
              <!-- Address1 -->

              <v-text-field
                v-model="shipment_local.address1"
                v-validate="'max:50'"
                clearable
                outlined
                dense
                :error-messages="errs.collect('address1')"
                label="Address1"
                data-vv-name="address1"
              />
            </v-col>

            <!-- Address2 -->
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="shipment_local.address2"
                v-validate
                clearable
                outlined
                dense
                :error-messages="errs.collect('address2')"
                label="Address2"
                data-vv-name="address2"
              />
            </v-col>

            <!-- City -->
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="shipment_local.city"
                clearable
                outlined
                dense
                :error-messages="errs.collect('city')"
                label="City"
                data-vv-name="city"
              />
            </v-col>

            <!-- Country code -->
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="shipment_local.country_code"
                clearable
                outlined
                dense
                :error-messages="errs.collect('country_code')"
                data-vv-name="country_code"
                :items="countries"
                label="Country"
                item-text="name"
                item-value="code"
                :search-input.sync="searchInput"
                dusk="sf_country"
              />
            </v-col>

            <!-- Province/State -->
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-if="shipment.country_code == 'CA'"
                v-model="shipment_local.province_code"
                v-validate="'max:2'"
                clearable
                outlined
                dense
                :error-messages="errs.collect('province_code')"
                data-vv-name="province_code"
                :items="provinces"
                label="Province"
                item-text="name"
                item-value="code"
                :search-input.sync="searchInput2"
                dusk="sf_province"
              />

              <v-autocomplete
                v-else-if="shipment.country_code == 'US'"
                v-model="shipment_local.province_code"
                v-validate="'max:2'"
                clearable
                outlined
                :error-messages="errs.collect('province_code')"
                data-vv-name="province_code"
                :items="states"
                label="State"
                item-text="name"
                item-value="code"
                :search-input.sync="searchInput2"
                dense
                dusk="sf_province"
              />

              <v-text-field
                v-else
                v-model="shipment_local.province_code"
                clearable
                outlined
                dense
                dusk="sf_province"
                label="Province/State"
              />
            </v-col>

            <!-- Postal Code -->
            <v-col
              cols="12"
              md="6"
            >
              <!-- <v-text-field
                  outlined
                  dense
                  v-show="shipment.country_code == 'CA'"
                  v-mask="'A#A #A#'"
                  v-validate="{
                    regex: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/
                  }"
                  validate-on-blur
                  clearable v-model="shipment_local.postal_code"
                  :error-messages="errs.collect('postal_code')"
                  label="Postal Code"
                  data-vv-name="postal_code"
                ></v-text-field>

                <v-text-field
                  outlined
                  dense
                  v-show="shipment.country_code == 'US'"
                  v-validate="{
                    regex: /(^\d{5}$)|(^\d{5}-\d{4}$)/
                  }"
                  validate-on-blur
                  v-bind:class="{ 'is-invalid': errs.first('postal_code') }"
                  clearable v-model="shipment_local.postal_code"
                  :error-messages="errs.collect('postal_code')"
                  label="Zip Code"
                  data-vv-name="postal_code"
                ></v-text-field> -->

              <v-text-field
                v-model="shipment_local.postal_code"
                clearable
                outlined
                dense
                :error-messages="errs.collect('postal_code')"
                label="Postal Code"
                data-vv-name="postal_code"
              />
            </v-col>

            <!-- Phone -->
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-if="
                  shipment.country_code == 'CA' ||
                    shipment.country_code == 'US'
                "
                v-model="shipment_local.phone"
                v-validate
                clearable
                outlined
                dense
                :error-messages="errs.collect('phone')"
                label="Phone"
                data-vv-name="phone"
              />

              <v-text-field
                v-else
                v-model="shipment_local.phone"
                v-validate
                clearable
                outlined
                dense
                :error-messages="errs.collect('phone')"
                label="Phone"
                data-vv-name="phone"
              />
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="shipment_local.email"
                v-validate="'email'"
                clearable
                :error-messages="errs.collect('email')"
                label="Email"
                data-vv-name="email"
                outlined
                dense
              />
            </v-col>

            <v-col
              v-if="!['CA', 'US'].includes(shipment_local.country_code)"
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="shipment_local.tax_id"
                :error-messages="errs.collect('tax_id')"
                data-vv-name="tax_id"
                :items="$store.getters['main/tax_identifiers']"
                :item-text="(i) => i.nickname + `(${i.number})`"
                item-value="id"
                label="Tax ID"
                outlined
                clearable
                dense
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12">
              <h2>Package</h2>
            </v-col>

            <v-col
              cols="12"
              md="12"
            >
              <v-select
                v-model="shipment_local.package_type_id"
                v-validate="''"
                clearable
                :error-messages="errs.collect('package_type')"
                data-vv-name="package_type"
                label="Package Type"
                :items="package_array"
                item-text="description"
                item-value="id"
                dense
                outlined
              />
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-text-field
                v-model="shipment_local.length"
                v-validate="''"
                clearable
                :error-messages="errs.collect('length')"
                data-vv-name="length"
                label="Length"
                dense
                outlined
                type="number"
              />
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-text-field
                v-model="shipment_local.width"
                v-validate="''"
                clearable
                :error-messages="errs.collect('width')"
                data-vv-name="width"
                label="Width"
                dense
                outlined
                type="number"
              />
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-text-field
                v-model="shipment_local.height"
                v-validate="''"
                clearable
                :error-messages="errs.collect('height')"
                data-vv-name="height"
                label="Height"
                dense
                outlined
                type="number"
              />
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <!-- $sizeUnits from mixin.js  -->
              <v-select
                v-model="shipment_local.size_unit"
                v-validate="''"
                clearable
                :error-messages="errs.collect('size_unit')"
                data-vv-name="size_unit"
                label="Unit"
                :items="$sizeUnits()"
                dense
                outlined
              />
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-text-field
                v-model="shipment_local.weight"
                v-validate="''"
                clearable
                :error-messages="errs.collect('weight')"
                data-vv-name="weight"
                label="Weight"
                dense
                outlined
                type="number"
              />
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-select
                v-model="shipment_local.weight_unit"
                v-validate="''"
                clearable
                :error-messages="errs.collect('weight_unit')"
                data-vv-name="weight_unit"
                label="Weight Unit"
                :items="['kg', 'g', 'oz', 'lbs']"
                dense
                outlined
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <p>Total Value: {{ getCombinedValue(shipment_local?.items) }}</p>
            </v-col>
          </v-row>
          <v-row
            v-for="(item, index) in shipment_local?.items"
            :key="index"
            dense
          >
            <v-col cols="6">
              <strong>Line Item {{ index + 1 }}</strong>
            </v-col>
            <v-col
              cols="6"
              class="text-right"
            >
              <v-btn
                color="error"
                x-small
                depressed
                @click="removeItem(index)"
              >
                <v-icon small>
                  mdi-minus
                </v-icon>Remove
              </v-btn>
            </v-col>
               
            <v-col cols="12">
              <v-text-field
                v-if="shipment_local.is_fba"
                :ref="`asin_${index}`"
                v-model="item.sku"
                v-validate="'required'"
                outlined
                dense
                :error-messages="errs.collect(`asin_${index}`)"
                label="ASIN"
                :data-vv-name="`asin_${index}`"
                required
              />
              <v-text-field
                v-else
                :ref="`sku_${index}`"
                v-model="item.sku"
                v-validate="''"
                outlined
                dense
                :error-messages="errs.collect(`sku_${index}`)"
                label="SKU"
                :data-vv-name="`sku_${index}`"
                required
              />
            </v-col>
                
            <v-col cols="12">
              <v-text-field
                v-model="item.description"
                v-validate="'required'"
                outlined
                hide-details
                dense
                :error-messages="errs.collect(`description_${index}`)"
                label="Description"
                :data-vv-name="`description_${index}`"
                required
              />
            </v-col>

            <v-col cols="4">
              <v-text-field
                v-model="item.quantity"
                v-validate="'required|min_value:1|integer'"
                outlined
                hide-details
                dense
                :error-messages="errs.collect(`quantity_${index}`)"
                label="Qty"
                :data-vv-name="`quantity_${index}`"
                type="number"
                required
              />
            </v-col>
                
            <v-col cols="4">
              <v-text-field
                v-model="item.value"
                v-validate="'required|min_value:0.00'"
                outlined
                hide-details
                dense
                :error-messages="errs.collect(`value_${index}`)"
                label="Unit Value"
                :data-vv-name="`value_${index}`"
                required
              />
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="item.currency"
                v-validate="'required'"
                :error-messages="errs.collect(`currency_${index}`)"
                :data-vv-name="`currency_${index}`"
                :items="$currencyAsFlatArray()"
                dense
                outlined
                label="Currency"
                hide-details
              />
            </v-col>
            <v-col
              v-if="
                !['US', 'CA'].includes(
                  shipment_local
                    .country_code
                )
              "
              cols="12"
            >
              <v-text-field
                :ref="`customs_description_${index}`"
                v-model="item.customs_description"
                v-validate="''"
                outlined
                dense
                :error-messages="errs.collect(`customs_description_${index}`)"
                label="Customs Description"
                :data-vv-name="`customs_description_${index}`"
                required
              />
            </v-col>
            <v-col
              v-if="!['US', 'CA'].includes(shipment_local.country_code)"
              cols="6"
            >
              <v-text-field
                v-model="item.hs_code"
                v-validate="''"
                outlined
                hide-details
                dense
                :error-messages="errs.collect(`hs_code_${index}`)"
                label="HS Code"
                :data-vv-name="`hs_code_${index}`"
                required
              />
            </v-col>
            <v-col
              v-if="!['US', 'CA'].includes(shipment_local.country_code)"
              cols="6"
            >
              <v-autocomplete
                v-model="item.country_of_origin"
                v-validate="''"
                outlined
                hide-details
                dense
                :error-messages="errs.collect(`country_of_origin_${index}`)"
                :data-vv-name="`country_of_origin_${index}`"
                :items="countries"
                label="Country of Origin"
                item-text="name"
                item-value="code"
              />
            </v-col>

            <v-col
              v-if="index != shipment_local?.items.length -1"
              cols="12"
            >
              <v-divider />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <h2>Postage</h2>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="shipment_local.postage_type_id"
                v-validate="''"
                clearable
                :error-messages="errs.collect('postage_type')"
                data-vv-name="postage_type"
                :items="postage_array"
                item-text="description"
                item-value="id"
                label="Postage Type"
                dense
                outlined
              />
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="shipment_local.lowest_postage_ids"
                v-validate="''"
                clearable
                :error-messages="errs.collect('cheapest_postage')"
                data-vv-name="cheapest_postage"
                :items="postage_array"
                item-text="description"
                item-value="id"
                label="Cheapest Postage Type"
                dense
                outlined
                multiple
              />
            </v-col>

            <v-col
              v-if="$auth.user.enable_third_party_postage"
              cols="12"
              sm="6"
              md="3"
            >
              <v-select
                v-model="shipment_local.needs_postage"
                v-validate="''"
                clearable
                :error-messages="errs.collect('needs_postage')"
                data-vv-name="needs_postage"
                :items="[
                  { value: 1, text: 'Yes' },
                  { value: 0, text: 'No' }
                ]"
                label="Need Postage?"
                dense
                outlined
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-select
                v-model="shipment_local.is_fba"
                v-validate="''"
                clearable
                :error-messages="errs.collect('is_fba')"
                data-vv-name="is_fba"
                :items="[
                  { value: 1, text: 'Yes' },
                  { value: 0, text: 'No' }
                ]"
                label="Is FBA?"
                dense
                outlined
              />
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-select
                v-model="shipment_local.signature_confirmation"
                v-validate="''"
                clearable
                :error-messages="errs.collect('signature')"
                data-vv-name="signature"
                :items="[
                  { value: 1, text: 'Yes' },
                  { value: 0, text: 'No' }
                ]"
                dense
                outlined
                label="Signature"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-if="shipment_local.needs_postage == false"
                v-model="shipment_local.tracking_code"
                v-validate="''"
                clearable
                outlined
                dense
                :error-messages="errs.collect('tracking_code')"
                data-vv-name="tracking_code"
                label="Tracking Number"
              />
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-select
                v-model="shipment_local.insured"
                v-validate="''"
                clearable
                :error-messages="errs.collect('stallion_protection')"
                data-vv-name="stallion_protection"
                :items="[
                  { value: 1, text: 'Yes' },
                  { value: 0, text: 'No' }
                ]"
                dense
                outlined
                label="Stallion Protection"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="text-center"
            >
              <v-btn
                color="primary"
                :disabled="rate_loading"
                :loading="rate_loading"
                @click="getPostageRates()"
              >
                Get Rates
              </v-btn>
            </v-col>
          </v-row>
          <v-row
            v-if="shipment_local.needs_postage != false && 
              postage_rates.length > 0"
          >
            <v-col cols="12">
              <h2>Rate Estimates</h2>
            </v-col>
            <v-col cols="12">
              <rates-list
                show-total
                :postage-rates="postage_rates"
                :shipment="shipment_local"
                :update-rate-handler="updateShipmentPostage"
              />
            </v-col>
          </v-row>
        </v-form>
      </template>

      <template #actions>
        <v-btn
          v-if="shipment_local"
          color="primary"
          class="px-7"
          @click="updateShipments()"
        >
          Apply
        </v-btn>
      </template>
    </StallionDialog>
  </div>
</template>

<script>
import RatesList from './rates-list.vue';
import CONSTANTS from '@/constants';

export default {
  components: {
    RatesList
  },
  props: {
    value: {
      type: Boolean,
      default: true
    },
    shipment: {
      default: null,
      type: Object
    }
  },
  data() {
    return {
      new_dialog: false,

      countries: CONSTANTS.COUNTRIES,
      provinces: CONSTANTS.PROVINCES,
      states: CONSTANTS.STATES,
      searchInput2: null,
      searchInput: null,
      postage_rates: [],
      rate_loading: false,
      shipment_local: null,
      postage_array: [],
      package_array: []
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    show: {
      handler(newValue, oldValue) {
        if (oldValue === false && newValue === true) {
          this.shipment_local = {};
          this.shipment_local = Object.assign(
            {},
            this.shipment_local,
            this.shipment
          );
          this.postage_rates = [];
          this.postage_array = this.$store.getters['main/postage_types'](this.shipment_local.needs_postage).map(
            (p) => {
              return { id: parseInt(p.id), description: p.description };
            }
          );
          this.package_array = this.$store.getters['main/package_types'].map(
            (p) => {
              return {
                id: parseInt(p.id),
                description: p.description,
                classification: p.classification,
                package_provider: p.package_provider
              };
            }
          );
        }
      }
    }
  },
  methods: {
    updateShipments() {
      this.$http
        .put(`/shipments/${this.shipment_local.id}`, this.shipment_local)
        .then(() => {
          this.shipment_local.error = 0;
          this.shipment_local.validated = 0;
          this.$emit('update', this.shipment_local);
          this.show = false;
        })
        .catch(() => {});
    },
    reset() {
      this.$validator.reset();
      this.postage_rates = [];
    },
    close() {
      this.reset();
      this.show = false;
    },
    getPostageRates() {
      this.rate_loading = true;
      this.postage_rates = [];

      this.$http
        .post('/shipments/rates', this.shipment_local)
        .then((res) => {
          if (res.data.errors) {
            this.errorMessage(res.data.errors);
          } else {
            this.postage_rates = res.data.rates;
          }
        })
        .catch(() => {
        })
        .finally(() => {
          this.rate_loading = false;
        });
    },
    removeItem(index) {
      this.shipment_local.items.splice(index, 1);
    },
    addItem() {
      this.shipment_local.items.push({ ...CONSTANTS.SHIPMENT_ITEM });
    },
    updateShipmentPostage(postage) {
      this.shipment_local.postage_type_id = postage?.postage_type_id || null;
      this.shipment_local.rate_id = postage?.id || null;
    },
  }
};
</script>

<style scoped>
h2 {
  margin-bottom: 0px !important;
}

h4 {
  margin-bottom: 0px !important;
}
</style>
