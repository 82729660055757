<template>
  <FullPageLayout
    title="New Claim"
    :bread-crumbs="breadCrumbs"
  >
    <template #default>
      <v-card-text class="pa-0">
        <claims-form ref="refClaimsForm" />
        
        <v-col cols="12">
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="loading"
            @click="submit()"
          >
            Submit
          </v-btn>
        </v-col>
      </v-card-text>
    </template>
  </FullPageLayout>
</template>

<script>
import ClaimsForm from '@/components/main/claims/claims-form.vue';

export default {
  components: {
    ClaimsForm
  },
  props: {
    filter: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      loading: false,
      breadCrumbs: [
        {
          text: 'Claims',
          exact: true,
          to: '/claims'
        },
        {
          text: 'New Claim',
          exact: true,
          to: '/claims/new'
        }
      ]
    };
  },
  methods: {
    async submit() {
      // execute the claims form reference actions
      const refsClaimsForm = this.$refs.refClaimsForm;
      if (!(await refsClaimsForm.validate())) return;

      this.loading = true;
      refsClaimsForm
        .submit()
        .then(() => {
          this.$router.push({
            name: 'claims'
          });
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
