<template>
  <StallionDialog
    v-model="show"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    title="Upload Images"
  >
    <template #content>
      <v-card-text>
        <v-container>
          <v-layout
            justify-center
            wrap
          >
            <v-flex xs12>
              <h2>Adding images to {{ shipmentReturn.unique_id }}</h2>
            </v-flex>
          </v-layout>

          <v-layout
            justify-center
            wrap
          >
            <v-flex xs12>
              <v-card>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-data-table
                      :headers="table_headers"
                      item-key="id"
                      :items="files"
                      :items-per-page="25"
                      :footer-props="{
                        'items-per-page-options': [
                          50, 100, 200, 500
                        ]
                      }"
                      class="table-striped"
                    >
                      <template #item.thumb="{ item }">
                        <img
                          v-if="item.thumb"
                          :src="item.thumb"
                          width="40"
                          height="auto"
                        >
                        <span v-else>No Image</span>
                      </template>

                      <template #item.name="{ item }">
                        <div class="filename">
                          {{ item.name }}
                        </div>
                        <div v-if="item.active || item.progress !== '0.00'">
                          <v-progress-linear
                            v-model="item.progress"
                            color="primary"
                          />
                          {{ item.progress }}%
                        </div>
                      </template>

                      <template #item.index="{ item }">
                        {{ files.indexOf(item) }}
                      </template>
                      <template #item.size="{ item }">
                        {{ item.size | formatSize }}
                      </template>

                      <template #item.speed="{ item }">
                        {{ item.speed | formatSize }}
                      </template>

                      <template #item.status="{ item }">
                        <div v-if="item.error">
                          <v-icon>mdi-alert</v-icon>
                          {{ item.error }}
                        </div>
                        <div v-else-if="item.success">
                          <v-icon>mdi-check</v-icon>Success
                        </div>
                        <div v-else-if="item.active">
                          Active
                        </div>
                        <div v-else />
                      </template>
                      <template #item.action="{ item }">
                        <v-menu
                          open-on-hover
                          top
                          offset-y
                        >
                          <template #activator="{ on }">
                            <v-btn v-on="on">
                              Action
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item
                              :disabled="!item.active"
                              @click.prevent="
                                item.active
                                  ? $refs.upload.update(item, {
                                    error: 'cancel'
                                  })
                                  : false
                              "
                            >
                              <v-list-item-title>Cancel</v-list-item-title>
                            </v-list-item>

                            <v-list-item
                              v-if="item.active"
                              @click.prevent="
                                $refs.upload.update(item, { active: false })
                              "
                            >
                              <v-list-item-title>Abort</v-list-item-title>
                            </v-list-item>

                            <v-list-item
                              v-else-if="
                                item.error &&
                                  item.error !== 'compressing' &&
                                  $refs.upload.features.html5
                              "
                              @click.prevent="
                                $refs.upload.update(item, {
                                  active: true,
                                  error: '',
                                  progress: '0.00'
                                })
                              "
                            >
                              <v-list-item-title>
                                Retry upload
                              </v-list-item-title>
                            </v-list-item>

                            <v-list-item
                              v-else
                              :disabled="
                                item.success || item.error === 'compressing'
                              "
                              @click.prevent="
                                item.success || item.error === 'compressing'
                                  ? false
                                  : $refs.upload.update(item, { active: true })
                              "
                            >
                              <v-list-item-title>Upload</v-list-item-title>
                            </v-list-item>
                            <v-divider />
                            <v-list-item
                              @click.prevent="$refs.upload.remove(item)"
                            >
                              <v-list-item-title>Remove</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-data-table>

                    <!-- <div
              v-show="$refs.upload && $refs.upload.dropActive"
              class="drop-active"
            >
              <h2>Drop files to upload</h2>
                    </div>-->
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex
              xs12
              class="ma-5"
            >
              <file-upload
                ref="upload"
                v-model="files"
                :post-action="postAction"
                :extensions="extensions"
                :accept="accept"
                :multiple="true"
                :directory="directory"
                :thread="5"
                :headers="headers"
                :drop="true"
                :drop-directory="dropDirectory"
                :add-index="addIndex"
                @input-filter="inputFilter"
                @input-file="inputFile"
              />

              <label
                v-if="!files.length"
                :for="name"
                class="v-btn theme--light v-size--default primary v-btn--contained"
              >Select Files</label>

              <v-btn
                v-if="!$refs.upload || !$refs.upload.active"
                color="primary"
                :disabled="files.length == 0"
                @click.prevent="startUpload"
              >
                <v-icon>mdi-upload</v-icon>Start Upload
              </v-btn>

              <v-btn
                v-if="!$refs.upload || !$refs.upload.active"
                @click.prevent="reset()"
              >
                <v-icon>mdi-back</v-icon>Reset
              </v-btn>

              <v-btn
                v-else
                class="btn btn-danger"
                @click.prevent="$refs.upload.active = false"
              >
                <v-icon>mdi-stop</v-icon>Stop Upload
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </template>
  </StallionDialog>
</template>

<script>
import Cropper from 'cropperjs';
import ImageCompressor from '@xkeshi/image-compressor';
import FileUpload from 'vue-upload-component';
export default {
  components: {
    FileUpload
  },
  props: {
    shipmentReturn: {
      type: Object,
      default: null
    },
    imageUploaded: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      table_headers: [
        {
          text: '#',
          align: 'left',
          value: 'index'
        },
        {
          text: 'Thumb',
          align: 'left',
          value: 'thumb'
        },
        {
          text: 'Name',
          align: 'left',
          value: 'name'
        },
        {
          text: 'Size',
          align: 'left',
          value: 'size'
        },
        {
          text: 'Speed',
          align: 'left',
          value: 'speed'
        },
        {
          text: 'Status',
          align: 'left',
          value: 'status'
        },
        {
          text: 'Action',
          align: 'left',
          value: 'action'
        }
      ],

      files: [],
      accept: 'image/jpeg',
      extensions: 'jpg,jpeg',
      minSize: 0,
      size: 1024 * 1024 * 10,
      multiple: true,
      directory: false,
      drop: true,
      dropDirectory: true,
      addIndex: false,
      thread: 3,
      name: 'file',

      headers: {
        Accept: 'application/json'
      },
      data: {
        _csrf_token: 'xxxxxx'
      },
      autoCompress: 1024 * 1024,
      uploadAuto: false,
      isOption: false,
      addData: {
        show: false,
        name: '',
        type: '',
        content: ''
      },
      editFile: {
        show: false,
        name: ''
      },
      show: false
    };
  },
  computed: {
    postAction() {
      return `api/admin/returns/${this.shipmentReturn.id}/upload`;
    }
  },
  watch: {
    'editFile.show'(newValue, oldValue) {
      // 关闭了 自动删除 error
      if (!newValue && oldValue) {
        this.$refs.upload.update(this.editFile.id, {
          error: this.editFile.error || ''
        });
      }
      if (newValue) {
        this.$nextTick(function () {
          if (!this.$refs.editImage) {
            return;
          }
          let cropper = new Cropper(this.$refs.editImage, {
            autoCrop: false
          });
          this.editFile = {
            ...this.editFile,
            cropper
          };
        });
      }
    },
    'addData.show'(show) {
      if (show) {
        this.addData.name = '';
        this.addData.type = '';
        this.addData.content = '';
      }
    }
  },
  methods: {
    openDialog() {
      this.show = true;
    },
    startUpload() {
      this.$refs.upload.active = true;
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // 添加文件前
        // Filter system files or hide files
        // 过滤系统文件 和隐藏文件
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }
        // Filter php html js file
        // 过滤 php html js 文件
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent();
        }
        // Automatic compression
        // 自动压缩
        if (
          newFile.file &&
          newFile.type.substr(0, 6) === 'image/' &&
          this.autoCompress > 0 &&
          this.autoCompress < newFile.size
        ) {
          newFile.error = 'compressing';
          const imageCompressor = new ImageCompressor(null, {
            convertSize: Infinity,
            maxWidth: 512,
            maxHeight: 512
          });
          imageCompressor
            .compress(newFile.file)
            .then((file) => {
              this.$refs.upload.update(newFile, {
                error: '',
                file,
                size: file.size,
                type: file.type
              });
            })
            .catch((err) => {
              this.$refs.upload.update(newFile, {
                error: err.message || 'compress'
              });
            });
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        // Create a blob field
        // 创建 blob 字段
        newFile.blob = '';
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }
        // Thumbnails
        // 缩略图
        newFile.thumb = '';
        if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
          newFile.thumb = newFile.blob;
        }
      }
    },
    // add, update, remove File Event
    inputFile(newFile, oldFile) {
      if (newFile && oldFile) {
        // update
        if (newFile.active && !oldFile.active) {
          // beforeSend
          // min size
          if (
            newFile.size >= 0 &&
            this.minSize > 0 &&
            newFile.size < this.minSize
          ) {
            this.$refs.upload.update(newFile, { error: 'size' });
          }
        }
        if (newFile.progress !== oldFile.progress) {
          // progress
        }
        if (newFile.error && !oldFile.error) {
          // error
        }
        if (newFile.success && !oldFile.success) {
          // success
        }
      }
      if (!newFile && oldFile) {
        // remove
        if (oldFile.success && oldFile.response.id) {
          // $.ajax({
          //   type: 'DELETE',
          //   url: '/upload/delete?id=' + oldFile.response.id,
          // })
        }
      }
      // Automatically activate upload
      if (
        Boolean(newFile) !== Boolean(oldFile) ||
        oldFile.error !== newFile.error
      ) {
        if (this.uploadAuto && !this.$refs.upload.active) {
          this.$refs.upload.active = true;
        }
      }
    },
    alert(message) {
      alert(message);
    },
    onEditFileShow(file) {
      this.editFile = { ...file, show: true };
      this.$refs.upload.update(file, { error: 'edit' });
    },
    onEditorFile() {
      if (!this.$refs.upload.features.html5) {
        this.alert('Your browser does not support');
        this.editFile.show = false;
        return;
      }
      let data = {
        name: this.editFile.name
      };
      if (this.editFile.cropper) {
        let binStr = atob(
          this.editFile.cropper
            .getCroppedCanvas()
            .toDataURL(this.editFile.type)
            .split(',')[1]
        );
        let arr = new Uint8Array(binStr.length);
        for (let i = 0; i < binStr.length; i++) {
          arr[i] = binStr.charCodeAt(i);
        }
        data.file = new File([arr], data.name, { type: this.editFile.type });
        data.size = data.file.size;
      }
      this.$refs.upload.update(this.editFile.id, data);
      this.editFile.error = '';
      this.editFile.show = false;
    },
    // add folader
    onAddFolader() {
      if (!this.$refs.upload.features.directory) {
        this.alert('Your browser does not support');
        return;
      }
      let input = this.$refs.upload.$el.querySelector('input');
      input.directory = true;
      input.webkitdirectory = true;
      this.directory = true;
      input.onclick = null;
      input.click();
      input.onclick = () => {
        this.directory = false;
        input.directory = false;
        input.webkitdirectory = false;
      };
    },
    onAddData() {
      this.addData.show = false;
      if (!this.$refs.upload.features.html5) {
        this.alert('Your browser does not support');
        return;
      }
      let file = new window.File([this.addData.content], this.addData.name, {
        type: this.addData.type
      });
      this.$refs.upload.add(file);
    },
    reset() {
      this.files = [];
    },
    close() {
      this.show = false;
      this.imageUploaded();
      Event.fire('update-admin-returns');
    }
  }
};
</script>
