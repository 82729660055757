<template>
  <div v-if="$auth.user">
    <v-row dense>
      <v-col cols="12">
        <v-btn
          color="primary"
          @click="$refs.containerDialog.startNew()"
        >
          Create Container
        </v-btn>

        <v-btn
          v-if="$auth.user.employee_location.sort_method == 'NPI' && $auth.user.employee_location_id == 5"
          color="primary"
          @click="$refs.outputContainerDialog.startNew()"
        >
          Sorter Container
        </v-btn>

        <CreateContainerDialog
          ref="containerDialog"
          :carriers="npiCarriers"
          @update-containers="updateTable()"
        />

        <CreateOutputContainersDialog
          ref="outputContainerDialog"
          @update-containers="updateTable()"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-text-field
          v-model="output"
          outlined
          dense
          label="Output"
          type="number"
          clearable
          @input="submitSearch()"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-select
          v-model="statuses"
          outlined
          dense
          :items="['Locked', 'Open', 'Closed']"
          label="Status"
          clearable
          multiple
          @input="submitSearch()"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-select
          v-model="types"
          outlined
          dense
          :items="['Bag', 'Gaylord', 'Trailer']"
          label="Type"
          clearable
          multiple
          @input="submitSearch()"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-select
          v-model="carriers"
          outlined
          dense
          :items="npiCarriers"
          label="Carrier"
          clearable
          multiple
          @input="submitSearch()"
        />
      </v-col>
      <v-col cols="12">
        <v-data-table
          id="container-table"
          class="table-striped"
          :headers="headers"
          :items="items"
          :loading="loading"
          :server-items-length="totalItems"
          :options.sync="options"
          :footer-props="{
            'items-per-page-options': [
              50, 100, 200, 500
            ]
          }"
          @click:row="rowClick"
        >
          <template #item.created_at="{ item }">
            {{ item.created_at | datetime_short }}
          </template>

          <template #item.day_code="{ item }">
            <v-chip>
              {{ item.day_code }}
            </v-chip>
          </template>

          <template #item.name="{ item }">
            <v-icon
              v-if="item.carrier == 'USPS'"
              left
              color="#333366"
              text-color="white"
            >
              fab fa-usps
            </v-icon>

            <v-icon
              v-if="item.carrier == 'OSM'"
              color="#a2012b"
              text-color="white"
              small
              left
            >
              fas fa-shipping-fast
            </v-icon>

            <v-icon
              v-if="item.carrier == 'PostNL'"
              color="brown"
              text-color="white"
              left
            >
              fas fa-plane
            </v-icon>

            <v-icon
              v-if="item.carrier == 'APC'"
              color="#4b2682"
              text-color="white"
              left
            >
              fas fa-globe
            </v-icon>

            <v-icon
              v-if="item.carrier == 'Canada Post' || item.carrier == 'Canada Post Zone Skip'"
              color="red"
              text-color="white"
              left
            >
              fab fa-canadian-maple-leaf
            </v-icon>


            <v-icon
              v-if="item.carrier == 'FleetOptics'"
              color="primary"
              text-color="white"
              left
            >
              fas fa-horse-head
            </v-icon>

            <v-icon
              v-if="item.carrier == 'ICS'"
              color="#004b8d"
              text-color="white"
              left
            >
              fas fa-circle
            </v-icon>

            <v-icon
              v-if="item.type == 'Trailer'"
              color="black"
              text-color="white"
              left
            >
              fas fa-trailer
            </v-icon>

            <v-icon
              v-if="item.carrier == 'UPS' || item.carrier == 'UPS Mail Innovations'"
              color="red"
              text-color="white"
              left
            >
              fab fa-ups
            </v-icon>

            <v-icon
              v-if="item.carrier == 'FedEx'"
              color="purple"
              text-color="white"
              left
            >
              fab fa-fedex
            </v-icon>

            <v-icon
              v-if="item.carrier == 'UniUni'"
              color="#fe9d46"
              text-color="white"
              left
            >
              fas fa-box
            </v-icon>


              
            {{ item.name }}
          </template>


          <template #item.type="{ item }">
            <v-chip
              v-if="item.type == 'Bag'"
              color="#2196F3"
              text-color="white"
              label 
            >
              Bag
            </v-chip>
            <v-chip
              v-if="item.type == 'Gaylord'"
              color="#8D6E63"
              text-color="white"
              label
            >
              Gaylord
            </v-chip>
            <v-chip
              v-if="item.type == 'Trailer'"
              color="black"
              text-color="white"
              label
            >
              Trailer
            </v-chip>
          </template>


          <template #item.status="{ item }">
            <v-chip
              v-if="item.status == 'Locked'"
              color="red"
              text-color="white"
            >
              Locked
            </v-chip>

            <v-chip
              v-if="item.status == 'Open'"
              color="green"
              text-color="white"
            >
              Open
            </v-chip>

            <v-chip
              v-if="item.status == 'Closed'"
              color="grey"
              text-color="white"
            >
              Closed
            </v-chip>
          </template>

          <template #item.actions="{ item }">
            <v-btn
              small
         
              
              icon
              @click.stop="deleteContainer(item.id)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-btn
              small
              :loading="printLoading"
              
              icon
              @click.stop="print(item.id)"
            >
              <v-icon>mdi-printer</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <ContainerProcessDialog
      ref="containerProcessDialog"
      @update-containers="updateTable()"
    />
    <!-- 
    <container-dialog
      ref="boxDialog"
      @update-boxes="updateTable()"
    /> -->
  </div>
</template>
<script>
import CreateContainerDialog from '@/pages/admin/npi/CreateContainerDialog.vue';
import CreateOutputContainersDialog from './CreateOutputContainersDialog.vue';
import ContainerProcessDialog from './ContainerProcessDialog.vue';
export default {
  components: {
    CreateContainerDialog,
    ContainerProcessDialog,
    CreateOutputContainersDialog
  },
  data() {
    return {
      container: {},
      show_edit: false,
      loading: false,
      items: [],
      carriers: [],
      headers: [
      { text: 'Output', value: 'output'},
      { text: 'Name', value: 'name'},
      { text: 'Type', value: 'type'},
      // { text: 'Sort Number', value: 'sort_number'},
      { text: 'Status', value: 'status' },
    
      { text: 'Day Code', value: 'day_code'},
      
        { text: 'Created At', value: 'created_at'},
        { text: 'Actions', value: 'actions', sortable: false, align: 'right' }
      ],
      options: {
        sortBy: ['created_at'],
        sortDesc: [false],
        itemsPerPage: 100,
      },
      totalItems: 0,
      cancelSource: null,
      search: null,
      timer: null,
      statuses: ['Locked', 'Open'],
      types: ['Bag', 'Gaylord'],
      printLoading: false,
      output: null,
      npiCarriers: [],
    };
  },
  computed:{
    params() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      return {
        page: page,
        per_page: itemsPerPage,
        sort_by: sortBy[0],
        desc: sortDesc[0],
        search: this.search,
        statuses: this.statuses,
        types: this.types,
        carriers: this.carriers,
        output: this.output,
      };
    }
  },
  watch: {
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    },
    'options.itemsPerPage': function (newVal, oldVal) {
      if (oldVal && newVal) {
        const preference = {
          table_rows: {
            [this.tablePreferenceKey]: newVal
          }
        };
        this.$store.dispatch('main/setPreferences', preference);
      }
    },

  },

  mounted() {
    this.updateTable();
    this.loadNpiCarrier();
  },
  methods: {
    rowClick(item) {
      this.$refs.containerProcessDialog.onBarcodeScanned(item.barcode);
    },
    print(id) {
      this.printLoading = true;
      this.$http
        .request(`/admin/containers/${id}/print`, {
          method: 'get',
        })
        .then((response) => {
          this.printUrl(response.data, true);
        }).finally(() => {
          this.printLoading = false;
        });
    },
    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.options.page = 1;
      this.timer = setTimeout(function () {
        self.updateTable();
      }, ms);
    },
    updateTable() {
      // this.selected = [];
      this.totalItems = 0;
      // this.items = [];

      this.getDataFromApi().then((data) => {
        this.items = data.items;
        this.totalItems = data.total;
      });
    },
    getDataFromApi() {
      this.loading = true;
      return new Promise((resolve) => {
        // const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let items = [];
        let total = 0;
        let pages = 0;

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        this.$http
          .request(`/admin/containers`, {
            method: 'get',
            params: this.params,
            cancelToken: this.cancelSource.token
          })
          .then((response) => response.data)
          .then((response) => {
            items = response.data;
            total = response.total;
            pages = response.last_page;

           
            this.loading = false;
            resolve({
              items,
              total,
              pages
            });
          });
      }).finally(() => {
        this.loading = false;
      });
    },
    async deleteContainer(id) {
      const isConfirm = await swal({
        title: 'Confirm Delete',
        text: 'Do you really want to delete this container?',
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      });

      if (isConfirm) {
        this.$http
          .request(`/admin/containers/${id}`, {
            method: 'delete',
           
          })
        
          .then(() => {
           this.updateTable();
          })
          .catch((err) => {
          this.onError(err);
        });
      }
    },
    onError(err) {
      if (
        err.response.status === 422 &&
        typeof err.response.data.errors !== 'undefined'
      ) {
        const errors = err.response.data.errors;
        let message = '';

        for (var field in errors) {
          message += `${errors[field][0]}\n`;
        }

        this.errorMessage(message);
      }
    },
    showUpdate(container) {
      this.show_edit = true;
      this.container = container;
    },
    showCreate() {
      this.show_edit = true;
      this.container = null;
    },
    async loadNpiCarrier() {
      this.$http
          .get(`/admin/npi/carriers`)
          .then((data) => {
            this.npiCarriers = data.data;
          })
          .catch((err) => {
            this.onError(err);
          });
    },

  }
};
</script>
<style>
#container-table tbody tr:hover {
cursor: pointer;
}
</style>