<template>
  <FullPageLayout title="Pickups">
    <template #default>
      <v-container>
        <!-- Types of Pickups -->
        <v-row>
          <v-col
            cols="12"
            md="4"
            class="pl-0 pr-4 py-1"
          >
            <v-card
              outlined
              hover
              class="px-5 py-3 fill-height mx-auto"
              @click="upsPickup"
            >
              <v-list-item class="px-0">
                <v-list-item-avatar
                  size="40"
                  tile
                  class="mt-3 mb-auto"
                >
                  <v-img :src="asset('images/ups-simple-logo.svg')" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <h4>Book UPS Pickup</h4>
                  <p class="pt-2 description-text">
                    Consolidate your items in ready-to-ship condition, and UPS
                    will pick up from you!
                  </p>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>

        
          <v-col
            cols="12"
            md="4"
            class="pl-0 pr-4 py-1"
          >
            <a
              style="text-decoration: none"
              href="mailto:hello@stallionexpress.ca?subject=Stallion Direct Pickup"
            >
              <v-card
                outlined
                hover
                class="px-5 py-3 fill-height mx-auto"
              >
                <v-list-item class="px-0">
                  <v-list-item-avatar
                    size="40"
                    tile
                    class="mt-2 mb-auto"
                  >
                    <v-img :src="asset('images/stallion-simple-logo-black.svg')" />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <h4>Stallion Direct Pickup</h4>
                    <p class="pt-2 description-text">
                      Looking for daily pickups from Stallion Express? Tell us
                      your location and weekly volume!
                    </p>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </a>
          </v-col>
        </v-row>
      </v-container>
      <PickupTable
        v-model="selected"
        :filter="filter"
        :types="['UPS']"
      />

      <PrintPickupDialog
        v-model="showPrintDialog"
        :pickups="selected"
      />

      <CancelPickupDialog
        v-model="showCancelDialog"
        :pickups="selected"
      />
        
      <UpsPickupDialog />
      <FbaPickupDialog />
    </template>
    <template #footer>
      <v-btn
        outlined
        color="primary"
        :disabled="!pickup_printable"
        @click="showPrintDialog = true"
      >
        <v-icon
          left
          small
        >
          mdi-printer
        </v-icon>

        Print
      </v-btn>
      <!-- <v-btn
        color="primary"
        @click="showCancelDialog = true"
        :disabled="!cancelable"
        >Cancel</v-btn
      >-->
    </template>
  </FullPageLayout>
</template>

<script>
import UpsPickupDialog from '@/components/main/pickups/UpsPickupDialog.vue';
import PrintPickupDialog from '@/components/main/pickups/PrintPickupDialog.vue';
import PickupTable from '@/components/main/pickups/PickupTable.vue';
import CancelPickupDialog from '@/components/main/pickups/CancelPickupDialog.vue';

export default {
  metaInfo: {
    title: "Pickups | Stallion Express"
  },
  components:{
    UpsPickupDialog,
    PrintPickupDialog,
    PickupTable,
    CancelPickupDialog
  },
  props: {
    filter: {
      default: null,
      type: String
    }
  },

  data() {
    return {
      showCancelDialog: false,
      showPrintDialog: false,

      selected: [],
      drawer: null
    };
  },
  computed: {
    filter_formated() {
      return this.$options.filters.capitalize(this.$route.query.filter);
    },

    pickup_printable() {
      if (
        this.selected.length != 0 &&
        this.selected.every((r) => r.has_label === 1)
      ) {
        return true;
      } else {
        return false;
      }
    },
    cancelable() {
      if (
        this.selected.length != 0 &&
        this.selected.every((r) =>
          moment(moment().format('YYYY-MM-DD')).isBefore(
            r.requested_pickup_date
          )
        ) &&
        this.selected.every((r) => r.type == 'UPS')
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    upsPickup: function () {
      this.$store.state.main.ups_request_dialog = true;
    },
  }
};
</script>
