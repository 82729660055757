<template>
  <StallionDialog
    v-model="show"
    :width="500"
    persistent
    :disabled="disabled"
    title="Print Product Pick List"
  >
    <template #content>
      <v-layout v-if="loading">
        <v-flex
          xs12
        >
          <div
            class="loading-content"
            style="margin: auto"
          >
            <atom-spinner
              style="margin: auto"
              :animation-duration="1000"
              :size="100"
              :color="'#f9ac40'"
            />
            <div class="dialog-info">
              Generating your product pick
              {{ items.length | pluralize('list') }}
            </div>
          </div>
        </v-flex>
      </v-layout>

      <v-layout
        v-else
        wrap
      >
        <v-flex
          xs12
          text-center
        >
          <div class="dialog-info">
            Pick list for {{ items.length }} {{ items.length | pluralize('order') }} will be generated
          </div>
        </v-flex>
        <v-flex
          v-if="items.length > 1"
          xs12
        >
          <v-select
            v-model="sort_by"
            label="Sort By"
            :items="sort_items"
          />
        </v-flex>
        <v-flex
          v-if="items.length > 1"
          xs12
        >
          <v-select
            v-model="sort_order"
            label="Sort Order"
            :items="sort_order_items"
          />
        </v-flex>
      </v-layout>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        download
        outlined
        :disabled="loading"
        @click.prevent="getPickList('download')"
      >
        Download
      </v-btn>

      <v-btn
        color="primary"
        download
        :disabled="loading"
        @click.prevent="getPickList('print')"
      >
        Print
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
import { AtomSpinner } from 'epic-spinners';

export default {
  components: {
    AtomSpinner
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    disabled: {
      default: false,
      type: Boolean
    },
    value: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'shipments'
    }
  },

  data() {
    return {
      loading: false,
      sort_items: [
        { text: 'Created At', value: 'id' },
        { text: 'Order ID', value: 'order_id' },
        { text: 'SKU', value: 'sku' },
      ],
      sort_order_items: [
        { text: 'Descending', value: 'desc' },
        { text: 'Ascending', value: 'asc' }
      ],
      sort_by_sku_obj: { text: 'SKU', value: 'sku' },
      sort_by: 'id',
      sort_order: 'asc',
      label_name: null,
      status: null,
      max_ship: 100,
      name: null
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  mounted() {
    this.updateSortByOptions()
  },

  methods: {
    getPickList: function (action = 'download') {
      this.loading = true;
      var ids = [];
      this.items.forEach(function (item) {
        ids.push(item.id);
      });

      this.$http
        .post(
          `pick-locations/print/${this.type}`,
          {
            ids: ids,
            sort_by: this.sort_by,
            sort_order: this.sort_order
          }
        )
        .then((response) => {
          if (action == 'print') {
            this.printUrl(response.data, true);
          } else {
            this.downloadFileUrl(response.data, 'StallionPickList.pdf');
          }
        })
        .catch(() => {
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateSortByOptions() {
      this.type == 'shipments' ? this.sort_items.push(this.sort_by_sku_obj) : ''
    },

    setPrintingData(printingSettings = {}) {
      console.log('printingSettings', printingSettings);

      ['sort_by', 'sort_order'].forEach(key => {
        if (printingSettings[key] !== undefined) {
          this[key] = printingSettings[key];
        }
      });
    },
  }
};
</script>