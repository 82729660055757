<template>
  <v-card flat>
    <v-container class="px-0 py-0">
      <v-row>
        <v-col md="6">
          <p class="mb-2">
            Add/Edit addresses that you can use to quickly fill out forms.
          </p>
        </v-col>
        <v-col md="6">
          <v-row class="float-right">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                  class="mx-2"
                  color="primary"
                  depressed
                  outlined
                  :disabled="loading"
                  v-on="on"
                  @click="openAddressDialog"
                >
                  <v-icon small>
                    mdi-plus
                  </v-icon>Add Address
                </v-btn>
              </template>
              <span>Add Address</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>

      <AddressTable
        ref="addressTable"
        :show-select="false"
      />
    </v-container>
  </v-card>
</template>

<script>
import AddressTable from './AddressTable.vue';

export default {
  components: {
    AddressTable
  },
  data(){
    return {
      loading: false,
    }
  },
  methods: {
    openAddressDialog() {
      this.$refs.addressTable.$refs.addressDialog.startNew();
    }
  }
}
</script>
