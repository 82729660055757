<template>
  <StallionDialog
    v-model="show"
    :width="500"
    :disabled="disabled"
    persistent
    title="Bulk Edit Supplies"
  >
    <template #content>
      <v-card-text>
        <v-layout column>
          <v-flex
            xs12
            text-center
          >
            <v-checkbox
              v-model="enabled"
              label="Enabled"
            />
          </v-flex>
        </v-layout>
      </v-card-text>
    </template>

    
    <template #actions>
      <v-btn
        color="primary"
        :loading="update_loading"
        :disabled="update_loading"
        @click="updateStatuses()"
      >
        Update
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>

export default {
  props: {
    supplies: {
      type: Array,
      default: () => []
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      show: false,
      update_loading: false,
      enabled: true
    };
  },
  methods: {
    openDialog() {
      this.show = true
    },
    updateStatuses: function () {
      this.update_loading = true;

      // var ids = [];

      this.$http
        .post('/admin/supplies/bulk-edit', {
          enabled: this.enabled,
          ids: this.supplies.map(item => item.id)
        })
        .then(() => {
          this.$notify({
            group: 'main',
            title: 'Item Updated',
            text: `The supply item has been updated`,
            duration: 5000
          });

          Event.fire('update-supplies');
        })
        .finally(() => {
          this.update_loading = false;
          this.show = false;
        });
    },
    close() {
      this.show = null;
      Event.fire('update-supplies');
    }
  }
};
</script>
