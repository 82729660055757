<template>
  <div>
    <div>
      <v-breadcrumbs
        large
        :items="list"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menu: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      list: [
        {
          text: 'Claims',
          exact: true,
          to: '/admin/claims'
        }
      ]
    };
  },
  created() {
    if (this.menu) {
      this.list.push(this.menu);
    }
  }
};
</script>
