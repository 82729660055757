<template>
  <FullPageLayout title="Postage Restrictions">
    <template #default>
      <postage-table />
    </template>
  </FullPageLayout>
</template>
    
<script>
import PostageTable from './PostageTable.vue'
export default {
  components: {
    PostageTable
  },
  data() {
    return {
    }
  },
  mounted() {

  },
  methods: {
  
  }
};
</script>

