import Vue from 'vue';
import VueAxios from 'vue-axios';
import VueRouter from 'vue-router';
import axios from 'axios';
Vue.axios = window.axios;

import mainroutes from './main.js';
import adminroutes from './admin.js';
import authroutes from './auth.js';
import portalRoutes from './portal.js';
import store from '../store';

Vue.use(VueRouter);
Vue.use(VueAxios, axios);

const notFound = [
  {
    path: '/403',
    name: '403',
    component: () =>
      import(
        '../pages/shared/403.vue'
      ),
    meta: {
      layout: 'auth-layout'
    }
  },
  {
    path: '*',
    name: '404',
    component: () =>
      import(
        '../pages/shared/404.vue'
      ),
    meta: {
      layout: 'auth-layout'
    }
  },

];
const routes = [
  ...portalRoutes,
  ...adminroutes,
  ...authroutes,
  ...mainroutes,
  ...notFound
];

const router = new VueRouter({
  routes, // short for routes: routes
  mode: 'history' // removes # from routes
});

Vue.router = router;
// if (
//   window.location.hostname !== 'localhost' &&
//   window.location.hostname !== '127.0.0.1'
// ) {
// console.log(window.location.hostname);

// }
function checkUserReturnAgreement() {
  if(store.state.auth.user.return_agree !== null) {
    return false;
  }
  if(store.state.auth.user.poa_sign_by_date == null){
    return true;
  } else {
      let currentDate = new Date();
      let targetDate = new Date(store.state.auth.user.poa_sign_by_date);
      if(currentDate>targetDate){
        return true;
      } else {
        return false;
      }
    }
}
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);

  if (to.matched.some((record) => record.meta.auth)) {
    if (to.path == 'shopify-login') {
      next();
    } else if (!store.state.auth.authenticated) {
      next({
        path: '/login'
      });
    } else if (
      store.state.auth.user.is_two_factor_required &&
      !store.state.auth.user.has_two_factor_enabled
    ) {
      next({
        path: '/two-factor-auth'
      });
    } else if (to.matched.some((record) => record.meta.reg)) {
      next();
    } else if (!store.state.auth.user.account_setup) {
      next({
        path: '/account-setup'
      });
    } else if (!store.state.auth.user.terms_agree) {
      next({
        path: '/terms-and-conditions'
      });
    } else if (!store.state.auth.user.verified) {
      next({
        path: '/send-verification'
      });
    } 
    // else if (
    //   !store.state.auth.user.phone_verified
    // ) {
    //   next({
    //     path: '/verify-phone'
    //   });
    // } 
    else if(store.state.auth.user.locked == 1) {
      next({
        path: '/locked'
      });
    } else if(store.state.auth.user.approval_status != 'Approved') {
      next({
        path: '/approval-status'
      });
    } else if (
      to.matched.some((record) => record.name == 'dashboard') &&
        checkUserReturnAgreement()
    ) {
      if (from.path !== '/returns-agreement') {
        next({
          path: '/returns-agreement'
        });
      }
    } else if (
      to.matched.some((record) => record.meta.permission) &&
      !store.state.auth.user.enabled_permissions.includes(to.meta.permission)
    ) {
      next({
        path: '/'
      });
    } else {
      next();
    }
  } else if (
    store.state.auth.authenticated &&
    ['/login', '/forgot-password', '/register'].includes(to.path)
  ) {
    next({
      path: '/'
    });
  } else {
    next();
  }
});

// force reload on dynamic import error
router.onError((error, to) => {
  if (
    error.message.includes('Failed to fetch dynamically imported module') ||
    error.message.includes('Importing a module script failed')
  ) {
    if (!to?.fullPath) {
      window.location.reload();
    } else {
      window.location = to.fullPath;
    }
  }
});

export default router;
