<template>
  <v-container>
    <v-flex xs12>
      <h1>Sort Returns</h1>
    </v-flex>

    <v-layout>
      <v-flex
        xs12
        md6
        offset-md3
      >
        <!-- loading dialog -->
        <v-dialog
          v-model="dialog2"
          persistent
          width="300"
        >
          <v-card dark>
            <v-card-text>
              Getting Users
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              />
            </v-card-text>
          </v-card>
        </v-dialog>

        <!-- Filter -->
        <v-switch
          v-model="filterScannedItems"
          label="Filter Only My Scanned Items"
        />
        <v-card
          v-if="items.length > 0"
          outlined
        >
          <!-- List -->
          <v-list
            two-line
            class="pa-0"
          >
            <template v-for="(item, index) in items">
              <v-list-item
                v-if="item.user"
                :key="item.user.id"
                ripple
              >
                <v-list-item-avatar @click="select(item)">
                  <v-icon size="50">
                    account_circle
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content @click="select(item)">
                  <v-list-item-title>
                    {{ item.user.business_name || item.user.name }} -
                    {{ item.user.id }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    v-html="item.shipments.length + ' Return Shipments'"
                  />

                  <v-list-item-subtitle
                    v-if="item.scanned_users && item.scanned_users.length > 0"
                  >
                    Last Scanned By: {{ item.scanned_users.join(', ') }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn
                    icon
                    ripple
                    @click="
                      selected = item;
                      dialog3 = true;
                    "
                  >
                    <v-icon color="grey lighten-1">
                      info
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider
                v-if="index != items.length - 1"
                :key="index"
                class="ma-0"
              />
            </template>
          </v-list>
        </v-card>
        <v-alert
          v-else-if="!dialog2 && items.length == 0"
          class="mt-5"
          outlined
          type="success"
        >
          Nothing to sort
        </v-alert>
      </v-flex>

      <!-- sort dialog -->
      <v-dialog
        v-if="selected"
        v-model="dialog"
        max-width="400"
        persistent
      >
        <v-card>
          <v-card-title class="text-h3">
            Assign {{ selected.business_name || selected.name }}
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-container>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-select
                      v-model="box"
                      v-validate="'required'"
                      :items="boxes"
                      label="Box Name"
                      outlined
                      item-text="name"
                      item-value="name"
                      :error-messages="errs.collect('box')"
                    />
                  </v-flex>
                  <v-flex xs6>
                    <v-select
                      v-model="type"
                      v-validate="'required'"
                      :items="types"
                      label="Bags"
                      outlined
                      data-vv-name="bags"
                      :error-messages="errs.collect('bags')"
                    />
                  </v-flex>
                  <v-flex xs6>
                    <v-select
                      v-model="bags"
                      v-validate="'required'"
                      :items="[
                        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
                        17, 18, 19, 20, 21, 22, 23, 24, 25
                      ]"
                      label="Bags"
                      outlined
                      data-vv-name="bags_count"
                      :error-messages="errs.collect('bags_count')"
                    />
                  </v-flex>
                  <v-flex xs12>
                    <v-textarea
                      v-model="comment"
                      outlined
                      label="Comment"
                    />
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              text="text"
              @click="dialog = false"
            >
              Cancel
            </v-btn>
            <v-spacer />
            <v-btn
              color="primary"
              @click="setBox(selected)"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- fullscreen -->
      <v-dialog
        v-model="dialog3"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark>
            <v-btn
              icon
              @click="dialog3 = false"
            >
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>Return Shipments</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn
                dark
                text
                @click="select(selected)"
              >
                Sort
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-data-table
            :items="selected.shipments"
            hide-headers
            :headers="headers"
            :footer-props="{
              'items-per-page-options': [50, 100, 200, 500]
            }"
          >
            <template #item.created_at="{ item }">
              {{ item.created_at | date_pretty }}
              <br>
              ({{ item.created_at | datetime_from }})
            </template>

            <template #item.status_id="{ item }">
              <div
                v-html="
                  $options.filters.return_status(item.status_id)
                "
              />
            </template>

            <template #item.current_location_id="{ item }">
              <div
                v-html="
                  $options.filters.location(item.current_location_id)
                "
              />
            </template>

            <template #item.destination_location_id="{ item }">
              <div
                v-html="
                  $options.filters.location(item.destination_location_id)
                "
              />
            </template>
          </v-data-table>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>

export default {
  props: {
    sortType: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      filterScannedItems: true,
      items: [],
      selected: {
        shipments: []
      },
      headers: [
        {
          text:'Current Location',
          value:'current_location_id'
        },
        {
          text:'Destination Location',
          value:'destination_location_id'
        },
        {
          text: 'Contents',
          value: 'item'
        },
        {
          text: 'Status',
          value: 'status_id'
        },
        {
          text: 'Created',
          value: 'created_at'
        }
      ],

      dialog: false,
      dialog2: false,
      dialog3: false,
      boxes: [],

      types: ['Bag', 'Box', 'Mix', 'Skid'],
      type: 'Bag',

      box: null,
      comment: null,
      bags: 1,
    };
  },
  watch: {
    sortType: {
      handler() {
        this.getUsers();
        this.setOptions();
      },
      deep: true
    },
    filterScannedItems: {
      handler() {
        this.getUsers();
      }
    }
  },
  mounted() {
    this.getUsers();
    this.getBox();
  },
  methods: {
    getUsers() {
      let unsortedUrl = '/admin/return-sorts/scanned';
      if(this.filterScannedItems) unsortedUrl = '/admin/return-sorts/scanned?filter=true';
      this.dialog2 = true;
      this.$http
          .get(
              unsortedUrl
          )
          .then((response) => response.data)
          .then((response) => {
            this.items = response;
          })
          .finally(() => {
            this.dialog2 = false;
          });
    },
    getBox() {
      this.$http
          .get(`/admin/return-sorts/box-location`)
          .then((response) => response.data)
          .then((response) => {
            this.boxes = response;
          });
    },
    setBox(item) {
      this.$validator.validate().then((result) => {
        if (result) {
          var ids = item.shipments.map((x) => x.id);
          this.$http
              .post(`/admin/return-sorts/sort`, {
                ids,
                box: this.box,
                comment: this.comment,
                bags: this.bags,
                type: this.type,
              })
              .then((response) => response.data)
              .then(() => {
                this.$notify({
                  group: 'main',
                  title: 'Return Shipments Sorted',
                  text: `${
                      item.user.business_name || item.user.name
                  } has been assigned to a box`,
                  duration: 4000
                });

                var index = this.items.indexOf(item);
                if (index !== -1) this.items.splice(index, 1);
                this.selected = { shipments: [] };
              })
              .catch(() => {})
              .finally(() => {
                this.dialog = false;
              });
        }
      });
    },
    select(item) {
      this.dialog = true;
      this.selected = item;
    },
  }
};
</script>
