<template>
  <div
    v-if="shipment && shipment.name"
  >
    <div class="d-flex flex-row justify-space-between">
      <h4 v-if="showHeader">
        Recipient
      </h4>
      <!--can from mixin.js-->
      <span
        v-if="canEdit"
      >
        <a
          class="float-right"
          href="javascript:void(0)"
          @click="editShipment(shipment, 'recipient')"
        >Edit</a>
      </span>
    </div>

    <div>{{ shipment.name }}</div>
    <div v-if="shipment.company">
      {{ shipment.company }}
    </div>
    <div>{{ shipment.address1 }}</div>
    <div v-if="shipment.address2">
      {{ shipment.address2 }}
    </div>
    <div>
      {{
        shipment.city +
          ', ' +
          shipment.province_code +
          ' ' +
          shipment.postal_code
      }}
    </div>
    <div v-if="shipment.country_code">
      {{ shipment.country_code }}
    </div>
    <div v-if="shipment.phone">
      Phone: {{ shipment.phone }}
    </div>
    <div
      v-if="shipment.email"
      class="mt-3"
    >
      {{ shipment.email }}
    </div>

    <v-divider
      v-if="showDivider"
      class="details-divider"
    />
  </div>
</template>

<script>
export default {
  props: {
    shipment: {
      type: Object,
      default: null
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    showDivider: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    canEdit() {
      if (!this.shipment || !this.can('CLIENT.SHIPPING')) {
        return false
      }

      // only admin can edit shipments that are in ready status
      if (this.shipment.status_id == 4 && this.can('access.admin') && (this.shipment.is_fba || !this.shipment.needs_postage)) {
        // 4 = Ready
        return true
      } else if (this.shipment.status_id == 3) {
        // 3 = In Complete
        return true
      }

      return false
    }
  },
};
</script>
