function initialState() {
  return {
    qzStatus: false,
    currentScaleWeight: {},
    currentScale: {},
    currentScaleStream: false,
    currentPrinter: '',
    printerFormat: '',
    currentDocType: '',
    isQuickShip: false,
    notified: false,
    certificate: '',
    enabledAutoWeightFetch: false,
    autoReconnect: false,
    isScaleLocked: false,
  };
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },
    setQzStatus(state, status) {
      state.qzStatus = status;
    },
    setCurrentScaleWeight(state, weight) {
      state.currentScaleWeight = weight;
    },
    updateCurrentScale(state, scale) {
      state.currentScale = scale;
    },
    updateCurrentPrinter(state, printerData) {
      state.currentPrinter = printerData.printer;
      state.printerFormat = printerData.format || 'PDF';
    },
    setQuickShipStatus(state, status) {
      state.isQuickShip = status;
    },
    updateCertificate(state, certificate) {
      state.certificate = certificate;
    },
    setCurrentScaleStream(state, streamStatus) {
      state.currentScaleStream = streamStatus;
    },
    updateNotified(state, notified) {
      state.notified = notified;
    },
    setEnabledAutoWeightFetch(state, enabled) {
      state.enabledAutoWeightFetch = enabled;
    },
    setEnabledAutoReconnect(state, enabled) {
      state.autoReconnect = enabled;
    },
    setCurrentDocType(state, docType) {
      state.currentDocType = docType;
    },
    setScaleLock(state, lockStatus) {
      state.isScaleLocked = lockStatus;
    },
  },
  actions: {
    activateQz({ commit }) {
      commit('setQzStatus', true);
    },
    deactivateQz({ commit }) {
      commit('setQzStatus', false);
    },
    updateCurrentScaleWeight({ commit }, weight) {
      commit('setCurrentScaleWeight', weight);
    },
    updateCurrentScale({ commit }, scale) {
      commit('updateCurrentScale', scale);
    },
    updateCurrentPrinter({ commit }, printerData) {
      commit('updateCurrentPrinter', printerData);
    },
    updateQuickShipStatus({ commit }, status) {
      commit('setQuickShipStatus', status);
    },
    updateCertificate({ commit }, certificate) {
      commit('updateCertificate', certificate);
    },
    updateCurrentScaleStream({ commit }, streamStatus) {
      commit('setCurrentScaleStream', streamStatus);
    },
    updateNotified({ commit }, notified) {
      commit('updateNotified', notified);
    },
    updateEnabledAutoWeightFetch({ commit }, enabled) {
      commit('setEnabledAutoWeightFetch', enabled);
    },
    updateAutoReconnect({ commit }, enabled) {
      commit('setEnabledAutoReconnect', enabled);
    },
    updateCurrentDocType({ commit }, docType) {
      commit('setCurrentDocType', docType);
    },
    setScaleLock({ commit }, lockStatus) {
      commit('setScaleLock', lockStatus);
    },
  }
};
