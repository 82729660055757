<template>
  <FullPageLayout 
    :title="`Transactions - ${$options.filters.humanize($route.params.transactional_type)} ${$route.params.transactional_id}`"
  >
    <!-- <div>
      <v-breadcrumbs
        large
        :items="[
        {
          text: 'Home',
          exact: true,
          to: '/dashboard',
        },
        {
          text: 'Credits',
          exact: true,
          to: '/credits',
        },
     
      ]"
      ></v-breadcrumbs>
    </div> -->
    <template #default>
      <v-layout
        wrap
        class="mt-5"
      >
        <v-flex xs12>
          <transaction-table
            v-if="$auth.user"
            :transactional-type="$route.params.transactional_type"
            :transactional-id="$route.params.transactional_id"
            :show-search="false"
            :show-summary="false"
            :user-id="$auth.user.id"
          />
        </v-flex>
      </v-layout>
      <v-layout
        v-if="$route.params.transactional_type == 'shipment'"
        wrap
        class="mt-5"
      >
        <v-flex
          xs12
          class="mb-3"
        >
          <h3>Shipment</h3>
        </v-flex>

        <v-flex
          xs12
          md6
        >
          <v-card outlined>
            <view-shipment-details
              :shipment-id="$route.params.transactional_id"
            />
          </v-card>
        </v-flex>
      </v-layout>
    </template>
  </FullPageLayout>
</template>

<script>
import moment from 'moment';
export default {
  filters: {
    relativeTime: function (value) {
      return moment(value, 'YYYY-MM-DD hh:mm:ss').fromNow();
    }
  },
  data() {
    return {
      dense: null,
      rounded: null,
      text: null,
      s_show: false,

      csrf: '',

      paymentMethod: [],
      cardBrand: '',

      reloadSettings: {
        status: false,
        threshold_credits: null,
        additional_credits: null
      },
      reloadSettingisUpdating: false
    };
  },
  computed: {
    hasCard() {
      return this.$auth.user.card_last_four;
    },
    last4() {
      return this.$auth.user.card_last_four;
    }
  },
  mounted() {
    Event.listen('payment-completed', () => {
      Event.fire('get-credits');
    });

    Event.listen('card-created', () => {
      this.getUser();
    });
    this.getPaypalPaymentStatus();
    this.syncreload_credit_settings();
  },
  methods: {
    syncreload_credit_settings() {
      if (this.$auth.user.reload_credit_setting) {
        this.reloadSettings = this.$auth.user.reload_credit_setting;
      }
    },
    updateReloadCreditsSettings() {
      this.reloadSettingisUpdating = true;
      this.$http
        .post('user/reload-settings', this.reloadSettings)
        .then((res) => res.data)
        .then(() => {
          this.reloadSettingisUpdating = false;

          swal({
            title: 'Success!',
            text: 'Your auto reload Setting has been updated',
            icon: 'success'
          });
        })
        .catch(() => (this.reloadSettingisUpdating = false));
    },

    removePaymentMethod() {
      var self = this;
      swal({
        title: 'Are you sure?',
        text: 'Are you sure you want to remove this card from your account?',
        icon: 'warning',
        buttons: ['Cancel', 'Remove']
      }).then(function (isConfirm) {
        if (isConfirm) {
          self.$http
            .post('credit-card/remove')
            .then((res) => {
              if (res.data.success) {
                self.$auth.fetch({
                  params: {},
                  success: function () {},
                  error: function () {}
                  // etc...
                });

                swal({
                  title: 'Success!',
                  text: 'Your card has been removed from your account.',
                  icon: 'success'
                });
              } else {
                swal({
                  title: 'Error!',
                  text: res.data.error,
                  icon: 'error'
                });
              }
            })
            .catch(() => {
              swal({
                title: 'Error!',
                text: 'Error removing your card.',
                icon: 'error'
              });
            });
        }
        swal.close();
      });
    },

    getPaypalPaymentStatus() {
      if (this.$route.query.success !== undefined) {
        if (this.$route.query.success == 'true') {
          this.processing = true;
          this.$http
            .post('/paypal/express-checkout', {
              payerID: this.$route.query.PayerID,
              paypal_token: this.$route.query.token,
              paymentID: this.$route.query.paymentId,
              _token: this.csrf
            })
            .then((res) => {
              if (res.data.success) {
                this.$analytics.fbq.event('AddCredits', {
                  currency: 'CAD',
                  value: res.data.amount
                });

                Event.fire('payment-completed');

                swal({
                  title: 'Success!',
                  text: 'Your credits have been added successfully.',
                  icon: 'success'
                });

                this.$router.push('/credits');
              } else {
                swal({
                  title: 'Error!',
                  text: 'Paypal payment not successful',
                  icon: 'error'
                });
              }
            })
            .catch((err) => {
              swal({
                title: 'Error!',
                text: err,
                icon: 'error'
              });
            });
        } else {
          setTimeout(function () {
            swal('Error!', 'Paypal payment authentication failed ');
          }, 1000);
        }
      }
    }
  }
};
</script>

<style scoped>
h3 {
  margin-bottom: 0px !important;
}

.v-application p {
  margin-bottom: 0px !important;
}
</style>
