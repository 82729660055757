<template>
  <div>
    <v-row dense>
      <v-col cols="12">
        <v-btn
          color="primary"
          dark
          @click="$refs.ruleDialog.startNew()"
        >
          Create Rule
        </v-btn>
      </v-col>

      <v-col cols="12">
        <p class="mt-3">
          These rules will be run in sequence. You can reorder them by dragging
          them to the desired location.
        </p>

        <v-btn
          outlined
          color="primary"
          class="float-right"
          dark
          @click="details = !details"
        >
          {{ details ? 'Hide Details' : 'Show Details' }}
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-data-table
          class="table-striped"
          :headers="headers"
          :items="items"
          :loading="loading"
          :footer-props="{
            'items-per-page-options': [25, 50, 100]
          }"
          :items-per-page="25"
        >
          <template
            v-if="items.length > 0"
            #body="props"
          >
            <draggable
              v-if="props.items.length > 0"
              v-bind="dragOptions"
              :list="items"
              tag="tbody"
              @change="updateOrder"
            >
              <transition-group
                v-for="(item, index) in props.items"
                :key="index"
                tag="tr"
                type="transition"
                name="flip-list"
              >
                <td :key="`${index}name`">
                  {{ item.name }}
                </td>
                <td :key="`${index}criteria`">
                  <div v-if="item.filter && details">
                    <div
                      v-for="filter_criteria in item.filter.filter_criteria"
                      :key="filter_criteria.id"
                    >
                      <strong>{{ filter_criteria.field | humanize }} {{ filter_criteria.comparator | humanize
                      }}:</strong>

                      <ul>
                        <li
                          v-for="v in getDisplayValues(filter_criteria.field, filter_criteria.value)"
                          :key="v"
                        >
                          {{ v }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div v-else-if="item.filter">
                    <strong>Count: </strong> {{ item.filter.filter_criteria.length }}
                  </div>
                  <div v-else>
                    Apply to All
                  </div>
                </td>

                <td :key="`${index}actions`">
                  <div v-if="details">
                    <div
                      v-for="rule in item.rule_actions"
                      :key="rule.id"
                    >
                      <strong>{{ rule.type | humanize }}:</strong>
                      <ul>
                        <li
                          v-for="v in getDisplayValues(rule.type, rule.value)"
                          :key="v"
                        >
                          {{ v }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div v-else>
                    <strong>Count: </strong> {{ item.rule_actions.length }}
                  </div>
                </td>

                <td :key="`${index}updated`">
                  {{ item.updated_at | date_pretty }}
                  <br>
                  ({{ item.updated_at | datetime_from }})
                </td>
                <td :key="`${index}created`">
                  {{ item.created_at | date_pretty }}
                  <br>
                  ({{ item.created_at | datetime_from }})
                </td>

                <td :key="`${index}active`">
                  <v-switch
                    v-model="item.active"
                    @change="updateRule(item)"
                  >
                    <template #label>
                      <span v-if="item.active">Active</span>
                      <span v-else>Not Active</span>
                    </template>
                  </v-switch>
                </td>

                <td
                  :key="`${index}del`"
                  class="text-right"
                >
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        small
                        icon
                        text
                        v-bind="attrs"
                        @click="$refs.ruleDialog.duplicate(item)"
                        v-on="on"
                      >
                        <v-icon>mdi-content-duplicate</v-icon>
                      </v-btn>
                    </template>
                    <span>Duplicate</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        small
                        text
                        v-bind="attrs"
                        :loading="loading"
                        icon
                        @click="deleteRule(item.id)"
                        v-on="on"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        small
                        text
                        v-bind="attrs"
                        icon
                        @click="$refs.ruleDialog.startEdit(item)"
                        v-on="on"
                      >
                        <v-icon> mdi-square-edit-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                </td>
              </transition-group>
            </draggable>
            <td v-else>
              No data available
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <rule-dialog
      ref="ruleDialog"
      @update-rules="updateTable()"
    />
  </div>
</template>
<script>
import draggable from 'vuedraggable';
import RuleDialog from './RuleDialog.vue';
export default {
  components: {
    RuleDialog,
    draggable
  },
  data() {
    return {
      details: false,
      loading: false,
      items: [],
      dragOptions: {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      },
      options: {},
      headers: [
        { text: 'Rule Name', value: 'name', sortable: false },
        { text: 'Criteria', value: 'criteria', sortable: false },
        { text: 'Actions', value: 'number_actions', sortable: false },
        // { text: '# Actions', value: 'number_actions', sortable: false },
        { text: 'Updated At', value: 'updated_at', sortable: false },
        { text: 'Created At', value: 'created_at', sortable: false },
        { text: 'Status', value: 'active', sortable: false },
        { text: 'Actions', value: 'actions', align: 'right', sortable: false },

      ]
    };
  },
  watch: {
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    },
    'options.itemsPerPage': function (newVal, oldVal) {
      if (oldVal && newVal) {
        const preference = {
          table_rows: {
            automation: newVal
          }
        };
        this.$store.dispatch('main/setPreferences', preference);
      }
    }
  },

  mounted() {
    this.updateTable();
    Event.listen('update-rules', () => {
      this.updateTable();
    });

    const table_rows = this.$store.getters['main/table_rows'];
    this.options.itemsPerPage = table_rows?.automation ?? 25;
  },
  methods: {
    updateRule(rule) {
      this.loading = true
      this.$http
        .put(`/rules/${rule.id}/update-status`, {
          active: rule.active
        })
        .then(() => {
          //
        })
        .finally(() => {
          this.loading = false
        });
    },
    deleteRule(id) {
      let self = this;
      swal({
        title: 'Confirm Delete',
        text: 'Do you really want to delete this filter?',
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      }).then(function (isConfirm) {
        if (isConfirm) {
          self.loading = true
          self.$http
            .request(`/rules/${id}`, {
              method: 'delete'
            })
            .then((response) => response.data)
            .then(() => {
              self.updateTable();
            })
            .finally(() => {
              self.loading = false
            })
        }
      });
    },
    updateTable() {
      this.items = [];
      this.loading = true;

      this.$http
        .request(`/rules`, {
          method: 'get'
        })
        .then((response) => response.data)
        .then((response) => {
          this.items = response;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    updateOrder() {
      this.$http
        .request(`/rules/order`, {
          method: 'post',
          data: {
            rules: this.items
          }
        })
        .then((response) => response.data)
        .then(() => {
          // this.updateTable();
        });
    },
    getDisplayValues(field, value) {

      let values = [];

      if (Array.isArray(value)) {
        console.log('Array detected');
        values = value;
      } else if (typeof value === 'string') {
        console.log('String detected');
        values = value.split(';').map(item => item.trim()); // Trim spaces if any around the items
      } else if (typeof value === 'number') {
        console.log('Number detected');
        values = [value]; // Convert number to a single-element array
      } else {
        console.log('Unsupported type');
        // Optionally handle other types or throw an error
      }
      let displayValues = [];

      try {
        switch (field) {
          case 'set_postage_type':
          case 'set_lowest_postage_ids':
          case 'postage_type': {
            values = values.map((str) => parseInt(str));
            const postageTypes = this.$store.getters['main/postage_types'](true);
            displayValues = postageTypes.filter((postageType) => values.includes(postageType.id)).map((postageType) => postageType.description);
            break;
          }

          case 'set_package_type':
          case 'package_type': {
            values = values.map((str) => parseInt(str));
            const packageTypes = this.$store.getters['main/package_types'];
            displayValues = packageTypes.filter((packageType) => values.includes(packageType.id)).map((packageType) => packageType.description);
            break;
          }

          case 'set_tax_identifier': {
            values = values.map((str) => parseInt(str));
            const taxIdentifiers = this.$store.getters['main/tax_identifiers'];
            displayValues = taxIdentifiers.filter((taxIdentifier) => values.includes(taxIdentifier.id)).map((taxIdentifier) => taxIdentifier.nickname);
            break;
          }

          case 'order_tags':
          case 'add_tags': {
            values = values.map((str) => parseInt(str));
            const tags = this.$store.getters['tags/filtered_tags'];
            displayValues = tags.filter((tag) => values.includes(tag.id)).map((tag) => tag.name);
            break;
          }

          case 'set_box': {
            values = values.map((str) => parseInt(str));
            const boxes = this.$store.getters['boxes/boxes'];
            displayValues = boxes.filter((box) => values.includes(box.id)).map((box) => box.name);
            break;
          }

          case 'user_store_id': {
            values = values.map((str) => parseInt(str));
            const userStores = this.$store.getters['userStores/getStoreOptions'];
            displayValues = userStores.filter((store) => values.includes(store.id)).map((store) => store.identifier);
            break;
          }

          case 'box_type': {
            values = values.map((str) => parseInt(str));
            const boxTypes = this.$store.getters['boxes/boxTypes'];
            displayValues = boxTypes.filter((boxType) => values.includes(boxType.id)).map((boxType) => boxType.name);
            break;
          }

          default:
            displayValues = values; // default to using original values as display values
            break;
        }
      } catch (e) {
        console.error(e);
        // Handle the exception here, you can log it or take appropriate action
        // For example: console.error('An error occurred: ' + e.message);
        return null;
      }

      return displayValues;
    }
  },

};
</script>

<style>
.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}
</style>
