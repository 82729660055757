<template>
  <FullPageLayout title="Return Lookups">
    <template #default>
      <search-input @search="onSearch" />

      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="returns"
        :loading="loading"
        item-key="id"
        class="elevation-1"
        show-select
        :footer-props="{ 'items-per-page-options': [10, 25, 100] }"
        :items-per-page.sync="itemsPerPage"
        :server-items-length="totalItems"
        :page.sync="currentPage"
        @update:items-per-page="onUpdatePerPage"
      >
        <template #top>
          <v-btn
            style="margin-top: 1rem; margin-left: 1rem"
            color="primary"
            :disabled="buttonIsDisabled"
            :loading="isMarkingAsComplete"
            @click="setSelectedAsComplete"
          >
            Mark As Complete
          </v-btn>
        </template>

        <template #item.user_id="{ item }">
          <span>
            {{ item.user.id }}
            <br>
            {{ item.user.email }}
          </span>
        </template>
      </v-data-table>
    </template>
  </FullPageLayout>
</template>

<script>
import SearchInput from './SearchInput.vue';
export default {
  name: 'ReturnLookup',
  components: {
    SearchInput
  },
  data() {
    return {
      searchString: '',
      itemsPerPage: 10,
      totalItems: 1,
      firstLoad: true,
      currentPage: 1,
      selected: [],
      isMarkingAsComplete: false,
      headers: [
        {
          text: 'User',
          align: 'left',
          value: 'user_id'
        },
        {
          text: 'Barcode',
          value: 'barcode'
        },
        {
          text: 'Content',
          value: 'item'
        },
        {
          text: 'Order #',
          align: 'left',
          value: 'order_id'
        },
        {
          text: 'Status',
          value: 'status.description'
        },
        {
          text: 'Created At',
          value: 'created_at'
        }
      ],
      loading: false,
      returns: []
    };
  },
  computed: {
    validSelected() {
      return this.selected.filter(re => {
        return re.status_id !== 35;
      });
    },
    selectedItemIds() {
      return this.validSelected.map(item => item.id);
    },
    buttonIsDisabled() {
      return !this.validSelected.length;
    },
    url() {
      return `/admin/returns/lookup?page_size${this.itemsPerPage}&page=${this.currentPage}${this.searchString}`;
    }
  },
  watch: {
    currentPage() {
      this.fetchShipmentReturns();
    }
  },
  mounted() {
    this.fetchShipmentReturns();
  },
  methods: {
    // Listen when search.
    onSearch(searchString) {
      this.searchString = searchString;
      this.fetchShipmentReturns();
    },

    // Send API call to mark the selected shipment return as complete.
    async setSelectedAsComplete() {
      this.isMarkingAsComplete = true;
      await this.$http.put('/admin/returns/lookup', {
        ids: this.selectedItemIds
      });
      this.fetchShipmentReturns();
      this.isMarkingAsComplete = false;
    },

    // Listen when page update.
    onUpdatePerPage() {
      this.fetchShipmentReturns();
    },

    // Send API to fetch Shipment returns.
    async fetchShipmentReturns() {
      this.firstLoad = false;
      this.loading = true;
      const SHIPMENT_RETURNS_REPONSE = await this.$http.get(this.url);
      this.returns = SHIPMENT_RETURNS_REPONSE.data.data;
      this.totalItems = SHIPMENT_RETURNS_REPONSE.data.total;
      this.selected = [];
      this.loading = false;
    }
  }
};
</script>
