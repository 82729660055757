import axios from 'axios';

function initialState() {
  return {
    boxes: [],
    loading: false
  };
}

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    boxes: (state) => {
      return state.boxes;
      // return state.boxes.map((box) => {
      //   return {
      //     ...box,
      //     // convert to string to match with values from RuleAction automation
      //     id: box.id.toString()
      //   };
      // });
    }
  },
  actions: {
    getBoxes({ commit, state }) {
      state.loading = true;
      axios
        .get('/boxes')
        .then((response) => {
          commit('updateBoxes', response.data);
        })
        .finally(() => {
          state.loading = false;
        });
    },
    async deleteBox({ dispatch }, id) {
      await axios.request(`/boxes/${id}`, {
        method: 'delete'
      });
      dispatch('getBoxes');
    }
  },
  mutations: {
    reset(state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    updateBoxes(state, boxes) {
      state.boxes = boxes;
    }
  }
};
