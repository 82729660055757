<template>
  <FullPageLayout
    title="Boxes"
    desc="Boxes help you set the package type and dimensions for orders that you import from your store or from files."
  >
    <template #page-links>
      <order-links />
    </template>
    <template #default>
      <box-table ref="boxTable" />
    </template>
  </FullPageLayout>
</template>

<script>
import BoxTable from './BoxTable.vue';
export default {
  metaInfo: {
    title: "Boxes | Stallion Express"
  },
  components: {
    BoxTable
  },
  props: {},
  data() {
    return {};
  },
  methods: {}
};
</script>
