<template>
  <v-card flat>
    <v-container class="px-0 py-0">
      <v-row>
        <v-col cols="12">
          <TemplateTable />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import TemplateTable from './TemplateTable.vue';

export default {
  components: {
    TemplateTable
  }
};
</script>
