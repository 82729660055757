<template>
  <div>
    <h4 v-if="showHeader">
      Stallion Protection
    </h4>
    <div
     
      class="d-flex flex-row justify-space-between"
    >
      <div class="shipment-title font-weight-bold">
        Protected Value
      </div>
      <div v-if="shipment.insured_value">
        {{ shipment.insured_value | currency }} CAD
      </div>
      <div v-else>
        No Protection
      </div>
    </div>

    <v-divider
      v-if="showDivider"
      class="details-divider"
    />
  </div>
</template>

<script>
export default {
  props: {
    shipment: {
      type: Object,
      default: null
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    showDivider: {
      type: Boolean,
      default: true
    }
  }
};
</script>
