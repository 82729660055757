import axios from 'axios';
import router from '../../router';
import Vue from 'vue';
function initialState() {
  return {
    completion: {
      shipments: [],
      selected: [],
      errors: [],
      overAllProgress: 0,
      progressPercentage: 0,
      loading: false,
      disabled: false,
      count: null,
      start_time: null
    },
    validation: {
      shipments: [],
      selected: [],
      errors: [],
      overAllProgress: 0,
      progressPercentage: 0,
      loading: false,
      disabled: false,
      count: null,
      start_time: null
    },
    selection: {
      shipments: [],
      selected: [],
      type: 'file',
      filters: {
        filter_id: null,
        user_store_id: [],
        search: null,
        search_type: 'Order ID',
        country_code_type: 'Custom',
        order_date_min: null,
        order_date_max: null,
        value_min: null,
        value_max: null,
        weight_min: null,
        weight_max: null,
        quantity_min: null,
        quantity_max: null,
        country_code: [],
        country_code_inclusive: true,
        order_tag: []
      }
    },
    loader: {
      message: '',
      show: false
    },
    total: 0,
    step: 1,
    dialog: null,
    msg: '',
    batch: null
  };
}

const imp = {
  namespaced: true,
  state: initialState(),
  getters: {
    printable: state => {
      var array = [];

      if (state.completion.shipments.length > 0) {
        state.completion.shipments.forEach(item => {
          if (
            [2, 4, 5, 6, 8, 10, 13].includes(item.status_id) &&
            (item.needs_postage || item.is_fba)
          ) {
            array.push(item);
          }
        });
      }
      return array;
    }
  },
  mutations: {
    filtersSet(state, payload) {
      state.selection.filters = payload;
    },
    providersSet(state, payload) {
      state.selection.filters.user_store_id = payload;
    },
    loaderShow(state, message) {
      state.loader.show = true;
      state.loader.message = message;
    },
    loaderHide(state) {
      state.loader.show = false;
      state.loader.message = '';
    },
    updateSelectionSelected(state, shipments) {
      state.selection.selected = shipments;
    },
    updateValidationSelected(state, shipments) {
      state.validation.selected = shipments;
    },
    updateValidationShipments(state, shipments) {
      state.validation.shipments = shipments;
    },
    selectionStart(state, payload) {
      state.step = 2;
      state.selection.loading = false;
      state.selection.disabled = false;
      state.selection.shipments = payload.shipments;

      state.selection.type = payload.type;
      this.commit('imp/providersSet', payload.providers);

      if (payload.select_all) {
        console.log('select all');
        state.selection.selected = state.selection.shipments;

        console.log(state.selection.selected);
      }
    },
    validationStart(state, payload) {
      state.validation.shipments = payload.shipments;
      state.batch = payload.batch;

      state.validation.loading = false;
      state.validation.disabled = false;
      state.step = 3;

      // if(state.selection.selected.length == 0){

      //   Vue.notify({
      //     group: 'main',
      //     title: 'Shipment Required',
      //     text: `You must select at least one shipment to continue`,
      //     duration: 5000
      //   });
      // }else{
      //   state.step = 3
      //   state.validation.loading = false
      //   state.validation.disabled = false
      //   state.validation.shipments = state.selection.selected
      // }
    },
    validationRemoveItem(state) {
      state.validation.selected.forEach(shipment => {
        var index = state.validation.shipments
          .map(e => e.row)
          .indexOf(shipment.row);
        state.validation.shipments.splice(index, 1);
      });

      state.validation.selected = [];

      if (state.validation.shipments.length == 0) {
        this.commit('imp/reset');
      }
    },
    validationAddItem(state, payload) {
      var row = 2;
      state.validation.shipments.forEach(item => {
        item.row = row;
        row++;
      });

      state.validation.selected = [];

      state.validation.shipments.push(payload);
    },
    validationEditItem(state, payload) {
      state.validation.selected.forEach(shipment => {
        var index = state.validation.shipments
          .map(e => e.row)
          .indexOf(shipment.row);
        var new_shipment = Object.assign(shipment, payload.value);
        state.validation.shipments.splice(index, 1, new_shipment);
      });
    },

    //===============
    reset(state) {
      if (state.batch && state.batch.id && state.batch.open) {
        this.commit('imp/cancelBatch');
      }

      const s = initialState();
      Object.keys(s).forEach(key => {
        state[key] = s[key];
      });
    },
    importShipments(state) {
      axios
        .post('/shipments/import-shipments', {
          shipments: state.validation.shipments
        })
        .then(response => response.data)
        .then(() => {
          router.push({
            name: 'shipments-view'
          });
          this.commit('imp/reset');
        });
    },
    exportImported(state) {
      axios
        .post(`/import/export`, state.validation.shipments)
        .then(response => {
          this.downloadFileUrl(response.data,'Stallion Express Template.csv', 'text/csv');
        });
    },
    back() {
      var self = this;
      // swal({
      //   title: 'Are you sure?',
      //   text:
      //     'You will lose any changes made to your shipments.' +
      //     '.\n Do you wish to continue?',
      //   icon: 'warning',
      //   buttons: ['Cancel', 'Confirm']
      // }).then(function (isConfirm) {
      //   if (isConfirm) {
      self.commit('imp/reset');
      //   }
      // });
    },

    abort(state) {
      var self = this;
      swal({
        title: 'Are you sure?',
        text:
          'This will abort the processing of any uncompleted shipments.' +
          '.\n Do you wish to continue?',
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      }).then(function(isConfirm) {
        if (isConfirm) {
          self.commit('imp/reset');
          state.step = 1;
        }
      });
    },
    validateShipments(state) {
      state.validation.loading = true;
      state.validation.disabled = true;
      state.validation.errors = [];
      state.validation.overAllProgress = 0;
      state.validation.progressPercentage = 0;
      state.validation.count = null;

      axios
        .post('/shipments/import', {
          shipments: state.validation.shipments,
          batch: state.batch
        })
        .then(response => response.data)
        .then(response => {
          this.commit('imp/updateValidationStatus', response);
        })
        .catch(() => {
          this.commit('imp/checkForActiveBatch');
        });
    },
    updateValidationStatus(state, payload) {
      state.step = 3;
      console.log(payload);
      state.batch = payload;
      console.log('updateValidationStatus');
      state.completion.shipments = [];
      state.selection.errors = [];
      state.total = 0;
      state.postageTotal = 0;
      state.handlingTotal = 0;
      state.taxTotal = 0;

      state.validation.overAllProgress = 0;
      state.validation.progressPercentage = 0;

      state.validation.loading = true;
      state.validation.disabled = true;
      state.validation.count = state.batch.count;

      state.validate_error = false;

      state.validation.start_time = new Date(
        Date.parse(state.batch.validate_start_at)
      );

      // var self = this;

      // var increment = (1 / state.batch.count) * 100;

      this.commit('imp/checkBatchValidated');
    },
    updateValidationProgress(state, payload) {
      state.validation.overAllProgress = payload.completed;
      state.validation.progressPercentage = payload.percent;
    },
    checkBatchValidated(state) {
      if (router.currentRoute.path != '/import') {
        return true;
      }

      var self = this;
      console.log('watching');
      axios({
        method: 'get',
        url: `/batches/${state.batch.id}/validation-status`,
        timeout: 10000 // Let's say you want to wait at least 180 seconds
      })
        .then(response => response.data)
        .then(response => {
          console.log(response);

          const completed = response.completed;
          const percent = (response.completed / state.validation.count) * 100;

          const payload = {
            completed,
            percent
          };

          this.commit('imp/updateValidationProgress', payload);

          if (response.count == response.completed) {
            this.commit('imp/getValidated');
          } else {
            setTimeout(function() {
              self.commit('imp/checkBatchValidated');
            }, 2000);
          }
        })
        .catch(err => {
          if (err.response && err.response.status === 401) {
            console.log(err);
          } else {
            setTimeout(function() {
              self.commit('imp/checkBatchValidated');
            }, 2000);
          }
        });
    },
    getValidated(state) {
      state.validation.loading = false;
      state.validation.disabled = false;
      state.selection.shipments = [];
      state.completion.shipments = [];
      state.import_loading = true;

      // var self = this;
      axios
        .get(`/batches/${state.batch.id}/validated`)
        .then(response => response.data)
        .then(response => {
          const shipments = response;
          var missingTrackingNumber = false;
          var errors = [];

          shipments.forEach((shipment, index) => {
            if (shipment.errors.length > 0) {
              const errs = shipment.errors.map(a => a.description);
              errors.push('On row ' + (index + 1) + ': ' + errs.join(' '));
            }

            if (
              (shipment.needs_postage === false ||
                shipment.needs_postage === 0) &&
              (shipment.tracking_code == null || shipment.tracking_code == '')
            ) {
              missingTrackingNumber = true;
            }
          });

          if (errors.length > 0) {
            const payload = {
              errors,
              missingTrackingNumber,
              shipments
            };
            this.commit('imp/validationError', payload);
          } else {
            var total = 0;
            var i;
            for (i = 0; i < shipments.length; i++) {
              console.log(shipments[i].total_amt_paid);
              total += parseFloat(shipments[i].total_amt_paid);
            }

            total = total.toFixed(2);

            const payload = {
              total,
              shipments
            };

            this.commit('imp/validationSuccess', payload);
          }
        })
        .catch(() => {
          console.log('validate not sent :(');
        });
    },
    validationError(state, payload) {
      state.import_loading = false;
      state.validation.loading = false;
      state.validation.disabled = false;
      state.step = 3;
      state.validation.shipments = payload.shipments;
      state.validation.errors = payload.errors;
    },
    validationSuccess(state, payload) {
      state.import_loading = false;
      state.validation.loading = false;
      state.validation.disabled = false;
      state.step = 4;
      state.completion.shipments = payload.shipments;
      state.total = payload.total;
    },
    checkForActiveBatch() {
      axios
        .get(`/batches/active`)
        .then(response => response.data)
        .then(response => {
          if (response) {
            const batch = response;
            if (response.step === 0) {
              this.commit('imp/updateValidationStatus', batch);
            } else if (response.step === 1) {
              this.commit('imp/updatePurchaseStatus', batch);
            }
          }
          console.log(response);
        });
    },
    cancelBatch(state) {
      axios
        .get(`/batches/${state.batch.id}/cancel`)
        .then(response => response.data)
        .then(() => {});
    },

    complete(state) {
      var self = this;

      state.completion.loading = true;
      state.completion.disabled = true;

      axios
        .post('/shipments/complete', state.batch)
        .then(response => response.data)
        .then(response => {
          const batch = response;
          self.commit('imp/updatePurchaseStatus', batch);
        })
        .catch(() => {
          self.commit('imp/checkForActiveBatch');
        });
    },
    updatePurchaseStatus(state, payload) {
      state.batch = payload;
      state.step = 4;
      state.completion.loading = true;
      state.completion.disabled = true;
      state.completion.overAllProgress = 0;
      state.completion.progressPercentage = 0;
      state.completion.count = state.batch.count;

      state.completion.start_time = new Date(
        Date.parse(state.batch.purchase_start_at)
      );

      // var self = this;

      // var increment = (1 / state.batch.count) * 100;

      this.commit('imp/checkBatchPurchased');
    },
    updateCompletionProgress(state, payload) {
      state.completion.overAllProgress = payload.completed;
      state.completion.progressPercentage = payload.percent;
    },
    checkBatchPurchased(state) {
      if (router.currentRoute.path != '/import') {
        return true;
      }

      var self = this;

      console.log('watching');
      axios({
        method: 'get',
        url: `/batches/${state.batch.id}/purchase-status`,
        timeout: 10000 // Let's say you want to wait at least 180 seconds
      })
        .then(response => response.data)
        .then(response => {
          const completed = response.completed;
          const percent = (response.completed / state.completion.count) * 100;

          const payload = {
            completed,
            percent
          };

          this.commit('imp/updateCompletionProgress', payload);

          if (response.count == response.completed) {
            this.commit('imp/getPurchased');
          } else {
            setTimeout(function() {
              self.commit('imp/checkBatchPurchased');
            }, 2000);
          }
          console.log(response);
        })
        .catch(err => {
          if (err.response && err.response.status === 401) {
            console.log(err);
          } else {
            setTimeout(function() {
              self.commit('imp/checkBatchPurchased');
            }, 2000);
          }
        })
        .finally(() => {});
    },
    completionSuccess(state, payload) {
      state.completion.loading = false;
      state.completion.disabled = false;
      state.step = 4;
      state.completion.shipments = payload.shipments;
    },
    getPurchased(state) {
      state.completion.loading = false;
      state.completion.disabled = false;
      state.completion.overAllProgress = 0;
      state.completion.progressPercentage = 0;
      state.completion.purchased = true;

      Event.fire('get-credits');

      // var self = this;
      axios
        .get(`/batches/${state.batch.id}/purchased`)
        .then(response => response.data)
        .then(response => {
          console.log(response);
          var shipments = response;

          var failed = false;
          shipments.forEach(shipment => {
            if (shipment.status_id == 3) {
              console.log('failed');
              console.log(shipment);
              failed = true;
              return 'done';
            }
          });

          const payload = {
            shipments
          };

          this.commit('imp/completionSuccess', payload);

          if (failed) {
            swal({
              title: 'Import Partially Complete',
              text:
                'Your import has been completed with some errors. The failed shipments have been saved as "Incomplete" and await further action by you. You can retry the failed shipments on the view shipments page by selectiong them.',
              icon: 'warning'
            });
          } else {
            Vue.notify({
              group: 'main',
              title: 'Import Complete',
              text: 'Your import has been completed',
              duration: 5000
            });
          }
        });
    }
  }
};

export default imp;
