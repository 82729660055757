<template>
  <div>
    <v-container
      grid-list-md
      mb-12
    >
      <v-row>
        <v-col
          cols="auto"
        >
          <v-select
            v-model="location"
            :items="locations"
            item-text="name"
            item-value="id"
            label="Branch"
            outlined
          />
        </v-col>

        <v-col
          cols="auto"
        >
          <v-select
            v-model="group"
            :items="groups"
            label="Group"
            outlined
            clearable
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="filteredSupplies"
            show-select
            
            :loading="loading"
            class="table-striped"
            :page.sync="pagination.page"
            :items-per-page.sync="pagination.itemsPerPage"
            :sort-by.sync="pagination.sortBy"
            :sort-desc.sync="pagination.descending"
            :footer-props="{'items-per-page-options': [25,50, 100, 200, 500]}"
          >
            <template #item.price="{ item }">
              {{ item.price | currency }}
            </template>

            <template #item.enabled="{ item }">
              <v-icon v-if="item.enabled">
                mdi-check-circle-outline
              </v-icon>
              <v-icon v-else>
                mdi-close
              </v-icon>
            </template>

            <!--<template
              v-slot:item.total="{ item }"
            >{{ parseFloat(item.quantity_in_stock) + parseFloat(item.pending_pickup) }}</template>-->
            <template
              #item.total="{ item }"
            >
              <span>
                {{ parseFloat(item.region_location_inventory.quantity_in_stock) + parseFloat(item.region_location_inventory.pending_pickup) }}
              </span>
            </template>

            <template #item.action="{ item }">
              <v-menu>
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    color="primary"
                    v-on="on"
                    @click="stockItem = item; $refs.suppliesEdit.openDialog(item)"
                  >
                    <v-icon>mdi-pencil-box-outline</v-icon>
                  </v-btn>
                </template>
              </v-menu>

              <EditSuppliesStock
                v-if="$auth.user.employee_location_id"
                ref="suppliesEdit"
              />
            </template>

            <template #item.region_location_inventory.pending_pickup="{ item }">
              <span>
                {{ item.region_location_inventory.pending_pickup }}
              </span> 
            </template>

            <template #item.region_location_inventory.quantity_in_stock="{ item }">
              <span 
                class="badge badge-pill" 
                :class="item.region_location_inventory.quantity_in_stock > 4 ? 'badge-success': 'badge-primary'"
              >
                {{ item.region_location_inventory.quantity_in_stock }}
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <div class="footer">
      <v-menu>
        <template #activator="{ on }">
          <v-btn
            color="primary"
            :disabled="selected.length == 0"
            v-on="on"
            @click="$refs.suppliesAdd.addNew()"
          >
            Add
          </v-btn>

          <v-btn
            color="primary"
            :disabled="selected.length == 0"
            v-on="on"
            @click="$refs.bulkEditSupplies.openDialog()"
          >
            Update
          </v-btn>
        </template>
      </v-menu>

      <AddSuppliesStockDialog
        v-if="$auth.user.employee_location_id"
        ref="suppliesAdd"
        :supplies="selected"
        :disabled="selected.length == 0"
      />

      <BulkEditSuppliesDialog
        v-if="$auth.user.employee_location_id"
        ref="bulkEditSupplies"
        :supplies="selected"
        :disabled="selected.length == 0"
      />
    </div>
  </div>
</template>

<script>
import AddSuppliesStockDialog from './AddSuppliesStockDialog.vue'
import EditSuppliesStock from './EditSuppliesStockDialog.vue';
import BulkEditSuppliesDialog from './BulkEditSuppliesDialog.vue';
import { initializeInventory } from '../../../helpers/helper';
export default {
  components: {
    AddSuppliesStockDialog,
    EditSuppliesStock,
    BulkEditSuppliesDialog
  },
  data() {
    return {
      supplies: [],
      group: null,
      groups: [],
      selected: [],
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Dimensions', value: 'dimentions' },
        // { text: 'Available', value: 'quantity_in_stock' },
        { text: 'Available', value: 'region_location_inventory.quantity_in_stock' },
        // { text: 'Pending Pickup', value: 'pending_pickup' },
        { text: 'Pending Pickup', value: 'region_location_inventory.pending_pickup' },
        { text: 'Total', value: 'total', sortable: false },
        { text: 'Price', value: 'price' },
        { text: 'Enabled', value: 'enabled' },
        { text: 'Action', value: 'action', sortable: false }
      ],
      pagination: {
        descending: false,
        page: 1,
        itemsPerPage: -1,
        sortBy: 'name'
      },
      loading: false,
      locations: [],
      location: {},
      stockSupplyEdit: false
    };
  },
  computed: {
    filteredSupplies() {
      this.selected = [];
      if (this.group) {
        return this.supplies.filter(obj => {
          return obj.type === this.group;
        });
      }

      return this.supplies;
    }
  },
  watch:{
    location: function(){
      this.getSupplies();
    }
  },
  mounted() {
    this.getInventoryLocations();
    this.getGroup();

    Event.listen('update-supplies', () => {
      // this.selectedShipments = [];
      this.getSupplies();
    });
  },
  methods: {
    // getGroup(group) {
    //   return this.supplies.filter(obj => {
    //     return obj.type === group;
    //   });
    // },
    getInventoryLocations(){
      this.$http
        .get('/admin/supplies/inventory-locations')
        .then(res => {
          this.locations = res.data.locations;
          this.location = res.data.employee_branch.id ?? this.locations[0].id;
        })
    },
    getGroup() {
      this.$http
        .get('/admin/supplies/group')
        .then(res => {
          this.groups = res.data;
        })
    },
    getSupplies() {
      this.loading = true;
      this.$http
        .get('/admin/supplies/inventory/' + this.location)
        .then(res => {
          this.supplies = res.data;
          this.supplies.forEach(item => {
            initializeInventory(item);
            item.add = 0;
            if (item.region_location_inventory === null) {
              const obj = {
               'pending_pickup' : 0,
               'quantity_in_stock' : 0,
               'location_id' : this.location
              };
              item.region_location_inventory = obj;
            }
          });
          this.refreshCurrentlySelectedSupplies();
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    save() {
      this.snack = true;
      this.snackColor = 'success';
      this.snackText = 'Data saved';
    },
    cancel() {
      this.snack = true;
      this.snackColor = 'error';
      this.snackText = 'Canceled';
    },
    open() {
      this.snack = true;
      this.snackColor = 'info';
      this.snackText = 'Dialog opened';
    },
    close() {
      console.log('Dialog closed');
    },
    refreshCurrentlySelectedSupplies() {
      this.selected.forEach(item => {
        for (let i = 0; i < this.supplies.length; i++) {
          let supply = this.supplies[i];
          if (item.id == supply.id) {
            item.employee_location_inventory =
              supply.employee_location_inventory;
            item.price = supply.price;
            break;
          }
        }
      });
    }
  }
};
</script>
