<template>
  <div>
    <h2 class="text-center">
      Shipment Details
    </h2>
    <hr>
    <ul>
      <!-- <span class="float-right">{{ shipment.package_contents }}</span> -->

      <div v-if="shipment.items">
        <div
          v-for="item in shipment.items"
          :key="item.id"
        >
          <!-- <div class="d-flex flex-row justify-space-between">
        <h6>Item {{ index + 1 }}</h6>
      </div> -->

          <!-- <div class="d-flex flex-row justify-space-between">
        <div class="shipment-title">{{ item.title }}</div>
        <div></div>
      </div> -->

          <div
            v-if="item.sku"
            class="d-flex flex-row justify-space-between"
          >
            <strong>
              SKU
            </strong>
            <div>{{ item.sku }}</div>
          </div>

          <div class="d-flex flex-row justify-space-between">
            <strong>
              Quantity
            </strong>
            <div>{{ item.quantity }}</div>
          </div>

       

          <!-- <div
        v-if="item.weight"
        class="d-flex flex-row justify-space-between"
      >
        <div class="shipment-title">
          Weight
        </div>
        <div>{{ item.weight }} {{ item.weight_unit }}</div>
      </div> -->

          <div
            v-if="item.value"
            class="d-flex flex-row justify-space-between"
          >
            <strong>
              Unit Value
            </strong>
            <div>{{ item.value | currency }} {{ item.currency }}</div>
          </div>

          <div>
            <strong>
              Description
            </strong>
            <div> {{ item.description }}</div>
          </div>
          <v-divider />
        </div>
      </div>
    
     
      <li>
        <strong>Total Retail Value:</strong>
        <span class="float-right">
          {{ getCombinedValue(shipment?.items) }}  
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    shipment: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
