<template>
  <FullPageLayout title="Rack Scan">
    <template #default>
      <v-layout wrap>
        <v-flex xs12>
          <v-form>
            <v-container>
              <v-row
                class="pb-2"
                style="justify-content: space-between"
              >
                <v-text-field
                  v-model="barcode"
                  label="Manual Entry"
                  class="mr-2"
                  outlined
                  clearable
                  dense
                  hide-details
                  @keydown.enter.prevent="manualScan"
                />

                <v-btn
                  color="primary"
                  @click="manualScan"
                >
                  Submit
                </v-btn>
              </v-row>
            </v-container>
          </v-form>
        </v-flex>

        <v-flex
          id="scan-page"
          xs12
        >
          <v-flex
            v-for="(item, index) in errors"
            :key="index"
            xs12
          >
            <v-alert
              class="pa-2"
              :value="true"
              dismissible
              type="error"
              transition="fade-transition"
            >
              {{ item }}
            </v-alert>
          </v-flex>
          <v-flex
            v-for="(item, index) in warnings"
            :key="index"
            xs12
          >
            <v-alert
              class="pa-2"
              :value="true"
              dismissible
              type="warning"
              transition="fade-transition"
            >
              {{ item }}
            </v-alert>
          </v-flex>
        </v-flex>
      </v-layout>
      <v-layout wrap>
        <v-flex xs12>
          <h1 class="text-h2 font-weight-black">
            {{ rack }}
          </h1>
        </v-flex>
        <v-flex xs12>
          <v-data-table
            class="table-striped"
            :headers="headers"
            :items="shipments"
            :loading="loading"
            no-data-text="Awaiting scanned items"
            :page.sync="pagination.page"
            :items-per-page.sync="pagination.itemsPerPage"
            :sort-by.sync="pagination.sortBy"
            :sort-desc.sync="pagination.descending"
          >
            <template #item.status="{ item }">
              <span v-if="item.status_id == 30">Received</span>
              <span v-else-if="item.status_id == 35">Complete</span>
            </template>
            <template #item.service_id="{ item }">
              <div v-html="$options.filters.return_service(item.service_id)" />
            </template>

            <template #item.status_id="{ item }">
              <div v-html="$options.filters.return_status(item.status_id)" />
            </template>
            <template #item.created_at="{ item }">
              <div v-if="item.created_at">
                {{ item.created_at | date_pretty }}
                <br>
                ({{ item.created_at | datetime_from }})
              </div>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </template>
  </FullPageLayout>
</template>

<script>
export default {
  data() {
    return {
      shipments: [],
      warnings: [],
      activeBtn: 1,
      showNav: true,
      barcodes: [],
      box: 0,
      barcode: null,

      selected: [],
      rack: null,
      headers: [
        {
          text: 'Barcode',
          sortable: false,
          value: 'barcode'
        },
        {
          text: 'Rack',
          sortable: false,
          value: 'rack'
        },
        {
          text: 'User ID',
          sortable: false,
          value: 'user_id'
        },
        {
          text: 'Service',
          value: 'service_id'
        },
        {
          text: 'Status',
          value: 'status_id'
        },

        {
          text: 'Created At',
          value: 'created_at'
        }
      ],
      pagination: {
        page: 1,
        itemsPerPage: 200,
        sortBy: null,
        descending: false
      },
      loading: false,
      errors: []
    };
  },
  computed: {},
  created() {
    document.activeElement.blur();
    // Add barcode scan listener and pass the callback function
    this.$barcodeScanner.init(this.onBarcodeScanned);
  },
  destroyed() {
    // Remove listener when component is destroyed
    this.$barcodeScanner.destroy();
  },
  mounted() {},
  methods: {
    manualScan() {
      this.onBarcodeScanned(this.barcode);

      this.barcode = null;
    },
    test() {
      var x = document.activeElement.tagName;

      return x;
    },
    // Create callback function to receive barcode when the scanner is already done
    onBarcodeScanned(barcode) {
      this.$notify({
        group: 'main',
        title: 'Item Scanned',
        text: barcode,
        duration: 3000
      });

      // let self = this;

      var patt = /^RACK_/;
      //
      if (patt.test(barcode)) {
        var code = barcode.replace(patt, '');

        this.rack = code;

        var msg = new SpeechSynthesisUtterance(code);
        window.speechSynthesis.speak(msg);

        return;
      }

      if (this.rack == null) {
        this.playErrorSound();
        this.errors.push('No rack selected');
        return;
      }

      this.scan(barcode).then(res => {
        this.shipments.push(res);
      });

      // do something...
    },
    // Reset to the last barcode before hitting enter (whatever anything in the input box)
    resetBarcode() {
      this.$barcodeScanner.getPreviousCode();
      // do something...
    },
    scan(barcode) {
      return new Promise((resolve, reject) => {
        this.$http
          .post('/admin/scan/returns/rack', { code: barcode, rack: this.rack })
          .then(response => response.data)
          .then(response => {
            if (response.success == true) {
              var s = {
                rack: this.rack,
                barcode: barcode,
                user_id: null,
                status_id: null,
                service_id: null,
                created_at: null
              };

              if (response.shipment) {
                s.user_id = response.shipment.user_id;
                s.status_id = response.shipment.status_id;
                s.service_id = response.shipment.service_id;
                s.created_at = response.shipment.created_at;
                this.playSuccessSound();
              } else {
                var audio2 = new Audio('/sounds/beep-09.wav');
                audio2.play();
              }

              resolve(s);
            } else {
              this.playErrorSound();
              this.errors.push(response.error);
              reject({});
            }
          })
          .catch((res) => {
            window.speechSynthesis.speak(
                new SpeechSynthesisUtterance(res.response.data.audio)
              );
            this.errors.push(`${barcode} - ${res.response.data.message}`);
          });
      });
    }
  }
};
</script>
