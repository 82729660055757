<template>
  <StallionDialog
    v-model="show"
    :width="500"
    persistent
    title="Please Confirm Your Password"
  >
    <template #content>
      <v-form @submit.prevent="confirmPassword">
        <v-layout column>
          <v-flex xs12>
            <v-text-field
              v-model="password"
              v-validate="'required'"
              autofocus
              mt-4
              outlined
              type="password"
              label="Password"
              data-vv-name="password"
              :error-messages="errs.collect('password')"
              required
            />
          </v-flex>
        </v-layout>
      </v-form>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        :loading="processing"
        :disabled="processing"
        @click="confirmPassword"
      >
        Confirm
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  name: 'ConfirmPasswordDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      password: null,
      processing: false
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    confirmPassword() {
      this.$validator.validate().then((result) => {
        if (result) {
          this.processing = true;
          this.$http
            .post('auth/user/confirm-password', {
              password: this.password
            })
            .then(() => {
              this.close();
              this.$emit('onSuccess');
            })
            .catch(() => {
              this.errorMessage('Invalid Password');
            })
            .finally(() => {
              this.processing = false;
            });
        }
      });
    },
    close(){
      this.password = null;
      this.show = false
      this.$validator.reset()
    }
  }
};
</script>
