import Vue from 'vue';

// layouts
Vue.component('MainLayout', () =>
  import('../components/layouts/main-layout.vue')
);
Vue.component('MinimalLayout', () =>
  import(
     '../components/layouts/minimal-layout.vue'
  )
);

//shipments
Vue.component('ShipmentTable', () =>
  import(
     '../components/main/shipments/shipment-table.vue'
  )
);

Vue.component('BatchLabelPrint', () =>
  import(
    /* webpackChunkName: "main" */ './main/batches/batch-label-print.vue'
  )
);

Vue.component('Export', () =>
  import(
     '../components/main/shipments/export-shipments.vue'
  )
);

Vue.component('BatchDelete', () =>
  import(
     '../components/main/batches/batch-delete.vue'
  )
);
Vue.component('BatchPrint', () =>
  import(
     '../components/main/batches/batch-print.vue'
  )
);

Vue.component('BatchTable', () =>
  import(
     '../components/main/batches/batch-table.vue'
  )
);

Vue.component('PrintReturnLabel', () =>
  import(
     '../components/main/shipments/action-bar/CreateReturnShipment.vue'
  )
);

// single flow
Vue.component('NewShipmentModal', () =>
  import(
     '../components/main/shipments/single-flow/modal.vue'
  )
);
Vue.component('ShipmentFrom', () =>
  import(
     '../components/main/shipments/single-flow/from.vue'
  )
);

Vue.component('ShipmentRecipient', () =>
  import(
     '../components/main/shipments/single-flow/recipient.vue'
  )
);
Vue.component('Postage', () =>
  import(
     '../components/main/shipments/single-flow/postage.vue'
  )
);
Vue.component('Package', () =>
  import(
     '../components/main/shipments/single-flow/package-contents.vue'
  )
);
Vue.component('PackageUSPS', () =>
  import(
     '../components/main/shipments/single-flow/package-type.vue'
  )
);
Vue.component('Summary', () =>
  import(
     '../components/main/shipments/single-flow/summary.vue'
  )
);
Vue.component('AddressVerification', () =>
  import(
     '../components/main/shipments/single-flow/address-verification.vue'
  )
);
Vue.component('Complete', () =>
  import(
     '../components/main/shipments/single-flow/complete.vue'
  )
);

Vue.component('TransactionTable', () =>
  import(
    '../components/main/transactions/transaction-table.vue'
  )
);
Vue.component('StatementTable', () =>
  import(
    '../components/main/statements/statement-table.vue'
  )
);

Vue.component('DatePicker', () =>
  import(
     './shared/DatePicker.vue'
  )
);

//user
Vue.component('ExportInvoices', () =>
  import(
     '../components/main/invoices/export.vue'
  )
);

Vue.component('ImportPreset', () =>
  import(
     '../components/main/import/import-preset.vue'
  )
);
Vue.component('ImportPresetIndex', () =>
  import(
     '../components/main/import/import-preset-index.vue'
  )
);

// returns
Vue.component('PrintReturns', () =>
  import(
     '../components/main/returns/print-returns.vue'
  )
);
Vue.component('EditReturnDetails', () =>
  import(
     '../components/main/returns/edit-return-details.vue'
  )
);
Vue.component('RequestAdditionalPhotos', () =>
  import(
     '../components/main/returns/request-additional-photos.vue'
  )
);
Vue.component('ReturnRequest', () =>
  import(
     '../components/main/returns/return-request.vue'
  )
);
Vue.component('ReturnDelete', () =>
  import(
     '../components/main/returns/return-delete.vue'
  )
);
Vue.component('ReturnDispose', () =>
  import(
     '../components/main/returns/return-dispose.vue'
  )
);
Vue.component('ReturnsTable', () =>
  import(
     '../components/main/returns/returns-table.vue'
  )
);
Vue.component('ReturnOrdersTable', () =>
  import(
     '../components/main/orders/orders-table.vue'
  )
);

Vue.component('ClaimsTable', () =>
  import(
     '../components/main/claims/claims-table.vue'
  )
);

Vue.component('DownloadsTable', () =>
  import(
     '../components/main/downloads/downloads-table.vue'
  )
);

Vue.component('UserSettings', () =>
  import(
     '../components/main/user/user-settings.vue'
  )
);

Vue.component('UserStoreSettings', () =>
  import(
     '../components/main/user/user-store-settings.vue'
  )
);

Vue.component('UserSettingsOption', () =>
  import(
     '../components/main/user/user-settings-option.vue'
  )
);

// shipments
Vue.component('ViewShipmentDetails', () =>
  import(
     '../components/main/shipments/details/view-shipment-details.vue'
  )
);

Vue.component('VoidShipments', () =>
  import(
     '../components/main/shipments/action-bar/VoidShipments.vue'
  )
);

Vue.component('Announcements', () =>
  import(
     '../components/main/announcements/index.vue'
  )
);

Vue.component('ManageTags', () =>
  import('../components/main/tags/manage.vue')
);

Vue.component('WeglotSwitcher', () =>
  import(
     '../components/main/weglot/weglot-switcher.vue'
  )
);

Vue.component('OrderLinks', () =>
  import(
     '../components/main/orders/order-links.vue'
  )
);

Vue.component('AddCreditsModal', () =>
  import(
    '../pages/main/credits/BraintreeAddCredits.vue'
  )
);

Vue.component('BandcampConnect', () =>
  import(
    '../components/main/import/link-bandcamp-account.vue'
  )
);


Vue.component('LocationSelector', () => import('../components/shared/LocationSelector.vue'));