<template>
  <StallionDialog
    v-model="show"
    :width="500"
    :disabled="disabled"
    class="dialog-slot"
    title="Shopify"
  >
    <slot v-on="on" />

    <template #content>
      <v-row>
        <v-col
          cols="12"
          class="text-center"
        >
          Visit the Shopify app store to connect your store.
        </v-col>
      </v-row>
    </template>

    <template #actions>
      <!-- <v-btn
        type="submit"
        color="primary"
        :loading="print_loading"
        :disabled="print_loading"
        @click="connect()"
      >
        Connect
      </v-btn> -->

      <v-btn
        color="primary"
        href="https://apps.shopify.com/stallion-express"
      >
        Visit App Store
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },

  data() {
    return {
      print_loading: false,
      dowload_loading: false,
      shop: null,
      install_live_rates: false
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    connect: function () {
      this.$validator.validate().then((result) => {
        if (result) {
          this.print_loading = true;
          this.$http
            .post(`/stores/shopify`, {
              shop: this.shop,
              install_live_rates: this.install_live_rates
            })
            .then((response) => response.data)
            .then((response) => {
              window.location = response.redirect;
            });
        }
      });
    }
  }
};
</script>
