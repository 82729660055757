<template>
  <v-row v-if="store">
    <v-col
      cols="12"
      class="d-flex justify-center mb-6"
    >
      <div>
        <h2 class="font-weight-bold">
          Look up your order
        </h2>
        <p>
          Enter your order number below along with your zip code to find your
          order.
        </p>
        <br>

        <v-form @submit.prevent>
          <v-row>
            <v-container>
              <v-text-field
                v-model="lookupData.order_id"
                v-validate="'required'"
                outlined
                autofocus
                data-vv-name="lookupData.order_id"
                :error-messages="errs.collect('lookupData.order_id')"
                name="lookupData.order_id"
                label="Order #"
                type="text"
                data-vv-validate-on="blur"
                hide-details="true"
              />
            </v-container>

            <v-container>
              <v-text-field
                v-model="lookupData.postal_code"
                v-validate="'required'"
                outlined
                data-vv-name="lookupData.postal_code"
                :error-messages="errs.collect('lookupData.postal_code')"
                name="lookupData.postal_code"
                label="Zip Code/Postal Code"
                type="text"
                data-vv-validate-on="blur"
                hide-details="true"
              />
            </v-container>

            <v-container>
              <v-layout align-center>
                <v-flex
                  xs12
                  text-center
                >
                  <br>
                  <v-btn
                    :loading="loading"
                    :disabled="loading"
                    color="primary"
                    type="submit"
                    class="px-12"
                    name="login-button"
                    @click="searchOrder()"
                  >
                    Search
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-row>
        </v-form>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    store: {
      type: Object,
      default: null
    },
    lookupData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    async searchOrder() {
      if (!this.store) {
        return;
      }

      // mixin.js
      if (!(await this.$validateAll())) {
        return;
      }

      this.loading = true;

      var params = {
        order_id: this.lookupData.order_id,
            postal_code: this.lookupData.postal_code
      };
      this.$http
        .request(`/returns-portal/${this.store.returns_portal_code}/order`, {
          method: 'get',
          params: params,
        })
        .then((res) => {
          const data = res.data;
          if (data.success) {
            if(data.existing_return_shipment){
              this.$emit('goToReturnLabel', { shipment: data.existing_return_shipment });
            }
            else{
              this.$emit('goToOrderSelection', { order: data.shipment });
            }
          } else {
            this.$notify({
              group: 'main',
              title: 'Order Not Found',
              text: 'We could not find your order.',
              duration: 5000,
              type: 'error'
            });
          }
        })
        .catch(() => {
          this.$notify({
            group: 'main',
            text: 'Unknown Error Occurred',
            duration: 5000,
            type: 'error'
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
