import axios from 'axios';

function initialState() {
  return {
    lists: [],
    loading: false
  };
}

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    lists: (state) => {
      return state.lists.map((list) => {
        return {
          ...list,
          // convert to string to match with values from Criteria.vue
          id: list.id.toString()
        };
      });
    }
  },
  actions: {
    getLists({ commit, state }) {
      state.loading = true;
      axios
        .get('/list-criteria')
        .then((response) => {
          commit('updateLists', response.data);
        })
        .finally(() => {
          state.loading = false;
        });
    },
    async deleteList({ dispatch }, id) {
      await axios.request(`/list-criteria/${id}`, {
        method: 'delete'
      });
      dispatch('getLists');
    },
  },
  mutations: {
    reset(state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    updateLists(state, lists) {
      state.lists = lists;
    }
  }
};
