<template>
  <v-container
    v-if="shipment !== null"
    pa-0
    pb-10
  >
    <div>
      <div class="d-flex flex-row justify-space-between">
        <h4>Shipment Details</h4>
      </div>

      <div class="d-flex flex-row justify-space-between">
        <div class="shipment-title">
          Shipment ID
        </div>

        <v-tooltip top>
          <template #activator="{ on }">
            <a
              v-clipboard:copy="shipment.ship_code"
              v-clipboard:success="oncopy"
              style="font-weight: bold"
              href="javascript:void(0);"
              v-on="on"
            >{{ shipment.ship_code }}</a>
          </template>
          <span>Click to copy</span>
        </v-tooltip>
      </div>

      <div
        v-if="shipment.outbound_ship_code"
        class="d-flex flex-row justify-space-between"
      >
        <div class="shipment-title">
          Outbound Shipment ID
        </div>

        <v-tooltip top>
          <template #activator="{ on }">
            <a
              v-clipboard:copy="shipment.outbound_ship_code"
              v-clipboard:success="oncopy"
              style="font-weight: bold"
              href="javascript:void(0);"
              v-on="on"
            >{{ shipment.outbound_ship_code }}</a>
          </template>
          <span>Click to copy</span>
        </v-tooltip>
      </div>

      <div class="d-flex flex-row justify-space-between">
        <div class="shipment-title">
          Shipment Status
        </div>
        <div v-html="$options.filters.status(shipment.status_id)" />
      </div>

      <div class="d-flex flex-row justify-space-between">
        <div class="shipment-title">
          Signature Confirmation
        </div>
        <div>{{ shipment.signature_confirmation == true ? 'Yes' : 'No' }}</div>
      </div>

      <div
        v-if="shipment.region"
        class="d-flex flex-row justify-space-between"
      >
        <div class="shipment-title">
          Origin
        </div>
        <div>{{ shipment.region.name }}</div>
      </div>

      <div class="d-flex flex-row justify-space-between">
        <div class="shipment-title">
          Created By
        </div>

        <span>
          {{ shipment.created_by_user?.business_name || shipment.created_by_user?.name || shipment.created_by_user?.email || '' }}
        </span>
      </div>

      <div
        v-if="can('access.admin')"
        class="mt-3"
      >
        <div
          v-if="shipment.seik"
          class="d-flex flex-row justify-space-between"
        >
          <div>SEIK</div>
          <div>{{ shipment.seik }}</div>
        </div>

        <div
          v-if="shipment.truck"
          class="d-flex flex-row justify-space-between"
        >
          <div>Truck</div>
          <div>{{ shipment.truck }}</div>
        </div>
      </div>

      <div
        v-if="shipment.status_id == 3 && can('CLIENT.SHIPPING')"
        class="d-flex flex-row justify-space-between"
      >
        <div class="">
          <v-btn
            small
            color="primary"
            depressed
            @click="openQuickShipFormDialog()"
          >
            <v-icon left>
              mdi-truck-fast
            </v-icon>
            Quick Ship
          </v-btn>
        </div>

        <div>
          <v-btn
            small
            depressed
            color="primary"
            @click="editShipment(shipment)"
          >
            <v-icon left>
              mdi-restart
            </v-icon>
            Resume
          </v-btn>
        </div>
      </div>

      <div
        v-if="canClaim"
        class="d-flex flex-row justify-space-between"
      >
        <div class="">
          <v-btn
            small
            color="primary"
            depressed
            @click="openQuickClaimsDialog()"
          >
            <v-icon left>
              mdi mdi-chat-alert
            </v-icon>
            Claim
          </v-btn>
        </div>
      </div>

      <div
        v-if="shipment.transactions && shipment.transactions.length > 0"
        class="text-center mt-4 mb-4"
      >
        <router-link
          router
          :class="{ disabled: !can('CLIENT.ACCOUNT_MANAGEMENT') }"
          :to="{
            name: 'transactions',
            params: {
              transactional_id: shipment.ship_code,
              transactional_type: 'shipment'
            }
          }"
        >
          View Transactions
        </router-link>
      </div>
      <v-divider class="details-divider" />
    </div>
    <div>
      <error-details :shipment="shipment" />
    </div>

    <div>
      <v-expansion-panels
        v-model="selectedPanel"
        class="edit-order-expansion-panel"
        focusable
        flat
        accordion
      >
        <v-expansion-panel :key="0">
          <v-expansion-panel-header>
            <h4>Tracking</h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <tracking-details
              :shipment="shipment"
              :show-header="false"
              :show-divider="false"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel
          v-if="(shipment.handling_fee || shipment.postage_rate || shipment.tax) > 0"
          :key="1"
        >
          <v-expansion-panel-header>
            <h4>Postage</h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <postage-details
              :shipment="shipment"
              :show-header="false"
              :show-divider="false"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel
          :key="2"
        >
          <v-expansion-panel-header>
            <h4>Stallion Protection</h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <protection-details
              :shipment="shipment"
              :show-header="false"
              :show-divider="false"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel
          v-if="shipment.package_type_id"
          :key="3"
        >
          <v-expansion-panel-header>
            <h4>Package</h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <package-details
              :shipment="shipment"
              :show-header="false"
              :show-divider="false"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel
          v-if="shipment.tax_id"
          :key="4"
        >
          <v-expansion-panel-header>
            <h4>Customs</h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <customs-details
              :shipment="shipment"
              :show-header="false"
              :show-divider="false"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel
          v-if="shipment.order_id !== null"
          :key="5"
        >
          <v-expansion-panel-header>
            <h4>Store</h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <store-details
              :shipment="shipment"
              :show-header="false"
              :show-divider="false"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel
          v-if="shipment.name"
          :key="6"
        >
          <v-expansion-panel-header>
            <h4>Recipient</h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-col cols="12">
              <address-verification-details :shipment="shipment" />
            </v-col>
            <recipient-details
              :shipment="shipment"
              :show-header="false"
              :show-divider="false"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel
          v-if="shipment.return_name"
          :key="7"
        >
          <v-expansion-panel-header>
            <h4>Return</h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <return-address-details
              :shipment="shipment"
              :show-header="false"
              :show-divider="false"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel
          v-if="shipment.applied_automation?.length > 0"
          :key="8"
        >
          <v-expansion-panel-header>
            <h4>Applied Automation</h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <automation-details
              :shipment="shipment"
              :show-header="false"
              :show-divider="false"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
 
    <quick-ship-form-dialog
      v-if="shipment"
      ref="refQuickShipFormDialog"
      v-model="showQuickShipFormDialog"
      :shipment.sync="shipment"
      :tax-identifiers="taxIdentifiers"
      :update-shipment-handler="updateShipmentHandler"
    />

    <quick-claims-dialog
      v-if="shipment"
      v-model="showQuickClaimsDialog"
      :shipment.sync="shipment"
      :update-shipment-handler="updateQuickClaimsShipment"
    />
  </v-container>
</template>

<script>
import QuickShipFormDialog from '../../quick-ship/QuickShipFormDialog.vue';
import QuickClaimsDialog from '../../../claims/quick-claims-dialog.vue';

export default {
  components: {
    QuickShipFormDialog,
    QuickClaimsDialog
  },
  props: {
    shipment: {
      type: Object,
      default: null
    },
    taxIdentifiers: {
      default: () => [],
      type: Array
    },
    updateShipmentHandler: {
      default: () => {},
      type: Function
    }
  },

  data() {
    return {
      loading: false,
      showQuickShipFormDialog: false,
      showQuickClaimsDialog: false,
      selectedPanel: 0
    };
  },
  computed: {
    canClaim() {
      // type_id 2 = Enterprise
      return (
        this.$auth.user &&
        this.$auth.user.type_id == 2 &&
        this.shipment.claimable &&
        this.can('CLIENT.CLAIMS')
      );
    }
  },
  watch: {
    //
  },
  methods: {
    oncopy() {
      this.$notify({
        group: 'main',
        title: 'Copied!',
        text: 'Shipment ID has been copied to the clipboard',
        duration: 2000,
        type: 'info'
      });
    },

    async openQuickShipFormDialog() {
      try {
        if(this.isScaleInitialized() && this.$store.state.qz.enabledAutoWeightFetch){
          await this.$store.dispatch('qz/updateQuickShipStatus', true);
          if(!this.$store.state.qz.currentScaleStream) {
            await this.startScaleStream();
          }
        }
      } catch (e) {
        console.error(e);
      }
      this.showQuickShipFormDialog = true
    },

    openQuickClaimsDialog() {
      this.showQuickClaimsDialog = true;
    },
    
    updateQuickClaimsShipment() {
      // event emitted @view-shipment-details.vue
      this.$emit('getShipment', this.shipment.id)
    }
  }
};
</script>
