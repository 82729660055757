<template>
  <StallionDialog
    v-model="show"
    :width="500"
    persistent
    title="Update Shipment Status"
  >
    <template #content>
      <v-layout column>
        <v-flex
          xs12
        >
          <div class="dialog-info">
            Request Photos of {{ returns.length }} shipment Return
          </div>
        </v-flex>
        <v-flex
          xs12
          text-center
          mt-4
          mb-12
        >
          <v-checkbox
            v-model="charge_request_photos"
            label="Charge Customer for Request"
          />
        </v-flex>
      </v-layout>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        :loading="update_loading"
        :disabled="update_loading"
        @click="requestAdditionalPhotos()"
      >
        Request
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    returns: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      update_loading: false,
      dowload_loading: false,
      statuses: [],
      status: 0,
      charge_request_photos: false
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },

  methods: {
    requestAdditionalPhotos: function() {
      this.update_loading = true;

      var ids = [];
      this.returns.forEach(function(shipmentReturn) {
        ids.push(shipmentReturn.id);
      });
      this.$http
        .post('/admin/returns/request-photo', {
          ids: ids,
          charge: this.charge_request_photos
        })
        .then(() => {
          Event.fire('update-admin-returns');
        })
        .catch(() => {
        })
        .finally(() => {
          this.update_loading = false;
          this.show = false;
        });
    }
  }
};
</script>
