// import 'babel-polyfill';

import './bootstrap';

import '@fortawesome/fontawesome-free/css/all.css';

import Vue from 'vue';
import store from './store';
import router from './router';
import vuetify from './plugins/vuetify';
import mixin from './mixins/mixin';

import './filter';
import './components';
import './plugins/auth';
import './plugins/moment';
import './plugins/vee-validate';
import './plugins/axios';
import './plugins/vue-notification';
import './plugins/vue-barcode-scanner';
import './plugins/vue-clipboard2';
import './plugins/vue-the-mask';
import './plugins/vue-cookies';
import './plugins/vue-signature-pad';
import './plugins/qz-tray'
import './plugins/vue-meta'
import './plugins/sentry';

Vue.mixin(mixin);

import printJS from 'print-js';
import swal from 'sweetalert';
window.printJS = printJS;
window.swal = swal;
// import Echo from 'laravel-echo';
import { io } from "socket.io-client";
window.io = io;
window.Event = new class {
  constructor() {
    this.vue = new Vue();
  }
  fire(event, data = null) {
    this.vue.$emit(event, data);
  }
  listen(event, callback) {
    this.vue.$on(event, callback);
  }
}();

window.bus = new Vue();
window.bus1 = new Vue();
import App from './App.vue';

Vue.prototype.$contactUs = 'https://help.stallionexpress.ca/portal/en/newticket?departmentId=952951000000006907'

new Vue({
  el: '#app',
  render: h => h(App),
  router: router,
  id: 'app',
  store,
  vuetify
});
