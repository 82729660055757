function initialState() {
  return {
    loading: false,

    totals: null,
    byType: [],
    byDate: [],

    cancelSource: null,

    chargeTypes: []
  };
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setLoading: (state, value) => (state.loading = value),

    setTotals: (state, value) => (state.totals = value),

    setByType: (state, value) => (state.byType = value),
    setByDate: (state, value) => (state.byDate = value),

    setCancelSource: state => (state.cancelSource = axios.CancelToken.source()),
    cancelSource: state => state.cancelSource.cancel('Cancel supplies request'),

    reset(state) {
      const s = initialState();
      Object.keys(s).forEach(key => {
        state[key] = s[key];
      });
    }
  },
  actions: {
    getData({ commit, state }, payload) {
      commit('reset');

      const { startDate, endDate } = payload;

      var params = {
        start_date: startDate,
        end_date: endDate
      };

      if (state.cancelSource) {
        commit('cancelSource');
      }

      commit('setCancelSource');
      commit('setLoading', true);

      axios
        .request(`/admin/analytics/supplies`, {
          method: 'get',
          params: params,
          cancelToken: state.cancelSource.token
        })
        .then(response => response.data)
        .then(response => {
          commit('setTotals', response.totals);
          commit('setByType', response.by_type);
          commit('setByDate', response.by_date);
        })
        .finally(() => {
          commit('setLoading', false);
        });
    }
  }
};
