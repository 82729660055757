<template>
  <div>
    <v-form v-if="!condensed">
      <v-row dense>
        <v-col
          cols="12"
          md="4"
          class="pr-1"
        >
          <v-text-field
            v-model="search"
            style="padding: 0"
            label="Search"
            prepend-inner-icon="search"
            clearable
            outlined
            @input="submitSearch()"
          />
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-menu
            v-model="start_date_menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template #activator="{ on }">
              <v-text-field
                v-model="start_date"
                class="pr-1"
                style="padding: 0"
                prepend-inner-icon="event"
                label="Start Date"
                readonly
                clearable
                outlined
                v-on="on"
                @change="updateTable()"
              />
            </template>
            <v-date-picker
              v-model="start_date"
              @change="updateTable()"
              @input="start_date_menu = false"
            />
          </v-menu>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-menu
            v-model="end_date_menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template #activator="{ on }">
              <v-text-field
                v-model="end_date"
                prepend-inner-icon="event"
                style="padding: 0"
                label="End Date"
                readonly
                clearable
                outlined
                v-on="on"
                @change="updateTable()"
              />
            </template>
            <v-date-picker
              v-model="end_date"
              @change="updateTable()"
              @input="end_date_menu = false"
            />
          </v-menu>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="d-flex flex-wrap">
          <v-tooltip
            v-for="option in filter_options"
            :key="option.value"
            bottom
          >
            <template #activator="{ on }">
              <v-card
                class="state-button"
                outlined
                hover
                :raised="filter == option.value"
                v-on="on"
                @click="setStatus(option.value)"
              >
                <v-icon
                  small
                  left
                >
                  {{ option.icon }}
                </v-icon>
                {{ option.name }}
              </v-card>
            </template>
            <span>{{ option.name }} Claims</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-form>

    <v-data-table
      v-model="selected"
      class="table-striped"
      :headers="headers"
      :items="items"
      item-key="id"
      :loading="loading"
      :server-items-length="totalItems"
      :options.sync="options"
      :footer-props="{
        'items-per-page-options': [50, 100, 200, 500]
      }"
      :items-per-page="25"
      @click:row="rowClick"
    >
      <template #item.created_at="{ item }">
        {{ item.created_at | date_pretty }}
        <br>
        ({{ item.created_at | datetime_from }})
      </template>

      <template #item.value="{ item }">
        {{ item.value | currency }}
      </template>
      <template #item.docs="{ item }">
        <v-btn
          v-if="item.photo_url"
          icon
          :href="item.photo_url"
          target="_blank"
        >
          <v-icon>mdi-camera</v-icon>
        </v-btn>

        <span v-if="item.additional_document_urls">
          <v-btn
            v-for="url in item.additional_document_urls"
            :key="url"
            :href="url"
            target="_blank"
            icon
          >
            <v-icon>mdi-file-document</v-icon>
          </v-btn>
        </span>

        <span v-if="item.photo_urls">
          <v-btn
            v-for="url in item.photo_urls"
            :key="url"
            :href="url"
            target="_blank"
            icon
          >
            <v-icon>mdi-camera</v-icon>
          </v-btn>
        </span>

        <v-btn
          v-if="item.invoice_url"
          icon
          :href="item.invoice_url"
          target="_blank"
        >
          <v-icon>mdi-file-document</v-icon>
        </v-btn>
      </template>

      <template #item.review="{ item }">
        <v-btn
          v-if="item.status == 'Pending'"
          outlined
          color="primary"
          :to="'/admin/claims/' + item.id + '/review'"
        >
          Review
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    filter: {
      type: String,
      default: null
    },
    condensed: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      show: null,
      show2: null,
      selected: [],

      options: {
        sortBy: ['created_at'],
        sortDesc: [true]
      },
      loading: false,
      totalItems: 0,
      items: [],

      headers: [
        {
          text: 'Ship Code',
          align: 'left',
          value: 'ship_code'
        },
        {
          text: 'Tracking Number',
          align: 'left',
          value: 'tracking_code'
        },
        {
          text: 'Type',
          align: 'left',
          value: 'type'
        },
        {
          text: 'Status',
          align: 'left',
          value: 'status'
        },
        {
          text: 'Comment',
          align: 'left',
          value: 'comment'
        },
        {
          text: 'Contents',
          align: 'left',
          value: 'contents'
        },
        {
          text: 'Value',
          align: 'left',
          value: 'value'
        },
        {
          text: 'Uploads',
          align: 'left',
          value: 'docs',
          sortable: false
        },
        {
          text: 'Created',
          value: 'created_at'
        },
        {
          text: '',
          value: 'review',
          sortable: false
        }
      ],
      filter_options: [
        {
          name: 'Ready',
          icon: 'mdi-package-variant-closed',
          value: 'ready'
        },
        {
          name: 'On Hold',
          icon: 'mdi-circle-edit-outline',
          value: 'hold'
        },
        {
          name: 'Approved',
          icon: 'mdi-check',
          value: 'approved'
        },
        {
          name: 'Denied',
          icon: 'mdi-cross',
          value: 'denied'
        },
        {
          name: 'Pending',
          icon: 'mdi-clock',
          value: 'pending'
        }
      ],

      search: null,

      start_date: null,
      end_date: null,
      start_date_menu: null,
      end_date_menu: null,
      timer: null,
      status: 'ready',

      cancelSource: null
    };
  },

  watch: {
    filter: function () {
      this.options.page = 1;
      this.updateTable();
    },
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    }
  },

  mounted() {
    this.selected = [];

    Event.listen('update-claims', () => {
      this.updateTable();
    });
  },
  methods: {
    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.options.page = 1;
      this.timer = setTimeout(function () {
        self.updateTable();
      }, ms);
    },
    updateTable() {
      this.selected = [];
      this.totalItems = 0;
      this.items = [];

      this.getDataFromApi().then((data) => {
        this.items = data.items;
        this.totalItems = data.total;
      });
    },

    setStatus(status) {
      this.status = status;
      this.updateTable();
    },

    rowClick(item) {
      this.selected = [item];
    },

    getDataFromApi() {
      this.loading = true;
      return new Promise((resolve) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let items = [];
        let total = 0;
        let pages = 0;

        console.log(this.options);
        var params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sortBy[0],
          desc: sortDesc[0],
          search: this.search,
          start_date: this.start_date,
          end_date: this.end_date,
          // filter: this.filter,
          status: this.status
        };

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        this.$http
          .request(`/admin/claims`, {
            method: 'get',
            params: params,
            cancelToken: this.cancelSource.token
          })
          .then((response) => response.data)
          .then((response) => {
            items = response.data;
            total = response.total;
            pages = response.last_page;

            this.loading = false;
            resolve({
              items,
              total,
              pages
            });
          });
      });
    }
  }
};
</script>
