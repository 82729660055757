<template>
  <FullPageLayout title="NPI Containers">
    <template #default>
      <v-container>
        <v-row>
          <v-col
            cols="12"
          >
            <ContainerTable />
          </v-col>
        </v-row>
      </v-container>
    </template>
  </FullPageLayout>
</template>

<script>

import ContainerTable from './ContainerTable.vue';

export default {
  components: {
    ContainerTable
  },
  data() {
    return {
      
    };
  },

  mounted() {
  
  },

  methods: {
   
    }
  

};
</script>