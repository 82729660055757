const DefaultTemplate = {
  id: null,
  name: null,
  size: null,
  is_default: false,
  css: `table {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  body {
    min-height: 8cm;
    min-width: 10.16cm;
    margin: 0;
    padding: 8px;
    font-size: 1rem;
    color: black;
    font-weight: 500;
  }

  .center {
    margin: auto;
    width: 50%;
    text-align: center;
  }

  .center-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  td {
    vertical-align: text-top;
  }

  td:nth-child(2) {
    padding-left: 10px
  }

  tfoot>tr>td {
    text-align: right;
  }

  tfoot>tr>td:first-child {
    font-weight: bold;
  }

  * {
    font-family: monospace;
  }`,
  page_header: `
  <table>
    <tr>
      <td colspan="2">
        <div class="center-image">
            [STORE LOGO]
        </div>
      </td>
    </tr>

    <tr style="background-color: black; color: white">
      <td colspan="2" class="center">
        <h2>Packing Slip</h2>
      </td>
    </tr>

    <tr>
      <td>
        <table>
            <tr>
              <td>
                <strong>Name:</strong>
              </td>
              <td>
                [CUSTOMER NAME]
              </td>
            </tr>
            <tr>
              <td>
                <strong>Company:</strong>
              </td>
              <td>
                [COMPANY NAME]
              </td>
            </tr>
            <tr>
              <td>
                <strong>Ship To:</strong>
              </td>
              <td>
                [SHIP TO]
              </td>
            </tr>
            <tr>
              <td>
                <strong>Phone:</strong>
              </td>
              <td>
                [PHONE]
              </td>
            </tr>
        </table>
      </td>
      <td>
        <table>
          <tr>
            <td>
              <strong>Order ID: </strong>
            </td>
            <td>
            [ORDER ID]
            </td>
          </tr>
          <tr>
            <td>
              <strong>Order Tags: </strong>
            </td>
            <td>
              [ORDER TAGS]
            </td>
          </tr>
          <tr>
            <td>
              <strong>Ship Code: </strong>
            </td>
            <td>
              [SHIP CODE]
            </td>
          </tr>
          <tr>
            <td>
              <strong>Batch: </strong>
            </td>
            <td>
              [BATCH ID]
            </td>
          </tr>
          <tr>
            <td>
              <strong>Shipping Method: </strong>
            </td>
            <td>
              [SHIPPING METHOD]
            </td>
          </tr>
          <tr>
            <td>
              <strong>Dimensions: </strong>
            </td>
            <td>
              [DIMENSIONS]
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>`,
  item_header: `<table>
    <tr>
      <td colspan="2" style="padding-top: 5px;">
          <table style="font-size: .9rem">
            <tr>
              <td>
                <strong>TITLE</strong>
              </td>

              <td>
                <strong>SKU</strong>
              </td>

              <td>
                <strong>TAGS</strong>
              </td>

              <td>
                <strong>WEIGHT</strong>
              </td>

              <td>
                <strong>QUANTITY</strong>
              </td>

              <td>
                <strong>VALUE</strong>
              </td>
            </tr>
            <!-- Contents from the Item Content Section will be inserted here -->
            [ITEM CONTENT]
          </table>
      </td>
    </tr>
  </table>
  `,
  // by default item_content should only have <tr></tr> so that it can be inserted properly to the item_header [ITEM CONTENT] element
  item_content: `<!-- Item Content must be enclosed with <tr></tr> element -->
  <tr>
    <td>
      [ITEM NAME]
    </td>
    <td>
      [ITEM SKU]
    </td>
    <td>
      [ITEM TAGS]
    </td>
    <td>
      [ITEM WEIGHT]
    </td>
    <td>
      [ITEM QUANTITY]
    </td>
    <td>
      [ITEM VALUE]
    </td>
  </tr>
  `,
  page_footer: `<table style="font-size: .9rem">
    <tr>
      <td colspan="2" class="center">
        <h3> [BARCODE] </h3>
      </td>
    </tr>

    <tr>
      <td colspan="2" class="center">
        <h3> [ORDER ID] </h3>
      </td>
    </tr>
  </table>`,
}

export default DefaultTemplate
