<template>
  <v-flex
    v-if="
      shipment.errors && shipment.errors.length > 0 && (shipment.status_id == 3 || shipment.fulfillment_status_id == 3)
    "
    xs12
  >
    <h4>Errors</h4>
    <ul class="red--text">
      <li
        v-for="error in shipment.errors"
        :key="error.description"
      >
        {{ error.description }}
      </li>
    </ul>
    <v-divider class="details-divider" />
  </v-flex>
</template>

<script>
export default {
  props: {
    shipment: {
      type: Object,
      default: null
    }
  }
};
</script>
