<template>
  <FullPageLayout
    :title="`Checkout`"
  >
    <v-card outlined>
      <v-card-text>
        <v-row>
          <v-col
            v-if="cart.items.length > 0"
            cols="12"
          >
            <transition-group name="slide">
              <v-container
                v-for="item in cart.items"
                :key="'c_' + item.id"
                class="d-flex pa-5"
              >
                <v-flex xs2>
                  <img
                    :src="item.image_location"
                    height="100"
                    width="100"
                  >
                </v-flex>
                <v-flex
                  xs10
                  text-left
                >
                  <div class="d-flex flex-row justify-space-between">
                    <h2>{{ item.name }}</h2>
                    <h4 class="font-weight-bold">
                      {{ (item.purchase_quantity * item.price) | currency }}
                    </h4>
                  </div>

                  <div class="d-flex flex-row">
                    <div>
                      <p>Unit Price: {{ item.price | currency }}</p>
                    </div>
                    <div class="px-5">
                      <!--:max="item.quantity_in_stock"-->
                      <NumberInputSpinner
                        v-model="item.purchase_quantity"
                        :min="1"
                        :max="item.region_location_inventory.quantity_in_stock"
                        button-class="btn-increment"
                        style="transform: scale(0.8)"
                        :integer-only="true"
                        @input="
                          cart.items.find((element) => element.id === item.id) &&
                            addProductToCart(item)
                        "
                      />
                    </div>
                    <div>
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <v-btn
                            color="#BA3F3F"
                            :ripple="false"
                            small
                            class="no-background-hover px-0"
                            text
                            dark
                            :disabled="
                              !cart.items.find(
                                (element) => element.id === item.id
                              )
                            "
                            @click="removeProductFromCart(item)"
                            v-on="on"
                          >
                            Remove
                          </v-btn>
                        </template>
                        <span>Remove</span>
                      </v-tooltip>
                    </div>
                  </div>
                </v-flex>
              </v-container>
              <v-divider />
            </transition-group>
            <v-divider />
    

            <!-- Totals -->
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <table class="float-right">
                    <tr>
                      <td class="pr-3">
                        <h4 class="font-weight-bold">
                          Subtotal
                        </h4>
                      </td>
                      <td><h4>{{ cart.subtotal | currency }}</h4></td>
                    </tr>
                    <tr>
                      <td>
                        <h4 class="font-weight-bold">
                          Tax
                        </h4>
                      </td>
                      <td><h4>{{ cart.tax | currency }}</h4></td>
                    </tr>
                    <tr>
                      <td>
                        <h4 class="font-weight-bold">
                          Total
                        </h4>
                      </td>
                      <td><h4>{{ cart.total | currency }}</h4></td>
                    </tr>
                  </table>
                </v-col>
                <v-col>
                  <div
                    v-if="$auth.user.deliver_supplies"
                    class="mb-5"
                  >
                    <div>
                      <h4>Deliver Supplies</h4>
                      <p>
                        Supplies will be delivered to {{ $auth.user.address1 }},
                        {{ $auth.user.address2 }}
                      </p>
                      <v-switch
                        v-model="deliver_supplies"
                        :label="` ${deliver_supplies ? 'Enabled' : 'Disabled'}`"
                      />
                    </div>
                  </div>
                  <div v-if="!$auth.user.deliver_supplies || !deliver_supplies">
                    <v-row>
                      <v-col md="4">
                        <h4 class="font-weight-bold">
                          Region:
                        </h4>
                      </v-col>
                      <v-col md="3">
                        <h4>{{ cart.pickup_location.region.name }}</h4>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col md="4">
                        <h4 class="font-weight-bold">
                          Pickup Location:
                        </h4>
                      </v-col>
                      <v-col md="5">
                        <v-select
                          v-model="branch"
                          v-validate="'required'"
                          :error-messages="errs.collect('branch')"
                          data-vv-name="branch"
                          :items="branches"
                          item-value="id"
                          item-text="name"
                          label="Please select a branch"
                          outlined
                          @change="
                            (newVal) => {
                              branch = branches.find((i) => i.id === newVal);
                            }
                          "
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-alert
                          text
                          type="info"
                        >
                          You will receive an email once your supplies are ready for
                          pickup
                        </v-alert>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
                
                <v-col cols="12">
                  <v-layout class="d-flex justify-end mt-md-3">
                    <v-flex
                      xs12
                      text-right
                    >
                      <v-btn
                        router
                        to="/supplies/store"
                        class="mx-2"
                        color="primary"
                        outlined
                      >
                        Continue Shopping
                      </v-btn>
                      <v-menu offset-y>
                        <template #activator="{ on }">
                          <v-btn
                            color="primary"
                            dark
                            v-on="on"
                            @click="$refs.suppliesConfirm.openDialog()"
                          >
                            Purchase
                          </v-btn>
                        </template>
                      </v-menu>
                    </v-flex>
                  </v-layout>
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>

          <v-card
            v-else
            :elevation="0"
          >
            <v-card-text>
              <v-layout>
                <v-flex
                  xs12
                  text-center
                >
                  <h2 class="ma-12">
                    No Items in Cart
                  </h2>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
          <ConfirmSuppliesOrderDialog
            ref="suppliesConfirm"
            :pickup-location="branch"
            :deliver-supplies="deliver_supplies"
            :validate="validate"
          />
        </v-row>
      </v-card-text>
    </v-card>
  </FullPageLayout>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import NumberInputSpinner from 'vue-number-input-spinner';
import ConfirmSuppliesOrderDialog from './ConfirmSuppliesOrderDialog.vue';
export default {
  metaInfo: {
    title: "Checkout | Stallion Express"
  },
  components: {
    NumberInputSpinner,
    ConfirmSuppliesOrderDialog
  },
  data() {
    return {
      supplies: [],
      dialog: null,
      branches: [],
      branch: null,
      deliver_supplies: false
    };
  },
  computed: {
    ...mapState(['cart'])
  },
  mounted() {
    this.$store.commit('cart/updateTotals');
    this.getLocations();
    this.deliver_supplies = this.$auth.user.deliver_supplies;
  },
  methods: {
    ...mapActions('cart', ['addProductToCart', 'removeProductFromCart']),
    validate() {
      return this.$validator.validate();
    },
    async getLocations() {
      if (this.cart.items) {
        try {
          const region = this.cart.pickup_location.region;

          const params = {
            region_id: region.id,
            supplies_pickup: 1
          };
          const response = await this.$http.get('/branches', { params });
          this.branches = response.data;
        } catch (error) {
          console.log(error);
        }
      }
    }
  }
};
</script>

<style scoped>
.no-background-hover::before {
  background-color: transparent !important;
}
</style>
