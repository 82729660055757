import axios from 'axios';

function initialState() {
  return {
    user_stores: [],
    updating: false,
    autoRefresh: false,
    timer: '',
    loading: false
  };
}

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    getStoreOptions: (state) => {
      let r = [];

      state.user_stores.forEach((item) => {
        r.push({
          id: item.id.toString(),
          identifier: item.identifier,
          nickname: item.nickname,
        });
      });

      return r;
    }
  },
  actions: {
    cancelAutoRefresh({ state }) {
      state.autoRefresh = false;
      clearInterval(state.timer);
    },
    autoRefresh({ state, dispatch }) {
      state.autoRefresh = true;
      state.timer = setInterval(function () {
        dispatch('getStores');
      }, 5000);
    },
    getStores({ commit, state, dispatch }) {
      state.loading = true;
      axios
        .get('/user-stores')
        .then((response) => {
          commit('updateStores', response.data);

          const updating = response.data.some((e) => e.is_updating == true);
          commit('updateStatus', updating);

          if (updating === true && state.autoRefresh === false) {
            dispatch('autoRefresh');
          }

          if (updating === false && state.autoRefresh === true) {
            dispatch('cancelAutoRefresh');
          }
        })
        .catch(() => {
          dispatch('cancelAutoRefresh');
        })
        .finally(() => {
          state.loading = false;
        });
    },
    async deleteStore({ dispatch }, id) {
      await axios.request(`/user-stores/${id}`, {
        method: 'delete'
      });
      dispatch('getStores');
    },
    updateStores({ dispatch }, user_store_id = null) {
      axios
        .request(`/user-stores/update-orders`, {
          method: 'post',
          data: { user_store_id }
        })
        .then(() => {
          dispatch('getStores');
        });
    }
  },
  mutations: {
    reset(state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    updateStores(state, user_stores) {
      state.user_stores = user_stores;
    },
    updateStatus(state, user_stores) {
      state.updating = user_stores;
    }
  }
};
