import Vue from 'vue';
// layout
Vue.component('AdminLayout', () =>
  import('../components/layouts/admin-layout.vue')
);

// shipments
Vue.component('AdminShipmentTable', () =>
  import('../components/admin/shipments/admin-shipment-table.vue')
);
Vue.component('UpdateStatus', () =>
  import('../components/admin/shipments/update-shipment-status.vue')
);
Vue.component('UpdatePostage', () =>
  import('../components/admin/shipments/update-shipment-postage.vue')
);

// user
Vue.component('ExportByCustomer', () =>
  import('../components/admin/users/by-customer.vue')
);

Vue.component('CreateUser', () =>
  import('../components/admin/users/create-user.vue')
);

// returns
Vue.component('ShipmentReturnForm', () =>
  import('../components/admin/returns/form.vue')
);
Vue.component('ChangeReturnStatus', () =>
  import('../components/admin/return-trips/change-status.vue')
);
Vue.component('ReturnTableTrips', () =>
  import('../components/admin/return-trips/admin-returns-table.vue')
);
Vue.component('UpdateReturnTripStatus', () =>
  import('../components/admin/return-trips/update-return-trip-status.vue')
);
Vue.component('AdminReturnsTable', () =>
  import('../components/admin/returns/admin-returns-table.vue')
);
Vue.component('AdminReturnsLabelTable', () =>
  import('../components/admin/returns/admin-return-labels-table.vue')
);
Vue.component('AdminReturnsLabelIndexTable', () =>
  import('../components/admin/returns/admin-return-labels-index.table.vue')
);
Vue.component('RequestReturn', () =>
  import('../components/admin/returns/bill-return-customer.vue')
);
Vue.component('UpdateReturnStatus', () =>
  import('../components/admin/returns/update-return-status.vue')
);
Vue.component('RequestPhotos', () =>
  import('../components/admin/returns/request-photos.vue')
);
Vue.component('ReturnOrder', () =>
  import('../components/admin/returns/return-order.vue')
);
Vue.component('CreateReturn', () =>
  import('../components/admin/returns/create-return.vue')
);
Vue.component('UploadImages', () =>
  import('../components/admin/returns/upload-image.vue')
);

Vue.component('AdminReturnTripsTable', () =>
  import('../components/admin/returns/return-trips-table.vue')
);
Vue.component('UpdateReturnTripStatus', () =>
  import('../components/admin/returns/update-return-trip-status.vue')
);

Vue.component('CreateUserGroup', () =>
  import('../components/admin/user-groups/create-user-group.vue')
);

Vue.component('GroupUsersTable', () =>
  import('../components/admin/user-groups/group-users-table.vue')
);

Vue.component('GroupAddUsersTable', () =>
  import('../components/admin/user-groups/group-add-users-table.vue')
);

Vue.component('ApproveVoid', () =>
  import('../components/admin/shipments/approve-void.vue')
);

Vue.component('RejectVoid', () =>
  import('../components/admin/shipments/reject-void.vue')
);

Vue.component('AdminReturnRequest', () =>
  import('../components/admin/returns/return-request.vue')
);
Vue.component('AdminReturnDispose', () =>
  import('../components/admin/returns/return-dispose.vue')
);

// shipments
Vue.component('AdminViewShipmentDetails', () =>
  import( '../components/admin/shipments/view-shipment-details.vue')
);

Vue.component('UserSearch', () =>
  import('../components/admin/users/user-search.vue')
);

Vue.component('AccountSearch', () =>
  import('../components/admin/users/account-search.vue')
);
Vue.component('DropOffInvoicesTable', () =>
  import ('../components/admin/users/drop-off-invoices-table.vue')
);
