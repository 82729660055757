import axios from 'axios';

function initialState() {
  return {
    filters: [],
    loading: false
  };
}

export default {
  namespaced: true,
  state: initialState(),
  getters: {},
  actions: {
    getFilters({ commit, state }, { use_cache = false, singleton_requests = false } = {}) {
      state.loading = true;
    
      // Determine the endpoint based on `singleton_requests`
      const endpoint = singleton_requests ? '/filters/active' : '/filters';
    
      axios
        .get(endpoint, {
          params: {
            use_cache: use_cache ? 1 : 0,
          }
        })
        .then(async (response) => {
          const filters = response.data;
    
          if (singleton_requests) {
            // If `singleton_requests` is true, fetch details for each filter in parallel
            const detailedFilters = await Promise.all(
              filters.map(async (filter) => {
                const filterDetailResponse = await axios.get(`/filters/${filter.id}`, {
                  params: {
                    use_cache: use_cache ? 1 : 0,
                  }
                });
                return { ...filter, unfulfilled_count: filterDetailResponse.data.unfulfilled_count };
              })
            );
    
            // Commit the fully detailed filters to the store
            commit('updateFilters', detailedFilters);
          } else {
            // If not using `singleton_requests`, commit filters directly
            commit('updateFilters', filters);
          }
        })
        .finally(() => {
          state.loading = false;
        });
    },
    async deleteFilter({ dispatch }, id) {
      await axios.request(`/filters/${id}`, {
        method: 'delete'
      });
      dispatch('getFilters');
    }
  },
  mutations: {
    reset(state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    updateFilters(state, filters) {
      state.filters = filters;
    }
  }
};
