<template>
  <v-form @submit.prevent="save">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2>Identification</h2>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-autocomplete
            v-model="r.user_id"
            :items="userIDs"
            :search-input.sync="searchID"
            hide-selected
            label="User ID"
            clearable
            @change="submitSearch()"
          />
          <!-- <v-text-field
              v-validate="'required'"
              v-model="r.user_id"
              :error-messages="errs.collect('user_id')"
              label="User _ID"
              data-vv-name="user_id"
              type="number"
          ></v-text-field>-->
        </v-col>
        <v-col
          cols="12"
          md="9"
        >
          <v-text-field
            v-model="r.unique_id"
            v-validate="'required'"
            :error-messages="errs.collect('unique_id')"
            label="Unique ID"
            data-vv-name="unique_id"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <h2>Package Details</h2>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="r.item"
            v-validate="'required'"
            :error-messages="errs.collect('item')"
            label="Item"
            data-vv-name="item"
          />
        </v-col>

        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="r.quantity"
            v-validate="'required'"
            :error-messages="errs.collect('quantity')"
            label="Quantity"
            data-vv-name="quantity"
            type="number"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="r.value"
            v-validate
            :error-messages="errs.collect('value')"
            label="Value"
            data-vv-name="value"
            type="number"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="r.weight"
            v-validate="'required'"
            :error-messages="errs.collect('weight')"
            label="Weight"
            data-vv-name="weight"
            type="number"
          />
        </v-col>
      </v-row>

      <!-- <v-layout row wrap>
					<v-flex xs12>
						<h2>Dimensions</h2>
					</v-flex>
					<v-col cols="12" md="3" >
						<v-text-field
							v-validate
							v-model="r.length"
							:error-messages="errs.collect('length')"
							label="Length"
							data-vv-name="length"
							type="number"
						></v-text-field>
					</v-flex>
					<v-col cols="12" md="3" >
						<v-text-field
							v-validate
							v-model="r.width"
							:error-messages="errs.collect('width')"
							label="Width"
							data-vv-name="width"
							type="number"
						></v-text-field>
					</v-flex>
					<v-col cols="12" md="3" >
						<v-text-field
							v-validate
							v-model="r.height"
							:error-messages="errs.collect('height')"
							label="Height"
							data-vv-name="height"
							type="number"
						></v-text-field>
					</v-flex>
      </v-layout>-->

      <v-layout wrap>
        <!-- 
          <v-flex xs12>
            <h2>Photos</h2>
          </v-flex>
          <v-col cols="12" md="3" >
            <v-text-field
              v-validate="'required'"
              v-model="r.content_photo"
              :error-messages="errs.collect('content_photo')"
              label="Content Photo"
              data-vv-name="content_photo"
            ></v-text-field>
          </v-flex>
          <v-col cols="12" md="3" >
            <v-text-field
              v-validate
              v-model="r.label_photo"
              :error-messages="errs.collect('label_photo')"
              label="Label Photo"
              data-vv-name="label_photo"
            ></v-text-field>
          </v-flex>
          <v-col cols="12" md="3" >
            <v-text-field
              v-validate
              v-model="r.invoice_photo"
              :error-messages="errs.collect('invoice_photo')"
              label="Invoice Photo"
              data-vv-name="invoice_photo"
            ></v-text-field>
          </v-flex>
        </v-layout>-->
        <!-- 
        <v-layout row wrap>
          <v-flex xs12>
            <h2>Addditional Information</h2>
          </v-flex>
          <v-col cols="12" md="3" >
            <v-text-field
              v-validate
              v-model="r.cost"
              :error-messages="errs.collect('cost')"
              label="Cost"
              data-vv-name="cost"
              type="number"
            ></v-text-field>
          </v-flex>
          <v-col cols="12" md="3" >
            <v-text-field
              v-validate
              v-model="r.status"
              :error-messages="errs.collect('status')"
              label="Status"
              data-vv-name="status"
            ></v-text-field>
          </v-flex>
          <v-col cols="12" md="3" >
            <v-text-field
              v-validate
              v-model="r.comments"
              :error-messages="errs.collect('comments')"
              label="Comments"
              data-vv-name="comments"
            ></v-text-field>
          </v-flex>
          <v-col cols="12" md="3" >
            <v-text-field
              v-validate
              v-model="r.fee"
              :error-messages="errs.collect('fee')"
              label="Fee"
              data-vv-name="fee"
              type="number"
            ></v-text-field>
        </v-flex>-->

        <v-flex
          xs12
          text-center
        >
          <v-btn
            color="primary"
            type="submit"
            :loading="loading"
            :disabled="loading"
          >
            Save
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
export default {
  props: {
    r: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      userIDs: [],
      userEmails: [],
      users: [],
      menu1: null,
      loading: false,
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      model: null,
      search: null,
      items: []
    };
  },
  computed: {
    // fields() {
    //     if (!this.model) return []
    //     return Object.keys(this.model).map(key => {
    //         return {
    //             key,
    //             value: this.model[key] || 'n/a'
    //         }
    //     })
    // },
    // items() {
    //     return this.entries.map(entry => {
    //         const Description = entry.Description.length > this.descriptionLimit ?
    //             entry.Description.slice(0, this.descriptionLimit) + '...' :
    //             entry.Description
    //         return Object.assign({}, entry, {
    //             Description
    //         })
    //     })
    // }
  },
  watch: {
    model(val) {
      console.log(val);
      if (val) {
        this.r.item = this.toTitleCase(val.description);
        this.r.hs_code1 = val.hs_code;
      } else {
        this.r.item = null;
        this.r.hs_code1 = null;
      }
    },
    search() {
      // Items have already been loaded
      if (this.items.length > 0) return;

      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      // Lazily load input items
      this.$http
        .get('/hs-codes', this.shipment)
        .then(res => res.data)
        .then(res => {
          // const {
          //   // count,
          //   entries
          // } = res;
          // this.count = count
          this.items = res;
        })
        .catch(() => {})
        .finally(() => (this.isLoading = false));
    }
  },
  mounted() {
    this.getShipCodes();
    this.$http
      .get('/hs-codes', this.shipment)
      .then(res => res.data)
      .then(res => {
        // const {
        //   // count,
        //   entries
        // } = res;
        // this.count = count
        this.items = res;
      })
      .catch(() => {})
      .finally(() => (this.isLoading = false));
  },
  created() {},
  methods: {
    getShipCodes() {
      this.$http
        .get('/admin/users/all')
        .then(response => response.data)
        .then(response => {
          this.userIDs = response.map(e => e.id);
          this.userEmails = response.map(e => e.email);
          this.users = response;
        })
        .catch(() => {});
    },
    toTitleCase(str) {
      return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    save() {
      this.$validator.validate().then(result => {
        if (result) {
          this.loading = true;
          this.$http
            .put('/admin/returns/' + this.r.id, this.r)
            .then(() => {
              this.$notify({
                group: 'main',
                title: 'Return Updated',
                text: 'Return has been updated successfully',
                duration: 5000,
                type: 'success'
              });

              this.$router.push({
                name: 'admin-return-show',
                params: {
                  id: this.r.id
                }
              });
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.errorMessage('Some of the form fields are invalid.');
        }
      });
    }
  }
};
</script>
