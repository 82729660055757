<template>
  <StallionDialog
    v-model="dialog"
    persistent
    :width="800"
    :title="edit ? 'Edit Announcement' : 'Create Announcement'"
  >
    <template #content>
      <v-container>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="announcement.title"
              outlined
              dense
              label="Title"
              required
            />
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model="announcement.description"
              v-validate="'required'"
              :error-messages="errs.collect('description')"
              data-vv-name="description"
              label="Description"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row v-if="!notify_all">
          <v-col
            cols="12"
          >
            <user-search
              v-model="users.id_selected"
              label="Search User"
              short
              multi-select
              :v-validate="!notify_all ? 'required': ''"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="6">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="announcement.expiry_date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  v-model="announcement.expiry_date"
                  label="Expiry date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="announcement.expiry_date"
                no-title
                scrollable
              >
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="menu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(announcement.expiry_date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="6">
            <v-checkbox
              v-model="notify_all"
              label="Notify all clients"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="6">
            <v-checkbox
              v-model="announcement.active"
              label="Active"
            />
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template #actions>
      <v-btn
        v-if="edit"
        color="primary"
        :disabled="loading"
        :loading="loading"
        @click="updateAnnouncement()"
      >
        Update
      </v-btn>
      <v-btn
        v-else
        color="primary"
        :loading="loading"
        :disabled="loading"
        @click="saveAnnouncement()"
      >
        Save
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>

const ANNOUNCEMENT = {
  title: null,
  description: null,
  active: 1,
  expiry_date: null,
  user_ids: []
};

export default {
  data() {
    return {
      announcement: ANNOUNCEMENT,
      edit: false,
      dialog: false,
      menu: false,
      notify_all: true,
      loading: false,
      users: {
        ids: [],
        id_selected: []
      },
      fetched_users: [],
    };
  },
  methods: {
    async startNew() {
      this.reset();
      this.dialog = true;
      this.announcement = Object.assign({}, ANNOUNCEMENT);
      this.edit = false;
      this.$validator.reset();
    },

    async startEdit(announcement) {
      this.getUsers(JSON.parse(announcement.user_ids)).then(() => {
        this.dialog = true;
        this.announcement = Object.assign({}, announcement);
        this.announcement.user_ids = JSON.parse(this.announcement.user_ids);
        this.notify_all = !this.announcement.user_ids;
        this.users.id_selected = this.fetched_users;
        this.edit = true;
        this.$validator.reset();
      });
    },

    async getUsers(ids){
      await this.$http
          .get('/admin/users/list', {
            params: {ids: ids, limit: 20, admin_only: this.adminOnly},
          })
          .then((response) => response.data)
          .then((response) => {
            this.fetched_users = [];
            for (var i = 0; i < response.length; i++) {
                this.fetched_users.push({
                  id: response[i].id,
                  text: `${response[i].id} - ${response[i].email}`
                });
            }
          });
    },
    saveAnnouncement() {
      this.$validator.validate().then(result => {
        if (result) {
          this.loading = true;
          this.announcement.user_ids = this.users.id_selected.map((user) => user.id);
          this.$http
            .post(`/admin/announcements`, this.announcement)
            .then(() => {
              this.reset();
              this.$emit('update');
            })
            .catch(err => {
              if (
                err.response.status === 422 &&
                typeof err.response.data.errors !== 'undefined'
              ) {
                const errors = err.response.data.errors;
                let message = '';

                for (var field in errors) {
                  message += `${errors[field][0]}\n`;
                }

                this.errorMessage(message);
              }
            })
            .finally(() => {
              this.loading = false;
            })
        }
      });
    },
    updateAnnouncement() {
      this.$validator.validate().then(result => {
        if (result) {
          this.loading = true;
          if (this.notify_all) {
            this.announcement.user_ids = null;
          } else {
            this.announcement.user_ids = this.users.id_selected.map((user) => user.id)
          }
          this.$http
            .put(`/admin/announcements/${this.announcement.id}`, this.announcement)
            .then(() => {
              this.reset();
              this.$emit('update');
            })
            .catch(err => {
              if (
                err.response.status === 422 &&
                typeof err.response.data.errors !== 'undefined'
              ) {
                const errors = err.response.data.errors;
                let message = '';

                for (var field in errors) {
                  message += `${errors[field][0]}\n`;
                }

                this.errorMessage(message);
              }
            })
            .finally(() => {
              this.loading = false;
            })
        }
      });
    },
    reset(){
      this.notify_all = true;
      this.dialog = false;
      this.users = {
        ids: [],
        id_selected: []
      }
    },
  }
};
</script>