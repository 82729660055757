<template>
  <v-container>
    <v-flex xs12>
      <h1>Return Delivery Required</h1>
    </v-flex>

    <v-layout>
      <v-flex
        xs12
        md6
        offset-md3
      >
        <!-- Filter -->
        <v-card
          v-if="items.length > 0"
          outlined
        >
          <!-- List -->
          <v-list
            two-line
            class="pa-0"
          >
            <template v-for="(item, index) in items">
              <v-list-item
                v-if="item.user"
                :key="item.user.id"
                ripple
              >
                <v-list-item-avatar @click="select(item)">
                  <v-icon size="50">
                    account_circle
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content @click="select(item)">
                  <v-list-item-title>
                    {{ item.user.business_name || item.user.name }} -
                    {{ item.user.id }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    v-html="item.shipments.length + ' Return Shipments'"
                  />

                  <v-list-item-subtitle
                    v-if="item.scanned_users && item.scanned_users.length > 0"
                  >
                    Last Scanned By: {{ item.scanned_users.join(', ') }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn
                    icon
                    ripple
                    @click="
                      selected = item;
                      dialog3 = true;
                    "
                  >
                    <v-icon color="grey lighten-1">
                      info
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider
                v-if="index != items.length - 1"
                :key="index"
                class="ma-0"
              />
            </template>
          </v-list>
        </v-card>
        <v-alert
          v-else-if="items.length == 0"
          class="mt-5"
          outlined
          type="success"
        >
          No Returns
        </v-alert>
      </v-flex>

      <!-- change status dialog -->
      <update-return-status
        v-model="dialog"
        :returns="selected.shipments"
        :status-required="statusRequired"
      />

      <!-- fullscreen -->
      <v-dialog
        v-model="dialog3"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark>
            <v-btn
              icon
              @click="dialog3 = false"
            >
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>Return Shipments</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn
                dark
                text
                @click="select(selected)"
              >
                Change Status
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-data-table
            :items="selected.shipments"
            hide-headers
            :headers="headers"
            :footer-props="{
              'items-per-page-options': [50, 100, 200, 500]
            }"
          >
            <template #item.created_at="{ item }">
              {{ item.created_at | date_pretty }}
              <br>
              ({{ item.created_at | datetime_from }})
            </template>

            <template #item.status_id="{ item }">
              <div
                v-html="
                  $options.filters.return_status(item.status_id)
                "
              />
            </template>

            <template #item.current_location_id="{ item }">
              <div
                v-html="
                  $options.filters.location(item.current_location_id)
                "
              />
            </template>
          </v-data-table>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>

import CONSTANTS from "../../../constants";
export default {
  data() {
    return {
      items: [],
      selected: {
        shipments: []
      },
      headers: [
        {
          text:'Barcode',
          value:'barcode'
        },
        {
          text:'Current Location',
          value:'current_location_id'
        },
        {
          text: 'Contents',
          value: 'item'
        },
        {
          text: 'Status',
          value: 'status_id'
        },
        {
          text: 'Created',
          value: 'created_at'
        }
      ],

      dialog: false,
      dialog3: false,
      statusRequired: [CONSTANTS.RETURN_STATUSES.complete],
    };
  },
  mounted() {
    this.getReturns();
    Event.listen('update-admin-returns', () => {
      this.getReturns();
    });
  },
  methods: {
    getReturns() {
      this.$http
          .get(`/admin/returns/delivery-required`)
          .then((response) => response.data)
          .then((response) => {
            this.items = response;
          });
    },
    select(item) {
      this.dialog = true;
      this.selected = item;
    },
  }
};
</script>
