<template>
  <div>
    <nav-menu
      :menu="{
        text: 'New',
        exact: true,
        to: null
      }"
    />

    <v-container
      grid-list-md
      mb-12
    >
      <v-card class="elevation-0 mt-4">
        <v-container class="pb-4">
          <h2 class="font-weight-bold">
            New Claim
          </h2>
        </v-container>

        <v-card-text class="pa-0">
          <v-form>
            <v-container fluid>
              <v-row align="center">
                <v-col cols="6">
                  <v-text-field
                    v-model="claim.shipment_id"
                    v-validate="'required'"
                    outlined
                    :error-messages="errs.collect('shipment_id')"
                    label="Shipment ID"
                    data-vv-name="shipment_id"
                    persistent-hint
                    hint="Ship code or tracking number"
                  />
                </v-col>

                <v-col cols="6">
                  <v-select
                    v-model="claim.type"
                    v-validate="'required'"
                    outlined
                    :items="types"
                    label="Claim Type"
                    :error-messages="errs.collect('type')"
                    data-vv-name="type"
                  />
                </v-col>
              </v-row>

              <v-row align="center">
                <v-col cols="6">
                  <v-file-input
                    v-model="claim.invoice"
                    outlined
                    :prepend-icon="false"
                    prepend-inner-icon="mdi-paperclip"
                    accept="image/png, image/jpeg, application/pdf"
                    label="Supporting Invoice"
                    :error-messages="errs.collect('invoice')"
                    data-vv-name="invoice"
                    persistent-hint
                    hint="A commercial invoice detailing the retail value and currency, seller and buyer information, and date of transaction."
                  />
                </v-col>

                <v-col
                  v-if="claim.type == 'Lost'"
                  cols="6"
                >
                  <v-file-input
                    v-model="claim.additional_documents"
                    v-validate="''"
                    outlined
                    multiple
                    :prepend-icon="false"
                    prepend-inner-icon="mdi-paperclip"
                    accept="image/png, image/jpeg, application/pdf"
                    label="Additional Documentation"
                    :error-messages="errs.collect('additional_documents')"
                    data-vv-name="additional_documents"
                    persistent-hint
                    hint=""
                  />
                </v-col>

                <v-col
                  v-if="claim.type == 'Damaged'"
                  cols="6"
                >
                  <v-file-input
                    v-model="claim.photos"
                    outlined
                    multiple
                    :prepend-icon="false"
                    prepend-inner-icon="mdi-camera"
                    accept="image/jpeg, image/png"
                    label="Photos of Damage"
                    :error-messages="errs.collect('photos')"
                    data-vv-name="photos"
                  />
                </v-col>
              </v-row>

              <v-row align="center">
                <v-col cols="6">
                  <v-text-field
                    v-model.trim="claim.contents"
                    v-validate="'required|min:5'"
                    outlined
                    :error-messages="errs.collect('contents')"
                    label="Package Contents"
                    data-vv-name="contents"
                  />
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    v-model="claim.value"
                    v-validate="'required|min_value:0.1'"
                    outlined
                    :error-messages="errs.collect('value')"
                    label="Value (CAD)"
                    data-vv-name="value"
                    type="number"
                  />
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    v-model.trim="claim.reason"
                    v-validate="'required|min:50'"
                    outlined
                    :error-messages="errs.collect('reason')"
                    label="Reason for Claim"
                    data-vv-name="reason"
                  />
                </v-col>

                <v-col cols="12">
                  <v-btn
                    color="primary"
                    :loading="loading"
                    :disabled="loading"
                    @click="submit()"
                  >
                    Submit
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import NavMenu from './nav-menu.vue';

export default {
  components: {
    NavMenu
  },
  props: {
    filter: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      claim: {
        shipment_id: null,
        type: 'Lost',
        reason: null,
        value: null,
        contents: null,
        photos: null,
        invoice: null,
        additional_documents: null
      },
      types: [
        {
          text: 'Damaged Item',
          value: 'Damaged'
        },
        {
          text: 'Lost Item',
          value: 'Lost'
        }
      ],
      loading: false
    };
  },
  methods: {
    submit() {
      this.$validator.validate().then((result) => {
        if (result) {
          this.loading = true;

          let formData = new FormData();

          (this.claim.photos || []).forEach((photo) => {
            formData.append('photos[]', photo);
          });

          (this.claim.additional_documents || []).forEach((photo) => {
            formData.append('additional_documents[]', photo);
          });

          if (this.claim.invoice) {
            formData.append('invoice', this.claim.invoice);
          }

          formData.append('shipment_id', this.claim.shipment_id);
          formData.append('type', this.claim.type);
          formData.append('reason', this.claim.reason);
          formData.append('value', this.claim.value);
          formData.append('contents', this.claim.contents);

          this.$http
            .post('/admin/claims', formData)
            .then((response) => response.data)
            .then(() => {
              swal({
                title: 'Success',
                text: 'Your claim has been submitted.',
                icon: 'success'
              });
              this.$router.push({
                name: 'admin-claims-index'
              });
            })
            .catch((err) => {
              if (err.response.status === 422) {
                this.errorMessage(err.response.data.errors);
              }

              if (err.response.status === 500) {
                swal({
                  title: 'Error',
                  text: 'Unknown error',
                  icon: 'error'
                });
              }
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>
