<template>
  <StallionDialog
    v-model="show"
    :width="500"
    persistent
    :title="'Close Batch '.concat(batch.id)"
  >
    <template
      v-if="batch"
      #content
    >
      <v-form>
        <v-container fluid>
          <v-row dense>
            <v-col cols="12">
              <div v-if="autoClose">
                Auto close batch on complete.
              </div>

              <div class="dialog-info">
                You will not be able to modify the batch after it has been
                closed.

                <div
                  v-if="!autoClose"
                  class="mt-3"
                >
                  Are you sure that you want to close batch
                  {{ batch.id }}?
                </div>
              </div>
            </v-col>

            <v-col
              v-if="batch === null"
              cols="12"
            >
              <v-text-field
                v-model="name"
                label="Name (Optional)"
                clearable
                outlined
              />
            </v-col>
            <!-- <v-col cols="12">
              <v-checkbox
                :value="true"
                v-model="closeout"
                dense
                label="Create closeout sheet for batch"
                class="mt-1"
              >
              </v-checkbox>
            </v-col> -->
          </v-row>
        </v-container>
      </v-form>
    </template>

    <template #actions>
      <v-spacer />
      <v-btn
        :loading="loading"
        :disabled="loading"
        color="primary"
        @click="closeBatch()"
      >
        Confirm
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    batch: {
      type: Object,
      default: null
    },
    value: {
      type: Boolean,
      default: false
    },
    autoClose: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      closeout: true,
      loading: false
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    batch: {
      deep: true,
      immediate: true,
      handler() {
        if (!this.batch) return;

        if (this.show && this.autoClose) {
          this.closeBatch();
        }
      }
    }
  },
  methods: {
    closeBatch() {
      this.loading = true;
      this.$http
        .post(`/batches/${this.batch.id}/close`)
        .then((response) => response.data)
        .then(() => {
          this.show = false;
          this.$store.dispatch('batches/getOpenBatches');
          this.$emit('update');
          if (this.$store.state.imp.batch) {
            this.$store.state.imp.batch.open = false
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
