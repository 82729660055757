<template>
  <v-row>
    <v-col cols="12">
      <v-select
        v-model="user.employee_location_id"
        :items="locations"
        item-text="name"
        item-value="id"
        label="Employee Location"
        outlined
        clearable
        dense
      />

      <v-layout
        align-center
        class="mt-4"
      >
        <v-flex
          xs12
          text-center
        >
          <v-btn
            :loading="loading1"
            :disabled="loading1"
            color="primary"
            @click="updateLocation"
          >
            Update
          </v-btn>
        </v-flex>
      </v-layout>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      location: null,
      locations: [],
      loading1: false
    };
  },
  mounted() {
    this.getLocations();
  },
  methods: {
    getLocations() {
      var params = {
        company_location: true
      };

      this.$http
        .request(`/admin/dropoff-locations`, {
          method: 'get',
          params: params
        })
        .then(response => response.data)
        .then(response => {
          this.locations = response;
        });
    },
    updateLocation() {
      this.loading1 = true;
      this.$http
        .post(`/admin/users/${this.user.id}/update-location`, {
          location_id: this.user.employee_location_id
        })
        .then(response => response.data)
        .then(response => {
          this.$emit('user-updated', response);
          this.$notify({
            group: 'main',
            title: 'Location Updated',
            text: "The user's locattion have been update successfully",
            type: 'success',
            duration: 3000
          });
        })
        .finally(() => {
          this.loading1 = false;
        });
    }
  }
};
</script>
