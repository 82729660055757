<template>
  <v-layout
    v-if="imp.step == 3"
    wrap
  >
    <v-flex
      v-if="imp.validation.loading"
      xs12
      text-left
      mb-12
      mt-12
    >
      <!-- <atom-spinner
                style="margin: auto;"
                :animation-duration="2000"
                :size="100"
                :color="'#f7882f'"
              />-->
      <div v-if="imp.batch && imp.validation.overAllProgress > 0">
        <h3>
          Validated {{ imp.validation.overAllProgress }} of
          {{ imp.batch.count }} shipments
        </h3>
        Started {{ imp.validation.start_time | datetime_from }}
      </div>
      <div v-else>
        <h3>Submitting Shipments</h3>
        <br>
      </div>

      <br>

      <v-progress-linear
        v-model="imp.validation.progressPercentage"
        height="30"
        class="mt-3 mb-3"
      >
        <template #default="{ value }">
          <strong>{{ Math.floor(value) }}%</strong>
        </template>
      </v-progress-linear>
    </v-flex>

    <v-flex
      v-if="imp.batch"
      xs12
      mb-3
    >
      <h3>Import Successful</h3>

      <v-alert
        border="left"
        prominent
        type="success"
        color="green"
        outlined
      >
        Your {{ shipments.length }}
        {{ shipments.length | pluralize(' shipment') }}
        {{ shipments.length | pluralize(' has', ' have') }} been imported and
        added to batch
        <router-link
          v-if="imp.batch.id"
          class="font-weight-bold"
          :to="{ name: 'batch', params: { id: imp.batch.id } }"
        >
          <v-tooltip bottom>
            <template #activator="{ on }">
              <span v-on="on">{{ imp.batch.id }}</span>
            </template>
            <span>View Batch</span>
          </v-tooltip>
        </router-link>
      </v-alert>
      <p>
        Your {{ shipments.length }}
        {{ shipments.length | pluralize(' shipment') }}
        {{ shipments.length | pluralize(' has', ' have') }} been added to a
        batch. You can page this window or continue to process the batch
        shipments.
      </p>
      <!-- <v-btn>View Batch</v-btn> -->
    </v-flex>
    <v-flex
      xs12
      mb-3
    >
      <v-btn
        large
        color="primary"
        outlined
        :to="{ name: 'batch', params: { id: imp.batch.id } }"
      >
        Go to Batch
      </v-btn>
    </v-flex>
    <v-flex
      v-if="showReturnButton"
      xs12
      mb-3
    >
      <v-btn
        large
        color="primary"
        @click="previousStep()"
      >
        Go back to Import
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
import CONSTANTS from '@/constants';

import { mapState } from 'vuex';
export default {
  props: {
    previousStep: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      options: {
        sortBy: ['row'],
        sortDesc: [false]
      },
      search_selected: null,

      editedIndex: -1,
      countries: CONSTANTS.COUNTRIES,
      // edit dialog
      editAll: false,
      dialog: false,
      dialog2: false,
      editedItem: {
        name: null,
        address1: null,
        address2: null,
        city: null,
        province_code: null,
        postal_code: null,
        country_code: null,
        phone: null,
        package_type: null,
        description: null,
        value: null,
        currency: null,
        weight: null,
        length: null,
        width: null,
        height: null,
        need_postage: null,
        postage_type: null,
        tracking_code: null,
        additional_insurance: null,
        signature: null
      },
      rate_loading: false,
      postage_rates: [],
      headers_validation: [
        {
          text: '',
          value: 'action',
          sortable: true
        },
        {
          text: 'Row',
          value: 'row',
          sortable: true
        },
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Order ID',
          value: 'order_id',
          sortable: true
        },
        {
          text: '',
          value: 'display_order_id',
          align: ' d-none'
        },
        {
          text: 'Package Type',
          value: 'package_type_id'
        },
        {
          text: 'Need Postage',
          value: 'needs_postage'
        },
        {
          text: 'Postage Type',
          value: 'postage_type_id'
        },
        {
          text: 'Value',
          value: 'value'
        },
        {
          text: 'Weight',
          value: 'weight'
        },
        {
          text: 'Unit',
          value: 'weight_unit'
        },
        {
          text: 'L',
          value: 'length'
        },
        {
          text: 'W',
          value: 'width'
        },
        {
          text: 'H',
          value: 'height'
        },
        {
          text: 'Unit',
          value: 'size_unit'
        },
        {
          text: 'Description',
          value: 'package_contents'
        },
        {
          text: 'Order At',
          value: 'order_at'
        },
        {
          text: '',
          value: 'errors'
        }
      ]
    };
  },
  computed: {
    ...mapState(['imp']),
    selected: {
      get() {
        return this.$store.state.imp.validation.selected;
      },
      set(value) {
        this.$store.commit('imp/updateValidationSelected', value);
      }
    },
    shipments: {
      get() {
        return this.$store.state.imp.validation.shipments;
      },
      set(value) {
        this.$store.commit('imp/updateValidationShipments', value);
      }
    },
    showReturnButton: function() {
      return this.imp.selection.shipments.length - this.imp.selection.selected.length > 0;
    }
  },
  watch: {},

  mounted() {},

  methods: {
    startValidate() {
      const payload = {
        shipments: this.shipments,
        batch: this.batch
      };
      this.$store.commit('imp/validationStart', payload);
    },
    selectAll() {
      if (this.selected.length != this.imp.selection.shipments.length) {
        this.$notify({
          group: 'main',
          title: 'All Selected',
          text: 'All shipments have been selected',
          duration: 5000,
          type: 'success'
        });
        this.selected = this.imp.selection.shipments;
      } else {
        this.selected = [];
      }
    },
    /**
     * Save edit to row or multiple rows
     */
    saveEdit(value) {
      console.log('saveEdit');
      if (this.editAll === true) {
        this.saveSelected();
      } else if (this.editedIndex > -1) {
        this.selected = [this.shipments[this.editedIndex]];
        this.$store.commit('imp/validationEditItem', {
          value: value
        });
      } else {
        value.row = 1;

        this.$store.commit('imp/validationAddItem', value);
      }

      this.editAll = false;
    },
    /**
     * Sets the item edit value
     */
    showAddDialog(item) {
      // this.editedItem = Object.assign({}, this.defaultItem);
      this.editedItem = Object.assign({}, CONSTANTS.DEFAULT_PRESET_ITEM || {});
      this.postage_rates = [];

      this.editedIndex = -1;
      this.$validator.reset();
      if (typeof item !== 'undefined') {
        this.editedIndex = this.shipments.map(e => e.row).indexOf(item.row);

        console.log('this.editedIndex');
        console.log(this.editedIndex);

        this.editedItem = Object.assign({}, item);
      }

      this.dialog = true;
    },

    showEditDialog() {
      // this.editedItem = Object.assign({}, this.defaultItem);
      this.editedItem = Object.assign({}, CONSTANTS.DEFAULT_PRESET_ITEM);
      this.postage_rates = [];

      this.editedIndex = -1;

      this.dialog = true;
      this.editAll = true;
    },

    saveSelected() {
      //  console.log('editedIndex: ' + this.editedIndex)

      for (var property1 in this.editedItem) {
        if (
          this.editedItem[property1] === null ||
          this.editedItem[property1] === ''
        ) {
          delete this.editedItem[property1];
        }
      }
      this.$store.commit('imp/validationEditItem', {
        value: this.editedItem
      });
    },
    deleteSelected() {
      this.$store.commit('imp/validationRemoveItem');
    },

    bulkSelectValidation({ item: b, value }) {
      const { currentValidation, shiftKeyOn } = this;

      if (value == true && shiftKeyOn) {
        let [a] = this.selected;

        if (this.lastValidation !== null) {
          a = this.lastValidation;
        }

        let start = currentValidation.findIndex(item => item == a);
        let end = currentValidation.findIndex(item => item == b);

        if (start - end > 0) {
          let temp = start;
          start = end;
          end = temp;
        }
        for (let i = start; i <= end; i++) {
          this.selected.push(currentValidation[i]);
        }
      }

      this.lastValidation = b;
    }
  }
};
</script>
