<template>
  <v-row dense>
    <v-col cols="12">
      <statement-table :user-id="user.id" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
