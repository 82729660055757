<template>
  <v-row dense>
    <v-col>
      <v-menu offset-y>
        <template #activator="{ on }">
          <v-row>
            <v-col>
              <v-btn
                color="primary"
                v-on="on"
                @click="openChargeUserDialog()"
              >
                <v-icon left>
                  mdi-plus
                </v-icon>Add
              </v-btn>
            </v-col>

            <!-- hide if view is for specific user -->
            <v-col v-if="!user?.id">
              <v-form @submit.prevent="updateTable()">
                <v-container pa-0>
                  <v-layout wrap>
                    <v-flex xs12>
                      <v-text-field
                        v-model="search"
                        pt-0
                        label="Name/Email"
                        append-icon="search"
                        @input="updateTable()"
                      />
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-col>

            <v-col>
              <v-form @submit.prevent="updateTable()">
                <v-container pa-0>
                  <v-layout wrap>
                    <v-flex xs12>
                      <v-select
                        v-model="is_active"
                        clearable
                        label="Status"
                        item-text="text"
                        item-value="value"
                        :items="[
                          {
                            value: 1,
                            text: 'Active'
                          },
                          {
                            value: 0,
                            text: 'In-Active'
                          }
                        ]"
                        @change="updateTable()"
                      />
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-col>
          </v-row>
        </template>
      </v-menu>
    </v-col>

    <v-col cols="12">
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :loading="loading"
            item-key="id"
            :items="items"
            :options.sync="options"
            class="table-striped"
            :server-items-length="totalItems"
            :footer-props="{
              'items-per-page-options': [50, 100, 200, 500]
            }"
            :items-per-page="25"
          >
            <template #item.prev_billing_date="{ item }">
              <span v-if="item.prev_billing_date">
                {{ item.prev_billing_date | date_pretty }}
              </span>
            </template>

            <template #item.next_billing_date="{ item }">
              <span v-if="item.next_billing_date">
                {{ item.next_billing_date | date_pretty }}
              </span>
            </template>
              
            <template #item.amount="{ item }">
              {{ item.amount | currency }}
            </template>

            <template #item.created_at="{ item }">
              {{ item.created_at | date_pretty }}
            </template>

            <template #item.is_active="{ item }">
              {{ item.is_active == 1 ? 'Active' : 'In-Active' }}
            </template>

            <template #item.action="{ item }">
              <v-btn
                color="primary"
                small
                text
                :loading="loading"
                @click.stop="openChargeUserDialog(item)"
              >
                Edit
              </v-btn>
              
              <v-btn
                color="primary"
                small
                text
                :loading="loading"
                @click.stop="deleteRecurring(item)"
              >
                Delete
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12">
      <charge-user
        ref="chargeUser"
        :dialog-title="chargeUserDialogTitle"
        :user="user"
        :is-recurring="true"
        @complete="updateTable"
      />
    </v-col>
  </v-row>
</template>
  
<script>
import ChargeUser from '../../../pages/admin/users/ChargeUser.vue';

export default {
  components: {
    ChargeUser
  },
  props: {
    user: {
      type: [Object, null],
      default: null
    }
  },
  data() {
    return {
      chargeUserDialogTitle: '',
      totalItems: 0,
      items: [],

      loading: false,
      search: null,
      is_active: null,

      timer: null,
      cancelSource: null,

      headers: [
        {
          text: 'Email',
          align: 'left',
          value: 'user.email',
          sortable: false
        },
        {
          text: 'Transaction Type',
          align: 'left',
          value: 'transaction_type.name',
          sortable: false
        },
        {
          text: 'Schedule',
          align: 'left',
          value: 'schedule',
        },
        {
          text: 'Previous Billing Date',
          align: 'left',
          value: 'prev_billing_date',
        },
        {
          text: 'Next Billing Date',
          align: 'left',
          value: 'next_billing_date',
        },
        {
          text: 'Total',
          align: 'left',
          value: 'total',
        },
        {
          text: 'Created At',
          align: 'left',
          value: 'created_at',
        },
        {
          text: 'Status',
          align: 'left',
          value: 'is_active',
        },
        {
          text: '',
          value: 'action',
          sortable: false
        }
      ],

      options: {
        sortBy: ['created_at'],
        sortDesc: [true]
      },
    }
  },

  watch: {
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    }
  },
  mounted() {
    this.getDataFromApi()
  },
  
  methods:{
    openChargeUserDialog(data) {
      this.chargeUserDialogTitle = (!data ? 'Add ' : 'Edit ') + 'Recurring Charge'

      this.$refs.chargeUser.setChargeData(data)
      this.$refs.chargeUser.openDialog(data)
    },

    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.options.page = 1;
      this.timer = setTimeout(function () {
        self.updateTable();
      }, ms);
    },

    updateTable() {
      this.totalItems = 0;
      this.items = [];

      this.getDataFromApi().then((data) => {
        this.items = data.items;
        this.totalItems = data.total;
      });
    },

    getDataFromApi() {
      this.loading = true;
      return new Promise((resolve) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let items = [];
        let total = 0;
        let pages = 0;

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        var params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sortBy[0],
          desc: sortDesc[0],
          user_id: this.user?.id,
          search: this.search,
          is_active: this.is_active
        };

        this.$http
          .request(`/admin/recurring-charges`, {
            method: 'get',
            params: params,
            cancelToken: this.cancelSource.token
          })

          .then((response) => response.data)
          .then((response) => {
            items = response.data;
            total = response.total;
            pages = response.last_page;

            this.loading = false;
            resolve({
              items,
              total,
              pages
            });
          });
      });
    },

    async deleteRecurring(item) {
      const isConfirm = await swal({
        title: 'Confirm Delete',
        text: 'Do you really want to delete this recuring charge?',
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      });

      if (isConfirm) {
        this.loading = true
        try {
          await axios.request(`/admin/recurring-charges/${item.id}`, {
            method: 'delete'
          });

          this.successMessage('Successfully Deleted.');
          this.updateTable();
        } catch (err) {
          console.log(err);
          this.errorMessage();
        }

        this.loading = false
      }
    }
  }
};
</script>
