<template>
  <StallionDialog
    v-model="show"
    :width="500"
    persistent
    title="Print Batch Label"
  >
    <template #content>
      <v-layout column>
        <v-flex
          xs12
          text-center
        >
          <h3>
            Print your batch label and drop it off with it's shipments.
          </h3>

          <h3>
            {{ ids.length }} batch label
            {{ ids.length | pluralize(' sheet') }} ready to be printed
          </h3>
        </v-flex>
      </v-layout>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        text
        download
        :loading="dowload_loading"
        :disabled="dowload_loading"
        @click.prevent="download()"
      >
        <v-icon
          small
          dark
        >
          mdi-download
        </v-icon>
        Download
      </v-btn>

      <v-btn
        color="primary"
        :loading="print_loading"
        :disabled="print_loading"
        @click="print()"
      >
        <v-icon
          small
          dark
        >
          mdi-printer
        </v-icon>
        Print
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    ids: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      print_loading: false,
      dowload_loading: false
    };
  },
  computed: {
    labelsCount: function() {
      return this.batches.length;
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },

  methods: {
    close() {
      this.$emit('update:show', false);
    },
    print: function() {
      this.print_loading = true;

      this.$http
        .post(
          '/closeouts/print',
          { ids: this.ids }
        )
        .then(response => {
          this.printUrl(response.data);
        })
        .finally(() => {
          this.print_loading = false;
        });
    },
    download: function() {
      this.dowload_loading = true;

      this.$http
        .post(
          '/closeouts/print',
          { ids: this.ids }
        )
        .then(response => {
          this.downloadFileUrl(response.data, `Stallion_Batch_Label_${this.ids[0]}.pdf`);
        })
        .finally(() => {
          this.dowload_loading = false;
        });
    }
  }
};
</script>
