<template>
  <v-layout justify-center>
    <v-flex
      xs12
      sm8
      md6
      mb-12
      mt-4
    >
      <v-card class="elevation-5">
        <v-card-text>
          <v-layout wrap>
            <v-flex
              xs12
              text-center
            >
              <h2 class="font-weight-bold mt-4">
                Shipment Confirmed
              </h2>
            </v-flex>

            <v-flex
              xs12
              class="mb-4 text-center"
            >
              <h4>Thank you, your shipment is confirmed.</h4>
              <br>
            </v-flex>

            <v-flex
              xs12
              class="mb-4 text-center"
            >
              <p style="font-size: 1.2rem">
                <strong>Shipment Code:</strong>
                {{ shipment.ship_code }}
              </p>
            </v-flex>
            <v-flex
              -if="shipment.tracking_code"
              xs12
              class="mb-4 text-center"
            >
              <p style="font-size: 1.2rem">
                <strong>Tracking Code:</strong>
                {{ shipment.tracking_code }}
              </p>
            </v-flex>

            <v-layout
              align-center
              class="mt-4 mb-4"
            >
              <v-flex
                xs12
                text-center
              >
                <v-btn
                  large
                  text
                  color="secondary"
                  @click="close()"
                >
                  <v-icon
                    left
                    dark
                  >
                    mdi mdi-back
                  </v-icon>Close
                </v-btn>

                <v-btn
                  v-if="is_printable(shipment)"
                  color="primary"
                  @click="show_print = true"
                >
                  <v-icon
                    small
                    dense
                    left
                  >
                    mdi-printer
                  </v-icon>Print
                </v-btn>

                <print
                  ref="print"
                  v-model="show_print"
                  :shipments="[shipment]"
                />
              </v-flex>
            </v-layout>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      show_print: false
    };
  },
  computed: {
    shipment() {
      return this.$store.state.singleFlow.shipment;
    },
    step: {
      get() {
        return this.$store.state.singleFlow.step;
      },
      set(value) {
        this.$store.state.singleFlow.step = value;
      }
    }
  },
  watch: {
    step: {
      immediate: true,
      handler(newValue) {
        if (
          newValue == 7 &&
          this.is_printable(this.shipment) &&
          this.isUserSettingEnabled('print_after_single_flow')
        ) {
          this.$nextTick(function() {
            this.show_print = true
            this.$refs.print.getLabel('print', () => {
              this.show_print = false
            });
          });
        }
      }
    }
  },
  mounted() {},
  methods: {
    close: function() {
      Event.fire('close-singleflow');
    }
  }
};
</script>
