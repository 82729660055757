<template>
  <StallionDialog
    v-model="dialog"
    persistent
    :width="800"
    :title="edit ? 'Edit List' : 'Create List'"
  >
    <template #content>
      <v-container>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="list.name"
              outlined
              dense
              label="List Name"
              required
            />
          </v-col>

          <v-col cols="12">
            <criteria v-bind.sync="list" />
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template #actions>
      <v-spacer />
      <v-btn
        v-if="edit"
        :loading="loading"
        color="primary"
        @click="updateList()"
      >
        Update
      </v-btn>
      <v-btn
        v-else
        :loading="loading"
        color="primary"
        @click="saveList()"
      >
        Save
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
import Criteria from './Criteria.vue';
import FILTERS from '../../../shared/filters';
export default {
  components: {
    Criteria
  },

  data() {
    return {
      list: FILTERS.defaultList(),
      edit: false,
      dialog: false,
      loading: false
    };
  },

  methods: {
    startNew() {
      this.dialog = true;
      this.list = Object.assign({}, FILTERS.defaultList());
      this.edit = false;
    },
    startEdit(filter) {
      this.dialog = true;
      this.list = Object.assign(FILTERS.defaultList(), filter);
      this.edit = true;
    },
    saveList() {
      this.loading = true
      this.$http
        .post(`/list-criteria`, this.list)
        .then(() => {
          this.closeDialog()

          this.successMessage('List successfully created!');
        })
        .catch(err => {
          this.onError(err)
        })
        .finally(() => {
          this.loading = false
        });
    },
    updateList() {
      this.loading = true
      this.$http
        .put(`/list-criteria/${this.list.id}`, this.list)
        .then(() => {
          this.closeDialog()

          this.successMessage('List successfully updated!');
        })
        .catch(err => {
          this.onError(err)
        })
        .finally(() => {
          this.loading = false
        });
    },
    closeDialog() {
      this.dialog = false;
      this.$emit('update-lists');
      this.$store.dispatch('listCriteria/getLists');
    },
    onError(err) {
      if (
        err.response.status === 422 &&
        typeof err.response.data.errors !== 'undefined'
      ) {
        const errors = err.response.data.errors;
        let message = '';

        for (var field in errors) {
          message += `${errors[field][0]}\n`;
        }

        this.errorMessage(message);
      }
    }
  }
};
</script>
