<template>
  <FullPageLayout
    :title="`Import`"
  >
    <template #page-links>
      <order-links />
    </template>
   
    <template #default>
      <v-dialog
        v-model="imp.loader.show"
        persistent
        width="300"
        :transition="false"
      >
        <v-card dark>
          <v-card-text>
            <v-layout
              justify-center
              class="pt-3"
            >
              {{ imp.loader.message }}
              <atom-spinner
                :animation-duration="2000"
                :size="100"
                :color="'#f9ac40'"
              />
            </v-layout>
          </v-card-text>
        </v-card>
      </v-dialog>

   

   
      <!-- <v-row>
        <v-col>
          <h3 class="font-weight-bold">
            Import
          </h3>
        </v-col>
        <v-col cols="auto">
          <order-links />
        </v-col>
      </v-row> -->
      <v-row>
        <!-- <div>

          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn
                v-show="imp.step == 3"
                color="primary"
                :disabled="imp.validation.disabled"
                depressed
                v-on="on"
                @click="$store.commit('imp/exportImported')"
              >
                <v-icon small> mdi-cloud-download </v-icon>Export
              </v-btn>
            </template>
            <span>Export all shipments to a csv file</span>
          </v-tooltip>
        </div> -->
      </v-row>
      <v-row>
        <v-col>
          <v-stepper
            v-model="imp.step"
            class="elevation-0"
          >
            <div
              v-if="imp.main_loading"
              class="loading-overlay"
            >
              <div class="loading-content">
                <v-progress-circular
                  :size="100"
                  :width="10"
                  indeterminate
                  color="primary"
                />
              </div>
            </div>

            <v-stepper-header
              class="elevation-0"
              style="border: 1px solid #e7e7e7; border-radius: 8px"
            >
              <v-stepper-step
                :complete="imp.step > 1"
                step="1"
              >
                Choose Import
              </v-stepper-step>
              <v-divider />

              <v-stepper-step
                :complete="imp.step > 2"
                step="2"
              >
                Selection
              </v-stepper-step>
              <v-divider />

              <v-stepper-step
                :complete="imp.step == 3"
                step="3"
              >
                Import
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content
                :step="1"
                class="pa-0"
              >
                <v-container
                  v-if="imp.step == 1"
                  class="px-0"
                >
                  <v-row>
                    <v-col
                      md="6"
                    >
                      <file-import />
                    </v-col>

                    <v-col
                      md="6"
                    >
                      <filter-import />
                      
                      <store-import />

                      <add-store />

                      <!-- <fulfillment-settings :settings="fulfillmentSettings" /> -->
                    </v-col>
                  </v-row>
                </v-container>
              </v-stepper-content>

              <v-stepper-content
                :step="2"
                class="pa-3"
              >
                <select-orders v-if="imp.selection.type === 'store'" />
                <select-file-orders v-if="imp.selection.type === 'file'" />
              </v-stepper-content>

              <v-stepper-content
                :step="3"
                class="pa-3"
              >
                <import-complete :previous-step="previousStep" />
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
    </template>
    <template #footer>
      <action-bar />
    </template>
  </FullPageLayout>
</template>

<script>
import StoreImport from './StoreImport.vue';
import FilterImport from './FilterImport.vue';
// import OrderFilter from './OrderFilter';
import AddStore from './AddStore.vue';
import FileImport from './FileImport.vue';
// import FulfillmentSettings from './FulfillmentSettings.vue';

import SelectFileOrders from './steps/SelectFileOrders.vue';
import SelectOrders from './steps/SelectOrders.vue';
import ImportComplete from './steps/ImportComplete.vue';

import ActionBar from './steps/ActionBar.vue';

import { mapState } from 'vuex';
import { AtomSpinner } from 'epic-spinners';
export default {
  metaInfo: {
    title: "Import | Stallion Express"
  },
  components: {
    FilterImport,
    AtomSpinner,
    StoreImport,
    // OrderFilter,
    AddStore,
    FileImport,
    // FulfillmentSettings,
    SelectOrders,
    SelectFileOrders,

    ImportComplete,
    ActionBar
  },
  props: {
    provider: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      // fulfillmentSettings: {
      //   stallion_fulfillment: this.$auth.user.stallion_fulfillment,
      //   fulfill_shipments: this.$auth.user.fulfill_shipments,
      //   fulfillment_delay: this.$auth.user.fulfillment_delay,
      //   fulfillment_custom_delay: {
      //     hours_enabled:
      //       this.$auth.user.fulfillment_custom_delay?.hours_enabled || false,
      //     hours: this.$auth.user.fulfillment_custom_delay?.hours || null,
      //     time_enabled:
      //       this.$auth.user.fulfillment_custom_delay?.time_enabled || false,
      //     time: this.$auth.user.fulfillment_custom_delay?.time || null
      //   }
      // }
    };
  },
  computed: {
    ...mapState(['imp'])
  },
  created() {},
  mounted() {
    if (
      this.provider === 'shopify' ||
      this.provider === 'ebay' ||
      this.provider === 'etsy' ||
      this.provider === 'amazon' ||
      this.provider === 'bigcommerce'
    ) {
      this.addStore();
    } else if (
      this.provider === 'woocommerce' &&
      this.$route.query.success == '1'
    ) {
      this.$notify({
        group: 'main',
        title: this.provider + ' added successfully',
        text:
          'Your account for ' + this.provider + ' has been added successfully.',
        duration: 5000,
        type: 'success'
      });
    }
  },
  methods: {
    previousStep() {
      this.imp.selection.shipments = this.imp.selection.shipments.filter(
        (el) => {
          return this.imp.selection.selected.indexOf(el) < 0;
        }
      );
      this.imp.step = 2;
      this.$store.commit('imp/updateSelectionSelected', []);
    },
    addStore() {
      this.msg = `Adding ${this.provider} to your account`;
      this.dialog2 = true;

      this.$http
        .post(`/stores/${this.provider}/callback`, this.$route.query)
        .then((response) => response.data)
        .then(() => {
          this.$store.dispatch('userStores/getStores');
          this.$notify({
            group: 'main',
            title: this.provider + ' added successfully',
            text:
              'Your account for ' +
              this.provider +
              ' has been added successfully.',
            duration: 5000,
            type: 'success'
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.status === 422) {
            this.$notify({
              group: 'main',
              title: 'Invalid Credentials',
              text: err.response.data.errors,
              duration: 5000,
              type: 'error'
            });
          } else {
            this.$notify({
              group: 'main',
              title: 'Error adding ' + this.provider + ' account',
              text: 'We could not connect to that account.',
              duration: 5000,
              type: 'error'
            });
          }
        })
        .finally(() => {
          this.dialog2 = false;
        });
    }
  }
};
</script>

<style scoped>
.v-application p {
  margin-bottom: 0px;
}

.v-stepper-content {
  padding: 0px;
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}

.v-data-table__wrapper {
  padding-top: 5px;
  border: 1px solid #e7e7e7 !important;
  border-radius: 10px !important;
}

.v-data-table table {
  padding-top: 5px;
  border: 1px solid #e7e7e7 !important;
  border-radius: 10px !important;
}

.import-list {
  text-decoration: none;
  font-weight: 700;
  font-size: 14px;
}

.ecommerce-images {
  max-width: 80%;
  margin-top: 7%;
}

.ecommerce-images-large {
  max-width: 60%;
  margin-top: 7%;
}

@media only screen and (min-width: 1700px) {
  .ecommerce-images {
    max-width: 60%;
  }

  .ecommerce-images-large {
    max-width: 45%;
  }
}
</style>
