<template>
  <FullPageLayout :title="`Supplies Order ${$route.params.id}`">
    <template #default>
      <v-card outlined>
        <table class="cart-table">
          <thead>
            <tr>
              <th class="text-h4 text-center font-weight-bold">
                Item
              </th>
              <th class="text-h4 text-center font-weight-bold">
                Quantity
              </th>
              <th class="text-right text-h4 font-weight-bold">
                Unit Price
              </th>
              <th class="text-right text-h4 font-weight-bold">
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in invoice.items"
              :key="item"
            >
              <td class="text-center">
                <v-layout
                  wrap
                  align-center
                >
                  <v-flex
                    xs12
                    text-center
                  >
                    <h2>{{ item.supply.name }}</h2>
                  </v-flex>
                </v-layout>
              </td>

              <td>
                <v-layout
                  wrap
                  align-center
                >
                  <v-flex
                    xs12
                    text-center
                  >
                    {{ item.quantity }}
                  </v-flex>
                </v-layout>
              </td>
              <td class="text-right">
                {{ item.price | currency }}
              </td>
              <td class="text-right">
                {{ (item.quantity * item.price) | currency }}
              </td>
              <td />
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td
                class="text-right subheading font-weight-bold"
                colspan="3"
              >
                Subtotal
              </td>
              <td class="text-right">
                {{ invoice.subtotal | currency }}
              </td>
            </tr>
            <tr>
              <td
                class="text-right subheading font-weight-bold"
                colspan="3"
              >
                Tax
              </td>
              <td class="text-right">
                {{ invoice.tax | currency }}
              </td>
            </tr>
            <tr>
              <td
                class="text-right subheading font-weight-bold"
                colspan="3"
              >
                Total
              </td>
              <td class="text-right">
                {{ invoice.total | currency }}
              </td>
            </tr>
          </tfoot>
        </table>
      </v-card>
    </template>
    <template #footer>
      <supplies-print
        :invoices="[invoice]"
        :disabled="false"
        :show="show"
      />
    </template>
  </FullPageLayout>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      invoice: {},
      id: null
    };
  },
  computed: {},
  watch: {},
  mounted() {
    if (this.$route.query.print) {
      this.show = true;
    }
    this.id = this.$route.params.id;

    this.getInvoice();
  },
  methods: {
    getInvoice: function () {
      this.loading = true;

      this.$http
        .get(`/supplies-invoices/${this.id}`)
        .then((response) => {
          this.invoice = response.data;
        })
        .catch(() => {
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
