export default {
  SUPPLIES_STATUS: {
    UNFULFILLED: 1,
    COMPLETE: 2,
    VOIDED: 3,
    IN_TRANSIT: 4,
    READY: 5,
    PENDING: 6
  }
};
