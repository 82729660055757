import Vue from 'vue';
import Vue2Filters from 'vue2-filters';

function getTimezone() {
  try {
    return Vue.auth.user.timezone;
  } catch (err) {
    console.error(err);
  }
  return 'America/Toronto';
}

Vue.use(Vue2Filters);

Vue.filter('datetime_pretty', (value) => {
  const customerTimezone = getTimezone();

  try {
    var est = moment.tz(value, 'America/Toronto');
    var converted = est.clone().tz(customerTimezone);

    return converted.format('MMMM Do YYYY, h:mm:ss A');
  } catch (err) {
    console.error(err);
    return value;
  }
});

Vue.filter('datetime_short', (value) => {
  const customerTimezone = getTimezone();

  try {
    var est = moment.tz(value, 'America/Toronto');
    var converted = est.clone().tz(customerTimezone);

    return converted.format('MM/DD/YYYY hh:mmA');
  } catch (err) {
    console.error(err);
    return value;
  }
});

Vue.filter('date_pretty', (value) => {
  var customerTimezone = getTimezone();

  try {
    var est = moment.tz(value, 'America/Toronto');
    var converted = est.clone().tz(customerTimezone);

    return converted.format('MMMM Do YYYY');
  } catch (err) {
    console.error(err);
    return value;
  }
});

Vue.filter('date_pretty_non_timezone', (value) => {
  try {
    var date = moment(value);

    return date.format('MMMM Do YYYY');
  } catch (err) {
    console.error(err);
    return value;
  }
});

Vue.filter('date_month_year', (value) => {
  var customerTimezone = getTimezone();

  try {
    var est = moment.tz(value, 'America/Toronto');
    var converted = est.clone().tz(customerTimezone);

    return converted.format('MMMM YYYY');
  } catch (err) {
    console.error(err);
    return value;
  }
});

Vue.filter('humanize', (str) => {
  try {
    str = str.replace('-', '_');

    var i,
      frags = str.split('_');
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  } catch (err) {
    return str;
  }
});

Vue.filter('datetime_from', (value) => {
  const timezone = getTimezone();
  try {
    var est = moment.tz(value, 'America/Toronto');
    var start = est.clone().tz(timezone);
    var end = moment.tz(timezone);

    return start.from(end);
  } catch (err) {
    console.error(err);
    return value;
  }
});

Vue.filter('myFilter', (value) => {
  return value.toUpperCase();
});

Vue.filter('ratio', (value) => {
  return parseFloat(value / 100).toFixed(3);
});

Vue.filter('phone', function (phone) {
  if (phone) {
    const match = phone.match(/^(\+\d)?[- ]??[\s]?(\d{3})?[\s]??(.*)?$/);

    const valid = match.every((v) => typeof v !== 'undefined');

    if (valid) {
      return match[1] + ` (${match[2]})` + match[3];
    } else {
      return match[0]
        .replace(/[^0-9]/g, '')
        .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }
  }
});

Vue.filter('combined_rate', function (shipment) {
  var value = Number(shipment.postage_rate) + Number(shipment.handling_fee);
  value = value.toFixed(2);
  return value;
});

Vue.filter('dims', function (shipment) {
  var array = [shipment.length, shipment.width, shipment.height];
  var filtered = array.filter(function (el) {
    return el != null;
  });

  if (filtered.length === 0) {
    return null;
  }

  var join = filtered.join('x');

  return join + shipment.size_unit;
});

Vue.filter('status', function (value) {
  if (value == 1) {
    //1
    return '<i class="mdi mdi-clock-alert-outline"></i> Postage Expired';
  } else if (value == 2) {
    //2
    return '<i class="mdi mdi-pause-circle-outline"></i> Pending';
  } else if (value == 3) {
    //3
    return '<i class="mdi mdi-circle-edit-outline"></i> Incomplete';
  } else if (value == 4) {
    //4
    return '<i class="mdi mdi-cube-outline"></i> Ready';
  } else if (value == 5) {
    //4
    return '<i class="mdi mdi-marker-check"></i> Received';
  } else if (value == 6) {
    //6
    return '<i class="mdi mdi-marker-check"></i> Processing';
  } else if (value == 8) {
    //7
    return '<i class="mdi mdi-cube-send"></i> In-Transit'; //9 || 13
  } else if (value == 9) {
    // 9
    return '<i class="mdi mdi-check"></i> Delivered';
  } else if (value == 13) {
    //13
    return '<i class="mdi mdi-check"></i> Complete';
  } else if (value == 10) {
    //13
    return '<i class="mdi mdi-alert-circle-outline"></i> Exception';
  } else if (value == 11) {
    // 12
    return '<i class="mdi mdi-select"></i> Void Requested';
  } else if (value == 12) {
    // 12
    return '<i class="mdi mdi-select-off"></i> Voided';
  } else if (value == 14) {
    // 14
    return '<i class="mdi mdi-clock"></i> On Hold';
  } else if (value == 'void requested') {
    return '<i class="mdi mdi-select"></i> ';
  } else if (value == 'all') {
    return '<i class="mdi mdi-format-list-bulleted"></i> ';
  } else {
    return value;
  }
});

Vue.filter('postage', function (value) {
  if (
    typeof value.postage_type_id === 'undefined' &&
    typeof value.id !== 'undefined'
  ) {
    value.postage_type_id = value.id;
  } else if (typeof value !== 'object') {
    let t = value;
    value = {};
    value.postage_type_id = t;
  }

  let description = '';

  if (
    value.postage_type_id == null &&
    value.lowest_postage_ids &&
    value.lowest_postage_ids.length > 0
  ) {
    description = '<i class="fas fa-search-dollar"></i> Lowest Rate';
  }
  // 1	 USPS First Class Mail
  else if (
    value.postage_type_id == 1 &&
    [17, 18].includes(value.package_type_id)
  ) {
    description =
      '<i class="fab fa-usps"></i> USPS First Class Mail (Untracked)';
  } else if (value.postage_type_id == 1) {
    description = '<i class="fab fa-usps"></i> USPS First Class Mail';
  }
  // 2	USPS Priority Mail
  else if (value.postage_type_id == 2) {
    description = '<i class="fab fa-usps"></i> USPS Priority Mail';
  }
  // 3	USPS Priority Mail Express
  else if (value.postage_type_id == 3) {
    description = '<i class="fab fa-usps"></i> USPS Priority Mail Express';
  }
  // 4	USPS Parcel Select Ground
  else if (value.postage_type_id == 4) {
    description = '<i class="fab fa-usps"></i> USPS Parcel Select Ground';
  }
  // 5	USPS Media Mail
  else if (value.postage_type_id == 5) {
    description = '<i class="fab fa-usps"></i> USPS Media Mail';
  }
  // 6 Ground Advantage
  else if (value.postage_type_id == 6) {
    description = '<i class="fab fa-usps"></i> USPS Ground Advantage';
  }
  // 19	USPS Library Mail
  else if (value.postage_type_id == 19) {
    description = '<i class="fab fa-usps"></i> USPS Library Mail';
  }
  // 22	UPS
  else if (value.postage_type_id == 22) {
    description = '<i class="fab fa-ups"></i> UPS Standard';
  }
  // 23	Stallion Domestic
  else if (value.postage_type_id == 23) {
    description = `<i class="fas fa-bullseye"></i> Landmark Global`;
  }
  // 25	USPS Express Mail International
  else if (value.postage_type_id == 25) {
    description = '<i class="fab fa-usps"></i> USPS Express Mail International';
  }
  // 26	USPS Priority Mail International
  else if (value.postage_type_id == 26) {
    description =
      '<i class="fab fa-usps"></i> USPS Priority Mail International';
  }

  // 27	USPS First Class Mail International
  else if (value.postage_type_id == 27) {
    description =
      '<i class="fab fa-usps"></i> USPS First Class Mail International';
  }

  // 30	PostNL International Packet
  else if (value.postage_type_id == 30) {
    description =
      '<i class="fas fa-plane"></i> PostNL International Packet Untracked';
  }
  // 31	PostNL International Packet Tracked
  // 32	PostNL International Packet Tracked
  // 33	PostNL International Packet Tracked
  else if (
    value.postage_type_id == 32 ||
    value.postage_type_id == 33 ||
    value.postage_type_id == 37 ||
    value.postage_type_id == 38
  ) {
    description =
      '<i class="fas fa-plane"></i> PostNL International Packet Tracked';
  } else if (value.postage_type_id == 31) {
    description = '<i class="fas fa-plane"></i> PostNL Packet Plus';
  }
  // TODO: update rivo icons
  // Rivo
  // 34 = POSTNL_RIVO_NEXT_DAY
  // 35 = POSTNL_RIVO_PRIO
  // 36 = POSTNL_RIVO_STANDARD_PLUS
  else if (value.postage_type_id == 34) {
    description = '<i class="fas fa-plane"></i> Rivo Next Day';
  } else if (value.postage_type_id == 35) {
    description = '<i class="fas fa-plane"></i> Rivo Priority';
  } else if (value.postage_type_id == 36) {
    description = '<i class="fas fa-plane"></i> Rivo Standard Plus';
  }

  // 40	APC Priority Worldwide
  else if (value.postage_type_id == 40) {
    description =
      '<i class="fas fa-globe"></i> APC Priority Worldwide Untracked';
  }
  // 41	APC Priority Worldwide Tracked
  else if (value.postage_type_id == 41) {
    description = '<i class="fas fa-globe"></i> APC Priority Worldwide Tracked';
  }
  // 42 APC Priority Worldwide Tracked(DDP)
  else if (value.postage_type_id == 42) {
    description =
      '<i class="fas fa-globe"></i> APC Priority Worldwide Tracked(DDP)';
  } else if (value.postage_type_id == 43) {
    description =
      '<i class="fas fa-globe"></i> APC Priority Worldwide Expedited';
  } else if (value.postage_type_id == 44) {
    description =
      '<i class="fas fa-globe"></i> APC Priority Worldwide Expedited(DDP)';
  } else if (value.postage_type_id == 51) {
    description = '<i class="fab fa-ups"></i> UPS Worldwide Express Saver';
  } else if (value.postage_type_id == 52) {
    description = '<i class="fab fa-ups"></i> UPS Express Saver';
  } else if (value.postage_type_id == 53) {
    description = '<i class="fab fa-ups"></i> UPS Express';
  } else if (value.postage_type_id == 54) {
    description = '<i class="fab fa-ups"></i> UPS Worldwide Express Saver';
  } else if (value.postage_type_id == 55) {
    description = '<i class="fab fa-ups"></i> UPS Worldwide Expedited';
  } else if (value.postage_type_id == 56) {
    description = '<i class="fab fa-ups"></i> UPS Worldwide Expedited';
  } else if (value.postage_type_id == 57) {
    description = '<i class="fab fa-ups"></i> UPS Worldwide Express';
  } else if (value.postage_type_id == 58) {
    description = '<i class="fab fa-ups"></i> UPS Worldwide Express Plus';
  } else if (value.postage_type_id == 60) {
    description = '<i class="fas fa-shipping-fast"></i> USPS Economy';
  } else if (value.postage_type_id == 61) {
    description = '<i class="fas fa-shipping-fast"></i> USPS Economy';
  } else if (value.postage_type_id == 70) {
    description = '<i class="fas fa-horse-head"></i> Test CP-R';
  } else if (value.postage_type_id == 71) {
    description = '<i class="fas fa-horse-head"></i> Canada Post Zone-Skip';
  } else if (value.postage_type_id == 72) {
    description = '<i class="fas fa-horse-head"></i> Test CP-P';
  } else if (value.postage_type_id == 73) {
    description = '<i class="fas fa-horse-head"></i> Test CP-X';
  } else if (value.postage_type_id == 21) {
    description = '<i class="fab fa-fedex"></i> FedEx Ground®';
  } else if (value.postage_type_id == 75) {
    description = '<i class="fab fa-fedex"></i> FedEx Standard Overnight®';
  } else if (value.postage_type_id == 76) {
    description = '<i class="fab fa-fedex"></i> FedEx Express Saver®';
  } else if (value.postage_type_id == 77) {
    description = '<i class="fab fa-fedex"></i> FedEx International Priority®';
  } else if (value.postage_type_id == 78) {
    description = '<i class="fab fa-fedex"></i> FedEx International Economy®';
  } else if (value.postage_type_id == 79) {
    description =
      '<i class="fab fa-fedex"></i> FedEx International Priority Express®';
  } else if (value.postage_type_id == 80) {
    description =
      '<i class="fab fa-canadian-maple-leaf"></i> Canada Post Regular';
  } else if (value.postage_type_id == 81) {
    description =
      '<i class="fab fa-canadian-maple-leaf"></i> Canada Post Priority';
  } else if (value.postage_type_id == 82) {
    description =
      '<i class="fab fa-canadian-maple-leaf"></i> Canada Post Expedited';
  } else if (value.postage_type_id == 83) {
    description =
      '<i class="fab fa-canadian-maple-leaf"></i> Canada Post Xpresspost';
  } else if (value.postage_type_id == 90) {
    description = '<i class="fas fa-horse-head"></i> Generic Label';
  } else if (value.postage_type_id == 91) {
    description =
      '<i class="fab fa-fedex"></i> FedEx International Connect Plus®';
  } else if (value.postage_type_id == 92) {
    description = '<i class="fab fa-fedex"></i> FedEx International First®';
  } else if (value.postage_type_id == 93) {
    description = '<i class="fab fa-fedex"></i> FedEx First Overnight®';
  } else if (value.postage_type_id == 94) {
    description = '<i class="fab fa-fedex"></i> FedEx Priority Overnight®';
  } else if (value.postage_type_id == 95) {
    description = '<i class="fab fa-fedex"></i> FedEx 2Day®';
  } else if (value.postage_type_id == 96) {
    description = '<i class="fab fa-fedex"></i> FedEx 2Day AM®';
  } else if (value.postage_type_id == 110) {
    description = '<i class="fa-solid fa-truck"></i> Stallion Economy Standard';
  } else if (value.postage_type_id == 111) {
    description = '<i class="fa-solid fa-truck"></i> Stallion Economy Standard';
  } else if (value.postage_type_id == 112) {
    description = '<i class="fa-solid fa-truck"></i> GSM Air Skip Plus';
  } else if (value.postage_type_id == 120) {
    description = '<i class="fa-solid fa-truck"></i> Canpar Ground';
  } else if (value.postage_type_id == 121) {
    description = '<i class="fa-solid fa-truck"></i> Canpar Overnight';
  } else if (value.postage_type_id == 122) {
    description = '<i class="fa-solid fa-truck"></i> Canpar Select';
  } else if (value.postage_type_id == 130) {
    description = '<i class="fas fa-horse-head"></i> Fleet Optics Express';
  } 
  else if (value.postage_type_id == 131) {
    description = '<i class="fas fa-horse-head"></i> Fleet Optics Ground';
  } 

  else if (value.postage_type_id == 140) {
    description = '<i class="fa-solid fa-truck"></i> ICS Ground';
  } 
  else if (value.postage_type_id == 141) {
    description = '<i class="fa-solid fa-truck"></i> ICS Express';
  } 
  else if (value.postage_type_id == 150) {
    description = '<i class="fa-solid fa-box"></i> UniUni';
  } else if (value.postage_type_id == 160) {
    description = '<i class="fab fa-ups"></i> UPS Next Day Air';
  }  else if (value.postage_type_id == 161) {
    description = '<i class="fab fa-ups"></i> UPS 2nd Day Air';
  } else if (value.postage_type_id == 162) {
    description = '<i class="fab fa-ups"></i> UPS Ground';
  } else if (value.postage_type_id == 163) {
    description = '<i class="fab fa-ups"></i> UPS Next Day Air Saver';
  } else if (value.postage_type_id == 164) {
    description = '<i class="fab fa-ups"></i> UPS Next Day Air Early';
  } else if (value.postage_type_id == 165) {
    description = '<i class="fab fa-ups"></i> UPS 2nd Day Air A.M.';
  } else if (value.postage_type_id == 166) {
    description = '<i class="fab fa-ups"></i> UPS Ground Saver';
  } else if (value.postage_type_id == 170) {
    description = '<i class="fab fa-ups"></i> UPS Economy';
  } else if (value.postage_type_id == 180) {
    description = '<i class="mdi mdi-clock-fast"></i> Ecom Next Day';
  } else if (value.postage_type_id == 181) {
    description = '<i class="mdi mdi-clock-fast"></i> Ecom Same Day';
  } else if (value.postage_type_id == 190) {
    description = '<i class="mdi mdi-clock-fast"></i> Purolator Ground';
  } else if (value.postage_type_id == 191) {
    description = '<i class="mdi mdi-clock-fast"></i> Purolator Express';
  }

  if (value.return_label == true) {
    description += ' (Return)';
  }

  return description;
});

Vue.filter('return_status', function (value) {
  switch (value) {
    case 'Awaiting Review':
      return '<i class="mdi mdi-account-question-outline"></i> ' + value;

    case 'Ready To Ship':
      return '<i class="mdi mdi-cube"></i> ' + value;

    case 'Complete':
      return '<i class="mdi mdi-check"></i> ' + value;

    case 'Ready':
      return '<i class="mdi mdi-cube"></i> ' + value;

    default:
      return value;
  }
});

Vue.filter('return_service', function (value) {
  switch (value) {
    case 'Action Required':
      return '<i class="mdi mdi-comment-question-outline"></i> ' + value;

    case 'Return Requested':
      return '<i class="mdi mdi-keyboard-backspace"></i> ' + value;

    case 'Dispose':
      return '<i class="mdi mdi-delete"></i> ' + value;

    default:
      return value;
  }
});

Vue.filter('bool', function (value) {
  value = parseInt(value);
  switch (value) {
    case 1:
      return 'Yes';

    case 0:
      return 'No';
  }
});

Vue.filter('formatSize', function (size) {
  if (size > 1024 * 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB';
  } else if (size > 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB';
  } else if (size > 1024 * 1024) {
    return (size / 1024 / 1024).toFixed(2) + ' MB';
  } else if (size > 1024) {
    return (size / 1024).toFixed(2) + ' KB';
  }
  return size.toString() + ' B';
});

Vue.filter('orderLink', function (shipment) {
  if (shipment.display_order_id) {
    return `<a target="_blank" href="/api/orders/redirect/${shipment.order_id}">${shipment.display_order_id}</a>`;
  } if (shipment.order_id) {
    return `<a target="_blank" href="/api/orders/redirect/${shipment.order_id}">${shipment.order_id}</a>`;
  }
  else {
    return null;
  }
});

Vue.filter('package_type', function (value) {
  // 2	Legal Flat Rate Envelope
  if (value == 2) {
    return '<i class="fab fa-usps"></i> Legal Flat Rate Envelope';
  }
  // 3	Flat Rate Padded Envelope
  else if (value == 3) {
    return '<i class="fab fa-usps"></i> Flat Rate Padded Envelope';
  }
  // 7	Small Flat Rate Box
  else if (value == 7) {
    return '<i class="fab fa-usps"></i> Small Flat Rate Box';
  }
  // 8	Medium Flat Rate Box
  else if (value == 8) {
    return '<i class="fab fa-usps"></i> Medium Flat Rate Box';
  }
  // 10	Large Flat Rate Box
  else if (value == 10) {
    return '<i class="fab fa-usps"></i> Large Flat Rate Box';
  }
  // 12	Regional Rate Box A
  else if (value == 12) {
    return '<i class="fab fa-usps"></i> Regional Rate Box A';
  }
  // 14	Regional Rate Box B
  else if (value == 14) {
    return '<i class="fab fa-usps"></i> Regional Rate Box B';
  }
  // 17	Letter
  else if (value == 17) {
    return '<i class="fa-solid fa-envelope-open-text"></i> Letter';
  }
  // 18	Large Envelope or Flat
  else if (value == 18) {
    return '<i class="fa-solid fa-envelope"></i> Large Envelope or Flat';
  }
  // 19	Thick Envelope
  else if (value == 19) {
    return '<i class="fa-solid fa-box"></i> Thick Envelope';
  }
  // 20	Parcel
  else if (value == 20) {
    return '<i class="fa-solid fa-box"></i> Parcel';
  }
  // 21 Flat Rate Envelope
  else if (value == 21) {
    return '<i class="fab fa-usps"></i> Flat Rate Envelope';
  }
});

Vue.filter('fulfillment_status', function (value) {
  // 1 Pending
  if (value == 1) {
    return 'Pending';
  }
  // 2	Complete
  else if (value == 2) {
    return 'Complete';
  }
  // 3	Failed
  else if (value == 3) {
    return 'Failed';
  }
  // 4	Canceled
  else if (value == 4) {
    return 'Canceled';
  }
});

Vue.filter('return_status', function (value) {
  //0
  if (value == 0) {
    return '<i class="mdi mdi-file-question-outline"></i> Unprocessed';
  }
  //1
  else if (value == 1) {
    return '<i class="mdi mdi-comment-question-outline"></i> Pending Details';
  }
  //3
  else if (value == 3) {
    return '<i class="mdi mdi-pause-octagon-outline"></i>	Hold';
  }
  // 5
  else if (value == 5) {
    return '<i class="mdi mdi-account-question-outline"></i> Awaiting Review';
  }
  // 10
  else if (value == 10) {
    return '<i class="mdi mdi mdi-cube"></i> Ready To Ship';
  }
  // 15
  else if (value == 15) {
    return '<i class="mdi mdi-delete"></i> US Disposal';
  }
  // 16
  else if (value == 16) {
    return '<i class="mdi mdi-clipboard-text-outline"></i> Pre Sorting';
  }
  // 17
  else if (value == 17) {
    return '<i class="mdi mdi-cube-send"></i> Sorting';
  }
  // 20
  else if (value == 20) {
    return '<i class="mdi mdi-cube-send"></i> Sorted';
  }
  // 25
  else if (value == 25) {
    return '<i class="mdi mdi-cube-send"></i> In Transit';
  }
  // 30
  else if (value == 30) {
    return '<i class="mdi mdi-home-map-marker"></i> In Canada';
  }
  // 35
  else if (value == 35) {
    return '<i class="mdi mdi-check"></i> Complete';
  }
  // 35
  else if (value == 36) {
    return '<i class="mdi mdi-alert-outline"></i> Error';
  }
});

Vue.filter('return_service', function (value) {
  //1
  if (value == 1) {
    return '<i class="mdi mdi-comment-question-outline"></i> Action Required';
  }
  //2
  else if (value == 2) {
    return '<i class="mdi mdi-keyboard-backspace"></i> Return Requested';
  }
  //3
  else if (value == 3) {
    return '<i class="mdi mdi-delete"></i> Dispose';
  }
  // 4
  else if (value == 4) {
    return '<i class="mdi mdi-delete-circle-outline"></i> Auto-Disposal';
  }
  // 5
  else if (value == 5) {
    return '<i class="mdi mdi-delete"></i> US-Disposal';
  }
});

Vue.filter('billing_type', function (value) {
  //1
  if (value === 1) {
    return 'Prepaid';
  }
  //2
  else if (value === 2) {
    return '<i class="mdi mdi-calendar-week"></i> Weekly';
  }
  //3
  else if (value === 3) {
    return '<i class="mdi mdi-calendar-month"></i> Monthly';
  }
});

Vue.filter('file_download', function (value) {
  if (!value) return '';
  /* eslint-disable no-useless-escape */
  return value.replace(/^.*[\\\/]/, '');
});

Vue.filter('client', function (user) {
  return `${user.id} - ${user.business_name || user.name || 'Registration Incomplete'}`;
});

Vue.filter('to_yes_no', function (value) {
  return value == 1 ? 'Yes' : 'No';
});

Vue.filter('location', function (value) {
  if (value === 1) {
    return 'Markham';
  } else if (value === 2) {
    return 'Markham';
  } else if (value === 4) {
    return 'Vaughan';
  } else if (value === 5) {
    return 'Missisauga';
  } else if (value === 6) {
    return 'Missisauga';
  } else if (value === 7) {
    return 'Hamilton';
  } else if (value === 8) {
    return 'Kitchener';
  } else if (value === 9) {
    return 'London';
  } else if (value === 10) {
    return 'Midtown Toronto';
  } else if (value === 11) {
    return 'East Toronto';
  } else if (value === 12) {
    return 'London';
  } else if (value === 13) {
    return 'Niagara Falls NY';
  }
});

Vue.filter('json_pretty', function (value) {
  return JSON.stringify(JSON.parse(value), null, 2);
});

Vue.filter('package_descriptions', function (value) {
  let html = '';
  if (value == 2) {
    html = `Large envelopes. Non-tracked`;
  } else if (value == 3) {
    html = `Large envelopes. Non-tracked`;
  } else if (value == 7) {
    html = `Large envelopes. Non-tracked`;
  } else if (value == 8) {
    html = `Large envelopes. Non-tracked`;
  } else if (value == 10) {
    html = `Large envelopes. Non-tracked`;
  } else if (value == 12) {
    html = `Large envelopes. Non-tracked`;
  } else if (value == 14) {
    html = `Large envelopes. Non-tracked`;
  } else if (value == 17) {
    html = `Small Envelopes. Non-tracked`;
  } else if (value == 18) {
    html = `Large Envelopes. Non-tracked`;
  } else if (value == 19) {
    html = `Bubble mailers and other large padded envelopes. Full Tracking Available`;
  } else if (value == 20) {
    html = `Small to large boxes and tubes. Full Tracking Available`;
  } else if (value == 21) {
    html = `Large envelopes. Non-tracked`;
  }

  return html;
});

Vue.filter('rule_filter', function (item) {
  if (!item.filter) {
    return 'Apply to All';
  } else {
    if (item.filter_criteria.field == 'postage_type') {
      return 'TEST';
    }
  }
  {
    /* <div
  v-for="filter_criteria in item.filter.filter_criteria"
  :key="filter_criteria.id"
>
  <strong>{{ filter_criteria.field | humanize }}:</strong>
  {{ filter_criteria.comparator | humanize }}

  <ul v-if="filter_criteria.field == 'postage_type'">
    <li v-if="!!!filter_criteria.value">
      NULL
    </li>

    <li
      v-for="i in filter_criteria.value.split(';')"
      v-else
      :key="i"
      v-html="$options.filters.postage(i)"
    />
  </ul>
  <ul v-else-if="filter_criteria.field == 'package_type'">
    <li v-if="!!!filter_criteria.value">
      NULL
    </li>

    <li
      v-for="i in filter_criteria.value.split(';')"
      v-else
      :key="i"
      v-html="$options.filters.package_type(i)"
    />
  </ul>
  <ul v-else-if="filter_criteria.field == 'country_code'">
    <li v-if="!!!filter_criteria.value">
      NULL
    </li>

    <li
      v-for="i in filter_criteria.value.split(';')"
      v-else
      :key="i"
    >
      {{ i | humanize }}
    </li>
  </ul>
  <span v-else>
    {{ filter_criteria.value | humanize }}
  </span>
</div>
</div>
<div v-else>
Apply to All
</div> */
  }
});
