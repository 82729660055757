<template>
  <v-card flat>
    <v-container class="px-0 py-0">
      <v-row>
        <v-col md="12">
          <v-data-table
            :headers="headers"
            :loading="loading"
            item-key="id"
            :items="data"
            class="table-striped elevation-0"
            hide-default-footer
          >
            <!-- Printer Column -->
            <template #item.printer="{ item }">
              <span>{{ item.printer }}</span>
            </template>

            <!-- Document Format Column -->
            <template #item.docFormat="{ item }">
              <span>{{ item.docFormat }}</span>
            </template>

            <!-- Print Action Column -->
            <template #item.printAction="{ item }">
              <span>{{ item.printAction }}</span>
            </template>

            <!-- Density Column -->
            <template #item.density="{ item }">
              <span>{{ item.density }}</span>
            </template>

            <!-- Actions Column -->
            <template #item.actions="{ item }">
              <v-btn
                small
                color="primary"
                outlined
                @click="editSetting(item)"
              >
                Edit
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <!-- Single Dialog Component -->
    <GenericSettingsDialog
      v-if="selectedItem"
      v-model="dialogStates.showDialog"
      :doc-type="selectedItem?.docName"
      :item="selectedItem"
      @save="updateItem"
    />
  </v-card>
</template>

<script>
import PRINTER from "@/constants/printer";
import GenericSettingsDialog from "./GenericSettingsDialog.vue";

const HEADERS = [
  { text: "Document Type", value: "docType" },
  { text: "Printer", value: "printer" },
  { text: "Print Action", value: "printAction" },
  { text: "Document Format", value: "docFormat" },
  { text: "Density (DPI)", value: "density" },
  { text: "", value: "actions", sortable: false },
];

const DEFAULT_PRINTER = "Default";

export default {
  components: {
    GenericSettingsDialog,
  },
  data() {
    const defaultPrinter = this.$store.state.qz.currentPrinter || DEFAULT_PRINTER;
    const defaultSettings = {
      printer: defaultPrinter,
      printAction: PRINTER.PRINT_ACTION.PROMPT,
      density: PRINTER.DENSITIES.DEFAULT,
      sort_order : 'desc',
      sort_by: 'id',
    };

    return {
      PRINTER,
      headers: HEADERS,
      data: [
        {
          docType: "Label",
          docName: PRINTER.DOCUMENT_FORMATS.LABEL,
          docFormat: PRINTER.DIMENSIONS.FOUR_BY_SIX,
          ...defaultSettings,
        },
        {
          docType: "Packing Slip",
          docName: PRINTER.DOCUMENT_FORMATS.PACKING_SLIP,
          docFormat: PRINTER.DIMENSIONS.FOUR_BY_SIX,
          ...defaultSettings,
        },
        {
          docType: "Label & Packing Slip",
          docName: PRINTER.DOCUMENT_FORMATS.LABEL_WITH_PACKING_SLIP,
          docFormat: PRINTER.DIMENSIONS.FOUR_BY_SIX,
          ...defaultSettings,
        },
        {
          docType: "Pick List",
          docName: PRINTER.DOCUMENT_FORMATS.PICK_LIST,
          docFormat: PRINTER.DIMENSIONS.LETTER,
          ...defaultSettings,
        },
      ],
      docFormats: Object.values(PRINTER.DIMENSIONS),
      printActions: Object.values(PRINTER.PRINT_ACTION),
      densities: Object.values(PRINTER.DENSITIES),
      loading: false,
      printers: [],
      dialogStates: {
        showDialog: false,
      },
      selectedItem: null,
    };
  },
  async mounted() {
    await this.fetchPrinterList();
    Event.listen("save-printer-settings", this.saveSettings);
    const printingSettings = this.getPrinterSettingData(true);
    if (printingSettings) {
      this.data = printingSettings;
    }
  },
  methods: {
    printActionHint(printAction) {
      const hints = {
        [PRINTER.PRINT_ACTION.DIRECT]:
          "Print directly to your printer via QZ Tray with your default sort settings",
        [PRINTER.PRINT_ACTION.PROMPT]:
          "Prompt to download or print the document",
        [PRINTER.PRINT_ACTION.DOWNLOAD]:
          "Download the document to your computer",
      };
      return hints[printAction] || "No print action selected";
    },
    saveSettings() {
      this.savePrintingSettings(this.data);
    },
    async fetchPrinterList() {
      if (this.isQzInitialized()) {
        this.printers = await this.fetchConnectedPrinters();
      }
      this.printers.unshift(DEFAULT_PRINTER);
    },
    editSetting(item) {
      this.selectedItem = { ...item }; // Create a copy to avoid direct mutation
      this.dialogStates.showDialog = true;
    },
    updateItem(updatedItem) {
      // Find the index of the item and update it
      const index = this.data.findIndex(
        (item) => item.docName === updatedItem.docName
      );
      if (index !== -1) {
        this.$set(this.data, index, updatedItem);
      }
      this.dialogStates.showDialog = false;
      this.$emit('save-printer-settings');
    },
  },
};
</script>
