<template>
  <StallionDialog
    v-model="show"
    :disabled="disabled"
    title="Content Photos"
  >
    <template #content>
      <v-container>
        <v-row
          v-if="returnShipment?.content_images.length > 1"
          justify="space-around"
        >
          <v-col
            v-for="(image, index) in returnShipment.content_images"
            :key="index"
            md="6"
            class="pa-3"
          >
            <v-tooltip bottom>
              <template #activator="{ on }">
                <a
                  :href="image.full_image_url"
                  target="_blank"
                  v-on="on"
                >
                  <v-card hover>
                    <v-img
                      contain
                      :src="image.full_image_url"
                      class="elevation-1"
                    >
                      <v-layout
                        slot="placeholder"
                        fill-height
                        align-center
                        justify-center
                        ma-0
                      >
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        />
                      </v-layout>
                    </v-img>
                  </v-card>
                </a>
              </template>
              <span>View Image</span>
            </v-tooltip>
          </v-col>
        </v-row>

        <v-row
          v-else-if="!returnShipment?.images_required"
          justify="space-around"
          class="mt-3"
        >
          <v-col
            md="12"
            center-text
          >
            <p
              style="font-size: 1.3em;"
              class="text-center"
            >
              Request a content photo for {{ returnShipment.unique_id }}
            </p>

            <h2 class="text-center mt-12 mb-12">
              1 x {{ 2.5 | currency }} =
              {{ cost.subtotal | currency }}
            </h2>

            <ul
              style="
                  list-style-type: none;
                  list-style-position: inside;
                  margin: 0;
                  padding: 0;
                  font-size: 1.3em;
                "
            >
              <li>
                <strong>Tax:</strong>
                <span class="float-right">{{ cost.tax | currency }}</span>
              </li>
              <li>
                <strong>Total:</strong>
                <span class="float-right">{{
                  (cost.subtotal + cost.tax) | currency
                }}</span>
              </li>
            </ul>

            <p
              v-if="$auth.user?.bill_returns_monthly"
              class="text-center mt-3"
            >
              (You will be billed at the end of the month)
            </p>
          </v-col>
        </v-row>
        <v-row
          v-else
          justify="space-around"
          class="mt-3"
        >
          <v-col
            md="12"
            center-text
          >
            <h2 class="text-center">
              A content photo has been requested.
            </h2>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template #actions>
      <v-btn
        v-if="
          !returnShipment?.images_required &&
            returnShipment.content_images.length == 0
        "
        color="primary"
        :loading="loading"
        :disabled="loading"
        @click="submit()"
      >
        Request Photo
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    returnShipment: {
      type: Object,
      default: function() {
        return {
          content_images: []
        };
      }
    },
    disabled: {
      default: false,
      type: Boolean
    },
    updateImageStatus: {
      type: Function,
      default: () => {}
    },
    cost: {
      type: Object,
      required: true,
      default: () => {
        return {
          subtotal: 0,
          tax: 0,
          total: 0
        };
      }
    }
  },

  data() {
    return {
      images: [],
      show: null,
      loading: null,
      file: null,
      rows: [
        {
          description: null,
          value: null,
          quantity: 1
        }
      ]
    };
  },
  mounted() {
    this.$http
      .get('returns/image-cost')
      .then(response => response.data)
      .then(response => {
        if (response.success) {
          this.cost = response.price;
        }
      });
  },
  methods: {
    openDialog() {
      this.show = true;
    },
    submit: function() {
      if (!this.creditCheck(this.return_request_price)) {
        return null;
      }

      this.loading = true;

      this.$http
        .post('returns/request-photo', {
          return_id: this.returnShipment.id,
          request_additional_images: true
        })
        .then(response => response.data)
        .then(() => {
          Event.fire('update-returns');
          Event.fire('get-credits');

          this.successMessage('Request sent successfully');
          this.updateImageStatus();
          this.show = false
        })
        .catch(() => {
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
