<template>
  <v-container grid-list-lg>
    <v-row v-if="edit">
      <v-col cols="12">
        <v-text-field
          v-model="phone"
          v-mask="'###########'"
          v-validate="'required'"
          :error-messages="errs.collect('phone')"
          label="Phone"
          data-vv-name="phone"
          dusk="phone"
          outlined
        />
      </v-col>

      <v-col cols="12">
        <div class="text-center">
          <v-btn
            depressed
            outlined
            color="primary"
            class="mr-5"
            @click="edit = false"
          >
            Cancel
          </v-btn>

          <v-btn
            depressed
            color="primary"
            @click="updatePhone"
          >
            Update
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <span
          class="text-h4"
        >{{ $auth.user.phone }}
          <v-btn
            outlined
            small
            color="primary"
            class="ma-2"
            @click="edit = true"
          >
            Edit
          </v-btn></span>
      </v-col>
      <v-col
        cols="12"
        class="text-center"
      >
        <CodeInput
          auto-focus
          class="input"
          type="number"
          @complete="onComplete"
        />
        <v-btn
          :loading="check_loading"
          :disabled="check_loading"
          color="primary"
          class="mt-5"
          @click="sendCode()"
        >
          Send Code
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        class="text-center"
      >
        <a
          style="font-weight: 700; text-decoration: none"
          @click.prevent="logout()"
        >Log me out</a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CodeInput from 'vue-verification-code-input';
export default {
  components: {
    CodeInput
  },
  props: {
    shipments: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      check_loading: false,
      edit: false,
      phone: null,
      sent: false
    };
  },
  methods: {
    updatePhone() {
      this.$http
        .post('/auth/update-phone', {
          phone: this.phone
        })
        .then((res) => {
          if (res.data.success) {
            this.getUser();
            this.edit = false;
            this.sent = false;
          } else {
            this.errorMessage(res.data.message);
          }

          this.processing = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    sendCode() {
      this.check_loading = true;
      this.$http
        .get('/send-code')
        .then((response) => {
          if (response.data.success) {
            this.successMessage(response.data.message);

            this.getUser();

            this.sent = true;
          } else {
            this.errorMessage(response.data.message);
          }
        })
        .finally(() => {
          this.check_loading = false;
        });
    },
    checkCode() {
      this.check_loading = true;
      this.$http
        .post('/check-code', {
          code: this.code
        })
        .then((response) => {
          if (response.data.success) {
            this.successMessage(response.data.message);
            this.getUser();
            this.$router.push('/');
          } else {
            this.errorMessage(response.data.message);
          }
        })
        .finally(() => {
          this.check_loading = false;
        });
    },
    onComplete(v) {
      this.code = v;
      this.checkCode();
    }
  }
};
</script>
