<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-btn
          color="primary"
          dark
          @click="$refs.boxDialog.startNew()"
        >
          Create Box
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table
          class="table-striped"
          :headers="headers"
          :items="items"
          :loading="loading"
          :items-per-page="10"
        >
          <template #item.actions="{ item }">
            <v-btn
              small
              color="red"
              text
              @click="deleteBox(item.id)"
            >
              Delete
            </v-btn>
            <v-btn
              small
              color="primary"
              text
              @click="$refs.boxDialog.startEdit(item)"
            >
              Edit
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <box-dialog
      ref="boxDialog"
      @update-boxes="updateTable()"
    />
  </div>
</template>
<script>
import BoxDialog from './BoxDialog.vue';
export default {
  components: {
    BoxDialog
  },
  data() {
    return {
      box: {},
      show_edit: false,
      loading: false,
      items: [],
      options: {},
      headers: [
        { text: 'Name', value: 'name', sortable: false },
        { text: 'Package Type', value: 'package_type.description', sortable: false },
        { text: 'Length', value: 'length', sortable: false },
        { text: 'Width', value: 'width', sortable: false },
        { text: 'Height', value: 'height', sortable: false },
        { text: 'Unit Size', value: 'size_unit', sortable: false },
        { text: 'Weight', value: 'weight', sortable: false},
        { text: 'Actions', value: 'actions', align: 'right', sortable: false }
      ]
    };
  },
  watch: {
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    },
    'options.itemsPerPage': function (newVal, oldVal) {
      if (oldVal && newVal) {
        const preference = {
          table_rows: {
            filters: newVal
          }
        };
        this.$store.dispatch('main/setPreferences', preference);
      }
    }
  },

  mounted() {
    this.updateTable();

    const table_rows = this.$store.getters['main/table_rows'];
    this.options.itemsPerPage = table_rows?.filters ?? 5;
  },
  methods: {
    updateTable() {
      this.totalItems = 0;
      this.items = [];

      this.$http
        .request(`/boxes`, {
          method: 'get'
        })
        .then((response) => response.data)
        .then((response) => {
          this.items = response;
        });
    },
    async deleteBox(id) {
      const isConfirm = await swal({
        title: 'Confirm Delete',
        text: 'Do you really want to delete this box?',
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      });

      if (isConfirm) {
        try {
          await this.$store.dispatch('boxes/deleteBox', id);
          this.successMessage('Box successfully deleted!');
          this.updateTable();
        } catch (e) {
          console.log(e);
        }
      }
    },
    showUpdate(box) {
      this.show_edit = true;
      this.box = box;
    },
    showCreate() {
      this.show_edit = true;
      this.box = null;
    }
  }
};
</script>
