<template>
  <FullPageLayout
    title="Products"
  >
    <template #subtitle>
      <p>
        You can use this page to override various import settings for each
        item. For any change to take effect you will need to re-sync your
        stores.
      </p>
    </template>
    <template #page-links>
      <order-links />
    </template>
    <template #default>
      <product-table ref="filterTable" />
    </template>
  </FullPageLayout>
</template>

<script>
import ProductTable from './ProductTable.vue';
export default {
  metaInfo: {
    title: "Products | Stallion Express"
  },
  components: {
    ProductTable
  },
  props: {},
  data() {
    return {};
  },
  methods: {}
};
</script>
