<template>
  <v-layout justify-center>
    <v-flex
      xs12
      sm8
      md6
      mb-12
      mt-4
    >
      <v-card class="elevation-5">
        <v-col
          col-4
          text-left
          style="padding-bottom: unset"
        >
          <v-btn
            large
            text
            color="secondary"
            style="
              padding: unset;
              justify-content: start;
              align-items: start;
              align-content: start;
              height: unset;
              width: unset;
              margin: 0;
              text-align: center;
              min-width: unset;
              margin: 0 5px 0 0;
              padding: 0.5rem;
            "
            @click="setStep('postage')"
          >
            <v-icon dark>
              mdi-arrow-left
            </v-icon> Back
          </v-btn>
        </v-col>
        <v-card-text style="margin: unset; padding-top: unset">
          <v-flex
            xs12
            text-center
          >
            <h2 class="font-weight-bold mt-4">
              Summary
            </h2>

            <!-- <v-alert
              class="pa-2"
              v-if="shipment.missingTrackingNumber"
              dismissible
              type="warning"
              transition="fade-transition"
              >You did not input a tracking number for your third party postage.
              Please note that packages will not be accepted at our Drop & Go
              locations without a tracking number.</v-alert
            > -->
          </v-flex>
          <div id="model-summary">
            <div class="row mt-4">
              <div class="col-md-6">
                <summary-return-address :shipment="shipment" />
              </div>
              <div class="col-md-6">
                <summary-to-address :shipment="shipment" />
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <summary-shipment-details :shipment="shipment" />
              </div>

              <div
                v-if="rate"
                class="col-md-6"
              >
                <summary-estimated-cost
                  :rate="rate"
                  :shipment="shipment"
                />
              </div>
            </div>

            <summary-info-message :shipment="shipment" />

            <summary-agree v-model="summary_agree" />

            <div class="text-center mt-4">
              <div class="col-12">
                <v-form>
                  <v-btn
                    large
                    depressed
                    color="primary"
                    :disabled="!summary_agree"
                    dusk="sf_summary_next"
                    @click="completeShipment()"
                  >
                    Next
                    <v-icon dark>
                      mdi-arrow-right
                    </v-icon>
                  </v-btn>
                </v-form>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import SummaryReturnAddress from './summary-return-address.vue';
import SummaryToAddress from './summary-to-address.vue';
import SummaryShipmentDetails from './summary-shipment-details.vue';
import SummaryEstimatedCost from './summary-estimated-cost.vue';
import SummaryInfoMessage from './summary-info-message.vue';
import SummaryAgree from './summary-agree.vue';

export default {
  components: {
    SummaryReturnAddress,
    SummaryToAddress,
    SummaryShipmentDetails,
    SummaryEstimatedCost,
    SummaryInfoMessage,
    SummaryAgree
  },
  data() {
    return {
      errors: {},
      summary_agree: false,
      disabledDate: {
        to: new Date(new Date().setDate(new Date().getDate() - 1)),
        days: [6]
      },
      menu1: false,
      searchInput1: '',
      allowedStartDate: moment().format('YYYY-MM-DD'),
      dialog: false
    };
  },
  computed: {
    rate: {
      get() {
        return this.$store.state.singleFlow.rate;
      },
      set(value) {
        this.$store.state.singleFlow.rate = value;
      }
    },
    shipment: {
      get() {
        return this.$store.state.singleFlow.shipment;
      },
      set(value) {
        this.$store.state.singleFlow.shipment = value;
      }
    },
    step: {
      get() {
        return this.$store.state.singleFlow.step;
      },
      set(value) {
        this.$store.state.singleFlow.step = value;
      }
    },
    processing: {
      get() {
        return this.$store.state.singleFlow.processing;
      },
      set(value) {
        this.$store.state.singleFlow.processing = value;
      }
    }
  },
  watch: {
    step: {
      immediate: true,
      handler(newValue, oldValue) {
        // alert(newValue);
        if (newValue == 6 && (oldValue < 6 || oldValue == undefined)) {
          // this.returnAddress();
        }
      }
    }
  },
  mounted() {
    window.addEventListener('keydown', (e) => {
      if (
        this.summary_agree &&
        this.processing == 0 &&
        this.step == 6 &&
        e.key == 'Enter'
      ) {
        this.completeShipment();
      }
    });
  },

  methods: {
    allowedDates: (val) => ![5].includes(new Date(val).getDay()),
    setStep() {
      this.$store.commit('singleFlow/previous');
    },
    completeShipment() {
      this.processing++;
      this.$validator.validate().then((result) => {
        if (result) {
          this.$http
            .put('/shipments/' + this.shipment.id + '/complete', this.shipment)
            .then((res) => {
              if (!res.data.success) {
                this.$notify({
                  group: 'main',
                  title: 'Error',
                  text: res.data.errors,
                  duration: 8000,
                  type: 'error'
                });
              } else {
                Event.fire('get-credits');

                this.updateShipment();
              }
            })
            .catch((err) => {
              let errMsg = err.response.data.errors
              if (Array.isArray(errMsg)) {
                errMsg = errMsg.join('. ')
              }

              this.$notify({
                group: 'main',
                title: 'Error',
                text: errMsg || 'Something went wrong. Please try again.',
                duration: 5000,
                type: 'error'
              });
            })
            .finally(() => {
              this.processing--;
            });
        }
      });
    },

    updateShipment() {
      this.$validator.validate().then((result) => {
        if (result) {
          this.$http
            .get('/shipments/' + this.shipment.id)
            .then((res) => {
              this.shipment = res.data;
              this.$store.commit('singleFlow/next');
            })
            .catch(() => {});
        }
      });
    }
  }
};
</script>

<style>
#model-summary {
  color: black;
}
</style>
