<template>
  <div>
    <v-container
      v-if="statement"
      class="mb-100"
    >
      <v-row>
        <v-col cols="12">
          <h1>Statement {{ statement.end_date }}</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-card outlined>
            <v-simple-table>
              <template #default>
                <tbody>
                  <tr>
                    <td><strong>Open Balance</strong></td>
                    <td class="text-right">
                      {{ statement.open_balance | currency }}
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Payments</strong></td>
                    <td class="text-right">
                      {{ statement.payment_total | currency }}
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Charges</strong></td>
                    <td class="text-right">
                      {{ statement.charge_total | currency }}
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Close Balance</strong></td>
                    <td class="text-right">
                      {{ statement.close_balance | currency }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card outlined>
            <v-simple-table>
              <template #default>
                <tbody>
                  <tr>
                    <td><strong>Start Date</strong></td>
                    <td class="text-right">
                      {{ statement.start_date | date_pretty }}
                    </td>
                  </tr>
                  <tr>
                    <td><strong>End Date</strong></td>
                    <td class="text-right">
                      {{ statement.end_date | date_pretty }}
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Amount Due</strong></td>
                    <td class="text-right">
                      {{ statement.amount_due | currency }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <transaction-table
            v-if="$auth.user"
            :is-statement="true"
            :statement-id="id"
            :start-date="statement.start_date"
            :end-date="statement.end_date"
            :user-id="$auth.user.id"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: `Statement ${this.id} | Stallion Express`
    }
  },
  props: {
    id: {
      default: null,
      type: [Number, String]
    }
  },

  data() {
    return {
      statement: null,
      show: false
    };
  },
  mounted() {
    this.getStatement();
    if (this.$route.query.print) {
      this.show = true;
    }
  },
  methods: {
    getStatement() {
      this.$http
        .get(`/statements/${this.id}`)
        .then(response => {
          this.statement = response.data;
        })
        .catch(() => {})
        .finally(() => {});
    }
  }
};
</script>
