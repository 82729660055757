<template>
  <v-container
    class="py-5 px-8"
    fluid
    style="background-color: #fcfcfc"
  >
    <v-container>
      <v-row>
        <v-col>
          <h1>CBSA CARM Profile Agreement</h1>
        </v-col>
      </v-row>

      <!-- Completed CARM Agreement Banner -->
      <v-row v-if="carm_agree_status">
        <v-col>
          <v-alert
            type="success"
            text
          >
            You have already completed your CARM profile agreement. Thank you!
          </v-alert>
        </v-col>
      </v-row>

      <!-- CBSA CARM Information Section -->
      <v-row>
        <v-col>
          <p>CBSA CARM System Launch in October 2024</p>
          <v-card
            outlined
            class="pa-5"
          >
            <p>
              The Canada Border Services Agency (CBSA) is introducing the CARM (Assessment and Revenue Management) system in October 2024. This update will modernize duties and taxes collection for imported goods.
            </p>
            <p><strong>No changes in duties and taxes</strong></p>
            <p><strong>Action Required:</strong> Clients must create a CARM account to process returns to Canada.</p>
            <p>
              <strong>Get Started:</strong> Access the <a
                href="https://ccp-pcc.cbsa-asfc.cloud-nuage.canada.ca/en/onboarding-documentation"
                target="_blank"
              >CARM setup guide</a>
            </p>
            <p>
              <strong>More Info:</strong> <a
                href="https://www.cbsa-asfc.gc.ca/services/carm-gcra/schedule-calendrier-eng.html"
                target="_blank"
              >Visit the CBSA website</a>
            </p>
            <p>Sign up for your CARM account now to ensure smooth returns. Need help? Contact us anytime at +1 877-863-7447 or <a href="mailto:hello@stallionexpress.ca">hello@stallionexpress.ca</a>&nbsp;!</p>
            <v-btn
              color="primary"
              outlined
              href="https://www.cbsa-asfc.gc.ca/services/carm-gcra/menu-eng.html"
              target="_blank"
            >
              Learn More About CARM
            </v-btn>
          </v-card>
        </v-col>
      </v-row>

      <!-- Check if the user has already completed their CARM agreement -->
      <v-row v-if="$auth.user?.carm_agree">
        <v-col>
          <v-alert type="success">
            You have already completed your CARM profile agreement on {{ $auth.user.carm_completion_date }}.
          </v-alert>
        </v-col>
      </v-row>

      <!-- CARM Agreement Confirmation Section (if not completed) -->
      <v-row v-else>
        <StallionDialog
          v-model="show_confirm"
          title="CARM Profile Confirmation"
          :width="500"
        >
          <template #content>
            <v-row dense>
              <v-col
                cols="12"
                text-center
              >
                <h4>
                  In order to process returns to Canada, you must confirm that you have completed your CARM profile.
                </h4>
                <v-col
                  cols="12"
                  text-center
                  mt-3
                >
                  <v-alert
                    text
                    type="warning"
                  >
                    Please ensure you complete your CARM profile to avoid delays in processing returns.
                  </v-alert>
                </v-col>
              </v-col>
            </v-row>
            <v-divider />
          </template>

          <template #actions>
            <v-spacer />
            <v-btn
              outlined
              color="primary"
              @click="acceptDisclaimer()"
            >
              Accept
            </v-btn>
          </template>
        </StallionDialog>

        <!-- CARM Agreement Card -->
        <v-card outlined>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <p>Once you have completed your CARM profile, you may fill out the profile agreement below to notify Stallion of your completion.</p>
                  <h2 class="pa-5">
                    CARM Profile Agreement
                  </h2>

                  <v-card
                    outlined
                    class="pa-5"
                  >
                    <v-row dense>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="carm_completion_date"
                          v-validate="'required|date_format:yyyy-MM-dd'"
                          outlined
                          label="Date Completed"
                          :value="new Date().toISOString().substr(0, 10)"
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <v-col cols="12">
                        <h2>Signature</h2>
                        <VueSignaturePad
                          id="signature"
                          ref="signaturePad"
                          width="100%"
                          height="200px"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        text-center
                      >
                        <v-btn
                          depressed
                          small
                          @click="clear"
                        >
                          Clear
                        </v-btn>
                        <v-btn
                          depressed
                          small
                          @click="undo"
                        >
                          Undo
                        </v-btn>
                      </v-col>
                      <v-col cols="12">
                        Confirming this profile ensures smooth returns and does not affect your pricing or other agreements.
                      </v-col>
                      <v-col
                        cols="12"
                        text-center
                      >
                        <v-checkbox
                          v-model="agree"
                          v-validate="'required'"
                          outlined
                          label="I confirm that I have completed my CARM profile"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        text-center
                      >
                        <v-btn
                          color="primary"
                          :disabled="!agree || processing"
                          :loading="processing"
                          @click="submitConfirmation"
                        >
                          Confirm
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import VueSignaturePad from 'vue-signature-pad';

export default {
  metaInfo: {
    title: "CARM Profile Agreement | Stallion Express"
  },
  components: {
    VueSignaturePad
  },
  data() {
    return {
      carm_completion_date: new Date().toISOString().substr(0, 10), // Defaults to today
      agree: false, // Will be updated based on the API response
      processing: false,
      show_confirm: false,
      carm_agree_status: null, // Will hold the true/false value of 'agree'
    };
  },
  mounted() {
    // On component load, make a GET request to check CARM agreement status
    this.checkCarmAgreement();
  },
  methods: {
    checkCarmAgreement() {
      // GET request to /returns/carm-agree
      this.$http
        .get('/returns/carm-agree')
        .then((res) => {
          if (res.data.success) {
            this.carm_agree_status = res.data.agree;
          } else {
            this.carm_agree_status = false; // Handle unexpected responses
          }
        })
        .catch(() => {
          this.carm_agree_status = false; // Handle errors
        });
    },
    clear() {
      this.$refs.signaturePad.clearSignature();
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    submitConfirmation() {
      this.processing = true;

      if (this.$refs.signaturePad.isEmpty()) {
        this.errorMessage('No signature entered');
        this.processing = false;
        return;
      }

      const { data } = this.$refs.signaturePad.saveSignature();

      this.$http
        .post('/returns/carm-agree', {
          agree: this.agree,
          carm_completion_date: this.carm_completion_date,
          signature: data,
        })
        .then((res) => {
          this.processing = false;
          if (res.data.success) {
            this.$notify({
              group: 'main',
              title: 'CARM Agreement Submitted',
              text: 'Your CARM agreement has been submitted successfully.',
              duration: 5000,
              type: 'success',
            });
            this.carm_agree_status = true; // Update status if successful
          } else {
            this.errorMessage(res.data.errors);
          }
        })
        .catch(() => {
          this.processing = false;
        });
    }
  }
};
</script>

<style scoped>
#signature {
  border: solid 3px rgba(128, 128, 128, 0.5);
  border-radius: 5px;
}

.header-container {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 600px) {
  .header-container {
    display: block;
  }
}
</style>
