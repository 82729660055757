<template>
  <v-card
    class="elevation-0"
    style="border: 1px solid #e7e7e7;"
  >
    <v-card-text>
      <v-flex xs12>
        <span class="text-h3">
          <v-icon left>mdi-file-document</v-icon>Monthly Summary
        </span>
      </v-flex>
      <v-form @submit.prevent>
        <v-layout
          align-center
          class="mt-4 mb-4"
        >
          <v-flex
            xs12
            text-center
          >
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template #activator="{ on }">
                <v-text-field
                  :value="computedDateFormattedMomentjs"
                  label="Month"
                  prepend-icon="event"
                  readonly
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="date"
                type="month"
                @input="menu = false"
              />
            </v-menu>
          </v-flex>
        </v-layout>
        <v-layout
          align-center
          class="mt-4 mb-4"
        >
          <v-flex
            xs12
            text-center
          >
            <v-btn
              :loading="loading"
              :disabled="loading"
              color="primary"
              @click="getInvoice()"
            >
              Download
            </v-btn>
          </v-flex>
        </v-layout>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    userId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      date: moment()
        .subtract(1, 'months')
        .format('YYYY-MM'),
      endDate: moment()
        .subtract(1, 'months')
        .format('YYYY-MM'),
      menu: false,
      loading: false
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.date ? moment(this.date).format('MMMM YYYY') : '';
    }
  },
  methods: {
    getInvoice() {
      this.loading = true;
      let id = this.userId ? '?user_id=' + this.userId : '';

      this.$http
        .get('/users/invoice/' + this.date + id)
        .then(response => {
          this.downloadFileUrl(response.data, 'StallionExpressInvoice' + this.date + '.pdf');
        })
        .catch(err => {
          if (err.response.status === 500) {
            this.errorMessage('No invoice available for this month');
          }
        })

        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
