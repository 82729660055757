<template>
  <div>
    <v-container
      class="pt-4 pb-10 px-8"
      fluid
      style="height: 100vh; background-color: #fcfcfc"
    >
      <v-container class="px-0 pt-0 mt-4 mx-0">
        <h2 class="font-weight-bold">
          Settings
        </h2>
      </v-container>

      <v-card outlined>
        <v-tabs
          v-model="tabSelected"
          :center-active="$vuetify.breakpoint.mobile"
          :show-arrows="$vuetify.breakpoint.mobile"
          :left="!$vuetify.breakpoint.mobile"
          :vertical="!$vuetify.breakpoint.mobile"
          :class="{ 'my-tabs': !$vuetify.breakpoint.mobile }"
          class="elevation-0"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.title + '1'"
          >
            <v-icon
              small
              left
            >
              {{ tab.icon }}
            </v-icon>
            <h4 class="settings-tab">
              {{ tab.title }}
            </h4>
          </v-tab>

          <v-tab-item
            v-for="tab in tabs"
            :key="tab.title + '2'"
          >
            <v-card
              flat
              max-width="1200"
              class="settings-card"
            >
              <v-card-title>{{ tab.title }}</v-card-title>
              <v-card-text>
                <component
                  :is="tab.component"
                  v-if="$auth.user"
                  :main-settings-tab="tabSelected"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import UserStoreImportSettings from '@/components/main/user/user-store-settings.vue';

import AccountSettings from './tabs/AccountSettings.vue';
import UserManagementSettings from './tabs/UserManagement/UserManagementSettings.vue';
import BranchSettings from './tabs/BranchSettings.vue';
import AddressBook from './tabs/AddressBook/index.vue';
import UserPickupLocationSettings from './tabs/UserPickupLocationSettings.vue';
import ShipmentSettings from './tabs/ShipmentSettings.vue';
import PackingSlipTemplateTable from './tabs/PackingSlipTemplate/PackingSlipTemplateTable.vue';
import EmailTemplateSettings from './tabs/EmailTemplate/index.vue';
import ShipmentLabelSettings from './tabs/ShipmentLabelSettings.vue';
import WorkflowSettings from './tabs/WorkflowSettings.vue';
import PostageSettings from './tabs/PostageSettings.vue';
import NotificationSettings from './tabs/NotificationSettings.vue';
import PasswordSettings from './tabs/PasswordSettings.vue';
import ReturnSettings from './tabs/ReturnSettings.vue';
import ImportSettings from './tabs/ImportSettings.vue';
import ExportSettings from './tabs/ExportSettings.vue';
import PresetSettings from './tabs/PresetSettings.vue';
import StoreSettings from './tabs/StoreSettings.vue';
import ApiSettings from './tabs/ApiSettings.vue';
import TwoFactorSettings from './tabs/TwoFactor/TwoFactorSettings.vue';
import TaxIdentifierSettings from './tabs/TaxIdentifier/index.vue';
import PrinterSettings from './tabs/PrinterSetting/PrinterSettings.vue';
import ScaleSettings from './tabs/ScaleSettings.vue';
import BatchSummarySettings from './tabs/BatchSummarySettings.vue';
import CustomerEmails from './tabs/CustomerEmails.vue';

export default {
  components: {
    AccountSettings,
    UserManagementSettings,
    BranchSettings,
    AddressBook,
    UserPickupLocationSettings,
    ShipmentSettings,
    PackingSlipTemplateTable,
    EmailTemplateSettings,
    ShipmentLabelSettings,
    WorkflowSettings,
    PostageSettings,
    NotificationSettings,
    PasswordSettings,
    ReturnSettings,
    ImportSettings,
    ExportSettings,
    PresetSettings,
    StoreSettings,
    ApiSettings,
    UserStoreImportSettings,
    TwoFactorSettings,
    TaxIdentifierSettings,
    PrinterSettings,
    ScaleSettings,
    BatchSummarySettings,
    CustomerEmails
  },
  data() {
    return {
      tabSelected: null
    };
  },
  computed: {
    tabs() {
      let tabs = [
        {
          title: 'Account Owner',
          icon: 'mdi-account-circle-outline',
          component: 'account-settings'
        },
        {
          title: 'User Management',
          icon: 'mdi-account-network',
          component: 'user-management-settings'
        },
        {
          title: 'Your Branch',
          icon: 'mdi-map-marker-outline',
          component: 'branch-settings'
        },
        {
          title: 'My Addresses',
          icon: 'mdi-book-account-outline',
          component: 'address-book'
        },
        {
          title: 'Pickups',
          icon: 'mdi-truck-fast',
          component: 'user-pickup-location-settings'
        },
        {
          title: 'New Shipments',
          icon: 'mdi-package-variant',
          component: 'shipment-settings'
        },
        {
          title: 'Shipment Label',
          icon: 'mdi-label',
          component: 'shipment-label-settings'
        },
        {
          title: 'Workflow Settings',
          icon: 'mdi-window-shutter-cog',
          component: 'workflow-settings'
        },

        {
          title: 'Email Templates',
          icon: 'mdi-email-edit-outline',
          component: 'email-template-settings'
        },

        {
          title: 'Packing Slips',
          icon: 'mdi-language-html5',
          component: 'packing-slip-template-table'
        },
        {
          title: 'Batch Settings',
          icon: 'mdi-label',
          component: 'batch-summary-settings'
        },
        {
          title: 'Postage Types',
          icon: 'mdi-label-outline',
          component: 'postage-settings'
        },
        {
          title: 'Notifications',
          icon: 'mdi-bell-outline',
          component: 'notification-settings'
        },
        {
          title: 'Change Password',
          icon: 'mdi-key',
          component: 'password-settings'
        },
        {
          title: 'Returns',
          icon: 'mdi-keyboard-backspace',
          component: 'return-settings'
        },
        {
          title: 'Import Presets',
          icon: 'mdi-auto-upload',
          component: 'preset-settings'
        },
        {
          title: 'Import',
          icon: 'mdi-briefcase-upload',
          component: 'import-settings'
        },
        {
          title: 'Export',
          icon: 'mdi-briefcase-download',
          component: 'export-settings'
        },
        {
          title: 'Store',
          icon: 'mdi-store',
          component: 'store-settings'
        },
        {
          title: '2-Step Verification',
          icon: 'mdi-barcode',
          component: 'two-factor-settings'
        },
        {
          title: 'Tax Identifiers',
          icon: 'mdi-earth',
          component: 'tax-identifier-settings'
        },
        {
          title: 'API Token',
          icon: 'mdi-tag-outline',
          component: 'api-settings'
        },
        {
          title: 'Printer Settings',
          icon: 'mdi-printer-outline',
          component: 'printer-settings'
        },
        {
          title: 'Scale Setting',
          icon: 'mdi-scale',
          component: 'scale-settings'
        },
        {
          title: 'Customer Emails',
          icon: 'mdi-bell-alert-outline',
          component: 'customer-emails'
        }
      ];

      // isPrimaryAccountHolder from mixin.js
      if (!this.can('CLIENT.PRIMARY_ACCOUNT_HOLDER')) {
        // TODO: hide tabs not applicable to sub-users
        const filter = ['user-management-settings', 'password-settings'];
        tabs = tabs.filter((tab) => {
          return filter.indexOf(tab.component) === -1;
        });
      }

      return tabs;
    }
  }
};
</script>

<style scoped>
.settings-tab {
  font-size: 13px;
  font-weight: 700;
}

div.my-tabs [role='tab'] {
  justify-content: flex-start;
  padding-left: 25px;
  padding-right: 25px;
}

.settings-card {
  padding: 25px !important;
  border-left: 1px solid #e7e7e7;
  border-radius: 0 !important;
}

h4 {
  margin-bottom: 0px !important;
}

.v-application p {
  margin-bottom: 0px !important;
}

.v-application ul {
  padding-left: 0px !important;
}

input,
select {
  width: 100%;
}
</style>
