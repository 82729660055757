<template>
  <v-row dense>
    <v-col cols="12">
      <v-form>
        <v-container class="pa-0">
          <v-switch v-model="date_switch">
            <template #label>
              <span v-if="date_switch">By Month</span>
              <span v-else>Date Range</span>
            </template>
          </v-switch>

          <v-layout
            v-if="date_switch"
            wrap
          >
            <v-flex xs6>
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template #activator="{ on }">
                  <v-text-field
                    v-model="year_month"
                    label="Month"
                    prepend-inner-icon="event"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="year_month"
                  type="month"
                />
              </v-menu>
            </v-flex>
          </v-layout>

          <v-layout
            v-else
            wrap
          >
            <v-flex xs6>
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template #activator="{ on }">
                  <v-text-field
                    v-model="start_date"
                    label="Start Date"
                    prepend-inner-icon="event"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="start_date"
                  @input="menu1 = false"
                />
              </v-menu>
            </v-flex>
            <v-flex xs6>
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template #activator="{ on }">
                  <v-text-field
                    v-model="end_date"
                    label="End Date"
                    prepend-inner-icon="event"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="end_date"
                  @input="menu2 = false"
                />
              </v-menu>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-col>

    <v-col cols="12">
      <v-card
        :loading="commissions.loading"
        outlined
      >
        <v-card-title>
          Commissions (Created At)
        </v-card-title>
        <v-card-text>
          Total Shipments: {{ commissions.quantity }}
          <br>
          Total Earned: {{ commissions.earned | currency }}
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-card
        :loading="commissions.loading"
        outlined
      >
        <v-card-title> Commissions - By Day </v-card-title>
        <v-card-text>
          <line-chart
            :height="200"
            :chart-data="commissions.byDay"
          />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    commissionStructureId: {
      type: [Number, null, undefined],
      default: null
    }
  },
  data() {
    return {
      date_switch: true,
      start_date: moment(this.year_month)
        .startOf('month')
        .format('YYYY-MM-DD'),
      end_date: moment(this.year_month)
        .endOf('month')
        .format('YYYY-MM-DD'),
      year_month: moment().format('YYYY-MM'),
      menu1: null,
      menu2: null,

      commissions: {
        byDay: null,
        loading: false,
        quantity: 0,
        postage_fee: 0
      },
      cancelSource: []
    };
  },

  watch: {
    start_date: function() {
      this.update();
    },
    end_date: function() {
      this.update();
    },
    year_month: function() {
      this.start_date = moment(this.year_month)
        .startOf('month')
        .format('YYYY-MM-DD');
      this.end_date = moment(this.year_month)
        .endOf('month')
        .format('YYYY-MM-DD');

      this.update();
    },
    date_switch: function(val) {
      if (val) {
        this.start_date = moment(this.year_month)
          .startOf('month')
          .format('YYYY-MM-DD');
        this.end_date = moment(this.year_month)
          .endOf('month')
          .format('YYYY-MM-DD');

        this.update();
      }

      this.update();
    }
  },

  mounted() {
    this.update();
  },

  methods: {
    update() {
      this.getcommissions();
    },

    getcommissions() {
      this.commissions.loading = true;

      if (this.cancelSource['commissions']) {
        this.cancelSource['commissions'].cancel(
          'Start new search, stop active search'
        );
      }

      this.cancelSource['commissions'] = axios.CancelToken.source();
      this.$http
        .get(
          `/admin/analytics-commission?start_date=${this.start_date}&end_date=${this.end_date}&commission_structure_id=${this.commissionStructureId}`,
          {
            cancelToken: this.cancelSource['commissions'].token
          }
        )
        .then(response => response.data)
        .then(response => {
          // console.log(response)
          this.commissions.earned = response.totals.earned;
          this.commissions.quantity = response.totals.quantity;

          this.commissions.byDay = {
            labels: response.by_date.map(a => a.date),
            datasets: [
              {
                label: 'Commission',
                borderColor: '#9ccc65',
                data: response.by_date.map(a => a.earned)
              }
            ]
          };
        })
        .finally(() => {
          this.commissions.loading = false;
        });
    },
  }
};
</script>
