import Vue from 'vue';

import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

import bigcommerce from './icons/bigcommerce.vue';

import bigcommerce_logo from './icons/bigcommerce-logo.vue';
import amazon_logo from './icons/amazon-logo.vue';
import etsy_logo from './icons/etsy-logo.vue';
import ebay_logo from './icons/ebay-logo.vue';
import shopify_logo from './icons/shopify-logo.vue';
import shipstation_logo from './icons/shipstation-logo.vue';
import woocommerce_logo from './icons/woocommerce-logo.vue';
import woocommerce from './icons/woocommerce.vue';
import landmark_global from './icons/landmark-global.vue';
import magento_logo from './icons/magento-logo.vue';
import walmart_logo_us from './icons/walmart-logo-us.vue';
import walmart_logo_ca from './icons/walmart-logo-ca.vue';
import walmart from './icons/walmart.vue';
import bandcamp_logo from './icons/bandcamp-logo.vue';

export default new Vuetify({
  icons: {
    values: {
      bigcommerce: {
        component: bigcommerce
      },
      'bigcommerce-logo': {
        component: bigcommerce_logo
      },
      'amazon-logo': {
        component: amazon_logo
      },
      'etsy-logo': {
        component: etsy_logo
      },
      'ebay-logo': {
        component: ebay_logo
      },
      'shopify-logo': {
        component: shopify_logo
      },
      'shipstation-logo': {
        component: shipstation_logo
      },
      'woocommerce-logo': {
        component: woocommerce_logo
      },
      woocommerce: {
        component: woocommerce
      },
      'landmark-global': {
        component: landmark_global
      },
      'magento-logo': {
        component: magento_logo
      },
      'walmart-logo-us': {
        component: walmart_logo_us
      },
      'walmart-logo-ca': {
        component: walmart_logo_ca
      },
      'walmart': {
        component: walmart
      },
      'bandcamp-logo': {
        component: bandcamp_logo
      },
    }
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#f9ac40',
        accent: '#f9ac40'
      }
    }
  }
});
