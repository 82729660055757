<template>
  <v-row
    v-if="$auth.user"
    dense
  >
    <v-col
      cols="12"
      class="mb-3"
    >
      <h4>ID: {{ $auth.user.id }}</h4>
    </v-col>

    <v-col
      cols="12"
      md="6"
    >
      <v-text-field
        v-model.trim="$auth.user.email"
        :dense="dense"
        outlined
        label="Email"
        readonly
        hint="Contact us to change your email address"
      />
    </v-col>

    <v-col
      cols="12"
      md="6"
    >
      <v-text-field
        v-if="$auth.user.email != $auth.user.username"
        v-model.trim="$auth.user.username"
        :dense="dense"
        outlined
        label="Username"
        readonly
        hint="Contact us to change your username"
      />
    </v-col>

    <v-col
      cols="12"
      md="6"
    >
      <v-text-field
        v-model.trim="$auth.user.first_name"
        v-validate="'required'"
        :dense="dense"
        :error-messages="errs.collect('first_name')"
        data-vv-name="first_name"
        outlined
        label="First Name"
      />
    </v-col>

    <v-col
      cols="12"
      md="6"
    >
      <v-text-field
        v-model.trim="$auth.user.last_name"
        v-validate="'required'"
        :dense="dense"
        :error-messages="errs.collect('last_name')"
        data-vv-name="last_name"
        outlined
        label="Last Name"
      />
    </v-col>

    <v-col
      cols="12"
      md="6"
    >
      <v-text-field
        v-model.trim="$auth.user.business_name"
        :dense="dense"
        outlined
        label="Business Name"
        disabled
      />
    </v-col>

    <v-col
      cols="12"
      md="6"
    >
      <v-text-field
        v-model.trim="$auth.user.address1"
        v-validate="'required'"
        :dense="dense"
        :error-messages="errs.collect('address1')"
        data-vv-name="address1"
        outlined
        label="Address1"
      />
    </v-col>

    <v-col
      cols="12"
      md="6"
    >
      <v-text-field
        v-model.trim="$auth.user.address2"
        :dense="dense"
        outlined
        label="Address2"
      />
    </v-col>

    <v-col
      cols="12"
      md="6"
    >
      <v-text-field
        v-model.trim="$auth.user.city"
        v-validate="'required'"
        :dense="dense"
        :error-messages="errs.collect('city')"
        data-vv-name="city"
        outlined
        label="City"
      />
    </v-col>

    <v-col
      cols="12"
      md="6"
    >
      <v-autocomplete
        v-model="$auth.user.province_code"
        v-validate="'required'"
        :dense="dense"
        :error-messages="errs.collect('province_code')"
        data-vv-name="province_code"
        :items="provinces"
        label="Province"
        item-text="name"
        item-value="code"
        outlined
      />
    </v-col>

    <v-col
      cols="12"
      md="6"
    >
      <v-text-field
        v-model.trim="$auth.user.postal_code"
        v-validate="{
          required: true,
          regex: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/
        }"
        :dense="dense"
        :error-messages="errs.collect('postal_code')"
        data-vv-name="postal_code"
        outlined
        label="Postal Code"
      />
    </v-col>

    <v-col
      cols="12"
      md="6"
    >
      <v-autocomplete
        v-model="$auth.user.country_code"
        v-validate="'required'"
        :dense="dense"
        :error-messages="errs.collect('country_code')"
        data-vv-name="country_code"
        :items="countries"
        label="Country"
        item-text="name"
        item-value="code"
        outlined
      />
    </v-col>

    <v-col
      cols="12"
      md="6"
    >
      <v-text-field
        v-model.trim="$auth.user.phone"
        v-validate="'required'"
        :dense="dense"
        :error-messages="errs.collect('phone')"
        data-vv-name="phone"
        outlined
        label="Phone"
      />
    </v-col>

    <v-col
      cols="12"
      class="mb-3"
    >
      <h3>Contact Details</h3>
    </v-col>

    <v-col
      cols="12"
      md="6"
    >
      <v-text-field
        v-model.trim="$auth.user.contact_phone"
        v-validate="''"
        :dense="dense"
        :error-messages="errs.collect('contact_phone')"
        data-vv-name="contact_phone"
        outlined
        label="Phone"
      />
    </v-col>

    <v-col
      cols="12"
      md="6"
    >
      <v-text-field
        v-model.trim="$auth.user.contact_email"
        v-validate="'email'"
        :dense="dense"
        :error-messages="errs.collect('contact_email')"
        data-vv-name="contact_email"
        outlined
        label="Email"
      />
    </v-col>

    <v-col
      cols="12"
      class="mb-3"
    >
      <h3>Timezone</h3>
    </v-col>

    <v-col
      cols="12"
      md="6"  
    >
      <v-select
        v-model="$auth.user.timezone"
        v-validate="'required'"
        :dense="dense"
        :error-messages="errs.collect('timezone')"
        data-vv-name="timezone"
        :items="timezones"
        label="Timezone"
        outlined
      />
    </v-col>


    <v-col cols="12">
      <v-btn
        color="primary"
        :loading="processing"
        @click="submit()"
      >
        Update
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import constants from '@/constants';
export default {
  metaInfo: {
    title: "Settings | Stallion Express"
  },
  data() {
    return {
      dense: true,
      processing: false,
      provinces: constants.PROVINCES,
      countries: [
        {
          name: 'Canada',
          code: 'CA'
        }
      ],
      timezones: [
        { text: 'Eastern Time - GMT-5 (Toronto)', value: 'America/Toronto' },
        { text: 'Central Time - GMT-6 (Winnipeg)', value: 'America/Winnipeg' },
        { text: 'Mountain Time - GMT-7 (Edmonton)', value: 'America/Edmonton' },
        { text: 'Pacific Time - GMT-8 (Vancouver)', value: 'America/Vancouver' },
        { text: 'Atlantic Time - GMT-4 (Halifax)', value: 'America/Halifax' },
        { text: 'Newfoundland Time - GMT-3:30 (St Johns)', value: 'America/St_Johns' }
      ],
    };
  },
  methods: {
    submit() {
      this.$validator.validate().then((result) => {
        if (result) {
          this.processing = true;

          this.$http
            .post('/users/accountSettings', this.$auth.user)
            .then((res) => {
              if (Object.prototype.hasOwnProperty.call(res.data, 'message')) {
                if (res.data.success) {
                  swal({
                    title: 'Success',
                    text: 'Your account changes have been saved',
                    icon: 'success'
                  });

                  if (res.data.user && res.data.user.phone_verified === false) {
                    this.$router.push('/verify-phone');
                  }
                } else {
                  // /var erros =
                  swal('Error!', res.data);
                }
              }
            })
            .finally(() => {
              this.processing = false;
            });
        } else {
          swal({
            title: 'Error',
            text: 'Some of the form fields are invalid.',
            icon: 'error'
          });
        }
      });
    }
  }
};
</script>
