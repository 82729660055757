<template>
  <div>
    <v-layout wrap>
      <v-flex xs12>
        <v-card
          class="elevation-0"
          style="border: 1px solid #e7e7e7"
        >
          <div>
            <v-card-title>
              <v-spacer />
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
                @input="submitSearch()"
              />
            </v-card-title>
          </div>

          <v-data-table
            :headers="headers"
            :loading="loading"
            item-key="id"
            :items="items"
            :options.sync="options"
            class="table-striped"
            :server-items-length="totalItems"
            :footer-props="{
              'items-per-page-options': [50, 100, 200, 500]
            }"
            :items-per-page="25"
          >
            <template #item.shipments="{ item }">
              {{ item.shipments.length }}
            </template>

            <template #item.open="{ item }">
              <span v-if="item.open">Open</span>
              <span v-else>Closed</span>
            </template>

            <template #item.created_at="{ item }">
              {{ item.created_at | datetime_short }}
              <br>
              ({{ item.created_at | datetime_from }})
            </template>

            <template #item.process_start_at="{ item }">
              <span v-if="item.processing">
                {{ item.process_start_at | datetime_short }}
                <br>
                ({{ item.process_start_at | datetime_from }})
              </span>
            </template>

            <template #item.actions="{ item }">
              <v-btn
                v-if="item.processing"
                small
                color="primary"
                text
                @click="stopProcessing(item)"
              >
                Stop Process
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>

export default {
  props: {},

  data() {
    return {
      search: null,

      headers: [
        {
          text: 'Batch',
          align: 'left',
          value: 'id'
        },

        {
          text: 'Name',
          align: 'left',
          value: 'name'
        },
        {
          text: 'Status',
          value: 'open',
          sortable: true
        },
        {
          text: 'Shipments',
          value: 'shipments_count',
          sortable: true
        },
        {
          text: 'Created',
          value: 'created_at'
        },
        {
          text: 'Processing Since',
          value: 'process_start_at'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ],

      options: {
        sortBy: ['created_at'],
        sortDesc: [true]
      },
      loading: false,
      totalItems: 0,
      items: [],

      timer: null,
      cancelSource: null,
    };
  },

  watch: {
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    },
    'options.itemsPerPage': function (newVal, oldVal) {
      if (oldVal && newVal) {
        const preference = {
          table_rows: {
            batches: newVal
          }
        };
        this.$store.dispatch('main/setPreferences', preference);
      }
    }
  },

  mounted() {
    const table_rows = this.$store.getters['main/table_rows'];
    this.options.itemsPerPage = table_rows?.batches ?? 5;
  },

  methods: {
    async stopProcessing(batch) {
      const isConfirm = await swal({
        title: 'Confirm Action',
        text: 'Do you really want to stop the process of this batch?',
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      });

      if (isConfirm) {
        try {
          await axios.request(`/admin/batches/${batch.id}`, {
            method: 'post'
          });
          this.updateTable();

          this.successMessage('Batch process successfully stopped.')
        } catch (err) {
          let errMsg = 'Unknown Error Occured!'
          if (err.response.status === 422 && err.response.data.error) {
            errMsg = err.response.data.error
          }

          this.errorMessage(errMsg);
        }
      }
    },

    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.options.page = 1;
      this.timer = setTimeout(function () {
        self.updateTable();
      }, ms);
    },
    updateTable() {
      this.totalItems = 0;
      this.items = [];

      this.getDataFromApi().then((data) => {
        this.items = data.items;
        this.totalItems = data.total;
      });
    },
    getDataFromApi() {
      this.loading = true;
      return new Promise((resolve) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let items = [];
        let total = 0;
        let pages = 0;

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        var params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sortBy[0],
          desc: sortDesc[0],
          search: this.search,
        };

        this.$http
          .request(`/admin/batches`, {
            method: 'get',
            params: params,
            cancelToken: this.cancelSource.token
          })
          .then((response) => response.data)
          .then((response) => {
            items = response.data;
            total = response.total;
            pages = response.last_page;

            this.loading = false;
            resolve({
              items,
              total,
              pages
            });
          });
      });
    }
  }
};
</script>
