<template>
  <FullPageLayout title="Announcements">
    <template #default>
      <announcement-table />
    </template>
  </FullPageLayout>
</template>
    
<script>
import AnnouncementTable from './AnnouncementTable.vue'
export default {
  components: {
    AnnouncementTable
  }
};
</script>