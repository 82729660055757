<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-btn
          color="primary"
          dark
          @click="
            $refs.ruleDialog.startNew({
              user_id: user_id
            })
          "
        >
          Create Rule
        </v-btn>
      </v-col>

      <v-col cols="12">
        <p>
          These rules will be run in sequence. You can reorder them by dragging
          them to the desired location. The last applicable rule will be used
          for pricing.
        </p>

        <v-data-table
          class="table-striped"
          :headers="headers"
          :items="items"
          :loading="loading"
          :footer-props="{
            'items-per-page-options': [25, 50, 100]
          }"
          :items-per-page="25"
        >
          <template
            v-if="items.length > 0"
            #body="props"
          >
            <draggable
              v-if="props.items.length > 0"
              v-bind="dragOptions"
              :list="items"
              tag="tbody"
              @change="updateOrder"
            >
              <transition-group
                v-for="(item, index) in props.items"
                :key="index"
                tag="tr"
                type="transition"
                name="flip-list"
              >
                <td :key="`${index}user_id`">
                  {{ item.user.id }} -
                  {{ item.user.business_name || item.user.name }}
                </td>
                <td :key="`${index}name`">
                  {{ item.name }}
                </td>
                <td :key="`${index}criteria`">
                  <div v-if="item.filter">
                    <div
                      v-for="filter_criteria in item.filter.filter_criteria"
                      :key="filter_criteria.id"
                    >
                      <strong>{{ filter_criteria.field | humanize }}:</strong>
                      {{ filter_criteria.comparator | humanize }}

                      <ul v-if="filter_criteria.field == 'postage_type'">
                        <li v-if="!!!filter_criteria.value">
                          NULL
                        </li>

                        <li
                          v-for="i in filter_criteria.value.split(';')"
                          v-else
                          :key="i"
                          v-html="$options.filters.postage(i)"
                        />
                      </ul>
                      <ul v-else-if="filter_criteria.field == 'package_type'">
                        <li v-if="!!!filter_criteria.value">
                          NULL
                        </li>

                        <li
                          v-for="i in filter_criteria.value.split(';')"
                          v-else
                          :key="i"
                          v-html="$options.filters.package_type(i)"
                        />
                      </ul>
                      <ul v-else-if="filter_criteria.field == 'country_code'">
                        <li v-if="!!!filter_criteria.value">
                          NULL
                        </li>

                        <li
                          v-for="i in filter_criteria.value.split(';')"
                          v-else
                          :key="i"
                        >
                          {{ i | humanize }}
                        </li>
                      </ul>
                      <span v-else>
                        {{ filter_criteria.value | humanize }}
                      </span>
                    </div>
                  </div>
                  <div v-else>
                    Apply to All
                  </div>
                </td>
                <td :key="`${index}actions`">
                  <div
                    v-for="rule in item.rule_actions"
                    :key="rule.id"
                  >
                    <div v-if="rule.type == 'discount_amount'">
                      <strong>{{ rule.type | humanize }}:</strong>
                      {{ rule.value | currency }}
                    </div>
                    <div v-else-if="rule.type == 'discount_percent' || rule.type == 'markup_percent'">
                      <strong>{{ rule.type | humanize }}:</strong>
                      {{ rule.value }}%
                    </div>
                    <div v-else-if="rule.type == 'fixed_amount'">
                      <strong>{{ rule.type | humanize }}:</strong>
                      {{ rule.value | currency }}
                    </div>
                    <div v-else>
                      <strong>{{ rule.type | humanize }}:</strong>
                      {{ rule.value }}
                    </div>
                  </div>
                </td>
                <td :key="`${index}updated`">
                  {{ item.updated_at | date_pretty }}
                  <br>
                  ({{ item.updated_at | datetime_from }})
                </td>
                <td :key="`${index}created`">
                  {{ item.created_at | date_pretty }}
                  <br>
                  ({{ item.created_at | datetime_from }})
                </td>
                <td :key="`${index}active`">
                  <v-icon v-if="item.active">
                    mdi-check-circle-outline
                  </v-icon>
                  <v-icon v-else>
                    mdi-close
                  </v-icon>
                </td>
                <td
                  :key="`${index}del`"
                  class="text-right"
                >
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        small
                        icon
                        text
                        v-bind="attrs"
                        @click="$refs.ruleDialog.duplicate(item)"
                        v-on="on"
                      >
                        <v-icon>mdi-content-duplicate</v-icon>
                      </v-btn>
                    </template>
                    <span>Duplicate</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        small
                        text
                        v-bind="attrs"
                        icon
                        @click="deleteRule(item.id)"
                        v-on="on"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        small
                        text
                        v-bind="attrs"
                        icon
                        @click="$refs.ruleDialog.startEdit(item)"
                        v-on="on"
                      >
                        <v-icon> mdi-square-edit-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                </td>
              </transition-group>
            </draggable>
            <td v-else>
              No data available
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <rule-dialog
      ref="ruleDialog"
      @update-rules="updateTable()"
    />
  </div>
</template>
<script>
import draggable from 'vuedraggable';
import RuleDialog from './RuleDialog.vue';
export default {
  components: {
    RuleDialog,
    draggable
  },
  props: {
    userId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      items: [],
      dragOptions: {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      },
      options: {},
      headers: [
        { text: 'User', value: 'user_id', sortable: false },
        { text: 'Rule Name', value: 'name', sortable: false },
        { text: 'Criteria', value: 'criteria', sortable: false },
        { text: 'Actions', value: 'number_actions', sortable: false },
        { text: 'Updated At', value: 'updated_at', sortable: false },
        { text: 'Created At', value: 'created_at', sortable: false },
        { text: 'Active', value: 'active', sortable: false },
        { text: 'Actions', value: 'actions', align: 'right', sortable: false }
      ]
    };
  },
  watch: {
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    },
    'options.itemsPerPage': function (newVal, oldVal) {
      if (oldVal && newVal) {
        const preference = {
          table_rows: {
            automation: newVal
          }
        };
        this.$store.dispatch('main/setPreferences', preference);
      }
    }
  },

  mounted() {
    this.updateTable();
    Event.listen('update-rules', () => {
      this.updateTable();
    });

    const table_rows = this.$store.getters['main/table_rows'];
    this.options.itemsPerPage = table_rows?.automation ?? 25;
  },
  methods: {
    deleteRule(id) {
      let self = this;
      swal({
        title: 'Confirm Delete',
        text: 'Do you really want to delete this filter?',
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      }).then(function (isConfirm) {
        if (isConfirm) {
          self.$http
            .request(`/admin/pricing-rules/${id}`, {
              method: 'delete'
            })
            .then((response) => response.data)
            .then(() => {
              self.updateTable();
            });
        }
      });
    },
    updateTable() {
      this.items = [];
      this.loading = true;

      var params = {
        user_id: this.userId
      };

      this.$http
        .request(`/admin/pricing-rules`, {
          method: 'get',
          params: params
        })
        .then((response) => response.data)
        .then((response) => {
          this.items = response;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    updateOrder() {
      this.$http
        .request(`/admin/pricing-rules/order`, {
          method: 'post',
          data: {
            rules: this.items
          }
        })
        .then((response) => response.data)
        .then(() => {
          // this.updateTable();
        });
    }
  }
};
</script>

<style>
.button {
  margin-top: 35px;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>
