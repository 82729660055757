// AUTH
const Login = () => import('../pages/auth/login.vue');
const Register = () => import('../pages/auth/register.vue');
const Reference = () => import('../pages/auth/set-reference.vue');
const ForgotPassword = () => import('../pages/auth/forgot-password.vue');
const PasswordReset = () => import('../pages/auth/password-reset.vue');
const TwoFactorAuth = () => import('../pages/auth/two-factor-setup.vue');
const VerifyEmail = () => import('../pages/auth/verify-email.vue');
const SendVerification = () => import('../pages/auth/send-verification.vue');
const AccountInformation = () => import('../pages/auth/account-setup.vue');
const Locked = () => import('../pages/auth/locked.vue');
const ApprovalStatus = () => import('../pages/auth/approval-status.vue');
const UpsAgree = () => import('../pages/auth/terms-and-conditions.vue');
const VerifyPhone = () => import('../pages/auth/verify-phone.vue');

const routes = [
  {
    path: '/verify-phone',
    component: VerifyPhone,
    meta: {
      conditionalRoute: true,
      reg: true,
      auth: true,
      layout: 'auth-layout'
    }
  },
  {
    path: '/locked',
    component: Locked,
    meta: {
      conditionalRoute: true,
      reg: true,
      auth: true,
      layout: 'auth-layout'
    }
  },
  {
    path: '/approval-status',
    component: ApprovalStatus,
    meta: {
      conditionalRoute: true,
      reg: true,
      auth: true,
      layout: 'auth-layout'
    }
  },
  {
    path: '/verify/:code?',
    component: VerifyEmail,
    meta: {
      conditionalRoute: true,
      reg: true,
      layout: 'auth-layout'
    }
  },
  {
    path: '/send-verification',
    component: SendVerification,
    meta: {
      conditionalRoute: true,
      reg: true,
      layout: 'auth-layout'
    }
  },

  {
    path: '/two-factor-auth',
    name: 'two-factor-setup',
    component: TwoFactorAuth,
    meta: {
      layout: 'auth-layout'
    }
  },

  {
    path: '/account-setup',
    component: AccountInformation,
    meta: {
      auth: true,
      reg: true,
      layout: 'auth-layout'
    }
  },

  {
    path: '/reference/:id',
    name: 'reference',
    component: Reference,
    meta: {
      layout: 'auth-layout'
    }
  },

  {
    path: '/register/:id?',
    name: 'register',
    component: Register,
    meta: {
      layout: 'auth-layout'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      layout: 'auth-layout'
    }
  },
  {
    path: '/shopify-login',
    name: 'shopify-login',
    component: Login,
    meta: {
      layout: 'auth-layout'
    }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
    meta: {
      layout: 'auth-layout'
    }
  },
  {
    path: '/password-reset',
    name: 'password-reset',
    component: PasswordReset,
    meta: {
      layout: 'auth-layout'
    }
  },
  {
    path: '/terms-and-conditions',
    name: 'terms-and-conditions',
    component: UpsAgree,
    meta: {
      layout: 'auth-layout'
    }
  }
];

export default routes;
