<template>
  <v-flex
    v-if="
      shipment.tax_id
    "
    xs12
  >
    <h4 v-if="showHeader">
      Customs
    </h4>

    <div class="d-flex flex-row justify-space-between">
      <div class="shipment-title">
        Tax Identifier
      </div>
      <div>{{ shipment.tax_identifier?.number }}</div>
    </div>

    <v-divider
      v-if="showDivider"
      class="details-divider"
    />
  </v-flex>
</template>

<script>
export default {
  props: {
    shipment: {
      type: Object,
      default: null
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    showDivider: {
      type: Boolean,
      default: true
    }
  }
};
</script>