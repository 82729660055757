<template>
  <StallionDialog
    v-model="dialog"
    persistent
    :width="800"
    :title="'Create Container'"
  >
    <template #content>
      <v-form @submit.prevent>
        <v-container>
          <v-row dense>
            <v-col
            
              cols="12"
            >
              <v-select
                v-model="container.type"
                v-validate="'required'"
                outlined
                dense
                :items="['Bag', 'Gaylord', 'Trailer']"
               
                :error-messages="errs.collect('type')"
                label="Type"
                data-vv-name="type"
                required
              />
            </v-col>
            <v-col
              v-if="container.type != 'Trailer'"
              cols="12"
            >
              <v-select
                v-model="container.carrier"
                v-validate="'required'"
                outlined
                dense
                :items="carriers"
   
                :error-messages="errs.collect('carrier')"
                label="Carrier"
                data-vv-name="carrier"
                required
              />
            </v-col>
      

            <v-col
              v-if="container.type == 'Trailer'"
              cols="12"
            >
              <v-text-field
                v-model="container.name"
                v-validate="'required'"
                outlined
                dense
                :error-messages="errs.collect('name')"
                label="Name"
                data-vv-name="name"
                required
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>

    <template #actions>
      <v-spacer />
      <v-btn
        v-if="edit"
        color="primary"
        :loading="submitting"
        @click="updateContainer()"
      >
        Update
      </v-btn>
      <v-btn
        v-else
        color="primary"
        :loading="submitting"
        @click="saveContainer()"
      >
        Create
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  // Component properties
  props: {
    carriers: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      container: this.getDefaultContainer(),
      edit: false,
      dialog: false,
      submitting: false,
      sortPlan: [],
      bins: [],
    };
   
  },
  computed: {
    name() {
      if(this.container.type == 'Trailer'){
        return 'Trailer';
      }
      else if(this.container.carrier){
        return `${this.container.carrier} ${this.container.type}`;
      }
      else{
        return `${this.container.type}`;
      }
    }
  },
  watch: {
    'container.carrier': function () {
      this.container.name = this.name
    },
    'container.type': function () {
      this.container.name = this.name
    },
  },
  mounted() {
    // Add event listener when the component is mounted
    window.addEventListener('keyup', this.handleGlobalKeyUp);
  },
  beforeDestroy() {
    // Remove event listener before the component is destroyed
    window.removeEventListener('keyup', this.handleGlobalKeyUp);
  },
  methods: {
    handleGlobalKeyUp(event) {
      if(this.dialog == false){
        return;
      }
      
      if (event.key === 'Enter') {
        alert('Enter key was pressed');
        // Your logic here
      }
    },
    getDefaultContainer() {
      return  {
        name: null,
        type: null,
        output: 0,
        carrier: null,
      };
    },
    initDialog() {

      this.dialog = true;
      this.submitting = false;
      this.$validator.reset();
    },
    closeDialog() {
      this.dialog = false;
      this.$emit('update-containers');
    },
    startNew() {
      this.initDialog();
      this.container = { ...this.getDefaultContainer() };
      this.edit = false;
    },
    startEdit(container) {
      this.initDialog();
      this.container = { ...container };
      this.edit = true;
    },
    async validateContainer() {
      return this.$validator.validate().then((result) => {
        return result ? true : false;
      });
    },
    async saveContainer() {
      if (!(await this.validateContainer())) return;
      this.submitting = true;
      this.$http
        .post(`/admin/containers`, {containers: [this.container]})
        .then((data) => {
          this.printUrl(data.data.url, true);
          this.closeDialog();
        })
        .catch((err) => {
          this.onError(err);
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    async updateContainer() {
      if (!(await this.validateContainer())) return;

      this.submitting = true;
      this.$http
        .put(`/boxes/${this.container.id}`, {containers: [this.container]})
        .then(() => {
          this.closeDialog();
          this.successMessage('Container successfully updated!');
        })
        .catch((err) => {
          this.onError(err);
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    onError(err) {
      if (
        err.response.status === 422 &&
        typeof err.response.data.errors !== 'undefined'
      ) {
        const errors = err.response.data.errors;
        let message = '';

        for (var field in errors) {
          message += `${errors[field][0]}\n`;
        }

        this.errorMessage(message);
      }
    }
  }
};
</script>
