<template>
  <div>
    <v-layout wrap>
      <v-flex xs12>
        <v-form v-if="!condensed">
          <v-container class="pa-0">
            <!-- Search and Date -->
            <v-row
              class="py-4 px-2"
              style="justify-content: space-between"
            >
              <v-col
                cols="12"
                md="4"
                style="padding: 5px 5px"
              >
                <v-text-field
                  v-model="search"
                  pt-0
                  label="Search"
                  prepend-inner-icon="search"
                  outlined
                  clearable
                  dense
                  hide-details
                  data-lpignore="true"
                  @input="submitSearch()"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
                style="padding: 5px 5px"
              >
                <date-picker
                  v-model="start_date"
                  label="Start Date"
                  @change="updateTable()"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
                style="padding: 5px 5px"
              >
                <date-picker
                  v-model="end_date"
                  label="End Date"
                  @change="updateTable()"
                />
              </v-col>
            </v-row>

            <!-- Search by Status -->
            <v-row class="pa-0 px-3">
              <v-col
                cols="12"
                md="12"
                class="py-0 px-0"
              >
                <p class="font-weight-bold">
                  Search By Status
                </p>
              </v-col>
              <v-col
                cols="12"
                md="12"
                class="d-flex flex-wrap px-0"
              >
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-card
                      class="state-button"
                      outlined
                      hover
                      @click="status_id = null"
                      v-on="on"
                    >
                      <v-icon
                        small
                        left
                      >
                        mdi-format-list-bulleted
                      </v-icon>
                      All
                    </v-card>
                  </template>
                  <span>See All Supplies Orders</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-card
                      class="state-button"
                      outlined
                      hover
                      @click="status_id = 6"
                      v-on="on"
                    >
                      <v-icon
                        small
                        left
                      >
                        mdi-clipboard-outline
                      </v-icon>
                      Pending
                    </v-card>
                  </template>
                  <span>See Unfulfilled Supplies Orders</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-card
                      class="state-button"
                      outlined
                      hover
                      @click="status_id = 4"
                      v-on="on"
                    >
                      <v-icon
                        small
                        left
                      >
                        mdi-clipboard-check-outline
                      </v-icon>
                      In-Transit
                    </v-card>
                  </template>
                  <span>See Fulfilled Supplies Orders</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-card
                      class="state-button"
                      outlined
                      hover
                      @click="status_id = 5"
                      v-on="on"
                    >
                      <v-icon
                        small
                        left
                      >
                        mdi-clipboard-check-outline
                      </v-icon>
                      Ready
                    </v-card>
                  </template>
                  <span>See Fulfilled Supplies Orders</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-card
                      class="state-button"
                      outlined
                      hover
                      @click="status_id = 2"
                      v-on="on"
                    >
                      <v-icon
                        small
                        left
                      >
                        mdi-clipboard-check-outline
                      </v-icon>
                      Complete
                    </v-card>
                  </template>
                  <span>See Fulfilled Supplies Orders</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-card
                      class="state-button"
                      outlined
                      hover
                      @click="status_id = 3"
                      v-on="on"
                    >
                      <v-icon
                        small
                        left
                      >
                        mdi-select-off
                      </v-icon>
                      Voided
                    </v-card>
                  </template>
                  <span>See Voided Supplies Orders</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-container>
        </v-form>

        <v-card
          class="elevation-0"
          style="border: 1px solid #e7e7e7"
        >
          <div v-if="condensed">
            <v-card-title>
              <span class="text-h3">Order Items</span>
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
                @input="submitSearch()"
              />
            </v-card-title>
          </div>
          <v-data-table
            v-model="selected"
            class="table-striped"
            :headers="headers"
            item-key="id"
            :loading="loading"
            show-select
            :items="items"
            :options.sync="options"
            :server-items-length="totalItems"
            :footer-props="{
              'items-per-page-options': [
                50, 100, 200, 500
              ]
            }"
            :items-per-page="25"
            @click:row="rowClick"
          >
            <template #item.created_at="{ item }">
              {{ item.created_at | date_pretty }}
              <br>
              ({{ item.created_at | datetime_from }})
            </template>
            <template #item.quantity="{ item }">
              {{ item.items.length }}
            </template>

            <template #item.status_id="{ item }">
              <span>
                {{ item.status.description }}
              </span>
            </template>
            <template #item.total="{ item }">
              {{ item.total | currency }}
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: {
    admin: {
      default: false,
      type: Boolean
    },
    invoiceId: {
      type: String,
      default: null
    },
    filter: {
      default: null,
      type: String
    },
    condensed: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      selected: [],
      options: {
        page: 1,
        sortBy: ['created_at'],
        sortDesc: [true]
      },
      loading: false,

      headers: [
        {
          text: 'Invoice ID',
          value: 'invoice_number'
        },
        {
          text: 'Status',
          value: 'status_id'
        },
        {
          text: '# Line Items',
          value: 'quantity',
          sortable: false
        },
        {
          text: 'Total',
          value: 'total'
        },
        {
          text: 'Created At',
          value: 'created_at'
        }
      ],

      search: null,
      start_date: null,
      end_date: null,
      menu1: null,
      menu2: null,
      timer: null,
      totalItems: 0,
      items: [],
      cancelSource: null,
      status_id: null
    };
  },
  computed: {},
  watch: {
    status_id: function () {
      this.updateTable();
    },
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    },
    selected: function () {
      this.$emit('update:selected', this.selected);
    },
    'options.itemsPerPage': function (newVal, oldVal) {
      if (oldVal && newVal) {
        const preference = {
          table_rows: {
            supplies: newVal
          }
        };
        this.$store.dispatch('main/setPreferences', preference);
      }
    }
  },
  mounted() {
    Event.listen('update-supplies-orders', () => {
      this.updateTable();
    });

    const table_rows = this.$store.getters['main/table_rows'];
    this.options.itemsPerPage = table_rows?.supplies ?? 25;
  },
  methods: {
    rowClick(item) {
      this.$router.push({
        name: 'supplies-order',
        params: {
          id: item.invoice_number
        }
      });
    },
    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.options.page = 1;
      this.timer = setTimeout(function () {
        self.updateTable();
      }, ms);
    },
    updateTable() {
      this.selected = [];
      this.totalItems = 0;
      this.items = [];

      this.getDataFromApi().then((data) => {
        this.items = data.items;
        this.totalItems = data.total;
      });
    },
    getDataFromApi() {
      this.loading = true;
      return new Promise((resolve) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let items = [];
        let total = 0;
        let pages = 0;

        var params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sortBy[0],
          desc: sortDesc[0],
          search: this.search,
          start_date: this.start_date,
          end_date: this.end_date,
          status_id: this.status_id
        };

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        this.$http
          .request(`/supplies-invoices`, {
            method: 'get',
            params: params,
            cancelToken: this.cancelSource.token
          })

          .then((response) => response.data)
          .then((response) => {
            items = response.data;
            total = response.total;
            pages = response.last_page;

            resolve({
              items,
              total,
              pages
            });
          })
          .finally(() => {
            this.loading = false;
          });
      });
    }
  }
};
</script>

<style scoped>
.v-application p {
  margin-bottom: 0px;
}
</style>
