<template>
  <v-card
    :loading="loading"
    outlined
    :class="{ 'fill-height': fillHeight }"
  >
    <v-card-title>
      <v-icon
        v-if="icon"
        left
      >
        {{ icon }}
      </v-icon>{{ title }}
    </v-card-title>
    <v-card-text>
      <table>
        <tr
          v-for="(total, index) in data"
          :key="index"
        >
          <td>
            <strong>{{ index | humanize }}</strong>
          </td>
          <td class="pl-3 text-right">
            <span v-if="!autoFormat || index == 'quantity'">{{
              Number(total)
            }}</span>
            <span v-else>{{ Number(total) | currency }}</span>
          </td>
        </tr>
      </table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    fillHeight: {
      type: Boolean,
      default: false
    },
    autoFormat: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => null
    },
    title: {
      type: String,
      default: 'Overview'
    },
    icon: {
      type: String,
      default: null
    }
  }
};
</script>
