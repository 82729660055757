<template>
  <div>
    <StallionDialog
      v-model="show"
      :width="500"
      title="Confirm Purchase"
    >
      <template #content>
        <v-card-text v-if="batch">
          <v-row>
            <v-col cols="12">
              <div class="dialog-info">
                Are you sure you want to purchase {{ batch.validated }}
                {{ batch.validated | pluralize('shipment') }} for a total of
                {{ $options.filters.currency(batch.validated_cost) }}?
              </div>
            </v-col>
            <v-col cols="12">
              <summary-agree v-model="summaryAgree" />
            </v-col>
          </v-row>
        </v-card-text>
      </template>

      <template #actions>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
              color="primary"
              :disabled="loading || !summaryAgree"
              type="submit"
              @click="confirm()"
              v-on="on"
            >
              Confirm
            </v-btn>
          </template>
          <span>Confirm Purchase</span>
        </v-tooltip>
      </template>
    </StallionDialog>
  </div>
</template>

<script>
import SummaryAgree from '@/components/main/shipments/single-flow/summary-agree.vue';

export default {
  components: {
    SummaryAgree
  },
  props: {
    value: {
      type: Boolean,
      default: true
    },
    batch: {
      default: () => {},
      type: Object
    },
    confirmPurchaseHandler: {
      default: () => {},
      type: Function
    }
  },
  data() {
    return {
      summaryAgree: true,
      loading: false
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    show: {
      handler() {
        if (this.show) {
          this.summaryAgree = true;
        }
      }
    }
  },

  methods: {
    confirm() {
      this.close();
      this.confirmPurchaseHandler();
    },
    close() {
      this.show = false;
    }
  }
};
</script>
