<template>
  <v-combobox
    v-if="multiSelect"
    v-model="value_local"
    :search-input.sync="searchInput"
    :items="users"
    :label="label"
    :dense="dense"
    :outlined="outlined"
    multiple
    clearable
    item-text="text"
    item-value="id"
    :disabled="disabled"
  />
  <v-autocomplete
    v-else
    v-model="value_local"
    :outlined="outlined"
    :dense="dense"
    prepend-inner-icon="mdi-pound-box"
    table
    :items="users"
    :search-input.sync="searchInput"
    hide-selected
    :label="label"
    clearable
    item-text="text"
    item-value="id"
    :disabled="disabled"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: Array || Number || Object,
      default: null
    },
    label: {
      type: String,
      default: 'User'
    },
    adminOnly: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    short: {
      type: Boolean,
      default: false,
    },
    multiSelect: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchEmail: null,
      email: null,
      users: [],
      searchInput: null,
      cancelToken: null,
      searchTimeout: null,
    };
  },
  computed: {
    value_local: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
        this.$emit('change');
      }
    }
  },
  watch: {
    searchInput: function (newVal, oldVal) {
      // return if value in users
      if (this.users.find((user) => user.text === newVal)) {
        return;
      }

      console.log(newVal + ' ' + oldVal);
      if (newVal != oldVal) {
        if (this.searchTimeout) {
          clearTimeout(this.searchTimeout);
        }

        // Set a new timeout
        this.searchTimeout = setTimeout(() => {
          // this.users = [];
          this.fetchUsers(newVal);
        }, 300);
      }
    }
  },
  mounted() {
    if (this.value) {
      this.fetchUsers(this.value);
    }
  },
  methods: {
    fetchUsers(searchTerm = '') {
      if (this.cancelToken) {
        this.cancelToken.cancel('Operation canceled due to new request.');
      }

      // Create a new CancelToken
      this.cancelToken = this.$http.CancelToken.source();

      if (typeof searchTerm !== 'object') {
        this.$http
            .get('/admin/users/list', {
              params: {search: searchTerm, limit: 20, admin_only: this.adminOnly},
              cancelToken: this.cancelToken.token // Pass the token here
            })
            .then((response) => response.data)
            .then((response) => {
              this.users = [];
              for (var i = 0; i < response.length; i++) {
                if (this.short) {
                  this.users.push({
                    id: response[i].id,
                    text: `${response[i].id} - ${response[i].email}`
                  });
                } else {
                  this.users.push({
                    id: response[i].id,
                    text: `${response[i].id} - ${response[i].email} - ${response[i].business_name} - ${response[i].name}`
                  });
                }
              }
            });
      }
    }
  }
};
</script>
