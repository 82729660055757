<template>
  <StallionDialog
    v-model="show"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    title="Assign permissions to role"
  >
    <template #content>
      <v-container>
        <v-row>
          <v-container class="py-0">
            <v-alert
              v-for="(error, key1) in errors"
              :key="key1"
              dark
              color="error"
              class="login-field"
              :value="error != ''"
              icon="warning"
            >
              <div
                v-for="(description, key2) in error"
                :key="key2"
              >
                {{ description }}
              </div>
            </v-alert>
          </v-container>
        </v-row>

        <v-container
          container--fluid
          grid-list-lg
        >
          <v-layout wrap>
            <v-flex xs12>
              <v-form>
                <v-container>
                  <v-row>
                    <v-text-field
                      v-model="role.name"
                      type="text"
                      label="Role"
                      :readonly="true"
                    />
                  </v-row>
                  <v-row>
                    <v-autocomplete
                      v-model="role.permissions"
                      :items="permissions"
                      multiple
                      item-text="name"
                      item-value="name"
                      label="Permissions"
                      return-object
                      clearable
                    />
                  </v-row>
                </v-container>
              </v-form>
            </v-flex>
          </v-layout>
        </v-container>
      </v-container>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        :loading="loading"
        :disabled="loading"
        @click="save()"
      >
        Save
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    role: {
      type: Object,
      required: true
    },
    permissions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      errors: [],
      disabled: false,
      loading: false
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  watch: {},
  methods: {
    closeModal() {
      this.$emit('reloadRoles');
      this.show = null;
    },
    save() {
      this.$validator.validate().then(result => {
        if (result) {
          this.loading = true;
          this.$http
            .post(`/admin/roles-and-permissions/assignToRole`, {
              role_id: this.role.id,
              permissions: this.role.permissions
            })
            .then(response => response.data)
            .then(() => {
              this.$notify({
                group: 'main',
                title: 'Success',
                text: 'Permissions assigned sucessfully',
                duration: 5000,
                type: 'success'
              });
              this.show = false;
              // emit event from ./roles-permissions/roles-index.vue
              this.$emit('reloadRoles');
            })
            .catch(err => {
              // console.log(err.response);
              this.errors = err.response.data.errors;
              swal({
                title: 'Error',
                text: 'Some of the form fields are invalid.',
                icon: 'error'
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>
