<template>
  <FullPageLayout title="Commission Structures">
    <template #default>
      <commission-structures-table />
    </template>
  </FullPageLayout>
</template>
  
<script>
import CommissionStructuresTable from  './commission-structures-table.vue'

export default {
  components: {
    CommissionStructuresTable
  }
};
</script>
