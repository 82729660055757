<template>
  <FullPageLayout
    title="Merged Orders"
  >
    <template #subtitle>
      <p>
        View already merged orders and unmerge them
      </p>
    </template>
    <template #page-links>
      <router-link
        exact
        router
        to="/merge-orders"
      >
        Mergeable
      </router-link>
      |
      <router-link
        exact
        router
        to="/merged-orders"
      >
        Merged
      </router-link>
    </template>
    <template #default>
      <div style="padding: 15px;">
        <v-row>
          <v-col cols="12">
            <v-simple-table dense>
              <template #default>
                <thead>
                  <tr>
                    <th>Order Number</th>
                    <th>Total</th>
                    <th># of items</th>
                    <th>Total Quantity</th>
                    <th>Ready to ship</th>
                    <th>Order Date</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="totalOrders.length == 0">
                    <tr>
                      <td>No data</td>
                    </tr>
                  </template>
                  <template v-for="(orders, index) in totalOrders">
                    <tr :key="index">
                      <td
                        colspan="7"
                        style="padding: 15px;"
                      >
                        <v-row
                          align="center"
                          justify="space-between"
                        >
                          <v-col cols="auto">
                            <strong>{{ orders.length }} orders merged for {{ orders[0].name }}, {{ orders[0].address1 }}, {{ orders[0].city }}, {{ orders[0].postal_code }}, {{ orders[0].province_code }}</strong>
                          </v-col>
                          <v-col cols="auto">
                            <v-btn
                              small
                              :loading="loading"
                              color="primary"
                              depressed
                              @click="unmergeOrders(orders[0].merged_order_id)"
                            >
                              <v-icon>mdi-call-split</v-icon>Unmerge
                            </v-btn>
                          </v-col>
                        </v-row>
                      </td>
                    </tr>
                    <tr
                      v-for="(order) in orders"
                      :key="order.id"
                    >
                      <td>#{{ order.display_order_id ?? order.order_id }}</td>
                      <td>{{ order.currency == "CAD" ? 'C$' : '$' }} {{ order.value }}</td>
                      <td>{{ order.items_count }}</td>
                      <td>{{ order.total_quantity }}</td>
                      <td>
                        <v-icon color="success">
                          mdi-check
                        </v-icon>
                      </td>
                      <td>{{ order.order_at }}</td>
                    </tr>
                  </template>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </div>
    </template>
  </FullPageLayout>
</template>

<script>

export default {
    data() {
        return {
            totalOrders: [],
            loading: false
        };
    },

    mounted() {
        this.fetchOrders();
    },

    methods: {
        fetchOrders() {
            this.loading = true;

            this.$http
                .get('/orders/merged')
                .then(response => {
                    this.totalOrders = response.data.data;
                    this.loading = false;
                })
                .catch(() => {
                    this.errorMessage('Could not fetch orders. Please try again.');
                });
        },
        
        unmergeOrders(id) {
            this.loading = true;
            this.$http
                .post('/orders/unmerge', { orderID: id })
                .then(() => {
                    this.successMessage('Orders unmerged successfully');
                    this.fetchOrders();
                })
                .catch(error => {
                    if (error.response && error.response.data && error.response.data.message) {
                        this.errorMessage(error.response.data.message);
                    } else {
                        this.errorMessage('Could not unmerge orders. Please try again.');
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};

</script>