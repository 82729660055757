<template>
  <StallionDialog
    v-model="show"
    :width="500"
    :loading="loading_cost > 0"
    title="Admin Dispose Return"
  >
    <template
      v-if="show"
      #content
    >
      <v-layout
        justify-center
        align-center
        class="mt-4"
      >
        <v-flex xs8>
          <p
            style="font-size: 1.3em;"
            class="text-center"
          >
            Dispose {{ returns.length }}
            {{ returns.length | pluralize('shipment') }}
          </p>

          <h2 class="text-center mt-12 mb-12">
            {{ returns.length }} x {{ unit_cost | currency }} =
            {{ cost | currency }}
          </h2>

          <ul
            style="
              list-style-type: none;
              list-style-position: inside;
              margin: 0;
              padding: 0;
              font-size: 1.3em;
            "
          >
            <li>
              <strong>Handling Fee:</strong>
              <span class="float-right">{{ cost | currency }}</span>
            </li>
            <li>
              <strong>Tax:</strong>
              <span class="float-right">{{ tax | currency }}</span>
            </li>

            <li>
              <strong>Total:</strong>
              <span class="float-right">{{ total | currency }}</span>
            </li>
          </ul>
        </v-flex>
      </v-layout>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        :loading="loading"
        :disabled="loading"
        @click="request"
      >
        Confirm
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    returns: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      loading: false,
      loading_cost: false,
      unit_cost: null,
      cost: null,
      tax: null,
      vat: null,
      duty: null,

      total: null
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    value: function(val) {
      if (val === true) {
        this.getCost();
      }
    }
  },
  mounted() {},
  methods: {
    getCost() {
      this.loading_cost = true;
      var ids = this.returns.map(x => x.id);
      this.$http
        .post('/returns/dispose-cost', {
          ids: ids
        })
        .then(response => response.data)
        .then(response => {
          this.unit_cost = response.unit_cost;
          this.cost = response.handling_fee;
          this.tax = response.tax;
          this.vat = response.vat;
          this.duty = response.duty;
          this.total = response.total;
        })
        .catch(err => {
          const errMsg =
            err.response.data.error ||
            'We could not calculate the cost for the selected items';
          this.errorMessage(errMsg);

          this.show = false;
        })
        .finally(() => {
          this.loading_cost = false;
        });
    },
    request() {
      this.loading = true;

      var ids = this.returns.map(x => x.id);
      this.$http
        .post('/admin/returns/dispose', {
          ids: ids
        })
        .then(response => response.data)
        .then(response => {
          if (response.success == true) {
            Event.fire('update-admin-returns');
            this.show = false;
            this.returns = [];

            this.successMessage('Returns have been disposed');
          } else {
            this.errorMessage(
              'We could not process the request for the selected returns.'
            );
          }
        })
        .catch(() => {
          this.errorMessage(
            'We could not process the request for the selected returns.'
          );
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
