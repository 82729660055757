<template>
  <FullPageLayout title="Inbound Shipment">
    <template #default>
      <div class="separator mb-4" />
      <v-layout wrap>
        <v-flex
          xs12
          md6
        >
          <v-card>
            <v-container
              container--fluid
              grid-list-lg
            >
              <v-layout wrap>
                <v-flex xs12>
                  <h1>
                    <i class="mdi mdi-download" />
                    <span>Shipment List</span>
                  </h1>

                  <v-layout
                    align-center
                    class="mt-4"
                  >
                    <v-flex
                      xs12
                      text-center
                    >
                      <v-btn
                        :loading="loading1"
                        :disabled="loading1"
                        color="primary"
                        large
                        ma-5
                        @click="getExport"
                      >
                        Download List
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex xs12>
          <p
            v-for="(error, index) in errors"
            :key="index"
            class="alert alert-danger"
          >
            {{ error }}
          </p>
        </v-flex>
      </v-layout>
    </template>
  </FullPageLayout>
</template>

<script>
export default {
  data() {
    return {
      trip_number: null,
      time: '11:00:00',
      date: moment().format('YYYY-MM-DD'),
      port_of_entry: '0427',
      export_type: 'Ready',
      file: null,
      form: new FormData(),
      form2: new FormData(),
      start_dropoff_date: moment().format('YYYY-MM-DD'),
      end_dropoff_date: moment().format('YYYY-MM-DD'),

      menu1: false,
      menu2: false,
      menu3: true,
      items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
      errors: [],
      loading1: false,
      loading2: false,
      loading3: false,
      file2: null,
      times: [
        { text: '1 AM', value: '1:00:00' },
        { text: '2 AM', value: '2:00:00' },
        { text: '3 AM', value: '3:00:00' },
        { text: '4 AM', value: '4:00:00' },
        { text: '5 AM', value: '5:00:00' },
        { text: '6 AM', value: '6:00:00' },
        { text: '7 AM', value: '7:00:00' },
        { text: '8 AM', value: '8:00:00' },
        { text: '9 AM', value: '9:00:00' },
        { text: '10 AM', value: '10:00:00' },
        { text: '11 AM', value: '11:00:00' },
        { text: '12 AM', value: '12:00:00' },
        { text: '1 PM', value: '13:00:00' },
        { text: '2 PM', value: '14:00:00' },
        { text: '3 PM', value: '15:00:00' },
        { text: '4 PM', value: '16:00:00' },
        { text: '5 PM', value: '17:00:00' },
        { text: '6 PM', value: '18:00:00' },
        { text: '7 PM', value: '19:00:00' },
        { text: '8 PM', value: '20:00:00' },
        { text: '9 PM', value: '21:00:00' },
        { text: '10 PM', value: '22:00:00' },
        { text: '11 PM', value: '23:00:00' },
        { text: '12 PM', value: '24:00:00' }
      ]
    };
  },

  methods: {
    customFormatter(date) {
      return moment(date).format('MMMM Do YYYY, h:mm:ss a');
    },

    getExport() {
      this.loading1 = true;

      this.$http
        .get(`/admin/us-export`)
        .then(response => {
          this.downloadFileUrl(response.data,this.export_type +
            ' (' +
            moment().format('YYYYmmdHHMMSS') +
            ').csv', 'text/csv');
        })
        .catch(err => {
          if (err.response.status === 404) {
            swal({
              title: 'Error',
              text: 'Nothing found',
              icon: 'error'
            });
          }
          
        })
        .finally(() => {
          this.loading1 = false;
        });
    }
  }
};
</script>
