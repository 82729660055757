<template>
  <FullPageLayout title="Credits">
    <template #default>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-card
            outlined
            class="pa-3 fill-height"
          >
            <v-container class="pb-0">
              <v-row>
                <v-col>
                  <h3>Available Credits</h3>
                  <v-container class="pa-0">
                    <p>Add credits by credit card or Paypal.</p>
                  </v-container>
                  <h1 class="mt-5">
                    {{ $auth.user?.credits | currency }}
                  </h1>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>

        <!-- Credit Card -->
        <v-col
          cols="12"
          md="4"
        >
          <v-card
            outlined
            class="pa-3 fill-height"
          >
            <v-container class="pb-0">
              <v-row>
                <v-col>
                  <h3>Credit Cards</h3>
                  <p>
                    Your credit card information is securely stored by our
                    payment provider.
                  </p>
                </v-col>
              </v-row>

              <!-- Bottom Card -->
              <v-row>
                <v-col>
                  <!-- <stripe-add-credits
                    :card="card"
                    :display="s_show"
                    :disabled="submitting"
                  /> -->

                  <v-menu v-if="card">
                    <template #activator="{ on }">
                      <v-btn
                        color="primary"
                        :disabled="submitting"
                        v-on="on"
                        @click="addCredits()"
                      >
                        Add Credits
                      </v-btn>
                    </template>
                  </v-menu>
                </v-col>
                <v-col cols="auto">
                  <img
                    width="50"
                    :src="asset('images/icons/visa.png')"
                    alt="Visa"
                  >
                  <img
                    width="50"
                    :src="asset('images/icons/mastercard.png')"
                    alt="Mastercard"
                  >
                  <img
                    width="50"
                    :src="asset('images/icons/amex.png')"
                    alt="Amex"
                  >
                </v-col>
              </v-row>

              <v-row
                align-end
                justify-center
                fill-height
                mt-4
              >
                <v-col
                  xs12
                  class="mb-2"
                >
                  <v-row
                    v-for="userCard in userCards"
                    :key="userCard.id"
                    dense
                  >
                    <v-col>
                      <span class="display_3">
                        {{ userCard.card_brand }} ****
                        {{ userCard.card_last_four }}
                      </span>
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <v-btn
                            text
                            icon
                            :disabled="submitting"
                            color="red darken-4"
                            v-on="on"
                            @click="removePaymentMethod(userCard)"
                          >
                            <v-icon small>
                              fa-minus-circle
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Remove credit card</span>
                      </v-tooltip>
                    </v-col>

                    <v-col cols="auto">
                      <!--can from mixin.js-->
                      <v-switch
                        v-model="userCard.is_default"
                        class="ma-0"
                        :loading="submitting"
                        :disabled="userCard.is_default ? true : false"
                        :hint="`${userCard.is_default ? 'Default' : ''}`"
                        persistent-hint
                        @change="setPaymentMethodAsDefault(userCard)"
                      />
                    </v-col>

                    <v-col cols="12">
                      <v-divider />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col />
                <v-col cols="auto">
                  <v-menu>
                    <template #activator="{ on }">
                      <!-- <v-btn
                        color="primary"
                        outlined
                        v-on="on"
                        @click="$refs.addCreditCard.openDialog()">
                        Add Card
                      </v-btn> -->

                      <v-btn
                        color="primary"
                        v-on="on"
                        @click="$refs.braintreeAddCreditCard.openDialog()"
                      >
                        Add Card
                      </v-btn>
                    </template>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>

        <!-- Paypal -->
        <v-col
          v-show="!restrictCreditLoading"
          cols="12"
          md="4"
        >
          <v-card
            outlined
            class="pa-3 fill-height"
          >
            <v-container class="pb-0">
              <v-row>
                <v-col>
                  <h3>PayPal</h3>
                  <p>Purchase credits through your PayPal account.</p>
                </v-col>
              </v-row>
              <!-- Bottom -->
              <v-row>
                <v-col>
                  <!--can from mixin.js-->
                  <v-menu offset-y>
                    <template #activator="{ on }">
                      <v-btn
                        color="primary"
                        v-on="on"
                        @click="$refs.paypalAddCredits.openDialog()"
                      >
                        Add Credits
                      </v-btn>
                    </template>
                  </v-menu>
                </v-col>
                <v-col cols="auto">
                  <a
                    href="https://www.paypal.com/webapps/mpp/paypal-popup"
                    title="How PayPal Works"
                    onclick="javascript:window.open('https://www.paypal.com/webapps/mpp/paypal-popup','WIPaypal','toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=1060, height=700'); return false;"
                  >
                    <img
                      style="height: 50px"
                      src="https://www.paypalobjects.com/webstatic/mktg/logo/AM_mc_vs_dc_ae.jpg"
                      border="0"
                      alt="PayPal Acceptance Mark"
                    >
                  </a>
                </v-col>
              </v-row>
            </v-container>

            <BraintreeAddCard
              ref="braintreeAddCreditCard"
              :has-card="hasCard"
              @getUserCards="getUserCards"
            />

            <PaypalAddCredits
              ref="paypalAddCredits"
            />
          </v-card>
        </v-col>
      </v-row>

      <!-- Auto Fund Account -->
      <v-row
        v-if="hasCard"
        class="mt-5"
      >
        <v-col>
          <v-card
            class="elevation-0 pa-5"
            style="border: 1px solid #e7e7e7"
          >
            <h3>Auto-Fund Account</h3>

            <v-card-text class="pa-0">
              <v-form>
                <v-container class="pa-0">
                  <v-row>
                    <v-col cols="2">
                      <v-switch
                        v-model="reloadSettings.status"
                        label="Activate"
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        v-model="reloadSettings.additional_credits"
                        v-validate="'required|max_value:10000|min_value:50'"
                        type="number"
                        :error-messages="errs.collect('additional_credits')"
                        data-vv-name="additional_credits"
                        label="Automatically Purchase"
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="reloadSettings.threshold_credits"
                        v-validate="'required|max_value:10000|min_value:50'"
                        type="number"
                        :error-messages="errs.collect('threshold')"
                        data-vv-name="threshold"
                        label="If account balance is below"
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                        :loading="reloadSettingisUpdating"
                        color="primary"
                        @click="updateReloadCreditsSettings"
                      >
                        Update Setting
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!--can from mixin.js-->
      <v-row
        wrap
        class="mt-5"
      >
        <v-flex xs12>
          <transaction-table
            v-if="$auth.user"
            :is-statement="false"
            :user-id="$auth.user.id"
          />
        </v-flex>
      </v-row>

      <!-- <v-layout
        wrap
        class="mt-5"
      >
        <v-col
          xs12
          mb-12
        >
          <credit-invoice-table />
        </v-flex>
      </v-layout> -->
    </template>
  </FullPageLayout>
</template>

<script>
import moment from 'moment';
import BraintreeAddCard from './BraintreeAddCard.vue';
import PaypalAddCredits from './PaypalAddCredits.vue';
export default {
  metaInfo: {
    title: "Credits | Stallion Express"
  },
  filters: {
    relativeTime: function (value) {
      return moment(value, "YYYY-MM-DD hh:mm:ss").fromNow();
    }
  },
  components: {
    BraintreeAddCard,
    PaypalAddCredits
  },
  data() {
    return {
      dense: null,
      rounded: null,
      text: null,
      s_show: false,
      csrf: "",
      paymentMethod: [],
      cardBrand: "",
      reloadSettings: {
        status: false,
        threshold_credits: null,
        additional_credits: null
      },
      reloadSettingisUpdating: false,
      userCards: [],
      submitting: false,
      restrictCreditLoading: false
    };
  },
  computed: {
    hasCard() {
      return this.userCards.length > 0;
    },
    card() {
      const defaultCard = this.userCards.filter((item) => {
        return item.is_default;
      });
      return defaultCard.length > 0 ? defaultCard[0] : null;
    }
  },
  mounted() {
    Event.listen("payment-completed", () => {
      Event.fire("get-credits");
    });
    Event.listen("card-created", () => {
      this.getUser();
    });
    this.getPaypalPaymentStatus();
    this.syncreload_credit_settings();
    this.getUserCards();
  },
  methods: {
    getUserCards() {
      this.$http.get("credit-card").then((response) => {
        this.userCards = response.data;
      });
    },
    syncreload_credit_settings() {
      if (this.$auth.user?.reload_credit_setting) {
        this.reloadSettings = this.$auth.user.reload_credit_setting;
      }
    },
    updateReloadCreditsSettings() {
      this.$validator.validate().then((result) => {
        if (result) {
          this.reloadSettingisUpdating = true;
          this.$http
            .post("user/reload-settings", this.reloadSettings)
            .then((res) => res.data)
            .then(() => {
              this.reloadSettingisUpdating = false;
              swal({
                title: "Success!",
                text: "Your auto reload Setting has been updated",
                icon: "success"
              });
          })
          .catch(() => (this.reloadSettingisUpdating = false));
        }
      });
    },
    setPaymentMethodAsDefault(card) {
      this.submitting = true;
      this.$http
        .post("credit-card/set-default", {
          id: card.id,
          is_default: card.is_default
        })
        .then(() => {
          this.userCards.forEach((item) => {
            if (item.id != card.id) {
              item.is_default = 0;
            }
          });
          this.$notify({
            group: "main",
            title: "New Default Card",
            text: `${this.card.card_brand} **** ${this.card.card_last_four} has been set as your default card.`,
            duration: 5000
          });
        })
        .catch(() => {
          card.is_default = false;
          swal({
            title: "Error!",
            text: "Error setting card as default.",
            icon: "error"
          });
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    removePaymentMethod(card) {
      var self = this;
      swal({
        title: "Are you sure?",
        text: "Are you sure you want to remove this card from your account?",
        icon: "warning",
        buttons: ["Cancel", "Remove"]
      }).then(function (isConfirm) {
        if (isConfirm) {
          self.submitting = true;
          self.$http
            .post("credit-card/remove", {
              id: card.id
            })
            .then((res) => {
              if (res.data.success) {
                // vue/plugins/auth.js
                self.getUser();

                // self.$auth.fetch({
                //   params: {},
                //   success: function () { },
                //   error: function () { }
                //   // etc...
                // });
                swal({
                  title: "Success!",
                  text: "Your card has been removed from your account.",
                  icon: "success"
                });
                self.getUserCards();
              }
              else {
                swal({
                  title: "Error!",
                  text: res.data.error,
                  icon: "error"
                });
              }
            })
            .catch(() => {
              swal({
                title: "Error!",
                text: "Error removing your card.",
                icon: "error"
              });
            })
            .finally(() => {
              self.submitting = false;
            });
        }
        swal.close();
      });
    },
    getPaypalPaymentStatus() {
      if (this.$route.query.success !== undefined) {
        if (this.$route.query.success == "true") {
          this.processing = true;
          this.$http
            .post("/paypal/express-checkout", {
              payerID: this.$route.query.PayerID,
              paypal_token: this.$route.query.token,
              paymentID: this.$route.query.paymentId,
              _token: this.csrf
            })
            .then((res) => {
              if (res.data.success) {
                Event.fire("payment-completed");
                swal({
                  title: "Success!",
                  text: "Your credits have been added successfully.",
                  icon: "success"
                });
                this.$router.push("/credits");
              }
              else {
                swal({
                  title: "Error!",
                  text: "Paypal payment not successful",
                  icon: "error"
                });
              }
            })
            .catch(() => {
                swal({
                  title: "Error!",
                  text: "Paypal payment not successful",
                  icon: "error"
                });
            });
        }
        else {
          setTimeout(function () {
            swal("Error!", "Paypal payment authentication failed ");
          }, 1000);
        }
      }
    },
    addCredits() {
      const payment_data = { card: this.card, amount: null, disabled: this.submitting }
      Event.fire('add-credits', payment_data);
    }
  }
};
</script>

<style scoped>
h3 {
  margin-bottom: 0px !important;
}

.v-application p {
  margin-bottom: 0px !important;
}
</style>
