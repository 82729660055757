export default {
  ADDRESS_BOOK_FROM: {
    // shipment key => address book key
    return_name: 'name',
    return_company: 'company',
    phone: 'phone',
    return_address1: 'address1',
    return_address2: 'address2',
    return_city: 'city',
    return_province_code: 'province_code',
    return_postal_code: 'postal_code',
    return_country_code: 'country_code'
  },
  ADDRESS_BOOK_TO: {
    // shipment key => address book key
    name: 'name',
    company: 'company',
    phone: 'phone',
    address1: 'address1',
    address2: 'address2',
    city: 'city',
    province_code: 'province_code',
    postal_code: 'postal_code',
    country_code: 'country_code',
    email: 'email'
  }
};
