<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-btn
          color="primary"
          dark
          @click="$refs.ruleDialog.startNew()"
        >
          Create Rule
        </v-btn>
      </v-col>

      <v-col cols="12">
        <p>These are users who have custom pricing applied on their account.</p>

        <v-data-table
          class="table-striped"
          :headers="headers"
          :items="items"
          :loading="loading"
          :footer-props="{
            'items-per-page-options': [25, 50, 100]
          }"
          :items-per-page="25"
          @click:row="rowClick"
        >
          <template #item.rules="{ item }">
            <div
              v-for="rule in item.rules"
              :key="rule.id"
            >
              {{ rule.name }}
            </div>
          </template>

          <template #item.updated_at="{ item }">
            <div v-if="item.updated_at">
              {{ item.updated_at | datetime_short }}
              <br>
              ({{ item.updated_at | datetime_from }})
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <rule-dialog
      ref="ruleDialog"
      @update-rules="updateTable()"
    />
  </div>
</template>
<script>
import RuleDialog from './RuleDialog.vue';
export default {
  components: {
    RuleDialog
  },
  data() {
    return {
      loading: false,
      items: [],
      options: {},
      headers: [
        { text: 'User', value: 'email', sortable: true },
        { text: 'Name', value: 'name', sortable: true },
        { text: 'Business Name', value: 'business_name', sortable: true },
        { text: 'Rules', value: 'rules', sortable: true },
        // { text: 'Criteria', value: 'criteria', sortable: false },
        // { text: 'Actions', value: 'number_actions', sortable: false },
        { text: 'Updated At', value: 'updated_at', sortable: true }
        // { text: 'Created At', value: 'created_at', sortable: false }
        // { text: 'Active', value: 'active', sortable: false },
        // { text: 'Actions', value: 'actions', align: 'right', sortable: false }
      ]
    };
  },
  watch: {
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    },
    'options.itemsPerPage': function (newVal, oldVal) {
      if (oldVal && newVal) {
        const preference = {
          table_rows: {
            automation: newVal
          }
        };
        this.$store.dispatch('main/setPreferences', preference);
      }
    }
  },

  mounted() {
    this.updateTable();
    Event.listen('update-rules', () => {
      this.updateTable();
    });

    const table_rows = this.$store.getters['main/table_rows'];
    this.options.itemsPerPage = table_rows?.automation ?? 25;
  },
  methods: {
    rowClick(item) {
      if (item.return_id == null) {
        this.$router.push({
          name: 'custom-pricing-show',
          params: {
            user_id: item.id
          }
        });
      }
    },
    updateTable() {
      this.items = [];
      this.loading = true;

      this.$http
        .request(`/admin/pricing-rules/users`, {
          method: 'get'
        })
        .then((response) => response.data)
        .then((response) => {
          this.items = response;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
