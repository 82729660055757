<template>
  <StallionDialog
    v-model="show"
    :fullscreen="true"
    title="Create User"
    :transition="dialog-bottom-transition"
  >
    <template #content>
      <v-card-text>
        <v-container>
          <v-row>
            <v-container class="py-0">
              <v-alert
                v-for="(error, key1) in errors"
                :key="key1"
                dark
                color="error"
                class="login-field"
                :value="error != ''"
                icon="warning"
              >
                <div
                  v-for="(description, key2) in error"
                  :key="key2"
                >
                  {{ description }}
                </div>
              </v-alert>
            </v-container>
          </v-row>

          <v-container
            container--fluid
            grid-list-lg
          >
            <v-layout wrap>
              <v-flex xs12>
                <v-form>
                  <v-container>
                    <v-layout wrap>
                      <v-flex xs12>
                        <h4>Login Info</h4>
                      </v-flex>
                      <v-flex
                        xs12
                        md3
                      >
                        <v-text-field
                          v-model="user.username"
                          v-validate="'required|unique'"
                          label="Username"
                          data-vv-name="username"
                          :error-messages="errs.collect('username')"
                          type="username"
                          name="username"
                          data-vv-validate-on="blur"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        md3
                      >
                        <v-text-field
                          v-model="user.email"
                          v-validate="'required|email'"
                          label="Email"
                          data-vv-name="email"
                          :error-messages="errs.collect('email')"
                          type="email"
                          name="email"
                          data-vv-validate-on="blur"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        md3
                      >
                        <v-text-field
                          ref="password"
                          v-model="user.password"
                          v-validate="'required|min:8|verify_password'"
                          label="Password"
                          data-vv-name="password"
                          :error-messages="errs.collect('password')"
                          type="password"
                          name="password"
                          data-vv-validate-on="blur"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        md3
                      >
                        <v-text-field
                          ref="confirm"
                          v-model="user.password_confirmation"
                          v-validate="'required|confirmed:password'"
                          label="Confirm Password"
                          data-vv-name="confirm"
                          :error-messages="errs.collect('confirm')"
                          type="password"
                          name="confirm"
                          data-vv-validate-on="blur"
                        />
                      </v-flex>
                    </v-layout>
                    <v-layout wrap>
                      <v-flex xs12>
                        <h4>Account Info</h4>
                      </v-flex>
                      <v-flex
                        xs12
                        md3
                      >
                        <v-text-field
                          v-model="user.name"
                          v-validate="'required'"
                          label="Full Name"
                          data-vv-name="name"
                          :error-messages="errs.collect('name')"
                          type="text"
                          name="name"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        md3
                      >
                        <v-text-field
                          v-model="user.company_name"
                          label="Company Name"
                          data-vv-name="company_name"
                          :error-messages="errs.collect('company_name')"
                          type="text"
                          name="company_name"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        md3
                      >
                        <v-text-field
                          v-model="user.phone"
                          v-mask="'###########'"
                          v-validate="'required'"
                          :error-messages="errs.collect('phone')"
                          label="Phone"
                          data-vv-name="phone"
                        />
                      </v-flex>

                      <v-flex
                        xs12
                        md3
                      >
                        <v-text-field
                          id="address1"
                          v-model="user.address1"
                          v-validate="'required'"
                          label="Address1"
                          data-vv-name="address1"
                          :error-messages="errs.collect('address1')"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        md3
                      >
                        <v-text-field
                          v-model="user.address2"
                          label="Address2"
                          required
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        md3
                      >
                        <v-text-field
                          v-model="user.city"
                          v-validate="'required'"
                          label="City"
                          data-vv-name="city"
                          :error-messages="errs.collect('city')"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        md3
                      >
                        <v-text-field
                          v-model="user.postal_code"
                          v-mask="'A#A #A#'"
                          v-validate="{
                            required: true,
                            regex: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/
                          }"
                          label="Postal Code"
                          :error-messages="errs.collect('postal_code')"
                          data-vv-name="postal_code"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        md3
                      >
                        <v-autocomplete
                          v-model="user.province_code"
                          v-validate="'required'"
                          :error-messages="errs.collect('province_code')"
                          data-vv-name="province_code"
                          :items="provinces"
                          label="Province"
                          item-text="name"
                          item-value="code"
                          :search-input.sync="searchInput2"
                        />
                      </v-flex>
                      <v-flex
                        xs12
                        md3
                      >
                        <v-autocomplete
                          v-model="user.country_code"
                          v-validate="'required'"
                          :error-messages="errs.collect('country_code')"
                          data-vv-name="country_code"
                          :items="countries"
                          label="Country"
                          item-text="name"
                          item-value="code"
                          :search-input.sync="searchInput"
                        />
                      </v-flex>

                      <v-flex
                        xs12
                        md3
                      >
                        <v-autocomplete
                          v-model="user.weekly_package_count"
                          v-validate="'required'"
                          :error-messages="errs.collect('weekly_package_count')"
                          data-vv-name="weekly_package_count"
                          :items="package_counts"
                          label="Weekly Package Count"
                          :search-input.sync="searchInput3"
                        />
                      </v-flex>

                      <v-flex
                        xs12
                        md3
                      >
                        <v-autocomplete
                          v-model="user.branch_location_id"
                          v-validate="'required'"
                          :error-messages="errs.collect('branch_location_id')"
                          data-vv-name="branch_location_id"
                          :items="default_location_list"
                          label="Default Pickup Location"
                          :search-input.sync="branch"
                          item-text="location"
                          item-value="id"
                        />
                      </v-flex>

                      <v-flex
                        xs12
                        md3
                      >
                        <v-autocomplete
                          v-model="user.ups_active"
                          v-validate="'required'"
                          :error-messages="errs.collect('ups_active')"
                          data-vv-name="ups_active"
                          :items="[
                            {
                              text: 'Yes',
                              value: 0
                            },
                            {
                              text: 'No',
                              value: 1
                            }
                          ]"
                          label="Have an active UPS account?"
                          :search-input.sync="ups_active"
                          item-text="text"
                          item-value="value"
                        />
                      </v-flex>
                    </v-layout>
                  </v-container>

                  <v-container>
                    <v-layout wrap>
                      <v-flex xs12>
                        <h4>Products Info</h4>
                      </v-flex>

                      <v-col
                        id="product_categories1"
                        cols="12"
                        md="3"
                        class="py-0"
                      >
                        <v-checkbox
                          v-for="item in categories1"
                          :key="item"
                          v-model="selected_categories"
                          v-validate="'required'"
                          :value="item"
                          data-vv-name="product_categories"
                          :name="`product_categories[${item}]`"
                          :error-messages="errs.collect('product_categories')"
                          :label="item"
                        />
                      </v-col>
                      <v-col
                        id="product_categories2"
                        cols="12"
                        md="3"
                        class="py-0"
                      >
                        <v-checkbox
                          v-for="item in categories2"
                          :key="item"
                          v-model="selected_categories"
                          v-validate="'required'"
                          :value="item"
                          data-vv-name="product_categories"
                          :name="`product_categories[${item}]`"
                          :error-messages="errs.collect('product_categories')"
                          :label="item"
                        />
                      </v-col>

                      <v-col
                        cols="12"
                        md="3"
                        class="py-0"
                      >
                        <v-text-field
                          v-model="user.promotional_code"
                          label="Promotional Code"
                          data-vv-name="name"
                          :error-messages="errs.collect('promotional_code')"
                          type="text"
                          name="promotional_code"
                        />
                        <v-autocomplete
                          v-model="user.referral"
                          v-validate="'required'"
                          :error-messages="errs.collect('referral')"
                          data-vv-name="referral"
                          :items="referral_list"
                          label="How did you hear about us?"
                          :search-input.sync="searchInput4"
                        />
                      </v-col>
                    </v-layout>
                  </v-container>
                </v-form>
              </v-flex>
            </v-layout>
          </v-container>
        </v-container>
      </v-card-text>
    </template>

    <template #actions>
      <v-spacer />
      <v-btn
        color="primary"
        :loading="loading"
        :disabled="loading"
        @click="save()"
      >
        Save
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
import constants from '@/constants/index';
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      errors: [],
      disabled: false,
      loading: false,
      isGooglePlacesAutoCompleteInitialized: false,
      countries: [
        {
          name: 'Canada',
          code: 'CA'
        }
      ],
      provinces: constants.PROVINCES || [],
      default_location_list: constants.DEFAULT_PICKUP_LOCATIONS || [],
      package_counts: constants.PACKAGE_COUNTS || [],
      categories1: constants.PRODUCT_CATEGORIES_1 || [],
      categories2: constants.PRODUCT_CATEGORIES_2 || [],
      referral_list: constants.REFERRAL_LIST || [],
      searchInput: null,
      searchInput2: null,
      searchInput3: null,
      searchInput4: null,
      branch: null,
      ups_active: null,
      selected_categories: [],
      user: {}
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    show: {
      handler(newVal) {
        if (newVal && !this.isGooglePlacesAutoCompleteInitialized) {
          // wait a moment to let the component finish rendering
          setTimeout(() => {
            this.initializeGooglePlacesAutoComplete(
              'address1',
              this.updateAddress
            ); // mixin @app.js
            this.isGooglePlacesAutoCompleteInitialized = true;
          }, 500);
        }
      }
    }
  },
  mounted() {},
  created() {},
  methods: {
    updateAddress(value) {
      // console.log(value);
      this.user.address1 = value.street_number + ' ' + value.route;
      this.user.city = value.locality;
      this.user.postal_code = value.postal_code;

      if (value.country == 'CA') {
        this.user.country_code = value.country;
      }

      if (value.administrative_area_level_1 == 'ON') {
        this.user.province_code = value.administrative_area_level_1;
      }
    },
    resetUserForm() {
      let app = this;
      (Object.keys(app.user) || []).forEach(function (key) {
        app.user[key] = null;
      });
      app.selected_categories = [];
      app.$validator.reset();
      app.errors = [];
    },
    save() {
      this.$validator.validate().then((result) => {
        if (result) {
          this.loading = true;
          this.user.product_categories = this.selected_categories;

          this.$http
            .post(`/admin/users`, this.user)
            .then((response) => response.data)
            .then(() => {
              // console.log(response);
              this.$notify({
                group: 'main',
                title: 'Created',
                text: 'New User has been created sucessfully',
                duration: 5000,
                type: 'success'
              });
              this.show = false;
              this.resetUserForm();
              this.$emit('reloadUsers'); // emit event from admin/users/index.vue
            })
            .catch((err) => {
              // console.log(err.response);
              this.errors = err.response.data.errors;
              swal({
                title: 'Error',
                text: 'Some of the form fields are invalid.',
                icon: 'error'
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>
