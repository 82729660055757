<script>
// import VueCharts from 'vue-chartjs';
import { Pie, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;

export default {
  extends: Pie,
  mixins: [reactiveProp],
  // props: ['chartData', 'options'],
  props: {
    chartData: {
      type: [Object, String, Number, Array],
      default: null
    },
    options: {
      type: [Object, String, Number, Array],
      default: null
    }
  },
  mounted() {
    console.log('chart1 mounted');
    console.log(this.chartData);
    this.renderChart(this.chartData, this.options);
  }
};
</script>
