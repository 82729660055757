<template>
  <div>
    <v-container
      class="main-page-container"
    >
      <v-row>
        <!-- <v-col cols="12" v-if="breadCrumbs">
          <v-breadcrumbs class="pa-0" :items="breadCrumbs" />
        </v-col> -->
        <v-col>
          <h1>{{ title }}</h1>
          <p>
            <slot name="subtitle" />
          </p>
        </v-col>
        <v-col cols="auto">
          <slot name="page-links" />
        </v-col>
      </v-row>
      <main>
        <slot />
      </main>
    </v-container>
    <footer class="text-left footer">
      <slot name="footer" />
    </footer>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    },
    breadCrumbs: {
      type: Array,
      required: false,
      default: function () {
        return [];
      }
    }
  }
};
</script>
