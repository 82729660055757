import _ from 'lodash';
window._ = _;
import Echo from "laravel-echo";

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/* import Pusher from 'pusher-js';
window.Pusher = Pusher; */
  
if(window.Laravel?.pusher_key) {
  window.Echo = new Echo({
    broadcaster: 'pusher',
    key: window.Laravel?.pusher_key,
    cluster: 'us2',
    forceTLS: true
  });
}