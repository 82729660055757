<template>
  <StallionDialog
    v-model="show"
    :width="500"
    title="Delete Returns"
  >
    <template #content>
      <div class="pa-4">
        <v-layout
          justify-center
          align-center
          class="mt-4"
        >
          <v-flex xs8>
            <p
              style="font-size: 1.3em"
              class="text-center"
            >
              Delete {{ returns.length }}
              {{ returns.length | pluralize('shipment') }}
            </p>
          </v-flex>
        </v-layout>
      </div>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        :loading="loading"
        :disabled="loading"
        @click="deleteReturns"
      >
        Delete
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    returns: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      step: 1,
      loading: false
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },

  methods: {
    deleteReturns() {
      this.loading = true;
      var ids = this.returns.map(x => x.id);
      this.$http
        .post('/returns/delete', {
          ids: ids
        })
        .then(response => response.data)
        .then(() => {
          Event.fire('update-returns');
          this.show = false;
          this.returns = [];
        })
        .catch(() => {
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
