<template>
  <v-row dense>
    <recurring-charges-table :user="user" />
  </v-row>
</template>
  
<script>
import RecurringChargesTable from '../../../../components/admin/billing/recurring-charges-table.vue';

export default {
  components: {
    RecurringChargesTable
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  }
};
</script>
