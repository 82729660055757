<template>
  <v-row>
    <v-col sm="8">
      <v-card outlined>
        <v-data-table
          class="table-striped"
          :headers="headers"
          hide-default-header
          :options.sync="options"
          :items="group"
        >
          <template #item.title="{ item }">
            <v-list-item :class="item.deleted_at ? 'archived-row' : ''">
              <v-list-item-content style="margin-right: 50px">
                <v-list-item-title>
                  <h3 v-if="isNotificationGroup">
                    {{ item.data.title }}
                  </h3>
                  <h3 v-else>
                    {{ item.title }}
                  </h3>
                </v-list-item-title>
                <v-list-item-subtitle
                  class="mt-2"
                  style="width: 50px"
                >
                  <span 
                    v-if="isNotificationGroup"
                    style="white-space: normal"
                  >{{ item.data.message }}</span>
                  <span 
                    v-else 
                    :style="expand_id == item.id ? 'white-space: normal' : ''"
                    v-html="getAnnouncementHTML(item.description)"
                  />
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template #item.actions="{ item }">
            <div :class="item.deleted_at ? 'archived-row' : ''">
              <span class="d-block">{{ item.created_at | datetime_from }}</span>
              
              <v-layout 
                v-if="isNotificationGroup"
                class="float-right mr-4"
              >
                <button
                  :disabled="loading"
                  @click="deleteNotification(item)"
                >
                  <v-icon color="warning">
                    mdi-delete
                  </v-icon>
                </button>
              &nbsp;
                <button
                  :disabled="loading"
                  @click="toggle(item)"
                >
                  <v-icon v-if="item.read_at == null">
                    mdi-checkbox-blank-circle-outline
                  </v-icon>
                  <v-icon
                    v-else
                    color
                  >
                    mdi-checkbox-marked-circle-outline
                  </v-icon>
                </button>
              </v-layout>
  
              <v-layout
                v-else-if="item.description.length > 120"
                class="float-right mr-4 mt-3"
              >
                <v-btn
                  color="primary"
                  elevation="2"
                  small
                  @click="expandDescription(item.id)"
                >
                  <v-icon small>
                    mdi-unfold-more
                  </v-icon>
                  <span v-if="expand_id == item.id">Collapse</span>
                  <span v-else>Expand</span>
                </v-btn>
              </v-layout>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
      type: {
        type: String,
        default: 'notification'
      },
      group: {
        type: Array,
        default: () => {return []}
      },
      update: {
        type: Function,
        default: () => {}
      }
  },
  data() {
      return {
        loading: false,
        expand_id: null,
        options: {},
        headers: [
          { text: '', value: 'title', sortable: false },
          { text: '', value: 'actions', align: 'right', sortable: false }
        ]
      }
  },
  computed: {
    isNotificationGroup(){
        return this.type == 'notification';
    }
  },
  watch: {
    'options.itemsPerPage' : function(newVal, oldVal) {
      if (oldVal && newVal) {
        const preference = {
          table_rows: {
            notifications: newVal
          }
        }
        this.$store.dispatch('main/setPreferences', preference);
      }

    },
  },
  created() {
    this.$store.dispatch('main/getAnnouncements');
  },
  mounted() {
    const table_rows = this.$store.getters['main/table_rows'];
    this.options.itemsPerPage = table_rows?.notifications ?? 5;
  },
  methods: {
    expandDescription(id){
      this.expand_id = this.expand_id == null ? id : null;
    },
    markAsRead(notification) {
      // check if the selected notification belongs to the main notification / notification dropdown header
      // main notification was initialized at App.vue@getNotifications
      let mainNotification = _.find(this.$store.state.main.notifications, {
        id: notification.id
      });
      if (mainNotification) {
        mainNotification.read_at = moment().unix();
      }
      // update current notifications list
      let currentNotification = _.find(this.notifications, {
        id: notification.id
      });
      currentNotification.read_at = moment().unix();
      this.$store.state.main.unread--;
    },
    toggle(notification) {
      if (notification.read_at) {
        return;
      }
      notification.read_at = Date.now;
      this.loading = true;
      this.$http
        .get(`/notifications/${notification.id}/toggle`)
        .then(() => {
          this.markAsRead(notification);
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    deleteNotification(notification) {
      this.loading = true;
      this.$http
        .delete(`/notifications/${notification.id}`)
        .then(response => response.data)
        .then(() => {
          // refresh notifications
          this.update();
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    getAnnouncementHTML(text) {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      let html = text.replace(urlRegex, '<a target="_blank" href="$1" style="color: #f9ac40">$1</a>');
      html = html.replace(/(\n)/g, '<br>');
      return html;
    }
  }
};
</script>

<style scoped>
.archived-row {
  pointer-events: none;
  color: red !important;
}
</style>
