<template>
  <div>
    <!-- v-bind.sync="imp.selection.filters"-->
    <order-search
      :search-order-filters.sync="imp.selection.filters"
      selection-type="file"
      @apply-search="setFilters()"
    />

    <v-row dense>
      <v-col>
        <v-form>
          <!-- <v-container class="grey lighten-5"> -->
          <v-row dense>
            <v-col cols="10">
              <v-btn
                color="primary"
                @click="selectAll"
              >
                Select All
              </v-btn>

              <span class="ml-3">{{ selected.length }} selected</span>
            </v-col>

            <v-col
              class="d-flex"
              cols="2"
            >
              <v-spacer />
              <!-- buildManageColumnHeaders is from mixin.js -->
              <stallion-table-settings
                :table="manageColumnTableKey"
                :columns="buildManageColumnHeaders(headers, manageColumnSettings, manageColumnTableKey)"
                :update-column-handler="updateColumns"
              />
            </v-col>
            <v-col />
          </v-row>
        </v-form>
      </v-col>
    </v-row>

    <v-flex
      xs12
      mb-12
      mt-4
    >
      <div>
        <v-card outlined>
          <!-- <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                small
                :disabled="selected.length == 0"
                color="primary"
                depressed
                outlined
                v-on="on"
                @click="show_edit = true"
              >
                Bulk Edit
              </v-btn>
            </template>
            <span>Edit {{ selected.length }} selected shipments</span>
          </v-tooltip> -->

          <v-data-table
            v-model="selected"
            :options.sync="options"
            dusk="validation_table"
            class="table-striped"
            :headers="filteredHeaders"
            :items="filteredShipments"
            show-select
            item-key="row"
            :footer-props="{
              'items-per-page-options': [
                50, 100, 200, 500
              ]
            }"
            @item-selected="bulkSelectSelect"
            @current-items="currentSelect = $event"
          >
            <template #item.order_id="{ item }">
              <div v-html="$options.filters.orderLink(item)" />
            </template>
            <template #item.name="{ item }">
              {{ item.name }}
              <br>
              {{ item.address1 }}
              <br>
              <span v-if="item.address2">
                {{ item.address2 }}
                <br>
              </span>
              {{
                item.city + ' ' + item.province_code + ', ' + item.postal_code
              }}
              <br>
              {{ item.country_code }}
            </template>

            <template #item.postage_type_id="{ item }">
              <div v-html="$options.filters.postage(item)" />
            </template>

            <template #item.package_type_id="{ item }">
              <div
                v-html="$options.filters.package_type(item.package_type_id)"
              />
            </template>

            <template #item.needs_postage="{ item }">
              <span v-if="item.needs_postage === true">Yes</span>
              <span v-else-if="item.needs_postage === false">No</span>
            </template>

            <template #item.postage_rate="{ item }">
              <div>
                {{ item | combined_rate | currency }}
              </div>
            </template>

            <template #item.tax="{ item }">
              {{ item.tax | currency }}
            </template>

            <template #item.error="{ item }">
              <span
                v-if="item.error"
                class="red--text"
              >
                {{ item.error }}
              </span>
            </template>

            <template #item.package_contents="{ item }">
              <div>
                <!-- getCombinedContents from mixin.js -->
                {{ item.package_contents ? item.package_contents : getCombinedContents(item.items) }}
              </div>
            </template>

            <template #item.value="{ item }">
              <!-- <div>{{ item.value | currency }}</div> -->
              <div>
                <!-- getCombinedValue from mixin.js -->
                {{ item.value ? item.value : getCombinedValue(item.items) }}
              </div>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </v-flex>
    <bulk-edit
      v-model="show_edit"
      :shipments="selected"
      @save="updateTable()"
    />
  </div>
</template>

<script>
// import AdvancedSearch from '../AdvancedSearch';
import OrderSearch from '../../orders/OrderSearch.vue';
import BulkEdit from '@/components/main/shipments/BulkEdit.vue';
import { mapState } from 'vuex';

export default {
  components: {
    // AdvancedSearch,
    BulkEdit,
    OrderSearch
  },
  data() {
    return {
      manageColumnSettings: [],
      manageColumnTableKey: 'table_columns_import_from_file',
      localFilters: {},
      show_edit: false,
      shiftKeyOn: false,
      options: {
        sortBy: ['row'],
        sortDesc: [false],
        itemsPerPage: 200
      },
      headers: [
        // {
        //   text: '',
        //   value: 'action',
        //   sortable: true
        // },
        {
          text: 'Row',
          value: 'row',
          sortable: true
        },
        {
          text: 'SKU',
          align: 'left',
          sortable: true,
          value: 'sku'
        },
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Order ID',
          value: 'order_id',
          sortable: true
        },
        // {
        //   text: '',
        //   value: 'display_order_id',
        //   align: 'd-none'
        // },
        {
          text: 'Package Type',
          value: 'package_type_id'
        },
        {
          text: 'Need Postage',
          value: 'needs_postage'
        },
        {
          text: 'Postage Type',
          value: 'postage_type_id'
        },
        {
          text: 'Value',
          value: 'value'
        },
        {
          text: 'Weight',
          value: 'weight'
        },
        {
          text: 'Unit',
          value: 'weight_unit'
        },
        {
          text: 'L',
          value: 'length'
        },
        {
          text: 'W',
          value: 'width'
        },
        {
          text: 'H',
          value: 'height'
        },
        {
          text: 'Unit',
          value: 'size_unit'
        },
        {
          text: 'Description',
          value: 'package_contents'
        },
        {
          text: 'Order At',
          value: 'order_at'
        },
        {
          text: 'Errors',
          value: 'errors'
        }
      ]
    };
  },
  computed: {
    ...mapState(['imp']),
    computedFilters: {
      get() {
        return this.imp.selection.filters;
      }
    },
    selected: {
      get() {
        return this.imp.selection.selected;
      },
      set(value) {
        this.$store.commit('imp/updateSelectionSelected', value);
      }
    },
    filteredShipments() {
      return this.applyAdvanceSearch();
    },
    filteredHeaders() {
      // filteredColumnHeaders is from mixin.js
      return this.manageColumnTableKey
        ? this.filteredColumnHeaders(this.headers, this.manageColumnSettings, this.manageColumnTableKey)
        : this.headers
    },
  },
  watch: {
    'options.itemsPerPage' : function(newVal, oldVal) {
      if (oldVal && newVal) {
        const preference = {
          table_rows: {
            import: newVal
          }
        }
        this.$store.dispatch('main/setPreferences', preference);
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.keyDownHandler);
    window.removeEventListener('keyup', this.keyUpHandler);
  },
  created() {
    const self = this;
    self.keyDownHandler = function ({ key }) {
      if (key == 'Shift') self.shiftKeyOn = true;
    };
    self.keyUpHandler = function ({ key }) {
      if (key == 'Shift') self.shiftKeyOn = false;
    };
    window.addEventListener('keydown', this.keyDownHandler);
    window.addEventListener('keyup', this.keyUpHandler);
  },
  async mounted(){
    const table_rows = this.$store.getters['main/table_rows'];
    this.options.itemsPerPage = table_rows?.import ?? 200;

    // getManageColumnSettings is from mixin.js
    this.manageColumnSettings = await this.getManageColumnSettings(this.manageColumnTableKey)
  },

  methods: {
    updateColumns(columns) {
      this.manageColumnSettings = columns
    },
    selectAll() {
      if (this.selected.length != this.imp.selection.shipments.length) {
        this.$notify({
          group: 'main',
          title: 'All Selected',
          text: 'All shipments have been selected',
          duration: 5000,
          type: 'success'
        });
        this.selected = this.applyAdvanceSearch();
      } else {
        this.selected = [];
      }
    },
    bulkSelectSelect({ item: b, value }) {
      const { currentSelect, shiftKeyOn } = this;

      if (this.selected && this.selected.length == 1 && value == true && shiftKeyOn) {
        let [a] = this.selected;

        if (this.lastSelect !== null) {
          a = this.lastSelect;
        }

        let start = currentSelect.findIndex((item) => item == a);
        let end = currentSelect.findIndex((item) => item == b);

        if (start - end > 0) {
          let temp = start;
          start = end;
          end = temp;
        }
        for (let i = start; i <= end; i++) {
          this.selected.push(currentSelect[i]);
        }
      }

      this.lastSelect = b;
    },
    setFilters() {
      this.$store.commit('imp/filtersSet', this.computedFilters);
      // make our localFilters reactive to trigger update of the computed filteredShipments
      this.localFilters = Object.assign({}, this.localFilters, {
        ...this.computedFilters
      });
      // reset selected filters
      this.selected = _.intersectionBy(
        this.selected,
        this.applyAdvanceSearch(),
        'row'
      );
    },
    applyAdvanceSearch() {
      const shipments = this.imp.selection.shipments;
      return shipments.filter((shipment) => {
        let found = true;
        // search
        const search = (this.localFilters.search || '').toLowerCase();
        const searchType = this.localFilters.search_type;
        if (search) {
          switch (searchType) {
            case 'Order ID':
              found =
                (shipment.order_id || '').toLowerCase() == search ||
                (shipment.display_order_id || '').toLowerCase() == search;
              break;
            case 'Customer Name':
              found = !(
                (shipment.name || '').toLowerCase().indexOf(search) == -1
              );
              break;
            case 'Item Name':
            case 'Item SKU':
              found = !(
                (shipment.package_contents || '')
                  .toLowerCase()
                  .indexOf(search) == -1
              );
              break;
            default:
          }
        }

        // country
        const countryCode = this.localFilters.country_code;
        const countryInclusive = this.localFilters.country_code_inclusive;
        if (countryCode && countryCode.length > 0) {
          if (countryInclusive) {
            found = found && countryCode.includes(shipment.country_code);
          } else {
            found = found && !countryCode.includes(shipment.country_code);
          }
        }

        // date
        const orderDateMin = this.localFilters.order_date_min;
        const orderDateMax = this.localFilters.order_date_max;
        const orderAt = new Date(shipment.order_at).valueOf();
        if (orderDateMin) {
          const minDate = new Date(orderDateMin).valueOf();
          found = found && orderAt >= minDate;
        }
        if (orderDateMax) {
          const maxDate = new Date(orderDateMax).valueOf();
          found = found && orderAt <= maxDate;
        }

        // value
        const valueMin = this.localFilters.value_min;
        const valueMax = this.localFilters.value_max;
        const orderValue = shipment.value ? parseFloat(shipment.value) : 0;
        if (valueMin) {
          found = found && orderValue >= parseFloat(valueMin);
        }
        if (valueMax) {
          found = found && orderValue <= parseFloat(valueMax);
        }

        // weight
        const weightMin = this.localFilters.weight_min;
        const weightMax = this.localFilters.weight_max;
        const orderWeight = shipment.value ? parseFloat(shipment.weight) : 0;
        if (weightMin) {
          found = found && orderWeight >= parseFloat(weightMin);
        }
        if (weightMax) {
          found = found && orderWeight <= parseFloat(weightMax);
        }

        return found;
      });
    }
  }
};
</script>
