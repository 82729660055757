<template>
  <v-container
    grid-list-md
    mb-12
  >
    <v-dialog
      v-model="uploadLoading"
      persistent
      width="300"
    >
      <v-card dark>
        <v-card-text>
          <v-layout
            justify-center
            class="pt-3"
          >
            <AtomSpinner
              :animation-duration="2000"
              :size="100"
              :color="'#f9ac40'"
            />
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card outlined>
      <v-card-text>
        <h3>Import From a File</h3>
        <p>Upload a file to import partial addresses to get rate estimate for clients.</p>

        <v-row>
          <v-col
            cols="6"
            md="6"
          >
            <user-search
              v-model="userId"
              label="Search User"
            />
          </v-col>


          <v-col
            cols="6"
            md="6"
          >
            <v-autocomplete
              v-model="postageTypeIds"
              v-validate="'required'"
              multiple
              outlined
              dense
              :error-messages="errs.collect('postageTypeIds')"
              data-vv-name="postageTypeIds"
              prepend-inner-icon="mdi-postage-stamp"
              :items="postageTypes"
              item-value="id"
              item-text="description"
              label="Postage Type"
              clearable
            >
              <template #selection="{ item, index }">
                <span v-if="index === 0">
                  <span>{{ item.description }}</span>
                </span>
                <span
                  v-if="index === 1"
                  class="grey--text text-caption"
                >
                  (+{{ postageTypeIds.length - 1 }} others)
                </span>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="6"
            md="6"
          >
            <v-select
              v-model="format"
              v-validate="'required'"
              clearable
              :error-messages="errs.collect('format')"
              data-vv-name="format"
              label="Import Template Format"
              :items="['Import Template (Customer)', 'Import Template (Employee)']"
              dense
              outlined
            />
          </v-col>
          

          <v-col
            cols="4"
            md="4"
          >
            <v-file-input
              v-model="file"
              dusk="import_file"
              label="File input"
              accept=".txt, .csv, .xml"
              outlined
              dense
            />
          </v-col>

          <v-col
            cols="2"
            md="2"
          >
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  color="primary"
                  :disabled="disabledUpload"
                  v-on="on"
                  @click="uploadFile()"
                >
                  <v-icon small>
                    mdi-upload
                  </v-icon>Upload
                </v-btn>
              </template>
              <span>Submit your file to begin the import</span>
            </v-tooltip>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <p>Download and fill out the rate estimate templates:</p>
            <ul
              class="my-1"
            >
              <li>
                <a
                  download
                  class="import-list"
                  href="/templates/import/Stallion_Express_Import_Rate_Estimate.xlsx"
                >
                  Default Stallion Rate Estimate Template
                </a>
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { AtomSpinner } from 'epic-spinners'

export default {
  components: {
    AtomSpinner
  },
  data() {
    return {
      format: null,
      file: null,
      filename: '',
      form: new FormData(),
      uploadDisabled: false,
      uploadLoading: false,
      userId: null,
      postageTypeIds: [],
      postageTypes: [],
    };
  },
  computed: {
    disabledUpload() {
      return !this.userId || this.postageTypeIds.length === 0 || !this.file || this.uploadLoading
    }
  },
  watch: {
    userId: function (val) {
      if (val) {
        this.postageTypeIds = [],
        this.getPostageTypes();
      }
    },
  },
  methods: {
    getPostageTypes() {
      this.loading = true;
      this.$http
        .get(`/admin/postage-types?user_id=${this.userId}`)
        .then((response) => response.data)
        .then((response) => {
          this.postageTypes = response;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    uploadFile() {
      this.uploadLoading = true;
      this.form.append('user_id', this.userId);
      this.form.append('format', this.format);
      this.form.append('import_file', this.file);
      this.postageTypeIds.forEach((id) => {
        this.form.append('postage_type_ids[]', id);
      });

      this.$http
        .post('/admin/rate-estimates/upload', this.form)
        .then((res) => {
          console.log(123, res.data)
          if (res.data.success) {
            swal({
              title: 'Success',
              text: res.data.message || 'Rate Estimates are being processed. You will be notified once the process is complete.',
              icon: 'success'
            });
          } else {
            swal({
              title: 'Error',
              text: res.data.message || 'Unknown error occurred. Please try again.',
              icon: 'error'
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errorMessage(err.response.data.errors);
          } else {
            swal({
              title: 'Error',
              text: 'Unknown error occurred. Please try again.',
              icon: 'error'
            });
          }
        })
        .finally(() => {
          this.file = null;
          this.uploadLoading = false
        });
    }
  }
};
</script>
