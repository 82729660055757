<template>
  <FullPageLayout title="Outbound Trips">
    <template #default>
      <v-layout>
        <v-flex xs12>
          <v-data-table
            v-model="selected"
            class="table-striped"
            :headers="headers"
            :items="items"
            :loading="loading"
            item-key="id"
            :server-items-length="totalItems"
            :footer-props="{
              'items-per-page-options': [50, 100, 200, 500]
            }"
            :page.sync="pagination.page"
            :items-per-page.sync="pagination.itemsPerPage"
            :sort-by.sync="pagination.sortBy"
            :sort-desc.sync="pagination.descending"
            @click:row="rowClick"
          >
            <template #item.created_at="{ item }">
              {{ item.created_at | date_pretty }}
              <br>
              ({{ item.created_at | datetime_from }})
            </template>

            <template #item.valid="{ item }">
              <span v-if="item.valid == null">Processing</span>
              <span v-else-if="item.valid == 0">Error</span>
              <span v-else-if="item.valid == 1">Complete</span>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </template>
  </FullPageLayout>
</template>

<script>
export default {
  data() {
    return {
      showDeleteDialog: false,
      showDisposeDialog: false,
      showRequestDialog: false,
      search: null,
      start_date: null,
      end_date: null,
      loading: false,
      menu1: null,
      menu2: null,
      timer: null,
      authRequest: false,

      headers: [
        {
          text: 'Trip #',
          value: 'trip_number'
        },
        {
          text: 'Shipments',
          value: 'shipment_count'
        },
        {
          text: 'Status',
          value: 'valid'
        },
        {
          text: 'Created At',
          value: 'created_at'
        }
      ],
      items: [],
      totalItems: null,
      numPages: null,
      pagination: {
        page: 1,
        itemsPerPage: 10,
        sortBy: null,
        descending: false
      }
    };
  },

  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },

  watch: {
    pagination: {
      immediate: true,
      handler() {
        this.updateReturns();
      },
      deep: true
    }
  },
  mounted() {
    console.log(this.$route);
  },

  methods: {
    rowClick(item) {
      this.$router.push({
        name: 'admin-outbound-trip-show',
        params: {
          tripNumber: item.trip_number
        }
      });
    },
    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.pagination.page = 1;
      this.timer = setTimeout(function() {
        self.updateReturns();
      }, ms);
    },

    updateReturns() {
      this.items = [];
      this.totalItems = 0;
      this.selected = [];
      this.getDataFromApi().then(data => {
        this.items = data.items;
        this.totalItems = data.total;
        this.numPages = data.pages;
      });
    },

    getDataFromApi() {
      this.loading = true;
      return new Promise(resolve => {
        const { sortBy, descending, page, itemsPerPage } = this.pagination;

        let items = [];
        let total = 0;
        let pages = 0;

        var params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sortBy,
          desc: descending,
          search: this.search,
          start_date: this.start_date,
          end_date: this.end_date,
          filter: this.filter
        };

        this.$http
          .get(`/admin/outbound-trips`, {
            method: 'get',
            params: params
          })
          .then(response => response.data)
          .then(response => {
            items = response.data;
            total = response.total;
            pages = response.last_page;

            this.loading = false;
            resolve({
              items,
              total,
              pages
            });
          });
      });
    }
  }
};
</script>
