<template>
  <FullPageLayout title="Claims">
    <template #default>
      <claims-table :filter="filter" />
    </template>
    <template #footer>
      <v-btn
        color="primary"
        to="/claims/new"
      >
        New Claim
      </v-btn>
    </template>
  </FullPageLayout>
</template>

<script>
export default {
  metaInfo: {
    title: "Claims | Stallion Express"
  },
  props: {
    filter: {
      default: null,
      type: String
    }
  }
};
</script>
