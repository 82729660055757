<template>
  <div>
    <v-form @submit.prevent>
      <v-container fluid>
        <v-row align="center">
          <v-col cols="6">
            <v-text-field
              v-model="claim.shipment_id"
              v-validate="'required'"
              outlined
              :error-messages="errs.collect('shipment_id')"
              label="Shipment ID"
              data-vv-name="shipment_id"
              persistent-hint
              hint="Ship code or tracking number"
            />
          </v-col>

          <v-col cols="6">
            <v-select
              v-model="claim.type"
              v-validate="'required'"
              outlined
              :items="types"
              label="Claim Type"
              :error-messages="errs.collect('type')"
              data-vv-name="type"
            />
          </v-col>
        </v-row>

        <v-row align="center">
          <v-col cols="6">
            <v-file-input
              v-model="claim.invoice"
              v-validate="'required'"
              outlined
              :prepend-icon="false"
              prepend-inner-icon="mdi-paperclip"
              accept="image/png, image/jpeg, application/pdf"
              label="Supporting Invoice"
              :error-messages="errs.collect('invoice')"
              data-vv-name="invoice"
              persistent-hint
              hint="A commercial invoice is required detailing the retail value and currency, seller and buyer information, and date of transaction."
            />
          </v-col>

          <v-col
            v-if="claim.type == 'Lost'"
            cols="6"
          >
            <v-file-input
              v-model="claim.additional_documents"
              v-validate="''"
              outlined
              multiple
              :prepend-icon="false"
              prepend-inner-icon="mdi-paperclip"
              accept="image/png, image/jpeg, application/pdf"
              label="Additional Documentation"
              :error-messages="errs.collect('additional_documents')"
              data-vv-name="additional_documents"
              persistent-hint
              hint=""
            />
          </v-col>

          <v-col
            v-if="claim.type == 'Damaged'"
            cols="6"
          >
            <v-file-input
              v-model="claim.photos"
              v-validate="'required'"
              outlined
              multiple
              :prepend-icon="false"
              prepend-inner-icon="mdi-camera"
              accept="image/jpeg, image/png"
              label="Photos of Damage"
              :error-messages="errs.collect('photos')"
              data-vv-name="photos"
            />
          </v-col>

          <v-col
            v-if="claim.type == 'Damaged'"
            cols="6"
          >
            <v-file-input
              v-model="claim.proof_of_refund_or_replacement"
              v-validate="'required'"
              outlined
              multiple
              :prepend-icon="false"
              prepend-inner-icon="mdi-camera"
              accept="image/jpeg, image/png"
              label="Proof of Refund/Replacement"
              :error-messages="errs.collect('proof_of_refund_or_replacement')"
              data-vv-name="proof_of_refund_or_replacement"
            />
          </v-col>

          <v-col
            v-if="claim.type == 'Damaged'"
            cols="6"
          >
            <v-alert
              type="warning"
              dense
            >
              Please note that for damaged items, proof of a refund being issued
              to the recipient or proof of replacement is required.
            </v-alert>
          </v-col>
        </v-row>

        <v-row align="center">
          <v-col cols="6">
            <v-text-field
              v-model.trim="claim.contents"
              v-validate="'required|min:5'"
              outlined
              :error-messages="errs.collect('contents')"
              label="Package Contents"
              data-vv-name="contents"
            />
          </v-col>

          <v-col cols="3">
            <v-text-field
              v-model="claim.value"
              v-validate="'required|min_value:0.1'"
              outlined
              :error-messages="errs.collect('value')"
              label="Value"
              data-vv-name="value"
              type="number"
              persistent-hint
              hint="Values will be converted to CAD."
            />
          </v-col>

          <v-col cols="3">
            <v-select
              v-model="claim.currency"
              v-validate="'required'"
              outlined
              :items="currencies"
              label="Currency"
              :error-messages="errs.collect('currencies')"
              data-vv-name="currency"
            />
          </v-col>

          <v-col cols="12">
            <v-textarea
              v-model.trim="claim.reason"
              v-validate="'required|min:50'"
              outlined
              :error-messages="errs.collect('reason')"
              label="Reason for Claim"
              data-vv-name="reason"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
const DEFAULT_CLAIM = {
  shipment_id: null,
  type: 'Lost',
  reason: null,
  value: null,
  contents: null,
  photos: null,
  invoice: null,
  proof_of_refund_or_replacement: null,
  additional_documents: null,
  currency: 'CAD',
};

export default {
  props: {
    filter: {
      default: null,
      type: String
    },
    shipment: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      claim: { ...DEFAULT_CLAIM },
      types: [
        {
          text: 'Damaged Item',
          value: 'Damaged'
        },
        {
          text: 'Lost Item',
          value: 'Lost'
        }
      ],
      currencies: [
        {
          text: 'USD',
          value: 'USD'
        },
        {
          text: 'CAD',
          value: 'CAD'
        },
      ]
    };
  },
  watch: {
    shipment: {
      deep: true,
      handler() {
        if (this.shipment.id) {
          this.initialize();
        }
      }
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$validator.reset();
      if (this.shipment.claimable) {
        this.claim = {
          ...DEFAULT_CLAIM,
          shipment_id: this.shipment.ship_code,
          value: this.shipment.value,
          contents: this.shipment.package_contents
        };
      }
    },

    async validate() {
      return await this.$validator.validate().then((result) => {
        return result ? true : false;
      });
    },

    submit() {
      let formData = new FormData();

      (this.claim.photos || []).forEach((photo) => {
        formData.append('photos[]', photo);
      });

      (this.claim.proof_of_refund_or_replacement || []).forEach((photo) => {
        formData.append('photos[]', photo);
      });

      (this.claim.additional_documents || []).forEach((photo) => {
        formData.append('additional_documents[]', photo);
      });

      if (this.claim.invoice) {
        formData.append('invoice', this.claim.invoice);
      }

      formData.append('shipment_id', this.claim.shipment_id);
      formData.append('type', this.claim.type);
      formData.append('reason', this.claim.reason);
      formData.append('value', this.claim.value);
      formData.append('currency', this.claim.currency);
      formData.append('contents', this.claim.contents);

      return new Promise((resolve, reject) => {
        this.$http
          .post('/claims', formData)
          .then((response) => response.data)
          .then((response) => {
            swal({
              title: 'Success',
              text: 'Your claim has been submitted.',
              icon: 'success'
            });

            resolve(response);
          })
          .catch((err) => {
            if (err.response.status === 500) {
              swal({
                title: 'Error',
                text: 'Unknown error',
                icon: 'error'
              });
            }

            reject(err);
          });
      });
    }
  }
};
</script>
