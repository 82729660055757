<template>
  <StallionDialog
    v-model="dialogDisplay"
    :width="500"
    :disabled="dialogDisabled"
    title="Add Credits"
  >
    <template #content>
      <v-layout column>
        <v-flex xs12>
          <p>Your payment will be processed through PayPal.</p>
        </v-flex>

        <v-flex
          xs12
          text-center
          mt-4
          mb-4
        >
          <v-text-field
            v-model="amount"
            v-validate="'required|max_value:10000|min_value:50'"
            prepend-inner-icon="fa-dollar-sign"
            type="number"
            :error-messages="errs.collect('amount')"
            label="Amount (CAD)"
            data-vv-name="amount"
            required
            outlined
          />
        </v-flex>
      </v-layout>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        :loading="processing"
        :disabled="processing"
        @click="payWithPaypal"
      >
        Pay {{ amount | currency }}
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    disabled: {
      default: false,
      type: Boolean
    }
    // display: {
    //   default: false,
    //   type: Boolean
    // }
  },

  data() {
    return {
      dialogDisplay: false,
      dialogDisabled: this.disabled,

      processing: false,
      amount: '',
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        suffix: ' ',
        precision: 2,
        masked: false
      }
    };
  },

  methods: {
    openDialog() {
      this.dialogDisplay = true
    },
    payWithPaypal() {
      this.$validator.validate().then(result => {
        if (result) {
          this.processing = true;
          this.$http
            .post('/paypal/process', {
              amount: this.amount
            })
            .then(res => {
              if (res.data.success) {
                Event.fire('payment-completed', res.data.credits);
                this.dialogDisplay = false;

                swal({
                  title: 'Redirecting to PayPal',
                  text:
                    'You are being redirected to Paypal to complete your transaction.',
                  icon: 'info'
                });
                window.location.href = res.data.redirect;
              }
              this.processing = false;
              this.amount = null;
            })
            .catch(err => {
              if (err.response.status == 422) {
                this.errorMessage(err.response.data.error);
              } else {
                this.errorMessage('The transaction could not be processed');
              }
            })
            .finally(() => {
              this.processing = false;
            });
        }
      });
    }
  }
};
</script>
