import axios from 'axios';

function initialState() {
  return {
    open_batches: []
  };
}

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    openBatchesOptions: (state) => {
      var batches = [
        {
          text: 'New Batch',
          value: null,
          name: null
        }
      ];

      state.open_batches.forEach(function (b) {
        var name = b.id;

        if (b.name != null) {
          name += ` - ${b.name}`;
        }
        batches.push({
          text: name,
          value: b.id,
          name: b.name
        });
      });

      return batches;
    },
    openBatchIds: (state) => {
      var ids = [];
      state.open_batches.forEach(function (b) {
        ids.push(b.id);
      });

      return ids;
    }
  },
  actions: {
    getOpenBatches({ commit }) {
      axios.get('/batches/open').then((response) => {
        commit('updateBatches', response.data);
      });
    }
  },
  mutations: {
    reset(state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    clearAll(state) {
      state.items = [];
    },
    updateBatches(state, batches) {
      state.open_batches = batches;
    }
  }
};
