<template>
  <StallionDialog
    v-model="show"
    :width="500"
    :disabled="disabled"
    title="Print Batch Label"
  >
    <!-- <v-btn
      v-if="button"
      color="primary"
      :disabled="disabled"
      v-on="on"
    >
      <v-icon
        small
        dark
      >
        mdi-printer
      </v-icon>Delete
    </v-btn> -->

    <v-card>
      <v-layout column>
        <v-flex
          xs12
          text-center
        >
          <h3>
            {{ labelsCount }} batch
            {{ labelsCount | pluralize(' label') }} ready to be printed
          </h3>
        </v-flex>
      </v-layout>
    </v-card>

    <template #actions>
      <v-btn
        color="primary"
        :loading="print_loading"
        :disabled="print_loading"
        @click="remove()"
      >
        <v-icon
          small
          dark
        >
          mdi-printer
        </v-icon>
        Delete
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    batches: {
      type: Array,
      default: () => []
    },
    disabled: {
      default: false,
      type: Boolean
    },
    button: {
      default: true,
      type: Boolean
    },
    show: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      print_loading: false,
      dowload_loading: false
    };
  },
  computed: {
    labelsCount: function() {
      return this.batches.length;
    }
  },
  mounted() {},
  methods: {
    remove: async function() {
      console.log('remove');
      this.delete_loading = true;

      var self = this;
      await Promise.all(
        this.batches.map(async (batch, index) => {
          console.log('loop: ' + index);

          await self.delete(batch.id).then(() => {
            console.log('then: ' + index);

            self.number++;
          });
        })
      );

      this.delete_loading = false;
      this.show = null;
      this.number = 0;

      Event.fire('update-batches');
    },

    delete: function(val) {
      return new Promise(resolve => {
        console.log('promise: ' + val);
        this.$http
          .delete('/batches/' + val)
          .then(response => {
            resolve({
              response
            });
          })
          .catch(err => {
            resolve({
              err
            });
          });
      });
    }
  }
};
</script>
