<template>
  <div>
    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-btn
          small
          text
          color="primary"
          icon
          :loading="loading"
          @click="$refs.searchDialog.startNew()"
          v-on="on"
        >
          <v-icon>
            mdi-map-search
          </v-icon>
        </v-btn>
      </template>
      <span>My Addresses</span>
    </v-tooltip>
   
    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-btn
          text
          color="primary"
          icon
          :loading="loading"
          @click="saveAddressBook"
          v-on="on"
        >
          <v-icon
            left
          >
            mdi-content-save
          </v-icon>
        </v-btn>
      </template>
      <span>Save Address</span>
    </v-tooltip>


    <search-dialog
      ref="searchDialog"
      @selectAddressBook="selectAddressBook"
    />
  </div>
</template>

<script>
import SearchDialog from './SearchDialog.vue';

export default {
  components: {
    SearchDialog,
  },
  props: {
    addressToSave: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    //
  },
  methods: {
    selectAddressBook(address) {
      this.$emit('selectAddressBook', address);
    },
    saveAddressBook() {
      this.loading = true;
      this.$http
        .post('/address-books', this.addressToSave)
        .then((response) => response.data)
        .then((response) => {
          if (response.success) {
            this.processSuccess(response, 'Address has been stored.');
          } else {
            this.errorMessage(response.message);
          }
        })
        .catch((err) => {
          this.processError(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    processSuccess(response, message) {
      swal({
        title: 'Success',
        text: message,
        icon: 'success'
      });
    },
    processError(err) {
      if (
        err && err.response &&
        err.response.status === 422 &&
        typeof err.response.data.errors !== 'undefined'
      ) {
        const errors = err.response.data.errors;
        let message = '';

        for (var field in errors) {
          message += `${errors[field][0]}\n`;
        }
        this.errorMessage(message);
      } else {
        this.errorMessage('An error occurred while processing your request');
      }
    }
  }
};
</script>
