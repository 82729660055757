<template>
  <StallionDialog
    v-model="show"
    :width="600"
    :disabled="disabled"
    class="dialog-slot"
    title="Amazon"
  >
    <template #content>
      <v-row dense>
        <!-- Suggestion and link to the Amazon Store Acceptable Use Policy -->
        <v-col
          cols="12"
          class="text-center"
        >
          <p>
            You must accept the
            <a
              href="/pdfs/policies/stallion_amazon_store_acceptable_use_policy.pdf"
              target="_blank"
            >
              Amazon Store Acceptable Use Policy
            </a>
            to continue.
          </p>
        </v-col>

        <!-- Checkbox for accepting the policy -->
        <v-col
          cols="12"
          class="text-center"
        >
          <v-checkbox
            v-model="accepted_policy"
            label="I accept the Amazon Store Acceptable Use Policy"
            color="primary"
          />
        </v-col>

        <v-col
          cols="12"
          class="text-center"
          :style="{ opacity: accepted_policy ? 1 : 0.5 }"
        >
          Please select the marketplace region for your store. You will be
          redirected to your seller central account.
        </v-col>

        <v-col>
          <v-select
            v-model="marketplace_id"
            item-text="text"
            item-value="value"
            :items="marketplaces"
            label="Marketplace"
            :disabled="!accepted_policy"
            :style="{ opacity: accepted_policy ? 1 : 0.5 }"
          />
        </v-col>
      </v-row>
    </template>

    <template #actions>
      <v-btn
        type="submit"
        color="primary"
        :loading="print_loading"
        :disabled="print_loading || !accepted_policy"
        :style="{ opacity: accepted_policy ? 1 : 0.5 }"
        @click="connect()"
      >
        Connect
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      print_loading: false,
      seller_id: null,
      token: null,
      marketplace_id: 'A2EUQ1WTGCTBG2', // Default marketplace ID
      accepted_policy: false, // Tracks if the user has accepted the Amazon Store policy
      marketplaces: [
        {
          value: 'A2EUQ1WTGCTBG2',
          text: 'Canada'
        },
        {
          value: 'ATVPDKIKX0DER',
          text: 'United States'
        },
        {
          value: 'A1AM78C64UM0Y8',
          text: 'Mexico'
        }
        // {
        //   value: 'A39IBJ37TRP1C6',
        //   text: 'Australia'
        // },
        // {
        //   value: 'A1PA6795UKMFR9',
        //   text: 'Germany'
        // },
        // {
        //   value: 'A1F83G8C2ARO7P',
        //   text: 'UK'
        // }
      ]
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    connect: function () {
      this.$validator.validate().then((result) => {
        if (result) {
          this.print_loading = true;
          this.$http
            .post(`/stores/amazon`, {
              seller_id: this.seller_id,
              token: this.token,
              marketplace_id: this.marketplace_id
            })
            .then((response) => response.data)
            .then((response) => {
              window.location = response.redirect;
              //this.$store.dispatch('userStores/getStores');
              //this.show = false;

              //              this.successMessage(
              //              'Your account for amazon has been added successfully. Please note it can up to 5 minutes after adding you store to pull all your shipments.'
              //          );
            })
            .catch((err) => {
              if (err.response && err.response.status === 422) {
                this.$notify({
                  group: 'main',
                  title: 'Invalid Credentials',
                  text: err.response.data.errors,
                  duration: 5000,
                  type: 'error'
                });
              } else {
                this.$notify({
                  group: 'main',
                  title: 'Error adding Amazon',
                  text: err,
                  duration: 5000,
                  type: 'error'
                });
              }
            })
            .finally(() => {
              this.print_loading = false; // Stop loading spinner
            });
        }
      });
    }
  }
};
</script>
