<template>
  <v-row
    v-if="user.id"
    dense
  >
    <v-col 
      cols="12"
    >
      <v-switch
        v-model="commissionData.enabled"
        :label="commissionData.enabled ? 'Enabled' : 'Disabled'"
      />
    </v-col>

    <template v-if="commissionData.enabled">
      <v-col cols="12">
        <p>
          Use a custom commission structure for this user when a new user registers using their referral link.
        </p>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="user.email"
          :disabled="true"
          label="Earner"
          outlined
          dense
        />
      </v-col>

      <v-col cols="6">
        <v-select
          v-model="commissionData.payout_method"
          v-validate="'required'"
          :error-messages="errs.collect('payout_method')"
          data-vv-name="payout_method"
          :items="payoutMethods"
          label="Payout Method"
          outlined
          dense
        />
      </v-col>

      <v-col cols="6">
        <v-select
          v-model="commissionData.type"
          v-validate="'required'"
          :error-messages="errs.collect('type')"
          data-vv-name="type"
          :items="commissionTypes"
          label="Commission Rate Type"
          item-text="label"
          item-value="key"
          outlined
          dense
        />
      </v-col>

      <v-col cols="6">
        <v-text-field
          v-model="commissionData.amount"
          v-validate="commissionData.type == 'Fixed' ? 'required|decimal:2|min_value:0.01|max_value:5' : 'required|decimal:2|min_value:0.01|max_value:100' "
          :label="commissionData.type == 'Fixed' ? '$ Per Shipment' : '% of Margin'"
          type_id="number"
          :error-messages="errs.collect('amount')"
          data-vv-name="amount"
          required
          outlined
          dense
        />
      </v-col>
    </template>

    <v-col>
      <v-btn
        color="primary"
        :loading="loading"
        :disabled="loading"
        @click.native="submit()"
      >
        Save
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      commissionData: {
        enabled: false,
        group: 'Client',
        payout_method: 'Credits',
        type: 'Fixed',
        amount: 0.1,
        start_date: moment(new Date()).format('YYYY-MM-DD'),
        end_date: moment(new Date()).format('YYYY-MM-DD'),
        description: null
      },
      loading: false,
      menuStartDate: null,
      menuEndDate: null,

      commissionTypes: [
        {
          key: 'Fixed',
          label: 'Fix $ per shipment'
        },
        {
          key: 'Percent',
          label: '% of margin per shipment'
        }
      ],
      payoutMethods: ['Credits', 'Cheque']
    };
  },

  mounted() {
    this.getReferralCommissionStructure()
    this.getLatestReferral()
  },

  methods: {
    getReferralCommissionStructure() {
      if (!this.user.id) {
        return
      }

      this.loading = true
      this.$http
        .get(`/admin/referral-commission-structure?earner_id=${this.user.id}`)
        .then((res) => {
          if (res.data) {
            this.commissionData = res.data
          }
        })
        .finally(() => {
          this.loading = false
        });
    },

    getLatestReferral() {
      if (!this.user.id) {
        return
      }

      this.loading = true
      this.$http
        .get(`/referrals/latest?referrer_id=${this.user.id}`)
        .then((res) => {
          const data = res.data
          if (!this.commissionData.id && data && data.created_at) {
            const date = moment(new Date(data.created_at))
            this.commissionData.start_date = date.format('YYYY-MM-DD')
            this.commissionData.end_date = date.add(3, 'months').format('YYYY-MM-DD')
          }
        })
        .finally(() => {
          this.loading = false
        });
    },

    async validateForm() {
      return await this.$validator.validate().then((result) => {
        return result ? true : false;
      });
    },

    async submit() {
      if (!(await this.validateForm())) {
        this.$notify({
          group: 'main',
          title: 'Missing Fields',
          text: this.errs.all().join('. '),
          duration: 5000,
          type: 'error'
        });

        return;
      }

      this.commissionData.earner_id = this.user.id

      this.saveCommission(this.commissionData)
    },

    saveCommission(payload) {
      this.loading = true
      this.$http
        .post(`/admin/referral-commission-structure`, {
          structures: [payload]
        })
        .then((res) => {
          if (res.data.success) {
            this.successMessage('Commission Structure Updated Successfully!');
            this.commissionData = res.data.data
          } else {
            this.errorMessage();
          }
        })
        .catch((err) => {
          this.onError(err)
        })
        .finally(() => {
          this.loading = false
        });
    },

    onError(err) {
      let errMsg = 'Unknown Error Occured'
      if (err.response.status == 422) {
        errMsg = err.response.data.message
      }

      this.errorMessage(errMsg);
    }
  }
};
</script>
