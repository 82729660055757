<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-btn
          color="primary"
          dark
          @click="$refs.listCriteriaDialog.startNew()"
        >
          Create List
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table
          class="table-striped"
          :headers="headers"
          :items="items"
          :loading="loading"
          :items-per-page="10"
        >
          <template #item.field="{ item }">
            {{ getFieldName(item) }}
          </template>

          
          <template #item.actions="{ item }">
            <v-btn
              :loading="loading"
              small
              color="red"
              text
              @click="deleteList(item.id)"
            >
              Delete
            </v-btn>
            <v-btn
              :loading="loading"
              small
              color="primary"
              text
              @click="$refs.listCriteriaDialog.startEdit(item)"
            >
              Edit
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <list-criteria-dialog
      ref="listCriteriaDialog"
      @update-lists="updateTable()"
    />
  </div>
</template>

<script>
import ListCriteriaDialog from './ListCriteriaDialog.vue';
import FILTERS from '../../../shared/filters';
export default {
  components: {
    ListCriteriaDialog
  },
  data() {
    return {
      list: {},
      show_edit: false,
      loading: false,
      items: [],
      options: {},
      headers: [
        { text: 'Field', value: 'field', sortable: false},
        { text: 'List Name', value: 'name', sortable: false },
        { text: 'Actions', value: 'actions', align: 'right', sortable: false }
      ]
    };
  },
  watch: {
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    },
    'options.itemsPerPage': function (newVal, oldVal) {
      if (oldVal && newVal) {
        const preference = {
          table_rows: {
            filters: newVal
          }
        };
        this.$store.dispatch('main/setPreferences', preference);
      }
    }
  },

  mounted() {
    this.updateTable();

    const table_rows = this.$store.getters['main/table_rows'];
    this.options.itemsPerPage = table_rows?.filters ?? 5;
  },

  methods: {
    getFieldName(list) {
      return FILTERS.getCriterionName(list.field)
    },
    updateTable() {
      this.totalItems = 0;
      this.items = [];

      this.loading = true
      this.$http
        .request(`/list-criteria`, {
          method: 'get'
        })
        .then((response) => response.data)
        .then((response) => {
          this.items = response;
        })
        .finally(() => {
          this.loading = false
        });
    },
    async deleteList(id) {
      const isConfirm = await swal({
        title: 'Confirm Delete',
        text: 'Do you really want to delete this list?',
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      });

      if (isConfirm) {
        try {
          this.loading = true
          await this.$store.dispatch('listCriteria/deleteList', id);
          this.successMessage('List successfully deleted!');
          this.updateTable();
        } catch (e) {
          this.errorMessage()
        }

        this.loading = false
      }
    },
    showUpdate(list) {
      this.show_edit = true;
      this.list = list;
    },
    showCreate() {
      this.show_edit = true;
      this.list = null;
    }
  }
};
</script>
