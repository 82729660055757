<template>
  <FullPageLayout title="Rack Return Labels">
    <template #default>
      <v-layout wrap>
        <v-flex xs6>
          <v-card outlined>
            <v-card-text>
              <v-text-field
                v-model="rack"
                v-validate="'required'"
                :error-messages="errs.collect('startCount')"
                data-vv-name="startCount"
                label="Rack Number"
              />
              <v-select
                v-model="rows"
                :items="items"
                label="Rows"
              />
              <!-- <v-text-field
                v-model="startCount"
                type="number"
                required
                step="1"
                min="1"
                label="Number of Labels"
              ></v-text-field>-->
              <v-btn
                color="primary"
                :disabled="generateLoading"
                :loading="generateLoading"
                @click="generateLabels()"
              >
                Generate
              </v-btn>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </template>
  </FullPageLayout>
</template>

<script>
import moment from 'moment';
export default {
  data() {
    return {
      labelCount: 100,
      generateLoading: false,
      menu1: false,
      genDate: moment().format('YYYY-MM-DD'),
      rack: 1,
      rows: 1,
      items: [
        { value: 1, text: 'A (1)' },
        { value: 2, text: 'B (2)' },
        { value: 3, text: 'C (3)' },
        { value: 4, text: 'D (4)' },
        { value: 5, text: 'E (5)' },
        { value: 6, text: 'F (6)' },
        { value: 7, text: 'G (7)' },
        { value: 8, text: 'H (8)' },
        { value: 9, text: 'I (9)' },
        { value: 10, text: 'J (10)' },
        { value: 11, text: 'K (11)' },
        { value: 12, text: 'L (12)' },
        { value: 13, text: 'M (13)' },
        { value: 14, text: 'N (14)' },
        { value: 15, text: 'O (15)' },
        { value: 16, text: 'P (16)' },
        { value: 17, text: 'Q (17)' },
        { value: 18, text: 'R (18)' },
        { value: 19, text: 'S (19)' },
        { value: 20, text: 'T (20)' },
        { value: 21, text: 'U (21)' },
        { value: 22, text: 'V (22)' },
        { value: 23, text: 'W (23)' },
        { value: 24, text: 'X (24)' },
        { value: 25, text: 'Y (25)' },
        { value: 26, text: 'Z (26)' }
      ]
    };
  },

  methods: {
    generateLabels() {
      this.$validator.validate().then(result => {
        if (result) {
          this.generateLoading = true;

          // var count = this.labelCount;
          // var genDate = this.genDate;
          // var startCount = this.startCount;
          // var endCount = this.endCount;

          this.$http({
            method: 'post',
            url: '/admin/rack-labels',
            data: { rack: this.rack, rows: this.rows }
          })

            .then(response => {
              this.downloadFileUrl(response.data, 'RackLabel.pdf');
            })
            .finally(() => {
              this.generateLoading = false;
            });
        } else {
          swal({
            title: 'Error',
            text: 'Some of the form fields are invalid.',
            icon: 'error'
          });
        }
      });
    }
  }
};
</script>
