<template>
  <v-flex
    v-if="shipment.applied_automation?.length > 0"
    xs12
  >
    <h4 v-if="showHeader">
      Applied Automation
    </h4>
    <div
      v-for="(automation, index) in shipment.applied_automation"
      :key="index"
    >
      <div class="shipment-title">
        Name
      </div>
      <strong>{{ automation.name }}</strong>

      <div class="shipment-title">
        Criteria
      </div>
      <div
        v-for="(values, criteria) in automation.criteria"
        :key="criteria"
      >
        <strong>{{ criteria }}</strong>
        <ul>
          <li
            v-for="(value, index2) in values"
            :key="index2"
          >
            {{ value }}
          </li>
        </ul>
      </div>

      <div class="shipment-title">
        Action
      </div>
      <div
        v-for="(values, action) in automation.actions"
        :key="action"
      >
        <strong>{{ action }}</strong>
        <ul>
          <li
            v-for="(value, index3) in values"
            :key="index3"
          >
            {{ value }}
          </li>
        </ul>
      </div>
      <v-divider v-if="showDividerAction(shipment.applied_automation)" />
    </div>

    <v-divider
      v-if="showDivider"
      class="details-divider"
    />
  </v-flex>
</template>

<script>
export default {
  props: {
    shipment: {
      type: Object,
      default: null
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    showDivider: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    showDividerAction(appliedAutomation) {
      return appliedAutomation && appliedAutomation.length > 2;
    }
  }
};
</script>
