<template>
  <div>
    <StallionDialog
      v-model="show"
      :width="600"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      :title="getDialogTitle()"
      fluid
      :show-actions="false"
      @onDialogClosed="closeSingleFlow()"
    >
      <template #content>
        <v-stepper v-model="step">
          <v-stepper-header>
            <v-stepper-step
              v-if="shipment.return_label"
              :editable="step > 0 && step < 7 && !$store.getters['singleFlow/loading']"
              :complete="step > 0"
              :step="0"
            >
              From
            </v-stepper-step>
            <v-divider v-if="shipment.return_label" />

            <v-stepper-step
              :editable="step > 1 && step < 7 && !$store.getters['singleFlow/loading']"
              :complete="step > 1"
              :step="1"
            >
              Recipient
            </v-stepper-step>
            <v-divider />
            <v-stepper-step
              :editable="step > 2 && step < 7 && !$store.getters['singleFlow/loading']"
              :complete="step > 2"
              :step="2"
            >
              Verify Address
            </v-stepper-step>
            <v-divider />
            <v-stepper-step
              :editable="step > 3 && step < 7 && !$store.getters['singleFlow/loading']"
              :complete="step > 3"
              step="3"
            >
              Contents
            </v-stepper-step>
            <v-divider />
            <v-stepper-step
              :editable="step > 4 && step < 7 && !$store.getters['singleFlow/loading']"
              :complete="step > 4"
              step="4"
            >
              Package Details
            </v-stepper-step>
            <v-divider />
            <v-stepper-step
              :editable="step > 5 && step < 7 && !$store.getters['singleFlow/loading']"
              :complete="step > 5"
              step="5"
            >
              Postage
            </v-stepper-step>
            <v-divider />
            <v-stepper-step
              :editable="step > 6 && step < 7 && !$store.getters['singleFlow/loading']"
              :complete="step > 6"
              step="6"
            >
              Summary
            </v-stepper-step>
            <!-- <v-divider></v-divider>
            <v-stepper-step :complete="step == 7" step="7"
              >Complete</v-stepper-step
            >-->
          </v-stepper-header>
        </v-stepper>
        <v-stepper
          v-if="show"
          v-model="step"
          style="background-color: rgba(248,248,248,.5); min-height: 1000px"
        >
          <v-stepper-items>
            <div
              v-if="$store.getters['singleFlow/loading']"
              class="loading-overlay"
            >
              <div class="loading-content">
                <atom-spinner
                  style=" margin: auto;"
                  :animation-duration="1000"
                  :size="100"
                  :color="'#f9ac40'"
                />
              </div>
            </div>
            <v-container>
              <v-stepper-content step="0">
                <ShipmentFrom />
              </v-stepper-content>
              <v-stepper-content step="1">
                <ShipmentRecipient />
              </v-stepper-content>
              <v-stepper-content step="2">
                <AddressVerification />
              </v-stepper-content>
              <v-stepper-content step="3">
                <Package />
              </v-stepper-content>
              <v-stepper-content step="4">
                <PackageUSPS />
              </v-stepper-content>
              <v-stepper-content step="5">
                <Postage />
              </v-stepper-content>
              <v-stepper-content step="6">
                <Summary />
              </v-stepper-content>
              <v-stepper-content step="7">
                <Complete />
              </v-stepper-content>
            </v-container>
          </v-stepper-items>
        </v-stepper>
      </template>
    </StallionDialog>
  </div>
</template>

<script>
import { AtomSpinner } from 'epic-spinners';

export default {
  components: {
    AtomSpinner
  },
  data() {
    return {
      loaded: false
    };
  },
  computed: {
    shipment: {
      get() {
        return this.$store.state.singleFlow.shipment;
      },
      set(value) {
        this.$store.state.singleFlow.shipment = value;
      }
    },
    show: {
      get() {
        return this.$store.state.singleFlow.single_dialog;
      },
      set(value) {
        this.$store.state.singleFlow.single_dialog = value;
      }
    },
    processing: {
      get() {
        return this.$store.state.singleFlow.processing;
      },
      set(value) {
        this.$store.state.singleFlow.processing = value;
      }
    },
    step: {
      get() {
        return this.$store.state.singleFlow.step;
      },
      set(value) {
        this.$store.state.singleFlow.step = value;
      }
    }
  },
  created() {
    var self = this;

    window.addEventListener('keydown', (e) => {
      if ((this.processing == 0 && this.show && e.key == 'Escape')) {
        this.closeSingleFlow();
      }
    });

    Event.listen('edit-shipment', function(values) {
      self.loaded = true;
      console.log(values);
      if (values.shipment !== null) {
        self.getShipment(values.shipment, values.step);
      }
    });

    Event.listen('close-singleflow', function() {
      self.closeSingleFlow();
    });
  },
  methods: {
    closeSingleFlow: function() {
      this.show = false;
      this.processing = false;
      this.step = 1;

      this.loaded = false;
      this.step = 1;

      this.shipment = {
        id: null,

        weight_unit: 'lbs',
        size_unit: 'in',

        currency: 'USD',
        needs_postage: 1,
        is_fba: 0
      };

      Event.fire('update-shipments');
    },
    stepUpdate(value) {
      if (value === 'from' || value === 0) this.step = 0;

      if (value === 'recipient' || value === 1) this.step = 1;

      if (value === 'address_verification' || value === 2) this.step = 2;

      if (value === 'package' || value === 3) this.step = 3;

      if (value === 'package_usps' || value === 4) this.step = 4;

      if (value === 'postage' || value === 5) this.step = 5;

      if (value === 'summary' || value === 6) this.step = 6;

      if (value === 'complete' || value === 7) this.step = 7;
    },

    getShipment(shipment, step) {
      this.shipment = Object.assign( {
        id: null,

        weight_unit: 'lbs',
        size_unit: 'in',

        currency: 'USD',
        needs_postage: 1,
        is_fba: 0
      }, shipment);

      if (shipment.return_label) {
        step = 'from';
      }

      this.stepUpdate(step);

      console.log(this.step);
    },

    getDialogTitle() {
    
      if (!this.shipment.id) {
        if(this.shipment.is_fba) {
          return 'New FBA Shipment';
        } else {
          return 'New Shipment';
        }
      } else {
        if(this.shipment.is_fba) {
          return 'Edit FBA Shipment';
        } else {
          return 'Edit Shipment';
        }
      }
    }
  }
};
</script>

<style scoped>
.v-stepper__content { transition: none !important; }

/* .theme--light.v-stepper--vertical .v-stepper__content:not(:last-child) {
  border: none;
}
.v-stepper__content {
  margin: 0 !important;
  padding: 0 !important;
} */
</style>
