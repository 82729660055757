<template>
  <div>
    <!-- <div v-if="breadCrumbs">
      <v-breadcrumbs large :items="breadCrumbs" />
    </div> -->

    <v-navigation-drawer
      v-model="drawer"
      style="z-index: 1; padding: bottom: 100px"
      right
      :clipped="$vuetify.breakpoint.mdAndUp"
      fixed
      app
      width="350"
    >
      <v-container class="mb-100">
        <slot name="side-panel" />
      </v-container>
    </v-navigation-drawer>

    <v-container
      grid-list-lg
      class="mb-100"
    >
      <v-row v-if="title">
        <v-col>
          <h1>{{ title }}</h1>
        </v-col>
        <slot name="page-links" />
      </v-row>
      <slot name="title" />
      <main>
        <slot />
      </main>
    </v-container>
    <footer class="text-left footer">
      <slot name="footer" />
    </footer>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    breadCrumbs: {
      type: Array,
      required: false,
      default: function () {
        return [];
      }
    }
  },
  data(){
    return {
      drawer: null
    }
  }
};
</script>
