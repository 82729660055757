<template>
  <StallionDialog
    v-model="show"
    :width="600"
    persistent
    title="2-Step Verification"
  >
    <template #content>
      <v-form @submit.prevent="process2FA">
        <v-card outlined>
          <v-card-text class="mt-5">
            <!-- First Step - Ask for confirmation -->
            <v-row v-if="!has2FAEnabled && !showConfirm">
              <v-col cols="12">
                <b>Please scan the QR code using your authenticator app and verify
                  to continue</b>
              </v-col>
              <v-col cols="6">
                <span v-html="qrCode" />
              </v-col>
              <v-col cols="6">
                <h3>Recovery Codes.</h3>
                <p>
                  Here are your backup codes that you can enter to sign in. Keep
                  these backup codes somewhere safe but accessible.
                </p>

                <ul>
                  <li
                    v-for="recoverCode in recoveryCodes"
                    :key="recoverCode"
                  >
                    {{ recoverCode }} <br>
                  </li>
                </ul>
                <v-btn
                  class="mt-3"
                  outlined
                  small
                  color="primary"
                  @click="copyCodes"
                >
                  {{ btnText }}
                </v-btn>
              </v-col>
            </v-row>

            <!-- Second Step - Verify and Perform 2FA challenge -->
            <v-row v-else>
              <v-col cols="12">
                <b>Please open your authenticator app and enter the code to
                  continue</b>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  ref="confirmCode"
                  v-model="code"
                  v-validate="'required'"
                  mt-4
                  type="text"
                  label="Enter Code"
                  outlined
                  data-vv-name="code"
                  :error-messages="errs.collect('code')"
                  required
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </template>

    <template #actions>
      <v-spacer />
      <v-btn
        color="primary"
        :loading="processing"
        :disabled="processing"
        @click="process2FA"
      >
        Verify
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  name: 'TwoFactorConfirmDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      password: null,
      processing: false,
      qrCode: null,
      recoveryCodes: [],
      showConfirm: false,
      code: null,
      btnText: 'Copy'
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    has2FAEnabled() {
      return this.$auth.user.has_two_factor_enabled;
    }
  },
  watch: {
    show: function (val) {
      if (val && !this.has2FAEnabled) {
        this.showConfirm = false;
        this.getQRCode();
        this.getRecoveryCodes();
      }
    }
  },
  mounted() {
    this.$copyText('TEST COPY');

    this.$copyText('TEST COPY2');
  },
  methods: {
    copyCodes() {
      let self = this;
      const copyText = this.recoveryCodes.join(', ');
      /* Copy the text inside the text field */
      navigator.clipboard.writeText(copyText).then(() => {
        self.$notify({
          group: 'main',
          title: 'Copied!',
          text: 'Backup codes have been copied!',
          duration: 2000,
          type: 'info'
        });
        self.btnText = 'Copied!';
      });
    },
    getQRCode() {
      this.$http
        .get('auth/user/two-factor-qr-code')
        .then((response) => {
          this.qrCode = response.data.svg;
        })
        .catch(() => {
          this.errorMessage('Unknown Error while retrieving QR code!');
        });
    },
    getRecoveryCodes() {
      this.$http
        .get('auth/user/two-factor-recovery-codes')
        .then((response) => {
          this.recoveryCodes = response.data;
        })
        .catch(() => {
          this.errorMessage('Unknown Error while retrieving recovery codes!');
        });
    },
    process2FA() {
      // show confirm / verify form
      if (!this.has2FAEnabled && !this.showConfirm) {
        this.showConfirm = true;

        // let self = this;
        this.$nextTick(() => {
          this.$refs.confirmCode.focus();
        });
        return;
      }

      // submit confirmation to enable 2FA
      if (!this.has2FAEnabled && this.showConfirm) {
        this.confirm2FA();
        return;
      }
    },
    confirm2FA() {
      // verify first to enable 2fa
      this.$validator.validate().then((result) => {
        if (result) {
          this.processing = true;
          this.$http
            .post('auth/user/confirmed-two-factor-authentication', {
              code: this.code
            })
            .then((res) => {
              this.close();
              this.$emit('onSuccess', res);
            })
            .catch(() => {
              this.errorMessage('Invalid Code!');
              this.processing = false;
            });
        }
      });
    },
    close() {
      this.show = false;
      this.password = null;
      this.processing = false;
      this.qrCode = null;
      this.recoveryCodes = [];
      this.showConfirm = false;
      this.code = null;
      this.btnText = 'Copy Codes';
       this.$validator.reset();
    }
  }
};
</script>
