<template>
  <v-card outlined>
    <v-card-title>Logo</v-card-title>
    <v-card-text>
      <v-form @submit.prevent>
        <v-row v-if="logo">
          <v-col
            cols="auto"
          >
            <v-img
              :src="logo"
              max-width="200"
              max-height="200"
            />
          </v-col>

          <v-col
            cols="auto"
          >
            <v-btn
              color="primary"
              @click="logo = null"
            >
              <v-icon small>
                mdi-trash
              </v-icon>Replace
            </v-btn>
          </v-col>
        </v-row>
        
        <v-row
          v-else
          class="pt-2"
        >
          <v-col
            cols="12"
            md="8"
          >
            <v-file-input
              v-model="photo"
              v-validate="''"
              outlined
              name="Store Logo"
              :prepend-icon="false"
              prepend-inner-icon="mdi-camera"
              accept="image/jpeg, image/png"
              label="Upload Logo"
              :error-messages="errs.collect('photo')"
              data-vv-name="photo"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-btn
              color="primary"
              :disabled="uploading"
              :loading="uploading"
              @click="uploadPhoto"
            >
              <v-icon small>
                mdi-upload
              </v-icon>Upload
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    userStoreId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      uploading: false,
      photo: null,
      logo: null
    };
  },
  watch: {
    userStoreId() {
      this.getLogo()
    }
  },
  async mounted() {
    this.getLogo()
  },
  methods: {
    getLogo() {
      if (!this.userStoreId) {
        return;
      }

      this.$http.get(`user-stores/${this.userStoreId}/logo`)
        .then((response) => {
          this.logo = response.data;
        })
    },
    uploadPhoto() {
      this.$validator.validate().then((result) => {
        if (!result) return;

        this.uploading = true
        const formData = new FormData();

        if (this.photo) {
          formData.append('photo', this.photo);
        }

        this.$http.post(`/user-stores/${this.userStoreId}/logo`, formData)
          .then((response) => {
            this.successMessage('Store Logo has been updated.');
            this.photo = null;
            this.logo = response.data.logo || null
          })
          .catch(() => {
            this.errorMessage('Unable to upload logo.');
          })
          .finally(() => {
            this.uploading = false
          })
      })
    }
  }
};
</script>
