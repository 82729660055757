<template>
  <div>
    <!--Empty Parent Component-->
    <!-- Will be extended by child components. ex: QuickShipFormDialog, edit-shipment-details.vue -->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CONSTANTS from '@/constants';
import ADDRESS_CONSTANTS from '@/constants/address';
import _cloneDeep from 'lodash/cloneDeep';

export default {
  components: {
    //
  },
  props: {
    shipment: {
      default: null,
      type: Object
    },
    taxIdentifiers: {
      default: () => [],
      type: Array
    },
    updateShipmentHandler: {
      default: () => { },
      type: Function
    }
  },
  data() {
    return {
      // start batch processing return label
      show_batch_create_labels: false,
      isReturnLabel: false,
      isBatchProcess: false,
      shipments: [],
      selectedShipmentIndex: null,
      // end batch processing return label
      quickshipHotkeyEvent: null,
      showPresetsDialog: false,
      presets: [],
      presetId: null,
      countries: CONSTANTS.COUNTRIES,
      provinces: CONSTANTS.PROVINCES,
      states: CONSTANTS.STATES,
      searchInput2: null,
      searchInput: null,
      postageRates: [],
      shipmentLocal: null,
      postageArray: [],
      packageArray: [],
      rateLoading: false,
      purchaseLoading: false,
      updateLoading: false,
      showQuickShipCompleteDialog: false,
      tab: 2,
      showPrint: false,
      rateCancelTokenSource: null,
      hotkeyLocked: false,
      buildServiceLoading: false,
    };
  },

  computed: {
    ...mapState(['userStores']),
    dimensionsRequired: function () {
      return (
        parseInt(this.shipmentLocal.package_type_id) == 20 ||
        (parseInt(this.shipmentLocal.package_type_id) == 19 &&
          this.isShipmentOver1lb())
      );
    },
    postageTypes() {
      return this.$store.getters['main/postage_types'](this.shipmentLocal.needs_postage)
    },
    packageTypes() {
      return this.$store.getters['main/package_types']
    },
  },
  watch: {
    '$store.state.qz.currentScaleWeight': function () {
      if (this.$store.state.qz.isQuickShip && this.$store.state.qz.currentScaleWeight.status && this.$store.state.qz.currentScaleWeight.data.weight > 0) {
        let weightData = this.$store.state.qz.currentScaleWeight;
        if (weightData?.status && weightData?.data?.weight > 0) {
          if (this.shipmentLocal !== null) {
            this.shipmentLocal.weight = weightData?.data?.weight;
            this.shipmentLocal.weight_unit = weightData?.data?.weight_unit;
          }
        }
      }
    },
    tab: function (val) {
      // tab 0 = address
      if (val == 0 && this.showQuickShipForm) {
        // NOTE: nextTIck will not work - will need to use setTimeout to wait for the element to be rendered
        setTimeout(() => {
          this.initGoogleMap('from')
          this.initGoogleMap('to')
        }, 100)
      }
    },
  },
  created() { },
  beforeDestroy() {
    if (this.quickshipHotkeyEvent) {
      window.removeEventListener('keydown', this.quickshipHotkeyEvent);
    }
  },

  methods: {
    getAddressToSave(type) {
      const columns = type == 'from' ? ADDRESS_CONSTANTS.ADDRESS_BOOK_FROM : ADDRESS_CONSTANTS.ADDRESS_BOOK_TO

      let address = {}
      Object.keys(columns).forEach((key) => {
        address[columns[key]] = this.shipmentLocal[key]
      })

      return address
    },
    selectAddressBookFrom(address) {
      this.setAddressBook(ADDRESS_CONSTANTS.ADDRESS_BOOK_FROM, address)
    },
    selectAddressBookTo(address) {
      this.setAddressBook(ADDRESS_CONSTANTS.ADDRESS_BOOK_TO, address)
    },
    setAddressBook(columns, address) {
      Object.keys(columns).forEach((key) => {
        this.shipmentLocal[key] = address[columns[key]]
      })

      this.$forceUpdate();
    },
    initGoogleMap(addressType) {
      if (typeof google === 'undefined') {
        return
      }

      let inputAddress1 = ''
      let inputRefAddress2 = ''
      let addressObj = {}
      switch (addressType) {
        case 'from':
          inputAddress1 = 'QuickShipFromReturnAddress1'
          inputRefAddress2 = 'refReturnAddress2'
          addressObj = {
            address1: 'return_address1',
            address2: 'return_address2',
            city: 'return_city',
            province_code: 'return_province_code',
            postal_code: 'return_postal_code',
            country_code: 'return_country_code'
          }
          break;
        case 'to':
          inputAddress1 = 'QuickShipToAddress1'
          inputRefAddress2 = 'refAddress2'
          addressObj = {
            address1: 'address1',
            address2: 'address2',
            city: 'city',
            province_code: 'province_code',
            postal_code: 'postal_code',
            country_code: 'country_code'
          }
          break;
        default:
      }

      if (!inputAddress1) {
        return
      }

      try {
        var element = document.getElementById(inputAddress1);

        const options = {
          // bounds: defaultBounds,
          // componentRestrictions: { country: 'us' },
          fields: ['address_components'],
          strictBounds: false,
          types: ['address']
        };

        var autocomplete = new google.maps.places.Autocomplete(
          element,
          options
        );

        autocomplete.addListener('place_changed', () => {
          // Get the place details from the autocomplete object.
          const place = autocomplete.getPlace();
          const placeAddressObj = {}
          for (const key in addressObj) {
            // placeAddressObj.address1 = ''
            placeAddressObj[key] = ''
          }

          // Get each component of the address from the place details,
          // and then fill-in the corresponding field on the form.
          // place.address_components are google.maps.GeocoderAddressComponent objects
          // which are documented at http://goo.gle/3l5i5Mr
          // console.log(place.address_components);
          if (!place.address_components || !Array.isArray(place.address_components)) {
            return;
          }

          for (const component of place.address_components) {
            // @ts-ignore remove once typings fixed
            const componentType = component.types[0];

            switch (componentType) {
              case 'street_number': {
                placeAddressObj.address1 = `${component.long_name} ${placeAddressObj.address1 || ''}`;
                break;
              }

              case 'subpremise': {
                placeAddressObj.address2 = component.short_name;
                break;
              }

              case 'route': {
                placeAddressObj.address1 += component.short_name;
                break;
              }

              case 'postal_code': {
                placeAddressObj.postal_code = `${component.long_name}${placeAddressObj.postal_code || ''}`;
                break;
              }

              case 'postal_code_suffix': {
                placeAddressObj.postal_code = `${placeAddressObj.postal_code || ''}-${component.long_name}`;
                break;
              }
              case 'locality':
                placeAddressObj.city = component.long_name;
                break;
              case 'administrative_area_level_1': {
                placeAddressObj.province_code = component.short_name;
                break;
              }
              case 'country':
                placeAddressObj.country_code = component.short_name;
                break;
            }
          }

          for (const key in addressObj) {
            // this.ship.address1 = ''
            // this.ship.return_address1 = ''
            this.shipmentLocal[addressObj[key]] = placeAddressObj[key]
          }

          // After filling the form with address components from the Autocomplete
          // prediction, set cursor focus on the second address line to encourage
          // entry of subpremise information such as apartment, unit, or floor number.
          // address2Field.focus();

          this.$refs[inputRefAddress2].focus();
        });
      } catch (ex) {
        console.log(ex);
      }
    },
    activateQuickShipForm() {
      this.showQuickShipForm = true
      this.initializeShipmentLocal()
    },

    initializeShipmentLocal() {
      this.presetId = null
      this.getPresets();

      this.$nextTick(() => {
        if (Boolean(this.shipmentLocal?.return_label) !== true) {
          this.tab = 2; // set Shipping Tab
        } else {
          this.tab = 0; // set Address Tab
        }
      })

      this.shipmentLocal = Object.assign(
        {},
        this.shipmentLocal,
        this.shipment
      );
      this.postageRates = [];
      this.postageArray = this.postageTypes.map(
        (p) => {
          return { id: parseInt(p.id), description: p.description };
        }
      );
      this.packageArray = this.packageTypes.map(
        (p) => {
          return {
            id: parseInt(p.id),
            description: p.description,
            classification: p.classification,
            package_provider: p.package_provider
          };
        }
      );

      this.$validator.reset();
    },

    getPresets() {
      this.$http
        .get(`/import/presets`)
        .then((response) => response.data)
        .then((response) => {
          this.initializeHotkeys(response.presets);
        });
    },

    async applyPresetOnDropdown() {
      const preset = this.presets.find(
        (preset) => preset.id == this.presetId
      );

      if (!preset) {
        return;
      }

      await this.linkOrderShipment();
      this.$nextTick(function () {
        if (this.$refs.presetDialog) {
          this.$refs.presetDialog.applyPresetOnDropdown([this.shipmentLocal], preset, () => {
            // this.updateLoading = false
          });
        }
      })
    },

    initializeHotkeys(presets) {
      if (!this.showQuickShipForm) {
        return;
      }

      this.presets = presets;

      // Remove existing hotkey event listener
      if (this.quickshipHotkeyEvent) {
        window.removeEventListener('keydown', this.quickshipHotkeyEvent);
      }

      // Initialize the hotkey event listener
      this.quickshipHotkeyEvent = async (e) => {
        if (this.hotkeyLocked) {
          return; // Prevent further hotkey actions while locked
        }

        const isPressingHotkey = e.ctrlKey || e.metaKey;

        if (isPressingHotkey) {
          // Lock hotkeys for 1 second immediately upon pressing a valid hotkey

          // Check hotkeys for each preset
          for (const preset of presets) {
            const isWithHotkey = preset.hotkey !== null && preset.hotkey !== '';
            const hotkey = isNaN(preset.hotkey) ? preset.hotkey.toLowerCase() : preset.hotkey;

            if (isWithHotkey && e.key === hotkey) {
              e.preventDefault();
              if (this.$refs.presetDialog) {
                await this.linkOrderShipment();
                this.$refs.presetDialog.applyPresetOnHotkey(preset);
              }
              return; // Stop further execution after handling the hotkey
            }
          }

          // Handle specific hotkey actions
          if (this.showQuickShipForm && e.key === 'p') {
            e.preventDefault();
            if (!this.showOverlay) {
              await this.submitForm();
            }
          } else if (this.showQuickShipForm && e.key === 'r') {
            e.preventDefault();
            if (!this.showOverlay) {
              await this.getPostageRates();
            }
          } else if (this.showQuickShipForm && e.key === 's' ) {
            e.preventDefault();
            if(!this.showOverlay) {
              await this.save();
            }
          }

          // Reserved hotkeys
          if (e.key === 'Escape') {
            e.preventDefault();
            this.close();
          } else if (e.keyCode === 37) {
            e.preventDefault();
            this.tab--;
          } else if (e.keyCode === 39) {
            e.preventDefault();
            this.tab++;
          }
        }
      };

      // Attach the hotkey event listener
      window.addEventListener('keydown', this.quickshipHotkeyEvent);
    },
    async openPresetsDialog() {
      await this.linkOrderShipment();
      this.showPresetsDialog = true;
    },

    async onApplyPresets(payload) {
      const presetName = payload.preset
        ? `(${payload.preset.preset_name})`
        : '';
      this.$notify({
        group: 'main',
        title: 'Shipment Saved',
        text: `Selected preset ${presetName} has been applied to shipment ${this.shipmentLocal.ship_code}.`,
        duration: 5000,
        type: 'success'
      });
      await this.getShipment(this.shipmentLocal.id);
      this.updateShipmentHandler(this.shipmentLocal);
    },

    isShipmentOver1lb() {
      const unit = this.shipmentLocal.weight_unit;
      switch (unit) {
        case 'lbs':
          return this.shipmentLocal.weight >= 1;
        case 'kg':
          return this.shipmentLocal.weight >= 0.45;
        case 'g':
          return this.shipmentLocal.weight >= 453;
        case 'oz':
          return this.shipmentLocal.weight >= 16;
        default:
          return false;
      }
    },

    reset() {
      this.$validator.reset();
      this.postageRates = [];
    },

    close() {
      this.reset();
      this.$store.dispatch('qz/updateQuickShipStatus', false);
      this.stopScaleStream();
      this.showQuickShipForm = false

      // start batch processing return label
      this.isReturnLabel = false;
      this.isBatchProcess = false;
      this.shipments = [];
      this.selectedShipmentIndex = null;
      // end batch processing return label
    },

    async validateForm() {
      return await this.$validator.validate().then((result) => {
        return result ? true : false;
      });
    },

    async getPostageRates() {
      if (this.rateLoading) {
        return;
      }

      this.rateLoading = true;

      // Cancel the previous request if it's still running
      if (this.ratesCancelTokenSource) {
        this.ratesCancelTokenSource.cancel('Previous request cancelled');
      }

      // Create a new cancel token source for the new request
      this.ratesCancelTokenSource = this.$http.CancelToken.source();

      // Validate the form before proceeding with the request
      if (!(await this.validateForm())) {
        this.$notify({
          group: 'main',
          title: 'Missing Fields',
          text: this.errs.all().join('. '),
          duration: 5000,
          type: 'error'
        });
        return;
      }

      // Start loading and clear previous rates

      this.postageRates = [];

      this.$http
        .post('/shipments/rates', this.shipmentLocal, {
          cancelToken: this.ratesCancelTokenSource.token
        })
        .then((res) => {
          this.rateLoading = false;

          // Check for errors in the response
          if (res.data.errors) {
            this.errorMessage(res.data.errors);
          } else {
            this.postageRates = res.data.rates;
          }
        })
        .catch((err) => {
          if (this.$http.isCancel(err)) {
            console.log('Previous request was cancelled');
            // Don't stop loading when the previous request was cancelled
            return;
          } else {
            console.error('Error fetching postage rates:', err);

            this.rateLoading = false;
          }
        })
        .finally(() => {
          // Only stop the loading indicator if the request wasn't cancelled
          // this.rateLoading = false;
        });
    },

    // start batch processing return label
    resetShipmentsIndex() {
      this.shipments.forEach((shipment, index) => {
        // reset index for tracking
        this.$set(shipment, '_index', index);
      });
    },
    async purchaseForBatchProcessing() {
      try {
        if (!(await this.validateForm())) {
          this.$notify({
            group: 'main',
            title: 'Missing Fields',
            text: this.errs.all().join('. '),
            duration: 5000,
            type: 'error'
          });
          return;
        }

        this.purchaseLoading = true;
        // create shipments
        const toSave = this.shipments.filter((shipment) => !shipment.id);
        if (toSave.length > 0) {
          const response = await this.$http
            .post(`/shipments/bulk-create`, {
              shipments: toSave
            })

          // remove unsaved shipments
          this.shipments = this.shipments.filter((shipment) => shipment.id)
          // add newly saved shipments
          response.data.forEach((shipment) => {
            this.shipments.push(shipment)
          });

          this.resetShipmentsIndex();
        }

        this.$refs.refCreateLabels.openFromShipments(this.shipments);

        this.purchaseLoading = false;
        this.close();
      } catch (e) {
        console.log(e)
        this.errorMessage();
      }
    },
    async saveForBatchProcessing() {
      try {
        await this.save(false); // updates the shipmentLocal

        const index = this.shipments.findIndex((shipment) => shipment._index === this.selectedShipmentIndex);
        this.$set(this.shipments, index, _cloneDeep(this.shipmentLocal));
        this.resetShipmentsIndex();
        this.$forceUpdate();
      } catch (e) {
        console.log(e)
        this.errorMessage();
      }
    },
    // end batch processing return label
    async submitForm() {

      if (this.purchaseLoading) {
        return;
      }
        const isValid = await this.validateForm();
        if (!isValid) {
          this.$notify({
            group: 'main',
            title: 'Missing Fields',
            text: this.errs.all().join('. '),
            duration: 5000,
            type: 'error'
          });
          return;
        }
        const res = await this.saveOrUpdateShipment();
        await this.purchaseLabel(res.data);
    },

    async save(closeDialog = true) {
      if (!(await this.validateForm())) {
        this.$notify({
          group: 'main',
          title: 'Missing Fields',
          text: this.errs.all().join('. '),
          duration: 5000,
          type: 'error'
        });
        return;
      }

      await this.saveOrUpdateShipment()
        .then(async (res) => {
          this.shipmentLocal = res.data;
          this.updateShipmentHandler(this.shipmentLocal);

          if (closeDialog) {
            this.close();
          }

          this.$notify({
            group: 'main',
            title: 'Shipment Saved',
            text: 'Changes to the current shipment have been saved successfully.',
            duration: 5000,
            type: 'success'
          });
        })
        .catch(() => {
        })
        .finally(() => {
        
        });
    },

    /**
     * Link an order to shipment
     */
    async linkOrderShipment() {
      // exit if already linked to shipment
      if (this.shipmentLocal.id !== null) return;

      await this.saveOrUpdateShipment()
        .then((res) => {
          this.shipmentLocal = res.data;
          this.updateShipmentHandler(this.shipmentLocal)
        });
    },

    /**
     * Build the HTTP service that can create or update the shipment
     */
     async saveOrUpdateShipment() {
      if (this.isUpdateLoading || this.isPurchaseLoading) {
        throw new Error('Shipment save is already in progress');
      }
      this.isUpdateLoading = true;

      try {
        if (this.shipmentLocal.id === null) {
          // Create new shipment
          return await this.$http.post('/shipments', this.shipmentLocal);
        } else {
          // Update existing shipment
          return await this.$http.put(`/shipments/${this.shipmentLocal.id}`, this.shipmentLocal);
        }
      } catch (err) {
        // if 409, refresh the shipment
        if (err.response.status === 409) {
          this.$notify({
            group: 'main',
            title: 'Shipment Locked',
            text: 'This shipment is being processed by another user. Updates to this shipment have been blocked.',
            duration: 8000,
            type: 'error'
          });
        }

        throw err;
      }
      finally {
        // Ensure that isUpdateLoading is set to false after the operation completes
        this.isUpdateLoading = false;
      }
    },

    async purchaseLabel(shipment) {
      // Check if a purchase operation is already in progress
      if (this.purchaseLoading) {
        return;
      }

      // Lock the purchase operation
      this.purchaseLoading = true;


      this.$http
        .put('/shipments/' + shipment.id + '/complete', shipment)
        .then(async (res) => {
          await this.getShipment(shipment.id);
          this.updateShipmentHandler(this.shipmentLocal)

          if (!res.data.success) {
            this.$notify({
              group: 'main',
              title: 'Error',
              text: res.data.errors,
              duration: 5000,
              type: 'error'
            });
          } else {
            Event.fire('get-credits');

            this.close();
          }

          this.purchaseLoading = false;
        })
        .catch(async () => {
          await this.getShipment(shipment.id);
          this.updateShipmentHandler(this.shipmentLocal)
          this.purchaseLoading = false;
        })
        .finally(async () => {

        });
    },

    async getShipment(id) {
      await this.$http.get('/shipments/' + id).then((res) => {
        this.shipmentLocal = res.data;
      });
    },

    async setWeight(weight) {
      let weightData = {};
      try {
        if (this.$store.state.qz.currentScaleStream) {
          weightData = this.$store.state.qz.currentScaleWeight;
        } else {
          weightData = weight;
        }
        if (weightData.status) {
          this.shipmentLocal.weight = weightData.data.weight;
          this.shipmentLocal.weight_unit = weightData.data.weight_unit;
        }
      } catch (err) {
        if (err.message === "HID device could not be found") {
          this.errorMessage('Usb device not connected');
        } else {
          console.log(err);
        }
      }
    },
    clearPostageRates() {
      this.postageRates = [];
    },
  }
};
</script>
