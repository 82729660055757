<template>
  <v-flex
    v-if="batch"
    xs12
  >
    <h4> Resolve or ignore errors on shipments before creating labels. </h4>

    <v-data-table
      v-model="selected"
      dense
      :headers="headers"
      :items="shipments"
      item-key="id"
      show-select
    >
      <template #top>
        <BatchRemove
          :shipments="selected"
          :disabled="selected.length == 0"
          :batch="batch"
          :show-icon="false"
          remove-label="Remove Selected"
          class="my-1"
          @update="$emit('removeFromBatch', selected)"
        />
      </template>

      <template #item.order_id="{ item }">
        <div v-html="$options.filters.orderLink(item)" />
      </template>

      <template #item.errors="{ item }">
        <!-- show first 35 characters of first error -->
        <v-tooltip bottom>
          <template #activator="{ on }">
            <div v-on="on">
              <v-icon
                v-if="item.errors && item.errors.length > 0"
                color="red"
                small
              >
                mdi-alert
              </v-icon>
              {{ item.errors[0]?.description.substring(0, 50) }}
            </div>
          </template>
          <span v-if="item.errors && item.errors.length > 0">
            {{ item.errors[0]?.description }}
          </span>
        </v-tooltip>
      </template>
      

      <template #item.postage="{ item }">
        <span v-html="$options.filters.postage(item)" />
      </template>

      <template #item.total="{ item }">
        <span v-if="item.total">
          {{ item.total | currency }}
        </span>
      </template>

      <template #item.actions="{ item }">
        <BatchRemove
          :shipments="[item]"
          :is-text="true"
          :show-icon="false"
          :batch="batch"
          @update="$emit('removeFromBatch', item)"
        />
      </template>
    </v-data-table>
  </v-flex>
</template>

<script>
import BatchRemove from '../BatchRemove.vue';

export default {
  components: {
    BatchRemove
  },
  props: {
    shipments: {
      type: Array,
      default: () => []
    },
    batch: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      selected: [],
      headers: [
        {
          text: 'Order #',
          align: 'start',
          value: 'order_id'
        },
        { text: 'Problem', value: 'errors' },
        { text: 'Postage Type', value: 'postage' },
        { text: 'Rate', value: 'total' },
        { text: 'Remove from Batch', value: 'actions' }
      ]
    };
  },
  watch: {
    shipments: {
      handler() {
        this.selected = [];
      },
      deep: true
    }
  }
}
</script>
