<template>
  <v-flex xs12>
    <v-form>
      <v-container class="pa-0">
        <v-switch v-model="localDateSwitch">
          <template #label>
            <span v-if="localDateSwitch">By Month</span>
            <span v-else>Date Range</span>
          </template>
        </v-switch>
        <v-layout
          v-if="localDateSwitch"
          wrap
        >
          <v-flex xs6>
            <v-menu
              v-model="menu1"
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template #activator="{ on }">
                <v-text-field
                  v-model="localYearMonth"
                  outlined
                  label="Month"
                  prepend-inner-icon="event"
                  readonly
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="localYearMonth"
                type="month"
              />
            </v-menu>
          </v-flex>
        </v-layout>
        <v-layout
          v-else
          wrap
        >
          <v-flex xs6>
            <v-menu
              v-model="menu1"
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template #activator="{ on }">
                <v-text-field
                  v-model="localStartDate"
                  outlined
                  label="Start Date"
                  prepend-inner-icon="event"
                  readonly
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="localStartDate"
                @input="menu1 = false"
              />
            </v-menu>
          </v-flex>
          <v-flex xs6>
            <v-menu
              v-model="menu2"
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template #activator="{ on }">
                <v-text-field
                  v-model="localEndDate"
                  outlined
                  label="End Date"
                  prepend-inner-icon="event"
                  readonly
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="localEndDate"
                @input="menu2 = false"
              />
            </v-menu>
          </v-flex>
        </v-layout>

        <v-btn
          :loading="$store.state.analytics.loading"
          :disabled="$store.state.analytics.loading"
          color="primary"
          @click="$store.dispatch('analytics/getData')"
        >
          Update
        </v-btn>
      </v-container>
    </v-form>
  </v-flex>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  data() {
    return {
      localDateSwitch: true,
      localYearMonth: null,
      localStartDate: null,
      localEndDate: null,
      menu1: false,
      menu2: false
    };
  },
  computed: {
    ...mapState('analytics', ['year_month', 'start_date', 'end_date'])
  },
  watch: {
    localStartDate: function(val) {
      this.setStartDate(val);

      // this.$store.dispatch('analytics/getData');
    },
    localEndDate: function(val) {
      this.setEndDate(val);

      // this.$store.dispatch('analytics/getData');
    },
    localYearMonth: function(val) {
      this.setYearMonth(val);
      this.setStartDate(
        moment(val)
          .startOf('month')
          .format('YYYY-MM-DD')
      );
      this.setEndDate(
        moment(val)
          .endOf('month')
          .format('YYYY-MM-DD')
      );

      // this.$store.dispatch('analytics/getData');
    },
    localDateSwitch: function(val) {
      if (val) {
        this.setStartDate(
          moment(this.localYearMonth)
            .startOf('month')
            .format('YYYY-MM-DD')
        );
        this.setEndDate(
          moment(this.localYearMonth)
            .endOf('month')
            .format('YYYY-MM-DD')
        );
      } else {
        this.setStartDate(this.localStartDate);
        this.setEndDate(this.localEndDate);
      }

      // this.$store.dispatch('analytics/getData');
    }
  },
  created() {
    // assign store state to local variable
    this.localYearMonth = this.year_month;
    this.localStartDate = this.start_date;
    this.localEndDate = this.end_date;
  },
  methods: {
    ...mapActions('analytics', ['setYearMonth', 'setStartDate', 'setEndDate'])
  }
};
</script>
