<template>
  <div>
    <v-container
      v-if="user"
      class="pt-4 pb-10 px-8"
      fluid
      style="height: 100vh; background-color: #fcfcfc"
    >
      <v-container class="px-0 pt-0 mt-4 mx-0">
        <h2 class="font-weight-bold">
          User {{ user.id }} - {{ user.business_name || user.name }}
        </h2>
      </v-container>

      <v-card
        v-if="user"
        outlined
      >
        <v-tabs
          v-model="tabSelected"
          :center-active="$vuetify.breakpoint.mobile"
          :show-arrows="$vuetify.breakpoint.mobile"
          :left="!$vuetify.breakpoint.mobile"
          :vertical="!$vuetify.breakpoint.mobile"
          :class="{ 'my-tabs': !$vuetify.breakpoint.mobile }"
          class="elevation-0"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.title + '1'"
            :disabled="tab.permission && !can(tab.permission)"
          >
            <v-icon
              small
              left
            >
              {{ tab.icon }}
            </v-icon>
            <h4 class="settings-tab">
              {{ tab.title }}
            </h4>
          </v-tab>

          <v-tab-item
            v-for="tab in tabs"
            :key="tab.title + '2'"
          >
            <v-card
              flat
              class="settings-card"
            >
              <v-card-title>
                <v-icon left>
                  {{ tab.icon }}
                </v-icon>{{ tab.title }}
              </v-card-title>
              <v-card-text>
                <component
                  :is="tab.component"
                  :user="user"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-container>
  </div>
</template>

<script>
// import AccountSettings from './tabs/AccountSettings.vue';
import AccountSettings from './tabs/account-settings/Index.vue';
import BranchSettings from './tabs/BranchSettings.vue';
import EmployeeBranch from './tabs/EmployeeBranch.vue';
import ReturnsDescription from './tabs/ReturnsDescription.vue';
import Statements from './tabs/Statements.vue';
import Transactions from './tabs/Transactions.vue';
import RecurringCharges from './tabs/RecurringCharges.vue';
import LoginAsUser from './tabs/LoginAsUser.vue';
import UserImports from './tabs/UserImports.vue';
import AdvancedSettings from './tabs/AdvancedSettings.vue';
import TwoFactorSettings from './tabs/TwoFactorSettings.vue';
import PricingSettings from './tabs/PricingSettings.vue';
import OtherSettings from './tabs/OtherSettings.vue';

export default {
  components: {
    AccountSettings,
    BranchSettings,
    EmployeeBranch,
    ReturnsDescription,
    Statements,
    Transactions,
    RecurringCharges,
    UserImports,
    LoginAsUser,
    AdvancedSettings,
    TwoFactorSettings,
    PricingSettings,
    OtherSettings
  },
  data() {
    return {
      user: null,
      tabSelected: null,
      tabs: [
        {
          title: 'User Settings',
          icon: 'mdi-account',
          component: 'account-settings'
        },
        {
          title: 'Branch Settings',
          icon: 'mdi-map-marker-outline',
          component: 'branch-settings'
        },
        {
          title: 'Advanced Settings',
          icon: 'mdi-lock-open',
          component: 'advanced-settings',
          permission: 'users.edit_advanced'
        },
        {
          title: 'Transactions',
          icon: 'mdi-account-cash-outline',
          component: 'transactions'
        },
        {
          title: 'Recurring Charges',
          icon: 'mdi-credit-card-refresh-outline',
          permission: 'recurring_charges.manage',
          component: 'recurring-charges'
        },
        {
          title: 'Statements',
          icon: 'mdi-file-document-outline',
          component: 'statements'
        },
        {
          title: 'User Imports',
          icon: 'mdi-import',
          component: 'user-imports'
        },
        {
          title: 'Employee Branch',
          icon: 'mdi-map-marker-radius',
          component: 'employee-branch'
        },

        {
          title: 'Returns',
          icon: 'mdi-keyboard-return',
          component: 'returns-description'
        },
        {
          title: '2-Step Verification',
          icon: 'mdi-barcode',
          component: 'two-factor-settings',
          permission: 'users.edit_advanced'
        },
       
           {
          title: 'Other Settings',
          icon: 'mdi-account-settings-outline',
          component: 'other-settings'
        },
         {
          title: 'Price Settings',
          icon: 'mdi-cash',
          component: 'pricing-settings'
        },
         {
          title: 'Login As User',
          icon: 'mdi-login',
          component: 'login-as-user'
        },
      ]
    };
  },
  mounted() {
    this.getUser();
  },

  methods: {
    getUser() {
      this.$http.get('/admin/users/' + this.$route.params.id).then(
        (response) => {
          this.user = response.data;
        },
        () => {
          // error callback
        }
      );
    }
  }
};
</script>

<style scoped>
.settings-tab {
  font-size: 13px;
  font-weight: 700;
}

div.my-tabs [role='tab'] {
  justify-content: flex-start;
  padding-left: 25px;
  padding-right: 25px;
}

.settings-card {
  padding: 25px !important;
  border-left: 1px solid #e7e7e7;
  border-radius: 0 !important;
}

h4 {
  margin-bottom: 0px !important;
}

.v-application p {
  margin-bottom: 0px !important;
}

.v-application ul {
  padding-left: 0px !important;
}

input,
select {
  width: 100%;
}
</style>
