<template>
  <v-row>
    <v-col cols="12">
      <v-form @submit.prevent="submit">
        <draggable
          v-if="localColumns.length > 0"
          v-bind="dragOptions"
          :list="localColumns"
          @start="dragging = true"
          @end="dragging = false"
        >
          <v-col
            v-for="(column, index) in localColumns"
            :key="index"
            cols="3"
          >
            <v-checkbox
              v-model="column.visible"
              dense
            >
              <template #label>
                <div>
                  <strong> {{ index+1 }}</strong> -  {{ column.text }}
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </draggable>
      
        <v-col
          cols="12"
          class="pl-0"
        >
          <v-btn
            color="primary"
            :loading="processing"
            @click="submit"
          >
            Update
          </v-btn>
        </v-col>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  components: {
    draggable
  },
  data() {
    return {
      processing: false,
      localColumns: [],
      manageColumnSettings: [],
      manageColumnTableKey: 'packing_slip_columns',
      dragOptions: {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
        class: 'row'
      }
    };
  },
  async mounted() {
    // getManageColumnSettings is from mixin.js
    this.processing = true
    this.localColumns = await this.getManageColumnSettings(this.manageColumnTableKey)
    this.processing = false
  },
  methods: {
    submit() {
      this.processing = true

      const columns = this.localColumns.map((column) => {
        return {
          value: column.value,
          visible: column.visible
        }
      })

      this.$http.put(`manage-columns/${this.manageColumnTableKey}`, {
        columns: columns
      })
      .then(() => {
        this.successMessage('Default template columns have been saved.');
      })
      .catch(() => {
        this.errorMessage('Your account changes have not been saved');
      })
      .finally(() => {
        this.processing = false
      })

    }
  }
};
</script>
