<template>
  <div>
    <v-layout>
      <v-flex xs12>
        <v-form>
          <v-layout wrap>
            <!-- Name -->
            <v-flex
              xs12
              md4
            >
              <v-text-field
                v-model="search"
                outlined
                dense
                label="Search"
                prepend-inner-icon="search"
                @input="submitSearch()"
              />
            </v-flex>
            <v-flex
              xs12
              md4
              class="hidden-sm-and-down"
            >
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template #activator="{ on }">
                  <v-text-field
                    v-model="start_date"
                    prepend-inner-icon="event"
                    outlined
                    dense
                    label="Start Date"
                    readonly
                    v-on="on"
                  />
                </template>

                <v-date-picker
                  v-model="start_date"
                  @change="submitSearch()"
                  @input="menu1 = false"
                />
              </v-menu>
            </v-flex>

            <v-flex
              xs12
              md4
              class="hidden-sm-and-down"
            >
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template #activator="{ on }">
                  <v-text-field
                    v-model="end_date"
                    prepend-inner-icon="event"
                    outlined
                    dense
                    label="End Date"
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="end_date"
                  @change="submitSearch()"
                  @input="menu2 = false"
                />
              </v-menu>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
    <!-- <v-layout align-center>
      <v-flex xs12 text-center>
        <v-options v-model="options.page" :length="numPages" :total-visible="7" circle></v-options>
      </v-flex>
    </v-layout>-->

    <v-data-table
      v-model="selected"
      class="table-striped"
      :headers="headers"
      :loading="loading"
      item-key="id"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      :footer-props="{
        'items-per-page-options': [50, 100, 200, 500]
      }"
      :items-per-page="25"
      @click:row="rowClick"
    >
      <template #item.content_photo="{ item }">
        <h3 class="mt-3">
          {{ item.unique_id }}
        </h3>
        <v-row justify="space-around">
          <div class="mb-3">
            <v-hover v-slot="{ hover }">
              <a
                :href="item.full_image_url"
                target="_blank"
              >
                <v-img
                  aspect-ratio="1"
                  contain
                  height="100"
                  width="100"
                  :src="item.thumbnail_image_url"
                >
                  <v-expand-transition>
                    <div
                      v-if="hover"
                      class="
                        transition-fast-in-fast-out
                        orange
                        darken-2
                        v-card--reveal
                        white--text
                      "
                      style="height: 100%"
                    >
                      <div
                        class="v-card--reveal--inner"
                        style
                      >
                        <span
                          style="color: white; font-weight: bold"
                        >View</span>
                      </div>
                    </div>
                  </v-expand-transition>
                  <v-layout
                    slot="placeholder"
                    fill-height
                    align-center
                    justify-center
                    ma-0
                  >
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    />
                  </v-layout>
                </v-img>
              </a>
            </v-hover>
          </div>
        </v-row>
      </template>

      <template #item.estimated_arrival="{ item }">
        {{ item.estimated_arrival | datetime_short }}
        <br>
        ({{ item.estimated_arrival | datetime_from }})
      </template>

      <template #item.completed="{ item }">
        <span v-if="item.completed == true">Completed</span>
        <span v-else-if="item.completed == false">Pending</span>
      </template>

      <template #item.created_at="{ item }">
        {{ item.created_at | date_pretty }}
        <br>
        ({{ item.created_at | datetime_from }})
      </template>
      <template #item.returns="{ item }">
        {{ item.returns.length }}
      </template>
    </v-data-table>

    <v-dialog
      v-model="show"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title
          class="text-h3"
          primary-title
        >
          Update Trip Status
        </v-card-title>

        <v-divider />

        <v-card-text>
          <v-layout column>
            <v-flex
              xs12
              text-center
              mt-4
              mb-12
            >
              <h4>
                Confirm mark trip as complete. This will update all the returns
                to "In-Canada".
              </h4>
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="show = false"
          >
            Cancel
          </v-btn>
          <v-spacer />

          <v-btn
            color="primary"
            :loading="update_loading"
            :disabled="update_loading"
            @click="markComplete()"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    filter: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      show: null,
      update_loading: false,
      showDeleteDialog: false,
      showDisposeDialog: false,
      showRequestDialog: false,
      search: null,
      start_date: null,
      end_date: null,
      loading: false,
      menu1: null,
      menu2: null,
      timer: null,
      authRequest: false,
      trip: null,
      headers: [
        {
          text: 'Trip Number',
          value: 'trip_number'
        },
        {
          text: 'Entry Port',
          value: 'port_of_entry'
        },
        {
          text: 'Estimated Arrival',
          value: 'estimated_arrival'
        },

        // {
        //   text: 'Status',
        //   value: 'completed'
        // },
        {
          text: '# Returns In-Transit',
          value: 'in_transit_count',
          sortable: false
        },
        {
          text: '# Pre Sorting',
          value: 'pre_sorting_count',
          sortable: false
        },
        {
          text: '# Returns',
          value: 'returns_count',
          sortable: false
        },

        {
          text: 'Created At',
          value: 'created_at'
        }
        // {
        //   text: 'Action',
        //   sortable: false,
        //   value: 'action'
        // }
      ],
      items: [],
      totalItems: null,
      numPages: null,
      options: {
        sortBy: ['created_at'],
        sortDesc: [true]
      },
      cancelSource: null
    };
  },

  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },

  watch: {
    filter: function () {
      this.options.page = 1;
      this.updateTable();
    },
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    }
  },

  mounted() {
    Event.listen('update-trip', () => {
      this.updateTable();
    });
  },

  methods: {
    rowClick(item) {
      this.$router.push({
        name: 'admin-return-trip-show',
        params: {
          tripNumber: item.trip_number
        }
      });
    },
    showDialog(item) {
      this.show = true;
      this.trip = item;
    },
    markComplete: function () {
      this.update_loading = true;
      this.show = true;

      this.$http
        .put(`/admin/return-trips/${this.trip.id}`, {
          completed: true
        })
        .then(() => {
          Event.fire('update-trip');
          this.show = false;
          this.trip = null;
        })
        .finally(() => {
          this.update_loading = false;
        });
    },
    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.options.page = 1;
      this.timer = setTimeout(function () {
        self.updateTable();
      }, ms);
    },
    updateTable() {
      this.$store.state.main.selectedBatches = [];
      this.totalItems = 0;
      this.items = [];

      this.getDataFromApi().then((data) => {
        this.items = data.items;
        this.totalItems = data.total;
      });
    },
    updateSetting() {
      this.$http
        .put(`/users/${this.$auth.user.id}`, this.$auth.user)
        .then((response) => response.data)
        .then(() => {
          // let items = response.data;
          // let total = response.total;
          // let pages = response.last_page;

          this.loading = false;
          // resolve({
          //   items,
          //   total,
          //   pages
          // });
        });
    },
    getDataFromApi() {
      this.loading = true;
      return new Promise((resolve) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let items = [];
        let total = 0;
        let pages = 0;

        var params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sortBy[0],
          desc: sortDesc[0],
          search: this.search,
          start_date: this.start_date,
          end_date: this.end_date
        };

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        if (this.filter == 'complete') {
          params = {
            completed: true
          };
        } else if (this.filter == 'pending') {
          params = {
            completed: false
          };
        }

        this.$http
          .request(`/admin/return-trips`, {
            method: 'get',
            params: params,
            cancelToken: this.cancelSource.token
          })

          .then((response) => response.data)
          .then((response) => {
            items = response.data;
            total = response.total;
            pages = response.last_page;

            this.loading = false;
            resolve({
              items,
              total,
              pages
            });
          });
      });
    }
  }
};
</script>
