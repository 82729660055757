<template>
  <v-main>
    <v-toolbar flat>
      <!-- <v-toolbar-title>Title</v-toolbar-title> -->

      <v-spacer />
      

      <v-btn
        v-if="store?.portal_config?.store_website_url"
        text
        :href="store?.portal_config?.store_website_url"
        target="_blank"
      >
        Our Website
      </v-btn>


      <v-btn
        v-if="store?.portal_config?.returns_policy_url"
        text
        :href="store?.portal_config?.returns_policy_url"
        target="_blank"
      >
        Returns Policy
      </v-btn>


      <v-menu v-if="store?.portal_config?.contact_email || store?.portal_config?.contact_phone">
        <template #activator="{ on }">
          <v-btn
            text
            v-on="on"
          >
            Contact Us
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-if="store?.portal_config?.contact_email"
            :href="`mailto:${store?.portal_config?.contact_email}`"
          >
            <v-list-item-title>
              Email Us
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="store?.portal_config?.contact_phone"
            :href="`tel:${store?.portal_config?.contact_phone}`"
          >
            <v-list-item-title>
              {{ store?.portal_config?.contact_phone }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-dialog
      v-model="loading"
      persistent
      width="200"
    >
      <v-card dark>
        <v-card-text>
          <v-row
            class="pa-10"
          >
            <atom-spinner
              :animation-duration="2000"
              :size="100"
              :color="store?.portal_config?.primary_color"
            />
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-container
      container--fluid
      mt-5
    >
      <v-row>
        <v-col
          cols="12"

          lg="6"
          offset-lg="3"
        >
          <v-card flat>
            <div class="text-center pa-5 text-h5 ">
              <h1>{{ store?.portal_config?.page_title }}</h1>
            </div>

       
            <!-- <v-row
              justify="center"
            >
              <v-col
                cols="12"
                sm="8"
                md="6"
              >
             
                <v-img
                  :src="store?.portal_config?.logo_url"
                  :alt="store?.name"
               :height="100"
                />
              </v-col>
            </v-row> -->

            <v-card-text>
              <!-- Order Lookup Form  -->
              <div v-show="store && page == 1">
                <order-lookup
                  ref="refOrderLookup"
                  :store="store"
                  :lookup-data.sync="lookupData"
                  @goToOrderSelection="goToOrderSelection"
                  @goToReturnLabel="goToReturnLabel"
                />
              </div>

              <!-- Order Selection -->
              <div v-show="store && page == 2">
                <order-selection
                  ref="refOrderSelection"
                  :store="store"
                  :order.sync="order"
                  @goToReturnLabel="goToReturnLabel"
                  @reset="reset"
                />
              </div>

              <!-- Return Label -->
              <div v-show="store && page == 3">
                <return-label
                  ref="refReturnLabel"
                  :store="store"
                  :lookup-data="lookupData"
                  :return-label="returnLabel"
                  @goToReturnLabel="goToReturnLabel"
                  @reset="reset"
                />
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { AtomSpinner } from 'epic-spinners';
import OrderLookup from '../../components/portal/returns/OrderLookup.vue';
import OrderSelection from '../../components/portal/returns/OrderSelection.vue';
import ReturnLabel from '../../components/portal/returns/ReturnLabel.vue';

export default {
  components: {
    AtomSpinner,
    OrderLookup,
    OrderSelection,
    ReturnLabel
  },
  data() {
    return {
      page: 1,
      store: null,
      order: null,
      lookupData: {
        order_id: null,
        postal_code: null
      },
      returnLabel: {
        ship_code: null
      },
      loading: false
    };
  },
  computed: {
    canGoToOrderSelection() {
      return (
        this.lookupData.order_id &&
        this.lookupData.postal_code &&
        !this.returnLabel.ship_code
      );
    },
    canGoToReturnLabel() {
      return this.returnLabel.ship_code;
    }
  },

  mounted() {

  },

  created() {
    this.lookupData.order_id = this.$route.query.order_id;
    this.lookupData.postal_code = this.$route.query.postal_code;
    this.returnLabel.ship_code = this.$route.query.ship_code;

    this.getStoreDetails();
  },

  methods: {
    goToOrderSelection(data) {
      this.page = 2;
      this.order = data.order;
      this.$router.replace({
        name: 'returns-portal',
        params: this.store.returns_portal_code,
        query: {
          order_id: this.lookupData.order_id,
          postal_code: this.lookupData.postal_code
        }
      });
    },

    goToReturnLabel(data) {
      this.page = 3;
      this.returnLabel = data.shipment;
      this.$router.replace({
        name: 'returns-portal',
        params: this.store.returns_portal_code,
        query: {
          order_id: this.lookupData.order_id,
          postal_code: this.lookupData.postal_code,
          // ship_code: this.returnLabel.ship_code
        }
      });
    },

    reset() {
      this.lookupData.order_id = null;
      this.lookupData.postal_code = null;
      this.returnLabel.ship_code = null;
      this.page = 1;

      this.$router.replace({
        name: 'returns-portal',
        params: this.store.returns_portal_code,
        query: {}
      });
    },

    getStoreDetails() {
      this.loading = true;
      this.$http
        .get(`/returns-portal/${this.$route.params.store}`)
        .then((res) => {
          const data = res.data;
          if (!data.success) {
            throw 404;
          }

          this.store = data.store;
          this.$vuetify.theme.themes.light.primary = data.store.portal_config.primary_color;

          this.setPage();
        })
        .catch(() => {
          this.$router.push({ name: '404' });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    setPage() {
      // show order lookup form, auto search order and redirect to order selection
      if (this.canGoToOrderSelection) {
        this.page = 1;
        // add delay to wait for the component to mount
        setTimeout(() => {
          this.$refs.refOrderLookup.searchOrder();
        }, 100);
        return;
      }

      // show return label form and get shipment details
      if (this.canGoToReturnLabel) {
        this.page = 3;
        setTimeout(() => {
          console.log('getShipment');
          this.$refs.refOrderLookup.searchOrder();
        }, 100);
        return;
      }
    },
    
  }
};
</script>

<style>
.v-application p {
  margin-bottom: 0px !important;
}
</style>
