<template>
  <div class="row">
    <v-layout align-center>
      <v-flex
        xs12
        text-center
      >
        <v-layout justify-center>
          <v-checkbox v-model="agree">
            <template #label>
              <div>
                I agree to the
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <a
                      target="_blank"
                      href="https://stallionexpress.ca/terms-of-use/"
                      @click.stop
                      v-on="on"
                    >
                      terms and conditions
                    </a>
                  </template>
                  Opens in new window
                </v-tooltip>
              </div>
            </template>
          </v-checkbox>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: true
    },
    shipment: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    agree: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
};
</script>
