<template>
  <v-card
    :loading="loading"
    outlined
  >
    <v-card-title> {{ title }}</v-card-title>
    <v-card-text>
      <v-data-table
        :headers="localHeaders"
        :items="data"
      />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    headers: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: 'By Type'
    }
  },
  computed: {
    localHeaders() {
      if (this.headers.length > 0 || this.data.length < 1) {
        return this.headers;
      } else {
        let h = [];

        const keys = Object.keys(this.data[0]);

        keys.forEach(element => {
          const capitalize = this.humanize(element);
          h.push({
            text: capitalize,
            value: element
          });
        });

        return h;
      }
    }
  }
};
</script>
