<template>
  <v-stepper
    v-model="e6"
    non-linear
    outlined
    vertical
  >
    <v-stepper-step
      editable

      step="1"
    >
      QZ Setup
    </v-stepper-step>

    <v-stepper-content step="1">
      <div>
        <span style="font-size: large">
          We depend on QZ tray for printing and weighing please install the most recent QZ Tray by clicking <a
            href="https://qz.io/download"
            target="_blank"
            rel="noopener noreferrer"
          >here</a>
        </span>
        <v-tabs
          v-model="tabs"
          fixed-tabs
        >
          <v-tab>
            Windows
          </v-tab>
          <v-tab>
            Mac
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs">
          <v-tab-item>
            <br>
            <ol>
              <li>
                Run QZ Tray in the background after opening it.
              </li>
              <li>
                Turn on the printer/scale and connect it to the PC/Mac with a USB cable.
              </li>
              <li>
                If required, download and install your printer driver from <a
                  href="https://www.seagullscientific.com/support/downloads/drivers/"
                  target="_blank"
                  rel="noopener noreferrer"
                >here</a>
              </li>
            </ol>
          </v-tab-item>
          <v-tab-item>
            <br>
            <ol>
              <li>
                Run QZ Tray in the background after opening it.
              </li>
              <li>
                Turn on the printer/scale and connect it to the PC/Mac with a USB cable.
              </li>
              <li>
                Utilize System Preferences to add the printer.
                <ol>
                  <li>Navigate to System Preferences > Printers</li>
                  <li>
                    Click the ‘+’ to add the printer (provide password of an administrator account if necessary)
                  </li>
                  <li>Select the printer from the list of printers.</li>
                  <li>
                    For the driver click “Select Software” and choose the driver associated with your printer from
                    the available options
                  </li>
                </ol>
              </li>
            </ol>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <br>
      <v-btn
        class="mt-3 mb-3"
        color="primary"
        @click="step2()"
      >
        Next
      </v-btn>
    </v-stepper-content>

    <v-stepper-step
      editable

      step="2"
    >
      QZ Tray Status
    </v-stepper-step>

    <v-stepper-content step="2">
      <div v-if="connectionStatus">
        <v-alert
          type="success"
          width="300"
          outlined
        >
          Connected to QZ tray
        </v-alert>
      </div>
      <div v-else>
        <v-progress-circular
          indeterminate
          size="25"
          color="primary"
          class="mr-3"
        />
        Connecting to QZ tray...
      </div>
    </v-stepper-content>
  </v-stepper>
</template>

<script>
export default {
  name: "SetupQz",
  data() {
    return {
      e6: 1,
      connectionStatus: false,
      count: 0,
      tabs: '',
      buttonRefresh: false,
      isWebSocketActive: false,
    }
  },

  watch: {
    isWebSocketActive(newVal) {
      console.log('WebSocket activity changed:', newVal);
      if(newVal){
        this.finish();
      } else {
        this.connectionStatus = false;
        this.start();
      }
    },
  },
  created() {
    this.updateWebSocketStatus();
    // Optionally, use an interval to keep it updated if necessary
    this.statusInterval = setInterval(this.updateWebSocketStatus, 1000);
  },

  beforeDestroy() {
    clearInterval(this.statusInterval);
  },
  async mounted() {
    await this.start();
  },

  methods: {
    async start() {
      this.e6 = 1;
      if (await this.qzStatus()) {
      await this.finish();
    } else {
      if (this.getOS() === 'Windows') {
        this.tabs = 0;
      } else {
        this.tabs = 1;
      }
    }
    },
    async finish() {
      if (await this.qzStatus()) {
        this.e6 = 2
      } else {
        if (this.count === 0) {
          this.initializeQz();

          this.count++;
          await this.loadQztray();
        } else {
          await this.qzStatus();
        }
      }
    },

    async step2() {
      this.e6 = 2;
      await this.initializeQz();
    },

    async loadQztray() {
      let getApp = setInterval(async () => {
        await this.initializeQz();
        if (await this.qzStatus()) {
          await this.qzStatus();
          clearInterval(getApp);
        }
      }, 2000);
    },

    async qzStatus() {
      this.connectionStatus = await this.isQzInitialized();      
      
      return this.connectionStatus;
    },

    getOS() {
      let platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
          macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
          windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
          os = null;

      if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
      } else if (/Linux/.test(platform)) {
        os = 'Linux';
      }
      return os;
    },

    updateWebSocketStatus() {
      this.isWebSocketActive = this.$qz.websocket.isActive();
      console.log('this.$qz.websocket.isActive() value:', this.$qz.websocket.isActive());
    },

  }
}
</script>

<style scoped>

</style>