import Vue from 'vue';
import VueAxios from 'vue-axios';
import axios from 'axios';
import router from '../router/index';
import store from '../store';

axios.defaults.baseURL = window.location.origin + '/api';

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status == 401) {
      Vue.notify({
        group: 'main',
        title: 'Unauthorized',
        text: 'Please login to continue',
        duration: 8000,
        type: 'error'
      });

      localStorage.removeItem('vuex');

      store.dispatch('clearAll');
      router.push({ path: '/login' });
    } else if (error.response && error.response.status == 500) {
      Vue.notify({
        group: 'main',
        title: 'Error',
        text: 'An unknown error occurred',
        duration: 8000,
        type: 'error'
      });
    } else if (error.response && error.response.status == 403) {
      Vue.notify({
        group: 'main',
        title: 'Unauthorized Action',
        text: 'You are not authorized to access this resource.',
        duration: 8000,
        type: 'error'
      });

      router.push({ path: '/403' });
    } else if (error.response && error.response.status == 419) {
      // refresh on csrf token invalid
      Vue.notify({
        group: 'main',
        title: 'Session Expired',
        text: 'Sorry, your session seems to have expired. Please login again.',
        duration: 8000,
        type: 'error'
      });

      localStorage.removeItem('vuex');

      store.dispatch('clearAll');
      router.push({ path: '/login' });
    } else if (error.response && error.response.status == 422) {

      if (error.response.data.message) {
        Vue.notify({
          group: 'main',
          title: 'Validation Error',
          text: error.response.data.message,
          duration: 8000,
          type: 'error'
        });
      }
      else if (error.response.data.errors) {
        let errors = error.response.data.errors;
        if (!Array.isArray(errors)) {
          errors = [errors]
        }
        for (let key in errors) {
          let message = typeof errors[key] === 'string' ? errors[key] : errors[key][0];

          Vue.notify({
            group: 'main',
            title: 'Validation Error',
            text: message,
            duration: 8000,
            type: 'error'
          });
        }
      }
       else{
        Vue.notify({
          group: 'main',
          title: 'Validation Error',
          text: 'Validation error occurred',
          duration: 8000,
          type: 'error'
        });
       }

    }
    return Promise.reject(error);
  }
);

Vue.use(VueAxios, axios);
