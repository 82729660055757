<template>
  <user-store-settings
    ref="refStoreSettings"
    :import-base-settings="$auth.user.user_config_settings.imports"
  />
</template>
<script>
export default {
  props: {
    mainSettingsTab: {
      type: String, // zero based index
      default: null
    }
  },
  data() {
    return {};
  },
  watch: {
    mainSettingsTab: {
      handler() {
        if (this.mainSettingsTab == 17) {
          const ref = this.$refs.refStoreSettings;
          if (ref) {
            ref.getUserStoreSettings();
          }
        }
      },
      deep: true
    }
  },
  mounted() {},
  methods: {}
};
</script>
