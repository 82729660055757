<template>
  <div v-if="shipment">
    <div
      v-if="shipment.tracking_events && shipment.tracking_events.length > 0"
      xs12
    >
      <h4 v-if="showHeader">
        Tracking
      </h4>
      <div>
        <v-tooltip top>
          <template #activator="{ on }">
            <a
              v-if="shipment.tracking_events[0].tracking"
              :href="
                axios.defaults.baseURL.replace('api', 'tracking') +
                  '/' +
                  shipment.tracking_events[0].tracking
              "
              target="”_blank”"
              v-on="on"
            >{{ shipment.tracking_events[0].tracking }}</a>
          </template>
          <span>Track on Carrier Site</span>
        </v-tooltip>
      </div>
      <div
        v-if="shipment.tracking_events && shipment.tracking_events[0].carrier"
      >
        {{ shipment.tracking_events[0].carrier }}
      </div>
      <div
        v-if="shipment.tracking_events && shipment.tracking_events[0].datetime"
      >
        {{ shipment.tracking_events[0].datetime | datetime_short }}
      </div>
      <div
        v-if="shipment.tracking_events && shipment.tracking_events[0].status"
      >
        {{ shipment.tracking_events[0].status }}
      </div>
      <div
        v-if="shipment.tracking_events"
        class="text-center mt-4 mb-4"
      >
        <router-link
          target="_blank"
          router
          :to="{ name: 'track', params: { code: shipment.ship_code } }"
        >
          View History
        </router-link>
      </div>
    </div>
    <div
      v-else
      xs12
    >
      <h4 v-if="showHeader">
        Tracking
      </h4>

      <div v-if="shipment.tracking_code && shipment.rate_details?.trackable">
        <v-tooltip top>
          <template #activator="{ on }">
            <a
              :href="
                axios.defaults.baseURL.replace('api', 'tracking') +
                  '/' +
                  shipment.tracking_code
              "
              target="”_blank”"
              v-on="on"
            >{{ shipment.tracking_code }}</a>
          </template>
          <span>Track on Carrier Site</span>
        </v-tooltip>
      </div>

      <div v-else-if="shipment.tracking_code">
        {{ shipment.tracking_code }}
      </div>
      <div v-else>
        No Tracking Available
      </div>
    </div>
    <div
      v-if="shipment.dropoff_date"
      class="my-1"
    >
      <div class="shipment-title">
        Drop-Off Date
      </div>
      <div>{{ shipment.dropoff_date | date_pretty }}</div>
    </div>
    <div
      v-if="shipment.rate_details && shipment.rate_details.delivery_days"
      class="my-2"
    >
      <div class="shipment-title">
        Average Transit time
      </div>
      <div>{{ shipment.rate_details.delivery_days }} business days</div>
    </div>
    <div
      v-if="shipment.completion_date && shipment.status_id == 9"
      class="my-1"
    >
      <div class="shipment-title">
        Delivered
      </div>
      <div>
        {{ shipment.completion_date | date_pretty }}
      </div>
    </div>
    <div
      v-else-if="shipment.estimated_delivery_date"
      class="my-1"
    >
      <div class="shipment-title">
        Estimated Delivery Date
      </div>
      <div>
        {{ shipment.estimated_delivery_date }}
      </div>
    </div>
  
    <v-divider
      v-if="showDivider"
      class="details-divider"
    />
  </div>
</template>
<script>
export default {
  props: {
    shipment: {
      type: Object,
      default: null
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    showDivider: {
      type: Boolean,
      default: true
    }
  }
};
</script>
