<template>
  <FullPageLayout title="Provider Credentials">
    <template #default>
      <v-layout>
        <v-flex xs12>
          <ProviderCredentialsTable />
        </v-flex>
      </v-layout>
    </template>
  </FullPageLayout>
</template>

<script>
import ProviderCredentialsTable from './ProviderCredentialsTable.vue'
export default {
  components:{
    ProviderCredentialsTable
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>
