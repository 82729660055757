<template>
  <StallionDialog
    v-model="show"
    :width="500"
    persistent
    title="Update Shipment Status"
  >
    <template #content>
      <v-layout column>
        <v-flex
          xs12
        >
          <div class="dialog-info">
            Change the status of {{ returns.length }} shipment return
          </div>
        </v-flex>
        <v-flex
          xs12
          text-center
          mt-4
          mb-12
        >
          <v-select
            v-model="status"
            :items="statuses"
            item-value="id"
            item-text="description"
          />
        </v-flex>
      </v-layout>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        :loading="update_loading"
        :disabled="update_loading"
        @click="updateStatuses()"
      >
        Update
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    returns: {
      type: Array,
      default: null
    },
    statusRequired: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      update_loading: false,
      dowload_loading: false,
      statuses: [],
      status: null
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  mounted() {
    this.getReturnStatuses();
  },
  methods: {
    getReturnStatuses() {
      this.$http
        .get(`/return-statuses`)
        .then(response => response.data)
        .then(response => {
          this.statuses = response;
          this.statuses.unshift({
            id: 0,
            description: 'Request for Photos'
          });
          if (this.statusRequired && this.statusRequired.length !== 0) {
            this.statuses = this.filterStatusById(this.statusRequired);
            this.status = this.statuses[0].id;
          }
        });
    },
    updateStatuses: function() {
      this.update_loading = true;

      var ids = [];
      this.returns.forEach(function(shipmentReturn) {
        ids.push(shipmentReturn.id);
      });
      this.$http
        .post('/admin/returns/update-statuses', {
          ids: ids,
          status: this.status
        })
        .then(() => {
          Event.fire('update-admin-returns');
        })
        .finally(() => {
          this.update_loading = false;
          this.show = false;
        });
    },
    filterStatusById: function(requiredStatusIds) {
      return this.statuses.filter(function(status) {
        return requiredStatusIds.includes(status.id);
      });
    }
  }
};
</script>
