<template>
  <FullPageLayout title="Pickups">
    <template #default>
      <v-container>
        <v-row class="px-0 pt-4">
          <v-col
            cols="12"
            md="12"
            class="pb-0 px-0"
          >
            <p class="font-weight-bold">
              Search For Pickups
            </p>
          </v-col>

          <v-col
            cols="12"
            md="8"
            class="pl-0"
          >
            <v-text-field
              v-model="search"
              pt-0
              label="Search"
              outlined
              prepend-inner-icon="search"
              clearable
              dense
              hide-details
              data-lpignore="true"
              @input="submitSearch()"
            />
          </v-col>

          <v-col
            cols="12"
            md="4"
            class="d-flex flex-wrap px-0"
          >
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-card
                  class="state-button"
                  outlined
                  hover
                  router
                  to="/admin/pickups"
                  v-on="on"
                >
                  <v-icon
                    small
                    left
                  >
                    mdi-package-variant-closed
                  </v-icon>
                  All
                </v-card>
              </template>
              <span>See All Pickups</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-card
                  class="state-button"
                  outlined
                  hover
                  router
                  to="/admin/pickups?filter=pending"
                  v-on="on"
                >
                  <v-icon
                    small
                    left
                  >
                    mdi-calendar
                  </v-icon>
                  Pending
                </v-card>
              </template>
              <span>See Pending Pickups</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-card
                  class="state-button"
                  outlined
                  hover
                  router
                  to="/admin/pickups?filter=complete"
                  v-on="on"
                >
                  <v-icon
                    small
                    left
                  >
                    mdi-truck-check-outline
                  </v-icon>
                  Complete
                </v-card>
              </template>
              <span>See Completed Pickups</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-container>

      <v-data-table
        v-model="selected"
        :headers="headers"
        :loading="loading"
        show-select
        item-key="id"
        :items="items"
        :options.sync="options"
        class="table-striped"
        :server-items-length="totalItems"
        :footer-props="{
          'items-per-page-options': [50, 100, 200, 500]
        }"
      >
        <template #item.created_at="{ item }">
          {{ item.created_at | date_pretty }}
          <br>
          ({{ item.created_at | datetime_from }})
        </template>

        <template #item.requested_pickup_date="{ item }">
          {{ item.requested_pickup_date | date_pretty }}
        </template>

        <template #item.tracking_numbers="{ item }">
          <div v-if="item.type == 'UPS'">
            <div
              v-for="num in item.tracking_numbers"
              :key="num"
            >
              <v-tooltip top>
                <template #activator="{ on }">
                  <a
                    :href=" 'https://www.ups.com/track?loc=en_CA&tracknum=' + num"
                    target="”_blank”"
                    v-on="on"
                  >
                    {{ num }}
                  </a>
                </template>
                <span>Track on UPS</span>
              </v-tooltip>
            </div>
          </div>
        </template>
      </v-data-table>
    </template>
  </FullPageLayout>
</template>

<script>
export default {
  data() {
    return {
      loading: false,

      search: null,
      start_date: null,
      end_date: null,

      headers: [
        {
          text: "ID",
          align: "left",
          value: "id",
        },
        {
          text: "Address",
          value: "pickup_address",
        },
        {
          text: "Carrier",
          value: "type",
        },
        {
          text: "Confirmation Number",
          value: "confirmation_number",
        },
        {
          text: "# Items",
          value: "quantity",
        },
        {
          text: "Tracking",
          value: "tracking_numbers",
        },

        {
          text: "Pickup Date",
          value: "requested_pickup_date",
        },
        {
          text: "Created At",
          value: "created_at",
        },
      ],
      options: {
        sortBy: ["created_at"],
        sortDesc: [true],
      },
      totalItems: 0,
      items: [],

      timer: null,
      cancelSource: null,
    };
  },

  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },

  watch: {
    '$route.query.filter'() {
      this.updateTable();
    },
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    }
  },

  mounted() {
    this.getDataFromApi()
  },

  methods: {
    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.options.page = 1;
      this.timer = setTimeout(function () {
        self.updateTable();
      }, ms);
    },

    updateTable() {
      this.selected = [];
      this.totalItems = 0;
      this.items = [];

      this.getDataFromApi().then((data) => {
        this.items = data.items;
        this.totalItems = data.total;
      });
    },

    getDataFromApi() {
      this.loading = true;
      return new Promise((resolve) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let items = [];
        let total = 0;
        let pages = 0;

        var params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sortBy[0],
          desc: sortDesc[0],
          search: this.search,
          start_date: this.start_date,
          end_date: this.end_date,
          filter: this.$route.query.filter
        };

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        this.$http
          .request(`/admin/pickups`, {
            method: 'get',
            params: params,
            cancelToken: this.cancelSource.token
          })
          .then((response) => response.data)
          .then((response) => {
            items = response.data;
            total = response.total;
            pages = response.last_page;

            this.loading = false;
            resolve({
              items,
              total,
              pages
            });
          });
      });
    }
  }

};
</script>