export default {
  DEFAULT_ACCESS: 'CLIENT.DEFAULT_ACCESS',
  PRIMARY_ACCOUNT_HOLDER: 'CLIENT.PRIMARY_ACCOUNT_HOLDER',
  ORDERS: 'CLIENT.ORDERS',
  ACCOUNT_MANAGEMENT: 'CLIENT.ACCOUNT_MANAGEMENT',
  SHIPPING: 'CLIENT.SHIPPING',
  SUPPLIES: 'CLIENT.SUPPLIES',
  AUTOMATION: 'CLIENT.AUTOMATION',
  CLAIMS: 'CLIENT.CLAIMS',
  PICKUPS: 'CLIENT.PICKUPS',
  PRODUCTS: 'CLIENT.PRODUCTS',
  STORES: 'CLIENT.STORES',
  ANALYTICS: 'CLIENT.ANALYTICS',
};
