<template>
  <div>
    <v-form v-if="!condensed">
      <v-row dense>
        <v-col>
          <v-row dense>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="tracking_code"
                outlined
                dense
                label="Tracking"
                prepend-inner-icon="mdi-pound"
                clearable
                @input="submitSearch()"
              />
            </v-col>

            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="batch_id"
                outlined
                dense
                label="Batch ID"
                prepend-inner-icon="mdi-file-document"
                clearable
                @input="submitSearch()"
              />
            </v-col>

            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="closeout_id"
                outlined
                dense
                label="Closeout ID"
                prepend-inner-icon="mdi-clipboard-check "
                clearable
                @input="submitSearch()"
              />
            </v-col>

            <v-col md="3">
              <v-text-field
                v-model="name"
                outlined
                dense
                label="Recipient"
                prepend-inner-icon="mdi-account"
                clearable
                @input="submitSearch()"
              />
            </v-col>

            <v-col
              cols="12"
              md="4"
            >
              <user-search
                v-model="user_id"
                label="User Id"
                @change="submitSearch()"
              />
            </v-col>

         

            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="user_type_id"
                outlined
                dense
                :items="user_type_list"
                item-value="id"
                item-text="name"
                label="User Type"
                clearable
                @change="submitSearch()"
              />
            </v-col>

            <v-col md="4">
              <v-text-field
                v-model="seik"
                outlined
                dense
                label="SEIK"
            
                clearable
                @input="submitSearch()"
              />
            </v-col>
          </v-row>
          <v-row
            v-if="showAdvanceSearch"
            dense
          >
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="order_id"
                outlined
                dense
                label="Order ID"
                prepend-inner-icon="mdi-clipboard-text-search-outline"
                clearable
                @input="submitSearch()"
              />
            </v-col>

            <v-col md="4">
              <v-text-field
                v-model="address1"
                outlined
                dense
                label="Address1"
                prepend-inner-icon="mdi-home-floor-1"
                clearable
                @input="submitSearch()"
              />
            </v-col>

            <v-col
              cols="12"
              md="4"
            >
              <date-picker
                v-model="start_date"
                label="Start Date"
                @change="submitSearch()"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <date-picker
                v-model="end_date"
                label="End Date"
                @change="submitSearch()"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="postage_types_local"
                outlined
                dense
                prepend-inner-icon="mdi-postage-stamp"
                :items="postage_list"
                item-value="id"
                item-text="description"
                label="Postage Type"
                multiple
                clearable
                @change="submitSearch()"
              >
                <template #selection="{ item, index }">
                  <span v-if="index === 0">
                    <span>{{ item.description }}</span>
                  </span>
                  <span
                    v-if="index === 1"
                    class="grey--text text-caption"
                  >(+{{ postage_types_local.length - 1 }} others)</span>
                </template>
              </v-select>
            </v-col>

            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="multiple_needs_postage"
                outlined
                dense
                prepend-inner-icon="mdi-postage-stamp"
                :items="[
                  { value: 1, label: 'Yes' },
                  { value: 0, label: 'No' }
                ]"
                item-value="value"
                item-text="label"
                label="Needs Postage"
                clearable
                @change="submitSearch()"
              />
            </v-col>

            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="is_fba"
                outlined
                dense
                prepend-inner-icon="mdi-postage-stamp"
                :items="[
                  { value: 1, label: 'Yes' },
                  { value: 0, label: 'No' }
                ]"
                item-value="value"
                item-text="label"
                label="Is FBA"
                clearable
                @change="submitSearch()"
              />
            </v-col>

            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="stallion_protection"
                outlined
                dense
                prepend-inner-icon="mdi-horse-variant"
                :items="[
                  { value: 1, label: 'Yes' },
                  { value: 0, label: 'No' }
                ]"
                item-value="value"
                item-text="label"
                label="Stallion Protection"
                clearable
                @change="submitSearch()"
              />
            </v-col>

            <v-col
              cols="12"
              md="4"
            >
              <v-autocomplete
                v-model="multiple_country_code"
                outlined
                dense
                prepend-inner-icon="mdi-earth"
                :items="countries"
                item-value="code"
                item-text="name"
                label="Country"
                multiple
                clearable
                :search-input.sync="country_input"
                @change="submitSearch()"
              />
            </v-col>

            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="region_ids"
                outlined
                dense
                prepend-inner-icon="mdi-globe-model"
                :items="regions"
                item-value="id"
                item-text="name"
                label="Region"
                multiple
                clearable
                @change="submitSearch()"
              />
            </v-col>

            <v-col 
              cols="12"
              md="4"
            >
              <v-autocomplete
                v-if="selectedCountry != 'other'"
                v-model="province"
                outlined
                dense
                prepend-inner-icon="mdi-map"
                :items="selectedCountry == 'CA' ? provinces : states"
                item-value="code"
                item-text="name"
                label="Province/State"
                multiple
                clearable
                :search-input.sync="province_input"
                @change="submitSearch()"
              />
              <v-text-field
                v-else
                v-model="province"
                outlined
                dense
                label="Province/State"
                prepend-inner-icon="mdi-map"
                clearable
                @input="submitSearch()"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <date-picker
                v-model="dropoff_date"
                label="Dropoff Date"
                @change="submitSearch"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto">
          <v-tooltip
            v-model="show2"
            bottom
          >
            <template #activator="{ on }">
              <v-btn
                class="mt-1"
                outlined
                color="primary"
                v-on="on"
                @click="showAdvanceSearch = !showAdvanceSearch"
              >
                Filters
                <v-icon
                  v-if="showAdvanceSearch"
                  small
                  right
                >
                  mdi-chevron-up
                </v-icon>
                <v-icon
                  v-else
                  small
                  right
                >
                  mdi-chevron-down
                </v-icon>
              </v-btn>
            </template>
            <span v-if="showAdvanceSearch">Hide Advanced Filters</span>
            <span v-else>Show Advanced Filters</span>
          </v-tooltip>
          <v-col cols="auto">
            <v-menu offset-y>
              <template #activator="{ on, attrs }">
                <v-btn
                  outlined
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  Export
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  :disabled="main.selectedShipments.length === 0 || isExporting"
                  @click="exportSelected"
                >
                  <v-list-item-title>
                    Export Currently Selected ({{ main.selectedShipments.length }})
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  :disabled="totalItems === 0 || isExporting"
                  @click="exportPageShipments"
                >
                  <v-list-item-title>
                    Export Current View ({{ totalItems }})
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-col>
      </v-row>
    </v-form>

    <div v-if="condensed">
      <v-card-title>
        <span class="text-h3">Shipments</span>
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
          @input="submitSearch()"
        />
      </v-card-title>
    </div>
    <v-data-table
      v-model="main.selectedShipments"
      class="table-striped"
      :headers="headers"
      :loading="loading"
      item-key="id"
      show-select
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      :footer-props="{
        'items-per-page-options': [50, 100, 200, 500]
      }"
      :items-per-page="25"
      @click:row="rowClick"
    >
      <template #item.action="{ item }">
        <show-details-mobile
          v-if="$vuetify.breakpoint.smAndDown"
          :selected="item"
        />
      </template>

      <template #item.name="{ item }">
        {{ item.name }}
        <br>
        {{ item.address1 }}
        <!-- <br />
              {{ item.city }} {{ item.province_code }} {{ item.postal_code }}
              <br />
              {{ item.country_code }} -->
      </template>
      <template #item.user_id="{ item }">
        <router-link
          router
          :to="{ name: 'admin-user-show', params: { id: item.user_id } }"
        >
          {{ item.user_id }}
          <!-- <br>
          {{ item.email }} -->
        </router-link>
      </template>

      <template #item.created_at="{ item }">
        {{ item.created_at | date_pretty }}
        <br>
        ({{ item.created_at | datetime_from }})
      </template>

      <template #item.void_requested_at="{ item }">
        <span v-if="item.void_requested_at">
          {{ item.void_requested_at | date_pretty }}
          <br>
          ({{ item.void_requested_at | datetime_from }})
        </span>
      </template>

      <template #item.status_id="{ item }">
        <div v-html="$options.filters.status(item.status_id)" />
      </template>

      <template #item.postage_type_id="{ item }">
        <div v-html="$options.filters.postage(item)" />

        <span v-if="item.is_fba == true">(FBA)</span>
        <span v-else-if="item.needs_postage == false">(3rd Party)</span>
      </template>

      <template #item.postage_rate="{ item }">
        {{ item.postage_rate | currency }}
      </template>

      <template #item.closeout_id="{ item }">
        <router-link
          v-if="item.closeout_id"
          :to="{
            name: 'closeout-show',
            params: { id: item.closeout_id }
          }"
        >
          {{ item.closeout_id }}
        </router-link>
      </template>

      <template #item.batch_id="{ item }">
        <router-link
          v-if="item.batch_id"
          :to="{ name: 'batch', params: { id: item.batch_id } }"
        >
          {{ item.batch_id }}
        </router-link>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CONSTANTS from '@/constants';

export default {
  props: {
    url: {
      default: '/admin/shipments',
      type: String
    },
    admin: {
      default: false,
      type: Boolean
    },
    // batchId: {
    //   default: null,
    //   type: String
    // },
    adminCloseoutTypeId: {
      default: null,
      type: Number
    },
    adminCloseoutId: {
      default: null,
      type: Number
    },
    batched: {
      default: null,
      type: Boolean
    },
    currentLocationId: {
      type: Number,
      default: null
    },
    // closeoutId: {
    //   type: String,
    //   default: null
    // },
    statusId: {
      type: Number,
      default: null
    },
    filter: {
      type: String,
      default: null
    },
    pending: {
      default: false,
      type: Boolean
    },
    condensed: {
      default: false,
      type: Boolean
    },
    // postage_types: {
    //   type: Array,
    //   default() {
    //     return [];
    //   }
    // },
    statusIds: {
      type: Array,
      default: () => []
    },
    // TODO: where are the components that are using this properties?
    // TODO: https://nonibrands.atlassian.net/browse/STL-177 adding new option for multi select but will retain the existing properties to prevent breaking things up - will probably fix this once country_code and needs_postage properties has been identified
    countryCode: {
      type: String,
      default: null
    },
    needsPostage: {
      default: null,
      type: Boolean
    },
    headers: {
      type: Array,
      default: () => [
        {
          text: 'User',
          align: 'left',

          value: 'user_id'
        },
        {
          text: 'Recipient',
          align: 'left',

          value: 'name'
        },
        {
          text: 'Postage',
          value: 'postage_type_id'
        },
        {
          text: 'Status',
          value: 'status_id'
        },
        {
          text: 'Batch',
          value: 'batch_id',
          class: 'hidden-sm-and-down',
          sortable: false
        },
        {
          text: 'Closeout',
          value: 'closeout_id',
          class: 'hidden-sm-and-down',
          sortable: false
        },
        {
          text: 'Created',
          value: 'created_at'
        },
        {
          text: 'Void Requested Date',
          value: 'void_requested_at'
        },
        {
          text: '',
          value: 'action',
          sortable: false
        }
      ]
    }
  },

  data() {
    return {
      country_input: null,
      countries: CONSTANTS.COUNTRIES,
      stallion_protection: null,
      tracking_code: null,
      order_id: null,
      closeout_id: null,
      batch_id: null,
      is_fba: null,
      name: null,
      address1: null,
      postage_types_local: [],
      show2: false,
      showAdvanceSearch: false,
      drawer: null,
      searchID: null,
      searchEmail: null,
      user_id: null,
      email: null,
      userIDs: [],
      userEmails: [],
      users: [],
      user_type_id: null,
      options: {
        sortBy: [null],
        sortDesc: [null]
      },
      loading: false,
      region_ids: [],

      search: null,
      postage_list: [],
      user_type_list: [],
      start_date: moment().subtract(3, 'months').format('YYYY-MM-DD'),
      end_date: null,
      // multiple selection for needs_postage and country_code filter https://nonibrands.atlassian.net/browse/STL-177
      multiple_needs_postage: [],
      multiple_country_code: [],
      menu1: null,
      menu2: null,
      timer: null,
      totalItems: 0,
      items: [],

      cancelSource: null,
      regions: [],
      provinces: CONSTANTS.PROVINCES,
      states: CONSTANTS.STATES,
      province_input: null,
      province: [],
      dropoff_date: null,
      isExporting: false,
      exportParams:{},
      seik: null
    };
  },
  computed: {
    ...mapState(['main']),

    selectedCountry() {
      this.province = []
      const available_countries = ['CA', 'US']
      return this.multiple_country_code?.length == 1 && available_countries.includes(this.multiple_country_code[0]) ? 
        this.multiple_country_code[0] : 'other'
    }
    // postage_types_local: function() {
    //   return this.postage_types;
    // },
    //  postage_types_local: {
    //   get() {
    //     return this.postage_types;
    //   },
    //   set(postage_types) {
    //     this.$emit('update:postage_types', postage_types);
    //   }
    // }
  },
  watch: {
    user_id: function (val) {
      if (val) {
        var index = this.users.map((e) => e.id).indexOf(val);

        this.email = this.users[index]?.email || null;
      } else {
        this.email = null;
      }
    },
    email: function (val) {
      if (val) {
        var index = this.users.map((e) => e.email).indexOf(val);

        this.user_id = this.users[index]?.id || null;
      } else {
        this.user_id = null;
      }
    },
    filter: function () {
      this.options.page = 1;
      this.updateTable();
    },
    adminCloseoutTypeId: function () {
      this.options.page = 1;
      this.updateTable();
    },
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    }
  },
  mounted() {
    this.main.selectedShipments = [];
    this.getPostageTypes();
    this.getUserTypes();

    Event.listen('admin-update-shipments', () => {
      this.updateTable();
      this.main.selectedShipments = [];
    });

    this.$root.$on('shipment-table-show-select', (select) => {
      if (!select) {
        this.main.selectedShipments = this.shipments;
      } else {
        this.main.selectedShipments = [];
      }
    });

    this.getRegions();
  },
  methods: {
    getRegions() {
      this.$http
        .request(`/admin/regions`, {
          method: 'get'
        })
        .then((response) => response.data)
        .then((response) => {
          this.regions = response;
        });
    },
    rowClick(item) {
      this.main.selectedShipments = [item];
    },
    updateTable() {
      this.totalItems = 0;
      this.items = [];
      this.main.selectedShipments = [];
      this.getDataFromApi().then((data) => {
        this.items = data.items;
        this.totalItems = data.total;
      });
    },
    getDataFromApi() {
      this.loading = true;
      return new Promise((resolve) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let items = [];
        let total = 0;
        let pages = 0;

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        var params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sortBy[0],
          desc: sortDesc[0],
          // search: this.search,
          start_date: this.start_date,
          end_date: this.end_date,
          pending: this.pending,

          is_fba: this.is_fba,
          status: this.filter,
          user_id: this.user_id,
          user_type_id: this.user_type_id,
          batch_id: this.batch_id,
          order_id: this.order_id,
          name: this.name,
          tracking_code: this.tracking_code,
          closeout_id: this.closeout_id,
          postage_types: this.postage_types_local,
          status_ids: this.statusIds,
          status_id: this.statusId,
          current_location_id: this.currentLocationId,
          // will have to retain this one just to make sure it wouldn't break anything since this was passed as a property but not sure where exactly it was being passed
          country_code: this.countryCode,
          needs_postage: this.needsPostage,
          // multiple selection for needs_postage and country_code filter https://nonibrands.atlassian.net/browse/STL-177
          multiple_needs_postage: this.multiple_needs_postage,
          multiple_country_code: this.multiple_country_code,
          admin_closeout_type_id: this.adminCloseoutTypeId,
          admin_closeout_id: this.adminCloseoutId,
          region_ids: this.region_ids,
          stallion_protection: this.stallion_protection,
          address1: this.address1,
          province_codes: this.getFormattedProvinceCodes(),
          dropoff_date: this.dropoff_date,
          seik: this.seik
        };
        this.exportParams = {
          ...params,
          export: true
        }
        this.$http
          .request(this.url, {
            method: 'get',
            params: params,
            cancelToken: this.cancelSource.token
          })

          .then((response) => response.data)
          .then((response) => {
            items = response.data;
            total = response.total;
            pages = response.last_page;

            this.loading = false;
            resolve({
              items,
              total,
              pages
            });
          });
      });
    },
    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.options.page = 1;
      this.timer = setTimeout(function () {
        self.updateTable();
      }, ms);
    },
    getPostageTypes() {
      this.$http
        .get(`/admin/postage-types`)
        .then((response) => response.data)
        .then((response) => {
          this.postage_list = response;
        });
    },
    getUserTypes() {
      this.$http
        .get(`/admin/user-types`)
        .then((response) => response.data)
        .then((response) => {
          this.user_type_list = response;
        });
    },
    getFormattedProvinceCodes() {
      if(Array.isArray(this.province)) {
        return this.province
      } else if(this.province) {
        return this.province.split(',')
      }
      return []
    },
    exportSelected() {
      this.isExporting = true;
      var shipment_ids = this.main.selectedShipments.map((x) => x.id);

      var params = {
        shipment_ids: shipment_ids,
      };

      params.selected = true;

      this.$http
          .request(`admin/shipments/export`, {
            method: 'get',
            params: params,
          })
          .then((response) => {
            this.downloadFileUrl(response.data,`Stallion_Admin_Export.csv`, 'text/csv');
          })
          .catch(() => {
            this.errorMessage('Nothing to export');
          })
          .finally(() => {
            this.isExporting = false;
          });
    },
    exportPageShipments() {
      this.isExporting = true;

      this.$http
          .request(`admin/shipments/export`, {
            method: 'get',
            params: this.exportParams,
          })
          .then((response) => {
            this.downloadFileUrl(response.data,`Stallion_Admin_Export.csv`, 'text/csv');
          })
          .catch(() => {
            this.errorMessage('Nothing to export');
          })
          .finally(() => {
            this.isExporting = false;
          });
    }
  }
};
</script>
