<template>
  <v-container
    v-if="order !== null"
    pa-0
  >
    <v-row
  
      dense
    >
      <!-- <v-col cols="12">
          <div class="d-flex flex-row justify-space-between">
            <h2>Order Details</h2>
          </div>

          <div class="d-flex flex-row justify-space-between">
            <div class="order-title">
              Order ID
            </div>

            <v-tooltip top>
              <template #activator="{ on }">
                <strong><div
                  class="order-title"
                  v-on="on"
                  v-html="$options.filters.orderLink(order)"
                /></strong>
              </template>
              <span>View in store</span>
            </v-tooltip>
          </div>

          <div
            v-if="order.shipment"
            class="d-flex flex-row justify-space-between"
          >
            <div class="order-title">
              Shipment ID
            </div>

            <v-tooltip top>
              <template #activator="{ on }">
                <a
                  v-clipboard:copy="order.shipment.ship_code"
                  v-clipboard:success="oncopy"
                  style="font-weight: bold"
                  href="javascript:void(0);"
                  v-on="on"
                >{{ order.shipment.ship_code }}</a>
              </template>
              <span>Click to copy</span>
            </v-tooltip>
          </div>

          <div
            v-if="order.shipment"
            class="d-flex flex-row justify-space-between"
          >
            <error-details :shipment="order.shipment" />
          </div>
          
         

          <v-divider class="details-divider"/>
        </v-col> -->

      <v-col
        v-if="order.shipment"
        cols="12"
        xs12
      >
        <tracking-details :shipment="order.shipment" />
      </v-col>

      <v-col
        v-if="order && order.note"
        cols="12"
      >
        <div class="d-flex flex-row justify-space-between">
          <h4>Note</h4>
        </div>

        <div class="d-flex flex-row justify-space-between">
          {{ order.note }}
        </div>
        <v-divider class="details-divider" />
      </v-col>

      <v-col
        v-if="order && order.postage_code"
        cols="12"
      >
        <div class="d-flex flex-row justify-space-between">
          <h4>Shipping Method</h4>
        </div>

        <div class="d-flex flex-row justify-space-between">
          {{ order.postage_code }}
        </div>
        <v-divider class="details-divider" />
      </v-col>

      <v-col
        v-if="order && order.carrier_code"
        cols="12"
      >
        <div class="d-flex flex-row justify-space-between">
          <h4>Carrier</h4>
        </div>

        <div class="d-flex flex-row justify-space-between">
          carrier_code
        </div>
        <v-divider class="details-divider" />
      </v-col>
        
      <v-col cols="12">
        <address-verification-details :shipment="order" />
      </v-col>

      <v-col cols="12">
        <address-details :order="order" />
      </v-col>

      <v-col cols="12">
        <item-details :order="order" />
      </v-col>
     
      <quick-ship-form-dialog
        v-if="shipment"
        v-model="showQuickShipFormDialog"
        :shipment.sync="shipment"
        :tax-identifiers="taxIdentifiers"
        :update-shipment-handler="updateShipmentHandler"
      />
    </v-row>
  </v-container>
</template>

<script>
import AddressDetails from '../AddressDetails.vue';
import ItemDetails from '../ItemDetails.vue';
import QuickShipFormDialog from '@/components/main/shipments/quick-ship/QuickShipFormDialog.vue';

export default {
  components: {
    AddressDetails,
    ItemDetails,
    QuickShipFormDialog
  },
  props: {
    order: {
      type: Object,
      default: null
    },
    taxIdentifiers: {
      default: () => [],
      type: Array
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    updateShipmentHandler: {
      default: () => {},
      type: Function
    }
  },
  data() {
    return {
      loading: false,
      showQuickShipFormDialog: false,
      shipment: null,
      openingQuickShip: false
    };
  },
  computed: {
    //
  },
  methods: {
    oncopy() {
      this.$notify({
        group: 'main',
        title: 'Copied!',
        text: 'Shipment ID has been copied to the clipbaord',
        duration: 2000,
        type: 'info'
      });
    },

    prepareQuckShipForm() {
      if (!this.canEdit) return;

      // IMPORTANT: add loading
      this.openingQuickShip = true

      // quick ship existing shipment
      if (this.order.shipment) {
        this.openQuickShipFormDialog(this.order.shipment);
        return;
      }

      // emitted @OderDetails.vue
      this.$emit('autoPrepareShipment', this.openQuickShipFormDialog)
    },

    openQuickShipFormDialog(payload) {
      this.shipment = { ...payload };

      // IMPORTANT: wait for shipment to get properly initialized
      setTimeout(() => {
        this.openingQuickShip = false
        this.showQuickShipFormDialog = true
      }, 500)
    }
  }
};
</script>
