<template>
  <v-row dense>
    <v-col>
      <v-row>
        <v-col>
          <v-btn
            color="primary"
            @click="openCommissionStructureDialog()"
          >
            <v-icon left>
              mdi-plus
            </v-icon>Add
          </v-btn>
        </v-col>


        <v-col>
          <v-form @submit.prevent="updateTable()">
            <v-container pa-0>
              <v-layout wrap>
                <v-flex xs12>
                  <user-search
                    v-model="earner_id"
                    v-validate="'required'"
                    data-vv-name="user_id"
                    label="Employee"
                    :error-messages="errs.collect('user_id')"
           
                    @input="updateTable()"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-col>
        <v-col>
          <v-form @submit.prevent="updateTable()">
            <v-container pa-0>
              <v-layout wrap>
                <v-flex xs12>
                  <user-search
                    v-model="client_id"
                    v-validate="'required'"
                    data-vv-name="client_id"
                    label="Client"
                    :error-messages="errs.collect('client_id')"
           
                    @input="updateTable()"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-col> 
        <v-col>
          <v-form @submit.prevent="updateTable()">
            <v-container pa-0>
              <v-layout wrap>
                <v-flex xs12>
                  <v-select
                    v-model="type"
                    label="Type"
                    :items="['Percent', 'Fixed']"
                    outlined
                    dense
                    :clearable="true"
                    @input="updateTable()"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-col> 
      </v-row>
    </v-col>

    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :loading="loading"
        item-key="id"
        :items="items"
        :options.sync="options"
        class="table-striped"
        :server-items-length="totalItems"
        :footer-props="{
          'items-per-page-options': [50, 100, 200, 500]
        }"
        :items-per-page="25"
        @click:row="rowClick"
      >
        <template #item.client="{ item }">
          <span v-if="item.client">
            {{ displayUser(item.client) }}
          </span>
        </template>

        <template #item.earner="{ item }">
          <span v-if="item.earner">
            {{ displayUser(item.earner) }}
          </span>
        </template>

        <template #item.amount="{ item }">
          <span v-if="item.type == 'Fixed'">
            {{ item.amount | currency }}
          </span>
          <span v-else>
            {{ item.amount }}%
          </span>
        </template>

        <template #item.start_date="{ item }">
          <span v-if="item.start_date">
            {{ item.start_date | date_pretty }}
          </span>
        </template>

        <template #item.end_date="{ item }">
          <span v-if="item.end_date">
            {{ item.end_date | date_pretty }}
          </span>
        </template>

        <template #item.action="{ item }">
          <v-btn
            v-if="isClientCommission(item)"
            color="primary"
            small
            text
            :loading="loading"
            @click.stop="$refs.commissionStructureDialog.startEdit(item)"
          >
            Edit
          </v-btn>

          <v-btn
            color="primary"
            small
            text
            :loading="loading"
            @click.stop="deleteCommissionStructure(item)"
          >
            Delete
          </v-btn>
        </template>
      </v-data-table>
    </v-col>

    <v-col cols="12">
      <create-commission-structure-dialog
        ref="commissionStructureDialog"
        :dialog-title="commissionStructureDialogTitle"
        @update="updateTable"
      />
    </v-col>
  </v-row>
</template>
  
<script>
import CreateCommissionStructureDialog from './CommissionStructureDialog.vue';

export default {
  components: {
    CreateCommissionStructureDialog
  },
  data() {
    return {
      commissionStructureDialogTitle: '',
      totalItems: 0,
      items: [],

      loading: false,
      search: null,

      timer: null,
      cancelSource: null,

      options: {
        sortBy: ['created_at'],
        sortDesc: [true]
      },

      client_id: null,
      earner_id: null,
      type: null,

      headers: [
      {
          text: 'ID',
          align: 'left',
          value: 'id',
        },
        {
          text: '# Shipments',
          align: 'left',
          value: 'total_shipments',
          sortable: false
        },
        {
          text: 'Earner',
          align: 'left',
          value: 'earner',
          sortable: false
        },
        {
          text: 'Client',
          align: 'left',
          value: 'client',
          sortable: false
        },
        {
          text: 'Type',
          align: 'left',
          value: 'type',
          sortable: true
        },
        {
          text: 'Amount',
          align: 'left',
          value: 'amount',
          sortable: true
        },
        {
          text: 'Start Date',
          align: 'left',
          value: 'start_date',
          sortable: true
        },
        {
          text: 'End Date',
          align: 'left',
          value: 'end_date',
          sortable: true
        },
        {
          text: '',
          value: 'action',
          sortable: false
        }
      ],
    }
  },

  watch: {
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    }
  },
  mounted() {
    this.getDataFromApi()
  },
  
  methods: {
    isClientCommission(structure) {
      return structure.group == 'Client';
    },
    displayUser(user) {
      return `${user.id} - ${user.email}`
    },

    openCommissionStructureDialog() {
      // this.commissionStructureDialogTitle = !data ? 'Add ' : 'Edit '

      // this.$refs.commissionStructureDialog.setCommissionData(data)
      this.$refs.commissionStructureDialog.startNew()
    },

    rowClick(item) {
      this.$router.push({
        name: 'admin-commission-structure-show',
        params: {
          id: item.id
        }
      })
    },

    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.options.page = 1;
      this.timer = setTimeout(function () {
        self.updateTable();
      }, ms);
    },

    updateTable() {
      this.totalItems = 0;
      this.items = [];

      this.getDataFromApi().then((data) => {
        this.items = data.items;
        this.totalItems = data.total;
      });
    },

    getDataFromApi() {
      this.loading = true;
      return new Promise((resolve) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let items = [];
        let total = 0;
        let pages = 0;

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        var params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sortBy[0],
          desc: sortDesc[0],
          search: this.search,
          client_id: this.client_id,
          earner_id: this.earner_id,
          type: this.type
        };

        this.$http
          .request(`/admin/commission-structures`, {
            method: 'get',
            params: params,
            cancelToken: this.cancelSource.token
          })

          .then((response) => response.data)
          .then((response) => {
            items = response.data;
            total = response.total;
            pages = response.last_page;

            this.loading = false;
            resolve({
              items,
              total,
              pages
            });
          });
      });
    },

    async deleteCommissionStructure(item) {
      const isConfirm = await swal({
        title: 'Confirm Delete',
        text: 'Do you really want to delete this commission structure?',
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      });

      if (isConfirm) {
        this.loading = true
        try {
          await axios.request(`/admin/commission-structures/${item.id}`, {
            method: 'delete'
          });

          this.successMessage('Successfully Deleted.');
            this.updateTable();
        } catch (err) {
          this.errorMessage(err?.response?.data?.message || 'An unknown error occurred');
        }

        this.loading = false
      }
    }
  }
};
</script>
