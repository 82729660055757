<template>
  <div v-if="shipment && shipment.name">
    <div class="d-flex flex-row justify-space-between">
      <h4>Address Verification Status</h4>
    </div>
    <!-- Validation status -->
    <div>
      <v-icon
        v-if="shipment.address_verification_status == 'Verified'"
   
        color="green"
      >
        mdi-check-circle
      </v-icon> 
      <v-icon
        v-if="shipment.address_verification_status == 'Unverified'"
    
        color="orange"
      >
        mdi-alert-circle
      </v-icon>
      <v-icon
        v-if="shipment.address_verification_status == 'Verification Failed'"
 
        color="red"
      >
        mdi-alert-circle
      </v-icon>

      {{ shipment.address_verification_status }}
    </div>

    <v-divider class="details-divider" />
  </div>
</template>

<script>
export default {
  props: {
    shipment: {
      type: Object,
      default: null
    }
  }
};
</script>
