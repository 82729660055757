<template>
  <v-container
    grid-list-md
    mb-12
  >
    <v-layout wrap>
      <v-flex xs12>
        <h1>
          <i
            aria-hidden="true"
            class="mdi mdi-chart-line"
          /> Analytics -
          Carrier
        </h1>
        <div class="separator mb-4" />
      </v-flex>
      <v-flex xs12>
        <v-card flat>
          <v-card-text>
            <v-form>
              <v-container class="pa-0">
                <v-switch v-model="date_switch">
                  <template #label>
                    <span v-if="date_switch">By Month</span>
                    <span v-else>Date Range</span>
                  </template>
                </v-switch>
                <v-layout
                  v-if="date_switch"
                  wrap
                >
                  <v-flex xs6>
                    <v-menu
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template #activator="{ on }">
                        <v-text-field
                          v-model="year_month"
                          label="Month"
                          prepend-inner-icon="event"
                          readonly
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="year_month"
                        type="month"
                      />
                    </v-menu>
                  </v-flex>
                </v-layout>
                <v-layout
                  v-else
                  wrap
                >
                  <v-flex xs6>
                    <v-menu
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template #activator="{ on }">
                        <v-text-field
                          v-model="start_date"
                          label="Start Date"
                          prepend-inner-icon="event"
                          readonly
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="start_date"
                        @input="menu1 = false"
                      />
                    </v-menu>
                  </v-flex>
                  <v-flex xs6>
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template #activator="{ on }">
                        <v-text-field
                          v-model="end_date"
                          label="End Date"
                          prepend-inner-icon="event"
                          readonly
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="end_date"
                        @input="menu2 = false"
                      />
                    </v-menu>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>

      <!-- OVERVIEW -->
      <v-flex xs12>
        <v-card flat>
          <v-card-title class="text-h3">
            <v-icon
              left
              color="black"
            >
              mdi-package-variant-closed
            </v-icon>
            Shipments
            <v-spacer />
          </v-card-title>

          <v-card-text>
            <v-layout wrap>
              <v-flex xs6>
                <v-card
                  :loading="stamps.loading"
                  outlined
                >
                  <v-card-title>
                    <v-icon left>
                      fab fa-usps
                    </v-icon>
                    Stamps (Created At)
                  </v-card-title>
                  <v-card-text>
                    Quantity: {{ stamps.quantity }}
                    <br>
                    Postage: {{ stamps.postage_fee | currency }}
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex xs6>
                <v-card
                  :loading="freightcom.loading"
                  outlined
                >
                  <v-card-title>
                    <v-icon left>
                      fab fa-ups
                    </v-icon>
                    Freightcom (Created At)
                  </v-card-title>
                  <v-card-text>
                    Quantity: {{ freightcom.quantity }}
                    <br>
                    Postage: {{ freightcom.postage_fee | currency }}
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex xs6>
                <v-card
                  :loading="freightcom_pickups.loading"
                  outlined
                >
                  <v-card-title>
                    <v-icon left>
                      fas fa-shipping-fast
                    </v-icon>
                    Freightcom Pickups (Created At)
                  </v-card-title>
                  <v-card-text>
                    Quantity: {{ freightcom_pickups.quantity }}
                    <br>
                    Postage: {{ freightcom_pickups.postage_fee | currency }}
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex xs6>
                <v-card
                  :loading="landmark.loading"
                  outlined
                >
                  <v-card-title>
                    <v-icon left>
                      fab fa-canadian-maple-leaf
                    </v-icon>
                    Landmark (Closeout Date)
                  </v-card-title>
                  <v-card-text>
                    Quantity: {{ landmark.quantity }}
                    <br>
                    Postage: {{ landmark.postage_fee | currency }}
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex xs6>
                <v-card
                  :loading="postnl.loading"
                  outlined
                >
                  <v-card-title>
                    <v-icon left>
                      mdi-airplane
                    </v-icon>
                    PostNL (Closeout Date)
                  </v-card-title>
                  <v-card-text>
                    Quantity: {{ postnl.quantity }}
                    <br>
                    Postage: {{ postnl.postage_fee | currency }}
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex xs6>
                <v-card
                  :loading="apc.loading"
                  outlined
                >
                  <v-card-title>
                    <v-icon left>
                      mdi-globe-model
                    </v-icon>
                    APC (Closeout Date)
                  </v-card-title>
                  <v-card-text>
                    Quantity: {{ apc.quantity }}
                    <br>
                    Postage: {{ apc.postage_fee | currency }}
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex xs6>
                <v-card
                  :loading="fedex.loading"
                  outlined
                >
                  <v-card-title>
                    <v-icon left>
                      mdi-truck-check
                    </v-icon> Fedex (Closeout Date)
                  </v-card-title>
                  <v-card-text>
                    Quantity: {{ fedex.quantity }}
                    <br>
                    Postage: {{ fedex.postage_fee | currency }}
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex xs12>
                <v-card
                  :loading="stamps.loading"
                  outlined
                >
                  <v-card-title> Stamps - By Day </v-card-title>
                  <v-card-text>
                    <line-chart
                      :height="200"
                      :chart-data="stamps.byDay"
                    />
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex xs12>
                <v-card
                  :loading="stamps.loading"
                  outlined
                >
                  <v-card-title> Freightcom - By Day </v-card-title>
                  <v-card-text>
                    <line-chart
                      :height="200"
                      :chart-data="freightcom.byDay"
                    />
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex xs12>
                <v-card
                  :loading="stamps.loading"
                  outlined
                >
                  <v-card-title> Freightcom Pickups - By Day </v-card-title>
                  <v-card-text>
                    <line-chart
                      :height="200"
                      :chart-data="freightcom_pickups.byDay"
                    />
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex xs12>
                <v-card
                  :loading="stamps.loading"
                  outlined
                >
                  <v-card-title> Landmark - By Day </v-card-title>
                  <v-card-text>
                    <line-chart
                      :height="200"
                      :chart-data="landmark.byDay"
                    />
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex xs12>
                <v-card
                  :loading="stamps.loading"
                  outlined
                >
                  <v-card-title> PostNL - By Day </v-card-title>
                  <v-card-text>
                    <line-chart
                      :height="200"
                      :chart-data="postnl.byDay"
                    />
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex xs12>
                <v-card
                  :loading="stamps.loading"
                  outlined
                >
                  <v-card-title> APC - By Day </v-card-title>
                  <v-card-text>
                    <line-chart
                      :height="200"
                      :chart-data="apc.byDay"
                    />
                  </v-card-text>
                </v-card>
              </v-flex>

              <v-flex xs12>
                <v-card
                  :loading="fedex.loading"
                  outlined
                >
                  <v-card-title> Fedex - By Day </v-card-title>
                  <v-card-text>
                    <line-chart
                      :height="200"
                      :chart-data="fedex.byDay"
                    />
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      date_switch: true,
      start_date: moment(this.year_month)
        .startOf('month')
        .format('YYYY-MM-DD'),
      end_date: moment(this.year_month)
        .endOf('month')
        .format('YYYY-MM-DD'),
      year_month: moment().format('YYYY-MM'),
      menu1: null,
      menu2: null,

      stamps: {
        byDay: null,
        loading: false,
        quantity: 0,
        postage_fee: 0
      },
      freightcom: {
        byDay: null,
        loading: false,
        quantity: 0,
        postage_fee: 0
      },
      freightcom_pickups: {
        byDay: null,
        loading: false,
        quantity: 0,
        postage_fee: 0
      },
      landmark: {
        byDay: null,
        loading: false,
        quantity: 0,
        postage_fee: 0
      },
      postnl: {
        byDay: null,
        loading: false,
        quantity: 0,
        postage_fee: 0
      },
      apc: {
        byDay: null,
        loading: false,
        quantity: 0,
        postage_fee: 0
      },
      fedex: {
        byDay: null,
        loading: false,
        quantity: 0,
        postage_fee: 0
      },
      cancelSource: []
    };
  },

  watch: {
    start_date: function() {
      this.update();
    },
    end_date: function() {
      this.update();
    },
    year_month: function() {
      this.start_date = moment(this.year_month)
        .startOf('month')
        .format('YYYY-MM-DD');
      this.end_date = moment(this.year_month)
        .endOf('month')
        .format('YYYY-MM-DD');

      this.update();
    },
    date_switch: function(val) {
      if (val) {
        this.start_date = moment(this.year_month)
          .startOf('month')
          .format('YYYY-MM-DD');
        this.end_date = moment(this.year_month)
          .endOf('month')
          .format('YYYY-MM-DD');

        this.update();
      }

      this.update();
    }
  },

  mounted() {
    this.update();
  },

  methods: {
    update() {
      this.getStamps();
      this.getFreightcom();
      this.getFreightcomPickups();
      this.getLandmark();
      this.getPostnl();
      this.getApc();
      this.getFedex();
    },
    getFedex() {
      this.fedex.loading = true;

      if (this.cancelSource['fedex']) {
        this.cancelSource['fedex'].cancel(
          'Start new search, stop active search'
        );
      }
      this.cancelSource['fedex'] = axios.CancelToken.source();
      this.$http
        .get(
          `/admin/analytics-carrier/fedex?start_date=${this.start_date}&end_date=${this.end_date}`,
          {
            cancelToken: this.cancelSource['fedex'].token
          }
        )
        .then((response) => response.data)
        .then((response) => {
          this.fedex.postage_fee = response.totals.postage_fee;
          this.fedex.quantity = response.totals.quantity;

          this.fedex.byDay = {
            labels: response.by_date.map((a) => a.date),
            datasets: [
              {
                label: 'Postage',
                borderColor: '#9ccc65',
                data: response.by_date.map((a) => a.postage_fee)
              }
            ]
          };

          this.fedex.loading = false;
        })
        .finally(() => {});

    },
    getStamps() {
      this.stamps.loading = true;

      if (this.cancelSource['stamps']) {
        this.cancelSource['stamps'].cancel(
          'Start new search, stop active search'
        );
      }
      this.cancelSource['stamps'] = axios.CancelToken.source();
      this.$http
        .get(
          `/admin/analytics-carrier/stamps?start_date=${
            this.start_date
          }&end_date=${this.end_date}`,
          {
            cancelToken: this.cancelSource['stamps'].token
          }
        )
        .then(response => response.data)
        .then(response => {
          this.stamps.postage_fee = response.totals.postage_fee;
          this.stamps.quantity = response.totals.quantity;

          this.stamps.byDay = {
            labels: response.by_date.map(a => a.date),
            datasets: [
              {
                label: 'Postage',
                borderColor: '#9ccc65',
                data: response.by_date.map(a => a.postage_fee)
              }
            ]
          };

          this.stamps.loading = false;
        })
        .finally(() => {});
    },
    getFreightcom() {
      this.freightcom.loading = true;

      if (this.cancelSource['fc']) {
        this.cancelSource['fc'].cancel('Start new search, stop active search');
      }
      this.cancelSource['fc'] = axios.CancelToken.source();
      this.$http
        .get(
          `/admin/analytics-carrier/freightcom?start_date=${
            this.start_date
          }&end_date=${this.end_date}`,
          {
            cancelToken: this.cancelSource['fc'].token
          }
        )
        .then(response => response.data)
        .then(response => {
          this.freightcom.postage_fee = response.totals.postage_fee;
          this.freightcom.quantity = response.totals.quantity;

          this.freightcom.byDay = {
            labels: response.by_date.map(a => a.date),
            datasets: [
              {
                label: 'Postage',
                borderColor: '#9ccc65',
                data: response.by_date.map(a => a.postage_fee)
              }
            ]
          };

          this.freightcom.loading = false;
        })
        .finally(() => {});
    },
    getFreightcomPickups() {
      this.freightcom_pickups.loading = true;

      if (this.cancelSource['fc2']) {
        this.cancelSource['fc2'].cancel('Start new search, stop active search');
      }
      this.cancelSource['fc2'] = axios.CancelToken.source();
      this.$http
        .get(
          `/admin/analytics-carrier/freightcom-pickups?start_date=${
            this.start_date
          }&end_date=${this.end_date}`,
          {
            cancelToken: this.cancelSource['fc2'].token
          }
        )
        .then(response => response.data)
        .then(response => {
          this.freightcom_pickups.postage_fee = response.totals.postage_fee;
          this.freightcom_pickups.quantity = response.totals.quantity;

          this.freightcom_pickups.byDay = {
            labels: response.by_date.map(a => a.date),
            datasets: [
              {
                label: 'Postage',
                borderColor: '#9ccc65',
                data: response.by_date.map(a => a.postage_fee)
              }
            ]
          };
          this.freightcom_pickups.loading = false;
        })
        .finally(() => {});
    },
    getLandmark() {
      this.landmark.loading = true;

      if (this.cancelSource['landmark']) {
        this.cancelSource['landmark'].cancel(
          'Start new search, stop active search'
        );
      }
      this.cancelSource['landmark'] = axios.CancelToken.source();
      this.$http
        .get(
          `/admin/analytics-carrier/landmark?start_date=${
            this.start_date
          }&end_date=${this.end_date}`,
          {
            cancelToken: this.cancelSource['landmark'].token
          }
        )
        .then(response => response.data)
        .then(response => {
          this.landmark.postage_fee = response.totals.postage_fee;
          this.landmark.quantity = response.totals.quantity;

          this.landmark.byDay = {
            labels: response.by_date.map(a => a.date),
            datasets: [
              {
                label: 'Postage',
                borderColor: '#9ccc65',
                data: response.by_date.map(a => a.postage_fee)
              }
            ]
          };
          this.landmark.loading = false;
        })
        .finally(() => {});
    },
    getPostnl() {
      this.postnl.loading = true;

      if (this.cancelSource['postnl']) {
        this.cancelSource['postnl'].cancel(
          'Start new search, stop active search'
        );
      }
      this.cancelSource['postnl'] = axios.CancelToken.source();
      this.$http
        .get(
          `/admin/analytics-carrier/postnl?start_date=${
            this.start_date
          }&end_date=${this.end_date}`,
          {
            cancelToken: this.cancelSource['postnl'].token
          }
        )
        .then(response => response.data)
        .then(response => {
          this.postnl.postage_fee = response.totals.postage_fee;
          this.postnl.quantity = response.totals.quantity;

          this.postnl.byDay = {
            labels: response.by_date.map(a => a.date),
            datasets: [
              {
                label: 'Postage',
                borderColor: '#9ccc65',
                data: response.by_date.map(a => a.postage_fee)
              }
            ]
          };

          this.postnl.loading = false;
        })
        .finally(() => {});
    },
    getApc() {
      this.apc.loading = true;

      if (this.cancelSource['apc']) {
        this.cancelSource['apc'].cancel('Start new search, stop active search');
      }
      this.cancelSource['apc'] = axios.CancelToken.source();
      this.$http
        .get(
          `/admin/analytics-carrier/apc?start_date=${
            this.start_date
          }&end_date=${this.end_date}`,
          {
            cancelToken: this.cancelSource['apc'].token
          }
        )
        .then(response => response.data)
        .then(response => {
          this.apc.postage_fee = response.totals.postage_fee;
          this.apc.quantity = response.totals.quantity;

          this.apc.byDay = {
            labels: response.by_date.map(a => a.date),
            datasets: [
              {
                label: 'Postage',
                borderColor: '#9ccc65',
                data: response.by_date.map(a => a.postage_fee)
              }
            ]
          };

          this.apc.loading = false;
        })
        .finally(() => {});
    }
  }
};
</script>
