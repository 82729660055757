import Filter from './filters'
const filters = {
    defaultRule() {
        return {
            id: null,
            name: null,
            rule_actions: [],
            delete: false,
            selection: 'all',
            active: true,
            filter_id: null,
            filter: Filter.defaultFilter()

        };
    },
    defaultAction() {
        return {
            id: null,
            type: null,
            value: null
        };
    },
}

export default filters