import Vue from 'vue';
import Vuex from 'vuex';

import charges from './charges';
import claims from './claims';
import credits from './credits';
import expired from './expired';
import insurance from './insurance';
import pickups from './pickups';
import returns from './returns';
import shipments from './shipments';
import supplies from './supplies';
import users from './users';
import voids from './voids';

import moment from 'moment'

Vue.use(Vuex);

const initialDate = moment().format('YYYY-MM')

function initialState() {
    return {
        year_month: initialDate,
        start_date: moment(initialDate).startOf('month').format('YYYY-MM-DD'),
        end_date: moment(initialDate).endOf('month').format('YYYY-MM-DD'),
    }
}

const analytics = {
    namespaced: true,
    modules: {
        charges,
        claims,
        credits,
        expired,
        insurance,
        pickups,
        returns,
        shipments,
        supplies,
        users,
        voids
    },
    state: initialState(),
    getters: {
        loading: (state) => state.analytics.charges.loading
        
    },
    mutations: {
        setYearMonth: (state, value) => state.year_month = value,
        setStartDate: (state, value) => state.start_date = value,
        setEndDate: (state, value) => state.end_date = value,

    },
    actions: {
        setYearMonth: (context, value) => context.commit('setYearMonth', value),
        setStartDate: (context, value) => context.commit('setStartDate', value),
        setEndDate: (context, value) => context.commit('setEndDate', value),
        getData({ dispatch, state }) {
            dispatch('charges/getData', { startDate: state.start_date, endDate: state.end_date });
            dispatch('claims/getData', { startDate: state.start_date, endDate: state.end_date });
            dispatch('credits/getData', { startDate: state.start_date, endDate: state.end_date });
            dispatch('expired/getData', { startDate: state.start_date, endDate: state.end_date });
            dispatch('insurance/getData', { startDate: state.start_date, endDate: state.end_date });
            dispatch('pickups/getData', { startDate: state.start_date, endDate: state.end_date });
            dispatch('returns/getData', { startDate: state.start_date, endDate: state.end_date });
            dispatch('shipments/getData', { startDate: state.start_date, endDate: state.end_date });
            dispatch('supplies/getData', { startDate: state.start_date, endDate: state.end_date });
            dispatch('users/getData', { startDate: state.start_date, endDate: state.end_date });
            dispatch('voids/getData', { startDate: state.start_date, endDate: state.end_date });
        }
    }
};

export default analytics;