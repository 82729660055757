<template>
  <StallionDialog
    v-model="show"
    :width="500"
    title="Add To Batch"
    persistent
  >
    <template #content>
      <v-form>
        <v-container fluid>
          <v-row dense>
            <v-col cols="12">
              <div class="dialog-info">
                {{ shipments.length }}
                {{ shipments.length | pluralize(' shipment') }} ready to add
                to batch
              </div>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="batch_id"
                outlined
                :items="batches"
                label="Batches"
              />
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="name"
                label="Name (Optional)"
                clearable
                outlined
              />
            </v-col>
            <v-col cols="12">
              <v-checkbox
                v-model="start_validation"
                :value="true"
                dense
                label="Validate batch after import"
                class="mt-1"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
      
    <template #actions>
      <v-btn
        :loading="loading"
        :disabled="loading"
        color="primary"
        class="px-7"
        @click="moveToBatch()"
      >
        Save
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: {
    shipments: {
      type: Array,
      default: () => []
    },
    value: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      start_validation: true,
      batch_id: null,
      name: null,
      loading: false
    };
  },
  computed: {
    ...mapState(['batches', 'imp', 'filters']),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    batches() {
      return this.$store.getters['batches/openBatchesOptions'];
    }
  },
  watch: {
    batch_id: function (val) {
      this.name = null;
      if (val) {
        const index = this.batches.findIndex((x) => x.value === val);
        if (index > 0) {
          this.name = this.batches[index].name;
        }
      }
    },
    show: function (val) {
      if (val) {
        this.setDefaultName();
      }
    }
  },
  mounted() {
    this.$store.dispatch('batches/getOpenBatches');
  },

  methods: {
    setDefaultName() {
      this.name = null;

      const filterId = this.imp.selection.filters.filter_id;

      if (filterId) {
        const index = this.filters.filters.findIndex((x) => x.id == filterId);
        if (index >= 0) {
          this.name = this.filters.filters[index].name;
        }
      }
    },
    moveToBatch() {
      // const ids = this.shipments.map(a => a.id);
      this.loading = true;
      this.$http
        .post(`/batches/import`, {
          shipments: this.shipments,
          batch_id: this.batch_id,
          name: this.name,
          start_validation: this.start_validation
        })
        .then((response) => response.data)
        .then((response) => {
          const payload = {
            batch: response.batch,
            shipments: response.shipments
          };
          this.show = false;

          this.$store.dispatch('userStores/getStores');
          this.$store.dispatch('filters/getFilters');
          this.$store.dispatch('batches/getOpenBatches');
          this.$store.commit('imp/validationStart', payload);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
