<template>
  <v-tooltip top>
    <template #activator="{ on }">
      <v-btn
        icon
        small
        :disabled="disableWeightFetch"
        @click="getWeight"
        v-on="on"
      >
        <v-icon color="orange lighten-1">
          mdi-scale
        </v-icon>
      </v-btn>
    </template>
    <span>Get scale weight <br>(Ctrl + m)</span>
  </v-tooltip>
</template>

<script>

import {mapState} from "vuex";
import Vue from "vue";

export default {
  name: "AutoScaleButton",
  data() {
    return {
      weight: null,
      fetchWeight: '',
      weightStorage: [],
      disableWeightFetch: false,
    }
  },

  computed: {
    ...mapState(['userStores']),
    shipment: {
      get() {
        return this.$store.state.singleFlow.shipment;
      },
      set(value) {
        this.$store.state.singleFlow.shipment = value;
      }
    },
  },

  watch: {
    '$store.state.singleFlow.step': function() {
      if(this.$store.state.singleFlow.step === 3 && this.$store.state.qz.enabledAutoWeightFetch){
        this.startWeightRead();
      } else {
        this.stopScaleStream();
      }
    },
  },

  mounted() {
    Event.listen('set-auto-weights', (weight) => {
      this.shipment.weight = weight.data.weight;
      this.shipment.weight_unit = weight.data.weight_unit;
    });

    if (this.$store.state.qz.qzStatus && this.$store.state.qz.enabledAutoWeightFetch) {
      this.startWeightRead();
    }
    this.$qz.hid.setHidCallbacks(async (streamEvent) => {
      if(streamEvent.type === "RECEIVE"){
        let newWeight = await this.parseScaleStreamData(streamEvent.output);
        console.log("weight",newWeight.data.weight);
        if(newWeight.status){
          setTimeout(() => {
            this.disableWeightFetch = false;
          }, 1000);
          this.setAutoWeight(newWeight);
        }
      } else if(streamEvent.type === "ERROR"){
        await this.$store.dispatch('qz/updateCurrentScaleStream', false);
        this.disableWeightFetch = false;
        console.log('Stream closed / scale disconnected');
        // await this.stopScaleStream();
        if(this.$qz.websocket.isActive() && this.$store.state.qz.enabledAutoWeightFetch && this.$store.state.qz.autoReconnect){
          console.log('Reconnecting scale');
          await this.autoReconnectScale();
        }
      }
    });

    window.addEventListener('keydown', this.handleKeydown);
  },

  destroyed() {
    this.stopScaleStream();

    window.removeEventListener('keydown', this.handleKeydown);
  },

  methods: {
    async getWeight() {
      this.disableWeightFetch = true;
      try {
        if (!this.$store.state.qz.currentScaleStream && this.$store.state.qz.qzStatus && this.$store.state.qz.enabledAutoWeightFetch) {
          await this.startWeightRead();
        } else {
          let scaleData = await this.getScaleData();
          this.setWeight(scaleData);
        }
      } catch (e) {
        console.log(e);
        if(!this.$qz.websocket.isActive()){
          await this.$qz.websocket.connect();
        }
       await this.errorNotify(e.message);
      }
    },

    async startWeightRead() {
      if(!this.$store.state.qz.currentScaleStream) {
        let val = await this.startScaleStream();
        if(!val){
          this.disableWeightFetch = false;
        }
      }
    },

    setWeight(scaleData) {
      setTimeout(() => {
        this.disableWeightFetch = false;
      }, 200);
      if (scaleData.status) {
        Vue.notify({
          group: 'main',
          text: `Weight: ${scaleData.data.weight} ${scaleData.data.weight_unit}`,
          duration: 2000,
          type: 'success'
        });

        this.$emit('weight', scaleData);
      }
    },
    handleKeydown(event) {
      if (event.ctrlKey && event.key === 'm') {
        event.preventDefault();
        this.getWeight(); // Trigger weight fetching on Ctrl + M
      }
    },
  }
}
</script>

<style scoped>

</style>
