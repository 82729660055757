<template>
  <v-card
    class="settings-card"
    flat
  >
    <v-container class="px-0 pt-0">
      <h3>Prefrences</h3>
      <p class="mb-2">
        Control application settings of your account
      </p>
    </v-container>

    <v-container class="pa-0">
      <v-row>
        <v-col cols="12">
          <h3>Returns</h3>
          <user-settings-option
            v-for="(list, index) in newSettings.returns"
            :key="index"
            :list="list"
          />
        </v-col>
        <v-divider />
        <v-col cols="12">
          <h3>Shipment Wizard</h3>
          <user-settings-option
            v-for="(list, index) in newSettings.single_flow"
            :key="index"
            :list="list"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <v-btn
            color="primary"
            @click="submit"
          >
            Update
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import {
  // showErrorMsg,
  // swal,
  // handleErrorResponse
} from '../../../helpers/helper';

export default {
  data() {
    return {
      isEditing: false,
      newSettings: {
        returns: _.cloneDeep(this.$auth.user.user_config_settings.returns),
        single_flow: _.cloneDeep(this.$auth.user.user_config_settings.single_flow)
      },
      processing: false,
      errors: []
    };
  },
  methods: {
    cancel() {
      this.isEditing = false;
      this.newSettings = _.cloneDeep(this.$auth.user.user_config_settings);
    },
    changeUserSettings() {
      this.isEditing = true;
    },
    submit() {
      this.$validator.validate().then(result => {
        if (result) {
          this.processing = true;
          this.$http
            .post('/users/updateUserConfigSettings', this.newSettings)
            .then(res => {
              // console.log(res);
              if (Object.prototype.hasOwnProperty.call(res.data, 'message')) {
                if (res.data.success) {
                  this.successMessage('Your account changes have been saved');
                } else {
                  this.errorMessage('Your account changes have not been saved');
                }
              }
            });
        }
      });
    },
    swalError() {
      swal({
        title: 'Error',
        text: 'Some of the form fields are invalid.',
        icon: 'error'
      });
    },
    swalSuccess() {
      swal({
        title: 'Success',
        text: 'Your preferred settings have been saved',
        icon: 'success'
      });
    }
  }
};
</script>
