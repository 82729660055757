<template>
  <v-container v-if="$auth.user">
    <v-flex xs12>
      <h1>Sort {{ sortType }} {{ $auth.user.employee_location.sort_method }}</h1>
    </v-flex>

    <v-layout>
      <v-flex
        xs12
        md6
        offset-md3
      >
        <!-- loading dialog -->
        <v-dialog
          v-model="dialog2"
          persistent
          width="300"
        >
          <v-card dark>
            <v-card-text>
              Getting Users
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              />
            </v-card-text>
          </v-card>
        </v-dialog>

        <!-- Filter -->
        <v-switch
          v-model="filterScannedItems"
          label="Filter Only My Scanned Items"
        />
        <v-card
          v-if="items.length > 0"
          outlined
        >
          <!-- List -->
          <v-list
            two-line
            class="pa-0"
          >
            <template v-for="(item, index) in items">
              <v-list-item
                v-if="item.user"
                :key="item.user.id"
                ripple
                :class="{
                  warning: item.user.max >= 400,
                  dark: item.user.max >= 400,
                  red: item.user.check_non_fba,
                  dark: item.user.check_non_fba
                }"
              >
                <v-list-item-avatar @click="select(item)">
                  <v-icon size="50">
                    account_circle
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content @click="select(item)">
                  <v-list-item-title>
                    {{ item.user.business_name || item.user.name }} -
                    {{ item.user.id }}
                    {{ item.user.max }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    v-html="item.shipments.length + ' Shipments'"
                  />

                  <v-list-item-subtitle
                    v-if="item.scanned_users && item.scanned_users.length > 0"
                  >
                    Last Scanned By: {{ item.scanned_users.join(', ') }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn
                    icon
                    ripple
                    @click="
                      selected = item;
                      dialog3 = true;
                    "
                  >
                    <v-icon color="grey lighten-1">
                      info
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider
                v-if="index != items.length - 1"
                :key="index"
                class="ma-0"
              />
            </template>
          </v-list>
        </v-card>
        <v-alert
          v-else-if="!dialog2 && items.length == 0"
          class="mt-5"
          outlined
          type="success"
        >
          Nothing to sort
        </v-alert>
      </v-flex>

      <!-- sort dialog -->
      <v-dialog
        v-if="selected"
        v-model="dialog"
        max-width="500"
        persistent
      >
        <v-card>
          <v-card-title class="text-h3">
            Assign {{ selected.business_name || selected.name }}
          </v-card-title>
          <v-card-text>
            <v-form v-if="$auth.user.employee_location.sort_method == 'Manual'">
              <v-container>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-select
                      v-model="box"
                      v-validate="'required'"
                      :items="boxes"
                      label="Box"
                      outlined
                      data-vv-name="box"
                      :error-messages="errs.collect('box')"
                    />
                  </v-flex>
                  <v-flex xs6>
                    <v-select
                      v-model="type"
                      v-validate="'required'"
                      :items="types"
                      label="Bags"
                      outlined
                      data-vv-name="bags"
                      :error-messages="errs.collect('bags')"
                    />
                  </v-flex>
                  <v-flex xs6>
                    <v-select
                      v-model="bags"
                      v-validate="'required'"
                      :items="[
                        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,
                        17, 18, 19, 20, 21, 22, 23, 24, 25
                      ]"
                      label="Bags"
                      outlined
                      data-vv-name="bags_count"
                      :error-messages="errs.collect('bags_count')"
                    />
                  </v-flex>
                  <v-flex xs12>
                    <v-select
                      v-model="truck"
                      :items="trucks"
                      item-text="description"
                      item-value="id"
                      label="Truck"
                      outlined
                    />
                  </v-flex>
                  <v-flex xs12>
                    <v-textarea
                      v-model="comment"
                      outlined
                      label="Comment"
                    />
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
            <v-form v-if="$auth.user.employee_location.sort_method == 'NPI'">
              <v-select
                v-model="container_id"
                v-validate="'required'"
                :items="containers"
                item-text="name"
                item-value="id"
                label="Container"
                outlined
                data-vv-name="container"
                :error-messages="errs.collect('container')"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              text="text"
              @click="dialog = false"
            >
              Cancel
            </v-btn>
            <v-spacer />
            <v-btn
              v-if="$auth.user.employee_location.sort_method == 'Manual'"
              color="primary"
              @click="setBox(selected)"
            >
              Assign
            </v-btn>
            
            <v-btn
              v-if="$auth.user.employee_location.sort_method == 'NPI'"
              color="primary"
              @click="manualTransfer(selected)"
            >
              Assign
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- fullscreen -->
      <v-dialog
        v-model="dialog3"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark>
            <v-btn
              icon
              @click="dialog3 = false"
            >
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>Shipments</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn
                dark
                text
                @click="exportShipments"
              >
                Export
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-data-table
            :items="selected.shipments"
            hide-headers
            :headers="headers"
            :footer-props="{
              'items-per-page-options': [50, 100, 200, 500]
            }"
          >
            <template #item.created_at="{ item }">
              {{ item.created_at | date_pretty }}
              <br>
              ({{ item.created_at | datetime_from }})
            </template>

            <template #item.status_id="{ item }">
              <div
                v-html="
                  $options.filters.status(item.shipment_status.description)
                "
              />
            </template>

            <template #item.postage_rate="{ item }">
              {{ item.postage_rate | currency }}
            </template>

            <template #item.postage_type_id="{ item }">
              {{ item.postage_type }}
            </template>

            <template #body="props">
              <tbody>
                <tr
                  v-for="item in props.items"
                  :key="item.id"
                  :class="{ warning: item.value >= 400 }"
                >
                  <td>
                    {{ item.name }}
                    <br>
                    {{ item.address1 }} {{ item.address1 }}, {{ item.city }}
                    {{ item.province_code }}
                    <br>
                    {{ item.postal_code }} {{ item.country_code }}
                  </td>
                  <td>{{ item.package_contents }}</td>
                  <td>{{ item.postage_type }}</td>
                  <td>{{ item.weight }}{{ item.weight_unit }}</td>
                  <td>{{ item.value | currency }} {{ item.currency }}</td>
                  <td
                    v-if="item.shipment_status"
                    v-html="
                      $options.filters.status(item.shipment_status.description)
                    "
                  />
                  <td v-else />
                  <td>
                    {{ item.created_at | date_pretty }}
                    <br>
                    ({{ item.created_at | datetime_from }})
                  </td>
                  <td v-if="can('shipments.edit')">
                    <v-icon
                      small
                      @click="deleteItem(item)"
                    >
                      delete
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  props: {
    sortType: {
      default: null,
      type: String
    },
  },
  data() {
    return {
      filterScannedItems: true,
      items: [],
      selected: {
        shipments: []
      },
      trucks: [],
      truck: null,
      containers: [],
      container_id: null,
      headers: [
        {
          text: 'Recipient',
          align: 'left',

          value: 'name'
        },
        {
          text: 'Country',
          value: 'country_code',
          class: 'hidden-sm-and-down',
          sortable: false
        },
        {
          text: 'Postage',
          value: 'postage_type_id'
        },
        {
          text: 'Rate',
          value: 'postage_rate',
          class: 'hidden-sm-and-down'
        },
        {
          text: 'Status',
          value: 'status_id'
        },
        {
          text: 'Batch',
          value: 'batch_id',
          class: 'hidden-sm-and-down',
          sortable: false
        },
        {
          text: 'Closeout',
          value: 'closeout_id',
          class: 'hidden-sm-and-down',
          sortable: false
        },
        {
          text: 'Created',
          value: 'created_at'
        }
      ],

      dialog: false,
      dialog2: false,
      dialog3: false,
      boxes: [],

      types: ['Bag', 'Box', 'Mix', 'Skid'],
      type: 'Bag',

      box: null,
      comment: null,
      bags: 1,

      // items: [
      //   {
      //     text: 'Admin',
      //     disabled: false,
      //     exact: true,
      //     to: '/admin'
      //   },
      //   {
      //     text: 'Process',
      //     disabled: false,
      //     to: 'breadcrumbs_link_1'
      //   },
      //   {
      //     text: 'Non-FBA',
      //     disabled: true,
      //     to: 'admin/fba'
      //   }
      // ],

      usps_postage_types: [
        'priority-express',
        'parcel-select',
        'usps-general',
        'media-library'
      ],
       carriers:[]
    };
  },
  watch: {
    sortType: {
      handler() {
        this.getUsers();
        this.setOptions();
        this.getContainers();
      },
      deep: true
    },
    filterScannedItems: {
      handler() {
        this.getUsers();
        this.setOptions();
      }
    }
  },
  mounted() {
    this.getUsers();
    this.getTrucks();
    this.setOptions();
    this.getContainers();
  },
  created() {
    document.activeElement.blur();
    // Add barcode scan listener and pass the callback function
    this.$barcodeScanner.init(this.onBarcodeScanned);
    console.log('this.$barcodeScanner init');
  },
  destroyed() {
    // Remove listener when component is destroyed
    this.$barcodeScanner.destroy();
    console.log('this.$barcodeScanner destroy');
  },
  methods: {
    async onBarcodeScanned(barcode) {
      console.log('onBarcodeScanned', barcode);
      // select container_id by barcode
      this.container_id = this.containers.find((x) => x.barcode == barcode)
        ?.id;

    },
    manualTransfer(item){
      this.$validator.validate().then((result) => {
        if (result) {
      var ids = item.shipments.map((x) => x.id);
      
      this.$http
          .request(`/admin/containers/manual-transfer`, {
            method: 'post',
            data: {
              ids,
              container_id: this.container_id,
            },
          })
          .then((response) => response.data)
          .then(() => {
            this.$notify({
                group: 'main',
                title: 'Shipments Updated',
                text: `${
                  item.user.business_name || item.user.name
                } has been assigned to a container`,
                duration: 4000
              });

              var index = this.items.indexOf(item);
              if (index !== -1) this.items.splice(index, 1);
              this.selected = { shipments: [] };


          }) .finally(() => {
              this.dialog = false;
            });
          }})
    },
    getContainers() {
      const params = {
        types: ['Bag', 'Gaylord'],
        statuses: ['Open'],
        per_page: 1000,
       carriers: this.carriers,
      };

      this.$http
          .request(`/admin/containers`, {
            method: 'get',
            params: params,
          })
          .then((response) => response.data)
          .then((response) => {
           this.containers = response.data.map((container) => {
             return {
               ...container,
               name:  `${container.name} - ${container.type} - ${container.day_code}`
             };
           });
          });
    },
    getUsers() {
      this.dialog2 = true;
      this.$http
        .get(
          `/admin/sort/${this.sortType}?filterSannedItems=${this.filterScannedItems}`
        )
        .then((response) => response.data)
        .then((response) => {
          this.items = response;

          this.items.forEach(function (item) {
            if (item.shipments.length == 1) {
              item.max = item.shipments[0].value;
            } else {
              item.max = item.shipments.reduce(function (prev, current) {
                return prev.value > current.value ? prev.value : current.value;
              });
            }
          });
        })
        .finally(() => {
          this.dialog2 = false;
        });
    },
    getTrucks() {
      this.$http
        .get(`/trucks`)
        .then((response) => response.data)
        .then((response) => {
          this.trucks = response;
          this.truck = this.trucks[0].id;
        });
    },
    setBox(item) {
      this.$validator.validate().then((result) => {
        if (result) {
          var ids = item.shipments.map((x) => x.id);
          this.$http
            .post(`/admin/sort/non-fba`, {
              ids,
              box: this.box,
              comment: this.comment,
              bags: this.bags,
              type: this.type,
              truck: this.truck
            })
            .then((response) => response.data)
            .then(() => {
              this.$notify({
                group: 'main',
                title: 'Media Mail Updated',
                text: `${
                  item.user.business_name || item.user.name
                } has been assigned to a box`,
                duration: 4000
              });

              var index = this.items.indexOf(item);
              if (index !== -1) this.items.splice(index, 1);
              this.selected = { shipments: [] };
            })
            .catch(() => {})
            .finally(() => {
              this.dialog = false;
            });
        }
      });
    },
    select(item) {
      this.dialog = true;
      this.selected = item;
    },
    exportShipments() {
      let exportBy = this.sortType;
      if (this.usps_postage_types.includes(this.sortType)) {
        exportBy = 'usps';
      }
      this.$http
        .post(`/admin/export/${exportBy}`, { user: this.selected.user })
        .then((response) => {this.downloadFileUrl(response.data,`${
            this.selected.user.business_name || this.selected.user.name
          } ${this.sortType}.csv`, 'text/csv');
        });
    },
    deleteItem(shipment) {
      this.$http
        .post('/admin/shipments/update-statuses', {
          ids: [shipment.id],
          status: 4
        })
        .then(() => {
          this.$notify({
            group: 'main',
            title: 'Shipment Updated',
            text: `${
              shipment.ship_code || this.selected.name
            } shipment has been moved to ready`,
            duration: 4000
          });
          var index = this.selected.shipments.indexOf(shipment);
          this.selected.shipments.splice(index, 1);
          //          Event.fire('admin-update-shipments');
          this.getUsers();
        })
        .finally(() => {});
    },
    setOptions() {
      this.boxes = [];
      this.box = null;
      var s = [];
      if (this.sortType == 'media-library') {
        s = [
          {
            name: 'Mix Pallet',
            count: 4
          },
          {
            name: 'Media Mail',
            count: 40
          }
        ];

        this.carriers = ['USPS Media Mail'];
      } else if (this.sortType == 'usps-general' || this.sortType == 'usps-non-track') {
        s = [
          {
            name: 'Mix Pallet',
            count: 4
          },
          {
            name: 'USPS',
            count: 40
          }
        ];

        this.carriers = ['USPS'];
      } else if (this.sortType == 'ups-non-fba') {
        s = [
          {
            name: 'UPS',
            count: 40
          }
        ];

        this.carriers = ['UPS'];
      } else if (this.sortType == 'fedex-non-fba') {
        s = [
          {
            name: 'FedEx',
            count: 40
          }
        ];

        this.carriers = ['FedEx'];
      } else if (this.sortType == 'fedex-express') {
        s = [
          {
            name: 'FedEx Express',
            count: 40
          }
        ];

        this.carriers = ['FedEx'];
      } else if (this.sortType == 'apc') {
        s = [
          {
            name: 'APC',
            count: 40
          }
        ];

        this.carriers = ['APC'];
      } else if (this.sortType == 'osm') {
        s = [
          {
            name: 'OSM',
            count: 40
          }
        ];

        this.carriers = ['OSM'];
      } else if (this.sortType == 'ups-mail-innovations') {
        s = [
          {
            name: 'UPS Mail Innovations',
            count: 40
          }
        ];

        this.carriers = ['UPS Mail Innovations'];
      }

      s.forEach((option) => {
        console.log(option);
        for (var i = 1; i <= option.count; i++) {
          this.boxes.push(`${option.name} ${i}`);
        }
      });
    }
  }
};
</script>
