<template>
  <SidePanelLayout :title="`Return Trip ${tripNumber}`">
    <template #side-panel>
      <return-details
        style="font-size: 14px;"
        :returns="selected"
      />
    </template>

    <template #default>
      <v-layout v-if="trip">
        <v-flex
          v-if="trip.output_files"
          mt-12
          xs12
        >
          <v-card>
            <v-card-text v-if="trip.input_file">
              <h2>Input File</h2>
              <a
                href="javascript:void(0);"
                @click="download(trip.input_file)"
              >{{ trip.input_file | file_download }}</a>
            </v-card-text>
            <v-card-text v-if="trip.output_files">
              <h2>Output Files</h2>

              <div
                v-for="file in trip.output_files"
                :key="file"
              >
                <a
                  href="javascript:void(0);"
                  @click="download(file)"
                >
                  {{ file | file_download }}
                </a>
              </div>
            </v-card-text>
            <v-card-text v-if="trip.to_ship_files">
              <h2>To Ship Files</h2>
              <div
                v-for="file in trip.to_ship_files"
                :key="file"
              >
                <a
                  href="javascript:void(0);"
                  @click="download(file)"
                >
                  {{ file | file_download }}
                </a>
              </div>
            </v-card-text>
            <v-card-text>
              <h2>Sorted File</h2>
              <v-btn
                color="primary"
                @click="generate('sorted')"
              >
                GENERATE
              </v-btn>
            </v-card-text>
            <v-card-text>
              <h2>Pre Sorting Files</h2>
              <v-btn
                v-if="pre_button"
                color="primary"
                @click="generate('pre-sorted')"
              >
                GENERATE
              </v-btn>
              <div
                v-for="file in presorted_files"
                :key="file"
              >
                <a
                  href="javascript:void(0);"
                  @click="download(file)"
                >
                  {{ file | file_download }}
                </a>
              </div>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex xs12>
          <admin-returns-table
            v-model="selected"
            :trip-number="tripNumber"
          />
        </v-flex>
      </v-layout>
      <request-return
        v-model="showRequestReturn"
        :returns="selected"
      />
      <update-return-status
        v-model="show"
        :returns="selected"
      />
    </template>

    <template #footer>
      <v-btn
        color="primary"
        :disabled="selected.length == 0"
        @click="remove"
      >
        <v-icon left>
          mdi-minus
        </v-icon>Remove
      </v-btn>
      <v-btn
        color="primary"
        :disabled="selected.length == 0"
        @click="show = true"
      >
        <v-icon left>
          mdi-pencil
        </v-icon>Status
      </v-btn>
      <v-btn
        color="primary"
        :disabled="!selectedReturns"
        @click="showRequestReturn = true"
      >
        Request Returns
      </v-btn>
    </template>
  </SidePanelLayout>
</template>

<script>
export default {
  props: {
    tripNumber: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      status: '',
      selected: [],
      show: false,
      edit_status: true,
      showRequestReturn: false,
      trip: null,
      presorted_files: [],
      pre_button: true,
    };
  },
  computed: {
    selectedReturns() {
      if (
        this.selected.length != 0 &&
        this.selected.every(r => r.service_id === 1)
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.getTrip();
  },
  methods: {
    getTrip() {
      this.$http
        .get(`/admin/return-trips/${this.tripNumber}`)
        .then(response => response.data)
        .then(response => {
          this.trip = response;
          console.log(this.trip.valid);
          if (this.trip.valid === null) {
            this.auto_refresh = true;
            var self = this;
            setTimeout(function() {
              self.getTrip();
            }, 5000);
          } else {
            this.auto_refresh = false;
          }
        })
        .catch(err => {
          this.processing = false;
          swal({
            title: 'Error',
            text: err,
            icon: 'error'
          });
        });
    },
    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.pagination.page = 1;
      this.timer = setTimeout(function() {
        self.getDataFromApi().then(data => {
          self.desserts = data.items;
          self.totalDesserts = data.total;
          self.numPages = data.pages;
        });
      }, ms);
    },
    remove() {
      this.$http
        .post('/admin/return-trips/remove', {
          returns: this.selected
        })
        .then(response => {
          if (response.data.success) {
            Event.fire(
              'show-snackbar',
              this.selected.length + ' returns have been removed.'
            );

            this.selected = [];

            Event.fire('update-admin-returns');
          } else {
            swal({
              title: 'Error',
              text: 'error',
              icon: 'error'
            });
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },
    download: function(data) {
      /* eslint-disable no-useless-escape */
      var filename = data.replace(/^.*[\\\/]/, '');
      this.$http
        .post('/admin/return-trips/download', { file: data })
        .then(response => {
          this.downloadFileUrl(response.data, filename, 'text/csv');
        });
    },
    generate($type) {
      if($type === 'sorted') {
        let file = btoa(this.trip.output_files[1]);
        this.$http
            .get(`/admin/return-trips/sorted/${this.tripNumber}/download?input=${file}`)
            .then(response => {
              let filename = 'sorted_'+this.tripNumber+'.csv'
              this.downloadFileUrl(response.data, filename, 'text/csv');
            })
            .catch(err => {
              this.processing = false;
              swal({
                title: 'Error',
                text: err,
                icon: 'error'
              });
            });
      } else {
        this.pre_button = false;
        let file = btoa(JSON.stringify(this.trip.to_ship_files));
        this.$http
            .get(`/admin/return-trips/pre-sorting/${this.tripNumber}/download?input=${file}`)
            .then(response => {
              this.presorted_files = response.data;
            })
            .catch(err => {
              this.pre_button = true;
              swal({
                title: 'Error',
                text: err,
                icon: 'error'
              });
            });
      }
    }
  }
};
</script>
