<template>
  <v-card outlined>
    <v-container
      container--fluid
      grid-list-lg
    >
      <v-layout wrap>
        <v-flex xs12>
          <div>
            <v-text-field
              v-model="user_email"
              outlined
              label="Email"
              type="email"
              prepend-inner-icon="email"
            />
          </div>
          <div>
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template #activator="{ on }">
                <v-text-field
                  v-model="start_dropoff_date"
                  label="Start Date"
                  prepend-inner-icon="event"
                  readonly
                  outlined
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="start_dropoff_date"
                @input="menu1 = false"
              />
            </v-menu>

            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template #activator="{ on }">
                <v-text-field
                  v-model="end_dropoff_date"
                  label="End Date"
                  prepend-inner-icon="event"
                  readonly
                  outlined
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="end_dropoff_date"
                @input="menu2 = false"
              />
            </v-menu>
          </div>

          <v-layout
            align-center
            class="mt-4"
          >
            <v-flex
              xs12
              text-center
            >
              <v-btn
                :loading="loading1"
                :disabled="loading1"
                color="primary"
                @click="getExport"
              >
                Download
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
export default {
  // props: ['filter'],
  data() {
    return {
      form2: new FormData(),
      export_type: 'By User Email',
      start_dropoff_date: moment().format('YYYY-MM-DD'),
      end_dropoff_date: moment().format('YYYY-MM-DD'),
      user_email: '',

      menu1: false,
      menu2: null,
      loading1: false
    };
  },

  methods: {
    customFormatter(date) {
      return moment(date).format('MMMM Do YYYY, h:mm:ss a');
    },

    getExport() {
      this.loading1 = true;

      this.$http
        .get(
          `/admin/export?export_type=${this.export_type}&start_date=${
            this.start_dropoff_date
          }&end_date=${this.end_dropoff_date}&user_email=${this.user_email}`
        )
        .then(response => {
          this.downloadFileUrl(response.data, this.export_type +
              ' (' +
              moment().format('YYYYmmdHHMMSS') +
              ').csv', 'text/csv');
        })
        .catch(err => {
          if (err.response.status === 404) {
            this.errorMessage('Nothing found');
          } else {
            this.errorMessage();
          }
        })
        .finally(() => {
          this.loading1 = false;
        });
    }
  }
};
</script>
