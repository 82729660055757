<template>
  <StallionDialog
    v-model="dialogDisplay"
    :width="600"
    :disabled="dialogDisabled"
    :fullscreen="$vuetify.breakpoint.mobile"
    title="Add Card"
  >
    <template #content>
      <div>
        <v-form id="payment-form">
          <v-container>
            <v-row>
              <v-col
                v-if="dropinInstance"
                cols="12"
              >
                <p>
                  Your credit card information is securely stored by our payment
                  provider. Stallion Express does not have access to this
                  information.
                </p>
              </v-col>

              <v-col
                v-if="!dropinInstance && processing"
                cols="12"
              >
                <v-alert
                  outlined
                  type="info"
                  color="primary"
                >
                  Generating Payment Form...
                </v-alert>
              </v-col>

              <v-col cols="12">
                <div
                  :id="dropinContainerId"
                  style="margin-top: -30px"
                />

                <v-switch
                  v-if="dropinInstance"
                  v-model="is_default"
                  :disabled="!hasCard"
                  label="Set as default"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </div>
    </template>

    <template #actions>
      <v-btn
        :id="dropinSubmitBtnId"
        color="primary"
        :loading="processing"
        class="pay-with-stripe"
        type="submit"
        :disabled="processing"
      >
        Add
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
import braintree from 'braintree-web-drop-in';
export default {
  props: {
    disabled: {
      default: false,
      type: Boolean
    },
    // display: {
    //   default: false,
    //   type: Boolean
    // },
    hasCard: {
      default: false,
      type: Boolean
    }
  },

  data() {
    return {
      dialogDisplay: false,
      dialogDisabled: this.disabled,
      processing: false,

      is_default: true,

      dropinInstance: null,
      dropinContainerId: 'dropin-container',
      dropinSubmitBtnId: 'submit-button',
      createDropinCallback: null
    };
  },
  watch: {
    dialogDisplay(val) {
      if (val) {
        this.initializeBraintree();
      }
    }
  },
  destroyed() {
    this.destroyDropinInstance();
  },
  methods: {
    openDialog() {
      this.dialogDisplay = true;
    },
    initializeBraintree() {
      if (this.dropinInstance) return;
      this.processing = true;
      this.$http
        .post('/credit-card/auth-token')
        .then((res) => {
          if (res.data.success) {
            this.createDropinInstance(res.data.token);
          } else {
            window.swal({
              title: 'Error!',
              text: res.data.error,
              icon: 'error'
            });
          }
        })
        .catch(() => {
          // console.log(err);
          window.swal({
            title: 'Error!',
            text: 'There was a problem generating payment token',
            icon: 'error'
          });
        })
        .finally(() => {
          this.processing = false;
        });
    },
    createDropinInstance(authToken) {
      const submitButton = document.querySelector(`#${this.dropinSubmitBtnId}`);
      if (this.createDropinCallback)
        submitButton.removeEventListener('click', this.createDropinCallback);

      this.processing = true;
      braintree
        .create({
          authorization: authToken,
          container: `#${this.dropinContainerId}`,
          card: {
            cardholderName: {
              required: true
              // to make cardholder name required
              // required: true
            },
            // https://developer.paypal.com/braintree/docs/guides/drop-in/customization/javascript/v3#field-overrides
            overrides: {
              fields: {
                // number: {
                //   placeholder: '1111 1111 1111 1111'
                // },
                postalCode: {
                  minlength: 3,
                  type:'text'
                },
                // cvv: {
                //   selector: '#cvv'
                // }
              },
              styles: {
                input: {
                  'font-size': '18px' // Change the font size for all inputs
                },
                ':focus': {
                  color: 'red' // Change the focus color to red for all inputs
                }
              }
            },
          }
        })
        .then((dropinInstance) => {
          this.dropinInstance = dropinInstance;
          this.createDropinCallback = () => {
            dropinInstance.requestPaymentMethod().then((payload) => {
              // Send payload.nonce to your server
              this.addCard(payload);
            });
          };
          submitButton.addEventListener('click', this.createDropinCallback);
        })
        .catch(() => {
          // Handle any errors that might've occurred when creating Drop-in
          // console.error(err);
          window.swal({
            title: 'Error!',
            text: 'There was a problem creating payment gateway',
            icon: 'error'
          });
        })
        .finally(() => {
          this.processing = false;
        });
    },
    destroyDropinInstance() {
      if (this.dropinInstance) {
        try {
          this.dropinInstance.teardown(() => {
            this.dropinInstance = null;
          });
        } catch (err) {
          //
        }
      }
    },
    displayBraintreeToggle(display = 'flex') {
      const elements = document.querySelectorAll(
        '[data-braintree-id="toggle"]'
      );
      (elements || []).forEach((el) => {
        el.style.display = display;
      });
    },
    addCard(dropinPayload) {
      this.processing = true;
      this.displayBraintreeToggle('none');
      this.$http
        .post('/credit-card/add', {
          nonce: dropinPayload.nonce,
          card_type: dropinPayload.details.cardType,
          expiration_month: dropinPayload.details.expirationMonth,
          expiration_year: dropinPayload.details.expirationYear,
          last_four: dropinPayload.details.lastFour,
          is_default: this.is_default
        })
        .then((res) => {
          if (res.data.success) {
            Event.fire('card-created', res.data.card);
            this.dialogDisplay = false;
            this.destroyDropinInstance();
            window.swal({
              title: 'Success',
              text: res.data.message,
              icon: 'success'
            });
            this.$emit('getUserCards');
          } else {
            window.swal({
              title: 'Error',
              text: res.data.error,
              icon: 'error'
            });
          }
        })
        .catch(() => {
          window.swal({
            title: 'Error!',
            text: 'Could not add your card.',
            icon: 'error'
          });
        })
        .finally(() => {
          this.processing = false;
          this.displayBraintreeToggle();
        });
    }
  }
};
</script>
