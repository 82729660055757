<template>
  <FullPageLayout title="Claims">
    <template #default>
      <ClaimsTable :filter="'Pending'" />
    </template>

    <template #footer>
      <v-btn
        color="primary"
        to="/admin/claims/new"
      >
        New Claim
      </v-btn>
    </template>
  </FullPageLayout>
</template>

<script>
import ClaimsTable from './ClaimsTable.vue'
export default {
  components:{
    ClaimsTable
  },
  props: {
    filter: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      drawer: null
    };
  },
  computed: {}
};
</script>
