<template>
  <FullPageLayout title="System Settings">
    <template #default>
      <v-card outlined>
        <v-dialog
          v-model="loading"
          persistent
          width="300"
        >
          <v-card dark>
            <v-card-text>
              <v-layout
                justify-center
                class="pt-3"
              >
                Retrieving Settings
                <atom-spinner
                  :animation-duration="2000"
                  :size="100"
                  :color="'#f9ac40'"
                />
              </v-layout>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-tabs
          v-model="tabSelected"
          :center-active="$vuetify.breakpoint.mobile"
          :show-arrows="$vuetify.breakpoint.mobile"
          :left="!$vuetify.breakpoint.mobile"
          :vertical="!$vuetify.breakpoint.mobile"
          :class="{ 'my-tabs': !$vuetify.breakpoint.mobile }"
          class="elevation-0"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.title + '1'"
          >
            <v-icon
              small
              left
            >
              {{ tab.icon }}
            </v-icon>
            <h4 class="settings-tab">
              {{ tab.title }}
            </h4>
          </v-tab>

          <v-tab-item
            v-for="tab in tabs"
            :key="tab.title + '2'"
          >
            <v-card
              flat
              max-width="800"
              class="settings-card"
            >
              <v-card-title>{{ tab.title }}</v-card-title>
              <v-card-text>
                <component
                  :is="tab.component"
                  :settings="tab.props || []"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </template>
  </FullPageLayout>
</template>

<script>
import { AtomSpinner } from 'epic-spinners';
import SecuritySettings from './tabs/SecuritySettings.vue';
export default {
  components: {
    AtomSpinner,
    SecuritySettings
  },
  data() {
    return {
      tabSelected: null,
      loading: false,
      systemSettings: []
    };
  },
  computed: {
    tabs() {
      let tabs = [
        {
          title: 'Security Settings',
          icon: 'mdi-key',
          component: 'security-settings',
          props: this.systemSettings.security || []
        }
      ];

      return tabs;
    }
  },
  created() {
    this.getSystemSettings();
  },
  methods: {
    getSystemSettings() {
      this.loading = true;
      this.$http
        .get('/admin/system-settings')
        .then((res) => {
          this.systemSettings = res.data;
        })
        .catch(() => {
          this.successMessage(
            'Unknown Error ocurred while retrieving system settings!'
          );
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped>
.settings-tab {
  font-size: 13px;
  font-weight: 700;
}

div.my-tabs [role='tab'] {
  justify-content: flex-start;
  padding-left: 25px;
  padding-right: 25px;
}

.settings-card {
  padding: 25px !important;
  border-left: 1px solid #e7e7e7;
  border-radius: 0 !important;
}

h4 {
  margin-bottom: 0px !important;
}

.v-application p {
  margin-bottom: 0px !important;
}

.v-application ul {
  padding-left: 0px !important;
}

input,
select {
  width: 100%;
}
</style>
