<template>
  <v-row>
    <v-col cols="12">
      <h4>Pickup Location</h4>
      <p>
        Your pickup location is where your returns will be sent to and are where
        you will go to pick up any supplies purchased through our store.
      </p>
      <v-select
        v-model="branch_location_id"
        v-validate="'required'"
        :error-messages="errs.collect('branch')"
        data-vv-name="branch"
        dense
        outlined
        :items="pickup_branches_options"
        label="Branch"
      />
    </v-col>
    <v-col cols="12">
      <h4>Dropoff Location</h4>
      <p>
        Your dropoff location is where you will be dropping off your shipments.
      </p>
      <v-select
        v-model="dropoff_location_id"
        v-validate="'required'"
        :error-messages="errs.collect('dropoff')"
        data-vv-name="dropoff"
        dense
        outlined
        :items="branches_options"
        label="Dropoff Location"
      />
    </v-col>
    <v-col cols="12">
      <h4>Region</h4>
      <p>
        Select the region with the branch where you will be dropping off your
        shipments.
      </p>
      <v-select
        v-model="region_id"
        v-validate="'required'"
        disabled
        :error-messages="errs.collect('region')"
        data-vv-name="region"
        dense
        outlined
        item-text="name"
        item-value="id"
        :items="regions"
        label="Region"
      />
    </v-col>
    <v-col cols="12">
      <div class="mt-3">
        <v-btn
          color="primary"
          :loading="processing"
          @click="save()"
        >
          Update
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    user: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      branches: [],
      processing: false,
      regions: []
    };
  },
  computed: {
    branch_location_id: {
      get() {
        return this.user.branch_location_id;
      },
      set(value) {
        this.user.branch_location_id = value;
      }
    },
    region_id: {
      get() {
        return this.user.region_id;
      },
      set(value) {
        this.user.region_id = value;
      }
    },
    dropoff_location_id: {
      get() {
        return this.user.dropoff_location_id;
      },
      set(value) {
        this.user.dropoff_location_id = value;
      }
    },

    branches_options: {
      get() {
        let array = this.branches;

        return array.map((item) => {
          return {
            value: item.id,
            text: `${item.city} - ${item.address1}, ${item.city} ${item.province_code}, ${item.postal_code}`
          };
        });
      }
    },

    pickup_branches_options: {
      get() {
        let array = this.branches;

        return array.map((item) => {
          if(item.company_location){
            return {
              value: item.id,
              text: `${item.city} - ${item.address1}, ${item.city} ${item.province_code}, ${item.postal_code}`
            };
          }
        });
      }
    },
  },
  watch: {
    dropoff_location_id: {
      handler() {
          // set region_id
          this.region_id = _.find(this.branches, {
            id: this.dropoff_location_id
          }).region_id;
      }
    }
  },
  mounted() {
    this.getBranches();
    this.getRegions();
  },

  methods: {
    save() {
      this.$validator.validate().then((result) => {
        if (result) {
          this.processing = true;

          this.$http
            .post(`/admin/users/${this.user.id}/branch`, this.user)
            .then((res) => {
              if (Object.prototype.hasOwnProperty.call(res.data, 'message')) {
                if (res.data.success) {
                  swal({
                    title: 'Success',
                    text: 'Your default pickup location changes have been saved',
                    icon: 'success'
                  });
                  // set new location
                  this.user.branch_location = _.find(this.branches, {
                    id: this.user.branch_location_id
                  });
                  this.temp_branch_location = this.user.branch_location;
                  this.editGroup3 = false;
                } else {
                  // /var erros =
                  swal('Error!', res.data);
                }
              }
            })
            .catch((err) => {
              if (
                err.response.status === 422 &&
                typeof err.response.data.errors !== 'undefined'
              ) {
                const errors = err.response.data.errors;
                let message = '';

                for (var field in errors) {
                  message += `${errors[field][0]}\n`;
                }

                this.errorMessage(message);
              } else {
                swal({
                  title: 'Error',
                  text: 'Some of the form fields are invalid.',
                  icon: 'error'
                });
              }
            })
            .finally(() => {
              this.processing = false;
            });
        } else {
          swal({
            title: 'Error',
            text: 'Some of the form fields are invalid.',
            icon: 'error'
          });
        }
      });
    },
    getBranches() {
      this.$http
        .get(`/branches`)
        .then((response) => response.data)
        .then((response) => {
          this.branches = response;
        });
    },
    getRegions() {
      this.$http
        .get(`/regions`)
        .then((response) => response.data)
        .then((response) => {
          this.regions = response;
        });
    }
  }
};
</script>
