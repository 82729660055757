<template>
  <SidePanelLayout title="Returns">
    <!-- <div>
      <v-breadcrumbs
        large
        :items="[
        {
          text: 'Home',
          exact: true,
          to: '/dashboard',
        },
        {
          text: 'Returns',
          exact: true,
          to: '/returns',
        },
         {
          text: filter_formated   ,
          exact: true,
          to: '/returns/'+filter,
        },
     
      ]"
      ></v-breadcrumbs>
    </div> -->

    <template #side-panel>
      <return-details
        style="font-size: 14px"
        :returns="selected"
      />
    </template>

    <template #page-links>
      <v-col cols="auto">
        <div class="float-right">
          <router-link
            router
            to="/carm-agreement"
            color="primary"
            px-4
          >
            CARM Agreement
          </router-link>
          |
          <router-link
            router
            to="/returns-agreement"
            color="primary"
            px-4
          >
            Returns Agreement
          </router-link>
          |
          <router-link
            router
            to="/returns/orders"
            color="primary"
            px-4
          >
            Manage Orders
          </router-link>
        </div>
      </v-col>
    </template>

    <template #default>
      <returns-table
        v-model="selected"
        :filter="filter"
      />

      <return-dispose
        v-model="showDisposeDialog"
        :returns="selected"
      />
      <return-request
        v-model="showRequestDialog"
        :returns="selected"
      />
      <return-delete
        v-model="showDeleteDialog"
        :returns="selected"
      />
    </template>

    <template #footer>
      <v-btn
        color="primary"
        outlined
        :disabled="!requestable || !can('CLIENT.SHIPPING')"
        @click="showRequestDialog = true"
      >
        <v-icon
          small
          left
        >
          mdi-keyboard-backspace
        </v-icon>Request
      </v-btn>
      <v-btn
        color="red"
        outlined
        style="color: white"
        :disabled="!disposable || !can('CLIENT.SHIPPING')"
        @click="showDisposeDialog = true"
      >
        <v-icon
          small
          left
        >
          mdi-delete-off-outline
        </v-icon>Dispose
      </v-btn>
      <!--  -->
      <v-btn
        color="red"
        outlined
        style="color: white"
        :disabled="!deletable || !can('CLIENT.SHIPPING')"
        @click="showDeleteDialog = true"
      >
        Delete
      </v-btn>
    </template>
  </SidePanelLayout>
</template>

<script>
import CONSTANTS from '@/constants/index';
export default {
  metaInfo: {
    title: "Returns | Stallion Express"
  },
  props: {
    filter: {
      default: null,
      type: String
    }
  },

  data() {
    return {
      showDeleteDialog: false,
      showDisposeDialog: false,
      showRequestDialog: false,
      selected: []
    };
  },
  computed: {
    filter_formated() {
      return this.$options.filters.capitalize(this.filter);
    },
    isDomestic() {
      return this.selected.every((r) => r.type == "Domestic");
    },
    requestable() {
      if (this.$auth.user?.importer_number == null && !this.isDomestic) {
        return false
      } else if (
        this.selected.length != 0 &&
        this.selected.every((r) =>
          [
            CONSTANTS.RETURN_STATUSES.ready_to_ship,
            CONSTANTS.RETURN_STATUSES.awaiting_review
          ].includes(r.status_id)
        ) &&
        this.selected.every((r) =>
          [
            CONSTANTS.RETURN_SERVICES.action_required,
            CONSTANTS.RETURN_SERVICES.dispose
          ].includes(r.service_id)
        )
        // this.selected.every(r => [10, 5].includes(r.status_id)) &&
        // this.selected.every(r => r.service_id === 1)
      ) {
        return true;
      } else {
        return false;
      }
    },
    disposable() {
      if (
        this.selected.length != 0 &&
        this.selected.every((r) =>
          [
            CONSTANTS.RETURN_SERVICES.action_required,
            CONSTANTS.RETURN_SERVICES.return_requested
          ].includes(r.service_id)
        )
        // this.selected.every(r => r.service_id === 1)
      ) {
        return true;
      } else {
        return false;
      }
    },

    deletable() {
      if (
        this.selected.length != 0 &&
        this.selected.every(
          (r) => r.status_id === CONSTANTS.RETURN_STATUSES.complete
        )
        // this.selected.every(r => r.status_id === 35)
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
