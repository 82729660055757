<template>
  <FullPageLayout
    title="Merge Orders"
  >
    <template #subtitle>
      <p>
        Use this feature to consolidate multiple orders sharing the same shipping address into a single order shipment, effectively reducing shipping costs
      </p>
    </template>
    <template #page-links>
      <router-link
        exact
        router
        to="/merge-orders"
      >
        Mergeable
      </router-link>
      |
      <router-link
        exact
        router
        to="/merged-orders"
      >
        Merged
      </router-link>
    </template>
    <template #default>
      <merge-orders-table />
    </template>
  </FullPageLayout>
</template>
  
  <script>
  import MergeOrdersTable from './MergeOrders.vue';
  export default {
    metaInfo: {
      title: "Merge Orders | Stallion Express"
    },
    components: {
        'merge-orders-table': MergeOrdersTable
    },
    props: {},
    data() {
      return {};
    },
    methods: {}
  };
  </script>
  