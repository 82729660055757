<template>
  <FullPageLayout
    title="Statements"
    :bread-crumbs="breadCrumbs"
  >
    <template #default>
      <statement-table :user-id="$auth.user?.id" />
    </template>
  </FullPageLayout>
</template>

<script>
import moment from 'moment';

export default {
  metaInfo: {
    title: "Statements | Stallion Express"
  },
  data() {
    return {
      tab: null,

      date: moment()
        .subtract(1, 'months')
        .format('YYYY-MM'),
      endDate: moment()
        .subtract(1, 'months')
        .format('YYYY-MM'),
      menu: false,
      loading: false,
      breadCrumbs: [
        {
          text: 'Dashboard',
          exact: true,
          to: '/dashboard'
        },
        {
          text: 'Statements',
          exact: true,
          to: '/statements'
        }
      ]
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.date ? moment(this.date).format('MMMM YYYY') : '';
    }
  },
  mounted() {},

  methods: {
    getInvoice() {
      this.loading = true;

      this.$http
        .get('/users/invoice/' + this.date)
        .then(response => {
          this.downloadFileUrl(response.data,'StallionExpressInvoice' + this.date + '.pdf');
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
