<template>
  <StallionDialog
    v-model="$store.state.main.fba_request_dialog"
    :width="600"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    eager
    title="FBA Pickup Request"
    fluid
    :show-actions="false"
  >
    <template #content>
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step
            :complete="step > 1"
            :step="1"
          >
            Location
          </v-stepper-step>
          <v-divider />
          <v-stepper-step
            :complete="step > 2"
            :step="2"
          >
            Shipments
          </v-stepper-step>
          <v-divider />
          <v-stepper-step
            :complete="step > 3"
            :step="3"
          >
            Summary
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
      <v-stepper
        v-model="step"
        style="background-color: rgba(248, 248, 248, 0.5); min-height: 1000px"
      >
        <div
          v-if="processing > 0"
          class="loading-overlay"
        >
          <div class="loading-content">
            <v-progress-circular
              :size="100"
              :width="10"
              indeterminate
              color="primary"
            />
          </div>
        </div>
        <v-stepper-items>
          <v-container>
            <v-stepper-content step="1">
              <v-layout
                justify-center
                wrap
              >
                <v-flex
                  xs12
                  sm8
                  md6
                  mb-12
                  mt-4
                >
                  <v-card class="elevation-5">
                    <v-card-text>
                      <v-flex
                        xs12
                        text-center
                      >
                        <h2 class="font-weight-bold mt-4">
                          Location
                        </h2>
                      </v-flex>
                      <v-form data-vv-scope="pickup1">
                        <v-container>
                          <v-row>
                            <v-col cols="12">
                              <v-text-field
                                v-model="pickup_request.name"
                                v-validate="'required'"
                                :error-messages="
                                  errs.collect('pickup1.pickup_name')
                                "
                                label="Name"
                                data-vv-name="pickup_name"
                              />
                            </v-col>

                            <!-- <v-col cols="12">
                              <h4 text-center>Pickup Address</h4>
                            </v-col>-->
                            <v-col cols="12">
                              <v-text-field
                                id="address1_pickupfba"
                                ref="autocomplete"
                                v-model="pickup_request.address1"
                                v-validate="'required|max:50'"
                                :error-messages="
                                  errs.collect('pickup1.address1')
                                "
                                label="Address1"
                                data-vv-name="address1"
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              md="6"
                            >
                              <v-text-field
                                v-model="pickup_request.address2"
                                label="Address2"
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              md="6"
                            >
                              <v-text-field
                                v-model="pickup_request.city"
                                v-validate="'required'"
                                :error-messages="errs.collect('pickup1.city')"
                                label="City"
                                data-vv-name="city"
                                required
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              md="6"
                            >
                              <v-text-field
                                v-model="pickup_request.country_code"
                                readonly
                                label="Country"
                                data-vv-name="country"
                                :error-messages="
                                  errs.collect('pickup1.country')
                                "
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              md="6"
                            >
                              <v-select
                                v-model="pickup_request.province_code"
                                v-validate="'required'"
                                :error-messages="
                                  errs.collect('pickup1.province_code')
                                "
                                data-vv-name="province_code"
                                :items="provinces"
                                label="Province"
                                item-text="name"
                                item-value="code"
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              md="6"
                            >
                              <v-text-field
                                v-model="pickup_request.postal_code"
                                v-mask="'A#A #A#'"
                                v-validate="{
                                  required: true,
                                  regex: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/
                                }"
                                validate-on-blur
                                :error-messages="
                                  errs.collect('pickup1.postal_code')
                                "
                                label="Postal Code"
                                data-vv-name="postal_code"
                              />
                            </v-col>

                            <v-col
                              cols="12"
                              md="6"
                            >
                              <v-text-field
                                v-model="pickup_request.phone"
                                v-mask="'###########'"
                                v-validate="'required'"
                                :error-messages="errs.collect('pickup1.phone')"
                                label="Phone"
                                data-vv-name="phone"
                              />
                            </v-col>
                          </v-row>
                          <v-layout
                            align-center
                            class="mt-12 mb-4"
                          >
                            <v-flex
                              xs12
                              text-center
                            >
                              <v-btn
                                large
                                depressed
                                color="primary"
                                @click="submit1()"
                              >
                                Next
                                <v-icon dark>
                                  mdi-arrow-right
                                </v-icon>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-layout
                justify-center
                wrap
              >
                <v-flex
                  xs12
                  sm8
                  md6
                  mb-12
                  mt-4
                >
                  <v-card class="elevation-5">
                    <v-card-text>
                      <v-flex
                        xs12
                        text-center
                      >
                        <h2 class="font-weight-bold mt-4">
                          Shipments
                        </h2>
                      </v-flex>
                      <v-form
                        data-vv-scope="pickup2"
                        @submit.prevent
                      >
                        <v-container>
                          <v-row>
                            <v-col cols="12">
                              <h4>Number of FBA Boxes</h4>
                            </v-col>

                            <v-col
                              cols="12"
                              md="12"
                            >
                              <v-text-field
                                v-model="pickup_request.quantity"
                                v-validate="'required|integer|min_value:10'"
                                type="number"
                                :label="'# Boxes'"
                                :error-messages="
                                  errs.collect(`pickup2.quantity`)
                                "
                                :data-vv-name="`quantity`"
                              />
                            </v-col>

                            <v-col cols="12">
                              <v-menu
                                v-model="menu1"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                              >
                                <template #activator="{ on }">
                                  <v-text-field
                                    v-model="pickup_request.pickup_date"
                                    v-validate="'required'"
                                    :error-messages="
                                      errs.collect('pickup2.dropoff_date')
                                    "
                                    data-vv-name="dropoff_date"
                                    label="Pickup Date"
                                    prepend-inner-icon="event"
                                    readonly
                                    v-on="on"
                                  />
                                </template>
                                <v-date-picker
                                  id="edDropoff_date"
                                  v-model="pickup_request.pickup_date"
                                  :allowed-dates="allowedDates"
                                  :min="allowedStartDate"
                                  @input="menu1 = false"
                                />
                              </v-menu>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                v-model="pickup_request.comments"
                                label="Comments"
                              />
                            </v-col>
                          </v-row>
                          <br>

                          <v-layout
                            align-center
                            class="mt-12 mb-4"
                          >
                            <v-flex
                              xs12
                              text-center
                            >
                              <v-btn
                                large
                                text
                                @click="step = 1"
                              >
                                <v-icon dark>
                                  mdi-arrow-left
                                </v-icon>Back
                              </v-btn>

                              <v-btn
                                large
                                depressed
                                color="primary"
                                @click="submit2()"
                              >
                                Next
                                <v-icon dark>
                                  mdi-arrow-right
                                </v-icon>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-layout
                justify-center
                wrap
              >
                <v-flex
                  xs12
                  sm8
                  md6
                  mb-12
                  mt-4
                >
                  <v-card class="elevation-5">
                    <v-card-text>
                      <v-flex
                        xs12
                        text-center
                      >
                        <h2 class="font-weight-bold mt-4">
                          Summary
                        </h2>
                      </v-flex>

                      <v-container>
                        <v-row>
                          <v-col cols="6">
                            <h4>Pickup Address</h4>

                            <ul style="list-style: none; padding-left: 0">
                              <li>{{ pickup_request.name }}</li>
                              <li>{{ pickup_request.address1 }}</li>
                              <li v-if="pickup_request.address2">
                                {{ pickup_request.address2 }}
                              </li>
                              <li>
                                {{
                                  pickup_request.city +
                                    ', ' +
                                    pickup_request.province_code +
                                    ' ' +
                                    pickup_request.postal_code
                                }}
                              </li>

                              <li>{{ pickup_request.country_code }}</li>
                              <li v-if="pickup_request.phone">
                                Phone: {{ pickup_request.phone | phone }}
                              </li>
                            </ul>
                          </v-col>

                          <v-col cols="6">
                            <h4>Quantity</h4>

                            {{ pickup_request.quantity }}
                          </v-col>

                          <v-col
                            v-if="pickup_request.pickup_date"
                            cols="12"
                          >
                            <h4>Pickup Date</h4>

                            {{ pickup_request.pickup_date | date_pretty }}
                          </v-col>

                          <v-col
                            v-if="pickup_request.comments"
                            cols="12"
                          >
                            <h4>Comments</h4>

                            {{ pickup_request.comments }}
                          </v-col>
                        </v-row>
                        <br>

                        <div>
                          <p
                            style="
                              text-align: center;
                              font-size: 14px;
                              color: rgb(0, 0, 0);
                              background-color: rgb(255, 193, 7);
                              padding: 10px;
                            "
                          >
                            Pickup time window will be between 9am - 5pm.
                            <br>
                            <br>
                            We require a minimum of 10 FBA boxes to schedule the
                            pickup. The pickup service is available for the GTA
                            only and is complimentary.
                            <br>
                            <br>All pickup requests must be submitted before
                            5pm the day before the requested pickup date.
                            <br><br>
                            Shipments have to be ready by 5pm latest in order to
                            qualify for next day pickup.
                          </p>
                        </div>
                        <div class="text-center mt-4">
                          <v-checkbox
                            v-model="agree"
                            label="I agree to the terms above"
                          />
                        </div>
                        <v-layout
                          align-center
                          class="mt-12 mb-4"
                        >
                          <v-flex
                            xs12
                            text-center
                          >
                            <v-btn
                              large
                              text
                              @click="step = 2"
                            >
                              <v-icon dark>
                                mdi-arrow-left
                              </v-icon>Back
                            </v-btn>

                            <v-btn
                              :disabled="!agree"
                              large
                              depressed
                              color="primary"
                              @click="submit3()"
                            >
                              Confirm
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-stepper-content>
          </v-container>
        </v-stepper-items>
      </v-stepper>
    </template>
  </StallionDialog>
</template>

<script>

export default {
  data() {
    return {
      menu1: '',
      enabled: [],
      provinces: [
        {
          name: 'Ontario',
          abbreviation: 'ON'
        },
        {
          name: 'Quebec',
          abbreviation: 'QC'
        },
        {
          name: 'British Columbia',
          abbreviation: 'BC'
        },
        {
          name: 'Alberta',
          abbreviation: 'AB'
        }
      ],
      agree: false,
      pickup_request: {
        address1: '',
        address2: '',
        city: '',
        postal_code: '',
        province_code: 'ON',
        country_code: 'CA',
        pickup_date: '',
        name: '',
        phone: '',
        comments: '',
        carriers: {},
        package_fee: 0,
        quantity: null
      },
      processing: false,
      errors: {},
      step: 1, // 1-5
      stepStatus: {
        form: true,
        pickdetails: false,
        summary: false,
        complete: false
      },
      disabledDate: {
        to: new Date(new Date().setDate(new Date().getDate() - 1)),
        days: [0]
      },
      package_total: 0,
      package_nm: [],
      availableCredits: 0,
      pick_fees: true
    };
  },
  computed: {
    allowedStartDate() {
      var format = 'HH:mm:ss';

      // var time = moment() gives you current time. no format required.
      var time = moment(),
        beforeTime = moment('00:00:00', format),
        afterTime = moment('19:00:00', format);

      if (time.isBetween(beforeTime, afterTime)) {
        return moment().format('YYYY-MM-DD');
      } else {
        return moment().add(1, 'days').format('YYYY-MM-DD');
      }
    }
  },
  watch: {
    '$store.state.main.fba_request_dialog': {
      handler(newValue) {
        if (newValue) {
          // set default pickup location using the account address
          Object.assign(this.pickup_request, {
            address1: this.$auth.user.address1,
            address2: this.$auth.user.address2,
            city: this.$auth.user.city,
            postal_code: this.$auth.user.postal_code,
            province_code: this.$auth.user.province_code,
            country_code: this.$auth.user.country_code,
            name: this.$auth.user.name,
            phone: this.$auth.user.phone
          });

          // override default pickup location if user added one
          if (
            this.$auth.user.user_pickup_location &&
            this.$auth.user.user_pickup_location.is_default
          ) {
            Object.assign(
              this.pickup_request,
              this.$auth.user.user_pickup_location
            );
          }
        }
      }
    }
  },
  mounted() {
    // this.pickup_request.phone = this.$auth.user.phone;
    if (typeof google !== 'undefined') {
      try {
        // alert(google);
        var returnData = {
          street_number: '',
          route: '',
          locality: '',
          administrative_area_level_1: '',
          country: '',
          postal_code: ''
        };

        var element = document.getElementById('address1_pickupfba');

        var autocomplete = new google.maps.places.Autocomplete(element, {
          types: ['geocode']
        });

        element.addEventListener('keydown', (e) => {
          console.log('keydown');
          if (e.keyCode === 13) {
            e.preventDefault();
          }
        });

        element.addEventListener('blur', (e) => {
          console.log('blur');
          returnData.route = e.target.value;
          returnData.street_number = '';
        });

        autocomplete.addListener('place_changed', () => {
          console.log('autocomplete');
          returnData = {
            street_number: '',
            route: '',
            locality: '',
            administrative_area_level_1: '',
            country: '',
            postal_code: ''
          };

          let place = autocomplete.getPlace();
          if (!place.geometry) {
            return;
          }
          let addressComponents = {
            street_number: 'short_name',
            route: 'long_name',
            locality: 'long_name',
            administrative_area_level_1: 'short_name',
            country: 'short_name',
            postal_code: 'short_name',
            postal_town: 'short_name'
          };
          if (place.address_components !== undefined) {
            for (let i = 0; i < place.address_components.length; i++) {
              let addressType = place.address_components[i].types[0];
              if (addressComponents[addressType]) {
                let val =
                  place.address_components[i][addressComponents[addressType]];
                returnData[addressType] = val;
              }
            }
            returnData['latitude'] = place.geometry.location.lat();
            returnData['longitude'] = place.geometry.location.lng();
          }

          element.placeholder = '';

          this.updateAddress(returnData, place);
        });
      } catch (ex) {
        console.log(ex);
      }
    }
  },
  created() {},
  //parseInt(val.split('-')[1], 10) % 2 === 0
  methods: {
    allowedDates(val) {
      var userTimeZone = this.$auth.user.timezone || 'America/Toronto';
      const currentTime = moment().tz(userTimeZone);  // Get the current time in the user's timezone
      const cutoffTime = currentTime.clone().set({ hour: 17, minute: 0, second: 0 });  // 5 PM today
      const selectedDate = moment(val).startOf('day');  // Start of the selected date
      const tomorrow = currentTime.clone().add(1, 'day').startOf('day');

      // Check if the current time is after 5 PM and the selected date is tomorrow
      if (currentTime.isAfter(cutoffTime) && selectedDate.isSame(tomorrow)) {
        return false;  // Disallow selection of tomorrow's date
      } else {
        if(selectedDate.isSame(currentTime, 'day')) {
          return false;
        }
      }

      // Check if the selected date is not a Saturday or Sunday
      return ![6, 0].includes(selectedDate.day());
    },

    updateAddress(value, value2) {
      console.log('update');

      if (['AS', 'GU', 'MP', 'PR', 'VI', 'UM'].includes(value.country)) {
        this.pickup_request.country_code = 'US';
        this.pickup_request.province_code = value.country;
      } else {
        this.pickup_request.country_code = value.country;
        this.pickup_request.province_code = value.administrative_area_level_1;
      }

      this.pickup_request.address1 = value.street_number + ' ' + value.route;

      if (value.locality) {
        this.pickup_request.city = value.locality;
      } else {
        this.pickup_request.city = value2.address_components[2].long_name;
      }

      this.pickup_request.postal_code = value.postal_code;
    },
    getAddressData: function (addressData) {
      //clear this first to update other address field
      this.profile.country = '';
      this.profile.country =
        typeof addressData.country != 'undefined' ? addressData.country : '';
      this.profile.pickup_city =
        typeof addressData.locality != 'undefined' ? addressData.locality : '';
      this.profile.province =
        typeof addressData.administrative_area_level_1 != 'undefined'
          ? addressData.administrative_area_level_1
          : '';
      this.profile.postal_code =
        typeof addressData.postal_code != 'undefined'
          ? addressData.postal_code
          : '';
      this.profile.address1 =
        typeof addressData.route != 'undefined' ? addressData.route : '';
    },

    submit1: function () {
      console.log('submit1');
      // this.processing = true;
      this.$validator.validateAll('pickup1').then((result) => {
        if (result) {
          console.log(result);
          this.step = 2;
        } else {
          this.errorMessage('Some of the form fields are invalid.');
        }
      });
    },
    submit2: function () {
      console.log('submit1');
      // this.processing = true;
      this.$validator.validateAll('pickup2').then((result) => {
        if (result) {
          console.log(result);
          this.step = 3;
        } else {
          this.errorMessage('Some of the form fields are invalid.');
        }
      });
    },
    submit3: function () {
      this.$validator.validateAll('pickup2').then((result) => {
        if (result) {
          this.processing = true;
          this.$http
            .post('/pickups/submit', this.pickup_request)
            .then((response) => {
              this.processing = false;
              console.log(response.data.success);

              this.reset();

              this.successMessage(
                'Confirmation Number: ' + response.data.confirmation_no,
                'Pickup Request Received'
              );

              Event.fire('update-pickups');
            });
        } else {
          this.errorMessage('Some of the form fields are invalid.');
        }
      });
    },

    geolocate() {
      if (this.enableGeolocation) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            let geolocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            new google.maps.Circle({
              center: geolocation,
              radius: position.coords.accuracy
            });
          });
        }
      }
    },
    // updateAddress(value) {
    //   console.log(value);
    //   this.pickup_request.address1 = value.street_number + ' ' + value.route;
    //   this.pickup_request.city = value.locality;
    //   this.pickup_request.postal_code = value.postal_code;
    //   this.pickup_request.country_code = value.country;
    //   //this.province_code = value.administrative_area_level_1;
    // },
    reset() {
      this.pickup_request.address1 = '';
      this.pickup_request.address2 = '';
      this.pickup_request.city = '';
      this.pickup_request.postal_code = '';
      this.pickup_request.province_code = 'ON';
      this.pickup_request.country_code = 'CA';
      this.pickup_request.pickup_date = '';
      this.pickup_request.name = '';
      this.pickup_request.phone = '';
      this.pickup_request.comments = '';
      this.pickup_request.carriers = {};
      this.pickup_request.package_fee = 0;
      this.processing = false;
      this.errors = {};
      this.step = 1;
      this.stepStatus.form = true;
      this.stepStatus.pickdetails = false;
      this.stepStatus.confirmationDetails = false;
      this.stepStatus.summary = false;
      this.stepStatus.complete = false;

      this.$store.state.main.fba_request_dialog = false;
      this.$validator.reset();
    }
  }
};
</script>

<style scoped>
/* .theme--light.v-stepper--vertical .v-stepper__content:not(:last-child) {
  border: none;
}
.v-stepper__content {
  margin: 0 !important;
  padding: 0 !important;
} */
</style>
