<template>
  <div>
    <nav-menu
      :menu="{
        text: 'Review',
        exact: true,
        to: null
      }"
    />

    <v-container v-if="claim">
      <v-row>
        <v-col>
          <h1>Claim Review</h1>
        </v-col>
      </v-row>

      <v-card outlined>
        <v-card-text>
          <v-row>
            <v-col>
              <h2>Ship Code</h2>
              {{ claim.ship_code }}
            </v-col>
            <v-col>
              <h2>Tracking Number</h2>
              {{ claim.shipment.tracking_code }}
            </v-col>
            <v-col>
              <h2>Claim Type</h2>
              {{ claim.type }}
            </v-col>

            <v-col>
              <h2>Status</h2>
              {{ claim.status }}
            </v-col>

            <v-col>
              <h2>Contents</h2>
              {{ claim.status }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h2>Reason</h2>
              {{ claim.reason }}
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <h2>Postage Cost</h2>
              {{ claim.shipment.total_amt_paid | currency }} CAD
            </v-col>
            <v-col>
              <h2>Insured Value</h2>
              {{ claim.shipment.insured_value | currency }} CAD
            </v-col>
            <v-col>
              <h2>Claimed Value</h2>
              {{ claim.value | currency }} CAD
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h2>Uploads</h2>

              <v-btn
                v-if="claim.photo_url"
                icon
                :href="claim.photo_url"
                target="_blank"
              >
                <v-icon>mdi-camera</v-icon>
              </v-btn>

              <span v-if="claim.photo_urls">
                <v-btn
                  v-for="url in claim.photo_urls"
                  :key="url"
                  icon
                  :href="url"
                  target="_blank"
                >
                  <v-icon>mdi-camera</v-icon>
                </v-btn>
              </span>

              <span v-if="claim.additional_document_urls">
                <v-btn
                  v-for="url in claim.additional_document_urls"
                  :key="url"
                  :href="url"
                  target="_blank"
                  icon
                >
                  <v-icon>mdi-file-document</v-icon>
                </v-btn>
              </span>

              <v-btn
                v-if="claim.invoice_url"
                icon
                :href="claim.invoice_url"
                target="_blank"
              >
                <v-icon>mdi-file-document</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider />
          <v-row v-if="claim.status == 'Pending'">
            <v-col>
              <h2>Action</h2>

              <v-form>
                <v-container fluid>
                  <v-col cols="12">
                    <v-textarea
                      v-model="comment"
                      v-validate="'required'"
                      outlined
                      :error-messages="errs.collect('comment')"
                      label="Comments"
                      data-vv-name="comment"
                    />
                  </v-col>

                  <v-radio-group v-model="approve">
                    <v-radio
                      :label="`Approve`"
                      :value="true"
                    />

                    <v-radio
                      :label="`Deny`"
                      :value="false"
                    />
                  </v-radio-group>

                  <v-row
                    v-if="approve"
                    align="center"
                  >
                    <v-col>
                      <h2>Payout Amount</h2>
                      <v-text-field
                        v-model="reinburtment"
                        outlined
                      />
                      <v-btn
                        v-if="approve"
                        color="green"
                        dark
                        :disabled="loading"
                        :loading="loading"
                        @click="validateForm('approve')"
                      >
                        <v-icon>mdi-check</v-icon>Payout
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-row align="center">
                    <v-col cols="6">
                      <v-btn
                        v-if="!approve"
                        color="red"
                        dark
                        :disabled="loading"
                        :loading="loading"
                        @click="validateForm('deny')"
                      >
                        <v-icon>mdi-cross</v-icon>Deny
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import NavMenu from './nav-menu.vue';

export default {
  components: {
    NavMenu
  },
  data() {
    return {
      claim: null,
      reinburtment: null,
      approve: false,
      loading: false,
      comment: null
    };
  },
  computed: {
    showActions() {
      const days = ['CA', 'US'].includes(this.claim.shipment.country_code)
        ? 21
        : 45;
      const date1 = new Date();
      date1.setDate(date1.getDate() - days);
      const date2 = new Date(this.claim.shipment.dropoff_date);

      return date2 < date1;
    }
  },
  created() {
    this.getClaim();
  },
  methods: {
    getClaim() {
      // GET /someUrl
      this.$http.get(`/admin/claims/${this.$route.params.id}`).then(
        (response) => {
          this.claim = response.data;
          this.reinburtment = this.claim.shipment.insured_value;
        },
        () => {
          // error callback
        }
      );
    },
    validateForm(type) {
      this.$validator.validate().then((result) => {
        if (result) {
          if (type == 'approve') {
            this.approveClaim();
          } else {
            this.denyClaim();
          }
        }
      });
    },
    approveClaim() {
      this.loading = true;
      this.$http
        .post(`/admin/claims/${this.$route.params.id}/approve`, {
          amount: this.reinburtment,
          comment: this.comment
        })
        .then(() => {
          this.successMessage('Claim has been successfully approved');

          this.$router.push({
            name: 'admin-claims-index'
          });
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errorMessage(err.response.data.errors);
          } else {
            this.errorMessage('Error approving the claim');
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    denyClaim() {
      this.loading = true;
      this.$http
        .post(`/admin/claims/${this.$route.params.id}/deny`, {
          comment: this.comment
        })
        .then(() => {
          this.successMessage('Claim has been successfully denied');

          this.$router.push({
            name: 'admin-claims-index'
          });
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errorMessage(err.response.data.errors);
          } else {
            this.errorMessage('Error denying the claim');
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
