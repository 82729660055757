<template>
  <StallionDialog
    v-model="show"
    :fullscreen="true"
    :persistent="true"
    hide-overlay
    transition="dialog-bottom-transition"
    title="Return Label"
    fluid
  >
    <template #content>
      <v-stepper
        v-model="step"
        style="background-color: white; box-shadow: none;"
      >
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card
              outlined
              class="mb-3"
            >
              <v-tabs v-model="tab">
                <v-tab>
                  <v-icon left>
                    mdi-map
                  </v-icon>Address
                </v-tab>

                <v-tab>
                  <v-icon left>
                    mdi-package-variant-closed
                  </v-icon>Package
                </v-tab>
              </v-tabs>
            </v-card>

            <v-tabs-items
              v-model="tab"
            >
              <v-tab-item>
                <v-form>
                  <v-row dense>
                    <v-col cols="6">
                      <v-card
                        outlined
                        class="pa-5"
                      >
                        <v-row dense>
                          <v-col cols="12">
                            <h3>From</h3>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="ship.return_name"
                              v-validate="'required'"
                              :error-messages="errs.collect('return_name')"
                              data-vv-name="return_name"
                              label="Name"
                              dense
                              outlined
                            />
                          </v-col>
                          <v-col
                            cols="6"
                            md="6"
                            sm="12"
                          >
                            <v-text-field
                              id="CreateReturnFromReturnAddress1"
                              v-model="ship.return_address1"
                              v-validate="'required'"
                              :error-messages="
                                errs.collect('return_address_1')
                              "
                              data-vv-name="return_address_1"
                              label="Address 1"
                              dense
                              outlined
                            />
                          </v-col>
                          <v-col
                            cols="6"
                            md="6"
                            sm="12"
                          >
                            <v-text-field
                              ref="refReturnAddress2"
                              v-model="ship.return_address2"
                              :error-messages="
                                errs.collect('return_address_2')
                              "
                              data-vv-name="return_address_2"
                              label="Address 2"
                              dense
                              outlined
                            />
                          </v-col>
                          <v-col
                            cols="6"
                            md="6"
                            sm="12"
                          >
                            <v-select
                              v-model="ship.return_country_code"
                              v-validate="'required'"
                              :error-messages="
                                errs.collect('return_country_code')
                              "
                              data-vv-name="return_country_code"
                              label="Country Code"
                              :items="countries"
                              item-text="name"
                              item-value="code"
                              dense
                              outlined
                            />
                          </v-col>
                          <v-col
                            cols="6"
                            md="6"
                            sm="12"
                          >
                            <v-text-field
                              v-model="ship.return_city"
                              v-validate="'required'"
                              :error-messages="errs.collect('return_city')"
                              data-vv-name="return_city"
                              label="City"
                              dense
                              outlined
                            />
                          </v-col>
                          <v-col
                            cols="6"
                            md="6"
                            sm="12"
                          >
                            <v-autocomplete
                              v-if="ship.return_country_code == 'CA'"
                              v-model="ship.return_province_code"
                              v-validate="'required|max:2'"
                              clearable
                              outlined
                              dense
                              :error-messages="
                                errs.collect('return_province_code')
                              "
                              data-vv-name="return_province_code"
                              :items="provinces"
                              label="Province"
                              item-text="name"
                              item-value="code"
                              dusk="sf_province"
                            />

                            <v-autocomplete
                              v-else-if="ship.return_country_code == 'US'"
                              v-model="ship.return_province_code"
                              v-validate="'required|max:2'"
                              clearable
                              outlined
                              :error-messages="
                                errs.collect('return_province_code')
                              "
                              data-vv-name="return_province_code"
                              :items="states"
                              label="State"
                              item-text="name"
                              item-value="code"
                              dense
                              dusk="sf_province"
                            />

                            <v-text-field
                              v-else
                              v-model="ship.return_province_code"
                              clearable
                              outlined
                              dense
                              dusk="sf_province"
                              label="Province/State"
                            />
                          </v-col>
                          <v-col
                            cols="6"
                            md="6"
                            sm="12"
                          >
                            <v-text-field
                              v-model="ship.return_postal_code"
                              v-validate="'required'"
                              :error-messages="
                                errs.collect('return_postal_code')
                              "
                              data-vv-name="return_postal_code"
                              label="Postal Code"
                              dense
                              outlined
                            />
                          </v-col>

                          <v-col
                            cols="6"
                            md="6"
                            sm="12"
                          >
                            <v-text-field
                              v-model="ship.phone"
                              v-validate
                              :error-messages="errs.collect('phone')"
                              data-vv-name="phone"
                              label="Phone"
                              dense
                              outlined
                            />
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                    <v-col cols="6">
                      <v-card
                        outlined
                        class="pa-5"
                      >
                        <v-row dense>
                          <v-col cols="12">
                            <h3>To</h3>
                          </v-col>

                          <v-col cols="12">
                            <v-text-field
                              v-model="ship.name"
                              v-validate="'required'"
                              :error-messages="errs.collect('name')"
                              data-vv-name="name"
                              label="Name"
                              dense
                              outlined
                            />
                          </v-col>
                          <v-col
                            cols="6"
                            md="6"
                            sm="12"
                          >
                            <v-text-field
                              id="CreateReturnToAddress1"
                              v-model="ship.address1"
                              v-validate="'required'"
                              :error-messages="errs.collect('address_1')"
                              data-vv-name="address_1"
                              label="Address 1"
                              dense
                              outlined
                            />
                          </v-col>
                          <v-col
                            cols="6"
                            md="6"
                            sm="12"
                          >
                            <v-text-field
                              ref="refAddress2"
                              v-model="ship.address2"
                              :error-messages="errs.collect('address_2')"
                              data-vv-name="address_2"
                              label="Address 2"
                              dense
                              outlined
                            />
                          </v-col>
                          <v-col
                            cols="6"
                            md="6"
                            sm="12"
                          >
                            <v-select
                              v-model="ship.country_code"
                              v-validate="'required'"
                              :error-messages="errs.collect('country_code')"
                              data-vv-name="country_code"
                              label="Country Code"
                              :items="countries"
                              item-text="name"
                              item-value="code"
                              dense
                              outlined
                            />
                          </v-col>
                          <v-col
                            cols="6"
                            md="6"
                            sm="12"
                          >
                            <v-text-field
                              v-model="ship.city"
                              v-validate="'required'"
                              :error-messages="errs.collect('city')"
                              data-vv-name="city"
                              label="City"
                              dense
                              outlined
                            />
                          </v-col>
                          <v-col
                            cols="6"
                            md="6"
                            sm="12"
                          >
                            <v-autocomplete
                              v-if="ship.country_code == 'CA'"
                              v-model="ship.province_code"
                              v-validate="'required|max:2'"
                              clearable
                              outlined
                              dense
                              :error-messages="errs.collect('province_code')"
                              data-vv-name="province_code"
                              :items="provinces"
                              label="Province"
                              item-text="name"
                              item-value="code"
                              dusk="sf_province"
                            />

                            <v-autocomplete
                              v-else-if="ship.country_code == 'US'"
                              v-model="ship.province_code"
                              v-validate="'required|max:2'"
                              clearable
                              outlined
                              :error-messages="errs.collect('province_code')"
                              data-vv-name="province_code"
                              :items="states"
                              label="State"
                              item-text="name"
                              item-value="code"
                              dense
                              dusk="sf_province"
                            />

                            <v-text-field
                              v-else
                              v-model="ship.province_code"
                              clearable
                              outlined
                              dense
                              dusk="sf_province"
                              label="Province/State"
                            />
                          </v-col>
                          <v-col
                            cols="6"
                            md="6"
                            sm="12"
                          >
                            <v-text-field
                              v-model="ship.postal_code"
                              v-validate="'required'"
                              :error-messages="errs.collect('postal_code')"
                              data-vv-name="postal_code"
                              label="Postal Code"
                              dense
                              outlined
                            />
                          </v-col>
                          <v-col
                            cols="6"
                            md="6"
                            sm="12"
                          >
                            <v-text-field
                              v-model="ship.email"
                              v-validate="'email'"
                              :error-messages="errs.collect('email')"
                              data-vv-name="email"
                              label="Email"
                              dense
                              outlined
                            />
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-form>
              </v-tab-item>

              <v-tab-item>
                <v-row>
                  <v-col cols="6">
                    <v-card
                      outlined
                      class="pa-5"
                    >
                      <v-row dense>
                        <v-col cols="12">
                          <h2>Package</h2>
                        </v-col>

                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-select
                            v-model="ship.package_type_id"
                            v-validate="'required'"
                            clearable
                            :error-messages="errs.collect('package_type')"
                            data-vv-name="package_type"
                            label="Package Type"
                            :items="packageArray"
                            item-text="description"
                            item-value="id"
                            dense
                            outlined
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="6"
                          md="3"
                        >
                          <v-text-field
                            v-model="ship.weight"
                            v-validate="'required|min_value:0.001'"
                            clearable
                            :error-messages="errs.collect('weight')"
                            data-vv-name="weight"
                            label="Weight"
                            dense
                            outlined
                            type="number"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="6"
                          md="3"
                        >
                          <v-select
                            v-model="ship.weight_unit"
                            v-validate="'required'"
                            clearable
                            :error-messages="errs.collect('weight_unit')"
                            data-vv-name="weight_unit"
                            label="Weight Unit"
                            :items="['kg', 'g', 'oz', 'lbs']"
                            dense
                            outlined
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="6"
                          md="3"
                        >
                          <v-text-field
                            v-show="[19, 20].includes(ship.package_type_id)"
                            v-model="ship.length"
                            v-validate="'required'"
                            clearable
                            :error-messages="errs.collect('length')"
                            data-vv-name="length"
                            label="Length"
                            dense
                            outlined
                            type="number"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="6"
                          md="3"
                        >
                          <v-text-field
                            v-show="[19, 20].includes(ship.package_type_id)"
                            v-model="ship.width"
                            v-validate="'required'"
                            clearable
                            :error-messages="errs.collect('width')"
                            data-vv-name="width"
                            label="Width"
                            dense
                            outlined
                            type="number"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="6"
                          md="3"
                        >
                          <v-text-field
                            v-show="[19, 20].includes(ship.package_type_id)"
                            v-model="ship.height"
                            v-validate="'required'"
                            clearable
                            :error-messages="errs.collect('height')"
                            data-vv-name="height"
                            label="Height"
                            dense
                            outlined
                            type="number"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="6"
                          md="3"
                        >
                          <v-select
                            v-show="[19, 20].includes(ship.package_type_id)"
                            v-model="ship.size_unit"
                            v-validate="'required'"
                            clearable
                            :error-messages="errs.collect('size_unit')"
                            data-vv-name="size_unit"
                            label="Unit"
                            :items="['cm', 'in']"
                            dense
                            outlined
                          />
                        </v-col>

                        <v-col cols="12">
                          <v-text-field
                            v-model="ship.package_contents"
                            v-validate="'required|max:255'"
                            clearable
                            :error-messages="errs.collect('description')"
                            data-vv-name="description"
                            :hint="
                              ship.is_fba
                                ? 'ASIN number required, quantity and description of items'
                                : 'Be specific about the contents you are shipping!'
                            "
                            persistent-hint
                            label="Description"
                            dense
                            outlined
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <v-text-field
                            v-model="ship.value"
                            v-validate="
                              'required|max_value:800|min_value:0.001'
                            "
                            clearable
                            :error-messages="errs.collect('value')"
                            data-vv-name="value"
                            label="Retail Value"
                            dense
                            outlined
                            type="number"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                        >
                          <v-select
                            v-model="ship.currency"
                            v-validate="'required'"
                            clearable
                            :error-messages="errs.collect('currency')"
                            data-vv-name="currency"
                            label="Currency"
                            :items="$currencyAsFlatArray()"
                            dense
                            outlined
                          />
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>

                  <v-col cols="6">
                    <v-card
                      outlined
                      class="pa-5"
                    >
                      <v-row dense>
                        <v-col cols="12">
                          <h2>Postage</h2>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                        >
                          <v-select
                            v-model="ship.postage_type_id"
                            v-validate=""
                            clearable
                            :error-messages="errs.collect('postage_type')"
                            data-vv-name="postage_type"
                            :items="postageArray"
                            item-text="description"
                            item-value="id"
                            label="Postage Type"
                            dense
                            outlined
                          />
                        </v-col>

                        <!-- <v-col
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <v-select
                    v-model="ship.needs_postage"
                    v-validate="'required'"
                    clearable
                    :error-messages="errs.collect('needs_postage')"
                    data-vv-name="needs_postage"
                    :items="[
                      { value: true, text: 'Yes' },
                      { value: false, text: 'No' }
                    ]"
                    label="Need Postage?"
                    dense
                    outlined
                  />
                </v-col> -->
                        <!-- <v-col
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <v-select
                    v-model="ship.is_fba"
                    v-validate="'required'"
                    clearable
                    :error-messages="errs.collect('is_fba')"
                    data-vv-name="is_fba"
                    :items="[
                      { value: true, text: 'Yes' },
                      { value: false, text: 'No' }
                    ]"
                    label="Is FBA?"
                    dense
                    outlined
                  />
                </v-col> -->

                        <v-col
                          cols="12"
                          md="6"
                        >
                          <v-select
                            v-model="ship.signature_confirmation"
                            v-validate=""
                            clearable
                            :error-messages="errs.collect('signature')"
                            data-vv-name="signature"
                            :items="[
                              { value: 1, text: 'Yes' },
                              { value: 0, text: 'No' }
                            ]"
                            dense
                            outlined
                            label="Signature"
                          />
                        </v-col>
                        <v-col
                          v-if="ship.needs_postage == false"
                          cols="12"
                          md="6"
                        >
                          <v-text-field
                            v-model="ship.tracking_code"
                            v-validate="''"
                            clearable
                            outlined
                            dense
                            :error-messages="errs.collect('tracking_code')"
                            data-vv-name="tracking_code"
                            label="Tracking Number"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                        >
                          <v-select
                            v-model="ship.insured"
                            v-validate=""
                            clearable
                            :error-messages="
                              errs.collect('stallion_protection')
                            "
                            data-vv-name="stallion_protection"
                            :items="[
                              { value: 1, text: 'Yes' },
                              { value: 0, text: 'No' }
                            ]"
                            dense
                            outlined
                            label="Stallion Protection"
                          />
                        </v-col>

                        <v-col cols="12">
                          <h3>Rates</h3>
                        </v-col>
                        <v-col cols="12">
                          <rates-list
                            show-total
                            :postage-rates="postage_rates"
                            :shipment="ship"
                            :update-rate-handler="
                              (x) => rateSelected(x)
                            "
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          class="text-center"
                        >
                          <v-btn
                            color="primary"
     
                            :disabled="purchase_loading || rate_loading"
                            :loading="rate_loading"
                            @click="getPostageRates()"
                          >
                            Get Rates
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-card
              outlined
              class="mb-3"
            >
              <v-flex
                text-center
                class="my-md-5"
              >
                <h2>Label Purchased Successfully</h2>
              </v-flex>

              <v-flex
                text-center
                class="my-md-15"
              >
                <print
                  ref="print"
                  v-model="show_print"
                  :shipments="[ship]"
                />
              </v-flex>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="3">
            <div v-if="step === 3">
              <v-flex
                text-center
                class="my-md-5"
              >
                <h3>Email Label</h3>
              </v-flex>
              <v-flex>
                <v-text-field
                  v-model="email.recipient"
                  v-validate="'email|required'"
                  :error-messages="errs.collect('recipient')"
                  data-vv-name="recipient"
                  label="Recipient Email"
                  dense
                  outlined
                />
              </v-flex>
              <v-flex>
                <v-text-field
                  v-model="email.reply_to"
                  v-validate="'email|required'"
                  :error-messages="errs.collect('reply_to')"
                  data-vv-name="reply_to"
                  label="Reply To"
                  hint="Email the customer will respond to in case of any questions regarding the label."
                  dense
                  outlined
                />
              </v-flex>
              <v-flex>
                <v-text-field
                  v-model="email.subject"
                  v-validate="'required'"
                  :error-messages="errs.collect('subject')"
                  data-vv-name="subject"
                  label="Subject"
                  dense
                  outlined
                />
              </v-flex>
              <v-flex>
                <v-textarea
                  v-model="email.body"
                  v-validate="'required'"
                  :error-messages="errs.collect('content')"
                  data-vv-name="content"
                  label="Content"
                  dense
                  outlined
                />
              </v-flex>
              <v-flex>
                <v-icon>mdi-attachment</v-icon>
                <span>attachment: {{ ship.ship_code }}.pdf</span>
              </v-flex>
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>

    <template #actions>
      <div v-if="step === 1">
        <v-btn
          :disabled="purchase_loading"
          color="primary"
          :loading="purchase_loading"
          @click="purchaseReturnLabel"
        >
          Purchase
        </v-btn>
      </div>
      <div v-if="step === 2">
        <v-btn
          color="primary"
          class="px-4"
          @click="step = 3"
        >
          <v-icon
            small
            dense
            left
          >
            mdi-email
          </v-icon>Email
        </v-btn>
        <v-btn
          color="primary"
          @click="show_print = true"
        >
          <v-icon
            small
            dense
            left
          >
            mdi-printer
          </v-icon>Print
        </v-btn>
      </div>
      <div v-if="step === 3">
        <v-btn
          color="primary"
          class="px-4"
          @click="step = 2"
        >
          Back
        </v-btn>
        <v-btn
          color="primary"
          class="px-4"
          @click="sendEmail"
        >
          Send
        </v-btn>
      </div>
    </template>
  </StallionDialog>
</template>

<script>
import CONSTANTS from '@/constants';
import RatesList from '../rates-list.vue';
// import _isEmpty from 'lodash/isEmpty';
// import { AtomSpinner } from 'epic-spinners';

export default {
  components: {
    RatesList
    // AtomSpinner
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      default: false,
      type: Boolean
    },
    shipment: {
      default: () => {
        return {
          preset_id: null,
          package_type_id: null,
          package_contents: null,
          value: null,
          currency: null,
          weight: null,
          weight_unit: null,
          length: null,
          width: null,
          height: null,
          size_unit: null,
          needs_postage: null,
          postage_type_id: null,
          tracking_code: null,
          additional_insurance: null,
          signature: null,
          insured: null,
          is_fba: null,
          country_code: null,
          return_country_code: null
        };
      },
      type: Object,
      required: false
    },
    linked: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      countries: CONSTANTS.COUNTRIES,
      provinces: CONSTANTS.PROVINCES,
      states: CONSTANTS.STATES,
      rate_loading: false,
      postage_rates: [],
      return_address: {},
      rate_selected: false,
      weight_units: ['kg', 'lbs', 'g', 'oz'],
      empty: false,
      show_print: false,
      purchase_loading: false,
      ship: {},
      step: 1,
      action: null,
      email: {},
      print_loading: false,
      download_loading: false,
      tab: 1,
      searchInput2: null,
      postageArray: [],
      packageArray: []
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    tab: function (val) {
      // tab 0 = address
      if (val == 0 && this.show) {
        // NOTE: nextTIck will not work - will need to use setTimeout to wait for the element to be rendered
        setTimeout(() => {
          this.initGoogleMap('from')
          this.initGoogleMap('to')
        }, 100)
      }
    },
    show: function (val) {
      if (val) {
        this.clearData();

        this.postageArray = this.$store.getters['main/postage_types'](true, true).map(
          (p) => {
            return { id: parseInt(p.id), description: p.description };
          }
        );
        this.packageArray = this.$store.getters['main/package_types'].map(
          (p) => {
            return {
              id: parseInt(p.id),
              description: p.description,
              classification: p.classification,
              package_provider: p.package_provider
            };
          }
        );

        this.ship = { ...this.shipment };

        this.ship.return_name = this.ship.name;
        this.ship.return_address1 = this.ship.address1;
        this.ship.return_address2 = this.ship.address2;
        this.ship.return_city = this.ship.city;
        this.ship.return_province_code = this.ship.province_code;
        this.ship.return_postal_code = this.ship.postal_code;
        this.ship.return_country_code = this.ship.country_code;
        this.ship.return_phone = this.ship.phone;
        this.ship.return_email = this.ship.email;

        if (this.ship.return_country_code == 'CA') {
          this.ship.name =
            this.$auth.user.business_name || this.$auth.user.name;
          this.ship.address1 = this.$auth.user.address1;
          this.ship.address2 = this.$auth.user.address2;
          this.ship.city = this.$auth.user.city;
          this.ship.province_code = this.$auth.user.province_code;
          this.ship.postal_code = this.$auth.user.postal_code;
          this.ship.country_code = this.$auth.user.country_code;
          this.ship.phone = this.$auth.user.phone;
          this.ship.email = this.$auth.user.email;
        } else {
          this.ship.name = this.shipment.return_name;
          this.ship.address1 = this.shipment.return_address1;
          this.ship.address2 = this.shipment.return_address2;
          this.ship.city = this.shipment.return_city;
          this.ship.province_code = this.shipment.return_province_code;
          this.ship.postal_code = this.shipment.return_postal_code;
          this.ship.country_code = this.shipment.return_country_code;
          this.ship.phone = this.shipment.return_phone;
          this.ship.email = this.shipment.return_email;
        }

        // this.getPostageRates();
      }
    }
  },
  methods: {
    initGoogleMap(addressType) {
      if (typeof google === 'undefined') {
        return
      }

      let inputAddress1 = ''
      let inputRefAddress2 = ''
      let addressObj = {}
      switch (addressType) {
        case 'from':
          inputAddress1 = 'CreateReturnFromReturnAddress1'
          inputRefAddress2 = 'refReturnAddress2'
          addressObj = {
            address1: 'return_address1',
            address2: 'return_address2',
            city: 'return_city',
            province_code: 'return_province_code',
            postal_code: 'return_postal_code',
            country_code: 'return_country_code'
          }
          break;
        case 'to':
          inputAddress1 = 'CreateReturnToAddress1'
          inputRefAddress2 = 'refAddress2'
          addressObj = {
            address1: 'address1',
            address2: 'address2',
            city: 'city',
            province_code: 'province_code',
            postal_code: 'postal_code',
            country_code: 'country_code'
          }
          break;
        default:
      }

      if (!inputAddress1) {
        return
      }

      try {
        var element = document.getElementById(inputAddress1);

        const options = {
          // bounds: defaultBounds,
          // componentRestrictions: { country: 'us' },
          fields: ['address_components'],
          strictBounds: false,
          types: ['address']
        };

        var autocomplete = new google.maps.places.Autocomplete(
          element,
          options
        );

        autocomplete.addListener('place_changed', () => {
          // Get the place details from the autocomplete object.
          const place = autocomplete.getPlace();
          const placeAddressObj = {}
          for (const key in addressObj) {
            // placeAddressObj.address1 = ''
            placeAddressObj[key] = ''
          }

          // Get each component of the address from the place details,
          // and then fill-in the corresponding field on the form.
          // place.address_components are google.maps.GeocoderAddressComponent objects
          // which are documented at http://goo.gle/3l5i5Mr
          // console.log(place.address_components);
          for (const component of place.address_components) {
            // @ts-ignore remove once typings fixed
            const componentType = component.types[0];

            switch (componentType) {
              case 'street_number': {
                placeAddressObj.address1 = `${component.long_name} ${placeAddressObj.address1 || ''}`;
                break;
              }

              case 'subpremise': {
                placeAddressObj.address2 = component.short_name;
                break;
              }

              case 'route': {
                placeAddressObj.address1 += component.short_name;
                break;
              }

              case 'postal_code': {
                placeAddressObj.postal_code = `${component.long_name}${placeAddressObj.postal_code || ''}`;
                break;
              }

              case 'postal_code_suffix': {
                placeAddressObj.postal_code = `${placeAddressObj.postal_code || ''}-${component.long_name}`;
                break;
              }
              case 'locality':
                placeAddressObj.city = component.long_name;
                break;
              case 'administrative_area_level_1': {
                placeAddressObj.province_code = component.short_name;
                break;
              }
              case 'country':
                placeAddressObj.country_code = component.short_name;
                break;
            }
          }

          for (const key in addressObj) {
            // this.ship.address1 = ''
            // this.ship.return_address1 = ''
            this.ship[addressObj[key]] = placeAddressObj[key]
          }

          // After filling the form with address components from the Autocomplete
          // prediction, set cursor focus on the second address line to encourage
          // entry of subpremise information such as apartment, unit, or floor number.
          // address2Field.focus();

          this.$refs[inputRefAddress2].focus();
        });
      } catch (ex) {
        console.log(ex);
      }
    },
    async getPostageRates() {
      const valid = await this.$validator.validate();
      if (valid) {
        this.rate_loading = true;
        this.postage_rates = [];

        try {
          const response = await this.$http.post(
            '/return-label/getReturnRates',
            this.ship
          );
          if (response.data.errors) {
            this.$notify({
              group: 'main',
              title: 'Error',
              text: response.data.errors.join('. '),
              duration: 5000,
              type: 'error'
            });
          } else if (!response.data.rates?.length) {
            this.$notify({
              group: 'main',
              title: 'Error',
              text: 'Could not get rates. Please verify your information.',
              duration: 5000,
              type: 'error'
            });
          } else {
            this.postage_rates = response.data.rates;
            const postage_ids = this.postage_rates.map(
              (p) => p.postage_type_id
            );
            if (postage_ids.includes(this.ship?.postage_type_id)) {
              this.rate_selected = true;
            }
          }
        } catch (error) {
          this.$notify({
            group: 'main',
            title: 'Error',
            text: 'Error getting rates',
            duration: 5000,
            type: 'error'
          });
        }

        this.rate_loading = false;
      } else {
        this.$notify({
          group: 'main',
          title: 'Missing Fields',
          text: this.errs.all().join('. '),
          duration: 5000,
          type: 'error'
        });
      }
    },
    rateSelected(rate) {
      this.ship.postage_type_id = rate.postage_type_id;
      this.ship.rate_id = rate.id;
      this.rate_selected = true;
    },
    async purchaseReturnLabel() {
      const valid = await this.$validator.validate();
      if (valid) {
        this.purchase_loading = true;
        try {
          const response = await this.$http.post(
            '/return-label/createReturnLabel',
            this.ship
          );
          console.log(response);
          if (response.data.success) {
            Event.fire('update-shipments');
            this.ship = response.data.shipment;
            this.step = 2;
            this.email.recipient = this.ship.return_email;
            this.email.body = `Please find attached the label for shipment ${this.ship.ship_code}.`;
            this.email.subject = 'Return Label';
            this.email.reply_to = this.$auth.user.email;
          } else {
            this.$notify({
              group: 'main',
              title: 'Error',
              text: response.data.errors.join('. '),
              duration: 5000,
              type: 'error'
            });
          }
        } catch (error) {
          this.$notify({
            group: 'main',
            title: 'Error',
            text: 'Error getting postage',
            duration: 5000,
            type: 'error'
          });
        }
        this.purchase_loading = false;
      } else {
        this.$notify({
          group: 'main',
          title: 'Missing Fields',
          text: this.errs.all().join('. '),
          duration: 5000,
          type: 'error'
        });
      }
    },

    async sendEmail() {
      const valid = await this.$validator.validate();
      if (valid) {
        const params = {
          email: this.email.recipient,
          reply_to: this.email.reply_to,
          ship_code: this.ship.ship_code,
          subject: this.email.subject,
          message: this.email.body
        };
        const response = await this.$http.post(
          '/return-label/sendEmail',
          params
        );
        if (response.data.success) {
          this.successMessage('Email Sent');
        } else {
          this.errorMessage('Something went wrong');
        }
      } else {
        this.$notify({
          group: 'main',
          title: 'Missing Fields',
          text: this.errs.all().join('. '),
          duration: 5000,
          type: 'error'
        });
      }
    },
    clearData() {
      this.email = {};
      this.postage_rates = [];
      this.show_print = false;
      this.step = 1;
      this.$validator.reset();
      this.tab = 1;
    },
    close() {
      this.show = false;
    }
  }
};
</script>

<style scoped>
>>> #CreateReturnFromReturnAddress1::placeholder {
  visibility: hidden !important;
}
>>> #CreateReturnToAddress1::placeholder {
  visibility: hidden !important;
}
</style>
