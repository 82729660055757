<template>
  <div>
    <div class="container">
      <div class="row mt-12">
        <div class="col-md-12">
          <h1>Admin Main</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <v-alert
            :style="'font-size: 20px'"
            prominent
            color="primary"
            icon="mdi-horse-variant-fast"
          >
            {{ message }}
          </v-alert>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Funnies from 'funnies';
export default {
  data() {
    return {
      module: 'Dashboard',
      model: {},
      message: ''
    };
  },
  async mounted() {
    let funnies = new Funnies();
    this.message = funnies.message();
  },
  created() {
   
  },
  methods: {
  }
};
</script>
