<template>
  <v-container
    grid-list-md
    mb-12
  >
    <v-layout wrap>
      <v-flex xs12>
        <h1>
          <i
            aria-hidden="true"
            class="mdi mdi-download"
          /> Export
        </h1>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex
        xs12
        md6
      >
        <export-by-customer />
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs12>
        <p
          v-for="(error, index) in errors"
          :key="index"
          class="alert alert-danger"
        >
          {{ error }}
        </p>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      trip_number: null,
      export_type: 'Border Connect',
      file: null,
      form: new FormData(),
      form2: new FormData(),
      start_dropoff_date: moment().format('YYYY-MM-DD'),
      end_dropoff_date: moment().format('YYYY-MM-DD'),
      menu1: false,
      menu2: false,
      menu3: true,
      items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
      errors: [],
      loading1: false,
      loading2: false,
      loading3: false,
      file2: null,
      trucks: [],
      truck: null
    };
  },
  mounted() {
    this.getTrucks();

    this.trip_number =
      'S' +
      moment()
        .add(1, 'days')
        .format('YYYYMMDD') +
      'A';
  },
  methods: {
    getTrucks() {
      this.$http
        .get(`/trucks`)
        .then(response => response.data)
        .then(response => {
          this.trucks = response;
          this.truck = this.trucks[0].id;
        });
    },
    customFormatter(date) {
      return moment(date).format('MMMM Do YYYY, h:mm:ss a');
    },
    uploadFile() {
      // upload file
      this.loading2 = true;
      this.errors = [];
      this.form.append('import_file', this.file);
      this.$http
        .post('/admin/export/json', this.form)
        .then(
          res => {
            this.disable = false;
            if (res.data.success) {
              const items = res.data.file2;
              const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
              const header = Object.keys(items[0]);
              let csv = items.map(row =>
                header
                  .map(fieldName => JSON.stringify(row[fieldName], replacer))
                  .join(',')
              );
              csv.unshift(header.join(','));
              csv = csv.join('\r\n');

              console.log(csv);
              let blob2 = new Blob([csv], {
                type: 'text/csv'
              });
              let link2 = document.createElement('a');
              link2.href = window.URL.createObjectURL(blob2);
              link2.download =
                'BC SEIK List (' + moment().format('YYYYmmdHHMMSS') + ').csv';
              link2.click();

              var jsonse = JSON.stringify(res.data.file);
              console.log(jsonse);
              let blob = new Blob([jsonse], {
                type: 'application/json'
              });
              let link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);

              link.download =
                'BC Upload File  (' +
                moment().format('YYYYmmdHHMMSS') +
                ').json';
              link.click();

              swal({
                title: 'APC Weight (lbs)',
                text: res.data.apc_weight,
                icon: 'info'
              });
            } else {
              this.errors = res.data.errors;
              // swal({
              //   title: "Error",
              //   text: res.data.errors,
              //   icon: "error"
              // });
            }
          },
          err => {
            this.disable = false;
            swal({
              title: 'Error',
              text: err,
              icon: 'error'
            });
          }
        )
        .then(() => {
          this.loading2 = false;
          this.file = null;
        });
    },
    setFile(e) {
      if (e.target.files.length) {
        this.file = e.target.files[0];
      }
    },

    uploadFile2() {
      // upload file
      this.loading3 = true;
      this.errors = [];
      this.form2.append('import_file', this.file2);
      this.$http
        .post('/admin/export/update-processing', this.form2)
        .then(
          res => {
            this.disable = false;
            if (res.data.success) {
              swal({
                title: 'Success',
                text: 'Updated to processing',
                icon: 'success'
              });
            } else {
              swal({
                title: 'Error',
                text: res.data.errors,
                icon: 'error'
              });
            }
          },
          err => {
            this.disable = false;
            swal({
              title: 'Error',
              text: err,
              icon: 'error'
            });
          }
        )
        .finally(() => {
          this.loading3 = false;
          this.file2 = null;
        });
    },
    setFile2(e) {
      if (e.target.files.length) {
        this.file2 = e.target.files[0];
      }
    },

    getExport() {
      this.loading1 = true;

      this.$http
        .get(
          `/admin/export?export_type=${this.export_type}&start_date=${
            this.start_dropoff_date
          }&end_date=${this.end_dropoff_date}&user_email=${
            this.user_email
          }&truck=${this.truck}`
        )
        .then(response => {
          this.downloadFileUrl(response.data,this.export_type +
            ' (' +
            moment().format('YYYYmmdHHMMSS') +
            ').csv', 'text/csv');
        })
        .catch(err => {
          if (err.response.status === 404) {
            swal({
              title: 'Error',
              text: 'Nothing found',
              icon: 'error'
            });
          }
        })
        .finally(() => {
          this.loading1 = false;
        });
    }
  }
};
</script>
