<template>
  <div
    v-show="imp.step > 1"
    class="text-left footer"
    transition="slide-y-transition"
  >
    <v-tooltip top>
      <template #activator="{ on }">
        <v-btn
          v-show="
            imp.step > 1 && !imp.completion.purchased && !imp.completion.loading
          "
          color="primary"
          outlined
          :disabled="imp.completion.loading"
          v-on="on"
          @click="$store.commit('imp/back')"
        >
          <v-icon small>
            mdi-close
          </v-icon>Cancel
        </v-btn>
      </template>
      <span>Cancel current import and start over</span>
    </v-tooltip>

    <v-tooltip top>
      <template #activator="{ on }">
        <v-btn
          v-show="imp.completion.loading"
          color="primary"
          outlined
          v-on="on"
          @click="$store.commit('imp/abort')"
        >
          <v-icon small>
            mdi-close
          </v-icon>Abort
        </v-btn>
      </template>
      <span>Cancel current import and start over</span>
    </v-tooltip>

    <v-tooltip top>
      <template #activator="{ on }">
        <v-btn
          v-show="imp.step == 4 && imp.completion.purchased"
          color="primary"
          outlined
          :disabled="imp.completion.loading"
          v-on="on"
          @click="$store.commit('imp/reset')"
        >
          <v-icon small>
            mdi-back
          </v-icon>Reset
        </v-btn>
      </template>
      <span>Cancel current import and start over</span>
    </v-tooltip>

    <!-- <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="uploadFile()"
            color="primary"
            :disabled="uploadDisabled"
            :loading="uploadLoading"
            v-show="imp.step == 1"
            dusk="import_upload"
          >
            <v-icon small>mdi-upload</v-icon>Upload
          </v-btn>
        </template>
        <span>Submit your file to begin the import</span>
      </v-tooltip>-->
    <!-- 
    <v-tooltip top>
      <template #activator="{ on }">
        <v-btn
          v-show="imp.step == 2"
          :disabled="imp.selection.disabled"
          :loading="imp.selection.loading"
          color="primary"
          v-on="on"
          @click="$store.commit('imp/validationStart')"
        >
          <v-icon small>
            mdi-plus
          </v-icon>Batch
        </v-btn>
      </template>
      <span>Continue to the next step with the selected shipments</span>
    </v-tooltip> -->

    <v-tooltip top>
      <template #activator="{ on }">
        <v-btn
          v-show="imp.step == 2"
          :disabled="imp.selection.selected.length === 0"
          :loading="imp.selection.loading"
          color="primary"
          v-on="on"
          @click="show = true"
        >
          <v-icon small>
            mdi-plus
          </v-icon>Batch
        </v-btn>
      </template>
      <span>Continue to the next step with the selected shipments</span>
    </v-tooltip>

    <create-batch
      v-model="show"
      :shipments="imp.selection.selected"
    />
  </div>
</template>

<script>
import CreateBatch from './CreateBatch.vue';
import { mapState } from 'vuex';
export default {
  components: {
    CreateBatch
  },
  data() {
    return {
      show: false
    };
  },
  computed: {
    ...mapState(['imp'])
  },
  methods: {
    purchase() {
      if (!this.creditCheck(this.imp.total)) {
        return null;
      }
      var self = this;
      swal({
        title: 'Confirm Purchase',
        text:
          'You are about to pay ' +
          self.$options.filters.currency(this.imp.total) +
          ' for ' +
          this.imp.batch.count +
          ' shipments' +
          '.\n Do you wish to continue?',
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      }).then(function(isConfirm) {
        if (isConfirm) {
          self.$store.commit('imp/complete');
        }
      });
    }
  }
};
</script>
