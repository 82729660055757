<template>
  <SidePanelLayout>
    <template #side-panel>
      <return-details
        style="font-size: 14px;"
        :returns="selected"
      />
    </template>

    <template #default>
      <v-layout>
        <v-flex xs12>
          <admin-returns-table
            v-model="selected"
            :filter="filter"
          />
        </v-flex>
      </v-layout>

      <update-return-status
        v-model="show"
        :returns="selected"
      />
      <admin-return-dispose
        v-model="showDisposeDialog"
        :returns="selected"
      />
      <admin-return-request
        v-model="showRequestDialog"
        :returns="selected"
      />
    </template>

    <template #footer>
      <v-btn
        v-if="can('returns.edit')"
        outlined
        color="primary"
        :disabled="selected.length == 0"
        @click="show = true"
      >
        <v-icon
          small
          left
        >
          mdi-pencil
        </v-icon>Status
      </v-btn>

      <v-btn
        outlined
        color="primary"
        :disabled="!requestable"
        @click="showRequestDialog = true"
      >
        <v-icon
          small
          left
        >
          mdi-keyboard-backspace
        </v-icon>Request
      </v-btn>

      <v-btn
        v-if="can('returns.edit')"
        color="red"
        outlined
        style="color: white;"
        :disabled="!disposable"
        @click="showDisposeDialog = true"
      >
        <v-icon
          small
          left
        >
          mdi-delete-off-outline
        </v-icon>Dispose
      </v-btn>

      <v-menu offset-y>
        <template #activator="{ on }">
          <v-btn
            color="primary"
            :disabled="selected.length !== 1"
            v-on="on"
            @click="$refs.printReturns.openDialog()"
          >
            <v-icon
              small
              dense
              left
            >
              mdi-printer
            </v-icon>
            Print
          </v-btn>
        </template>
      </v-menu>
      
      <print-returns
        ref="printReturns"
        :disabled="selected.length !== 1"
        :shipments="selected"
      />
    </template>
  </SidePanelLayout>
</template>

<script>
import CONSTANTS from '@/constants/index';
export default {
  data() {
    return {
      filter: '',
      status: '',
      selected: [],
      show: false,
      showRequestPhotos: false,
      billAllLoading: false,
      showRequestDialog: false,
      showDisposeDialog: false
    };
  },
  computed: {
    printable() {
      if (
        this.selected.length != 0 &&
        this.selected.every(r => r.label !== null)
      ) {
        return true;
      } else {
        return false;
      }
    },
    requestable() {
      if (
        this.selected.length != 0 &&
        this.selected.every(r =>
          [
            CONSTANTS.RETURN_STATUSES.ready_to_ship,
            CONSTANTS.RETURN_STATUSES.awaiting_review
          ].includes(r.status_id)
        ) &&
        this.selected.every(r =>
          [
            CONSTANTS.RETURN_SERVICES.action_required,
            CONSTANTS.RETURN_SERVICES.dispose
          ].includes(r.service_id)
        )
        // this.selected.every(r => [10, 5].includes(r.status_id)) &&
        // this.selected.every(r => r.service_id === 1)
      ) {
        return true;
      } else {
        return false;
      }
    },
    disposable() {
      if (
        this.selected.length != 0 &&
        this.selected.every(r =>
          [
            CONSTANTS.RETURN_SERVICES.action_required,
            CONSTANTS.RETURN_SERVICES.return_requested
          ].includes(r.service_id)
        )
        // this.selected.every((r) => [1, 2].includes(r.service_id))
      ) {
        return true;
      } else {
        return false;
      }
    },
    photo_requestable() {
      if (
        this.selected.length != 0 &&
        this.selected.every(r => r.images_required == false)
      ) {
        return true;
      } else {
        return false;
      }
    },
    selectedReturns() {
      if (
        this.selected.length != 0 &&
        this.selected.every(
          r => r.service_id === CONSTANTS.RETURN_SERVICES.action_required
        )
        // this.selected.every((r) => r.service_id === 1)
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: { 
    '$route.query.filter': {
      handler: function(filter) {
        this.filter = filter || ''
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.pagination.page = 1;
      this.timer = setTimeout(function() {
        self.getDataFromApi().then(data => {
          self.desserts = data.items;
          self.totalDesserts = data.total;
          self.numPages = data.pages;
        });
      }, ms);
    },
    printLabel() {
      if (this.selected.length > 0) {
        const id = this.selected[0].label.id;
        this.$http
          .get(`/admin/return-labels/downloadlabel/${id}`)
          .then(response => {
            this.downloadFileUrl(response.data,id + '.pdf');
          });
      }
    },
    submit() {
      this.$http
        .post('/admin/returns/update-status', {
          returns: this.selected,
          status: this.status
        })
        .then(response => {
          if (response.data.success) {
            this.getDataFromApi().then(data => {
              this.desserts = data.items;
              this.totalDesserts = data.total;
              this.numPages = data.pages;
            });

            Event.fire(
              'show-snackbar',
              this.selected.length + ' returns have been updated.'
            );

            this.dialog = null;
            this.selected = [];
          } else {
            swal({
              title: 'Error',
              text: 'error',
              icon: 'error'
            });
          }
        })
        .finally(() => {
          this.processing = false;
        });
    }
  }
};
</script>
