<template>
  <v-main>
    <v-container
      container--fluid
      fill-height
    >
      <v-layout
        align-center
        justify-center
      >
        <v-flex
          xs12
          s12
          md6
          lg3
          xl2
        />
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
export default {
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  mounted() {
    console.log(this.id);
    console.log('Reference Component mounted');
    var date = new Date();
    date.setDate(date.getDate() + 30);
    localStorage.reference = this.$route.params.id;
    localStorage.referenceExpires = date.toISOString();
    this.$router.push('/register');
  }
};
</script>
