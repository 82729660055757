<template>
  <v-card
    :loading="loading"
    outlined
  >
    <v-card-title> {{ title }}</v-card-title>
    <v-card-text>
      <line-chart
        :height="200"
        :chart-data="formattedData"
      />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: 'By Type'
    },
    typeColumn: {
      type: String,
      default: 'date'
    },
    dataColumns: {
      type: Array,
      default: () => ['amount']
    }
  },
  computed: {
    formattedData() {
      let d = {
        labels: this.data.map((a) => a[this.typeColumn]),
        datasets: []
      };

      this.dataColumns.forEach((element) => {
        const color = this.getRandomColor();
        const capitalize = this.humanize(element);

        d.datasets.push({
          label: capitalize,
          borderColor: color,
          // backgroundColor: color,
          data: this.data.map((a) => a[element]),
          // fill: true
        });
      });

      return d;
    }
  },
  methods: {
    getRandomColor() {
      var letters = '0123456789ABCDEF';
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }
  }
};
</script>