<template>
  <v-card flat>
    <v-dialog
      v-model="atomLoading"
      persistent
      width="300"
    >
      <v-card dark>
        <v-card-text>
          <v-layout
            justify-center
            class="pt-3"
          >
            <atom-spinner
              :animation-duration="2000"
              :size="100"
              :color="'#f9ac40'"
            />
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-container class="px-0 pt-0">
      <h3>Import Store Settings</h3>
      <p class="mb-2">
        Change your default import store settings.
      </p>
    </v-container>

    <v-container
      v-if="stores.length == 0 && !loading"
      class="pa-0"
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <router-link to="/import">
            <h3>Integrate your online shop with Stallion Express!</h3>
          </router-link>
        </v-col>
      </v-row>
    </v-container>

    <v-container
      v-else
      class="pa-0"
    >
      <v-row>
        <v-col cols="12">
          <v-select
            v-model="storeId"
            outlined
            :items="stores"
            :item-text="getStoreName"
            item-value="id"
            label="Store"
            hide-details
            @change="onStoreChange = true"
          />
        </v-col>
      </v-row>
      <br>
      <v-text-field
        v-if="onStoreChange"
        v-model="nickname"
        v-validate="''"
        class="card-actions"
        clearable
        type="text"
        label="Nickname"
        outlined
      />

      <StoreLogo
        v-if="storeId"
        :user-store-id="storeId"
      />

      <br>
      <v-tabs
        v-if="
          selectedStoreSettings &&
            selectedStoreSettings.import_store_config_settings.store.length
        "
        v-model="tabSelected"
        :center-active="$vuetify.breakpoint.mobile"
        :show-arrows="$vuetify.breakpoint.mobile"
        :class="{ 'my-tabs': !$vuetify.breakpoint.mobile }"
        class="elevation-0"
      >
        <v-tab
          v-for="tab1 in tabs"
          :key="tab1"
          :href="'#' + tab1"
        >
          <h3 class="settings-tab">
            {{ tab1 }}
          </h3>
        </v-tab>

        <v-tab-item
          v-for="tab2 in tabs"
          :key="tab2"
          :value="tab2"
        >
          <v-row>
            <v-col
              v-for="(list, index) in selectedGroupStoreSettings"
              :key="index + tab2"
              cols="12"
              :md="12"
              class="my-n2"
            >
              <div v-if="tab2 == 'RETURNS'">
                <div v-if="list.key == 'enable_returns_portal'">
                  <user-settings-option :list="list">
                    <template #option-footer>
                      <div v-if="isReturnsPortalEnabled()">
                        <strong>URL: </strong>
                        <a
                          v-clipboard:copy="returnsPortalUrl"
                          v-clipboard:success="oncopy"
                          style="font-weight: bold"
                          href="javascript:void(0);"
                        >
                          {{ returnsPortalUrl }}
                        </a>
                      </div>
                    </template>
                  </user-settings-option>
                </div>

                <div v-else-if="isReturnsPortalEnabled()">
                  <user-settings-option
                    :full-height="true"
                    :list="list"
                  />
                </div>
              </div>

              <div v-else>
                <user-settings-option
                  :full-height="true"
                  :list="list"
                />
              </div>
            </v-col>
          </v-row>

          <v-row
            v-if="tab2 == 'IMPORT'"
            dense
          >
            <v-col
              cols="12"
              md="12"
            >
              <v-card
                outlined
                class="pa-5 my-3"
              >
                <div>
                  <h3>Product Import</h3>
    
                  <p class="mb-3">
                    Import products from Store.
                  </p>
    
                  <p>
                    Last updated -
                    {{ selectedStoreSettings.updated_at | datetime_from }}
                  </p>
                </div>
    
                <div>
                  <v-btn
                    :loading="productLoading"
                    outlined
                    primary
                    class="mt-5"
                    color="primary"
                    @click="fetchProducts(selectedStoreSettings.id)"
                  >
                    <v-icon left>
                      mdi-refresh
                    </v-icon> Refresh
                  </v-btn>
                </div>
              </v-card>
            </v-col>
          </v-row>

          <v-row
            v-if="tab2 == 'FULFILLMENT'"
            dense
          >
            <v-col
              cols="12"
              md="12"
            >
              <fulfillment-settings
                :key="storeId"
                :user-store-id="selectedStoreSettings.id"
                :settings="fulfillmentSettings"
                save-btn-text="Save Fulfillment"
                :save-btn-outlined="true"
                save-btn-justify="start"
                @updateFulfillmentSettings="updateFulfillmentSettings"
              />
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>

      <v-row class="pa-3 my-3">
        <v-btn
          v-if="selectedStoreSettings"
          color="primary"
          :loading="loading"
          @click="prepareStoreSettings()"
        >
          Update Store Settings
        </v-btn>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { AtomSpinner } from 'epic-spinners';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import FulfillmentSettings from '@/pages/main/import/FulfillmentSettings.vue';
import StoreLogo from './store-logo.vue';

export default {
  components: {
    AtomSpinner,
    FulfillmentSettings,
    StoreLogo
  },
  props: {
    importBaseSettings: {
      type: Array,
      default: () => {
        return [];
      }
    },
    id: {
      type: Number,
      required: false,
      default: null
    }
  },
  data() {
    return {
      tabSelected: 'IMPORT',
      tabs: [],
      atomLoading: false,
      loading: false,
      productLoading: false,
      onStoreChange: false,
      storeId: null,
      tab: null,
      stores: [],
      importStoreSettings: [],
      nickname: null,
      fulfillmentSettings: {}
    };
  },
  computed: {
    returnsPortalUrl() {
      if (!this.selectedStore) return '';

      return `${window.location.protocol}//${window.location.hostname}/r/p/${this.selectedStore.returns_portal_code}`;
    },

    selectedStore() {
      return _find(this.stores, { id: this.storeId });
    },

    selectedStoreSettings() {
      const store = _.find(this.importStoreSettings, (o) => {
        return o.id == this.storeId;
      });
      return store;
    },

    selectedGroupStoreSettings() {
      if (!this.tabSelected) {
        return [];
      }

      if (
        !this.selectedStoreSettings ||
        this.selectedStoreSettings.import_store_config_settings?.store
          ?.length == 0
      ) {
        return [];
      }

      return this.selectedStoreSettings.import_store_config_settings.store.filter(
        (setting) => {
          return setting.group == this.tabSelected;
        }
      );
    },
    
  },
  watch: {
    importBaseSettings: {
      handler: function () {
        // based settings has been changed from another part of the application
        // need to resync local base settings
        this.importStoreSettings.map((localSetting) => {
          (localSetting.import_store_config_settings.base || []).map(
            (local) => {
              const baseItem = _.find(this.importBaseSettings, (base) => {
                return base.key == local.key;
              });
              if (baseItem && !local.overriden) {
                local.value = baseItem.value;
              }
            }
          );
        });
      },
      deep: true,
      immediate: true
    },
    '$route.query': {
      handler: function () {
        const { id } = this.$route.query;
        this.storeId = !isNaN(id) ? parseInt(id) : null;
      },
      deep: true,
      immediate: true
    },
    id: {
      handler: function () {
        const { id } = this;
        this.storeId = !isNaN(id) ? parseInt(id) : null;
        this.tabSelected = 'IMPORT';
        if(this.storeId) {
          this.onStoreChange = true;
        }
      },
      immediate: true,
    },
    storeId: {
      handler: function () {
        this.getUserStoreSettings().then(() => {
          // get settings from store config - this will have default values based on user-store-settings config
          let settings  = _find(this.importStoreSettings || [], (store) => {
            return store.id == this.storeId
          })?.settings || []

          settings = _find(settings, (setting) => {
            return setting.key == 'fulfillment_settings'
          })?.value || {}

          this.fulfillmentSettings = {
            stallion_fulfillment: settings?.stallion_fulfillment || 0, // default carrier tracking
            fulfill_shipments: settings?.fulfill_shipments,
            fulfillment_delay: !isNaN(settings?.fulfillment_delay) ? settings?.fulfillment_delay : null,
            fulfillment_custom_delay: {
              hours_enabled:
                settings?.fulfillment_custom_delay?.hours_enabled || false,
              hours: settings?.fulfillment_custom_delay?.hours || null,
              time_enabled:
                settings?.fulfillment_custom_delay?.time_enabled || false,
              time: settings?.fulfillment_custom_delay?.time || null
            }
          }
        });
      },
      immediate: true,
    }
  },
  mounted() {
    this.getUserStores();
    this.getUserStoreSettings();
    this.getGroupStoreSettings();
  },
  methods: {
    oncopy() {
      this.$notify({
        group: 'main',
        title: 'Copied!',
        text: 'Returns Portal URL has been copiend to the clipboard',
        duration: 2000,
        type: 'info'
      });
    },

    isReturnsPortalEnabled() {
      const portal = _find(this.selectedGroupStoreSettings, {
        key: 'enable_returns_portal'
      });

      return portal && portal.value == '1';
    },

    updateFulfillmentSettings(settings) {
      const storeIndex = _findIndex(this.importStoreSettings, (store) => {
        return store.id == this.storeId
      })

      if (storeIndex == -1) {
        return
      }

      const configIndex = _findIndex(this.importStoreSettings[storeIndex]?.import_store_config_settings?.store || [], (setting) => {
          return setting.key == 'fulfillment_settings'
        })
      
      if (configIndex == -1) {
        return
      }

      this.importStoreSettings[storeIndex].import_store_config_settings.store[configIndex].value = settings
    },
    getStoreName(item) {
      if(item.nickname == null) {
        this.nickname = null;
        return item.identifier;
      } else {
        this.nickname = this.selectedStore?.nickname;
        return item.nickname;
      }
    },
    getUserStores() {
      this.loading = true;
      this.$http
        .get(`/user-stores`)
        .then((response) => response.data)
        .then((response) => {
          this.stores = response;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    getGroupStoreSettings() {
      this.$http
        .get(`/group-store-settings`)
        .then((response) => response.data)
        .then((response) => {
          this.tabs = response;
        })
    },
    async getUserStoreSettings() {
      try {
        this.atomLoading = true;
        const response = await this.$http.get(`/user-store-settings`);
        this.importStoreSettings = response.data.map((store) => {
          return store;
        });
      } catch (error) {
        console.error('Error fetching import store settings:', error);
      } finally {
        this.atomLoading = false;
      }
    },
    prepareStoreSettings() {
      this.submitImportSettings({
        id: this.selectedStoreSettings.id,
        settings: {
          // store: this.selectedStoreSettings.import_store_config_settings.store
          store: this.selectedGroupStoreSettings
        },
        type: 'store'
      });
      this.updateNickname();
    },
    prepareBaseSettings() {
      this.submitImportSettings({
        id: this.selectedStoreSettings.id,
        settings: this.selectedStoreSettings.import_store_config_settings.base,
        type: 'base'
      });
    },
    submitImportSettings(payload) {
      this.loading = true;
      this.$http
        .post(`/user-store-settings`, payload)
        .then((response) => response.data)
        .then(() => {
          this.successMessage('Your account changes have been saved');
        })
        .catch((error) => {
          console.log(error.response);
          this.errorMessage('Something went wrong! Please try again.');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchProducts(id) {
      this.productLoading = true;
      let payload = {
        user_store_id: id
      };
      this.$http
        .post(`/user-stores/products/update`, payload)
        .then((response) => response.data)
        .then(() => {
          // console.log(response);
          this.successMessage('Products are being fetched');
        })
        .catch((error) => {
          console.log(error.response);
          this.errorMessage('Something went wrong! Please try again.');
        })
        .finally(() => {
          this.productLoading = false;
        });
    },
    updateNickname(){
      if(this.selectedStore.nickname != this.nickname){
        let payload = {
          user_store_id: this.selectedStore.id,
          nickname: this.nickname
        };
        this.$http
            .post(`/user-stores/update`, payload)
            .then((response) => response.data)
            .catch((error) => {
              console.log(error.response);
              this.errorMessage('Something went wrong! Please try again.');
            })
            .finally(() => {
              this.getUserStores();
              this.$store.dispatch('userStores/getStores');
            });
      }
    }
  }
};
</script>
