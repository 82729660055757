<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="true"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          v-model="computedDateFormattedMomentjs"
          prepend-inner-icon="event"
          :outlined="outlined"
          :dense="dense"
          :label="label"
          readonly
          v-bind="attrs"
          clearable
          :disabled="disabled"
          @click:clear="date = null"
          v-on="on"
        />
      </template>
      <v-date-picker
        v-model="date"
        no-title
        scrollable
        :min="minDate"
        :max="maxDate"
        :show-current="minDate"
        :disabled="disabled"
      />
    </v-menu>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    label: {
      type: String,
      default: null
    },
    dense: {
      type: Boolean,
      default: true
    },
    outlined: {
      type: Boolean,
      default: true
    },
    value: {
      type: String,
      default: null
    },
    minDate: {
      type: String,
      default: null
    },
    maxDate: {
      type: String,
      default: null
    },
    format: {
      type: String,
      default:'dddd, MMMM Do YYYY'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      menu: null
    };
  },
  computed: {
    date: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
        this.$emit('change');
      }
    },
    computedDateFormattedMomentjs() {
      return this.date ? moment(this.date).format(this.format) : null;
    }
  },

  methods: {}
};
</script>
