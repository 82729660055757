<template>
  <div>
    <v-list
      v-for="(group, index) in side_menu"
      :key="index"
      class="rounded-list pa-0"
    >
      <v-subheader
        v-if="group.title"
        style="height: 40px"
      >
        {{ group.title }}
      </v-subheader>
      <stallion-list-item
        v-for="item in group.items"
        :key="item.text"
        v-bind="item"
      />
      <v-divider class="my-2" />
    </v-list>
  </div>
</template>

<script>
import PERMISSIONS from '@/constants/client-permissions';
import StallionListItem from '../ListItems/StallionListItem.vue';

export default {
  components: { StallionListItem },
  data() {
    return {
      side_menu: [
        {
          title: null,
          items: [
            {
              icon: 'mdi-view-dashboard',
              to: {
                name: 'dashboard'
              },
              text: 'Dashboard'
            },
            {
              icon: 'mdi-bell',
              to: {
                name: 'notifications'
              },
              text: 'Notifications'
            }
          ]
        },
        {
          title: null,
          items: [
            {
              icon: 'mdi-package-variant',
              click: this.newShipment,
              text: 'New Shipment',
              permission: PERMISSIONS.SHIPPING
            },
            {
              icon: 'mdi-package-variant',
              to: {
                name: 'import'
              },
              text: 'Import'
            },
            {
              icon: 'mdi-package-variant-closed',
              to: {
                name: 'shipments'
              },
              text: 'Shipments'
            },
            {
              icon: 'mdi-store',
              to: {
                name: 'supplies-store'
              },
              text: 'Supplies'
            },
            {
              icon: 'mdi-truck-fast',
              to: {
                name: 'pickups'
              },
              text: 'Pickups'
            },
            {
              icon: 'mdi-keyboard-backspace',
              to: {
                name: 'returns'
              },
              text: 'Returns'
            },
            {
              icon: 'mdi-clipboard-outline',
              to: {
                name: 'orders-show'
              },
              text: 'Orders'
            },
            {
              icon: 'mdi-file-document',
              to: {
                name: 'batches'
              },
              text: 'Batches'
            },
            {
              icon: 'fab fa-amazon',
              to: {
                name: 'fba'
              },
              click: this.newFbaShipment,
              text: 'FBA',
              permission: PERMISSIONS.SHIPPING
            },
          ]
        }
      ]
    };
  },
  methods: {
    newShipment: function () {
      this.$store.state.singleFlow.single_dialog = true;

      Event.fire('edit-shipment', {
        shipment: null,
        step: 'recipient'
      });
    },
  }
};
</script>
