// const units = [
//     null, // 0: Unknown, they start from 1
//     'mg', // 1: milligram (mg)
//     'g', //  2: gram (g)
//     'kg', // 3: kilogram (kg)
//     null, // 4: carat (ct) [Not Supported]
//     null, // 5: Tael (taels) [Not Supported]
//     null, // 6: grain (grain) [Not Supported]
//     null, // 7: pennyweight (dwt) [Not Supported]
//     null, // 8: metric tons (tonnes) [Not Supported]
//     null, // 9: avoir ton (tons) [Not Supported]
//     null, // 10:troy ounce (ozt) [Not Supported]
//     'oz', // 11: ounce (oz)
//     'lbs', // 12: pound (lbs)
// ];

const status = {
    // Unknown status
    UNKNOWN: 'UNKNOWN',
    // Faulty Scale
    FAULT_SCALE: 'FAULT_SCALE',
    // No weight on the scale
    ZERO: 'ZERO',
    // The weight is not stable yet
    UNSTABLE: 'UNSTABLE',
    // The weight is stable
    STABLE: 'STABLE',
    // The weight is under zero
    UNDER_ZERO: 'UNDER_ZERO',
    // The scale is over weighted
    OVER_WEIGHT: 'OVER_WEIGHT',
    // The scale requires callibration
    CAL_NEEDED: 'CAL_NEEDED',
    // The scale needs tare
    TARE_NEEDED: 'TARE_NEEDED',
}

const statusENUM = {
    1: status.FAULT_SCALE,
    2: status.ZERO,
    3: status.UNSTABLE,
    4: status.STABLE,
    5: status.UNDER_ZERO,
    6: status.OVER_WEIGHT,
    7: status.CAL_NEEDED,
    8: status.TARE_NEEDED,
}
export function ParseData (data = []){
    const raw_status = parseInt(data[1])
    const raw_weight_unit = parseInt(data[2],16)
    const raw = parseInt((256 * parseInt(data[5], 16) + parseInt(data[4], 16)).toString(), 10);
    const decimalPlacement = parseInt(data[3],16)

    const scale_data = ParseUnit(
        raw_weight_unit,
        raw,
        scalePrecision(decimalPlacement)
    )

    return {
        raw,
        raw_status,
        raw_weight_unit,
        status: statusENUM[raw_status],
        weight_unit: scale_data.unit,
        weight: Number(Math.round(scale_data.value + 'e' + 2) + 'e-' + 2),
    }
}

export function ParseUnit(unit, raw, precision){
    switch (unit) {
        case 2:
            return { value: parseFloat(raw), unit: 'g' }
        case 3:
            return { value: parseFloat(raw * precision), unit: 'kg' }
        case 11:
            return { value: parseFloat(raw * precision), unit: 'oz' }
        case 12:
            return { value: parseFloat(raw * precision), unit: 'lbs' }
        default:
            return { value: 0, unit: 'oz' }
    }
}

export function scalePrecision(decimalPlacement){
    const exponent = (256 - decimalPlacement) * -1
    return Math.pow(10, exponent)
}