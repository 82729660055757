<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="text-h3">Add Users</span>
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
          @input="submitSearch()"
        />
      </v-card-title>

      <v-data-table
        :headers="headers"
        :loading="loading"
        item-key="id"
        :items="items"
        :options.sync="options"
        class="table-striped"
        :server-items-length="totalItems"
        :footer-props="{
          'items-per-page-options': [50, 100, 200, 500]
        }"
        :items-per-page="25"
      >
        <template #item.action="{ item }">
          <v-btn
            color="primary"
            @click.stop="add(item)"
          >
            Add
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    groupId: {
      type: [String, Number],
      default: null
    }
  },

  data() {
    return {
      headers: [
        {
          text: 'ID',
          align: 'left',

          value: 'id'
        },
        {
          text: 'Username',
          align: 'left',

          value: 'username'
        },
        {
          text: 'Email',
          align: 'left',

          value: 'email'
        },
        {
          text: 'Name',
          align: 'left',

          value: 'name'
        },

        {
          text: '',
          align: 'left',

          value: 'action'
        }
      ],

      search: null,
      options: {
        sortBy: ['email'],
        sortDesc: [true]
      },
      loading: false,
      totalItems: 0,
      items: [],
      timer: null,
      cancelSource: null
    };
  },
  watch: {
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    }
  },
  mounted() {
    Event.listen('update-group-users', () => {
      this.updateTable();
    });
  },
  methods: {
    add(item) {
      var self = this;
      swal({
        title: 'Confirm Add',
        text: `Do you really want to add ${item.email} to this group?`,
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      }).then(function (isConfirm) {
        if (isConfirm) {
          self.$http
            .post(`/admin/users/${item.id}/add-group`, {
              group_id: self.groupId
            })
            .then((response) => response.data)
            .then(() => {
              Event.fire('update-group-users');
              self.$notify({
                group: 'main',
                title: 'User Added',
                text: 'User has been added to the group.',
                duration: 5000,
                type: 'success'
              });
            })
            .catch(() => {
              self.$notify({
                group: 'main',
                title: 'Error adding user',
                text: 'Could not add this user.',
                duration: 5000,
                type: 'error'
              });
            });
        }
      });
    },
    updateTable() {
      this.totalItems = 0;
      this.items = [];

      this.getDataFromApi().then((data) => {
        this.items = data.items;
        this.totalItems = data.total;
      });
    },
    getDataFromApi() {
      this.loading = true;
      return new Promise((resolve) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let items = [];
        let total = 0;
        let pages = 0;

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        var params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sortBy[0],
          desc: sortDesc[0],
          search: this.search
        };

        this.$http
          .request(`/admin/users`, {
            method: 'get',
            params: params,
            cancelToken: this.cancelSource.token
          })

          .then((response) => response.data)
          .then((response) => {
            items = response.data;
            total = response.total;
            pages = response.last_page;

            this.loading = false;
            resolve({
              items,
              total,
              pages
            });
          });
      });
    },
    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.options.page = 1;
      this.timer = setTimeout(function () {
        self.updateTable();
      }, ms);
    }
  }
};
</script>
