<template>
  <v-row dense>
    <v-col>
      <v-menu offset-y>
        <template #activator="{ on }">
          <v-btn
            v-if="can('charges.create')"
            color="primary"
            v-on="on"
            @click="$refs.chargeUser.openDialog()"
          >
            <v-icon left>
              mdi-minus
            </v-icon>Charge User
          </v-btn>

          <v-btn
            v-if="can('charges.create')"
            color="primary"
            v-on="on"
            @click="$refs.addCredits.openDialog()"
          >
            <v-icon left>
              mdi-plus
            </v-icon>Add Credits
          </v-btn>
        </template>
      </v-menu>
    </v-col>
    <v-col cols="auto">
      <v-menu offset-y>
        <template #activator="{ on }">
          <v-btn
            v-if="can('credits.add')"
            color="primary"
            v-on="on"
            @click="$refs.manualPayment.startNew()"
          >
            <v-icon left>
              mdi-square-edit-outline
            </v-icon>
            Record Payment
          </v-btn>
        </template>
      </v-menu>
    </v-col>
    <v-col cols="12">
      <v-divider />
    </v-col>
    <v-col cols="12">
      <transaction-table
        ref="transactionTable"
        :is-statement="false"
        :user-id="user.id"
      />
    </v-col>
    <v-col cols="12">
      <charge-user
        ref="chargeUser"
        :user="user"
        @complete="updateTransactions()"
      />
    </v-col>
    <v-col cols="12">
      <add-credits
        v-if="can('credits.add')"
        ref="addCredits"
        :user="user"
        @complete="updateTransactions()"
      />
    </v-col>
    <v-col cols="12">
      <manual-payment
        ref="manualPayment"
        :user="user"
        @complete="updateTransactions()"
      />
    </v-col>
  </v-row>
</template>

<script>
import ChargeUser from './../ChargeUser.vue';
import AddCredits from './../AddCredits.vue';
import ManualPayment from './../ManualPayment.vue';

export default {
  components: {
    ChargeUser,
    ManualPayment,
    AddCredits
  },
  props: {
    user: {
      type: Object,
      default: () => ({})
    }
  },
  methods:{
    updateTransactions(){
      this.$refs.transactionTable.updateTable()
    }
  }
};
</script>
