<template>
  <v-row dense>
    <!-- Trusted Device -->
    <v-col cols="12">
      <br>
      <h4>Trusted Devices</h4>
      <v-card outlined>
        <v-data-table
          :headers="deviceHeaders"
          :loading="loadingDevice"
          item-key="id"
          :items="trustedDevices"
          class="table-striped elevation-0"
        >
          <template #item.browser="{ item }">
            {{ item.browser + '(' + item.os + ')' }}
          </template>

          <template #item.location="{ item }">
            {{ item.city + ', ' + item.region + ', ' + item.country }}
          </template>

          <template #item.expires_at="{ item }">
            {{ item.expires_at | datetime_short }}
          </template>

          <template #item.actions="{ item }">
            <v-btn
              :disabled="loadingDevice"
              small
              color="red"
              text
              @click="deleteTrustedDevice(item)"
            >
              Revoke
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loadingDevice: false,
      trustedDevices: [],
      deviceHeaders: [
        {
          text: 'IP Address',
          align: 'left',
          value: 'ip_address'
        },
        {
          text: 'Browser',
          align: 'left',
          value: 'browser'
        },
        {
          text: 'Location',
          align: 'left',
          value: 'location'
        },
        {
          text: 'Expires At',
          align: 'left',
          value: 'expires_at'
        },
        {
          text: '',
          align: 'right',
          value: 'actions'
        }
      ]
    };
  },
  computed: {},
  created() {
    this.getTrustedDevices();
  },
  methods: {
    getTrustedDevices() {
      this.loadingDevice = true;
      this.$http
        .get('trusted-devices')
        .then((response) => {
          this.trustedDevices = response.data;
        })
        .catch(() => {
          this.errorMessage('Unknown Error while retrieving trusted device!');
        })
        .finally(() => {
          this.loadingDevice = false;
        });
    },
    async deleteTrustedDevice(device) {
      const isConfirm = await swal({
        title: 'Confirm Delete',
        text: 'Do you really want to delete this device?',
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      });

      if (!isConfirm) return;

      this.loadingDevice = true;
      this.$http
        .delete(`trusted-devices/${device.id}`)
        .then(() => {
          swal({
            title: 'Success',
            text: 'Device successfully deleted',
            icon: 'success'
          });
          this.trustedDevices = this.trustedDevices.filter((item) => {
            return device.id != item.id;
          });
        })
        .catch((err) => {
          swal({
            title: 'Error!',
            text: err.response.data?.message || 'An error occurred',
            icon: 'error'
          });
        })
        .finally(() => {
          this.loadingDevice = false;
        });
    }
  }
};
</script>
