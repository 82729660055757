<template>
  <div>
    <v-container
      grid-list-md
      mb-12
    >
      <v-layout v-if="group !== null">
        <v-flex xs12>
          <h1>
            <span>
              <i class="mdi mdi-account-group" /> User Group
              {{ group.name }}
            </span>
          </h1>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex
          xs12
          md6
        >
          <group-users-table :group-id="$route.params.id" />
        </v-flex>
        <v-flex
          xs12
          md6
        >
          <group-add-users-table :group-id="$route.params.id" />
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      group: null
    };
  },
  watch: {},
  mounted() {
    this.getGroup();
  },

  methods: {
    getGroup() {
      // GET /someUrl
      this.$http.get('/admin/user-groups/' + this.$route.params.id).then(
        response => {
          this.group = response.data;
        },
        () => {
          // error callback
        }
      );
    }
  }
};
</script>
