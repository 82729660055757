<template>
  <FullPageLayout title="Return Orders">
    <template #default>
      <v-row>
        <v-col cols="12">
          <v-form>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                  style="padding: 0 5px"
                >
                  <v-text-field
                    v-model="search"
                    pt-0
                    outlined
                    dense
                    label="Search"
                    prepend-inner-icon="search"
                    clearable
                    class="pa-0"
                    @input="submitSearch()"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                  style="padding: 0 5px"
                >
                  <v-autocomplete
                    v-model="user_id"
                    prepend-inner-icon="mdi-pound-box"
                    :items="userIDs"
                    :search-input.sync="searchID"
                    hide-selected
                    outlined
                    dense
                    label="User ID"
                    clearable
                    class="pa-0"
                    @change="submitSearch()"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                  style="padding: 0 5px"
                >
                  <v-autocomplete
                    v-model="email"
                    prepend-inner-icon="email"
                    :items="userEmails"
                    :search-input.sync="searchEmail"
                    hide-selected
                    outlined
                    dense
                    label="Email"
                    clearable
                    class="pa-0"
                    @change="submitSearch()"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-col>
        <v-col cols="12">
          <v-data-table
            v-model="selected"
            :headers="headers"
            :loading="loading"
            show-select
            item-key="id"
            :items="items"
            :options.sync="options"
            class="table-striped"
            :server-items-length="totalItems"
            :footer-props="{
              'items-per-page-options': [
                50, 100, 200, 500
              ]
            }"
            :items-per-page="25"
            @click:row="rowClick"
          >
            <template #item.id="{ item }">
              <router-link
                v-if="item.id"
                :to="{
                  name: 'admin-return-orders-view',
                  params: { id: item.id }
                }"
              >
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <span v-on="on">{{ item.id }}</span>
                  </template>
                  <span>View Order</span>
                </v-tooltip>
              </router-link>
            </template>

            <template #item.user_id="{ item }">
              <router-link
                router
                :to="{ name: 'admin-user-show', params: { id: item.user_id } }"
              >
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <span v-on="on">
                      {{ item.user.id }}
                      <br>
                      {{ item.user.email }}
                    </span>
                  </template>
                  <span>View User</span>
                </v-tooltip>
              </router-link>
            </template>

            <template #item.created_at="{ item }">
              {{ item.created_at | date_pretty }}
              <br>
              ({{ item.created_at | datetime_from }})
            </template>

            <template #item.pickup_at="{ item }">
              <div v-if="item.pickup_at">
                {{ item.pickup_at | date_pretty }}
                <br>
                ({{ item.pickup_at | datetime_from }})
              </div>
            </template>
            <template #item.tax="{ item }">
              {{ item.tax | currency }}
            </template>
            <template #item.total="{ item }">
              {{ item.total | currency }}
            </template>
            <template #item.handling_fee="{ item }">
              {{ item.handling_fee | currency }}
            </template>
            <template #item.disposal="{ item }">
              <span v-if="item.disposal">Dispose</span>
              <span v-else>Return</span>
            </template>
            <template #item.returns="{ item }">
              {{ item.returns.length }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </template>
    <template #footer>
      <v-btn
        color="primary"
        :disabled="selected.length == 0"
        @click="show = true"
      >
        <v-icon>mdi-printer</v-icon>Print
      </v-btn>

      <return-orders-print
        v-model="show"
        :orders="selected"
      />
    </template>
  </FullPageLayout>
</template>

<script>
export default {
  props: {
    filter: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      start_date: null,
      userEmails: [],
      userIDs: [],
      end_date: null,
      selected: [],
      search: null,
      show: null,
      user_id: null,
      email: null,

      timer: null,
      headers: [
        {
          text: 'User',
          align: 'left',
          value: 'user_id'
        },
        {
          text: 'Order #',
          align: 'left',
          value: 'id'
        },
        {
          text: 'Returns',
          sortable: false,
          value: 'item_count'
        },
        {
          text: 'Arrived',
          sortable: false,
          value: 'ready_count'
        },
        {
          text: 'Status',
          sortable: false,
          value: 'order_status'
        },
        {
          text: 'Type',
          value: 'disposal'
        },
        {
          text: 'Pickup At',
          value: 'pickup_at'
        },
        {
          text: 'Created At',
          value: 'created_at'
        }
      ],
      options: {
        sortBy: ['created_at'],
        sortDesc: [true]
      },
      loading: false,
      totalItems: 0,
      items: [],

      searchID: null,
      searchEmail: null
    };
  },
  computed: {
    requestable() {
      if (this.selected.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    user_id: function (val) {
      if (val) {
        var index = this.users.map((e) => e.id).indexOf(val);

        this.email = this.users[index].email;
      } else {
        this.email = null;
      }
    },
    email: function (val) {
      if (val) {
        var index = this.users.map((e) => e.email).indexOf(val);

        this.user_id = this.users[index].id;
      } else {
        this.user_id = null;
      }
    },
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    },
    '$route.query.filter'() {
      console.log('filer');
      this.updateTable();
    }
  },
  mounted() {
    this.getShipCodes();
  },
  methods: {
    getShipCodes() {
      this.$http
        .get('/admin/users/all')
        .then((response) => response.data)
        .then((response) => {
          this.userIDs = response.map((e) => e.id);
          this.userEmails = response.map((e) => e.email);
          this.users = response;
        })
        .catch(() => {});
    },
    rowClick(item) {
      this.selected = [item];
    },
    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.options.page = 1;
      this.timer = setTimeout(function () {
        self.updateTable();
      }, ms);
    },
    updateTable() {
      this.selected = [];
      this.totalItems = 0;
      this.items = [];

      this.getDataFromApi().then((data) => {
        this.items = data.items;
        this.totalItems = data.total;
      });
    },
    getDataFromApi() {
      this.loading = true;
      return new Promise((resolve) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let items = [];
        let total = 0;
        let pages = 0;

        var params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sortBy[0],
          desc: sortDesc[0],
          search: this.search,
          start_date: this.start_date,
          end_date: this.end_date,
          filter: this.$route.query.filter,

          user_id: this.user_id
        };

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        this.$http
          .request(`/admin/return-invoices`, {
            method: 'get',
            params: params,
            cancelToken: this.cancelSource.token
          })
          .then((response) => response.data)
          .then((response) => {
            items = response.data;
            total = response.total;
            pages = response.last_page;

            this.loading = false;
            resolve({
              items,
              total,
              pages
            });
          });
      });
    }
  }
};
</script>
