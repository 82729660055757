<template>
  <div>
    <h2 class="text-center">
      To Address
      <!-- <a href="#/account-info" class="btn btn-info btn-xs" v-on:click="editShipmentAddress()">Edit</a> -->
    </h2>
    <hr>
    <ul>
      <li>{{ shipment.name }}</li>
      <li v-if="shipment.company">
        {{ shipment.company }}
      </li>
      <li>{{ shipment.address1 }}</li>
      <li v-if="shipment.address2">
        {{ shipment.address2 }}
      </li>
      <li>
        {{
          shipment.city +
            ', ' +
            shipment.province_code +
            ' ' +
            shipment.postal_code
        }}
      </li>
      <li v-if="shipment.phone">
        Phone: {{ shipment.phone }}
      </li>
      <li>{{ shipment.country_code }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    shipment: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
