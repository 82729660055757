<template>
  <SingleCardLayout>
    <form
      id="registerform"
      class="mt-4"
      method="post"
      @submit.prevent="register"
    >
      <h2 class="box-title m-b-20">
        Verify Email
      </h2>

      <p class="mb-5">
        Verify your emaill token.
      </p>
      <div class="row">
        <div
          v-if="error"
          class="col-xs-12"
        >
          <div
            class="alert alert-danger"
            role="alert"
          >
            <strong>Error</strong>
            {{ error }}
          </div>
        </div>
      </div>
    </form>
  </SingleCardLayout>
</template>

<script>
// if (gtag !== undefined) {
//   gtag('event', 'conversion', { send_to: 'AW-940511379/3yPZCJzV2ZIBEJOhvMAD' });
// }
export default {
  data() {
    return {
      errors: [],
      email: '',
      error: ''
    };
  },
  mounted() {
    console.log('register component mounted.');

    if (this.$route.params.code) this.verifyCode();
  },
  methods: {
    sendLink() {
      this.getCsrf().then(() => {
        this.$http
          .post('/auth/resend-verification', {
            email: this.email
          })
          .then((res) => {
            console.log(res);
            if (res.data.success) {
              swal('Success!', 'A reset token has been sent to your email.');
            } else {
              swal('Error!', res.data.errors);
            }
          })
          .catch(() => {
            swal('Error!', 'Unknown error occurred');
          });
      });
    },
    verifyCode() {
      this.getCsrf().then(() => {
        this.$http
          .get('/auth/verify/' + this.$route.params.code)
          .then((res) => {
            console.log(res);
            if (res.data.success) {
              if (!this.$auth.authenticated) {
                this.successMessage(
                  'Email verification successful, login to start shipping.'
                );
              } else {
                this.successMessage('Email verification successful!');
              }
              this.$router.push('/');
            } else {
              swal('Error!', res.data.errors);
            }
          })
          .catch(() => {
            swal('Error!', 'Unknown error occurred');
          });
      });
    }
  }
};
</script>
