<template>
  <StallionDialog
    v-model="show"
    :width="500"
    persistent
    :title="'Edit Batch '.concat(batch?.id)"
  >
    <template
      v-if="batch"
      #content
    >
      <v-form>
        <v-container fluid>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="batch.name"
                label="Name (Optional)"
                clearable
                outlined
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>

    <template #actions>
      <v-btn
        :loading="loading"
        :disabled="loading"
        color="primary"
        class="px-7"
        @click="updateBatch()"
      >
        Update
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    batch: {
      type: Object,
      default: null
    },
    value: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loading: false
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },

  methods: {
    updateBatch() {
      this.loading = true;
      this.$http
        .put(`/batches/${this.batch.id}`, this.batch)
        .then(() => {
          this.successMessage('Batch name successfully updated');
          this.show = false;
          this.$emit('update-batch');
        })
        .catch(() => {
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
