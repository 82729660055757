<template>
  <div>
    <v-form v-if="!condensed">
      <v-row dense>
        <v-col
          cols="12"
          md="3"
        >
          <v-autocomplete
            v-model="closeout_type_ids"
            outlined
            dense
            prepend-inner-icon="mdi-clipboard-check"
            :items="closeout_types"
            item-value="id"
            item-text="name"
            label="Closeout Type"
            multiple
            clearable
            :search-input.sync="closeout_type_input"
            @change="submitSearch()"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-autocomplete
            v-model="location_ids"
            outlined
            dense
            prepend-inner-icon="mdi-map-marker"
            :items="locations"
            item-value="id"
            item-text="name"
            label="Location"
            multiple
            clearable
            :search-input.sync="location_input"
            @change="submitSearch()"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-menu
            v-model="start_date_menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            :menu-props="{ minWidth: '290px' }"
          >
            <template #activator="{ on }">
              <v-text-field
                v-model="start_date"
                outlined
                dense
                prepend-inner-icon="event"
                label="Start Date"
                readonly
                clearable
                v-on="on"
                @change="submitSearch()"
              />
            </template>
            <v-date-picker
              v-model="start_date"
              @change="submitSearch()"
              @input="start_date_menu = false"
            />
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-menu
            v-model="end_date_menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            :menu-props="{ minWidth: '290px' }"
          >
            <template #activator="{ on }">
              <v-text-field
                v-model="end_date"
                outlined
                dense
                prepend-inner-icon="event"
                label="End Date"
                readonly
                clearable
                v-on="on"
                @change="submitSearch()"
              />
            </template>
            <v-date-picker
              v-model="end_date"
              @change="submitSearch()"
              @input="end_date_menu = false"
            />
          </v-menu>
        </v-col>
      </v-row>
    </v-form>

    <v-row>
      <v-col cols="12">
        <v-data-table
          class="table-striped"
          :headers="headers"
          :items="items"
          :loading="loading"
          :options.sync="options"
          :server-items-length="totalItems"
          :footer-props="{
            'items-per-page-options': [10, 25, 50, 100]
          }"
          @click:row="rowClick"
        >
          <template #item.created_at="{ item }">
            {{ item.created_at | date_pretty }}
            <br>
            ({{ item.created_at | datetime_from }})
          </template>

          <template #item.valid="{ item }">
            <span v-if="item.valid == null">Processing</span>
            <span v-else-if="item.valid == 0">Error</span>
            <span v-else-if="item.valid == 1">Complete</span>
          </template>

          <template #item.type="{ item }">
            {{ item.type.name }} <br>{{ item.description }}
          </template>

          <template #item.files="{ item }">
            <div
              v-if="item.files && item.files.length > 0"
              @click.stop
            >
              <div
                v-for="(file, index) in item.files"
                :key="`${item.id}_${index}_${file}`"
                @click.prevent="download(file)"
              >
                <a>{{ file | file_download }}</a>
              </div>
            </div>
          </template>

          <template #item.label="{ item }">
            <div @click.stop>
              <v-btn
                icon
                color="primary"
                @click.prevent="generateLabel(item.id)"
              >
                <v-icon>mdi-printer</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    condensed: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      loading: false,
      totalItems: 0,
      items: [],
      locations: [],
      location_ids: [],
      location_input: null,
      start_date: null,
      end_date: null,
      start_date_menu: null,
      end_date_menu: null,
      timer: null,
      closeout_types: [],
      closeout_type_ids: [],
      closeout_type_input: null,

      options: {
        sortBy: [null],
        sortDesc: [null],
        itemsPerPage: 25
      },

      headers: [
        { text: 'ID', value: 'id' },
        { text: 'User', value: 'user.email', sortable: false },
        { text: 'Valid', value: 'valid', sortable: false },
        { text: 'Count', value: 'closeout_shipments_count' },
        { text: 'Type', value: 'type', sortable: false },
        { text: 'From Location', value: 'location.name', sortable: false },
        { text: 'Files', value: 'files', sortable: false },
        { text: 'Action', value: 'action.name', sortable: false },
        { text: 'Created at', value: 'created_at' },
        { text: 'Label', value: 'label' }
      ]
    };
  },
  watch: {
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    }
  },

  mounted() {
    this.getLocations();
    this.getCloseoutTypes();
    this.updateTable();
    Event.listen('update-rules', () => {
      this.updateTable();
    });
  },
  methods: {
    generateLabel(id) {
      this.$http({
        method: 'get',
        url: `/admin/closeouts/${id}/generate-label`
      })
        .then((response) => {
          this.downloadFileUrl(response.data, `Closeout_${id}.pdf`);
        })
        .catch(() => {
        })
        .finally(() => {
          this.generateLoading = false;
        });
    },
    download: function (data) {
      this.$http({
        method: 'post',
        url: '/admin/closeouts/download',
        data: {
          file: data
        }
      }).then((response) => {
        let filename = data.replace(/^.*[\\/]/, '');
        this.downloadFileUrl(response.data, filename, 'text/csv');
      });
    },
    rowClick(item) {
      this.$router.push({
        name: 'admin-closeouts-show',
        params: {
          id: item.id
        }
      });
    },
    updateTable() {
      this.selected = [];
      this.totalItems = 0;
      this.items = [];

      this.getDataFromApi().then((data) => {
        this.items = data.items;
        this.totalItems = data.total;
      });
    },
    getDataFromApi() {
      this.loading = true;
      return new Promise((resolve) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        const params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sortBy[0],
          desc: sortDesc[0],
          location_ids: this.location_ids,
          closeout_type_ids: this.closeout_type_ids,
          start_date: this.start_date,
          end_date: this.end_date
        };
        let items = [];
        let total = 0;
        let pages = 0;

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        this.$http
          .request(`/admin/closeouts`, {
            method: 'get',
            params: params
            // cancelToken: this.cancelSource.token
          })
          .then((response) => response.data)
          .then((response) => {
            items = response.data;
            total = response.total;
            pages = response.last_page;

            this.loading = false;
            resolve({
              items,
              total,
              pages
            });
          });
      }).finally(() => {
        this.loading = false;
      });
    },
    getLocations() {
      this.$http
        .request(`/admin/dropoff-locations`, {
          method: 'get'
        })
        .then((response) => response.data)
        .then((response) => {
          this.locations = response;
        });
    },
    getCloseoutTypes(){
      this.$http
        .request(`/admin/closeout-types`, {
          method: 'get'
        })
        .then((response) => response.data)
        .then((response) => {
          this.closeout_types = response;
        });
    },
    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.options.page = 1;
      this.timer = setTimeout(function () {
        self.updateTable();
      }, ms);
    }
  }
};
</script>
