<template>
  <StallionDialog
    v-model="show"
    :width="500"
    :disabled="disabled"
    class="dialog-slot"
    title="WooCommerce"
  >
    <slot v-on="on" />

    <template #content>
      <v-row dense>
        <v-col
          cols="12"
          class="text-center"
        >
          Please enter your store's domain. After, you'll be redirected to
          your site to verify your account.
        </v-col>
        <v-col>
          <v-text-field
            v-model="url"
            v-validate="'required|url'"
            :error-messages="errs.collect('url')"
            label="Store Domain"
            data-vv-name="url"
          />
        </v-col>
      </v-row>
    </template>

    <template #actions>
      <v-btn
        type="submit"
        color="primary"
        :loading="print_loading"
        :disabled="print_loading"
        @click="connect()"
      >
        Connect
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },

  data() {
    return {
      print_loading: false,
      dowload_loading: false,
      url: null
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    connect: function () {
      this.$validator.validate().then((result) => {
        if (result) {
          this.print_loading = true;
          this.$http
            .post(`/stores/woocommerce`, {
              url: this.url
            })
            .then((response) => response.data)
            .then((response) => {
              window.location = response.redirect;
            });
        }
      });
    }
  }
};
</script>
