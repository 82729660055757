<template>
  <v-row>
    <v-col md="4">
      <v-text-field
        v-model="userId"
        pt-0
        label="User ID"
        prepend-inner-icon="mdi-account"
        clearable
        class="pa-0"
        @input="onSearch"
      />
    </v-col>
    <v-col md="4">
      <v-text-field
        v-model="userEmail"
        pt-0
        label="User Email"
        prepend-inner-icon="mdi-email-open-outline"
        clearable
        class="pa-0"
        @input="onSearch"
      />
    </v-col>
    <v-col md="4">
      <v-text-field
        v-model="orderId"
        pt-0
        label="Order ID"
        prepend-inner-icon="mdi-clipboard-list-outline"
        clearable
        class="pa-0"
        @input="onSearch"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'SearchInput',
  data() {
    return {
      userId: '',
      userEmail: '',
      orderId: ''
    };
  },
  computed: {
    searchString() {
      let url = '';

      if (this.userId) {
        url = `${url}&filter[user_id]=${this.userId}`;
      }

      if (this.userEmail) {
        url = `${url}&filter[user_email]=${this.userEmail}`;
      }

      if (this.orderId) {
        url = `${url}&filter[order_id]=${this.orderId}`;
			}
			
			return url;
    }
  },
  methods: {
    onSearch() {
			this.$emit('search', this.searchString)
		}
  }
};
</script>