<template>
  <v-row
    v-if="$auth.user"
    dense
  >
    <v-col cols="12">
      <h4>Pickup Address</h4>
      <p>This address wil be used when scheduling new UPS or FBA pickups.</p>
    </v-col>
    <v-col
      cols="12"
      md="6"
    >
      <v-text-field
        v-model.trim="$auth.user.user_pickup_location.address1"
        v-validate="'required'"
        :dense="dense"
        :error-messages="errs.collect('address1')"
        data-vv-name="address1"
        outlined
        label="Address1"
      />
    </v-col>

    <v-col
      cols="12"
      md="6"
    >
      <v-text-field
        v-model.trim="$auth.user.user_pickup_location.address2"
        :dense="dense"
        outlined
        label="Address2"
      />
    </v-col>

    <v-col
      cols="12"
      md="6"
    >
      <v-text-field
        v-model.trim="$auth.user.user_pickup_location.city"
        v-validate="'required'"
        :dense="dense"
        :error-messages="errs.collect('city')"
        data-vv-name="city"
        outlined
        label="City"
      />
    </v-col>

    <v-col
      cols="12"
      md="6"
    >
      <v-autocomplete
        v-model="$auth.user.user_pickup_location.province_code"
        v-validate="'required'"
        :dense="dense"
        :error-messages="errs.collect('province_code')"
        data-vv-name="province_code"
        :items="provinces"
        label="Province"
        item-text="name"
        item-value="code"
        outlined
      />
    </v-col>

    <v-col
      cols="12"
      md="6"
    >
      <v-text-field
        v-model.trim="$auth.user.user_pickup_location.postal_code"
        v-validate="{
          required: true,
          regex: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/
        }"
        :dense="dense"
        :error-messages="errs.collect('postal_code')"
        data-vv-name="postal_code"
        outlined
        label="Postal Code"
      />
    </v-col>

    <v-col
      cols="12"
      md="6"
    >
      <v-autocomplete
        v-model="$auth.user.user_pickup_location.country_code"
        v-validate="'required'"
        :dense="dense"
        :error-messages="errs.collect('country_code')"
        data-vv-name="country_code"
        :items="countries"
        label="Country"
        item-text="name"
        item-value="code"
        outlined
      />
    </v-col>

    <v-col
      cols="12"
      md="6"
    >
      <v-text-field
        v-model.trim="$auth.user.user_pickup_location.phone"
        v-validate="'required'"
        :dense="dense"
        :error-messages="errs.collect('phone')"
        data-vv-name="phone"
        outlined
        label="Phone"
      />
    </v-col>

    <v-col
      cols="12"
      md="12"
    >
      <v-switch
        v-model="$auth.user.user_pickup_location.is_default"
        label="Set As Default"
      />
    </v-col>

    <v-col cols="12">
      <v-btn
        color="primary"
        :loading="processing"
        @click="submit()"
      >
        Update
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import constants from '@/constants';
export default {
  data() {
    return {
      dense: true,
      provinces: constants.PROVINCES,
      countries: [
        {
          name: 'Canada',
          code: 'CA'
        }
      ],
      processing: false,
      userPickupLocation: {
        address1: null,
        address2: null,
        city: null,
        phone: null,
        province_code: null,
        postal_code: null,
        country_code: null,
        is_default: 0
      }
    };
  },
  created() {
    // initialize default structure if user has no default pickup location
    // prevents undefined error
    if (!this.$auth.user.user_pickup_location) {
      this.$auth.user.user_pickup_location = { ...this.userPickupLocation };
    }
  },
  methods: {
    submit() {
      this.$validator.validate().then(result => {
        if (result) {
          this.processing = true;

          this.$http
            .post(
              `/users/pickup-location`,
              this.$auth.user.user_pickup_location
            )
            .then(() => {
              // mixins
              this.successMessage('Your default pickup address has been saved');
            })
            .catch(() => {
              // mixin
              this.errorMessage('An error occurred');
            })
            .finally(() => {
              this.processing = false;
            });
        } else {
          // mixin
          this.errorMessage('Some of the form fields are invalid.');
        }
      });
    }
  }
};
</script>
