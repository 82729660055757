<template>
  <StallionDialog
    v-model="dialog"
    persistent
    :width="800"
    title="Edit Restriction"
  >
    <template #content>
      <v-form @submit.prevent>
        <v-container>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="postage.description"
                v-validate="'required'"
                outlined
                dense
                :readonly="true"
                label="Postage Type"
                required
              />
            </v-col>
          </v-row>
        </v-container>

        <v-container fluid>
          <v-row dense>
            <v-col cols="12">
              <h3>Dim Restrictions</h3>
            </v-col>
            <v-col
              cols="3"
              md="3"
            >
              <v-text-field
                v-model="postage.max_girth"
                v-validate="''"
                outlined
                dense
                type="number"
                :error-messages="errs.collect('max_girth')"
                label="Girth"
                data-vv-name="max_girth"
              />
            </v-col>

            <v-col
              cols="3"
              md="3"
            >
              <v-text-field
                v-model="postage.max_longest_side"
                v-validate="''"
                outlined
                dense
                type="number"
                :error-messages="errs.collect('max_longest_side')"
                label="Longest Side"
                data-vv-name="max_longest_side"
              />
            </v-col>

            <v-col
              cols="3"
              md="3"
            >
              <v-text-field
                v-model="postage.max_girth_plus_sides"
                v-validate="''"
                outlined
                dense
                type="number"
                :error-messages="errs.collect('max_girth_plus_sides')"
                label="Girth Plus Length"
                data-vv-name="max_girth_plus_sides"
              />
            </v-col>

            <v-col
              cols="3"
              md="3"
            >
              <v-text-field
                v-model="postage.max_combined_sides"
                v-validate="''"
                outlined
                dense
                type="number"
                :error-messages="errs.collect('max_combined_sides')"
                label="Combined Sides"
                data-vv-name="max_combined_sides"
              />
            </v-col>
            <v-col cols="12">
              <h3>Individual Dim Restrictions</h3>
            </v-col>

            <v-col
              cols="3"
              md="3"
            >
              <v-text-field
                v-model="postage.max_length"
                v-validate="''"
                outlined
                dense
                type="number"
                :error-messages="errs.collect('max_length')"
                label="Length"
                data-vv-name="max_length"
              />
            </v-col>

            <v-col
              cols="3"
              md="3"
            >
              <v-text-field
                v-model="postage.max_width"
                v-validate="''"
                outlined
                dense
                type="number"
                :error-messages="errs.collect('max_width')"
                label="Width"
                data-vv-name="max_width"
              />
            </v-col>

            <v-col
              cols="3"
              md="3"
            >
              <v-text-field
                v-model="postage.max_height"
                v-validate="''"
                outlined
                dense
                type="number"
                :error-messages="errs.collect('max_height')"
                label="Height"
                data-vv-name="max_height"
              />
            </v-col>

            <v-col
              cols="3"
              md="3"
            >
              <!-- $sizeUnits from mixin.js  -->
              <v-select
                v-model="postage.size_unit"
                v-validate="''"
                outlined
                dense
                :error-messages="errs.collect('size_unit')"
                label="Size Unit"
                data-vv-name="size_unit"
                :items="$sizeUnits()"
                :clearable="true"
              />
            </v-col>

            <v-col cols="12">
              <h3>Weight Restriction</h3>
            </v-col>

            <v-col
              cols="3"
              md="3"
            >
              <v-text-field
                v-model="postage.max_weight"
                v-validate="''"
                outlined
                dense
                type="number"
                :error-messages="errs.collect('max_weight')"
                label="Weight"
                data-vv-name="max_weight"
              />
            </v-col>

            <v-col
              cols="3"
              md="3"
            >
              <!-- $weightUnits from mixin.js  -->
              <v-select
                v-model="postage.weight_unit"
                v-validate="''"
                outlined
                dense
                :error-messages="errs.collect('weight_unit')"
                label="Weight Unit"
                data-vv-name="weight_unit"
                :items="$weightUnits()"
                :clearable="true"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>

    <template #actions>
      <v-spacer />
      <v-btn
        color="primary"
        :loading="submitting"
        @click="update()"
      >
        Update
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  data() {
    return {
      postage: {},
      dialog: false,
      submitting: false
    };
  },
  methods: {
    initDialog() {
      this.dialog = true;
      this.submitting = false;
      this.$validator.reset();
    },
    closeDialog() {
      this.dialog = false;
    },
    startEdit(postage) {
      this.initDialog();
      this.postage = { ...postage };
    },
    async validatePostage() {
      return this.$validator.validate().then((result) => {
        return result ? true : false;
      });
    },
    async update() {
      if (!(await this.validatePostage())) return;

      this.submitting = true;
      this.$http
        .put(`/admin/postage-restrictions/${this.postage.id}`, this.postage)
        .then(() => {
          this.$emit('update-postage');
          this.closeDialog();
          this.successMessage('Postage Restriction successfully updated!');
        })
        .catch((err) => {
          this.onError(err);
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    onError(err) {
      if (
        err.response.status === 422 &&
        typeof err.response.data.errors !== 'undefined'
      ) {
        const errors = err.response.data.errors;
        let message = '';

        for (var field in errors) {
          message += `${errors[field][0]}\n`;
        }

        this.errorMessage(message);
      }
    }
  }
};
</script>
