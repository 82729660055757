<template>
  <div>
    <v-form compact-form>
      <v-row class="pt-6">
        <v-col
          cols="12"
          md="4"
          class="pt-0"
        >
          <!-- COUNTRY -->
          <v-autocomplete
            v-model="address.country_code"
            v-validate="'required'"
            :error-messages="errs.collect('country_code')"
            data-vv-name="country_code"
            :items="countries"
            label="I'm Shipping to"
            item-text="name"
            item-value="code"
            :search-input.sync="search_country"
            dense
            outlined
            style="font-size: 14px"
          />
        </v-col>
        <v-col
          v-if="address.country_code == 'US'"
          cols="12"
          md="4"
          class="pt-0"
        >
          <!-- POSTAL CODE -->
          <v-text-field
            v-model="address.postal_code"
            v-validate="{
              required: true,
              regex: /(^\d{5}$)|(^\d{5}-\d{4}$)/
            }"
            validate-on-blur
            :error-messages="errs.collect('postal_code')"
            label="Zip Code"
            data-vv-name="postal_code"
            dense
            outlined
            style="font-size: 14px"
          />
        </v-col>
        <v-col
          v-else-if="address.country_code == 'CA'"
          cols="12"
          md="4"
          class="pt-0"
        >
          <!-- POSTAL CODE -->
          <v-text-field
            v-model="address.postal_code"
            v-validate="{
              required: true,
              regex: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/
            }"
            validate-on-blur
            :error-messages="errs.collect('postal_code')"
            label="Postal Code"
            data-vv-name="postal_code"
            dense
            outlined
          />
        </v-col>
        <v-col
          v-if="address.country_code == 'US'"
          cols="12"
          md="4"
          class="pt-0"
        >
          <!-- PACKAGE TYPE -->
          <v-autocomplete
            v-model="address.package_type"
            v-validate="'required'"
            :error-messages="errs.collect('package_type')"
            data-vv-name="package_type"
            :items="$store.getters['main/package_types']"
            item-value="description"
            item-text="description"
            label="Package Type"
            :search-input.sync="search_package"
            dense
            outlined
            style="font-size: 14px"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="pt-0"
        >
          <!-- WEIGHT -->
          <v-text-field
            v-model="address.weight"
            v-validate="'required|min_value:0.001'"
            type="number"
            :error-messages="errs.collect('weight')"
            label="Weight"
            placeholder="0"
            data-vv-name="weight"
            dense
            outlined
            style="font-size: 14px"
          />
        </v-col>
        <!-- WEIGHT UNIT -->
        <v-col
          cols="12"
          md="4"
          class="pt-0"
        >
          <v-select
            v-model="address.weight_unit"
            :items="['lbs', 'kg', 'g', 'oz']"
            label="Weight Unit"
            dense
            outlined
            style="font-size: 14px"
          />
        </v-col>
      </v-row>
      <v-row v-if="['Parcel', 'Thick Envelope'].includes(address.package_type) || address.country_code != 'US'">
        <v-col
          cols="12"
          md="12"
          class="pt-0"
        >
          <p>Enter parcel dimensions</p>
        </v-col>

        <!-- DIMENSTIONS -->
        <v-col
          cols="12"
          md="3"
          class="pt-0"
        >
          <!-- LENGTH -->
          <v-text-field
            ref="length"
            v-model="address.length"
            v-validate="'required'"
            type="number"
            :error-messages="errs.collect('length')"
            label="Length"
            data-vv-name="length"
            dense
            outlined
          />
        </v-col>
        <!-- WIDTH -->
        <v-col
          cols="12"
          md="3"
          class="pt-0"
        >
          <v-text-field
            v-model="address.width"
            v-validate="'required'"
            type="number"
            :error-messages="errs.collect('width')"
            label="Width"
            data-vv-name="width"
            required
            dusk="sf_width"
            dense
            outlined
          />
        </v-col>
        <!-- HEIGHT -->
        <v-col
          cols="12"
          md="3"
          class="pt-0"
        >
          <v-text-field
            v-model="address.height"
            v-validate="'required'"
            type="number"
            :error-messages="errs.collect('height')"
            label="Height"
            data-vv-name="height"
            dense
            outlined
          />
        </v-col>

        <!-- SIZE UNIT -->
        <v-col
          cols="12"
          md="3"
          class="pt-0"
        >
          <v-autocomplete
            v-model="address.size_unit"
            :items="['in', 'cm']"
            label="Size Unit"
            :search-input.sync="search_size"
            dense
            outlined
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="auto"
          class="pt-0"
        >
          <v-btn
            color="primary"
            type="submit"
            outlined
            small
            :loading="loading"
            :disabled="loading"
            class="py-4 px-6"
            @click.prevent="getRates()"
          >
            Get Rates
          </v-btn>
        </v-col>
      </v-row>
      <!--  -->

      <!-- Rate Results -->
      <v-divider
        v-if="rates.length > 0"
        class="mt-3"
      />
      <v-col
        v-if="rates.length > 0"
        cols="12"
        md="12"
        class="px-0"
      >
        <p class="font-weight-bold">
          Rate Calculator Results:
        </p>
      </v-col>
      <v-row
        v-for="rate in rates"
        :key="rate.id"
        justify="space-between"
        dense
      >
        <v-col cols="auto">
          <div class="row-element">
            <h4><span v-html="$options.filters.postage(rate)" /></h4>
            <p>Delivered in {{ rate.delivery_days }} Business Days</p>
            <!-- Rate: ${{ (rate.handling_fee + rate.rate).toFixed(2) }}<span v-if="rate.tax > 0"> + ${{ (rate.tax).toFixed(2) }} Tax</span> -->
          </div>
        </v-col>
        <v-col cols="auto">
          <!-- <h4>${{ (rate.handling_fee + rate.rate).toFixed(2) }} CAD</h4> -->
         
          <h4>${{ (rate.total).toFixed(2) }} CAD</h4>
        </v-col>
        <v-col cols="12">
          <v-divider />
        </v-col>
      </v-row>
      <v-divider v-if="ratesCheck" />
      <v-col
        v-if="ratesCheck"
        cols="12"
        md="12"
        class="px-0"
      >
        <p class="font-weight-bold">
          No rates found.
        </p>
      </v-col>
    </v-form>
  </div>
</template>

<script>
import CONSTANTS from '@/constants';

export default {
  data() {
    return {
      countries: CONSTANTS.COUNTRIES,
      show: true,
      rates: [],
      ratesCheck: false,
      submitting: false,
      error: null,
      address: {
        address1: null,
        city: null,
        postal_code: null,
        province_code: null,
        country_code: 'US',
        weight: null,
        weight_unit: 'lbs',
        length: null,
        width: null,
        height: null,
        size_unit: 'in',
        package_type: 'Parcel'
      },
      package_types: [],
      search_country: null,
      search_package: null,
      search_size: null,
      loading: false
    };
  },
  watch: {
    'address.country_code': {
      immediate: false,
      handler() {
        this.reset();
      }
    }
  },
  mounted() {},
  methods: {
    reset() {
      this.address.postal_code = null;

      this.address.weight = null;
      this.address.weight_unit = 'lbs';
      this.address.length = null;
      this.address.width = null;
      this.address.height = null;
      this.address.size_unit = 'in';
      this.address.package_type = 'Parcel'

      this.error = null;
      this.rates = [];
      this.$validator.reset();
    },
    getRates() {
      this.$validator.validate().then((result) => {
        if (result) {
          this.error = null;
          this.rates = [];

          this.loading = true;

          this.$http({
            method: 'post',
            url: '/shipments/rates-widget',
            data: this.address
          })
            .then((response) => {
              this.rates = response.data.rates;
              if (this.rates == 0) {
                this.ratesCheck = true;
              } else {
                this.ratesCheck = false;
              }
            })
            .catch((err) => {
              this.error = err.response.data.errors;
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>

<style scoped>
.v-application p {
  margin-bottom: 0px;
}

h4 {
  margin-bottom: 0px !important;
}
</style>
