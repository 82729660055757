import Vue from 'vue';
import * as Sentry from "@sentry/vue";
import router from '../router';
// import store from '../store';
Sentry.init({
    Vue,
    dsn: import.meta.env.PROD ? 'https://e752dc67c84191a9ba5fc0b5d9b762c7@o4505326130626560.ingest.sentry.io/4505326147076096' : null,
    // beforeSend(event) {
    //   // Check if it is an exception, and if so, show the report dialog
    //   if (event.exception) {
    //     Sentry.showReportDialog({ eventId: event.event_id, email: store.state.auth.user.email, name: store.state.auth.user.name, labelSubmit: 'Send Report'});
    //   }
    //   return event;
    // },
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/.*\.stallion\.ca\/api/],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.001,
  
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.00,
    replaysOnErrorSampleRate: 0.001,
  });