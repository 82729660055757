<template>
  <div class="weglot-ignore">
    <v-select
      v-model="selected"
      style="max-width: 80px"
      class="mt-0 pa-0"
      outlined
      dense
      :items="options"
      @change="update"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [],
      selected: null
    };
  },
  mounted() {
    this.start();
  },
  methods: {
    start() {
      const Weglot = window.Weglot;
      if (!Weglot || !Weglot.options || !Weglot.options.languages) {
  return;
}

      //Create array of options to be added
      var availableLanguages = Weglot.options.languages
        .map(function (language) {
          return language.language_to;
        })
        .concat(Weglot.options.language_from);

      //Create and append select list
      var currentLang = Weglot.getCurrentLang();

      //Create and append the options
      for (var i = 0; i < availableLanguages.length; i++) {
        var lang = availableLanguages[i];

        this.options.push({
        //   text: Weglot.getLanguageName(lang),
        text: lang.toUpperCase(),
          value: lang
        });

        if (lang === currentLang) {
          this.selected = lang;
        }
      }

      //   Weglot.on('languageChanged', function (lang) {
      //     this.selected = lang;
      //   });
    },
    update() {
      const Weglot = window.Weglot;
      Weglot.switchTo(this.selected);
    }
  }
};
</script>
