<template>
  <v-card flat>
    <v-container class="px-0 py-0">
      <v-row>
        <v-col md="6">
          <p class="mb-2">
            Add/Edit Tax Identifier Numbers
          </p>
        </v-col>
        <v-col md="6">
          <v-row class="float-right">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                  class="mx-2"
                  color="primary"
                  depressed
                  outlined
                  :disabled="loading"
                  v-on="on"
                  @click="$refs.taxIdDialog.startNew()"
                >
                  <v-icon small>
                    mdi-plus
                  </v-icon>Add Tax Number
                </v-btn>
              </template>
              <span>Add Tax Number</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col md="12">
          <v-card outlined>
            <v-data-table
              :headers="headers"
              :loading="loading"
              item-key="id"
              :items="$store.getters['main/tax_identifiers']"
              class="table-striped elevation-0"
            >
              <template #item.actions="{ item }">
                <v-btn
                  :disabled="loading"
                  small
                  color="red"
                  text
                  @click="deleteTaxIdentifier(item)"
                >
                  Delete
                </v-btn>
                <v-btn
                  :disabled="loading"
                  small
                  color="primary"
                  text
                  @click="$refs.taxIdDialog.startEdit(item)"
                >
                  Edit
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <tax-identifier-dialog
      ref="taxIdDialog"
      @save="$store.dispatch('main/getTaxIdentifiers');"
    />
  </v-card>
</template>

<script>
import TaxIdentifierDialog from './TaxIdentifierDialog.vue';
export default {
  components: {
    TaxIdentifierDialog
  },
  data(){
    return {
      loading: false,
      headers: [
        {
          text: 'Nickname',
          align: 'left',
          value: 'nickname'
        },
        {
          text: 'Type',
          align: 'left',
          value: 'tax_type'
        },
        {
          text: 'Number',
          align: 'left',
          value: 'number'
        },
         {
          text: 'Issuing Authority',
          align: 'left',
          value: 'issuing_authority'
        },
        {
          text: '',
          align: 'right',
          value: 'actions'
        }
      ]
    }
  },
  mounted(){
    this.$store.dispatch('main/getTaxIdentifiers');
  },
  methods: {
    async deleteTaxIdentifier(taxIdentifier) {
      const isConfirm = await swal({
        title: 'Confirm Delete',
        text: 'Do you really want to delete this tax identifier?\n This will remove it from any shipments where it has been applied.',
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      });

      if (isConfirm) {
        this.loading = true;
        try {
          await this.$http.delete(`tax-identifiers/${taxIdentifier.id}`);
          swal({
            title: 'Success',
            text: 'Tax Identifier successfully deleted',
            icon: 'success'
          });
          this.$store.dispatch('main/getTaxIdentifiers');
        } catch (err) {
          swal({
            title: 'Error!',
            text: 'An error occurred',
            icon: 'error'
          });
        }

        this.loading = false;
      }
    }
  }
}
</script>