<template>
  <FullPageLayout
    title="Supplies - Orders"
    :bread-crumbs="breadCrumbs"
  >
    <template #subtitle>
      View all your supplies orders. Find answers to common questions at our
      <a
        style="font-weight: 700; text-decoration: none"
        href="https://help.stallionexpress.ca/"
        target="_blank"
      >Help Centre!</a>
    </template>
    <template #default>
      <v-row>
        <v-col
   
          cols="12"
        >
          <SuppliesOrderTable
            :selected.sync="selected"
            :status_id="status_id"
            :filter="$route.query.filter"
          />
        </v-col>
      </v-row>
    </template>

    <template #footer>
      <div class="d-flex">
        <supplies-print
          :disabled="!printable"
          :invoices="selected"
        />
        &nbsp;
        <v-menu offset-y>
          <template #activator="{ on }">
            <v-btn
              color="primary"
              :disabled="!duplicable"
              v-on="on"
              @click="$refs.suppliesDuplicate.openDialog()"
            >
              <v-icon left>
                mdi-content-copy
              </v-icon>Duplicate
            </v-btn>

            &nbsp;
            <v-btn
              color="primary"
              :disabled="!voidable"
              v-on="on"
              @click="$refs.suppliesVoid.openDialog()"
            >
              <v-icon left>
                mdi-package-variant-closed
              </v-icon>Void
            </v-btn>
          </template>
        </v-menu>
        <VoidSuppliesOrderDialog
          ref="suppliesVoid"
          :disabled="!voidable"
          :invoices="selected"
        />
        <DuplicateSuppliesOrderDialog
          ref="suppliesDuplicate"
          :disabled="!duplicable"
          :invoices="selected"
        />
      </div>
    </template>
  </FullPageLayout>
</template>

<script>
import SUPPLIES_CONSTANTS from '@/constants/supplies.js';
import SuppliesOrderTable from './SuppliesOrderTable.vue';
import DuplicateSuppliesOrderDialog from './DuplicateSuppliesOrderDialog.vue';
import VoidSuppliesOrderDialog from '@/components/shared/VoidSuppliesOrderDialog.vue';

export default {
  metaInfo: {
    title: "Supplies Orders | Stallion Express"
  },
  components:{
    SuppliesOrderTable,
    DuplicateSuppliesOrderDialog,
    VoidSuppliesOrderDialog
  },
  data() {
    return {
      status_id: null,
      selected: [],
      breadCrumbs: [
        {
          text: 'Home',
          exact: true,
          to: '/dashboard'
        },
        {
          text: 'Supplies',
          exact: true,
          to: '/supplies/store'
        },
        {
          text: 'Orders',
          exact: true,
          to: '/supplies/orders'
        },
        {
          text: this.filter_formated,
          exact: true,
          to: '/supplies/orders?filter=' + this.$route.query.filter
        }
      ]
    };
  },
  computed: {
    filter_formated() {
      return this.$options.filters.capitalize(this.$route.query.filter);
    },

    printable() {
      if (this.selected.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    voidable() {
      if (
        this.selected.length > 0 &&
        this.selected.every((r) =>
          [
            SUPPLIES_CONSTANTS.SUPPLIES_STATUS.UNFULFILLED,
            SUPPLIES_CONSTANTS.SUPPLIES_STATUS.READY
          ].includes(r.status_id)
        )
      ) {
        return true;
      }

      return false;
    },
    duplicable() {
      if (
        this.selected.length > 0 &&
        this.selected.every(
          (r) =>
            ![SUPPLIES_CONSTANTS.SUPPLIES_STATUS.VOIDED].includes(r.status_id)
        )
      ) {
        return true;
      }

      return false;
    }
  },
  watch: {
    '$route.query.status': {
      immediate: true,
      handler(newVal) {
        this.status_id = newVal;
      }
    }
  },
  mounted() {
    if (this.$route.query.status_id) {
      this.status_id = true;
    }
  }
};
</script>
