<template>
  <FullPageLayout title="Returns Trips">
    <template #default>
      <admin-return-trips-table
        v-model="selected"
        :filter="filter"
      />
    </template>
  </FullPageLayout>
</template>

<script>
export default {
  data() {
    return {
      status: '',
      selected: [],
      show: false
    };
  },
  computed: {
    filter() {
      if (this.$route.query.filter) return this.$route.query.filter;
      else return null;
    }
  },
  mounted() {
    console.log(this.$route);
  },
  methods: {}
};
</script>
