<template>
  <v-container pa-0>
    <div v-if="loading">
      <div class="loading-overlay">
        <div class="loading-content">
          <atom-spinner
            style="margin: auto"
            :animation-duration="1000"
            :size="100"
            :color="'#f9ac40'"
          />
          <div style="text-align: center; color: #9d9da6">
            Loading
          </div>
        </div>
      </div>
    </div>

    <div
      v-else-if="shipment !== null"
      dense
    >
      <!-- <v-card :loading="loading"> -->

      <div>
        <div class="d-flex flex-row justify-space-between">
          <h3>Shipment Details</h3>

          <router-link
            class="float-right ml-auto"
            router
            :to="{
              name: 'shipment-history',
              params: { id: shipment.id, shipcode: shipment.ship_code }
            }"
            target="_blank"
          >
            View History
          </router-link>
        </div>

        <div class="d-flex flex-row justify-space-between">
          <div class="shipment-title">
            Shipment ID
          </div>

          <v-tooltip top>
            <template #activator="{ on }">
              <a
                v-clipboard:copy="shipment.ship_code"
                v-clipboard:success="oncopy"
                style="font-weight: bold"
                href="javascript:void(0);"
                v-on="on"
              >{{ shipment.ship_code }}</a>
            </template>
            <span>Click to copy</span>
          </v-tooltip>
        </div>

        <div class="d-flex flex-row justify-space-between">
          <div class="shipment-title">
            Shipment Status
          </div>
          <div v-html="$options.filters.status(shipment.status_id)" />
        </div>

        <div
          v-if="shipment.region"
          class="d-flex flex-row justify-space-between"
        >
          <div class="shipment-title">
            Origin
          </div>
          <div>{{ shipment.region.name }}</div>
        </div>

        <div class="mt-3">
          <div
            v-if="shipment.seik"
            class="d-flex flex-row justify-space-between"
          >
            <div>SEIK</div>
            <div>{{ shipment.seik }}</div>
          </div>

          <div
            v-if="can('associate_name.view') && shipment.scanned_by_user"
            class="d-flex flex-row justify-space-between"
          >
            <div>Scanned By</div>
            <div>{{ shipment.scanned_by_user.name }}</div>
          </div>

          <div
            v-if="shipment.truck"
            class="d-flex flex-row justify-space-between"
          >
            <div>Truck</div>
            <div>{{ shipment.truck }}</div>
          </div>
        </div>

        <div
          v-if="shipment.transactions && shipment.transactions.length > 0"
          class="text-center mt-4 mb-4"
        >
          <router-link
            target="_blank"
            router
            :to="{
              name: 'transactions',
              params: {
                transactional_id: item.ship_code,
                transactional_type_id: 1
              }
            }"
          >
            View Transactions
          </router-link>
        </div>

        <v-divider class="details-divider" />
      </div>

      <div>
        <error-details :shipment="shipment" />
      </div>

      <div>
        <tracking-details :shipment="shipment" />
      </div>

      <div
        v-if="can('analytics.view') && shipment.rate_details"
        cols="12"
      >
        <rate-details :rate-details="shipment.rate_details" />
      </div>

      <div>
        <postage-details :shipment="shipment" />
      </div>

      <div>
        <package-details :shipment="shipment" />
      </div>

      <div>
        <customs-details :shipment="shipment" />
      </div>

      <div>
        <store-details :shipment="shipment" />
      </div>

      <div>
        <recipient-details :shipment="shipment" />
      </div>

      <div>
        <return-address-details :shipment="shipment" />
      </div>

      <div>
        <div
          class="d-flex flex-row justify-space-between"
        >
          <div class="">
            <v-btn
              small
              color="primary"
              depressed
              @click="getInvoice('download')"
            >
              <v-icon left>
                mdi-download
              </v-icon>
              Invoice
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      
      pa-3
    >
      <div>
        <div
          class="text-center"
          style="padding: 100px 0 100px 0"
        >
          <h2>{{ shipments.length }} shipments selected</h2>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { AtomSpinner } from 'epic-spinners';

export default {
  components: {
    AtomSpinner
  },
  props: {
    shipments: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      shipment: null,
      loading: false
    };
  },
  watch: {
    shipments: {
      immediate: true,
      handler(value) {
        if (value.length === 1) {
          this.shipment = this.shipments[0];
          this.getShipment();
        } else {
          this.shipment = null;
        }
      }
    }
  },
  methods: {
    getInvoice(action = 'print') {
      if (this.shipments.length == 0 || !this.shipments[0]) {
        return;
      }

      this.loading = true;
      this.$http
        .get(`/admin/shipments/${this.shipments[0].id}/get-invoice`)
        .then((response) => response.data)
        .then((response) => {
          if (action === 'print') {
            // mixin.js
            this.printUrl(response.url);
          } else {
            // mixin.js
            this.downloadFileUrl(response.url, response.filename);
          }
        })
        .catch((err) => {
          // mixin.js
          this.errorMessage(err.response?.data?.error || 'Something went wrong');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getShipment() {
      if (this.shipments.length == 0 || !this.shipments[0]) {
        return;
      }

      this.cancelSearch();
      this.cancelSource = axios.CancelToken.source();
      this.shipment = null;
      this.loading = true;
      this.$http
        .get(`/admin/shipments/${this.shipments[0].id}`, {
          cancelToken: this.cancelSource.token
        })
        .then((response) => response.data)
        .then((response) => {
          console.log('response');
          this.shipment = response;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancelSearch() {
      if (this.cancelSource) {
        this.cancelSource.cancel('Start new search, stop active search');
        console.log('cancel request done');
      }
    },
    oncopy() {
      this.$notify({
        group: 'main',
        title: 'Copied!',
        text: 'Shipment ID has been copied to the clipbaord',
        duration: 2000,
        type: 'info'
      });
    }
  }
};
</script>
