<template>
  <StallionDialog
    v-model="show"
    :width="500"
    :disabled="disabled"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    title="Create Return"
  >
    <template #content>
      <v-layout
        wrap
        justify-space-around
      >
        <v-flex xs6>
          <v-flex
            xs12
          >
            <div class="dialog-info">
              Create new return from image {{ returnImage.image_name }}
            </div>
          </v-flex>
          <v-flex
            xs12
            text-center
            mt-4
            mb-12
          >
            <v-autocomplete
              v-model="user_id"
              v-validate="'required'"
              :error-messages="errs.collect('user_id')"
              data-vv-name="user_id"
              :items="userIDs"
              :search-input.sync="searchID"
              hide-selected
              label="User ID"
              clearable
              @change="updateUniqueID()"
            />
          </v-flex>
          <!-- <v-col cols="12" md="8">
              <v-text-field
                v-validate="'required'"
                v-model="unique_id"
                :error-messages="errs.collect('unique_id')"
                label="Unique ID"
                data-vv-name="unique_id"
              ></v-text-field>
            </v-col> -->

          <v-flex xs12>
            <v-text-field
              v-model="weight"
              v-validate="'required'"
              :error-messages="errs.collect('weight')"
              label="Weight (lbs)"
              data-vv-name="weight"
            />
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="barcode"
              :error-messages="errs.collect('barcode')"
              label="Barcode"
              data-vv-name="barcode"
            />
          </v-flex>
          <v-flex
            xs12
            text-center
            mt-4
            mb-12
          >
            <v-btn
              color="primary"
              :loading="loading"
              :disabled="loading"
              @click="submit()"
            >
              Confirm
            </v-btn>
          </v-flex>
        </v-flex>

        <v-flex xs4>
          <v-img
            :src="returnImage.full_image_url"
            class="elevation-3"
          >
            <v-layout
              slot="placeholder"
              fill-height
              align-center
              justify-center
              ma-0
            >
              <v-progress-circular
                indeterminate
                color="primary"
              />
            </v-layout>
          </v-img>
        </v-flex>
      </v-layout>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        :loading="loading"
        :disabled="loading"
        @click="submit()"
      >
        Confirm
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    returnImage: {
      type: Object,
      default: null
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },

  data() {
    return {
      show: false,
      loading: false,
      new_return: {},
      unique_id: null,
      user_id: null,
      userIDs: [],
      weight: null,
      barcode: null,
      searchID: null
    };
  },
  watch: {
    shipment: function(val) {
      if (val) {
        this.unique_id = `${this.shipment.user_id}_`;
      } else {
        this.unique_id = null;
      }
    },
    show: function(val) {
      if (val) {
        this.barcode = this.returnImage.image_name;
      }
    }
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    openDialog() {
      this.show = true;
    },
    updateUniqueID() {
      this.unique_id = `${this.user_id}_`;
    },
    getUsers() {
      this.$http
        .get('/admin/users/all')
        .then(response => response.data)
        .then(response => {
          this.userIDs = response.map(e => e.id);
        })
        .catch(() => {});
    },
    submit: function() {
      this.$validator.validate().then(result => {
        if (result) {
          this.loading = true;

          this.$http
            .post('admin/return-images/new', {
              image_id: this.returnImage.id,
              user_id: this.user_id,
              unique_id: this.unique_id,
              weight: this.weight,
              barcode: this.barcode
            })
            .then(response => response.data)
            .then(response => {
              this.$router.push({
                name: 'admin-return-show',
                params: {
                  id: response.shipment_return.id
                }
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>
