<template>
  <SingleCardLayout>
    <v-layout wrap>
      <v-form
        v-if="user?.email"
        @submit.prevent="resetPassword()"
      >
        <v-container>
          <v-layout wrap>
            <v-flex xs12>
              <h2 class="box-title m-b-20">
                Password Reset
              </h2>
              <p>
                Enter your email and we'll send you a code to reset
                your password.
              </p>
            </v-flex>
            <v-flex xs12>
              <v-alert
                color="error"
                :value="error != ''"
                icon="warning"
              >
                {{ error }}
              </v-alert>
            </v-flex>

            <v-flex xs12>
              <h4>
                <strong>Username:</strong>
                {{ user.username }}
              </h4>
            </v-flex>

            <v-flex xs12>
              <v-text-field
                ref="password"
                v-model="password"
                v-validate="'required|min:8|verify_password'"
                autofocus
                label="Password"
                data-vv-name="password"
                :error-messages="errs.collect('password')"
                type="password"
                name="password"
                data-vv-validate-on="blur"
              />
            </v-flex>
            <v-flex xs12>
              <v-text-field
                ref="confirm"
                v-model="password_confirmation"
                v-validate="'required|confirmed:password'"
                label="Confirm Password"
                data-vv-name="confirm"
                :error-messages="errs.collect('confirm')"
                type="password"
                name="confirm"
              />
            </v-flex>
            <v-flex xs12>
              <v-layout
                align-center
                class="mt-4 mb-4"
              >
                <v-flex
                  xs12
                  text-center
                >
                  <v-btn
                    :loading="loading2"
                    :disabled="loading2"
                    depressed
                    color="primary"
                    type="submit"
                  >
                    <v-icon
                      dark
                      small
                    >
                      mdi-check
                    </v-icon>Submit
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-layout
              align-center
              class="mt-4 mb-4"
            >
              <v-flex
                xs12
                text-center
              >
                <router-link
                  to="/login"
                  aria-expanded="false"
                >
                  Return to Login
                </router-link>
              </v-flex>
            </v-layout>
          </v-layout>
        </v-container>
      </v-form>
      <v-flex
        v-else
        xs12
        pa-12
      >
        <h4>Invalid token</h4>
      </v-flex>
    </v-layout>
  </SingleCardLayout>
</template>

<script>
export default {
  data() {
    return {
      errors: [],
      password: '',
      password_confirmation: '',
      email: '',
      error: '',
      user: '',
      loading2: false
    };
  },
  mounted() {
    console.log('token: ' + this.$route.query.token);
    this.getCsrf().then(() => {
      this.$http
        .post('/auth/validate-token', {
          token: this.$route.query.token
        })
        .then((response) => {
          console.log(response);
          this.user = response.data.user;
        });
    });
  },
  methods: {
    resetPassword() {
      this.$validator.validate().then((result) => {
        if (result) {
          this.loading2 = true;
          this.getCsrf().then(() => {
            this.$http
              .post('/auth/change-password', {
                token: this.$route.query.token,
                password: this.password,
                password_confirmation: this.password_confirmation
              })
              .then((response) => {
                if (response.data.valid) {
                  swal('Success!', 'Your password has been changed');
                  this.login(this.user.username, this.password);
                } else {
                  swal('Error!', 'An error occurred');
                }
              })
              .then(() => {
                this.loading2 = false;
              });
          });
        }
      });
    }
  }
};
</script>
