<template>
  <StallionDialog
    v-model="show"
    :width="500"
    persistent
    title="Update Trip Status"
  >
    <template #content>
      <v-layout column>
        <v-flex
          xs12
        >
          <div class="dialog-info">
            Change the status of {{ trip.length }} return trip
          </div>
        </v-flex>
        <v-flex
          xs12
          text-center
          mt-4
          mb-12
        >
          <v-select
            v-model="status"
            :items="items"
          />
        </v-flex>
      </v-layout>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        :loading="update_loading"
        :disabled="update_loading"
        @click="updateStatuses()"
      >
        Update
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    trip: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      update_loading: false,
      dowload_loading: false,
      statuses: [],
      status: null,
      items: [
        {
          value: true,
          text: 'Complete'
        },
        {
          value: false,
          text: 'Pending'
        }
      ]
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  mounted() {},
  methods: {
    updateStatuses: function() {
      this.update_loading = true;

      this.$http
        .post(`/admin/return-trips/${this.trip.id}`, {
          // ids: ids,
          completed: this.status
        })
        .then(() => {
          Event.fire('update-trip');
        })
        .finally(() => {
          this.update_loading = false;
          this.show = false;
        });
    }
  }
};
</script>
