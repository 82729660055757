<template>
  <FullPageLayout title="Received Scan">
    <template #default>
      <v-flex xs12>
        <v-form>
          <v-container>
            <v-row
              class="pb-2"
              style="justify-content: space-between;"
            >
              <v-text-field
                v-model="barcode"
                label="Manual Entry"
                class="mr-2"
                outlined
                clearable
                dense
                hide-details
                @keydown.enter.prevent="manualScan"
              />

              <v-btn
                color="primary"
                @click="manualScan"
              >
                Submit
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-flex>
      <v-flex
        id="scan-page"
        xs12
      >
        <v-flex
          v-for="(item, index) in errors"
          :key="'1' + index"
          xs12
        >
          <v-alert
            class="pa-2"
            :value="true"
            dismissible
            type="error"
            transition="fade-transition"
          >
            {{ item }}
          </v-alert>
        </v-flex>
        <v-flex
          v-for="(item, index) in warnings"
          :key="'2' + index"
          xs12
        >
          <v-alert
            class="pa-2"
            :value="true"
            dismissible
            type="warning"
            transition="fade-transition"
          >
            {{ item }}
          </v-alert>
        </v-flex>

        <h1 class="text-h2 font-weight-black">
          {{ rack }}
        </h1>
       
        <v-data-table
          class="table-striped"
          :headers="headers"
          :items="shipments"
          :loading="loading"
          no-data-text="Awaiting scanned items"
          :page.sync="pagination.page"
          :items-per-page.sync="pagination.itemsPerPage"
          :sort-by.sync="pagination.sortBy"
          :sort-desc.sync="pagination.descending"
        >
          <template #item.current_location="{ item }">
            <span
              v-html="$options.filters.location(item.current_location_id)"
            />
          </template>

          <template #item.destination_location="{ item }">
            <span
              v-html="$options.filters.location(item.destination_location_id)"
            />
          </template>

          <template #item.delivery_required="{ item }">
            {{ item.delivery_required | bool }}
          </template>

          <template #item.created_at="{ item }">
            {{ item.created_at | date_pretty }}
            <br>
            ({{ item.created_at | datetime_from }})
          </template>
        </v-data-table>
      </v-flex>
    </template>
  </FullPageLayout>
</template>

<script>
const initialData = () => {
  return {
    filter: null,
    shipments: [],
    warnings: [],
    activeBtn: 1,
    showNav: true,
    barcodes: [],
    box: 0,
    barcode: null,
    selected: [],
    rack: null,
    headers: [
      {
        text: 'Barcode',
        sortable: false,
        value: 'barcode'
      },
      {
        text: 'User ID',
        sortable: false,
        value: 'user_id'
      },

      {
        text: 'Current Location',
        sortable: false,
        value: 'current_location'
      },

      {
        text: 'Destination Location',
        sortable: false,
        value: 'destination_location'
      },

      {
        text: 'Delivery Required',
        sortable: false,
        value: 'delivery_required'
      },

      {
        text: 'Created At',
        sortable: false,
        value: 'created_at'
      }
    ],

    pagination: {
      page: 1,
      itemsPerPage: 200,
      sortBy: null,
      descending: false
    },
    loading: false,
    errors: []
  };
};
export default {
  data() {
    return initialData();
  },
  computed: {},
  watch: {
    $route() {
      Object.assign(this.$data, initialData());
      this.getFilterFromUrl();
    }
  },
  created() {
    document.activeElement.blur();
    // Add barcode scan listener and pass the callback function
    this.$barcodeScanner.init(this.onBarcodeScanned);
  },
  destroyed() {
    // Remove listener when component is destroyed
    this.$barcodeScanner.destroy();
  },
  mounted() {
    this.getFilterFromUrl();
  },
  methods: {
    manualScan() {
      this.onBarcodeScanned(this.barcode);

      this.barcode = null;
    },
    test() {
      var x = document.activeElement.tagName;

      return x;
    },
    // Create callback function to receive barcode when the scanner is already done
    onBarcodeScanned(barcode) {
      console.log(barcode);

      this.$notify({
        group: 'main',
        title: 'Item Scanned',
        text: barcode,
        duration: 3000
      });

      // let self = this;

      var patt = /^RACK_/;
      //
      if (patt.test(barcode)) {
        var code = barcode.replace(patt, '');

        this.rack = code;

        var msg = new SpeechSynthesisUtterance(code);
        window.speechSynthesis.speak(msg);

        return;
      }

      // if (this.rack == null) {
      //   this.playErrorSound();
      //   this.errors.push('No rack selected');
      //   return;
      // }

      this.scan(barcode).then(res => {
        console.log(res);

        this.shipments.push(res.shipment);
      });

      // do something...
    },
    // Reset to the last barcode before hitting enter (whatever anything in the input box)
    resetBarcode() {
      this.$barcodeScanner.getPreviousCode();
      // do something...
    },
    getFilterFromUrl() {
      if (this.$route?.query?.filter) {
        this.filter = this.$route.query.filter;
      }
    },
    scan(barcode) {
      return new Promise((resolve, reject) => {
        this.$http
          .post('/admin/scan/returns/received', {
            code: barcode,
            rack: this.rack,
            filter: this.filter
          })
          .then(response => response.data)
          .then(response => {
            if (response.success == true) {
              let needDelivery = '';
              if(response.shipment.delivery_required === 1){
                needDelivery = 'Dropoff';
              }
              if (response.shipment.destination_location_id === 5) {
                window.speechSynthesis.speak(
                  new SpeechSynthesisUtterance('Mississauga'+ needDelivery)
                );
              } else if (response.shipment.destination_location_id === 1) {
                window.speechSynthesis.speak(
                  new SpeechSynthesisUtterance('Markham'+ needDelivery)
                );
              } else {
                this.playSuccessSound();
              }
              resolve({
                shipment: response.shipment
              });
            } else {
              this.playErrorSound();
              this.errors.push(response.error);
              reject({});
            }
          })
          .catch(err => {
            this.playErrorSound();
            console.log(err.response);
            this.errors.push('Server error');
            reject({});
          });
      });
    }
  }
};
</script>
