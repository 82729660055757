<template>
  <FullPageLayout
    title="Return Address"
    desc="This is your Stallion Express US return address."
    :bread-crumbs="breadCrumbs"
  >
    <template #default>
      <!--<returnAddressModal></returnAddressModal>-->
      <v-progress-linear
        v-if="loading"
        class="mt-5"
        indeterminate
      />

      <v-card
        class="pa-5 elevation-0"
        style="border: 1px solid #e7e7e7;"
      >
        <ul style="list-style-type: none;">
          <li>
            <b class="address-label">Name:</b>
            {{ $auth.user?.business_name || $auth.user?.name }}
          </li>
          <li>
            <b class="address-label">Address 1:</b>
            {{ stallionAddress.address1 }}
          </li>
          <li>
            <b class="address-label">Address 2:</b>
            {{ stallionAddress.address2 }}
          </li>
          <li>
            <b class="address-label">City:</b>
            {{ stallionAddress.city }}
          </li>
          <li>
            <b class="address-label">State:</b>
            {{ stallionAddress.province_code }}
          </li>
          <li>
            <b class="address-label">Phone:</b>
            {{ stallionAddress.phone | phone }}
          </li>
          <li>
            <b class="address-label">Zip Code:</b>
            {{ stallionAddress.postal_code }}
          </li>
          <li>
            <b class="address-label">Country:</b>
            {{ stallionAddress.country_code }}
          </li>
        </ul>
      </v-card>
    </template>
  </FullPageLayout>
</template>

<script>
export default {
  metaInfo: {
    title: "Return Address | Stallion Express"
  },
  data() {
    return {
      module: 'Return Addresses',
      returnAddresses: [],
      stallionAddress: [],
      stallionIsDefault: true,
      addresses: [],
      loading: false,
      breadCrumbs: [
        {
          text: 'Dashboard',
          exact: true,
          to: '/dashboard'
        },
        {
          text: 'Return Address',
          exact: true,
          to: '/return-address'
        }
      ]
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.getReturnAddresses();
    },
    getReturnAddresses: function() {
      this.loading = true;
      this.$http
        .get('/us-address')
        .then(res => {
          this.stallionAddress = res.data.address;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
