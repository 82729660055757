<template>
  <FullPageLayout title="Return Labels">
    <template #default>
      <v-container
        grid-list-md
        mb-12
      >
        <v-layout wrap>
          <v-flex xs12>
            <v-card>
              <v-card-text>
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template #activator="{ on }">
                    <v-text-field
                      v-model="genDate"
                      v-validate="'required'"
                      :error-messages="errs.collect('genDate')"
                      data-vv-name="genDate"
                      label="Start Date"
                      readonly
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="genDate"
                    @input="menu1 = false"
                  />
                </v-menu>
                <!-- <v-text-field
                  v-model="startCount"
                  v-validate="'required|min_value:0'"
                  :error-messages="errs.collect('startCount')"
                  data-vv-name="startCount"
                  type="number"
                  step="1"
                  min="0"
                  label="Start Number"
                /> -->
                <v-text-field
                  v-model="count"
                  v-validate="'required|min_value:' + (parseInt(startCount) + 1)"
                  :error-messages="errs.collect('count')"
                  data-vv-name="count"
                  type="number"
                  required
                  step="1"
                  :min="parseInt(startCount) + 1"
                  label="Quantity"
                />
                <!-- <v-text-field
                  v-model="startCount"
                  type="number"
                  required
                  step="1"
                  min="1"
                  label="Number of Labels"
                ></v-text-field>-->
                <v-btn
                  color="primary"
                  :disabled="generateLoading"
                  :loading="generateLoading"
                  @click="generateLabels()"
                >
                  Generate
                </v-btn>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
      <v-container class="mb-100">
        <!-- <admin-returns-label-index-table admin /> -->
      </v-container>
    </template>
  </FullPageLayout>
</template>

<script>
export default {
  data() {
    return {
      labelCount: 100,
      generateLoading: false,
      menu1: false,
      genDate: window.moment().format('YYYY-MM-DD'),
      startCount: 0,
      count: 1
    };
  },

  methods: {
    generateLabels() {
      this.$validator.validate().then(result => {
        if (result) {
          this.generateLoading = true;

          var genDate = this.genDate;
          // var startCount = this.startCount;
          var count = this.count;
          this.$http
            .post('/admin/returns/labels/generatelabels', {
              genDate: genDate,
              // startCount: startCount,
              count: count
            })
            .then(response => {
              if (response.data.success) {
                this.$http
                  .get(
                    `/admin/returns/labels/downloadlabel/${response.data.label}`
                  )
                  .then(response => {
                    this.printUrl(response.data);
                    Event.fire('update-return-labels');
                  });
              } else {
                window.swal({
                  title: 'Error',
                  text: 'Something went wrong.',
                  icon: 'error'
                });
              }
            })
            .catch(err => {
              window.swal({
                title: 'Error',
                text: err,
                icon: 'error'
              });
            })
            .finally(() => {
              this.generateLoading = false;
            });
        } else {
          window.swal({
            title: 'Error',
            text: 'Some of the form fields are invalid.',
            icon: 'error'
          });
        }
      });
    }
  }
};
</script>
