<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <user-settings-option
        v-for="(list, index) in newSettings.returns"
        :key="index"
        :list="list"
      />
    </v-col>
    <v-col
      cols="12"
      md="12"
    >
      <v-btn
        color="primary"
        :loading="processing"
        @click="submit"
      >
        Update
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      newSettings: {
        returns: _.cloneDeep(
          this.$auth.user.user_config_settings.returns
        )
      },
      processing: false
    };
  },
  mounted() {},
  methods: {
    submit() {
      this.$validator.validate().then(result => {
        if (result) {
          this.processing = true;
          this.$http
            .post('/users/updateUserConfigSettings', this.newSettings)
            .then(res => {
              // console.log(res);
              if (Object.prototype.hasOwnProperty.call(res.data, 'message')) {
                if (res.data.success) {
                  this.successMessage('Your account changes have been saved');

                } else {
                  this.errorMessage('Your account changes have not been saved');
                }
              }
            })
            .finally(() => {
              this.processing = false;
            });
        }
      });
    }
  }
};
</script>
