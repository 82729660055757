<template>
  <StallionDialog
    v-model="show"
    :width="500"
    :disabled="disabled"
    class="dialog-slot"
    title="BigCommerce"
  >
    <slot v-on="on" />

    <template #content>
      <v-row dense>
        <v-col
          cols="12"
        >
          Please enter your store hash (the text in your site admin URL before "mybigcommerce.com"). <strong>Important:</strong>&nbsp;Do not include the "store-" prefix
        </v-col>
        <v-col>
          <v-text-field
            v-model="store_hash"
            v-validate="'required'"
            :error-messages="errs.collect('store_hash')"
            label="Store Hash"
            data-vv-name="store_hash"
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col
          cols="12"
        >
          You can generate a access token in <a
            href="http://login.bigcommerce.com/deep-links/settings/api-accounts"
            target="_BLANK"
          >the BigCommerce Store API Settings</a>.
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <v-text-field
            v-model="token"
            v-validate="'required'"
            :error-messages="errs.collect('token')"
            label="API Access Token"
            data-vv-name="token"
          />
        </v-col>
      </v-row>
    </template>

    <template #actions>
      <v-btn
        type="submit"
        color="primary"
        :loading="print_loading"
        :disabled="print_loading"
        @click="connect()"
      >
        Connect
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },

  data() {
    return {
      store_hash: null,
      token: null,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    connect: function () {
      this.$validator.validate().then((result) => {
        if (result) {
          this.print_loading = true;
          this.$http
            .post(`/stores/bigcommerce`, {
              store_hash: this.store_hash,
              token: this.token
            })
            .then((response) => response.data)
            .then(() => {
              this.print_loading = false;
              this.$notify({
                group: 'main',
                title: 'Store added',
                text: 'Your account for BigCommerce has been added successfully.',
                duration: 5000,
                type: 'success'
              });
            })
            .catch((err) => {
              if (err.response && err.response.status === 422) {
                this.$notify({
                  group: 'main',
                  title: 'Invalid Credentials',
                  text: err.response.data.errors,
                  duration: 5000,
                  type: 'error'
                });
              } else {
                this.$notify({
                  group: 'main',
                  title: 'Error adding BigCommerce',
                  text: err,
                  duration: 5000,
                  type: 'error'
                });
              }
            });
        }
      });
    }
  }
};
</script>
