<template>
  <StallionDialog
    v-model="show"
    :width="500"
    :disabled="disabled"
    title="Print"
  >
    <template #content>
      <v-layout column>
        <v-flex
          xs12
        >
          <div class="dialog-info">
            {{ labelsCount }} return
            {{ labelsCount | pluralize(' label') }} ready to be printed
          </div>
        </v-flex>
      </v-layout>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        text
        download
        :loading="dowload_loading"
        :disabled="dowload_loading"
        @click.prevent="combineDownload()"
      >
        <v-icon
          small
          dark
        >
          mdi-download
        </v-icon>
        Download
      </v-btn>

      <v-btn
        color="primary"
        :loading="print_loading"
        :disabled="print_loading"
        @click="combinePrint()"
      >
        <v-icon
          small
          dark
        >
          mdi-printer
        </v-icon>
        Print
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    shipments: {
      type: Array,
      default: () => []
    },
    // show: {
    //     default: false,
    //     type: Boolean
    // },
    disabled: {
      default: false,
      type: Boolean
    }
  },

  data() {
    return {
      show: false,
      print_loading: false,
      dowload_loading: false
    };
  },
  computed: {
    labelsCount: function() {
      var count = 0;
      this.shipments.forEach(function() {
        count++;
      });
      return count;
    }
  },
  mounted() {},

  methods: {
    openDialog() {
      this.show = true
    },
    combinePrint: function() {
      this.print_loading = true;
      const id = this.shipments[0].id;

      this.$http
        .get('/admin/return-labels/getlabel/' + id)
        .then(response => {
          this.printUrl(response.data);
            this.print_loading = false;
        })
        .catch(() => {
        })
        .finally(() => {
          this.print_loading = false;
        });
    },
    combineDownload: function() {
      this.dowload_loading = true;
      const id = this.shipments[0].id;
      this.$http
        .get('/admin/return-labels/getlabel/' + id)
        .then(response => {
          this.downloadFileUrl(response.data, `ReturnItemsLabel_${this.$auth.user.id}.pdf`);
          this.dowload_loading = false;
      })
      .finally(() => {
        this.dowload_loading = false;
      });
    }
  }
};
</script>
