<template>
  <div>
    <div
      v-if="show_overlay"
      class="announcement-overlay"
    />
  
    <div v-show="$auth.ready">
      <!-- <transition name="slide-y-transition"> -->
      <!-- <transition name="component-fade" mode="out-in"> -->
      <component :is="layout">
        <!-- <transition name="component-fade" mode="out-in"> -->
        <router-view />
        <!-- </transition> -->
      </component>
      <!-- </transition> -->
      <!-- </transition> -->
      <notifications
        group="main"
        position="top center"
        classes="stallion-notification"
        animation-type="velocity"
        width="600px"
        :ignore-duplicates="true"
      />
      <announcements />
      <v-dialog
        v-model="dialog"
        persistent
        width="300"
      >
        <v-card dark>
          <v-card-text style="margin: 20px">
            {{ message }}
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <div
      v-show="!$auth.ready"
      class="loading-overlay"
    >
      <div class="content">
        <atom-spinner
          style="margin: auto"
          :animation-duration="1000"
          :size="100"
          :color="'#f9ac40'"
        />
        <div>
          <h2>{{ fun_message }}</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const default_layout = 'auth-layout';
import moment from 'moment-timezone';
import { AtomSpinner } from 'epic-spinners';
import Funnies from 'funnies';
export default {
  metaInfo: {
    title: 'Stallion Express'
  },
  components: {
    AtomSpinner
  },
  props: [],
  data() {
    return {
      model: {},
      fun_message: '',
      show_overlay: false
    };
  },
  computed: {
    layout() {
      return this.$route.meta.layout || default_layout;
    },
    dialog() {
      return this.$store.state.main.loading_dialog;
    },
    message() {
      return this.$store.state.main.loading_message;
    }
  },
  watch: {
    '$store.state.main.announcements.unread': {
      handler(value) {
        if (value.length > 0 && this.layout == 'main-layout') {
          this.show_overlay = true;
        } else {
          this.show_overlay = false;
        }
      }
    },
    '$auth.authenticated': {
      immediate: true,

      handler() {
        if (this.$auth.loaded) {
          if (this.$auth.authenticated === true) {
            moment.tz.setDefault(this.$auth.user.timezone);
            moment.locale();

            var words = this.$auth.user.name.split(' ');

            var first_letter = words[0].charAt(0);
            var second_letter = '';

            if (words[1]) second_letter = words[1].charAt(0);

            this.$store.commit(
              'main/setInitials',
              first_letter + second_letter
            );

            // Echo.connect();

            // Echo.connector.pusher.config.auth.headers[
            //   'Authorization'
            // ] = `Bearer ${this.$auth.token}`;

            // Echo.private(`users.${this.$auth.user.id}`).notification(
            //   (notification) => {
            //     if (
            //       notification.type ==
            //       'App\\Notifications\\AnnouncementPublished'
            //     ) {
            //       this.announcementPublished(notification);
            //     } else {
            //       this.$notify({
            //         group: 'main',
            //         title: notification.title,
            //         text: notification.message,
            //         duration: 5000
            //       });

            //       notification.created_at = moment().unix();
            //       notification.read_at = null;

            //       this.$store.commit('main/addNotification', notification);

            //       this.$store.state.main.unread++;
            //     }
            //   }
            // );

            this.initializeStore();

            try {
              Beacon('identify', {
                name: this.$auth.user.name,
                email: this.$auth.user.email,
                company: this.$auth.user.business_name,
                site: 'ship.stallionexpress.ca'
              });

              // Tooltip.API.updateUserData({
              //   name: this.$auth.user.name,
              //   email: this.$auth.user.email
              // });
            } catch (err) {
              console.log(err);
            }

            // console.log(this.$auth.user.notifications);
          }
        }
      },
      deep: true
    }
  },
  mounted() {
    if (this.$auth.authenticated) {
      this.getUser();
    }
    let funnies = new Funnies();
    this.fun_message = funnies.message();
  },
  methods: {
    getNotifications() {
  this.$http
    .get(`/notifications`)
      .then((response) => response.data)
      .then((response) => {
        if (this.$auth.user === null) {
          this.$auth.user = {};
        }
        this.$auth.user.notifications = response;

        this.$store.dispatch('main/setNotifications', {
            notifications: response.latestSummary || [],
            unread: response.unread
        });
      });
    },
    announcementPublished(notification) {
      const announcements = this.$store.getters['main/announcements'];
      if (notification.announcement_id) {
        notification.id = notification.announcement_id;
      }
      announcements.unread.push(notification);
      this.$store.commit('main/updateAnnouncements', announcements);
      Event.fire('display-announcement');
    },
    initializeStore() {
      this.getNotifications();
      this.$store.dispatch('main/getDefaultAddress');
      this.$store.dispatch('main/getPostageTypes');
      this.$store.dispatch('main/getThirdPartyPostageTypes');
      this.$store.dispatch('main/getReturnPostageTypes');
      this.$store.dispatch('main/getPackageTypes');
      this.$store.dispatch('main/getTaxIdentifiers');
      this.$store.dispatch('tags/getTags');
      this.$store.dispatch('boxes/getBoxes');
      this.$store.dispatch('packingSlipTemplates/getTemplates');
      this.$store.dispatch('listCriteria/getLists');
      this.$store.dispatch('filters/getFilters');
      this.$store.dispatch('main/getAnnouncements').then((response) => {
        if (response) {
          Event.fire('display-announcement');
        }
      });
    }
  }
};
</script>

<style lang="scss">
// @import '../resources/assets/sass/custom-style.scss';
</style>
