<template>
  <v-row dense>
    <v-tabs
      v-model="tabSelected"
      class="elevation-0"
    >
      <v-tab>
        <h4 class="settings-tab">
          General
        </h4>
      </v-tab>

      <v-tab>
        <h4 class="settings-tab">
          Commission Structure
        </h4>
      </v-tab>

      <v-tab-item>
        <general :user="user" />
      </v-tab-item>

      <v-tab-item>
        <referral-commission :user="user" />
      </v-tab-item>
    </v-tabs>
  </v-row>
</template>

<script>
import General from './General.vue';
import ReferralCommission from './ReferralCommission.vue';
export default {
  components: {
    General,
    ReferralCommission
  },
  props: {
    user: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      tabSelected: null
    }
  }
};
</script>
