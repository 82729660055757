<template>
  <StallionDialog
    v-model="show"
    :width="500"
    :disabled="disabled"
    title="Archive Shipments"
  >
    <template #content>
      <v-layout column>
        <v-flex
          xs12
          text-center
          mt-4
          mb-12
        >
          <h3>
            You are about to archive {{ shipments.length }}
            {{ shipments.length | pluralize(' shipment') }}. You will no
            longer be able to view them in your dashboard.
          </h3>
        </v-flex>
      </v-layout>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        :loading="archive_loading"
        :disabled="archive_loading"
        @click="remove()"
      >
        <v-icon
          small
          dark
        >
          mdi-archive-outline
        </v-icon>Archive
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    shipments: {
      type: Array,
      default: () => []
    },

    disabled: {
      default: false,
      type: Boolean
    },
    value: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      archive_loading: false,
      number: 0
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },

  methods: {
    remove: function() {
      console.log('remove');
      this.archive_loading = true;

      const ids = this.shipments.map(a => a.id);

      this.$http
        .post('/shipments/delete', ids)
        .then(() => {})
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.archive_loading = false;
          this.show = null;
          this.number = 0;

          this.$emit('update');
        });
    }
  }
};
</script>
