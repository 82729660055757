<template>
  <v-card flat>
    <v-container class="px-0 py-0">
      <v-row>
        <v-col md="6">
          <p class="mb-2">
            Add/Edit Sub-Users
          </p>
        </v-col>
        <v-col md="6">
          <v-row class="float-right">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                  class="mx-2"
                  color="primary"
                  depressed
                  outlined
                  :disabled="loading"
                  v-on="on"
                  @click="$refs.userDialog.startNew()"
                >
                  <v-icon small>
                    mdi-plus
                  </v-icon>Add User
                </v-btn>
              </template>
              <span>Add User</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
            outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <v-card outlined>
            <v-data-table
              :headers="headers"
              :loading="loading"
              item-key="id"
              :search="search"
              :items="subUsers"
              class="table-striped elevation-0"
            >
              <template #item.actions="{ item }">
                <v-btn
                  :disabled="loading"
                  small
                  color="red"
                  text
                  @click="deleteUser(item)"
                >
                  Delete
                </v-btn>
                <v-btn
                  :disabled="loading"
                  small
                  color="primary"
                  text
                  @click="$refs.userDialog.startEdit(item)"
                >
                  Edit
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <user-form-dialog
      ref="userDialog"
      @save="getSubUsers"
    />
  </v-card>
</template>

<script>
import UserFormDialog from './UserFormDialog.vue';
export default {
  components: {
    UserFormDialog
  },
  data() {
    return {
      search: null,
      headers: [
        {
          text: 'Username',
          align: 'left',
          value: 'username'
        },
        {
          text: 'Email',
          align: 'left',
          value: 'email'
        },
        {
          text: 'Name',
          align: 'left',
          value: 'name'
        },

        // {
        //   text: 'Status',
        //   align: 'left',
        //   value: 'status'
        // },
        {
          text: '',
          align: 'right',
          value: 'actions'
        }
      ],
      loading: false,
      user: {},
      subUsers: [],
      userDialog: false,
      formType: null
    };
  },
  mounted() {
    this.getSubUsers();
  },
  methods: {
    showUserForm() {
      this.userDialog = true;
    },
    editUser(user) {
      this.user = { ...user };
      this.formType = 'Update';
      this.showUserForm();
    },
    addUser() {
      this.user = {
        id: null,
        email: null,
        sub_permissions: [],
        restrictions: {}
      };
      this.formType = 'Create';
      this.showUserForm();
    },
    getSubUsers() {
      this.loading = true;
      this.$http
        .get('sub-users')
        .then((response) => response.data)
        .then((response) => {
          this.subUsers = response;
        })
        .catch(() => {
          swal({
            title: 'Error!',
            text: 'An error occurred',
            icon: 'error'
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async deleteUser(user) {
      const isConfirm = await swal({
        title: 'Confirm Delete',
        text: 'Do you really want to delete this sub-user?',
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      });

      if (isConfirm) {
        this.loading = true;
        try {
          await this.$http.delete(`sub-users/${user.id}`);
          swal({
            title: 'Success',
            text: 'Sub-User successfully deleted',
            icon: 'success'
          });
          this.getSubUsers();
        } catch (err) {
          swal({
            title: 'Error!',
            text: 'An error occurred',
            icon: 'error'
          });
        }

        this.loading = false;
      }
    }
  }
};
</script>
