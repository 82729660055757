<template>
  <FullPageLayout
    title="Returns - Orders"
    :bread-crumbs="breadCrumbs"
  >
    <template #subtitle>
      View all your return orders, print, and pick up from our Stallion warehouse. Find answers to common questions at our
      <a
        style="font-weight: 700; text-decoration: none;"
        href="https://help.stallionexpress.ca/"
        target="_blank"
      >Help Centre!</a>
    </template>

  
    <template #default>
      <return-orders-table
        v-model="selected"
        class="mt-5"
        :filter="$route.query.filter"
      />
    </template>

    <template #footer>
      <return-orders-print
        v-model="showPrintDialog"
        :orders="selected"
      />

      <v-btn
        color="primary"
        :disabled="selected.length == 0"
        @click="showPrintDialog = true"
      >
        <v-icon small>
          mdi-printer
        </v-icon>Print
      </v-btn>
    </template>
  </FullPageLayout>
</template>

<script>
export default {
  metaInfo: {
    title: "Return Orders | Stallion Express"
  },
  data() {
    return {
      showPrintDialog: false,
      selected: [],
      breadCrumbs: [
        {
          text: 'Home',
          exact: true,
          to: '/dashboard',
        },
        {
          text: 'Returns',
          exact: true,
          to: '/returns',
        },
      
        {
          text: 'Orders',
          exact: true,
          to: '/returns/orders',
        },
        {
          text: this.filter_formated ,
          exact: true,
          to: '/returns/orders?filter='+this.$route.query.filter,
        }    
      ]
    };
  },
  computed: {
    filter_formated() {
      return this.$options.filters.capitalize(this.$route.query.filter);
    }
  },
  mounted() {}
};
</script>
