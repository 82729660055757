<template>
  <div>
    <h3>Recent Uploads</h3>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      item-key="id"
      :options.sync="options"
      :server-items-length="totalItems"
      :footer-props="{
        'items-per-page-options': [5, 10, 20]
      }"
      :items-per-page="25"
      dense
      @click:row="rowClick"
    >
      <template #item.created_at="{ item }">
        {{ item.created_at | date_pretty }}
        <br>
        ({{ item.created_at | datetime_from }})
      </template>

      <template #item.file="{ item }">
        <a href="javascript:void(0);">{{ item.file | file_download }}</a>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    userId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'File',
          value: 'file',
          sortable: false
        },

        {
          text: 'Date',
          value: 'created_at',
          align: 'right',
          sortable: false
        }
      ],
      options: {
        sortBy: ['created_at'],
        sortDesc: [true]
      },
      loading: false,
      totalItems: 0,
      items: []
    };
  },
  watch: {
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    }
  },
  methods: {
    rowClick(item) {
      this.download(item.file);
    },
    download: function(data) {
      // var newfile;

      this.$http
        .post('/user-imports/download', { file: data })
        .then(response => {
          this.downloadFileUrl(response.data,data);
        });
    },
    updateTable() {
      this.selected = [];
      this.totalItems = 0;
      this.items = [];

      this.getDataFromApi().then(data => {
        this.items = data.items;
        this.totalItems = data.total;
      });
    },

    getDataFromApi() {
      this.loading = true;
      return new Promise(resolve => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let items = [];
        let total = 0;
        let pages = 0;

        var params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sortBy[0],
          desc: sortDesc[0]
        };

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        this.$http
          .request(`/user-imports/get-user-imports`, {
            method: 'get',
            params: params,
            cancelToken: this.cancelSource.token
          })
          .then(response => response.data)
          .then(response => {
            items = response.data;
            total = response.total;
            pages = response.last_page;

            this.loading = false;
            resolve({
              items,
              total,
              pages
            });
          });
      });
    }
  }
};
</script>
