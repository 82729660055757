<template>
  <div>
    <v-row
      v-if="criteria.length > 0"
      dense
    >
      <v-col
        cols="12"
        md="4"
      >
        <v-select
          v-model="field_local"
          outlined
          dense
          :items="criteria"
          label="Field"
        />
      </v-col>

      <v-col
        v-if="field_local != null"
        cols="12"
        md="4"
      >
        <v-select
          v-model="comparator_local"
          outlined
          dense
          :items="comparators"
          label="Comparison"
        />
      </v-col>

      <v-col
        v-if="input != null && !hideInput"
        cols="12"
        md="4"
      >
        <!-- IMPORTANT: must be check first - list is now available in number, string and select inputs -->
        <v-autocomplete
          v-if="isListAvailable"
          v-model="value_local"
          outlined
          dense
          multiple
          :items="input.lists"
          label="Value"
          :item-text="input.text || 'text'"
          :item-value="input.key || 'key'"
        />
      
        <v-text-field
          v-else-if="input.type == 'text'"
          v-model="value_local"
          outlined
          dense
          type="text"
          label="Value"
        />

        <v-text-field
          v-else-if="input.type == 'number'"
          v-model="value_local"
          outlined
          dense
          type="number"
          label="Value"
        />

        <v-select
          v-else-if="input.type == 'boolean'"
          v-model="value_local"
          outlined
          dense
          :items="bool_values"
          label="Value"
          item-text="text"
          item-value="value"
        />

        <v-autocomplete
          v-else-if="input.type == 'select'"
          v-model="value_local"
          outlined
          dense
          multiple
          :items="input.options"
          label="Value"
          :item-text="input.text"
          :item-value="input.key"
        />

        <v-menu
          v-else-if="input.type == 'date'"
          v-model="end_date_menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template #activator="{ on }">
            <v-text-field
              v-model="value_local"
              outlined
              dense
              style="padding: 0"
              label="End Date"
              readonly
              clearable
              hide-details
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="value_local"
            @input="end_date_menu = false"
          />
        </v-menu>
      </v-col>
      <v-col
        v-if="info"
        cols="12"
      >
        <v-alert
          dense
          type="info"
        >
          {{ info }}
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import filters from '../../../shared/filters';
import { mapState } from 'vuex';
export default {
  components: {},
  props: {
    field: {
      type: String,
      default: null
    },
    comparator: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      info: null,
      comparators: [],
      dialog: false,
      input: null,
      hideInput: false,
      criteria: filters.CRITERIA,
      bool_values: [
        { text: 'No', value: 'false' },
        { text: 'Yes', value: 'true' }
      ]
    };
  },
  computed: {
    ...mapState(['userStores']),
    isListAvailable() {
      if (!this.input) {
        return false
      }

      return filters.LIST_OPERATOR_VALUES.includes(this.comparator_local)
    },
    field_local: {
      get() {
        return this.field;
      },
      set(field) {
        this.comparator_local = null;
        this.value_local = null;
        this.$emit('update:field', field);
      }
    },
    comparator_local: {
      get() {
        const index = this.comparators.findIndex((x) => x.value === this.comparator);
        this.hideInput = this.comparators[index]?.hideInput === true;
        return this.comparator;
      },
      set(comparator) {
        this.$emit('update:comparator', comparator);

        const index = this.comparators.findIndex((x) => x.value === comparator);
        this.hideInput = this.comparators[index]?.hideInput === true;
        // if (this.hideInput) {
        //   this.value_local = null;
        // }

        // reset value on comparator change
        this.value_local = null;
      }
    },
    value_local: {
      get() {
        var value = this.value;
        if (
          (this.input) &&
          (this.input.type != 'boolean') & (value !== null) &&
          typeof value == 'string'
        ) {
          value = value.split(';');
        }
        return value;
      },
      set(value) {
        if (value !== null && Array.isArray(value)) {
          value = value.filter((v) => v !== '');
          value = value.join(';');
        }
        this.$emit('update:value', value);
      }
    }
  },
  watch: {
    field_local: {
      immediate: true,
      handler(val) {
        if (val) {
          this.initCriteria()
          const index = this.criteria.findIndex((x) => x.value == val);
          this.comparators = this.criteria[index].comparators;
          this.input = this.criteria[index].input;

          this.info = this.criteria[index].hint ?? null;

          this.setCriteria()
        } else {
          this.comparator_local = null;
          this.value_local = null;
          this.list_local = [];
          this.input = null;
          this.info = null;
        }
      }
    }
  },
  mounted() {
    this.initCriteria()
  },
  methods: {
    setCriteria() {
      this.criteria.forEach((c) => {
        if (
          this.type == 'pricing' &&
          c.value === 'postage_type' &&
          !c.options
        ) {
          this.$http
            .get(`/admin/postage-types`)
            .then((response) => response.data)
            .then((response) => {
              c.input.options = response.map((postage) => {
                return {
                  id: postage.id.toString(),
                  description: postage.description
                };
              });
            });
        } else if (c.value === 'postage_type' && !c.options) {
          c.input.options = this.$store.getters['main/postage_types'](true).map((postage) => {
                return {
                  id: postage.id.toString(),
                  description: postage.description
                };
              });
        } else if (c.value === 'package_type' && !c.options) {
          c.input.options = this.$store.getters['main/package_types'].map((postage) => {
                return {
                  id: postage.id.toString(),
                  description: postage.description
                };
              });
        } else if (c.value === 'user_store_id' && !c.options) {
          this.$store.dispatch('userStores/getStores');
          const op = this.$store.getters['userStores/getStoreOptions'];
          let d = [];
          op.forEach(o => {
            d.push({
              'id': o.id,
              'identifier': o.nickname ? o.nickname : o.identifier
            });
          });
          const index = this.criteria.findIndex(
            (x) => x.value === 'user_store_id'
          );

          this.criteria[index].input.options = d

          // return (this.criteria[index].input.options = op);
        } else if (c.value === 'order_tags' && !c.options) {
          c.input.options = this.$store.getters['tags/filtered_tags'].map((tag) => {
                return {
                  id: tag.id.toString(),
                  name: tag.name
                };
              });
        } else if (c.value === 'box_id' && !c.options) {
          c.input.options = this.$store.getters['boxes/boxes'].map((box) => {
                return {
                  id: box.id.toString(),
                  description: box.name
                };
              });
        }

        this.addDynamicList(c)
      });
    },

    addDynamicList(criteria) {
      if (criteria.comparators.filter(e => filters.LIST_OPERATOR_VALUES.includes(e.value)).length == 0) {
        return
      }

      if (!Array.isArray(criteria.input.lists)) {
        criteria.input.lists = []
      }

      // initialize original list
      if (criteria.input.lists_original === undefined || criteria.input.lists_original === null) {
        criteria.input.lists_original = _cloneDeep(criteria.input.lists)
      }

      // reset to original list
      criteria.input.lists = _cloneDeep(criteria.input.lists_original)
      // add dynamic list criteria - initially called at App.vue@initializeStore
      this.$store.getters['listCriteria/lists'].forEach((list) => {
        let criteriaField = criteria.value == 'item_names' ? 'title' : criteria.value;
        criteriaField = criteria.value == 'item_skus' ? 'sku' : criteriaField

        if (criteriaField == list.field) {
          criteria.input.lists.push({
            [criteria.input.text || 'text']: list.name,
            [criteria.input.key || 'key']: list.id
          })
        }
      })
    },

    initCriteria() {
      if (this.type == 'pricing') {
        this.criteria = filters.PRICING_CRITERIA;
      } else {
        this.criteria = filters.CRITERIA;
      }
    }
  }
};
</script>
