<template>
  <FullPageLayout
    title="All Batches"
    :bread-crumbs="breadCrumbs"
  >
    <template #default>
      <batch-table />
    </template>
    <template #footer>
      <action-bar :batches="batches" />
    </template>
  </FullPageLayout>
</template>

<script>
import ActionBar from './ActionBar.vue';

export default {
  metaInfo: {
    title: "Batches | Stallion Express"
  },
  components: {
    ActionBar
  },
  data() {
    return {
      pending: false,
      breadCrumbs: [
        {
          text: 'Home',
          exact: true,
          to: '/dashboard'
        },
        {
          text: 'Shipments',
          exact: true,
          to: '/shipments'
        },
        {
          text: 'All Batches',
          exact: true,
          to: '/batches'
        }
      ]
    };
  },
  computed: {
    batches() {
      return this.$store.state.main.selectedBatches;
    }
  },
  watch: {},
  mounted() {
    if (this.$route.query.pending) {
      this.pending = true;
    }
  },
  methods: {}
};
</script>
