<template>
  <FullPageLayout title="Regions">
    <template #default>
      <region-table />
    </template>
  </FullPageLayout>
</template>
    
<script>
import RegionTable from './RegionTable.vue'
export default {
  components: {
    RegionTable
  },
  data() {
    return {
    }
  },
  mounted() {

  },
  methods: {
  
  }
};
</script>

