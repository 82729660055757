<template>
  <v-row
    v-if="can('users.login_as')"
    dense
    mt-12
  >
    <v-col cols="12">
      <h4>{{ user.email }}</h4>
    </v-col>
    <v-col cols="12">
      <v-btn
        class="mt-5 mb-5"
        color="primary"
        :loading="loading"
        @click="loginAsUser()"
      >
        Login
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false
    };
  },
  async mounted() {},
  methods: {
    loginAsUser() {
      this.loading = true;
      this.login(null, null, false, `admin/users/${this.user.id}/login`).then(() =>{
        this.$router.push({ name: 'dashboard' });
        window.location.reload()
      })
    }
  }
};
</script>
