<template>
  <div>
    <v-container
      grid-list-md
      mb-12
    >
      <v-layout wrap>
        <v-flex xs12>
          <h1>
            <span>Generate Rates</span>
          </h1>
        </v-flex>

        <v-tabs
          v-model="tab"
        >
          <v-tab>Rate Card</v-tab>
          <v-tab>Import Template</v-tab>
          <v-tab>Downloads</v-tab>
        </v-tabs>
      </v-layout>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <RateCard />
        </v-tab-item>
        <v-tab-item>
          <ImportTemplate />
        </v-tab-item>
        <v-tab-item>
          <DownloadsTable :tab="tab" />
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </div>
</template>

<script>
import RateCard from './RateCard.vue'
import ImportTemplate from './ImportTemplate.vue'
import DownloadsTable from './DownloadsTable.vue'

export default {
  components: {
    RateCard,
    ImportTemplate,
    DownloadsTable
  },
  data() {
    return {
      tab: null,
    };
  },
};
</script>
