<template>
  <FullPageLayout 
    title="Drop-off Invoices" 
    desc="View monthly invoices of your earned commission."
  >
    <template #default>
      <v-row>
        <v-col
          cols="12"
          md="8"
        >
          <invoice-table />
        </v-col>
      </v-row>
    </template>
  </FullPageLayout>
</template>

<script>
import InvoiceTable from './InvoiceTable.vue';
export default {
  metaInfo() {
    return {
      title: `Drop Off Invoices | Stallion Express`
    }
  },
  components: {
    InvoiceTable
  },
  data() {
    return {};
  }
};
</script>
