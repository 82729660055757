<template>
  <StallionDialog
    v-model="show"
    :width="500"
    :disabled="disabled"
    class="dialog-slot"
    title="Shipstation"
  >
    <slot v-on="on" />

    <template #content>
      <v-row dense>
        <v-col
          cols="12"
          class="text-center"
        >
          Go to
          <a
            href="https://ship2.shipstation.com/settings/api"
            target="_blank"
          >ShipStation API Settings</a>
          section from your ShipStation account and generate an API key if
          none exists.
        </v-col>

        <v-col cols="12">
          <v-text-field
            id="apiKey"
            v-model="apiKey"
            v-validate="'required'"
            :error-messages="errs.collect('apiKey')"
            label="API Key"
            data-vv-name="apiKey"
          />
        </v-col>

        <v-col cols="12">
          <v-text-field
            id="apiSecret"
            v-model="apiSecret"
            v-validate="'required'"
            :error-messages="errs.collect('apiSecret')"
            label="API Secret"
            data-vv-name="apiSecret"
          />
        </v-col>
        <v-flex xs12>
          <v-text-field
            id="nickname"
            v-model="nickname"
            :error-messages="errs.collect('nickname')"
            label="Nickname(Optional)"
            data-vv-name="nickname"
          />
        </v-flex>
      </v-row>
    </template>

    <template #actions>
      <v-btn
        type="submit"
        color="primary"
        :loading="isSubmitting"
        :disabled="isSubmitting"
        @click="connect()"
      >
        Connect
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },

  data() {
    return {
      apiKey: null,
      apiSecret: null,
      nickname: null,
      isSubmitting: false
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    close() {
      this.$emit('update:show', false);
    },
    connect() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.isSubmitting = true;
          const { apiKey, apiSecret, nickname } = this.$data;
          this.$http
            .post('/stores/shipstation', { apiKey, apiSecret, nickname })
            .then(response => response.data)
            .then(() => {
              this.$store.dispatch('userStores/getStores');
              this.show = false;
              this.$notify({
                group: 'main',
                title: 'Store added',
                text:
                  'Your account for shipstation has been added successfully.',
                duration: 5000,
                type: 'success'
              });
            })
            .catch(err => {
              if (err.response && err.response.status === 422) {
                this.$notify({
                  group: 'main',
                  title: 'Invalid Credentials',
                  text: err.response.data.errors,
                  duration: 5000,
                  type: 'error'
                });
              } else {
                this.$notify({
                  group: 'main',
                  title: 'Error adding Shipstation',
                  text: err,
                  duration: 5000,
                  type: 'error'
                });
              }
            })
            .finally(() => {
              this.isSubmitting = false;
            });
        }
      });
    }
  }
};
</script>
