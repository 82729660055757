<template>
  <FullPageLayout title="Sample Rates">
    <template #default>
      <v-layout>
        <v-flex xs12>
          <v-card>
            <v-card-text v-if="rates">
              <h2>Output Files</h2>
              <div
                v-for="file in rates"
                :key="file"
              >
                <a
                  href="javascript:void(0);"
                  @click="download(file)"
                >{{
                  file | file_download
                }}</a>
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </template>
  </FullPageLayout>
</template>

<script>
export default {
  props: {
    tripNumber: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      rates: null,
      auto_refresh: false
    };
  },
  mounted() {
    this.getSampleRates();
  },

  methods: {
    getSampleRates() {
      this.$http
        .get(`/admin/sample-rates`)
        .then(response => response.data)
        .then(response => {
          this.rates = response;
        })
        .catch(() => {
          this.processing = false;
          swal({
            title: 'Error',
            text: 'err',
            icon: 'error'
          });
        });
    },
    download: function(data) {
      // var newfile;

      this.$http
        .post('/admin/sample-rates/download', { file: data })
        .then(response => {
          this.downloadFileUrl(response.data,data, 'text/csv');
        });
    }
  }
};
</script>
