<template>
  <SidePanelLayout>
    <template #side-panel>
      <admin-view-shipment-details :shipments="$store.state.main.selectedShipments" />
    </template>

    <template #default>
      <admin-shipment-table :status-id="11" />
    </template>

    <template
      v-if="can('voids.manage')"
      #footer
    >
      <v-menu>
        <template #activator="{ on }">
          <v-btn
            color="primary"
            :disabled="$store.state.main.selectedShipments.length == 0"
            v-on="on"
            @click="$refs.approveVoid.openDialog()"
          >
            <v-icon
              small
              left
            >
              mdi-check
            </v-icon>
            Approve
          </v-btn>
          
          <v-btn
            color="primary"
            :disabled="$store.state.main.selectedShipments.length == 0"
            v-on="on"
            @click="$refs.rejectVoid.openDialog()"
          >
            <v-icon
              small
              left
            >
              mdi-close-outline
            </v-icon>
            Reject
          </v-btn>
        </template>
      </v-menu>

      <approve-void
        ref="approveVoid"
        :shipments="$store.state.main.selectedShipments"
      />
      <reject-void
        ref="rejectVoid"
        :shipments="$store.state.main.selectedShipments"
      />
    </template>
  </SidePanelLayout>
</template>

<script>
export default {
  data() {
    return {
      pending: false,
      loading: false,
      loading2: false,
      dialog: null
    };
  },
  computed: {
    revertable() {
      if (this.$store.state.main.selectedShipments.length != 0) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {},
  mounted() {
    if (this.$route.query.pending) {
      this.pending = true;
    }
  },
  methods: {
    closeout() {
      this.loading = true;
      this.$http
        .get('/admin/closeout/us')
        .then(response => {
          var res = response.data;
          if (res.success) {
            Event.fire('admin-update-shipments');
            swal({
              title: 'Closeout Successful',
              text: `asas`,
              icon: 'success'
            });
          } else {
            swal({
              title: 'Error!',
              text: res.error,
              icon: 'error'
            });
          }
        })
        .catch(res => {
          swal({
            title: 'Error!',
            text: res.error,
            icon: 'error'
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    transfer() {
      this.loading2 = true;
      this.$http
        .get('/admin/transfers/us')
        .then(() => {
          // var res = response.data;

          Event.fire('admin-update-shipments');
          swal({
            title: 'Success',
            text: `Transfer successful`,
            icon: 'success'
          });
          this.dialog = false;
        })
        .catch(() => {
          swal({
            title: 'Error',
            text: 'Transfer not successful',
            icon: 'error'
          });
        })
        .finally(() => {
          this.loading2 = false;
        });
    },
    revert() {
      var ids = this.$store.state.main.selectedShipments.map(x => x.id);
      this.$http
        .post('/admin/closeouts/revert', { ids })
        .then(() => {
          Event.fire('admin-update-shipments');
        })
        .catch(() => {});
    }
  }
};
</script>
