<template>
  <div>
    <v-form
      class="very-dense"
      @submit.prevent
    >
      <v-row
        dense
      >
        <v-col>
          <!-- Search -->
          <v-row
            dense
          >
            <v-col>
              <v-row dense>
                <v-col>
                  <v-text-field
                    v-model="searchOrderFilters.order_id"
           
                    label="Order ID"
                    hide-details
            
                    clearable
                    outlined
                    dense
                    @input="autoSearch()"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="searchOrderFilters.name"
           
                    label="Recipient Name"
                    hide-details
            
                    clearable
                    outlined
                    dense
                    @input="autoSearch()"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="searchOrderFilters.item_name"
           
                    label="Item Name"
                    hide-details
            
                    clearable
                    outlined
                    dense
                    @input="autoSearch()"
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="searchOrderFilters.item_sku"
           
                    label="Item SKU"
                    hide-details
            
                    clearable
                    outlined
                    dense
                    @input="autoSearch()"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- Providers -->
          <v-row
            v-if="showAdvanceSearchLocal && selectionType == 'store'"
            dense
          >
            <v-col cols="6">
              <v-autocomplete
                v-model="searchOrderFilters.filter_id"
                prepend-inner-icon="mdi-filter"
                dense
                clearable
                :items="filters.filters"
                label="Filter"
                item-text="name"
                item-value="id"
                outlined
                multiple
                @input="autoSearch()"
              />
            </v-col>
    

            <v-col cols="6">
              <v-autocomplete
                v-model="searchOrderFilters.user_store_id"
                prepend-inner-icon="mdi-store"
                dense
                clearable
                :items="userStores.user_stores"
                label="Stores"
                multiple
                :item-text="getStoreName"
                item-value="id"
                :search-input.sync="search_input"
                outlined
                @input="autoSearch()"
              />
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-select
                v-model="order_date_type_local"
                dense
                outlined
                :items="date_types"
                label="Order Date"
                @input="autoSearch()"
              />
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <date-picker
                v-model="searchOrderFilters.order_date_min"
                label="Start Date"
                @change="autoSearch"
              />
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <date-picker
                v-model="searchOrderFilters.order_date_max"
                label="End Date"
                @change="autoSearch"
              />
            </v-col>
        
            <v-col
              cols="12"
              md="4"
            >
              <v-autocomplete
                v-model="order_tag_local"
                prepend-inner-icon="mdi-tag"
                clearable
                data-vv-name="order_tag"
                :items="tags.tags"
                label="Tags"
                multiple
                item-text="name"
                item-value="id"
                dense
                outlined
                @input="autoSearch()"
              >
                <template #item="{ item, on, attrs }">
                  <v-list-item
                    :style="attrs.inputValue ? { backgroundColor: '#D3D3D3' } : ''"
                    v-on="on"
                  >
                    <v-list-item-action>
                      <v-simple-checkbox
                        :value="attrs.inputValue"
                        color="primary"
                        :ripple="false"
                        v-on="on"
                      />
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        :style="{
                          backgroundColor: item.color_code,
                          padding: '10px',
                          borderRadius: '7px'
                        }"
                      >
                        {{ item.name }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row
            v-if="showAdvanceSearchLocal"
            dense
          />


          <!-- Date -->
          <v-row
            v-if="showAdvanceSearchLocal"
            dense
          >
            <v-col
              cols="12"
              md="2"
            >
              <v-text-field
                v-model="searchOrderFilters.weight_min"
                prepend-inner-icon="mdi-weight-pound"
                label="Min"
                hide-details
            
                clearable
                outlined
                dense
                @input="autoSearch()"
              />
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-text-field
                v-model="searchOrderFilters.weight_max"
                prepend-inner-icon="mdi-weight-pound"
                label="Max"
                hide-details
            
                clearable
                outlined
                dense
                @input="autoSearch()"
              />
            </v-col>

            <!-- Value -->
            <v-col
              cols="12"
              md="2"
            >
              <v-text-field
                v-model="searchOrderFilters.value_min"
                prepend-inner-icon="mdi-currency-usd"
                label="Min"
                hide-details
            
                clearable
                outlined
                dense
                @input="autoSearch()"
              />
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-text-field
                v-model="searchOrderFilters.value_max"
                prepend-inner-icon="mdi-currency-usd"
                label="Max"
                hide-details
            
                clearable
                outlined
                dense
                @input="autoSearch()"
              />
            </v-col>
       
      
   
            <!-- Total Quantity -->
            <v-col
              cols="12"
              md="2"
            >
              <v-text-field
                v-model="searchOrderFilters.quantity_min"
           
                label="Min Quantity"
                hide-details
            
                clearable
                outlined
                dense
                @input="autoSearch()"
              />
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-text-field
                v-model="searchOrderFilters.quantity_max"
         
                label="Max Quantity"
                hide-details
            
                clearable
                outlined
                dense
                @input="autoSearch()"
              />  
            </v-col>
          </v-row>
      

          <v-row
            v-if="showAdvanceSearchLocal"
            dense
          >
            <v-col
              cols="12"
              md="2"
            >
              <v-select
                v-model="country_code_type_local"
                dense
                outlined
                :items="['Domestic', 'International', 'US', 'Custom']"
                label="Countries"
                @input="autoSearch()"
              />
            </v-col>
    
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="searchOrderFilters.country_code"
                prepend-inner-icon="mdi-earth"
                :disabled="country_code_type_local != 'Custom'"
                clearable
                data-vv-name="country_code"
                :items="countries"
                label="Country"
                multiple
                item-text="name"
                item-value="code"
                :search-input.sync="country_input"
                dense
                outlined
                @input="autoSearch()"
              />
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-switch
                v-model="searchOrderFilters.country_code_inclusive"
                class="mt-1"
                dense
                :disabled="country_code_type_local != 'Custom'"
                :value="true"
                :label="
                  searchOrderFilters.country_code_inclusive == true
                    ? 'Inclusive'
                    : 'Exclusive'
                "
                @change="search()"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="auto"
        >
          <v-col
            v-if="!hideAdvanceSearchButton"
            class="text-right pa-0"
          >
            <!-- Clear Search Button with Tooltip -->
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                  color="primary"
                  icon
        
                  aria-label="Clear search"
                  v-on="on"
                  @click="clear"
                >
                  <v-icon>
                    mdi-close-circle-outline <!-- Updated icon for clearing/reset -->
                  </v-icon>
                </v-btn>
              </template>
              <span>Clear Search</span>
            </v-tooltip>

            <!-- Advanced Search Toggle Button with Tooltip -->
            <v-tooltip
              v-model="show2"
              bottom
            >
              <template #activator="{ on }">
                <v-btn
                  color="primary"
   
                  icon
                  aria-label="Toggle Advanced Filters"
                  v-on="on"
                  @click="showAdvanceSearchLocal = !showAdvanceSearchLocal"
                >
                  <v-icon>
                    {{ showAdvanceSearchLocal ? 'mdi-chevron-up-circle-outline' : 'mdi-chevron-down-circle-outline' }}
                  </v-icon>
                </v-btn>
              </template>
    
              <!-- Tooltip content based on the button state -->
              <span>
                {{ showAdvanceSearchLocal ? 'Hide Advanced Filters' : 'Show Advanced Filters' }}
              </span>
            </v-tooltip>
          </v-col>
        </v-col>
      </v-row>
      <!-- <v-row
        v-if="showAdvanceSearchLocal"
        dense
      >
        <v-col
          cols="12"
          md="12"
          class="text-center"
        >
          <v-btn
            color="primary"
            
            
            @click="search"
          >
            <v-icon left>
              mdi-magnify
            </v-icon> Search
          </v-btn>
        </v-col>
      </v-row> -->
    </v-form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CONSTANTS from '@/constants/index';
export default {
  props: {
    selectionType: {
      type: String,
      default: 'store'
    },
    searchOrderFilters: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Array,
      default: () => []
    },
    showAdvanceSearch: {
      type: Boolean,
      default: false
    },
    hideAdvanceSearchButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show2: null,
      show_edit: false,
      search_input: null,
      country_input: null,
      end_date_menu: null,
      start_date_menu: null,
      date_types: [
        { text: 'Today', value: 'Today' },
        { text: 'Yesterday', value: 'Yesterday' },
        { text: '1 Week', value: 7 },
        { text: '2 Weeks', value: 14 },
        { text: '3 Weeks', value: 21 },
        { text: '1 Month', value: 28 },
        { text: '2 Month', value: 48 },
        { text: '3 Month', value: 60 },
        { text: 'Custom Range', value: 'Custom Range' }
      ],
      countries: CONSTANTS.COUNTRIES,
      shiftKeyOn: false,
      options: {
        sortBy: ['row'],
        sortDesc: [false]
      },
      order_tag_local: [],
      showAdvanceSearchLocal: false
    };
  },
  computed: {
    ...mapState(['filters', 'userStores', 'tags']),
    order_date_type_local: {
      get() {
        return this.searchOrderFilters.order_date_type;
      },
      set(order_date_type) {
        this.searchOrderFilters.order_date_type = order_date_type;

        this.searchOrderFilters.order_date_min = null;
        this.searchOrderFilters.order_date_max = null;

        if (order_date_type === 'Today') {
          this.searchOrderFilters.order_date_min =
            moment().format('YYYY-MM-DD');
          this.searchOrderFilters.order_date_max =
            moment().format('YYYY-MM-DD');
        } else if (order_date_type === 'Yesterday') {
          this.searchOrderFilters.order_date_min = moment()
            .subtract(1, 'days')
            .format('YYYY-MM-DD');

          this.searchOrderFilters.order_date_max =
            moment().format('YYYY-MM-DD');
          // this.searchOrderFilters.order_date_max = moment()
          //   .subtract(1, 'days')
          //   .format('YYYY-MM-DD');
        } else if (order_date_type > 0) {
          this.searchOrderFilters.order_date_min = moment()
            .subtract(order_date_type, 'days')
            .format('YYYY-MM-DD');
          this.searchOrderFilters.order_date_max =
            moment().format('YYYY-MM-DD');
        }
      }
    },
    country_code_type_local: {
      get() {
        return this.searchOrderFilters.country_code_type;
      },
      set(country_code_type) {
        this.searchOrderFilters.country_code_type = country_code_type;

        if (country_code_type == 'Domestic') {
          this.searchOrderFilters.country_code_inclusive = true;
          this.searchOrderFilters.country_code = ['CA'];
        } else if (country_code_type == 'US') {
          this.searchOrderFilters.country_code_inclusive = true;
          this.searchOrderFilters.country_code = ['US'];
        } else if (country_code_type == 'International') {
          this.searchOrderFilters.country_code_inclusive = false;
          this.searchOrderFilters.country_code = ['US', 'CA'];
        } else {
          this.searchOrderFilters.country_code = [];
        }
      }
    }
  },
  watch: {
    'userStores.updating'(oldVal, newVal) {
      if (oldVal === false && newVal === true) {
        this.$emit('apply-search');
      }
    },
    order_tag_local(newVal) {
      this.searchOrderFilters.order_tags = newVal;
    },
    showAdvanceSearchLocal() {
  
    // this.clear()
      // this.autoSearch()
    }
  },
  mounted() {
    this.showAdvanceSearchLocal = this.showAdvanceSearch
  },
  methods: {
    clear(){
      for (const key in this.searchOrderFilters) {
        this.searchOrderFilters[key] = null;
        this.order_tag_local = [];

        this.search()
    }
    },
    search() {
      this.$emit('apply-search');
    },
    autoSearch() {
      console.log('apply')
      clearTimeout(this.timer);
      var ms = 600;
      var self = this;
      this.options.page = 1;
      this.timer = setTimeout(function () {
        self.$emit('apply-search');
      }, ms);
    },
    updateStores(user_store_id = null) {
      this.$store.dispatch('userStores/updateStores', user_store_id);
    },
    getStoreName(item) {
      if(item.nickname == null) {
        return item.identifier;
      } else {
        return item.nickname;
      }
    },
  }
};
</script>
<style>
.very-dense .row.row--dense .v-input .v-input__control{
  height: 45px !important;
}

.very-dense .row.row--dense .v-input.error--text .v-input__control{
  height: auto !important;
}


</style>