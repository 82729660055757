<template>
  <StallionDialog
    v-model="show"
    persistent
    :width="800"
    :disabled="disabled"
    transition="dialog-bottom-transition"
    title="Save Preset"
    @keydown.esc="cancelEdit"
  >
    <template #content>
      <v-form v-if="shipment.data">
        <v-container fluid>
          <!-- Preset -->
          <v-row
            v-if="!isItemizedStorePreset"
            align="center"
            class="py-4"
          >
            <v-col cols="12">
              <h4 class="font-weight-bold">
                Preset
              </h4>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="shipment.preset_name"
                v-validate="'required'"
                :error-messages="errs.collect('preset_name')"
                :disabled="shipment.id ? true : false"
                data-vv-name="preset_name"
                data-vv-validate-on="blur"
                label="Preset Name"
                outlined
                dense
              />

              <input
                v-model="shipment.preset_key"
                type="hidden"
                data-vv-name="name"
              >
            </v-col>

            <v-col cols="6">
              <!-- $hotkeyType from mixin.js  -->
              <v-text-field
                v-model="shipment.hotkey"
                v-validate="'max:1'"
                :error-messages="errs.collect('hotkey')"
                data-vv-name="hotkey"
                :label="'Hotkey (' + $hotkeyType() + ' +)'"
                outlined
                dense
              />
            </v-col>
          </v-row>

          <v-row v-else>
            <v-col cols="12">
              <h4 class="font-weight-bold">
                Preset
              </h4>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <v-select
                v-model="shipment.data.import_store_key"
                v-validate="'required'"
                :error-messages="errs.collect('import_store_key')"
                data-vv-name="import_store_key"
                label="Import Key"
                :items="['sku', 'name', 'value']"
                outlined
                dense
              />
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <v-text-field
                v-model="shipment.data.import_store_value"
                v-validate="'required'"
                :error-messages="errs.collect('import_store_value')"
                data-vv-name="import_store_value"
                :label="
                  shipment.data.import_store_key == 'value'
                    ? 'Import Value must be exact amount or range. eg: 100-200'
                    : 'Import Value'
                "
                outlined
                dense
              />
            </v-col>
          </v-row>

          <v-row
            dense
          >
            <v-col cols="12">
              <h2>Package</h2>
            </v-col>

            <v-col
              cols="12"
              md="12"
            >
              <v-select
                v-model="shipment.data.package_type_id"
                v-validate="''"
                :error-messages="errs.collect('package_type')"
                data-vv-name="package_type"
                label="Package Type"
                :items="packageTypes"
                item-text="description"
                item-value="id"
                dense
                outlined
              />
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-text-field
                v-model="shipment.data.length"
                v-validate="''"
                :error-messages="errs.collect('length')"
                data-vv-name="length"
                label="Length"
                dense
                outlined
                type="number"
              />
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-text-field
                v-model="shipment.data.width"
                v-validate="''"
                :error-messages="errs.collect('width')"
                data-vv-name="width"
                label="Width"
                dense
                outlined
                type="number"
              />
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-text-field
                v-model="shipment.data.height"
                v-validate="''"
                :error-messages="errs.collect('height')"
                data-vv-name="height"
                label="Height"
                dense
                outlined
                type="number"
              />
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-select
                v-model="shipment.data.size_unit"
                v-validate="''"
                :error-messages="errs.collect('size_unit')"
                data-vv-name="size_unit"
                label="Unit"
                :items="['cm', 'in']"
                dense
                outlined
              />
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-text-field
                v-model="shipment.data.weight"
                v-validate="''"
                :error-messages="errs.collect('weight')"
                data-vv-name="weight"
                label="Weight"
                dense
                outlined
                type="number"
              />
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-select
                v-model="shipment.data.weight_unit"
                v-validate="''"
                :error-messages="errs.collect('weight_unit')"
                data-vv-name="weight_unit"
                label="Weight Unit"
                :items="['kg', 'g', 'oz', 'lbs']"
                dense
                outlined
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col
              v-for="(item, index) in shipment.data?.items"
              :key="index"
              cols="6"
            >
              <v-card
                outlined
                class="pa-5"
              >
                <v-row dense>
                  <v-col cols="6">
                    <strong>Line {{ index + 1 }}</strong>
                  </v-col>
                  <v-col
                    cols="6"
                    class="text-right"
                  >
                    <v-btn
                      color="error"
                      x-small
                      depressed
                      @click="removeItem(index)"
                    >
                      Remove
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.description"
                      v-validate="'required'"
                      outlined
                      dense
                      :error-messages="
                        errs.collect(`description_${index}`)
                      "
                      label="Description"
                      :data-vv-name="`description_${index}`"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="item.quantity"
                      v-validate="'required|min_value:1|integer'"
                      outlined
                      dense
                      :error-messages="errs.collect(`quantity_${index}`)"
                      label="Quantity"
                      :data-vv-name="`quantity_${index}`"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="item.value"
                      v-validate="'required|min_value:0.01'"
                      outlined
                      dense
                      :error-messages="errs.collect('value')"
                      label="Unit Value"
                      data-vv-name="value"
                    />
                  </v-col>
                  <v-col cols="4">
                    <!-- $currencyAsFlatArray() from mixin.js  -->
                    <v-select
                      v-model="item.currency"
                      v-validate="''"
                      :error-messages="errs.collect(`currency_${index}`)"
                      :data-vv-name="`currency_${index}`"
                      label="Currency"
                      :items="$currencyAsFlatArray()"
                      dense
                      outlined
                    />
                  </v-col>
                  <v-col
                    v-if="
                      !['US', 'CA'].includes(
                        shipment.data.country_code
                      )
                    "
                    cols="6"
                  >
                    <v-text-field
                      v-model="item.hs_code"
                      v-validate="''"
                      outlined
                      dense
                      :error-messages="errs.collect(`hs_code_${index}`)"
                      label="HS Code"
                      :data-vv-name="`hs_code_${index}`"
                    />
                  </v-col>
                  <v-col
                    v-if="
                      !['US', 'CA'].includes(
                        shipment.data.country_code
                      )
                    "
                    cols="6"
                  >
                    <v-autocomplete
                      v-model="item.country_of_origin"
                      v-validate="''"
                      outlined
                      dense
                      :error-messages="
                        errs.collect(`country_of_origin_${index}`)
                      "
                      :data-vv-name="`country_of_origin_${index}`"
                      :items="countries"
                      label="Country of Origin"
                      item-text="name"
                      item-value="code"
                    />
                  </v-col>
                </v-row>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              class="text-right"
            >
              <v-btn
                color="primary"
                small
                depressed
                class="float-right"
                @click="addItem"
              >
                Add Line
              </v-btn>
            </v-col>
          </v-row>

          <v-row
            dense
          >
            <v-col cols="12">
              <h2>Postage</h2>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-select
                v-model="shipment.data.needs_postage"
                v-validate="''"
                :error-messages="errs.collect('needs_postage')"
                data-vv-name="needs_postage"
                :items="[
                  { value: true, text: 'Yes' },
                  { value: false, text: 'No' }
                ]"
                label="Need Postage?"
                dense
                outlined
                @change="changePostage(shipment.data.needs_postage)"
              />
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="shipment.data.postage_type_id"
                v-validate="''"
                :error-messages="errs.collect('postage_type')"
                data-vv-name="postage_type"
                :items="postageTypes"
                item-text="description"
                item-value="id"
                label="Postage Type"
                dense
                outlined
              />
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="3"
            >
              <v-select
                v-model="shipment.data.is_fba"
                v-validate="''"
                :error-messages="errs.collect('is_fba')"
                data-vv-name="is_fba"
                :items="[
                  { value: true, text: 'Yes' },
                  { value: false, text: 'No' }
                ]"
                label="Is FBA?"
                dense
                outlined
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-select
                v-model="shipment.data.signature_confirmation"
                v-validate="''"
                :error-messages="errs.collect('signature')"
                data-vv-name="signature"
                :items="[
                  { value: true, text: 'Yes' },
                  { value: false, text: 'No' }
                ]"
                dense
                outlined
                label="Signature "
              />
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="4"
            >
              <v-select
                v-model="shipment.data.insured"
                v-validate="''"
                :error-messages="errs.collect('stallion_protection')"
                data-vv-name="stallion_protection"
                :items="[
                  { value: true, text: 'Yes' },
                  { value: false, text: 'No' }
                ]"
                dense
                outlined
                label="Stallion Protection "
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-divider />
    </template>

    <template #actions>
      <v-btn
        color="primary"
        class="px-0"
        text
        :disabled="remove_loading || !shipment.id"
        :loading="remove_loading"
        @click="remove"
      >
        <v-icon
          small
          left
        >
          mdi-trash-can-outline
        </v-icon>Delete Preset
      </v-btn>
      <v-spacer />
      <v-btn
        color="primary"
        class="px-7"
        :disabled="save_loading"
        :loading="save_loading"
        @click="save"
      >
        Save
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
import CONSTANTS from '@/constants';
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      default: false,
      type: Boolean
    },
    shipment: {
      default: null,
      type: Object
    },
    // determines if the preset is for import store eg - import-store-preset-index.vue
    isItemizedStorePreset: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      print_loading: false,
      dowload_loading: false,
      rate_loading: false,
      remove_loading: false,
      save_loading: false,
      countries: CONSTANTS.COUNTRIES,
      provinces: CONSTANTS.PROVINCES,
      states: CONSTANTS.STATES,

      postage_rates: [],
      postageTypes: [],
      searchInput2: null,
      stallion_protection_items: [
        {
          text: 'Yes',
          value: true
        },
        {
          text: 'No',
          value: false
        }
      ]
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    // TODO: confirm if we really need to convert the id's from int to string
    // eg: vue/store/modules/main.js@postage_types => postage.id.toString()
    // Right now the id's of package_types/postage_types are converted to string
    // this causes an issue on dropdown options. eg: the default values are empty
    packageTypes() {
      return this.$store.getters['main/package_types'].map((item) => {
        item.id = parseInt(item.id);
        return item;
      });
    },
  },

  watch: {
    shipment() {
      if (this.shipment) {
        this.$validator.reset();
        this.postage_rates = [];
      }
    }
  },

  created() {
    this.changePostage(true);
  },

  methods: {
    removeItem(index) {
      this.shipment.data.items.splice(index, 1);
    },
    addItem() {
      if(this.shipment.data.items === null) {
        this.shipment.data.items = [];
      }
      this.shipment.data.items.push({ ...CONSTANTS.SHIPMENT_ITEM });
    },
    /**
     * Cancel Edit just hide the popup component
     */
    cancelEdit() {
      this.show = false;
      // reload presets index if emit event wa specified.
      // eg -- vue/components/main/import/import-preset-index.vue
      this.$emit('reloadPresets');
    },

    /**
     * Saves a preset.
     * Generates preset key
     * then emit @save back to parent index.vue
     */
    async save() {
      // mixin.js
      if (!(await this.$validateAll())) {
        this.$notify({
          group: 'main',
          title: 'Error saving preset',
          text: 'Some of the fields are invalid!',
          duration: 5000,
          type: 'error'
        });
        return;
      }

      // check if save as itemized store preset
      // import-store-preset-index.vue
      if (this.isItemizedStorePreset) {
        this.$emit('save', this);
        return;
      }

      this.save_loading = true;
      this.buildSaveService()
        .then((response) => response.data)
        .then((response) => {
          this.$notify({
            group: 'main',
            title: 'Preset Saved',
            text: 'This preset has been saved from our system.',
            duration: 5000,
            type: 'success'
          });
          // console.log(response);
          this.shipment.id = response.preset.id;
          this.emitSave();
        })
        .catch((err) => {
          let errMsg = 'We could not save this preset.';
          if (err.response.status == 422 && err.response.data.message) {
            errMsg = err.response.data.message;
          }

          this.$notify({
            group: 'main',
            title: 'Error saving preset',
            text: errMsg,
            duration: 5000,
            type: 'error'
          });
        })
        .finally(() => {
          this.save_loading = false;
        });
    },

    buildSaveService() {
      if (!this.shipment.id) {
        return this.$http.post('/import/preset', this.shipment)
      }

      return this.$http.put(`/import/preset/${this.shipment.id}`, this.shipment);
    },

    /**
     * Emit save to the parent index.vue
     */
    emitSave() {
      // emit item
      this.$emit('save', this.shipment);
      this.show = false;
    },

    /**
     * Removes the preset
     */
    remove() {
      // check if remove as itemized store preset
      // import-store-preset-index.vue
      if (this.isItemizedStorePreset) {
        this.$emit('remove', this);
        return;
      }

      this.message = swal({
        title: 'Confirm Delete',
        text: 'Do you really want to remove this preset?',
        icon: 'warning',
        buttons: ['Cancel', 'Remove']
      });
      this.message.then(this.onConfirmRemove);
    },

    /**
     * Called on confirm remove.
     *
     * @param      {boolean}  isConfirm  Indicates if confirm
     * @return     {boolean}  Just returns
     */
    onConfirmRemove(isConfirm) {
      if (!isConfirm) {
        return;
      }

      if (!this.shipment.id) {
        this.emitRemove();
        return;
      }

      this.remove_loading = true;
      this.$http
        .delete(`/import/preset/${this.shipment.id}`)
        .then((response) => response.data)
        .then(() => {
          this.$notify({
            group: 'main',
            title: 'Preset Removed',
            text: 'This preset has been removed from our system.',
            duration: 5000,
            type: 'success'
          });
          this.emitRemove();
        })
        .catch(() => {
          this.$notify({
            group: 'main',
            title: 'Error removing preset',
            text: 'We could not remove this preset.',
            duration: 5000,
            type: 'error'
          });
        })
        .finally(() => {
          this.remove_loading = false;
        });
    },

    /**
     * Emits remove to parent index.vue
     */
    emitRemove() {
      // emit item
      this.$emit('remove', this.shipment);
      this.show = false;
    },
    //change the value of need postage and return the postages accordingly
    changePostage(val) {
      this.postageTypes = this.$store.getters['main/postage_types'](val,false).map((item) => {
        item.id = parseInt(item.id);
        return item;
      });
    }
    /**
     * END METHOD
     */
  }
  /**
   * END DEFAULT
   */
};
</script>

<style scoped>
h2 {
  margin-bottom: 0px !important;
}

h4 {
  margin-bottom: 0px !important;
}
</style>
