<template>
  <StallionDialog
    v-model="show"
    :width="500"
    persistent
    title="Recovery Codes"
  >
    <template #content>
      <v-row>
        <v-col cols="12">
          <h3>Recovery Codes.</h3>
          <p>
            Here are your backup codes that you can enter to sign in. Keep
            these backup codes somewhere safe but accessible.
          </p>
          <ul>
            <li
              v-for="recoverCode in recoveryCodes"
              :key="recoverCode"
            >
              {{ recoverCode }}
            </li>
          </ul>
          <v-btn
            class="mt-3"
            outlined
            small
            color="primary"
            @click="copyCodes"
          >
            {{ btnText }}
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  name: 'TwoFactorRecoveryCodesDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    recoveryCodes: {
      type: Array,
      default: () => {
        [];
      }
    }
  },
  data() {
    return {
      btnText: 'Copy'
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    copyCodes() {
      let self = this;
      const copyText = this.recoveryCodes.join(', ');
      /* Copy the text inside the text field */
      navigator.clipboard.writeText(copyText).then(() => {
        self.$notify({
          group: 'main',
          title: 'Copied!',
          text: 'Backup codes have been copied!',
          duration: 2000,
          type: 'info'
        });
        self.btnText = 'Copied!';
      });
    }
  }
};
</script>
