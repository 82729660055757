<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-if="$auth.authenticated"
      v-model="drawer"
      :clipped="$vuetify.breakpoint.mdAndUp"
      fixed
      app
      width="220"
    >
      <v-list>
        <!-- new shipment -->
        <v-list-item @click="newShipment">
          <v-list-item-action>
            <v-icon>mdi-package-variant</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>New Shipment</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- import -->
        <v-list-item
          router
          to="/import"
        >
          <v-list-item-action>
            <v-icon>mdi-import</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Import</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- closeout -->

        <v-list-group
          v-model="dropdown"
          :prepend-icon="
            dropdown ? 'keyboard_arrow_down' : 'mdi-package-variant-closed'
          "
          append-icon
          router
          :to="'/closeout'"
        >
          <v-list-item
            router
            :to="'/closeout'"
            v-on="on"
          >
            <v-list-item-content>
              <v-list-item-title>Closeout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- ALL -->
          <v-list-item
            router
            to="/closeouts"
          >
            <v-list-item-action>
              <v-icon>mdi-package-variant-closed</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Ready</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <template v-for="item in items">
          <v-layout
            v-if="item.heading"
            :key="item.heading"
            align-center
          >
            <v-flex xs6>
              <v-subheader v-if="item.heading">
                {{ item.heading }}
              </v-subheader>
            </v-flex>
            <v-flex
              xs6
              class="text-center"
            >
              <a
                href="#!"
                class="text-body-2 black--text"
              >EDIT</a>
            </v-flex>
          </v-layout>
          <v-list-group
            v-else-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon
            router
            :to="item.to"
          >
            <v-list-item
              router
              :to="item.to"
              v-on="on"
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              router
              :to="child.to"
              @click="null"
            >
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ child.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            :key="item.text"
            router
            :to="item.to"
            @click="null"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <!-- RETURNS -->
        <v-list-group
          v-model="itemR.model"
          :prepend-icon="
            itemR.model ? 'keyboard_arrow_down' : 'mdi-keyboard-backspace'
          "
          append-icon
          router
          :to="'/returns'"
        >
          <v-list-item
            router
            :to="'/returns'"
            v-on="on"
          >
            <v-list-item-content>
              <v-list-item-title>Returns</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- ALL -->
          <v-list-item
            router
            exact
            :to="{ name: 'user-return-index' }"
          >
            <v-list-item-action>
              <v-icon>mdi-format-list-bulleted</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>All</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Action Required -->
          <v-list-item
            router
            exact
            :to="{
              name: 'user-return-index',
              query: { action: 'action-required' }
            }"
          >
            <v-list-item-action>
              <v-icon>mdi-package</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Action Required</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Return Requested -->
          <v-list-item
            router
            exact
            :to="{
              name: 'user-return-index',
              query: { action: 'return-requested' }
            }"
          >
            <v-list-item-action>
              <v-icon>mdi-check</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Return Requested</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Disposed -->
          <v-list-item
            router
            exact
            :to="{ name: 'user-return-index', query: { action: 'dispose' } }"
          >
            <v-list-item-action>
              <v-icon>mdi-delete</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Dispose</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Orders -->
          <v-list-item
            router
            exact
            to="/orders"
          >
            <v-list-item-action>
              <v-icon>mdi-clipboard-text</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Orders</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!-- pickup -->
        <v-list-item @click="pickupRequest">
          <v-list-item-action>
            <v-icon>mdi-truck</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Pickup Request</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- track -->
        <v-list-item
          exact
          router
          to="/track"
        >
          <v-list-item-action>
            <v-icon>mdi-crosshairs-gps</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Track</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider />

        <v-list-item
          exact
          router
          to="/credits"
        >
          <v-list-item-action>
            <v-icon>mdi-credit-card</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Credits</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- settings -->
        <v-list-item
          exact
          router
          to="/dashboard"
        >
          <v-list-item-action>
            <v-icon>mdi-settings</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-toolbar
      :clipped-left="$vuetify.breakpoint.mdAndUp"
      :clipped-right="$vuetify.breakpoint.mdAndUp"
      color="white"
      height="75"
      app
      fixed
    >
      <v-toolbar-title
        style="width: 300px"
        class="ml-0 pl-4"
      >
        <v-app-bar-nav-icon
          v-if="$auth.authenticated"
          @click.stop="drawer = !drawer"
        />
        <!-- <span class="hidden-sm-and-down">Google Contacts</span> -->
      </v-toolbar-title>
      <a
        class="navbar-logo"
        href
      >
        <span class="logo d-md-none d-lg-block" />
      </a>
      <v-spacer />

      <div v-if="$auth.authenticated">
        <v-tooltip
          v-if="$auth.user.credits > 0"
          bottom
        >
          <router-link
            class="header-icon btn btn-empty"
            to="/credits"
            aria-expanded="false"
            v-on="on"
          >
            <v-btn
              depressed
              color="white"
              pa-0
            >
              {{ $auth.user.credits | currency }}
            </v-btn>
          </router-link>
          <span>Add Credits</span>
        </v-tooltip>

        <v-tooltip
          v-else-if="$auth.user.credits < 0"
          bottom
        >
          <router-link
            class="header-icon btn btn-empty"
            to="/credits"
            aria-expanded="false"
            v-on="on"
          >
            <v-btn
              depressed
              color="white"
              pa-0
            >
              <span style="color: red">{{
                $auth.user.credits | currency
              }}</span>
            </v-btn>
          </router-link>
          <span>Add Credits</span>
        </v-tooltip>

        <router-link
          v-else
          class="header-icon btn btn-empty"
          to="/credits"
          aria-expanded="false"
        >
          <v-btn
            depressed
            color="white"
            pa-0
          >
            Add Credits
          </v-btn>
        </router-link>
        <v-menu
          v-if="$store.state.main.notifications.length > 0"
          bottom
          open-on-hover
        >
          <v-btn
            icon
            class="mr-12"
            v-on="on"
          >
            <v-badge color="primary">
              <span
                v-if="$store.state.main.unread > 99"
                slot="badge"
              >99</span>
              <span
                v-else-if="$store.state.main.unread > 0"
                slot="badge"
              >{{
                $store.state.main.unread
              }}</span>
              <v-icon>notifications</v-icon>
            </v-badge>
          </v-btn>

          <v-list
            subheader
            two-line
            dense
          >
            <v-subheader>Notifications</v-subheader>
            <template
              v-for="(notification,
                      index) in $store.state.main.notifications.slice(0, 5)"
            >
              <v-list-item
                :key="notification.id"
                ripple
                router
                to="/notifications"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ notification.title }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ notification.message }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text>
                    {{ notification.created_at | datetime_from }}
                  </v-list-item-action-text>
                  <v-spacer />
                  <v-spacer />
                </v-list-item-action>
              </v-list-item>

              <v-divider
                v-if="index + 1 < notifications.length"
                :key="index"
              />
            </template>
          </v-list>
        </v-menu>
      </div>
    </v-toolbar>

    <v-layout
      align-center
      class="mt-4 mb-4"
    >
      <v-flex
        xs12
        text-center
      >
        <router-link
          to="/notifications"
          aria-expanded="false"
        >
          View All
        </router-link>
      </v-flex>
    </v-layout>

    <!-- <v-badge color="primary">
      <span slot="badge" small>2</span>
      <v-icon
      large
        color="grey"
      >
        notifications
      </v-icon>
    </v-badge>-->

    <v-menu
      bottom
      open-on-hover
    >
      <v-btn
        fab
        depressed
        small
        v-on="on"
      >
        <!-- <span class="name mr-1">  </span> -->
        <!-- <i style="vertical-align: middle; font-size: 36px;" class="mdi mdi-account-circle"></i>
        -->
        <v-avatar
          color="secondary"
          size="40"
        >
          <span
            class="white--text text-h3"
            style="font-size: 20px  !important"
          >{{ $store.state.main.initials }}</span>
        </v-avatar>
      </v-btn>

      <v-list>
        <v-list-item
          exact
          router
          to="/account-settings"
        >
          <v-list-item-action>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-action>
          <v-list-item-title>Account</v-list-item-title>
        </v-list-item>

        <v-list-item
          exact
          router
          to="/credits"
        >
          <v-list-item-action>
            <v-icon>mdi-credit-card</v-icon>
          </v-list-item-action>
          <v-list-item-title>Credits</v-list-item-title>
        </v-list-item>

        <v-list-item
          exact
          router
          to="/invoices"
        >
          <v-list-item-action>
            <v-icon>mdi-file-document</v-icon>
          </v-list-item-action>
          <v-list-item-title>Invoices</v-list-item-title>
        </v-list-item>

        <!-- <v-list-item exact router to="/faq">
            <v-list-item-action><v-icon>mdi-comment-question-outline</v-icon></v-list-item-action>
            <v-list-item-title> FAQ  </v-list-item-title>
        </v-list-item>-->
        <v-divider />
        <v-list-item
          exact
          router
          to="/dashboard"
        >
          <v-list-item-action>
            <v-icon>mdi-settings</v-icon>
          </v-list-item-action>
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="can('access.admin')"
          exact
          router
          to="/admin"
        >
          <v-list-item-action>
            <v-icon>mdi-lock-open</v-icon>
          </v-list-item-action>
          <v-list-item-title>Admin Panel</v-list-item-title>
        </v-list-item>

        <v-list-item @click="logout()">
          <v-list-item-action>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-main>
      <slot />
    </v-main>

    <newShipmentModal />
    <fba-pickup-request v-if="$auth.authenticated" />
  </v-app>
</template>

<script>
export default {
  props: {
    source: {
      type: String,
      default: null
    }
  },
  data: () => ({
    unread: 0,
    notifications: [],
    first_letter: '',
    second_letter: '',
    dialog: false,
    drawer: null,
    dropdown: null,
    itemsN: [
      {
        header: 'Notifications'
      },
      {
        avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
        title: 'Brunch this weekend?',
        subtitle:
          "<span class='text--primary'>Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?"
      },
      {
        divider: true,
        inset: true
      },
      {
        avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
        title: 'Summer BBQ <span class="grey--text text--lighten-1">4</span>',
        subtitle:
          "<span class='text--primary'>to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend."
      },
      {
        divider: true,
        inset: true
      },
      {
        avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
        title: 'Oui oui1',
        subtitle:
          "<span class='text--primary'>Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?"
      },
      {
        divider: true,
        inset: true
      },
      {
        avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
        title: 'Oui oui2',
        subtitle:
          "<span class='text--primary'>Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?"
      },
      {
        divider: true,
        inset: true
      },
      {
        avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
        title: 'Oui oui3',
        subtitle:
          "<span class='text--primary'>Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?"
      }
    ],
    menu1: [
      {
        icon: 'mdi-account-circle',
        to: '/account-settings',
        text: 'Account'
      },
      {
        icon: 'mdi-credit-card',
        to: '/credits',
        text: 'Credits'
      },
      {
        icon: 'mdi-file-document',
        to: '/invoices',
        text: 'Invoices'
      },
      // {
      //     icon: "mdi-comment-question-outline",
      //     to: '/faq',
      //     text: "FAQ"
      // },
      {
        icon: 'mdi-settings',
        to: '/dashboard',
        text: 'Settings'
      },
      {
        icon: 'mdi-lock-open',
        to: '/admin',
        text: 'Admin Panel'
      },
      {
        icon: 'mdi-logout',
        to: '/logout',
        text: 'Logout'
      }
    ],
    itemR: {
      model: false
    },
    items: [
      {
        icon: 'keyboard_arrow_up',
        'icon-alt': 'keyboard_arrow_down',
        text: 'View Shipments',
        to: '/shipments',
        model: false,
        children: [
          {
            icon: 'mdi-format-list-bulleted',
            text: 'All',
            to: '/shipments/all'
          },
          {
            icon: ' mdi-archive',
            text: 'Batches',
            to: '/batches'
          },
          {
            icon: 'mdi-circle-edit-outline',
            text: 'Incomplete',
            to: '/shipments/incomplete'
          },
          {
            icon: 'mdi-pause-circle-outline',
            text: 'Pending',
            to: '/shipments/pending'
          },
          {
            icon: 'mdi-cube-outline',
            text: 'Ready',
            to: '/shipments/ready'
          },
          {
            icon: 'mdi-marker-check',
            text: 'Received',
            to: '/shipments/received'
          },
          {
            icon: 'mdi-cube-send',
            text: 'In Transit',
            to: '/shipments/in-transit'
          },
          {
            icon: 'mdi-check',
            text: 'Complete',
            to: '/shipments/complete'
          },
          {
            icon: 'mdi-alert-circle-outline',
            text: 'Exception',
            to: '/shipments/exception'
          },
          {
            icon: 'mdi-select-off',
            text: 'Voided',
            to: '/shipments/voided'
          }
        ]
      }
      //    {
      //         icon: "keyboard_arrow_up",
      //         "icon-alt": "keyboard_arrow_down",
      //         text: "Returns",
      //         to: '/returns',
      //         model: false,
      //         children: [
      //             {
      //                 icon: "mdi-format-list-bulleted",
      //                 text: "All",
      //                 to: "{name: 'user-return-index', params: { filter: all }}"
      //             },
      //             {

      //                 icon: "mdi-package",
      //                 text: "Action Required",
      //                  to: "/returns/action-required"
      //             },

      //             {
      //                 icon: "mdi-check",
      //                 text: "Return Requested",
      //                 to: "/returns/return-requested"
      //             },
      //             {
      //                 icon: "mdi-delete",
      //                 text: "Disposed",
      //                 to: "/returns/disposed"
      //             },
      //               {
      //                 icon: "mdi-clipboard-text",
      //                 text: "Orders",
      //                 to: "/returns/orders"
      //             },]
      //    }
    ]
  }),
  mounted() {
    Event.listen('payment-completed', () => {
      this.getUser();
    });

    Event.listen('get-credits', () => {
      this.$http.get('/auth/user').then((res) => {
        if (this.$auth.user !== null) {
          this.$auth.user.credits = res.data.data.credits;
        }
      });
    });
  },
  methods: {}
};
</script>
