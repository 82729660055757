<template>
  <StallionDialog
    v-model="show"
    :width="500"
    :disabled="shipments.length == 0"
    title="Approve Void"
  >
    <template #content>
      <v-layout column>
        <v-flex
          xs12
        >
          <div class="dialog-info">
            You are about to approve the void of {{ shipments.length }}
            {{ shipments.length | pluralize(' shipment') }}
          </div>
        </v-flex>

        <!-- <div v-if="delete_loading"> -->
        <!-- <v-progress-linear v-model="valueDeterminate"></v-progress-linear>

        <v-flex xs12 text-center
          >{{ number }} of {{ shipments.length }}</v-flex
        > -->
        <!-- </div> -->
      </v-layout>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        :loading="delete_loading"
        :disabled="delete_loading"
        @click="remove()"
      >
        <v-icon
          small
          dark
        >
          mdi-select-off
        </v-icon>Void
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    shipments: {
      type: Array,
      default: () => []
    },

    disabled: {
      default: false,
      type: Boolean
    }
  },

  data() {
    return {
      show: false,

      delete_loading: false,

      number: 0
    };
  },
  // computed: {
  //   valueDeterminate() {
  //     if (this.number > 0)
  //       return Math.round((this.number / this.shipments.length) * 100);
  //     else return 0;
  //   }
  // },
  methods: {
    openDialog() {
      this.show = true;
    },
    remove: async function() {
      console.log('remove');
      this.delete_loading = true;

      var ids = [];
      this.shipments.forEach(function(shipment) {
        ids.push(shipment.id);
      });
      this.$http
        .post('/admin/voids/approve', ids)
        .then(() => {})
        .catch(() => {
        })
        .finally(() => {
          this.delete_loading = false;
          this.show = null;
          this.number = 0;
          Event.fire('get-credits');
          Event.fire('admin-update-shipments');
        });
    }
  }
};
</script>
