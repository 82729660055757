<template>
  <v-dialog
    v-model="show"
    width="500"
    :disabled="disabled"
    class="dialog-slot"
  >
    <slot v-on="on" />
  
    <v-card>
      <v-form @submit.prevent>
        <v-card-title
          class="text-h5"
          primary-title
        >
          Bandcamp
        </v-card-title>
  
        <v-divider />
  
        <v-card-text>
          <v-layout column>
            <v-flex xs12>
              <strong>Step 1:</strong>
              <p>
                To connect Stallion Express to your Bandcamp account, you will need to go to your Bandcamp Orders Page and click 
                'Give a fulfillment partner access to this page.' In the e-mail box enter <strong>dev@stallionexpress.ca</strong>
              </p>
              <br>
              <strong>Step 2:</strong>
              <p>Enter your Band ID below and click 'Connect to Bandcamp' to complete the connection.</p>
              <!-- <br/>
                <p><strong>Finding Your Band URL:</strong> Click 'View Site' in your Bandcamp user menu. Copy the URL displayed in your browser.</p>  
                <p>Example: <strong>https://stallionexpress.bandcamp.com</strong></p> -->


              <br>
              <p><strong>Finding Your Band ID:</strong> Click the 'Edit Profile' button in your Bandcamp user menu. The URL will show your band id which you can copy paste</p>
              <p>Example: https://bandcamp.com/profile?id=<strong>1234567890</strong></p>
            </v-flex>
            <br>

            <v-flex xs12>
              <v-text-field
                v-model="bandId"
                v-validate="'required'"
                :error-messages="errs.collect('bandId')"
                label="Your Band ID"
                data-vv-name="bandId"
              />
            </v-flex>
          </v-layout>
        </v-card-text>
  
        <v-divider />
  
        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="show = false"
          >
            Close
          </v-btn>
          <v-spacer />
  
          <v-btn
            type="submit"
            color="primary"
            :loading="isSubmitting"
            :disabled="isSubmitting"
            @click="connect()"
          >
            Connect
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
  
  <script>
  export default {
    props: {
      value: {
        type: Boolean,
        default: false
      },
      disabled: {
        default: false,
        type: Boolean
      }
    },
  
    data() {
      return {  
        bandId: null,
        isSubmitting: false
      };
    },
    computed: {
      show: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      }
    },
    methods: {
      reset() {
        this.$validator.reset();
        this.isSubmitting = false;
      },
      close() {
        this.$emit('update:show', false);
      },
      async validate() {
        return await this.$validator.validate().then((result) => {
          return result ? true : false;
        });
      },
      async connect() {
        if (!(await this.validate())) return;
  
        this.isSubmitting = true;
        this.$http
          .post('/stores/bandcamp', {
            band_id: this.bandId
          })
          .then((response) => response.data)
          .then(() => {
            this.$store.dispatch('userStores/getStores');
            this.show = false;
            this.$notify({
              group: 'main',
              title: 'Store added',
              text: 'Your account for Bandcamp has been added successfully.',
              duration: 5000,
              type: 'success'
            });
  
            this.reset();
          })
          .catch((err) => {
            if (err.response && err.response.status === 422) {
              this.$notify({
                group: 'main',
                title: 'Invalid Credentials',
                text: err.response.data.errors,
                duration: 5000,
                type: 'error'
              });
            } else {
              this.$notify({
                group: 'main',
                title: 'Error adding Bandcamp',
                text: err,
                duration: 5000,
                type: 'error'
              });
            }
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      }
    }
  };
  </script>
  