<template>
  <StallionDialog
    v-model="dialog"
    persistent
    :width="800"
    :title="edit ? 'Edit Filter' : 'Create Filter'"
  >
    <template #content>
      <v-container>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="filter.name"
              outlined
              dense
              label="Filter Name"
              required
            />
          </v-col>
          <v-col cols="12">
            <v-divider />
          </v-col>
        </v-row>
        <v-row
          v-for="(criterion, index) in filter.filter_criteria"
          :key="index"
          dense
        >
          <v-col v-if="filter.filter_criteria[index].delete !== true">
            <criteria v-bind.sync="filter.filter_criteria[index]" />
          </v-col>
          <v-col
            v-if="filter.filter_criteria[index].delete !== true"
            cols="auto"
          >
            <v-btn
              color="primary"
              icon
              @click="removFilter(index, criterion)"
            >
              <v-icon dark>
                mdi-minus
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-btn
              color="primary"
              outlined
              @click="addCriteria()"
            >
              <v-icon>mdi-plus</v-icon>Add Criteria
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template #actions>
      <v-spacer />
      <v-btn
        v-if="edit"
        color="primary"
        :loading="loading"
        @click="updateFilter()"
      >
        Update
      </v-btn>
      <v-btn
        v-else
        color="primary"
        :loading="loading"
        @click="saveFilter()"
      >
        Save
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
import Criteria from './Criteria.vue';
import filters from '../../../shared/filters';
export default {
  components: {
    Criteria
  },
  data() {
    return {
      filter: filters.defaultFilter(),
      edit: false,
      dialog: false,
      loading: false
    };
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.$emit('update-filters');
      this.$store.dispatch('filters/getFilters');
    },
    startNew() {
      this.dialog = true;
      this.filter = Object.assign({}, filters.defaultFilter());
      this.edit = false;
    },
    startEdit(filter) {
      this.dialog = true;
      this.filter = Object.assign(filters.defaultFilter(), filter);
      this.edit = true;
    },
    addCriteria() {
      this.filter.filter_criteria.push({
        id: null,
        field: null,
        comparator: null,
        value: null
      });
    },
    saveFilter() {
      this.loading = true
      this.$http
        .post(`/filters`, this.filter)
        .then(() => {
          this.closeDialog();
        })
        .catch(err => {
          if (
            err.response.status === 422 &&
            typeof err.response.data.errors !== 'undefined'
          ) {
            const errors = err.response.data.errors;
            let message = '';

            for (var field in errors) {
              message += `${errors[field][0]}\n`;
            }

            this.errorMessage(message);
          }
        })
        .finally(() => {
          this.loading = false
        });
    },
    updateFilter() {
      this.loading = true
      this.$http
        .put(`/filters/${this.filter.id}`, this.filter)
        .then(() => {
          this.closeDialog();
        })
        .catch(err => {
          if (
            err.response.status === 422 &&
            typeof err.response.data.errors !== 'undefined'
          ) {
            const errors = err.response.data.errors;
            let message = '';

            for (var field in errors) {
              message += `${errors[field][0]}\n`;
            }

            this.errorMessage(message);
          }
        })
        .finally(() => {
          this.loading = false
        });
    },
    removFilter(index, criterion) {
      if (criterion.id === null) {
        this.filter.filter_criteria.splice(index, 1);
      } else {
        this.$set(this.filter.filter_criteria[index], 'delete', true);
      }
    }
  }
};
</script>
