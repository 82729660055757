<template>
  <div>
    <v-layout wrap>
      <v-flex xs12>
        <v-form>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="4"
                style="padding: 0 5px"
              >
                <v-text-field
                  v-model="search"
                  pt-0
                  outlined
                  dense
                  label="Search"
                  prepend-inner-icon="search"
                  clearable
                  class="pa-0"
                  @input="submitSearch()"
                />
              </v-col>

              <v-col
                cols="12"
                md="4"
                style="padding: 0 5px"
              >
                <v-autocomplete
                  v-model="user_id"
                  prepend-inner-icon="mdi-pound-box"
                  :items="userIDs"
                  :search-input.sync="searchID"
                  hide-selected
                  outlined
                  dense
                  label="User ID"
                  clearable
                  class="pa-0"
                  @change="submitSearch()"
                />
              </v-col>

              <v-col
                cols="12"
                md="4"
                style="padding: 0 5px"
              >
                <v-autocomplete
                  v-model="email"
                  prepend-inner-icon="email"
                  :items="userEmails"
                  :search-input.sync="searchEmail"
                  hide-selected
                  outlined
                  dense
                  label="Email"
                  clearable
                  class="pa-0"
                  @change="submitSearch()"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-flex>

      <v-flex xs12>
        <v-data-table
          v-model="selected"
          class="table-striped"
          :headers="headers"
          :loading="loading"
          show-select
          item-key="id"
          :items="items"
          :options.sync="options"
          :server-items-length="totalItems"
          :footer-props="{
            'items-per-page-options': [50, 100, 200, 500]
          }"
          :items-per-page="25"
        >
          <template #item.content_photo="{ item }">
            <div class="mt-3 mb-3">
              {{ item.unique_id }}
              <v-img
                aspect-ratio="1"
                contain
                height="100"
                width="100"
                :src="item.thumbnail_image_url"
              >
                <v-layout
                  slot="placeholder"
                  fill-height
                  align-center
                  justify-center
                  ma-0
                >
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  />
                </v-layout>
              </v-img>
            </div>
          </template>
          <template #item.status_id="{ item }">
            <div v-html="$options.filters.return_status(item.status_id)" />
          </template>

          <template #item.service_id="{ item }">
            <div v-html="$options.filters.return_service(item.service_id)" />
          </template>

          <template #item.created_at="{ item }">
            {{ item.created_at | date_pretty }}
            <br>
            ({{ item.created_at | datetime_from }})
          </template>

          <template #item.invoice="{ item }">
            <v-btn
              v-if="item.support_document_url"
              icon
              color="primary"
              target="_blank"
              :href="item.support_document_url"
              @click.stop
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>

          <template #item.user_id="{ item }">
            <router-link
              router
              :to="{ name: 'admin-user-show', params: { id: item.user_id } }"
            >
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <span v-on="on">
                    {{ item.user.id }}
                    <br>
                    {{ item.user.email }}
                  </span>
                </template>
                <span>View User</span>
              </v-tooltip>
            </router-link>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    filter: {
      type: String,
      default: null
    },
    tripNumber: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      search: null,
      start_date: null,
      end_date: null,
      loading: false,
      menu1: null,
      menu2: null,
      timer: null,
      users: [],
      userIDs: [],
      userEmails: [],
      user_id: null,
      email: null,
      searchID: null,
      searchEmail: null,

      headers: [
        {
          text: 'Image',
          sortable: false,
          value: 'content_photo'
        },
        {
          text: 'Description',
          value: 'item'
        },
        {
          text: 'Value',
          value: 'value'
        },
        {
          text: 'Email',
          value: 'user_id',
          sortable: false
        },
        {
          text: 'Status',
          value: 'status_id'
        },
        {
          text: 'Service',
          value: 'service_id'
        },
        {
          text: 'Created At',
          value: 'created_at'
        },
        {
          text: 'Order #',
          value: 'order_id'
        },
        {
          text: 'Document',
          value: 'invoice'
        }
      ],
      options: {
        sortBy: ['created_at'],
        sortDesc: [true]
      },
      totalItems: 0,
      items: [],
      cancelSource: null
    };
  },

  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },

  watch: {
    user_id: function(val) {
      if (val) {
        var index = this.users.map(e => e.id).indexOf(val);

        this.email = this.users[index].email;
      } else {
        this.email = null;
      }
    },
    email: function(val) {
      if (val) {
        var index = this.users.map(e => e.email).indexOf(val);

        this.user_id = this.users[index].id;
      } else {
        this.user_id = null;
      }
    },
    filter: function() {
      this.options.page = 1;
      this.updateTable();
    },
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    }
  },

  mounted() {
    this.getReturnStatuses();
    Event.listen('update-admin-returns', () => {
      this.updateTable();
    });
  },

  methods: {
    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.options.page = 1;
      this.timer = setTimeout(function() {
        self.updateTable();
      }, ms);
    },
    updateTable() {
      this.$store.state.main.selectedBatches = [];
      this.totalItems = 0;
      this.items = [];

      this.getDataFromApi().then(data => {
        this.items = data.items;
        this.totalItems = data.total;
      });
    },
    getReturnStatuses() {
      this.$http
        .get('/return-statuses')
        .then(response => response.data)
        .then(response => {
          this.statuses = response;
        })
        .catch(() => {});
    },
    updateTabletatus(item, approve) {
      var r = item;
      if (approve === true) {
        r.status_id = 10;
        r.status.description = 'Ready';
      } else {
        r.status_id = 1;
        r.status.description = 'Pending Details';
        r.item = null;
        r.value = null;
      }

      this.$http
        .put(`/admin/returns/${r.id}`, r)
        .then(response => response.data)
        .then(response => {
          item = response;
          Event.fire('update-admin-returns');
        })
        .finally(() => {
          // this.update_loading = false;
          // this.show = false;
        });
    },

    getDataFromApi() {
      this.loading = true;
      return new Promise(resolve => {
        const { sortBy, sortDesc, itemsPerPage } = this.options;

        let items = [];
        let total = 0;
        let pages = 0;

        var params = {
          per_page: itemsPerPage,
          sort_by: sortBy[0],
          desc: sortDesc[0],
          search: this.search,
          start_date: this.start_date,
          end_date: this.end_date,
          user_id: this.user_id,
          trip_number: this.tripNumber
        };

        if (this.filter == 'pending-details') {
          params.status_id = 1;
        } else if (this.filter == 'ready') {
          params.status_id = 10;
        } else if (this.filter == 'awaiting-review') {
          params.status_id = 5;
        } else if (this.filter == 'in-transit') {
          params.status_id = 25;
        } else if (this.filter == 'in-canada') {
          params.status_id = 30;
        } else if (this.filter == 'complete') {
          params.status_id = 35;
        } else if (this.filter == 'hold') {
          params.status_id = 3;
        } else if (this.filter == 'us-disposal') {
          params.status_id = 15;
        } else if (this.filter == 'sorted') {
          params.status_id = 20;
        } else if (this.filter == 'photo-requested') {
          params.images_required = true;
        }

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();
        this.$http
          .request(`/admin/returns`, {
            method: 'get',
            params: params,
            cancelToken: this.cancelSource.token
          })

          .then(response => response.data)
          .then(response => {
            items = response.data;
            total = response.total;
            pages = response.last_page;

            this.loading = false;
            resolve({
              items,
              total,
              pages
            });
          });
      });
    }
  }
};
</script>
