<template>
  <div>
    <v-list-item
      v-show="showDisabled || can(permission) || canAccessRoute(to.name)"
      :disabled="showDisabled && !(can(permission) || canAccessRoute(to.name))"
      router
      exact
      :dense="dense"
      :class="{ 'v-list-item--active': to.name && $route.name == to.name}"
      @click="clickSubmit"
    >
      <v-list-item-icon v-if="iconPosition == 'left'">
        <v-icon small>
          {{ icon }}
        </v-icon>
      </v-list-item-icon>
      <v-list-item-title>
        {{ text }}
      </v-list-item-title>
      <v-list-item-icon v-if="iconPosition == 'right'">
        <v-icon small>
          {{ icon }}
        </v-icon>
      </v-list-item-icon>
    </v-list-item>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: null
    },
    permission: {
      type: String,
      default: null
    },
    to: {
      type: Object,
      default: () => ({
        name: null
      })
    },
    click: {
      type: Function,
      default: null
    },
    href: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    dense: {
      type: Boolean,
      default: false
    },
    showDisabled: {
      type: Boolean,
      default: true
    },
    iconPosition: {
      type: String,
      default: 'left'
    }
  },
  data() {
    return {};
  },
  methods: {
    clickSubmit() {
      if (this.click) {
        this.click();
      } else if (this.to?.name) {
        this.$router.push(this.to);
      } else if (this.href) {
        window.open(this.href, '_blank');
      }
    }
  }
};
</script>
