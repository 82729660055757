<template>
  <FullPageLayout
    :title="`Notifications`"
  >
    <template #subtitle>
      Sorted by most recent notifications.
    </template>
    <template #default>
      <v-progress-linear
        v-if="initialLoading"
        color="primary"
        indeterminate
      />

      <v-row>
        <v-col
          md="6"
        >
          <v-radio-group v-model="radioGroup">
            <v-radio
              label="Account Notifications"
              :value="1"
            />
            <v-radio
              label="General Announcements"
              :value="2"
            />
          </v-radio-group>
        </v-col>

        <v-col
          v-if="radioGroup == 1"
          class="mt-4"
          md="6"
        >
          <div>
            <button
              :disabled="$store.state.main.unread == 0 || notifications.length == 0"
              @click="markAllAsRead"
            >
              <v-icon v-if="$store.state.main.unread > 0">
                mdi-checkbox-blank-circle-outline
              </v-icon>
              <v-icon
                v-else
                color
              >
                mdi-checkbox-marked-circle-outline
              </v-icon>

              Mark all As Read
            </button>
          </div>

          <div class="mt-n2">
            <v-checkbox
              v-model="showArchived"
              label="Show Archived"
              @change="getNotifications"
            >
              Show Archived
              <v-icon v-if="$store.state.main.unread > 0">
                mdi-checkbox-blank-circle-outline
              </v-icon>
              <v-icon
                v-else
                color
              >
                mdi-checkbox-marked-circle-outline
              </v-icon>
            </v-checkbox>
          </div>
        </v-col>
        
        <v-col
          cols="12"
        >
          <v-list
            v-if="radioGroup == 1"
          >
            <NotificationGroup
              :group="notifications"
              :type="'notification'"
              :update="getNotifications"
            />
          </v-list>
          <v-list
            v-else
          >
            <NotificationGroup
              :group="$store.getters['main/announcements'].announcements.data"
              :type="'announcement'"
            />
          </v-list>
        </v-col>
      </v-row>
    </template>
  </FullPageLayout>
</template>

<script>
import NotificationGroup from './NotificationGroup.vue'
import moment from 'moment';

export default {
  metaInfo: {
    title: "Notifications | Stallion Express"
  },
  components:{
    NotificationGroup
  },
  data() {
    return {
      notifications: [],
      showArchived: false,
      announcements: [],
      pages: 0,
      current_page: 1,
      total: 0,
      loading: true,
      initialLoading: true,
      options: {},
      radioGroup: 1,
      headers: [
        {
          text: '',
          align: 'left',
          value: 'message',
          sortable: false
        }
      ]
    };
  },
  mounted() {
    this.getNotifications();
    this.announcements = this.$store.getters['main/announcements'];
    console.log('Announcements: ', this.announcements);
  },
  methods: {
    paginate(page) {
      this.current_page = page;
      if (this.radioGroup == 1) {
        this.getNotifications();
      } else {
        this.getAnnouncements();
      }
    },
    getNotifications() {
      this.loading = true;
      if (this.cancelSource) {
        this.cancelSource.cancel('Start new search, stop active search');
      }
      this.cancelSource = axios.CancelToken.source();
      var params = {
        all: true,
        show_archived: this.showArchived
      };
      this.$http
        .request(`/notifications`, {
          method: 'get',
          params: params,
          cancelToken: this.cancelSource.token
        })

        .then(response => response.data)
        .then(response => {
          this.notifications = response.notifications;
          this.$store.dispatch('main/setNotifications', {
            notifications: response.latestSummary || [],
            unread: response.unread
          });
        })
        .finally(() => {
          this.loading = false;
          this.initialLoading = false;
        });
    },
    getAnnouncements(){
      this.loading = true;
      if (this.cancelSource) {
        this.cancelSource.cancel('Start new search, stop active search');
      }
      this.cancelSource = axios.CancelToken.source();
      var params = {
        page: this.announcements.announcements.current_page,
        perPage: 10 // default to 10 items per page
      };
      this.$http
        .request(`/announcements`, {
          method: 'get',
          params: params,
          cancelToken: this.cancelSource.token
        })

        .then(response => response.data)
        .then(response => {
          this.announcements = response;
          this.$store.dispatch('main/updateAnnouncements', response);
        })
        .finally(() => {
          this.loading = false;
          this.initialLoading = false;
        });
    },
    markAllAsRead() {
      var self = this;
      var unread = self.$store.state.main.unread;
      swal({
        title: 'Confirm Mark all As Read',
        text: `Do you really want to mark all ${unread} notification(s) as read?`,
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      }).then(function (isConfirm) {
        if (isConfirm) {
          self.submitMarkAllAsRead();
        }
      });
    },
    markAsRead(notification) {
      // check if the selected notification belongs to the main notification / notification dropdown header
      // main notification was initialized at App.vue@getNotifications
      let mainNotification = _.find(this.$store.state.main.notifications, {
        id: notification.id
      });
      if (mainNotification) {
        mainNotification.read_at = moment().unix();
      }
      // update current notifications list
      let currentNotification = _.find(this.notifications, {
        id: notification.id
      });
      currentNotification.read_at = moment().unix();
      this.$store.state.main.unread--;
    },
    submitMarkAllAsRead() {
      var unread = this.$store.state.main.unread;
      this.loading = true;
      this.$http
        .post(`/notifications/mark-all-as-read`)
        .then((response) => response.data)
        .then(() => {
          this.notifications.forEach((notification) => {
            this.markAsRead(notification);
          });
          this.$store.state.main.unread = 0;
          swal({
            title: 'Success',
            text: `All ${unread} notification(s) has been marked as read`,
            icon: 'success'
          });
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style>
.read {
  background-color: #fff;
}

.read .status {
  color: darkgray;
}

.unread {
  background-color: #f5f5f5;
}

.unread .v-list__tile__content .v-list__tile__title {
  font-weight: 800;
}
.custom-pagination {
  width: auto;
  margin-left: auto;
}
</style>