<template>
  <FullPageLayout title="Locations">
    <template #default>
      <dropoff-location-table />
    </template>
  </FullPageLayout>
</template>
    
<script>
import DropoffLocationTable from './DropoffLocationTable.vue'
export default {
  components: {
    DropoffLocationTable
  },
  data() {
    return {
    }
  },
  mounted() {

  },
  methods: {
  
  }
};
</script>

