<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="search"
          label="Search Postage Type"
          append-icon="search"
          @input="submitSearch()"
        />
      </v-col>

      <v-col cols="12">
        <v-data-table
          class="table-striped"
          :headers="headers"
          :loading="loading"
          item-key="id"
          :items="items"
          :options.sync="options"
          :server-items-length="totalItems"
          :footer-props="{
            'items-per-page-options': [50, 100, 200, 500]
          }"
          :items-per-page="25"
        >
          <template #item.max_girth="{ item }">
            <span v-if="item.max_girth">
              {{ item.max_girth + ' ' + item.size_unit }}
            </span>
          </template>

          <template #item.max_longest_side="{ item }">
            <span v-if="item.max_longest_side">
              {{ item.max_longest_side + ' ' + item.size_unit }}
            </span>
          </template>

          <template #item.max_girth_plus_sides="{ item }">
            <span v-if="item.max_girth_plus_sides">
              {{ item.max_girth_plus_sides + ' ' + item.size_unit }}
            </span>
          </template>

          <template #item.max_combined_sides="{ item }">
            <span v-if="item.max_combined_sides">
              {{ item.max_combined_sides + ' ' + item.size_unit }}
            </span>
          </template>

          <template #item.max_length="{ item }">
            <span v-if="item.max_length">
              {{ item.max_length + ' ' + item.size_unit }}
            </span>
          </template>

          <template #item.max_width="{ item }">
            <span v-if="item.max_width">
              {{ item.max_width + ' ' + item.size_unit }}
            </span>
          </template>

          <template #item.max_height="{ item }">
            <span v-if="item.max_height">
              {{ item.max_height + ' ' + item.size_unit }}
            </span>
          </template>

          <template #item.max_weight="{ item }">
            <span v-if="item.max_weight">
              {{ item.max_weight + ' ' + item.weight_unit }}
            </span>
          </template>

          <template #item.actions="{ item }">
            <v-btn
              small
              color="primary"
              text
              @click="$refs.postageDialog.startEdit(item)"
            >
              Edit
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <postage-dialog
      ref="postageDialog"
      @update-postage="updateTable()"
    />
  </div>
</template>
<script>
import PostageDialog from './PostageDialog.vue';
export default {
  components: {
    PostageDialog
  },
  data() {
    return {
      postage: {},
      show_edit: false,
      loading: false,
      items: [],
      totalItems: 0,

      timer: null,
      cancelSource: null,
      search: null,
      options: {
        sortBy: ['description'],
        sortDesc: [true]
      },

      headers: [
        // { text: 'ID', value: 'id' },
        { text: 'Postage Type', value: 'description' },
        { text: 'MaxGirth', value: 'max_girth' },
        { text: 'MaxLongestSide', value: 'max_longest_side' },
        { text: 'MaxGirthPlusLength', value: 'max_girth_plus_sides' },
        { text: 'MaxCombinedSides', value: 'max_combined_sides' },
        { text: 'MaxLength', value: 'max_length' },
        { text: 'MaxWidth', value: 'max_width' },
        { text: 'MaxHeight', value: 'max_height' },
        { text: 'MaxWeight', value: 'max_weight' },
        { text: 'Actions', value: 'actions', align: 'right', sortable: false }
      ]
    };
  },
  watch: {
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    }
  },

  mounted() {
    this.updateTable();
  },
  methods: {
    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.options.page = 1;
      this.timer = setTimeout(function () {
        self.updateTable();
      }, ms);
    },
    updateTable() {
      this.totalItems = 0;
      this.items = [];

      this.getPostage().then((data) => {
        this.items = data.items;
        this.totalItems = data.total;
      });
    },
    getPostage() {
      // console.log(this.filters);
      this.loading = true;
      return new Promise((resolve) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let items = [];
        let total = 0;
        let pages = 0;

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        var params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sortBy[0],
          desc: sortDesc[0],
          search: this.search
        };

        this.$http
          .request(`/admin/postage-restrictions`, {
            method: 'get',
            params: params,
            cancelToken: this.cancelSource.token
          })

          .then((response) => response.data)
          .then((response) => {
            // console.log(response);
            items = response.data;
            total = response.total;
            pages = response.last_page;

            this.loading = false;
            resolve({
              items,
              total,
              pages
            });
          });
      });
    },
    showUpdate(postage) {
      this.show_edit = true;
      this.postage = postage;
    }
  }
};
</script>
