<template>
  <div>
    <v-row dense>
      <v-col class="d-flex flex-wrap float-left">
        <v-checkbox
          v-model="error"
          :value="true"
          dense
          label="Error"
          class="mr-3"
          hide-details
          @change="submitSearch"
        />

        <v-checkbox
          v-model="return_label"
          :value="true"
          dense
          label="Return Label"
          class="mr-3"
          hide-details
          @change="submitSearch"
        />


        <v-checkbox
          v-model="show_archived"
          class="mr-3"
          dense
          label="Show Archived"
          hide-details
          :value="true"
          @change="submitSearch()"
        />
        <v-checkbox
          v-model="address_issue"
          :value="true"
          dense
          label="Address Issue"
          class="mr-3"
          @change="submitSearch"
        />
      </v-col>
      <v-col cols="auto">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          offset-y
        >
          <template #activator="{ on, attrs }">
            <v-btn
              color="primary"
              v-bind="attrs"
              small
              :disabled="selected.length === 0"
              v-on="on"
            >
              Edit Tags
            </v-btn>
          </template>

          <v-card>
            <v-autocomplete
              v-model="selected_tags"
              small-chips
              prepend-inner-icon="mdi-tag"
              clearable
              data-vv-name="order_tag"
              :items="tags.tags"
              multiple
              item-text="name"
              item-value="id"
              hide-details
              outlined
              :disabled="!can(PERMISSIONS.SHIPPING)"
            >
              <template #item="{ item, on, attrs }">
                <v-list-item
                  :style="attrs.inputValue ? { backgroundColor: '#D3D3D3' } : ''"
                  v-on="on"
                >
                  <v-list-item-action>
                    <v-simple-checkbox
                      :value="attrs.inputValue"
                      color="primary"
                      :ripple="false"
                      v-on="on"
                    />
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title
                      :style="{
                        backgroundColor: item.color_code,
                        padding: '10px',
                        borderRadius: '7px'
                      }"
                    >
                      {{ item.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <template #selection="{ item, index }">
                <v-chip
                  :key="item.id"
                  :style="{
                    backgroundColor: item.color_code,
                    color: 'black'
                  }"
                  close
                  @click:close="removeTag(index)"
                >
                  {{ item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-card>
        </v-menu>
      </v-col>
      <v-col cols="auto">
        <!-- buildManageColumnHeaders is from mixin.js -->
        <stallion-table-settings
          :table="manageColumnTableKey"
          :columns="buildManageColumnHeaders(headers, manageColumnSettings, manageColumnTableKey)"
          :update-column-handler="updateColumns"
        />
      </v-col>
      <v-col cols="auto">
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-btn           
              small
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              Export
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              :disabled="selected.length === 0"
              @click="exportSelected"
            >
              <v-list-item-title>
                Export Currently Selected ({{ selected.length }})
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              :disabled="totalItems === 0"
              @click="exportShipments"
            >
              <v-list-item-title>
                Export Current View ({{ totalItems }})
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <!-- Edit Shipment -->
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
              small
              :disabled="disableBulkEdit"
              color="primary"
              depressed
              v-on="on"
              @click="show_edit = true"
            >
              <!-- <v-icon small> mdi-pencil </v-icon> -->
              Bulk Edit
            </v-btn>
          </template>
          <span>Edit {{ selected.length }} selected shipments</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col>
        <v-row dense>
          <v-col>
            <v-form
              v-if="!condensed"
              @submit.prevent
            >
              <!-- <v-container class="grey lighten-5"> -->
              <v-row dense>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="tracking_code"
                    outlined
                    dense
                    label="Tracking"
                    prepend-inner-icon="mdi-pound"
                    clearable
                    @input="submitSearch()"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="order_id"
                    outlined
                    dense
                    label="Order ID"
                    prepend-inner-icon="mdi-clipboard-text-search-outline"
                    clearable
                    @input="submitSearch()"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="name"
                    outlined
                    dense
                    label="Recipient"
                    prepend-inner-icon="mdi-account"
                    clearable
                    @input="submitSearch()"
                  />
                </v-col>

                <!-- <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="batch_id"
                    outlined
                    dense
                    label="Batch ID"
                    prepend-inner-icon="mdi-file-document"
                    clearable
                    @input="submitSearch()"
                  />
                </v-col> -->
              </v-row>

              <v-row
                v-if="showAdvanceSearch"
                dense
              >
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-select
                    v-model="postageType"
                    :items="$store.getters['main/postage_types'](true)"
                    item-value="id"
                    item-text="description"
                    label="Postage Type"
                    dense
                    outlined
                    class="pr-1"
                    clearable
                    hide-details
                    @change="submitSearch"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-select
                    v-model="packageType"
                    :items="$store.getters['main/package_types']"
                    item-value="id"
                    item-text="description"
                    label="Package Type"
                    dense
                    outlined
                    clearable
                    hide-details
                    @change="submitSearch"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <date-picker
                    v-model="start_date"
                    label="Start Date"
                    @change="submitSearch"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <date-picker
                    v-model="end_date"
                    label="End Date"
                    @change="submitSearch"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <v-select
                    v-model="needs_postage"
                    :items="[
                      { value: 1, label: 'Yes' },
                      { value: 0, label: 'No' }
                    ]"
                    item-value="value"
                    item-text="label"
                    label="Needs Postage"
                    dense
                    outlined
                    clearable
                    hide-details
                    @change="submitSearch"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <v-select
                    v-model="country_code"
                    :items="countries"
                    item-value="code"
                    item-text="name"
                    label="Country"
                    dense
                    outlined
                    clearable
                    hide-details
                    @change="submitSearch"
                  />
                </v-col>
              </v-row>

              <!-- States -->
              <v-row
                dense
              >
                <v-col class="d-flex flex-wrap">
                  <filter-buttons
                    v-model="filter"
                    :filters="filter_options"
                  />
                </v-col>
              </v-row>


              <!-- </v-container> -->
            </v-form>
          </v-col>
        </v-row>
      </v-col>

    
      <v-col
        v-if="!hideAdvanceSearchButton"
        cols="auto"
        class="text-right"
      >
        <!-- Clear Search Button with Tooltip -->
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
              color="primary"
              icon
        
              aria-label="Clear search"
              v-on="on"
              @click="clear"
            >
              <v-icon>
                mdi-close-circle-outline <!-- Updated icon for clearing/reset -->
              </v-icon>
            </v-btn>
          </template>
          <span>Clear Search</span>
        </v-tooltip>

        <!-- Advanced Search Toggle Button with Tooltip -->
        <v-tooltip
          v-model="show2"
          bottom
        >
          <template #activator="{ on }">
            <v-btn
              color="primary"
   
              icon
              aria-label="Toggle Advanced Filters"
              v-on="on"
              @click="showAdvanceSearch = !showAdvanceSearch"
            >
              <v-icon>
                {{ showAdvanceSearch ? 'mdi-chevron-up-circle-outline' : 'mdi-chevron-down-circle-outline' }}
              </v-icon>
            </v-btn>
          </template>
    
          <!-- Tooltip content based on the button state -->
          <span>
            {{ showAdvanceSearch ? 'Hide Advanced Filters' : 'Show Advanced Filters' }}
          </span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col class="py-0">
        <div v-if="condensed">
          <v-card-title>
            <span class="text-h3">Shipments</span>
            <v-spacer />
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
              @input="submitSearch()"
            />

            <!-- buildManageColumnHeaders is from mixin.js -->
            <stallion-table-settings
              :table="manageColumnTableKey"
              :columns="buildManageColumnHeaders(headers, manageColumnSettings, manageColumnTableKey)"
              :update-column-handler="updateColumns"
            />
          </v-card-title>
        </div>

        
        <v-data-table
          v-model="selected"
          class="table-striped my-0"
          :elevation="0"
          :headers="filteredHeaders"
          :items="items"
          item-key="id"
          show-select
          :loading="loading"
          :server-items-length="totalItems"
          :options.sync="options"
          :footer-props="{
            'items-per-page-options': [
              50, 100, 200, 500
            ]
          }"
          @click:row="rowClick"
          @item-selected="bulkSelect"
          @current-items="current = $event"
        >
          <template #item.address="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-icon
                  v-if="item.address_verification_status == 'Verified'"
                  small
                  color="success"
                  v-on="on"
                >
                  mdi-check-circle
                </v-icon>
                <v-icon
                  v-else-if="item.address_verification_status == 'Unverified'"
                  small
                  color="warning"
                  v-on="on"
                >
                  mdi-alert
                </v-icon>
                <v-icon
                  v-else-if="item.address_verification_status == 'Verification Failed'"
                  small
                  color="error"
                  v-on="on"
                >
                  mdi-alert-circle
                </v-icon>
                {{ item.name }}
              </template>
              <span v-if="item.address_verification_status == 'Verified'">
                Verified
              </span>
              <span v-else-if="item.address_verification_status == 'Unverified'">
                Unverified
              </span>
              <span v-else-if="item.address_verification_status == 'Verification Failed'">
                Verification Failed
              </span>
            </v-tooltip>
            <br>
            <span v-if="item.company">
              {{ item.company }}
              <br>
            </span>
            {{ item.address1 }}
            <br>
            <span v-if="item.address2">
              {{ item.address2 }}
              <br>
            </span>
            {{
              item.city + ' ' + item.province_code + ', ' + item.postal_code
            }}
            <br>
            {{ item.country_code }}
          </template>

          <template #item.package_type_id="{ item }">
            <div
              v-html="$options.filters.package_type(item.package_type_id)"
            />
            <div v-html="$options.filters.dims(item)" />
            <div>{{ item.weight }}{{ item.weight_unit }}</div>
          </template>

          <template #item.needs_postage="" />

          <template #item.order_id="{ item }">
            <div v-html="$options.filters.orderLink(item)" />
          </template>

          <template #item.weight="{ item }">
            {{ item.weight }}{{ item.weight_unit }}
          </template>

          <template #item.package_contents="{ item }">
            <div>
              <!-- getCombinedContents from mixin.js -->
              {{ getCombinedContents(item.items) || item.package_contents }}
            </div>
          </template>

          <template #item.total_value="{ item }">
            {{ item.total_value | currency }}
          </template>

          <template #item.dims="{ item }">
            {{ item.length }}x{{ item.width }}x{{ item.height
            }}{{ item.size_unit }}
          </template>

          <template #item.action="{ item }">
            <show-details-mobile
              v-if="$vuetify.breakpoint.smAndDown"
              :selected="item"
            />
          </template>

          <template #item.created_at="{ item }">
            <div v-if="item.created_at">
              {{ item.created_at | datetime_short }}
              <br>
              ({{ item.created_at | datetime_from }})
            </div>
          </template>

          <template #item.status_id="{ item }">
            <div
              v-if="item.error && item.status_id == 3"
              class="red--text"
            >
              <!-- <v-icon class="red--text" small
                  >mdi-alert-rhombus-outline</v-icon> -->
              Error
            </div>
            <div
              v-else-if="item.validated && item.status_id == 3"
              class="green--text"
            >
              <!-- <v-icon>mdi-check</v-icon>  -->Unpaid
            </div>
            <div
              v-else
              v-html="$options.filters.status(item.status_id)"
            />
          </template>

          <template #item.postage_type_id="{ item }">
            <div v-html="$options.filters.postage(item)" />

            <span v-if="item.is_fba == true">(FBA)</span>
            <span v-else-if="item.needs_postage == false">(3rd Party)</span>
          </template>

          <template #item.postage_rate="{ item }">
            <div>
              {{ item.postage_rate | currency }}
            </div>
          </template>

          <template #item.closeout_id="{ item }">
            <router-link
              v-if="item.closeout_id"
              class="font-weight-bold"
              :to="{
                name: 'closeout-show',
                params: { id: item.closeout_id }
              }"
            >
              {{ item.closeout_id }}
            </router-link>
          </template>

          <template #item.batch_id="{ item }">
            <router-link
              v-if="item.batch_id"
              class="font-weight-bold"
              :to="{ name: 'batch', params: { id: item.batch_id } }"
            >
              {{ item.batch_id }}
            </router-link>
          </template>

          <template #item.actions="{ item }">
            <!--can from mixin.js-->
            <v-btn
              v-if="item.status_id == 3 && can('CLIENT.SHIPPING')"
              small
              color="primary"
              depressed
              outlined
              @click.stop="showSingleEdit(item)"
            >
              <v-icon small>
                mdi-pencil
              </v-icon>
              Edit
            </v-btn>
          </template>

          <template #item.name="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-icon
                  v-if="item.address_verification_status == 'Verified'"
                  small
                  color="success"
                  v-on="on"
                >
                  mdi-check-circle
                </v-icon>
                <v-icon
                  v-else-if="item.address_verification_status == 'Unverified'"
                  small
                  color="warning"
                  v-on="on"
                >
                  mdi-alert
                </v-icon>
                <v-icon
                  v-else-if="item.address_verification_status == 'Verification Failed'"
                  small
                  color="error"
                  v-on="on"
                >
                  mdi-alert-circle
                </v-icon>
                {{ item.name }}
              </template>
              <span v-if="item.address_verification_status == 'Verified'">
                Verified
              </span>
              <span v-else-if="item.address_verification_status == 'Unverified'">
                Unverified
              </span>
              <span v-else-if="item.address_verification_status == 'Verification Failed'">
                Verification Failed
              </span>
            </v-tooltip>
          </template>

          <template #item.display_tag_ids="{ item }">
            <div style="display: flex">
              <span
                v-for="(tag, index) in item.tags"
                :key="`tag-${index}`"
              >
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <div
                      :style="{
                        backgroundColor: tag.color,
                        width: '10px',
                        height: '20px',
                        borderRadius: '10%',
                        marginRight: '2px'
                      }"
                      v-on="on"
                    />
                  </template>
                  <span>
                    {{ tag.name }}
                  </span>
                </v-tooltip>
              </span>
            </div>
          </template>
          <!-- lowest_postage_ids -->
          <template #item.lowest_postage_ids="{ item }"> 
            <div
              v-for="id in item.lowest_postage_ids"
              :key="id"
              v-html="$options.filters.postage(id)"
            />
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <bulk-edit
      v-model="show_edit"
      :shipments="selected"
      @update="updateTable"
    />
    <single-edit
      v-model="show_edit_single"
      :shipment.sync="shipment"
      @update="updateSingle"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import BulkEdit from './BulkEdit.vue';
import SingleEdit from './SingleEdit.vue';
import FilterButtons from '../../shared/FilterButtons.vue';
import CONSTANTS from '@/constants';
import PERMISSIONS from '@/constants/client-permissions';

export default {
  components: {
    BulkEdit,
    SingleEdit,
    FilterButtons
  },
  props: {
    userId: {
      type: Number,
      default: null
    },
    batchId: {
      default: null,
      type: String
    },
    batched: {
      default: null,
      type: Boolean
    },
    closeoutId: {
      type: String,
      default: null
    },
    statusId: {
      type: Number,
      default: null
    },
    // filter: {
    //   type: String,
    //   default: null
    // },
    closedOut: {
      default: null,
      type: Boolean
    },
    pending: {
      default: null,
      type: Boolean
    },
    condensed: {
      default: false,
      type: Boolean
    },
    // postageTypes: {
    //   default: null,
    //   type: Array
    // },
    value: {
      type: Array,
      default: () => []
    },
    itemsPerPage: {
      type: Number,
      default: 25
    },
    tablePreferenceKey: {
      type: String,
      default: 'shipments'
    },
    manageColumnTableKey: {
      type: String,
      default: 'table_columns_client_shipments'
    },
    headers: {
      type: Array,
      default: () => [
        {
          text: 'ID',
          align: 'left',
          value: 'ship_code'
        },
        {
          text: 'Full Address',
          align: 'left',
          value: 'address',
          sortable: false
        },
        {
          text: 'Recipient',
          align: 'left',
          value: 'name'
        },
        {
          text: 'Address1',
          align: 'left',
          value: 'address1'
        },
        {
          text: 'City',
          align: 'left',
          value: 'city'
        },
        {
          text: 'Province',
          align: 'left',
          value: 'province_code'
        },
        {
          text: 'Postal Code',
          align: 'left',
          value: 'postal_code'
        },
    
        {
          text: 'Country',
          align: 'left',
          value: 'country_code'
        },
        {
          text: 'Order ID',
          value: 'order_id',
          class: 'hidden-sm-and-down'
        },
        {
          text: 'Postage',
          value: 'postage_type_id'
        },
        {
          text: 'Rate',
          value: 'postage_rate',
          // class: 'hidden-sm-and-down'
        },
        {
          text: 'Status',
          value: 'status_id'
        },
        {
          text: 'Batch',
          value: 'batch_id',
          class: 'hidden-sm-and-down'
        },
        {
          text: 'Closeout',
          value: 'closeout_id',
          class: 'hidden-sm-and-down'
        },
        {
          text: 'Created',
          value: 'created_at'
        },
        {
          text: 'Total Value',
          value: 'total_value'
        },
        {
          text: 'Contents',
          value: 'package_contents'
        },
        {
          text: 'Weight',
          value: 'weight',
          class: 'hidden-sm-and-down'
        },
        {
          text: 'Package',
          value: 'package_type_id',
          class: 'hidden-sm-and-down'
        },
        {
          text: 'Store',
          value: 'store',
          class: 'hidden-sm-and-down'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        },
        {
          text: 'SKU',
          value: 'sku',
          sortable: true
        },
        {
          text: 'Total Quantity',
          value: 'total_quantity',
          sortable: true
        },
        {
          text: 'Order Tags',
          value: 'display_tag_ids',
          sortable: true
        },
        {
          text: 'Cheapest Postage',
          value: 'lowest_postage_ids',
          sortable: false
        },
        {
          text: 'Filter',
          value: 'sub_batch',
          sortable: false
        }
      ]
    }
  },
  data() {
    return {
      PERMISSIONS: PERMISSIONS,
      menu: false,
      selected_tags: [],
      manageColumnSettings: [],
      filter:null,
      show_edit: false,
      show_edit_single: false,

      error: null,
      isExporting: false,

      show: null,
      show2: null,

      loading: false,
      totalItems: 0,
      items: [],

      hideAdvanceSearchButton: false,

      options: {
        sortBy: [null],
        sortDesc: [null]
      },

      filter_options: [
        {
          text: 'All',
          icon: 'mdi-package-variant-closed',
          value: null
        },
        {
          text: 'Incomplete',
          icon: 'mdi-circle-edit-outline',
          value: 'incomplete'
        },
        {
          text: 'Unpaid',
          icon: 'mdi-circle-edit-outline',
          value: 'unpaid'
        },
        {
          text: 'Pending',
          icon: 'mdi-pause-circle-outline',
          value: 'pending'
        },
        {
          text: 'Ready',
          icon: 'mdi-cube-outline',
          value: 'ready'
        },
        {
          text: 'Received',
          icon: 'mdi-check-underline',
          value: 'received'
        },
        {
          text: 'In Transit',
          icon: 'mdi-cube-send',
          value: 'in-transit'
        },
        {
          text: 'Complete',
          icon: 'mdi-check-circle-outline',
          value: 'complete'
        },
        {
          text: 'Exception',
          icon: 'mdi-alert-circle-outline',
          value: 'exception'
        },
        {
          text: 'Void Requested',
          icon: 'mdi-select',
          value: 'void-requested'
        },
        {
          text: 'Voided',
          icon: 'mdi-select-off',
          value: 'voided'
        },
        {
          text: 'Expired',
          icon: 'mdi-alert-outline',
          value: 'postage-expired'
        }
      ],

      search: null,

      start_date: null,
      end_date: null,
      start_date_menu: null,
      end_date_menu: null,
      needs_postage: null,
      country_code: null,
      timer: null,

      cancelSource: null,
      showAdvanceSearch: false,
      postageType: null,
      packageType: null,

      shiftKeyOn: false,
      current: [],
      last: null,
      shipment: null,
      postageTypes: [],
      return_label: false,
      name: null,
      tracking_code: null,
      closeout_id: null,
      batch_id: null,
      order_id: null,
      countries: CONSTANTS.COUNTRIES,
      show_archived: false,
      address_issue: null
    };
  },
  computed: {
    ...mapState(['main', 'tags']),
    ...mapGetters({ append_tags: 'tags/append_tags' }),
    filteredHeaders() {
      // filteredColumnHeaders is from mixin.js
      return this.manageColumnTableKey
        ? this.filteredColumnHeaders(this.headers, this.manageColumnSettings, this.manageColumnTableKey)
        : this.headers
    },
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    disableBulkEdit() {
      if (this.selected.length === 0) {
        return true;
      }
      if (this.selected.filter((s) => s.status_id === 3).length === 0) {
        return true;
      }
      return false;
    },
    params() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      // if all params are null, set start_date to 30 days ago
      if (
        !this.start_date &&
        !this.end_date &&
        !this.needs_postage &&
        !this.country_code &&
        !this.pending &&
        !this.filter &&
        !this.packageType &&
        !this.postageType &&
        !this.statusId &&
        !this.closeoutId &&
        !this.batchId &&
        !this.tracking_code &&
        !this.name &&
        !this.order_id &&
        !this.batched &&
        !this.error &&
        !this.return_label &&
        !this.show_archived &&
        !this.address_issue
      ) {
        // set start_date to 30 days ago if no filters are set
        this.start_date = moment().subtract(6, 'months').format('YYYY-MM-DD')
      }
      
      return {
        page: page,
        per_page: itemsPerPage,
        sort_by: sortBy[0],
        desc: sortDesc[0],
        search: this.search,
        start_date: this.start_date,
        end_date: this.end_date,
        needs_postage: this.needs_postage,
        country_code: this.country_code,
        pending: this.pending,
        status: this.filter,
        package_type_id: this.packageType,
        postage_type_id: this.postageType,
        status_id: this.statusId,
        closeout_id: this.closeoutId,
        batch_id: this.batchId,
        tracking_code: this.tracking_code,
        name: this.name,
        order_id: this.order_id,
        batched: this.batched,
        error: this.error,
        return_label: this.return_label,
        show_archived: this.show_archived,
        address_verification_status: this.address_issue ? 'Verification Failed' : null
      };
    }
  },
  watch: {
    filter: function () {
      this.options.page = 1;
      this.updateTable();
    },
    options: {
      handler(newOptions, oldOptions) {
        this.updateTable();

        const preference = {
      table_rows: {},
      table_sort: {}
    };

    let shouldUpdatePreferences = false;

    if (newOptions.itemsPerPage !== oldOptions.itemsPerPage) {
      preference.table_rows[this.tablePreferenceKey] = newOptions.itemsPerPage;
      shouldUpdatePreferences = true;
    }

    if (newOptions.sortBy !== oldOptions.sortBy) {
      preference.table_sort[this.tablePreferenceKey] = {
        sortBy: newOptions.sortBy[0],
        sortDesc: newOptions.sortDesc[0],
      };
      shouldUpdatePreferences = true;
    }

    if (newOptions.sortDesc !== oldOptions.sortDesc) {
      preference.table_sort[this.tablePreferenceKey] = {
        sortBy: newOptions.sortBy[0],
        sortDesc: newOptions.sortDesc[0],
      };
      shouldUpdatePreferences = true;
    }

    // Only dispatch setPreferences if there was a change
    if (shouldUpdatePreferences) {
      this.$store.dispatch('main/setPreferences', preference);
    }
      },
      deep: true
    },

    itemsPerPage: function (val) {
      this.options.itemsPerPage = val;
    },
    selected: function (orders) {
      if (orders) {
        const tags = [];
        for (let order of orders) {
          if (order.tag_ids) {

            // make sure tags are array 
            if (!Array.isArray(order.tag_ids)) {
              order.tag_ids = order.tag_ids.split(',').map(Number);
            }

            tags.push(...order.tag_ids);
          }
        }

        tags.sort();

        // remove duplicates
        const uniqueTags = tags.filter((value, index, self) => {
          return self.indexOf(value) === index;
        });

        this.selected_tags = uniqueTags;
        this.settingTags = true;
      }
    },
    selected_tags: function (newVal, oldVal) {
      if (!this.settingTags) {
        this.modifyOrderTags(newVal, oldVal);
      } else {
        this.settingTags = false;
      }
    },
  },

  async mounted() {
    this.selected = [];
    this.updateTable();
    Event.listen('update-shipments', () => {
      this.updateTable();
    });

    Event.listen('update-single', (shipment) => {
      this.updateSingle(shipment);
    });

    Event.listen('update-multiple', (shipments) => {
      shipments.forEach((shipment) => {
        this.updateSingle(shipment);
      });
    });

    this.$root.$on('shipment-table-show-select', (select) => {
      if (!select) {
        this.selected = this.items;
      } else {
        this.selected = [];
      }
    });
    
    // getManageColumnSettings is from mixin.js
    this.manageColumnSettings = await this.getManageColumnSettings(this.manageColumnTableKey)
  },
  created() {
    const table_rows = this.$store.getters['main/table_rows'];
    const table_sort = this.$store.getters['main/table_sort'];

    let options = {
      sortBy: [table_sort[this.tablePreferenceKey]?.sortBy] ?? this.options.sortBy,
      sortDesc: [table_sort[this.tablePreferenceKey]?.sortDesc] ?? this.options.sortDesc,
      itemsPerPage: table_rows[this.tablePreferenceKey] ?? this.itemsPerPage
    };

    this.options = Object.assign(this.options, options);
    
    const self = this;
    self.keyDownHandler = function ({ key }) {
      if (key == 'Shift') self.shiftKeyOn = true;
    };
    self.keyUpHandler = function ({ key }) {
      if (key == 'Shift') self.shiftKeyOn = false;
    };
    window.addEventListener('keydown', this.keyDownHandler);
    window.addEventListener('keyup', this.keyUpHandler);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.keyDownHandler);
    window.removeEventListener('keyup', this.keyUpHandler);
  },
  methods: {
    updateColumns(columns) {
      this.manageColumnSettings = columns
    },
    updateSingle(shipment) {
      const index = this.items.findIndex((x) => x.id === shipment.id);
      this.$set(this.items, index, shipment);
      this.$emit('update_batch');
    },
    showSingleEdit(shipment) {
      this.shipment = shipment;
      this.show_edit_single = true;
    },
    saveSelected() {
      //  console.log('editedIndex: ' + this.editedIndex)

      for (var property1 in this.editedItem) {
        if (
          this.editedItem[property1] === null ||
          this.editedItem[property1] === ''
        ) {
          delete this.editedItem[property1];
        }
      }
      this.$store.commit('imp/validationEditItem', {
        value: this.editedItem
      });
    },

    bulkSelect({ item: b, value }) {
      setTimeout(() => {
        const { current, shiftKeyOn } = this;

        if (this.selected && this.selected.length == 1 && value == true && shiftKeyOn) {
          let [a] = this.selected;

          if (this.last !== null) {
            a = this.last;
          }

          let start = current.findIndex((item) => item == a);
          let end = current.findIndex((item) => item == b);

          if (start - end > 0) {
            let temp = start;
            start = end;
            end = temp;
          }

          for (let i = start; i <= end; i++) {
            if (current[i]) {
              this.selected.push(current[i]);
            }
          }
        }

        this.last = b;
      }, 100);
    },
    selectAll() {
      if (this.selected.length > 0) {
        this.selected = [];
      } else {
        this.selected = this.items.map((x) => x['id']);
      }
    },
    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.options.page = 1;
      this.timer = setTimeout(function () {
        self.updateTable();
      }, ms);
    },
    updateTable() {
      // this.selected = [];
      this.totalItems = 0;
      // this.items = [];

      this.getDataFromApi().then((data) => {
        this.items = data.items;
        this.totalItems = data.total;
        const old = this.selected.map((x) => x['id']);
        this.selected = this.items.filter((value) => old.includes(value.id));
        
        this.$emit('update_batch');
      });
    },

    exportShipments() {
      this.isExporting = true;

      this.$http
        .post('shipments/export', this.params)
        .then((response) => {
          this.downloadFileUrl(response.data, `Stallion_Export.csv`, 'text/csv');
        })
        .catch(() => {
          this.errorMessage('Nothing to export');
        })
        .finally(() => {
          this.isExporting = false;
        });
    },
    exportSelected() {
      this.isExporting = true;

      var shipment_ids = this.selected.map((x) => x.id);

      var params = {
        shipment_ids: shipment_ids
      };

      this.$http
        .post('shipments/export', params)
        .then((response) => {
          this.downloadFileUrl(response.data, `Stallion_Export.csv`, 'text/csv');
        })
        .catch(() => {
          this.errorMessage('Nothing to export');
        })
        .finally(() => {
          this.isExporting = false;
        });
    },
    rowClick(item) {
      this.selected = [item];
    },

    getDataFromApi() {
      this.loading = true;
      return new Promise((resolve) => {
        // const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let items = [];
        let total = 0;
        let pages = 0;

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        this.$http
          .request(`/shipments`, {
            method: 'get',
            params: this.params,
            cancelToken: this.cancelSource.token
          })
          .then((response) => response.data)
          .then((response) => {
            response.data.forEach((item) => {
              item.tags = this.getFormattedTags(item.display_tag_ids);
            });
            // cast tag_ids and display_tag_ids to array
            response.data.forEach((item) => {
              item.tag_ids = JSON.parse(item.tag_ids);
              item.display_tag_ids = JSON.parse(item.display_tag_ids);
            });

            items = response.data;
            total = response.total;
            pages = response.last_page;

            // loop each item and parse the json string to array
            // Shipment model's mutator(auto parse json) is not being executed
            // because we are retrieving the shipments using db builder
            items.forEach((item) => {
              if (item.lowest_postage_ids) {
                item.lowest_postage_ids = JSON.parse(item.lowest_postage_ids);
              }

              if (item.items) {
                item.items = JSON.parse(item.items);
              }
            });

            this.loading = false;
            resolve({
              items,
              total,
              pages
            });
          });
      }).finally(() => {
        this.loading = false;
      });
    },
    clear() {
      this.search = null;
      this.start_date = moment().subtract(6, 'months').format('YYYY-MM-DD');
      this.end_date = null;
      this.needs_postage = null;
      this.country_code = null;
      this.pending = null;
      this.filter = null;
      this.packageType = null;
      this.postageType = null;
      this.statusId = null;
      this.closeoutId = null;
      this.batchId = null;
      this.tracking_code = null;
      this.name = null;
      this.order_id = null;
      this.batched = null;
      this.error = null;
      this.return_label = null;
      this.show_archived = null;
      this.address_issue = null;
      this.submitSearch();

      this.$emit('clear');
    },
    async modifyOrderTags(newTag, oldValues) {
      if (newTag.length !== oldValues.length) {
        const order_ids = this.selected.map((r) => r.id);

        if (newTag.length > oldValues.length) {
          const tag_ids = newTag[0]?.id ? newTag.map((i) => i.id) : newTag;
          const filtered = tag_ids.filter((x) => !oldValues.includes(x));
          const tag_id = filtered[0];
          await this.appendShipmentTag(tag_id, order_ids);
        } else if (newTag.length < oldValues.length) {
          const tag_ids = oldValues[0]?.id
            ? oldValues.map((i) => i.id)
            : oldValues;
          const filtered = tag_ids.filter((x) => !newTag.includes(x));
          const tag_id = filtered[0];
          await this.removeShipmentTag(tag_id, order_ids);
        }
      }
    },
    appendShipmentTag(tag_id) {
      // Update associated orders' tags
      const order_ids = this.selected
        .filter((shipment) => shipment.store_order_id)
        .map((shipment) => shipment.store_order_id);
      if (order_ids.length > 0) {
        this.$http.post('/orders/append-tag', {
          tag_id,
          order_ids,
        }).then(() => {
          for (let order of this.selected) {
            const shipment = this.items.find((s) => s.id === order.id);
            if (shipment) {
              // initialize display_tag_ids if it's null
              if (!shipment.display_tag_ids) {
                shipment.display_tag_ids = [];
              }
              shipment.display_tag_ids.push(tag_id);
            }

            shipment.tags = this.getFormattedTags(shipment.display_tag_ids);
          }
        });
      }
    },
    removeShipmentTag(tag_id) {
      // Update associated orders' tags
      const order_ids = this.selected
        .filter((shipment) => shipment.store_order_id)
        .map((shipment) => shipment.store_order_id);

      if (order_ids.length > 0) {
        this.$http.post('/orders/remove-tag', {
          tag_id,
          order_ids,
        }).then(() => {
            for (let order of this.selected) {
              const shipment = this.items.find((s) => s.id === order.id);
              if (shipment) {
                // initialize display_tag_ids if it's null
                if (!shipment.display_tag_ids) {
                  shipment.display_tag_ids = [];
                }
                
                shipment.display_tag_ids = shipment.display_tag_ids.filter((id) => id !== tag_id);
              }

              shipment.tags = this.getFormattedTags(shipment.display_tag_ids);
            }
        });
      }
    },
    getTagColor(tagId) {
      const tag = this.tags.tags.find(t => t.id === tagId);
      return tag ? tag.color_code : '#GGGGGG';
    },
    // Retrieves the tag name based on tag ID
    getTagName(tagId) {
      const tag = this.tags.tags.find(t => t.id === tagId);
      return tag ? tag.name : tagId;
    },
    getIsHidden(tagId) {
      const tag = this.tags.tags.find(t => t.id === tagId);
      return tag ? tag.is_hidden : false;
    },
    // Determines text color based on background color
    getTextColor(backgroundColor) {
      // Same implementation as before
      const color = backgroundColor.replace('#', '');
      const r = parseInt(color.substr(0, 2), 16);
      const g = parseInt(color.substr(2, 2), 16);
      const b = parseInt(color.substr(4, 2), 16);
      const brightness = (r * 299 + g * 587 + b * 114) / 1000;
      return brightness > 125 ? 'black' : 'white';
    },
    getFormattedTags(tag_ids) {
  // If tag_ids is null, undefined, or empty, return an empty array
  if (!tag_ids) {
    return [];
  }

  // If tag_ids is a string, try parsing it as JSON
  if (typeof tag_ids === 'string') {
    try {
      tag_ids = JSON.parse(tag_ids);
    } catch (e) {
      console.error('Failed to parse tag_ids:', tag_ids);
      return [];
    }
  }

  // If tag_ids is not an array after parsing, return an empty array
  if (!Array.isArray(tag_ids)) {
    return [];
  }

  console.log('tag_ids', tag_ids);

  return tag_ids
    .filter(id => !this.getIsHidden(id))
    .map(id => ({
      name: this.getTagName(id),
      color: this.getTagColor(id),
    }));
},
removeTag(index) {
  console.log('removeTag', index);
      const oldValues = [...this.selected_tags]; // Capture the old tags before removing
      this.selected_tags.splice(index, 1); // Remove the tag at the specified index

      // Update the tags for the selected orders with correct old values
      this.modifyOrderTags(this.selected_tags, oldValues);
    },
  }
};
</script>

<style>
.v-application p {
  margin-bottom: 0px;
}

@media only screen and (min-width: 1440px) {
  .shipment-buttons {
    max-width: 200px;
  }
}
.input-group--selection-controls__ripple {
  border-radius: 0 !important;
}
</style>
