<template>
  <div v-if="$auth.user && $auth.user.scan_location && can('CLIENT.PRIMARY_ACCOUNT_HOLDER')">
    <v-list
      v-for="(group, index) in scan_menu"
      :key="index"
      class="rounded-list pa-0"
    >
      <v-subheader
        v-if="group.title"
        style="height: 40px"
      >
        {{ group.title }}
      </v-subheader>
      <stallion-list-item
        v-for="item in group.items"
        :key="item.text"
        v-bind="item"
      />
      <v-divider />
    </v-list>
  </div>
</template>

<script>
import StallionListItem from '../ListItems/StallionListItem.vue';
export default {
  components: { StallionListItem },
  data() {
    return {
      scan_menu: [
        {
          title: 'Drop-off Location',
          items: [
            {
              icon: 'mdi-barcode',
              to: {
                name: 'drop-off-scan'
              },
              text: 'Scan'
            },
            {
              icon: 'mdi-chart-line',
              to: {
                name: 'drop-off-analytics'
              },
              text: 'Analytics'
            },
            {
              icon: 'mdi-receipt',
              to: {
                name: 'drop-off-invoices'
              },
              text: 'Invoices'
            }
          ]
        }
      ]
    };
  },

  mounted() {},
  methods: {}
};
</script>
