<template>
  <v-menu
    bottom
    offset-y
    class="pa-10"
    style="border: 1px solid #e7e7e7"
  >
    <template #activator="{ on }">
      <v-btn
        class="mr-6 ml-2 btn-fix"
        icon
        depressed
        small
        :dense="true"
        color="#fff"
        v-on="on"
      >
        <v-icon color="blue-grey darken-2">
          mdi-bell-outline
        </v-icon>
        <v-badge
          v-if="$store.state.main.unread > 0"
          :content="
            $store.state.main.unread > 99 ? '99+' : $store.state.main.unread
          "
          bordered
          color="primary"
          offset-x="5"
          offset-y="0"
        />
      </v-btn>
    </template>

    <v-list
      subheader
      two-line
      dense
      class="pa-0"
    >
      <v-subheader class="font-weight-bold px-8 pt-5">
        <span v-if="$store.state.main.unread > 99"> 99+ </span>
        <span v-else>
          {{ $store.state.main.unread }}
        </span>
        &nbsp; Unread Notifications
      </v-subheader>
      <template
        v-for="(notification, index) in $store.state.main.notifications.slice(
          0,
          4
        )"
      >
        <v-list-item
          :key="notification.id"
          ripple
          class="px-8"
          :class="{
            read: notification.read_at != null,
            unread: notification.read_at == null
          }"
        >
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">
              {{ notification.title }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ notification.message }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-icon>
            <span style="font-size: 0.8rem">
              {{ notification.created_at | datetime_from }}
            </span>
            <v-spacer />
            <v-spacer />
          </v-list-item-icon>
        </v-list-item>
        <v-divider
          :key="index"
          class="ma-0"
        />
      </template>
      <v-layout align-center>
        <v-flex
          xs12
          text-center
        >
          <v-btn
            block
            text
            small
            class="pt-6 pb-6"
            color="primary"
            to="/notifications"
          >
            View all notifications
          </v-btn>
        </v-flex>
      </v-layout>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {},
  data: () => ({}),
  computed: {},

  mounted() {},
  methods: {}
};
</script>

<style scoped>
.read {
  background-color: #fff;
}

.unread {
  background-color: #f5f5f5;
}
</style>
