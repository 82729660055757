<template>
  <div>
    <v-row dense>
      <v-col
        cols="12"
        md="6"
      >
        <v-select
          v-model="type_local"
          outlined
          dense
          :items="rules"
          label="Action"
        />
      </v-col>
      <v-col
        v-if="type_local && field"
        cols="12"
        md="6"
      >
        <v-text-field
          v-if="field.type == 'text'"
          v-model="value_local"
          outlined
          dense
          type="text"
          label="Value"
        />
        <v-text-field
          v-else-if="field.type == 'number'"
          v-model="value_local"
          outlined
          dense
          type="number"
          label="Value"
        />
        <v-select
          v-else-if="field.type == 'select'"
          v-model="value_local"
          outlined
          dense
          :items="field.options"
          label="Value"
          :item-text="field.text"
          :item-value="field.key"
          :multiple="field.multiple ? true : false"
        />
        <v-menu
          v-else-if="field.type == 'date'"
          v-model="end_date_menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template #activator="{ on }">
            <v-text-field
              v-model="value_local"
              outlined
              dense
              style="padding: 0"
              label="End Date"
              readonly
              clearable
              hide-details
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="value_local"
            @input="end_date_menu = false"
          />
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  components: {},
  props: {
    type: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number, Array],
      default: null
    }
  },
  data() {
    return {
      field: null,
      dialog: false,
      tax_identifiers: [],
      rules: [
        {
          text: 'Discount Percent',
          value: 'discount_percent',
          type: 'number',
          field: {
            name: 'Value',
            type: 'number'
          }
        },
        {
          text: 'Discount Amount',
          value: 'discount_amount',
          field: {
            name: 'Value',
            type: 'number'
          }
        },
        {
          text: 'Fixed Amount',
          value: 'fixed_amount',
          type: 'number',
          field: {
            name: 'Value',
            type: 'number'
          }
        },
        {
          text: 'Markup Amount',
          value: 'markup_amount',
          type: 'number',
          field: {
            name: 'Value',
            type: 'number'
          }
        },
        {
          text: 'Markup Percent',
          value: 'markup_percent',
          type: 'number',
          field: {
            name: 'Value',
            type: 'number'
          }
        },
      ]
    };
  },
  computed: {
    ...mapState(['tags']),
    type_local: {
      get() {
        return this.type;
      },
      set(type) {
        this.$emit('update:type', type);
      }
    },
    value_local: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('update:value', value);
      }
    }
  },
  watch: {
    type_local: {
      immediate: true,
      handler(val) {
        if (val) {
          const index = this.rules.findIndex((x) => x.value === val);
          this.field = this.rules[index].field;
          // parse value from array to string, single select or multiple
          // prevents vue error when array is converted to string (vice versa)
          let tempVal = null;
          switch (this.type_local) {
            case 'set_lowest_postage_ids':
              tempVal = Array.isArray(this.value_local)
                ? this.value_local
                : [this.value_local];
              break;
            case 'set_tags':
              tempVal = Array.isArray(this.value_local)
                ? this.value_local
                : [this.value_local];
              break;
            default:
              tempVal = Array.isArray(this.value_local)
                ? this.value_local[0]
                : this.value_local;
          }
          this.value_local = this.filterValue(tempVal);
        } else {
          this.field = null;
          this.value_local = null;
        }
      }
    }
  },
  mounted() {
    this.getTaxIdentifiers();
  },
  methods: {
    getPostageTypes() {
      return this.$store.getters['main/postage_types'](true);
    },
    getTags() {
      return this.$store.getters['tags/filtered_tags'];
    },
    getPackageTypes() {
      return this.$store.getters['main/package_types'];
    },
    getSubUsers() {
      this.$store.dispatch('main/getSubUsers');
      return this.$store.getters['main/sub_users'];
    },
    async getTaxIdentifiers() {
      const response = this.$store.getters['main/tax_identifiers']
      const ruleIndex = this.rules.findIndex(
        (rule) => rule.value == 'set_tax_identifier'
      );
      if (ruleIndex !== -1) {
        const rule = this.rules[ruleIndex];
        rule.field.options = response.data.map((identifier) => {
          identifier.id = identifier.id.toString();
          return identifier;
        });
      }
    },
    /**
     * Filter and make sure all values of dropdown exists on the select options
     * it might not exist when changing input from select to textfield (vice versa)
     */
    filterValue(value) {
      let options = [];
      switch (this.type_local) {
        case 'set_lowest_postage_ids':
        case 'set_postage_type':
          options = this.getPostageTypes();
          break;
        case 'set_package_type':
          options = this.getPackageTypes();
          break;
        case 'set_tags':
          options = this.getTags();
          break;
        default:
          return value;
      }

      let filteredValue = null;
      if (Array.isArray(value)) {
        filteredValue = value.filter((id) => {
          return _.find(options, { id });
        });
      } else {
        filteredValue = _.find(options, { id: value }) ? value : null;
      }

      return filteredValue;
    }
  }
};
</script>
