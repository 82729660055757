<template>
  <StallionDialog
    v-model="show"
    :width="500"
    :disabled="disabled"
    class="dialog-slot"
    title="Magento"
  >
    <slot v-on="on" />

    <template #content>
      <v-row dense>
        <v-col
          cols="12"
          class="text-center"
        >
          Please enter your credentials to connect to your Magento store.
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="url"
            v-validate="'required|url'"
            :error-messages="errs.collect('url')"
            label="Store URL"
            data-vv-name="url"
            placeholder="https://mystore.com"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            id="consumer_key"
            v-model="consumer_key"
            v-validate="'required'"
            :error-messages="errs.collect('consumer_key')"
            label="Consumer Key"
            data-vv-name="consumer_key"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            id="consumer_secret"
            v-model="consumer_secret"
            v-validate="'required'"
            :error-messages="errs.collect('consumer_secret')"
            label="Consumer Secret"
            data-vv-name="consumer_secret"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            id="access_token"
            v-model="access_token"
            v-validate="'required'"
            :error-messages="errs.collect('access_token')"
            label="Access Token"
            data-vv-name="access_token"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            id="access_secret"
            v-model="access_secret"
            v-validate="'required'"
            :error-messages="errs.collect('access_secret')"
            label="Access Secret"
            data-vv-name="access_secret"
          />
        </v-col>
        <v-flex xs12>
          <v-text-field
            id="nickname"
            v-model="nickname"
            :error-messages="errs.collect('nickname')"
            label="Nickname(Optional)"
            data-vv-name="nickname"
          />
        </v-flex>
      </v-row>
    </template>

    <template #actions>
      <v-btn
        type="submit"
        color="primary"
        :loading="isSubmitting"
        :disabled="isSubmitting"
        @click="connect()"
      >
        Connect
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },

  data() {
    return {
      url: null,
      consumer_key: null,
      consumer_secret: null,
      access_token: null,
      access_secret: null,
      nickname: null,
      isSubmitting: false
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    reset() {
      this.$validator.reset();
      this.url = null;
      this.consumer_token = null;
      this.consumer_secret = null;
      this.access_token = null;
      this.access_secret = null;
      this.isSubmitting = false;
      this.nickname = false;
    },
    close() {
      this.$emit('update:show', false);
    },
    async validate() {
      return await this.$validator.validate().then((result) => {
        return result ? true : false;
      });
    },
    async connect() {
      if (!(await this.validate())) return;

      this.isSubmitting = true;
      this.$http
        .post('/stores/magento', {
          url: this.url,
          consumer_key: this.consumer_key,
          consumer_secret: this.consumer_secret,
          access_token: this.access_token,
          access_secret: this.access_secret,
          nickname: this.nickname,
        })
        .then((response) => response.data)
        .then(() => {
          this.$store.dispatch('userStores/getStores');
          this.show = false;
          this.$notify({
            group: 'main',
            title: 'Store added',
            text: 'Your account for Magento has been added successfully.',
            duration: 5000,
            type: 'success'
          });

          this.reset();
        })
        .catch((err) => {
          if (err.response && err.response.status === 422) {
            this.$notify({
              group: 'main',
              title: 'Invalid Credentials',
              text: err.response.data.errors,
              duration: 5000,
              type: 'error'
            });
          } else {
            this.$notify({
              group: 'main',
              title: 'Error adding Magento',
              text: err,
              duration: 5000,
              type: 'error'
            });
          }
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    }
  }
};
</script>
