<template>
  <StallionDialog
    v-model="dialog"
    :width="500"
    persistent
    :disabled="disabled"
    :title="`Void Supplies ${$options.filters.pluralize(
      invoices.length,
      'Order'
    )}`"
  >
    <template #content>
      <v-layout column>
        <v-flex
          xs12
          text-center
          mt-4
          mb-12
        >
          <h4>
            You are about to void {{ invoices.length }} supplies
            {{ invoices.length | pluralize(' order') }} <br>Refunds will be
            processed instantly
          </h4>
        </v-flex>

        <!-- <div v-if="delete_loading"> -->
        <v-progress-linear v-model="valueDeterminate" />

        <v-flex
          xs12
          text-center
        >
          {{ number }} of {{ invoices.length }}
        </v-flex>
        <!-- </div> -->
      </v-layout>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        :loading="delete_loading"
        :disabled="delete_loading"
        @click="remove()"
      >
        <v-icon
          small
          dark
        >
          mdi-select-off
        </v-icon>Void
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    invoices: {
      type: Array,
      default: () => []
    },

    disabled: {
      default: false,
      type: Boolean
    }
  },

  data() {
    return {
      dialog: false,
      delete_loading: false,

      number: 0
    };
  },
  computed: {
    valueDeterminate() {
      if (this.number > 0)
        return Math.round((this.number / this.invoices.length) * 100);
      else return 0;
    }
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    remove: async function () {
      console.log('remove');
      this.delete_loading = true;

      var self = this;
      await Promise.all(
        this.invoices.map(async (item, index) => {
          console.log('loop: ' + index);
          // await this.invoices.reduce(async (item, index) => {

          // if(item.label_available == true)
          await self.void(item.invoice_number).then(() => {
            console.log('then: ' + index);

            self.number++;
          });
        })
      );

      this.delete_loading = false;
      this.dialog = null;
      this.number = 0;
      this.selected = [];
      Event.fire('get-credits');
      Event.fire('update-supplies-orders');
    },
    void: function (val) {
      return new Promise((resolve) => {
        console.log('promise: ' + val);
        this.$http
          .post('/supplies-invoices/void', { id: val })
          .then((response) => {
            resolve({
              response
            });
            // if (response.success)
            //     return true;
            // else
            //     return false;
          })
          .catch((err) => {
            if (err.response.status === 403) {
              this.errorMessage('This action is unauthorized.');
            }
            resolve({
              err
            });
          });
      });
    },
    getTitle(str) {
      console.log(str)
      return 'Void Supplies '.concat(str);
    }
  }
};
</script>
