<template>
  <StallionDialog
    v-model="show"
    :width="500"
    persistent
    title="2-Step Verification Challenge"
  >
    <template #content>
      <v-form @submit.prevent="perform2FAChallenge">
        <v-row>
          <v-col cols="12">
            <p>
              Please open your authenticator app and enter the code to
              continue
            </p>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="code"
              v-validate="'required'"
              autofocus
              type="text"
              outlined
              label="Enter Code"
              data-vv-name="code"
              :error-messages="errs.collect('code')"
              required
            />
          </v-col>
          <v-col cols="6">
            <v-checkbox
              id="use_recovery_code"
              v-model="useRecoveryCode"
              label="Use recovery code"
              dense
            />
          </v-col>
          <v-col cols="6">
            <v-checkbox
              id="trust_device"
              v-model="trustDevice"
              dense
              label="Trust this device for 30 days"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        :loading="processing"
        :disabled="processing"
        @click="perform2FAChallenge"
      >
        Verify
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  name: 'TwoFactorChallengeDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    rememberMe: {
      type: [Boolean, String],
      default: false
    }
  },
  data() {
    return {
      processing: false,
      code: null,
      useRecoveryCode: false,
      trustDevice: false
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    show: function (val) {
      if (val) {
        this.code = null;
        this.useRecoveryCode = null;
      }
    }
  },
  methods: {
    perform2FAChallenge() {
      this.$validator.validate().then((result) => {
        if (result) {
          this.processing = true;
          const payload = {};
          payload[this.useRecoveryCode ? 'recovery_code' : 'code'] = this.code;
          payload['trust_device'] = this.trustDevice;

          this.$http
            .post('/auth/two-factor-challenge', payload)
            .then(() => {
              this.getUser().then(() => {
                this.$router.push('/');
              });
            })
            .catch(() => {
              this.errorMessage('Invalid authenticator code.');
            })
            .finally(() => {
              this.processing = false;
              this.show = false;
            });
        }
      });
    }
  }
};
</script>
