<template>
  <SingleCardLayout>
    <v-flex
      xs12
      class="ma-5"
    >
      <h1>Page Not Found</h1>
      <p>
        Sorry about that, the page you were looking for could not be
        found.
      </p>
      <br>
      <br>
      <router-link to="/dashboard">
        Go Home
      </router-link>
    </v-flex>
  </SingleCardLayout>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {}
};
</script>
