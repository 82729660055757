<template>
  <SingleCardLayout>
    <v-card class="elevation-0 pa-5">
      <form
        id="registerform"
        class="mt-4"
        method="post"
        style="text-align: center;"
        @submit.prevent="register"
      >
        <v-row>
          <v-col cols="12">
            <h2 class="mb-2 font-weight-bold">
              Verify Email
            </h2>
            <p>
              An email has been sent you with a link to verify your account.
            </p>
      
            <div
              v-if="error"
              class="col-xs-12"
            >
              <div
                class="alert alert-danger"
                role="alert"
              >
                <strong>Error</strong>
                {{ error }}
              </div>
            </div>
          </v-col>
      
       
          <v-col cols="12">
            <v-btn
              text
              dusk="logout"
              @click.prevent="logout()"
            >
              <v-icon dark>
                mdi-logout
              </v-icon>Logout
            </v-btn>
            <v-btn
              depressed
              color="primary"
              :loading="loading"
              :disabled="loading"
              dusk="resend"
              @click="sendLink()"
            >
              <v-icon dark>
                mdi-send
              </v-icon>Resend
            </v-btn>
          </v-col>
        </v-row>
      </form>
    </v-card>
  </SingleCardLayout>
</template>

<script>
export default {
  data() {
    return {
      errors: [],
      email: '',
      error: '',
      loading: false
    };
  },
  mounted() {
    console.log('register component mounted.');

    if (this.$route.params.code) this.verifyCode();
  },
  methods: {
    sendLink() {
      this.loading = true;
      this.$http
        .post('/auth/resend-verification', {
          email: this.email
        })
        .then(res => {
          console.log(res);
          if (res.data.success) {
            swal(
              'Success!',
              'A verification token has been sent to your email'
            );
          } else {
            swal('Error!', res.data.errors);
          }
        })
        .catch(() => {
          swal('Error!', 'Unknown error occurred');
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
