<template>
  <v-row dense>
    <two-factor-recovery-codes-dialog
      v-model="showTwoFactorRecoveryCodesDialog"
      :recovery-codes="recoveryCodes"
    />

    <v-col cols="12">
      <v-form>
        <v-row>
          <v-col
            v-if="has2FAEnabled"
            cols="12"
          >
            <h4>Recovery Codes</h4>
            <p>
              Generate new recovery codes to use in case the user loses access
              to their device.
            </p>
            <v-btn
              v-if="can('users.edit')"
              color="primary"
              :disabled="loading"
              :loading="loading"
              @click="generateRecoveryCodes()"
            >
              Generate Recovery Codes
            </v-btn>
          </v-col>

          <v-col cols="12">
            <h4>Revoke 2-Step Verification</h4>
            <p>
              Revoke 2-Step Verification authentication in case the user loses access to
              their 2-Step Verification authenticator app. Once revoked they will be
              required to setup 2-Step Verification again using a new device.
            </p>
            <v-btn
              v-if="can('users.edit')"
              color="primary"
              :disabled="loading || !has2FAEnabled"
              :loading="loading"
              @click="revoke2FA()"
            >
              Revoke
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import TwoFactorRecoveryCodesDialog from '../../../auth/two-factor-recovery-codes-dialog.vue';
export default {
  components: {
    TwoFactorRecoveryCodesDialog
  },
  props: {
    user: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false,
      showTwoFactorRecoveryCodesDialog: false,
      recoveryCodes: []
    };
  },

  computed: {
    has2FAEnabled() {
      return this.user.has_two_factor_enabled;
    }
  },
  methods: {
    async revoke2FA() {
      const isConfirm = await swal({
        title: 'Confirm Action',
        text: 'Do you really want to revoke two-factor of this user?',
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      });

      if (!isConfirm) return;

      this.loading = true;
      this.$http
        .post(`/admin/users/${this.user.id}/revoke-two-factor`, this.user)
        .then((res) => {
          if (res.data.success) {
            swal({
              title: 'Success',
              text: '2-Step Verification successfully revoked!',
              icon: 'success'
            });
            this.user.two_factor_secret = null;
            this.user.two_factor_recovery_codes = null;
            this.user.two_factor_confirmed_at = null;
            this.user.has_two_factor_enabled = false;
          } else {
            swal({
              title: 'Error',
              text: res.data.errors ? res.data.errors : 'Something went wrong.',
              icon: 'error'
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async generateRecoveryCodes() {
      const isConfirm = await swal({
        title: 'Confirm Action',
        text: 'Do you really want to generate new recovery codes?',
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      });

      if (!isConfirm) return;

      this.loading = true;
      this.$http
        .post(
          `/admin/users/${this.user.id}/two-factor-recovery-codes`,
          this.user
        )
        .then((res) => {
          if (res.data.success) {
            this.showTwoFactorRecoveryCodesDialog = true;
            this.recoveryCodes = res.data.recovery_codes;
          } else {
            swal({
              title: 'Error',
              text: res.data.errors ? res.data.errors : 'Something went wrong.',
              icon: 'error'
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
