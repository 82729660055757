<template>
  <transition
    name="component-fade"
    mode="out-in"
  >
    <v-card
      v-if="userStores.user_stores.length > 0"
      class="mb-5 pa-8"
      outlined
    >
      <v-row>
        <v-col>
          <h3>Import from Store</h3>
          <p>Import unfulfilled orders from an integrated store.</p>
        </v-col>
        <v-col cols="auto">
          <v-btn
            v-if="userStores.user_stores.length >= 1"
            color="primary"
            outlined
            :loading="userStores.updating"
            :disabled="userStores.updating || !can('CLIENT.STORES')"
            @click="updateStores()"
          >
            <v-icon small>
              mdi-reload
            </v-icon>
            Sync
          </v-btn>

          <v-btn
            v-if="userStores.user_stores.length >= 1"
            color="primary"
            @click="getOrders()"
          >
            <v-icon small>
              mdi-upload
            </v-icon>Import All
          </v-btn>
        </v-col>
      </v-row>

      <!-- v-if="providers.length > 0" //above-->

      <v-row v-if="userStores.loading && !userStores.autoRefresh">
        <v-col justify-center>
          <v-progress-linear
            indeterminate
            color="#f9ac40"
          />
        </v-col>
      </v-row>

      <v-list two-line>
        <transition-group
          name="component-fade"
          mode="out-in"
        >
          <template v-for="(item, index) in userStores.user_stores">
            <v-list-item
              :key="item.identifier"
              ripple
              @click="getOrders(item)"
            >
              <div class="mr-5">
                <v-icon v-if="item.store.key === 'ebay'">
                  fab fa-ebay
                </v-icon>
                <v-icon v-if="item.store.key === 'amazon'">
                  fab fa-amazon
                </v-icon>
                <v-icon v-if="item.store.key === 'shopify'">
                  fab fa-shopify
                </v-icon>
                <v-icon v-if="item.store.key === 'etsy'">
                  fab fa-etsy
                </v-icon>
                <v-icon v-if="item.store.key === 'shipstation'">
                  mdi-cogs
                </v-icon>
                <v-icon v-if="item.store.key === 'bigcommerce'">
                  $bigcommerce
                </v-icon>
                <v-icon v-if="item.store.key === 'bandcamp'">
                  fab fa-bandcamp
                </v-icon>
                <v-icon v-if="item.store.key === 'woocommerce'">
                  $woocommerce
                </v-icon>
                <v-icon v-if="item.store.key === 'magento'">
                  fab fa-magento
                </v-icon>
                <v-icon v-if="item.store.key === 'walmart_us' || item.store.key === 'walmart_ca'">
                  $walmart
                </v-icon>
                <v-icon v-if="item.store.key === 'api'">
                  mdi-api
                </v-icon>
              </div>
              <v-list-item-content>
                <v-list-item-title
                  v-if="item.nickname == null"
                  v-html="item.identifier"
                />
                <v-list-item-title
                  v-else
                  v-html="item.nickname"
                />
                <v-list-item-subtitle
                  v-if="item.nickname != null"
                  v-html="item.identifier"
                />
                <!-- <v-list-item-subtitle>
                  {{ item.store.description }}
                </v-list-item-subtitle> -->
                <v-list-item-subtitle v-if="!item.auto_refresh">
                  Sync Disabled
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else-if="item.synced_at != null">
                  Last Synced:
                  {{ item.synced_at | datetime_short }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else>
                  Not synced yet
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="item.unfulfilled_count != null">
                  {{ item.unfulfilled_count }} Unfulfilled Orders
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn
                  small
                  icon
                  :loading="item.is_updating == true"
                  :disabled="item.is_updating == true"
                  @click.stop="updateStores(item.id)"
                >
                  <v-icon
                    small
                    color="grey darken-1"
                  >
                    mdi-reload
                  </v-icon>
                </v-btn>
                <v-btn
                  v-if="can(PERMISSIONS.STORES)"
                  small
                  icon
                  @click.stop="openSettings(item)"
                >
                  <v-icon
                    small
                    color="grey darken-1"
                  >
                    mdi-cog
                  </v-icon>
                </v-btn>
                <v-btn
                  v-if="can(PERMISSIONS.STORES)"
                  small
                  icon
                  @click.stop="deleteStore(item.id)"
                >
                  <v-icon
                    small
                    color="grey darken-1"
                  >
                    mdi-delete
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider
              v-if="index + 1 < userStores.user_stores.length"
              :key="index"
              class="ma-0"
            />
          </template>
        </transition-group>
      </v-list>
      <StallionDialog
        v-model="settings"
        :width="800"
        :fullscreen="true"
        :show-actions="false"
        title="Store Settings"
      >
        <template #content>
          <user-store-settings :id="selectedStore" />
        </template>
      </StallionDialog>
    </v-card>
  </transition>
</template>

<script>
import PERMISSIONS from '@/constants/client-permissions';

import { mapState } from 'vuex';
export default {
  data() {
    return {
      timer: '',
      settings: false,
      selectedStore:null,
      PERMISSIONS: PERMISSIONS
    };
  },
  computed: {
    ...mapState(['imp', 'userStores']),
    filters: {
      get() {
        return this.imp.selection.filters;
      }
    }
  },
  mounted() {
    this.$store.dispatch('userStores/getStores');
  },
  methods: {
    async deleteStore(id) {
      const isConfirm = await swal({
        title: 'Confirm Delete',
        text: 'Do you really want to remove this store?',
        icon: 'warning',
        buttons: ['Cancel', 'Remove']
      });

      if (isConfirm) {
        try {
          await this.$store.dispatch('userStores/deleteStore', id);

          this.$notify({
            group: 'main',
            title: 'Store Removed',
            text: 'Your store has been removed from our system.',
            duration: 5000,
            type: 'success'
          });
        } catch (e) {
          console.log(e);
          this.$notify({
            group: 'main',
            title: 'Error removing store',
            text: 'We could not remove this store.',
            duration: 5000,
            type: 'error'
          });
        }
      }
    },
    async openSettings(item) {
      this.selectedStore = item.id;
      this.settings = true;
    },
    getOrders(provider) {
      if (provider) {
        this.imp.selection.filters.user_store_id = [provider.id];
      } else {
        this.imp.selection.filters.user_store_id = [];
      }
      const msg = 'Loading shipments from store';
      this.$store.commit('imp/filtersSet', this.filters);
      this.$store.commit('imp/loaderShow', msg);
      return new Promise(() => {
        this.$http
          .request(`/orders/filter`, {
            method: 'post',
            data: this.filters
          })
          .then((response) => response.data)
          .then((response) => {
            if (response.length === 0) {
              this.$notify({
                group: 'main',
                title: 'Nothing to Import',
                text: `No shipments found ${provider ? 'for ' + provider.identifier : ''}`,
                duration: 5000,
                type: 'error'
              });

              return;
            }

            const payload = {
              shipments: response,
              select_all: false,
              providers: this.imp.selection.filters.user_store_id,
              type: 'store'
            };

            this.$store.commit('imp/selectionStart', payload);
          })
          .finally(() => {
            this.$store.commit('imp/loaderHide');
          });
      });
    },
    updateStores(user_store_id = null) {
      this.$store.dispatch('userStores/updateStores', user_store_id);
    },

    providerImport(provider) {
      const msg = `Importing orders from ${provider.store.key}`;

      this.$store.commit('imp/loaderShow', msg);

      this.$http
        .get(`/user-stores/${provider.id}/import`)
        .then((res) => {
          if (res.data.length === 0) {
            this.$notify({
              group: 'main',
              title: 'Nothing to Import',
              text: `No shipments found for ${provider.identifier}`,
              duration: 5000,
              type: 'error'
            });

            return;
          }

          const payload = {
            shipments: res.data,
            select_all: false,
            providers: [provider.id]
          };

          this.$store.commit('imp/selectionStart', payload);
        })
        .catch((err) => {
          this.checkForActiveBatch();
          if (err.response.status === 422) {
            this.$notify({
              group: 'main',
              title: 'Nothing to Import',
              text: err.response.data.errors,
              duration: 5000,
              type: 'error'
            });
          }

          if (err.response.status === 500) {
            this.$notify({
              group: 'main',
              title: 'Nothing to Import',
              text: err.response.data.errors,
              duration: 5000,
              type: 'error'
            });
          }
        })
        .finally(() => {
          this.imp.file = null;
          this.$store.commit('imp/loaderHide');
        });
    }
  }
};
</script>
