<template>
  <StallionDialog
    v-model="show"
    :width="500"
    :loading="loading_cost"
    title="Dispose Return"
  >
    <template #content>
      <div
        v-if="show"
        class="mx-auto"
        max-width="500"
        flat
      >
        <v-layout
          justify-center
          align-center
          class="mt-4"
        >
          <v-flex xs8>
            <p
              style="font-size: 1.3em;"
              class="text-center mt-6 mb-6"
            >
              Dispose {{ returns.length }}
              {{ returns.length | pluralize('shipment') }}
            </p>

            <h2
              v-if="cost > 0"
              class="text-center mt-6 mb-6"
            >
              {{ returns.length }} x {{ unit_cost | currency }} =
              {{ cost | currency }}
            </h2>

            <ul
              style="list-style-type: none; list-style-position: inside; margin: 0; padding: 0; font-size: 1.3em;"
            >
              <li v-if="cost > 0">
                <strong>Handling Fee:</strong>
                <span class="float-right">{{ cost | currency }}</span>
              </li>
              <li v-if="postage_cost">
                <strong>Postage Cost:</strong>
                <span class="float-right">{{ postage_cost | currency }}</span>
              </li>
              <li>
                <strong>Tax:</strong>
                <span class="float-right">{{ tax | currency }}</span>
              </li>

              <li>
                <strong>Total:</strong>
                <span class="float-right">{{ total | currency }}</span>
              </li>
            </ul>
          </v-flex>
        </v-layout>
      </div>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        :loading="loading"
        :disabled="loading"
        @click="request"
      >
        Confirm
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    returns: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      loading: false,
      loading_cost: false,
      unit_cost: null,
      postage_cost: null,
      cost: null,
      tax: null,
      vat: null,
      duty: null,

      total: null
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    value: function(val) {
      if (val === true) {
        this.getCost();
      }
    }
  },
  mounted() {},
  methods: {
    getCost() {
      this.loading_cost = true;
      var ids = this.returns.map(x => x.id);
      this.$http
        .post('/returns/dispose-cost', {
          ids: ids
        })
        .then(response => response.data)
        .then(response => {
          this.unit_cost = response.unit_cost;
          this.postage_cost = response.postage_cost;
          this.cost = response.handling_fee;
          this.tax = response.tax;
          this.vat = response.vat;
          this.duty = response.duty;
          this.total = response.total;
        })
        .catch(err => {
          const errMsg =
            err.response.data.error ||
            'We could not calculate the cost for the selected items';
          this.errorMessage(errMsg);

          this.show = false;
        })
        .finally(() => {
          this.loading_cost = false;
        });
    },
    request() {
      if (!this.creditCheck(this.total)) {
        return null;
      }

      this.loading = true;

      var ids = this.returns.map(x => x.id);
      this.$http
        .post('/returns/dispose', {
          ids: ids
        })
        .then(response => response.data)
        .then(() => {
          Event.fire('update-returns');
          this.show = false;
          this.returns = [];

          this.successMessage('Returns have been disposed');
        })
        .catch(() => {
          this.errorMessage(
            'We could not process the request for the selected returns.'
          );
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
