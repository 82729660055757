<template>
  <StallionDialog
    v-model="show"
    :width="500"
    :disabled="disabled"
    title="Duplicate Shipments"
  >
    <template #content>
      <v-layout column>
        <v-flex
          xs12
        >
          <div class="dialog-info">
            You are about to duplicate {{ shipments.length }}
            {{ shipments.length | pluralize(' shipment') }}.
          </div>
        </v-flex>
      </v-layout>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        :loading="duplicate_loading"
        :disabled="duplicate_loading"
        @click="duplicate()"
      >
        <v-icon
          small
          dark
        >
          mdi-content-copy
        </v-icon>Duplicate
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    shipments: {
      type: Array,
      default: () => []
    },

    disabled: {
      default: false,
      type: Boolean
    },
    value: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      duplicate_loading: false
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },

  methods: {
    duplicate: function() {
      this.duplicate_loading = true;

      const ids = this.shipments.map(a => a.id);

      this.$http
        .post('/shipments/duplicate', {
          ids
        })
        .then(response => {
          console.log(response.data);
          const shipCodes = response.data.ship_codes.join(', ');
          this.successMessage('Shipment Ids: ' + shipCodes);
        })
        .catch(() => {
          this.errorMessage('Could not duplicate the selected shipments');
        })
        .finally(() => {
          this.duplicate_loading = false;
          this.show = null;

          this.$emit('update');
        });
    }
  }
};
</script>
