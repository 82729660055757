<template>
  <StallionDialog
    v-model="dialog"
    persistent
    :width="800"
    :title="edit ? 'Edit Box' : 'Create Box'"
  >
    <template #content>
      <v-form @submit.prevent>
        <v-container>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="box.name"
                v-validate="'required'"
                outlined
                dense
                :error-messages="errs.collect('name')"
                label="Name"
                data-vv-name="name"
                required
              />
            </v-col>

            <v-col cols="12">
              <v-select
                v-model="box.package_type_id"
                v-validate="'required'"
                outlined
                dense
                :items="packageTypes"
                item-text="description"
                item-value="id"
                :error-messages="errs.collect('package_type_id')"
                label="Package Type"
                data-vv-name="package_type_id"
                required
              />
            </v-col>
          </v-row>
        </v-container>

        <v-container fluid>
          <v-row align="center">
            <v-col
              cols="4"
              md="3"
            >
              <v-text-field
                v-model="box.length"
                v-validate="'required'"
                outlined
                dense
                type="number"
                :error-messages="errs.collect('length')"
                label="Length"
                data-vv-name="length"
                required
              />
            </v-col>
            <v-col
              cols="4"
              md="3"
            >
              <v-text-field
                v-model="box.width"
                v-validate="'required'"
                outlined
                dense
                type="number"
                :error-messages="errs.collect('width')"
                label="Width"
                data-vv-name="width"
                required
              />
            </v-col>
            <v-col
              cols="4"
              md="3"
            >
              <v-text-field
                v-model="box.height"
                v-validate="'required'"
                outlined
                dense
                type="number"
                :error-messages="errs.collect('height')"
                label="Height"
                data-vv-name="height"
                required
              />
            </v-col>

            <v-col
              cols="4"
              md="3"
            >
              <!-- $sizeUnits from mixin.js  -->
              <v-select
                v-model="box.size_unit"
                v-validate="'required'"
                outlined
                dense
                :error-messages="errs.collect('size_unit')"
                label="Unit"
                data-vv-name="size_unit"
                required
                :items="$sizeUnits()"
              />
            </v-col>

            <v-col
              cols="4"
              md="3"
            >
              <v-text-field
                v-model="box.weight"
                v-validate="'required'"
                outlined
                dense
                type="number"
                :error-messages="errs.collect('weight')"
                label="Weight (lb)"
                data-vv-name="weight"
                required
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>

    <template #actions>
      <v-spacer />
      <v-btn
        v-if="edit"
        color="primary"
        :loading="submitting"
        @click="updateBox()"
      >
        Update
      </v-btn>
      <v-btn
        v-else
        color="primary"
        :loading="submitting"
        @click="saveBox()"
      >
        Save
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  data() {
    return {
      box: this.getDefaultBox(),
      edit: false,
      dialog: false,
      submitting: false
    };
  },
  computed: {
    packageTypes: {
      get() {
        const packageTypes = this.$store.getters['main/package_types'];

        return packageTypes
          .filter((item) => {
            return item.package_provider == 'own';
          })
          .map((pkg) => {
            return {
              ...pkg,
              id: parseInt(pkg.id)
            };
          });
      }
    }
  },
  methods: {
    getDefaultBox() {
      return {
        name: '',
        package_type_id: null,
        length: null,
        width: null,
        height: null,
        size_unit: null,
        weight: null
      };
    },
    initDialog() {
      this.dialog = true;
      this.submitting = false;
      this.$validator.reset();
    },
    closeDialog() {
      this.dialog = false;
      this.$emit('update-boxes');
      this.$store.dispatch('boxes/getBoxes');
    },
    startNew() {
      this.initDialog();
      this.box = { ...this.getDefaultBox() };
      this.edit = false;
    },
    startEdit(box) {
      this.initDialog();
      this.box = { ...box };
      this.edit = true;
    },
    async validateBox() {
      return this.$validator.validate().then((result) => {
        return result ? true : false;
      });
    },
    async saveBox() {
      if (!(await this.validateBox())) return;

      this.submitting = true;
      this.$http
        .post(`/boxes`, this.box)
        .then(() => {
          this.closeDialog();
          this.successMessage('Box successfully created!');
        })
        .catch((err) => {
          this.onError(err);
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    async updateBox() {
      if (!(await this.validateBox())) return;

      this.submitting = true;
      this.$http
        .put(`/boxes/${this.box.id}`, this.box)
        .then(() => {
          this.closeDialog();
          this.successMessage('Box successfully updated!');
        })
        .catch((err) => {
          this.onError(err);
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    onError(err) {
      if (
        err.response.status === 422 &&
        typeof err.response.data.errors !== 'undefined'
      ) {
        const errors = err.response.data.errors;
        let message = '';

        for (var field in errors) {
          message += `${errors[field][0]}\n`;
        }

        this.errorMessage(message);
      }
    }
  }
};
</script>
