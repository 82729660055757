<template>
  <FullPageLayout
    title="Filters"
    desc="Order Filters used to quickly access orders matching a set of saved search criteria. Order filters can also be used to apply automation rules."
  >
    <template #page-links>
      <order-links />
    </template>
    <template #default>
      <filter-table ref="filterTable" />
    </template>
  </FullPageLayout>
</template>

<script>
import FilterTable from './FilterTable.vue';
export default {
  metaInfo: {
    title: "Filters | Stallion Express"
  },
  components: {
    FilterTable
  },
  props: {
  },
  data() {
    return {
    };
  },
  methods: {
  }
};
</script>
