<template>
  <FullPageLayout title="Order Scan">
    <template #default>
      <v-flex xs12>
        <v-form>
          <v-container>
            <v-row
              class="pb-2"
              style="justify-content: space-between;"
            >
              <v-text-field
                v-model="barcode"
                label="Manual Entry"
                class="mr-2"
                outlined
                clearable
                dense
                hide-details
                @keydown.enter.prevent="manualScan"
              />

              <v-btn
                color="primary"
                @click="manualScan"
              >
                Submit
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-flex>
      <v-flex
        id="scan-page"
        xs12
      >
        <v-card v-if="order">
          <h1>Order {{ order.id }}</h1>
          <return-order :order="order" />
        </v-card>
        <v-alert
          v-else
          outlined
          type="success"
        >
          Ready to Scan
        </v-alert>
        <v-flex
          v-for="(item, index) in errors"
          :key="index"
          xs12
        >
          <v-alert
            class="mt-3"
            :value="true"
            dismissible
            type="error"
            transition="fade-transition"
          >
            {{ item }}
          </v-alert>
        </v-flex>
      </v-flex>
    </template>
  </FullPageLayout>
</template>

<script>
export default {
  data() {
    return {
      order: null,
      warnings: [],
      activeBtn: 1,
      showNav: true,
      barcodes: [],
      box: 0,
      barcode: null,
      selected: [],
      rack: null,
      headers: [
        {
          text: 'Barcode',
          sortable: false,
          value: 'barcode'
        },
        // {
        //   text: 'Item',
        //   sortable: false,
        //   value: 'item'
        // },
        {
          text: 'Scan Type',
          sortable: false,
          value: 'status'
        },

        {
          text: 'Created At',
          sortable: false,
          value: 'created_at'
        }
      ],
      pagination: {
        page: 1,
        itemsPerPage: 200,
        sortBy: null,
        descending: false
      },
      loading: false,
      errors: []
    };
  },
  computed: {},
  created() {
    document.activeElement.blur();
    // Add barcode scan listener and pass the callback function
    this.$barcodeScanner.init(this.onBarcodeScanned);
  },
  destroyed() {
    // Remove listener when component is destroyed
    this.$barcodeScanner.destroy();
  },
  mounted() {
    console.log('scan-page');
  },
  methods: {
    manualScan() {
      this.onBarcodeScanned('INVOICE' + this.barcode);

      this.barcode = null;
    },
    test() {
      var x = document.activeElement.tagName;

      return x;
    },
    // Create callback function to receive barcode when the scanner is already done
    onBarcodeScanned(barcode) {
      console.log(barcode);

      this.$notify({
        group: 'main',
        title: 'Item Scanned',
        text: barcode,
        duration: 3000
      });

      // let self = this;

      this.scan(barcode).then(res => {
        console.log(res);

        this.order = res.order;
      });

      // do something...
    },
    // Reset to the last barcode before hitting enter (whatever anything in the input box)
    resetBarcode() {
      this.$barcodeScanner.getPreviousCode();
      // do something...
    },
    scan(barcode) {
      return new Promise((resolve, reject) => {
        this.$http
          .post('/admin/scan/return-invoices/order', { code: barcode })
          .then(response => response.data)
          .then(response => {
            if (response.success == true) {
              this.playSuccessSound();
              resolve({
                order: response.order
              });
            } else {
              this.playErrorSound();
              this.errors.push(response.error);
              reject({});
            }
          })
          .catch(err => {
            this.playErrorSound();
            console.log(err.response);
            this.errors.push('Server error');
            reject({});
          });
      });
    }
  }
};
</script>
