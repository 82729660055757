<template>
  <v-layout
    justify-center
    wrap
  >
    <v-flex
      xs12
      sm8
      md6
      mb-12
    >
      <v-card class="elevation-5">
        <v-card-text>
          <v-flex
            xs12
            text-center
          >
            <h2 class="font-weight-bold mt-4">
              From
            </h2>
          </v-flex>

          <v-form @submit.prevent>
            <v-container fluid>
              <v-row align="center">
                <v-col md="6">
                  <v-text-field
                    id="name"
                    ref="search"
                    v-model="shipment.return_name"
                    v-validate="'required'"
                    :error-messages="errs.collect('name')"
                    label="Name"
                    data-vv-name="name"
                    required
                    dusk="sf_name"
                    hint="Tip: Paste your recipient's full address here"
                    @paste="onPaste($event)"
                  />
                </v-col>
                <v-col md="6">
                  <v-text-field
                    ref="company"
                    v-model="shipment.return_company"
                    v-validate
                    :error-messages="errs.collect('company')"
                    label="Company"
                    data-vv-name="company"
                    required
                  />
                </v-col>
                <v-col cols="12">
                  <!-- Address1 -->

                  <div class="d-flex">
                    <v-text-field
                      id="address1_single"
                      ref="autocomplete"
                      v-model="shipment.return_address1"
                      v-validate="'required|max:50'"
                      :error-messages="errs.collect('address1')"
                      data-vv-name="address1"
                      :placeholder="''"
                      label="Address 1"
                      dusk="sf_address1"
                    />
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          class="mt-3"
                          v-on="on"
                        >
                          <v-icon color="orange lighten-1">
                            mdi-information-outline
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        Addresses will be validated for Canada and United
                        States. <br>
                        Please verify your recipients address on your own accord
                        for International shipments as there is no address
                        validation.</span>
                    </v-tooltip>
                  </div>
                </v-col>

                <!-- Address2 -->
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    ref="r_address2"
                    v-model="shipment.return_address2"
                    v-validate
                    :error-messages="errs.collect('address2')"
                    label="Address 2"
                    data-vv-name="address2"
                    required
                  />
                </v-col>

                <!-- City -->
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="shipment.return_city"
                    v-validate="'required'"
                    :error-messages="errs.collect('city')"
                    label="City"
                    data-vv-name="city"
                    required
                    dusk="sf_city"
                  />
                </v-col>

                <!-- Country code -->
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-model="shipment.return_country_code"
                    v-validate="'required'"
                    :error-messages="errs.collect('country_code')"
                    data-vv-name="country_code"
                    :items="countries"
                    label="Country"
                    item-text="name"
                    item-value="code"
                    :search-input.sync="searchInput"
                    dense
                    dusk="sf_country"
                  />
                </v-col>

                <!-- Province/State -->
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-if="shipment.return_country_code == 'CA'"
                    v-model="shipment.return_province_code"
                    v-validate="'required|max:2'"
                    :error-messages="errs.collect('province_code')"
                    data-vv-name="province_code"
                    :items="provinces"
                    label="Province"
                    item-text="name"
                    item-value="code"
                    :search-input.sync="searchInput2"
                    dense
                    dusk="sf_province"
                  />

                  <v-autocomplete
                    v-else-if="shipment.return_country_code == 'US'"
                    v-model="shipment.return_province_code"
                    v-validate="'required|max:2'"
                    :error-messages="errs.collect('province_code')"
                    data-vv-name="province_code"
                    :items="states"
                    label="State"
                    item-text="name"
                    item-value="code"
                    :search-input.sync="searchInput2"
                    dense
                    dusk="sf_province"
                  />

                  <v-text-field
                    v-else
                    v-model="shipment.return_province_code"
                    dusk="sf_province"
                    label="Province/State"
                  />
                </v-col>

                <!-- Postal Code -->
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-if="shipment.return_country_code == 'CA'"
                    v-model="shipment.return_postal_code"
                    v-mask="'A#A #A#'"
                    v-validate="{
                      required: true,
                      regex: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/
                    }"
                    validate-on-blur
                    :error-messages="errs.collect('postal_code')"
                    label="Postal Code"
                    data-vv-name="postal_code"
                    dusk="sf_postal_code"
                  />

                  <v-text-field
                    v-else-if="shipment.return_country_code == 'US'"
                    v-model="shipment.return_postal_code"
                    v-validate="{
                      required: true,
                      regex: /(^\d{5}$)|(^\d{5}-\d{4}$)/
                    }"
                    validate-on-blur
                    :class="{ 'is-invalid': errs.first('postal_code') }"
                    :error-messages="errs.collect('postal_code')"
                    label="Zip Code"
                    data-vv-name="postal_code"
                    dusk="sf_postal_code"
                  />

                  <v-text-field
                    v-else
                    v-model="shipment.return_postal_code"
                    :error-messages="errs.collect('postal_code')"
                    label="Postal Code"
                    data-vv-name="postal_code"
                    dusk="sf_postal_code"
                  />
                </v-col>

                <!-- Phone -->
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-if="
                      shipment.return_country_code == 'CA' ||
                        shipment.return_country_code == 'US'
                    "
                    v-model="shipment.return_phone"
                    v-validate
                    :error-messages="errs.collect('phone')"
                    label="Phone (Recommended)"
                    data-vv-name="phone"
                    required
                  />

                  <v-text-field
                    v-else
                    v-model="shipment.return_phone"
                    v-validate
                    :error-messages="errs.collect('phone')"
                    label="Phone"
                    data-vv-name="phone"
                    required
                  />
                </v-col>

               
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="shipment.return_email"
                    v-validate="'email'"
                    :error-messages="errs.collect('email')"
                    label="Email"
                    data-vv-name="email"
                    required
                  />
                </v-col>
              </v-row>
            </v-container>

            <v-layout
              align-center
              class="mt-4"
            >
              <v-flex
                xs12
                text-center
              >
                <v-btn
                  type="submit"
                  large
                  depressed
                  color="primary"
                  dusk="sf_next"
                  :disabled="$store.getters['singleFlow/loading']"
                  @click="save('address_verification')"
                >
                  Next
                  <v-icon dark>
                    mdi-arrow-right
                  </v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import CONSTANTS from '@/constants';

export default {
  data() {
    return {
      test22: false,
      editShipFromAddress: false,
      err: {},
      countries: CONSTANTS.COUNTRIES,
      provinces: CONSTANTS.PROVINCES,
      states: CONSTANTS.STATES,
      isReturnSet: false,
      magic_flag: false,
      ignoreCountries: ['US', 'AS', 'GU', 'MP', 'PR', 'VI', 'UM', 'CA'],
      searchInput: '',
      searchInput2: '',
      show_info: null,
      vat_type: null,
      vat_types: [],
      eu_countries: CONSTANTS.EU_COUNTRIES,
      tax_countries: CONSTANTS.TAX_COUNTRIES,
    };
  },
  computed: {
    shipment: {
      get() {
        return this.$store.state.singleFlow.shipment;
      },
      set(value) {
        this.$store.state.singleFlow.shipment = value;
      }
    },
    step: {
      get() {
        return this.$store.state.singleFlow.step;
      },
      set(value) {
        this.$store.state.singleFlow.step = value;
      }
    },
    vat_countries() {
      return this.eu_countries.concat(['GB']);
    },
    vat_validation() {
      if (this.vat_type == 1) {
        return {
          regex: /IM\d{10}$/
        };
      } else {
        return {
          max: 25
        };
      }
    },
    vat_hint() {
      if (this.vat_type == 1) {
        return 'IM + 10 digits';
      } else {
        return null;
      }
    },
    processing: {
      get() {
        return this.$store.state.singleFlow.processing;
      },
      set(value) {
        this.$store.state.singleFlow.processing = value;
      }
    }
  },
  watch: {
    step: {
      immediate: true,
      handler(newValue) {
        if (newValue == 1) {
          var self = this; //code before the pause
          setTimeout(function () {
            if (self.$refs.search) {
              self.$refs.search.focus();
            }
          }, 100);
        }
      }
    },
    'shipment.return_country_code': function () {
      // this.getVATypes();
    }
  },
  mounted() {
    // window.addEventListener('keydown', (e) => {
    //   if ((this.processing == 0 && this.step == 1 && e.key == 'Enter')) {
    //     this.save();
    //   }
    // });

    Event.listen('reset-singleflow', function () {
      this.$validator.reset();
      this.errs.clear();
    });

    var self = this; //code before the pause
    setTimeout(function () {
      if (self.$refs.search) {
        self.$refs.search.focus();
      }
    }, 100);

    if (typeof google !== 'undefined') {
      try {
        var element = document.getElementById('address1_single');

        // geocode instructs the Place Autocomplete service to return only geocoding results, rather than business results. Generally, you use this request to disambiguate results where the location specified may be indeterminate.
        // address instructs the Place Autocomplete service to return only geocoding results with a precise address. Generally, you use this request when you know the user will be looking for a fully specified address.
        // establishment instructs the Place Autocomplete service to return only business results.

        // var defaultBounds = new google.maps.LatLngBounds(
        //   new google.maps.LatLng(-55, -130),
        //   new google.maps.LatLng(-24, -49)
        // );

        const options = {
          // bounds: defaultBounds,
          // componentRestrictions: { country: 'us' },
          fields: ['address_components'],
          strictBounds: false,
          types: ['address']
        };

        var autocomplete = new google.maps.places.Autocomplete(
          element,
          options
        );

        autocomplete.addListener('place_changed', () => {
          // Get the place details from the autocomplete object.
          const place = autocomplete.getPlace();
          let address1 = '';
          let postcode = '';

          this.shipment.return_address1 = null;
          this.shipment.return_address2 = null;
          this.shipment.return_city = null;
          this.shipment.return_province_code = null;
          this.shipment.return_country_code = null;
          this.shipment.return_postal_code = null;

          // Get each component of the address from the place details,
          // and then fill-in the corresponding field on the form.
          // place.address_components are google.maps.GeocoderAddressComponent objects
          // which are documented at http://goo.gle/3l5i5Mr
          console.log(place.address_components);
          for (const component of place.address_components) {
            // @ts-ignore remove once typings fixed
            const componentType = component.types[0];

            switch (componentType) {
              case 'street_number': {
                address1 = `${component.long_name} ${address1}`;
                break;
              }

              case 'subpremise': {
                this.shipment.return_address2 = component.short_name;
                break;
              }

              case 'route': {
                address1 += component.short_name;
                break;
              }

              case 'postal_code': {
                postcode = `${component.long_name}${postcode}`;
                break;
              }

              case 'postal_code_suffix': {
                postcode = `${postcode}-${component.long_name}`;
                break;
              }
              case 'locality':
                this.shipment.return_city = component.long_name;
                break;
              case 'administrative_area_level_1': {
                this.shipment.return_province_code = component.short_name;
                break;
              }
              case 'country':
                this.shipment.return_country_code = component.short_name;
                break;
            }
          }

          this.shipment.return_address1 = address1;
          this.shipment.return_postal_code = postcode;
          // After filling the form with address components from the Autocomplete
          // prediction, set cursor focus on the second address line to encourage
          // entry of subpremise information such as apartment, unit, or floor number.
          // address2Field.focus();

          this.$refs.r_address2.focus();
        });
      } catch (ex) {
        console.log(ex);
      }
    }
  },
  methods: {
    onPaste(e) {
      // Get pasted data via clipboard API
      var clipboardData, pastedData;

      clipboardData = e.clipboardData || window.clipboardData;
      pastedData = clipboardData.getData('Text');

      var string = pastedData.trim();

      this.$http
        .post('/shipments/address-parse', { address: string })
        .then((res) => {
          const {
            name,
            address1,
            address2,
            city,
            province_code,
            postal_code,
            country_code
          } = res.data;

          this.shipment.return_country_code = country_code;
          this.shipment.return_name = name;
          this.shipment.return_company = name;
          this.shipment.return_address1 = address1;
          this.shipment.return_address2 = address2;
          this.shipment.return_city = city;
          this.shipment.return_province_code = province_code;
          this.shipment.return_postal_code = postal_code;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    setFocus: function () {
      this.$refs.search.focus();
    },
    save() {
      this.$validator.validate().then((result) => {
        if (result) {
          this.processing++;
          console.log(this.shipment);
          if (this.shipment.id != null) {
            console.log('update');
            this.$http
              .put('/shipments/' + this.shipment.id, this.shipment)
              .then((res) => {
                this.shipment = res.data;

                this.$store.commit('singleFlow/next');
              })
              .catch((err) => {
                this.$notify({
                  group: 'main',
                  title: 'Error',
                  text: err,
                  duration: 5000,
                  type: 'error'
                });
              })
              .finally(() => {
                this.processing--;
              });
          } else {
            this.$http
              .post('/shipments', this.shipment)
              .then((res) => {
                this.shipment = res.data;
                this.$store.commit('singleFlow/next');
              })
              .catch((err) => {
                this.$notify({
                  group: 'main',
                  title: 'Error',
                  text: err,
                  duration: 5000,
                  type: 'error'
                });
              })
              .finally(() => {
                this.processing--;
              });
          }
        }
      });
    },
    // Set addres from google address
    updateAddress(value, value2) {
      console.log('update');

      if (['AS', 'GU', 'MP', 'PR', 'VI', 'UM'].includes(value.country)) {
        this.shipment.return_country_code = 'US';
        this.shipment.return_province_code = value.country;
      } else {
        this.shipment.return_country_code = value.country;
        this.shipment.return_province_code = value.administrative_area_level_1;
      }

      this.shipment.return_address1 = value.street_number + ' ' + value.route;

      if (value.locality) {
        this.shipment.return_city = value.locality;
      } else {
        this.shipment.return_city = value2.address_components[2].long_name;
      }

      this.shipment.return_postal_code = value.postal_code;
    },
    // Get the user's return address for the country

    geolocate() {
      console.log('geolocate');

      if (this.enableGeolocation) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            let geolocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            new google.maps.Circle({
              center: geolocation,
              radius: position.coords.accuracy
            });
          });
        }
      }
    }
  }
};
</script>
<style scoped>
#address1_single::placeholder {
  visibility: hidden !important;
}
</style>
