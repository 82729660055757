<template>
  <v-container grid-list-md>
    <div class="row">
      <div class="col-12">
        <div class="mb-2">
          <h1>
            <span> <i class="fa fa-question" /> FAQ </span>
          </h1>
          <nav
            class="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block"
            aria-label="breadcrumb"
          >
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link
                  class="active"
                  to="/dashboard"
                  aria-expanded="false"
                >
                  <span>Settings</span>
                </router-link>
              </li>

              <li
                class="active breadcrumb-item"
                aria-current="page"
              >
                <span>FAQ</span>
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
    <div class="separator mb-12" />
    <div class="row">
      <div class="col-md-6 mb-6">
        <div class="card">
          <div class="card-body">
            <h3>HOW CAN I CHANGE MY SHIPPING ADDRESS?</h3>
            <p class="small">
              By default, the last used shipping address will be saved into to
              your Sample Store account. When you are checking out your order,
              the default shipping address will be displayed and you have the
              option to amend it if you need to.
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-6">
        <div class="card">
          <div class="card-body">
            <h3>HOW CAN I USE MY REMAINING ACCOUNT CREDITS?</h3>
            <p class="small">
              By default, the last used shipping address will be saved into to
              your Sample Store account. When you are checking out your order,
              the default shipping address will be displayed and you have the
              option to amend it if you need to.
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-6">
        <div class="card">
          <div class="card-body">
            <h3>HOW CAN I CHANGE MY SHIPPING ADDRESS?</h3>
            <p class="small">
              By default, the last used shipping address will be saved into to
              your Sample Store account. When you are checking out your order,
              the default shipping address will be displayed and you have the
              option to amend it if you need to.
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-6">
        <div class="card">
          <div class="card-body">
            <h3>HOW CAN I CHANGE MY SHIPPING ADDRESS?</h3>
            <p class="small">
              By default, the last used shipping address will be saved into to
              your Sample Store account. When you are checking out your order,
              the default shipping address will be displayed and you have the
              option to amend it if you need to.
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-6">
        <div class="card">
          <div class="card-body">
            <h3>HOW CAN I CHANGE MY SHIPPING ADDRESS?</h3>
            <p class="small">
              By default, the last used shipping address will be saved into to
              your Sample Store account. When you are checking out your order,
              the default shipping address will be displayed and you have the
              option to amend it if you need to.
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-6">
        <div class="card">
          <div class="card-body">
            <h3>HOW CAN I CHANGE MY SHIPPING ADDRESS?</h3>
            <p class="small">
              By default, the last used shipping address will be saved into to
              your Sample Store account. When you are checking out your order,
              the default shipping address will be displayed and you have the
              option to amend it if you need to.
            </p>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      USPSPackageTypes: [
        {
          type: 'Small text Rate Box',
          description: '(8.6875" x 5.4375" x 1.75")'
        },
        {
          type: 'text Rate Medium Box',
          description: '(11.25" x 8.75" x 6" or 14" x 12" x 3.5")'
        },
        {
          type: 'Large text Rate Box',
          description: '(12.25" x 12.25" x 6" or 24.0625" x 11.875" x 3.125")'
        },
        {
          type: 'Regional Rate Box A',
          description: '(10.125" x 7.125" x 5" or 11.0625" x 2.5" x 12.8125")'
        },
        {
          type: 'Regional Rate Box B1',
          description: '(12.5" x 10.5" x 5.5")'
        },
        {
          type: 'Regional Rate Box B2',
          description: '(14.5" x 3" x 16.25")'
        }
      ],
      stampsRates: []
    };
  },
  mounted() {},
  created() {
    this.getAllStampsRates();
  },
  methods: {
    getAllStampsRates: function() {
      this.$http.get('/RocketShipAPI/getAllStampsRates').then(() => {});
    }
  }
};
</script>
