<template>
  <div>
    <v-row dense>
      <v-col
        cols="12"
        md="6"
      >
        <v-autocomplete
          v-model="user_id"
          outlined
          dense
          prepend-inner-icon="mdi-pound-box"
          table
          :items="userIDs"
          :search-input.sync="searchID"
          hide-selected
          label="User ID"
          clearable
          @change="submitSearch()"
        />
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <v-autocomplete
          v-model="email"
          outlined
          dense
          prepend-inner-icon="email"
          :items="userEmails"
          :search-input.sync="searchEmail"
          hide-selected
          label="Email"
          clearable
          @change="submitSearch()"
        />
      </v-col>
    </v-row>

    <v-card outlined>
      <v-data-table
        class="table-striped"
        :headers="headers"
        :items="items"
        :loading="loading"
        :items-per-page="10"
      >
        <template #item.period="{ item }">
          {{ item.start_date }} - {{ item.end_date }}
        </template>

        <template #item.actions="{ item }">
          <v-btn
            color="primary"
            download
            small
            @click.prevent="download(item.path)"
          >
            <v-icon
              small
              dark
            >
              mdi-download
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      items: [],      
      searchID: null,
      searchEmail: null,
      user_id: null,
      email: null,
      userIDs: [],
      userEmails: [],
      users: [],

      options: {
        sortBy: ['created_at'],
        sortDesc: [true]
      },

      headers: [
        {
          text: 'User Id',
          sortable: false,
          value: 'user.id'
        },
        {
          text: 'Email',
          sortable: false,
          value: 'user.email'
        },
        {
          text: 'Invoice ID',
          sortable: false,
          value: 'invoice_id'
        },
        {
          text: 'Total',
          sortable: false,
          value: 'total'
        },
        {
          text: 'Period',
          sortable: false,
          align: 'center',
          value: 'period'
        },
        {
          text: '',
          sortable: false,
          align: 'right',
          value: 'actions'
        }
      ]
    };
  },
  watch: {
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    },
    user_id: function (val) {
      if (val) {
        var index = this.users.map((e) => e.id).indexOf(val);

        this.email = this.users[index].email;
      } else {
        this.email = null;
      }
    },
    email: function (val) {
      if (val) {
        var index = this.users.map((e) => e.email).indexOf(val);

        this.user_id = this.users[index].id;
      } else {
        this.user_id = null;
      }
    },
  },
  mounted() {
    this.getUsers();
    this.updateTable();
    Event.listen('update-rules', () => {
      this.updateTable();
    });
  },
  methods: {
    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.options.page = 1;
      this.timer = setTimeout(function () {
        self.updateTable();
      }, ms);
    },
    updateTable() {
      this.items = [];

      this.$http
        .request(`/admin/drop-off/invoices`, {
          method: 'get',
          params: { user_id: this.user_id }
        })
        .then(response => response.data)
        .then(response => {
          this.items = response.data;
        });
    },
    download: function(data) {
      this.$http({
        method: 'post',
        url: '/admin/drop-off/invoices/download',
        data: {
          file: data
        }
      }).then(response => {
        this.downloadFileUrl(response.data, 'invoices');
      });
    },
    getUsers() {
      this.$http
        .get('/admin/users/all')
        .then((response) => response.data)
        .then((response) => {
          this.userIDs = response.map((e) => e.id);
          this.userEmails = response.map((e) => e.email);
          this.users = response;
        })
        .catch(() => {});
    }
  }
};
</script>
