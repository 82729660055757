<template>
  <div>
    <h2 class="text-center">
      Return Address
    </h2>
    <hr>
    <ul v-if="shipment.return_address1 !== null">
      <li v-if="shipment.return_name">
        {{ shipment.return_name }}
      </li>
      <li v-if="shipment.return_address1">
        {{ shipment.return_address1 }}
      </li>
      <li v-if="shipment.return_address2">
        {{ shipment.return_address2 }}
      </li>
      <li v-if="shipment.return_city">
        {{
          shipment.return_city +
            ' ' +
            shipment.return_province_code +
            ' ' +
            shipment.return_postal_code
        }}
      </li>
      <li v-if="shipment.return_phone">
        Phone: {{ shipment.return_phone | phone }}
      </li>
      <li v-if="shipment.return_country_code">
        {{ shipment.return_country_code }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    shipment: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
