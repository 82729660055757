<template>
  <FullPageLayout
    :title="'Supplies Order - '.concat(id)"
    :bread-crumbs="breadCrumbs"
  >
    <template #default>
      <v-card
        class="elevation-0"
        style="border: 1px solid #e7e7e7;"
      >
        <table class="cart-table px-6 py-2">
          <thead>
            <tr>
              <th class="text-center font-weight-bold">
                Item
              </th>
              <th class="text-center font-weight-bold">
                Quantity
              </th>
              <th class="text-right font-weight-bold">
                Unit Price
              </th>
              <th class="text-right font-weight-bold px-3">
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in invoice.items"
              :key="item.id"
            >
              <td class="text-center">
                <v-layout
                  wrap
                  align-center
                >
                  <v-flex
                    xs12
                    text-center
                  >
                    <h2>{{ item.supply.name }}</h2>
                  </v-flex>
                </v-layout>
              </td>

              <td>
                <v-layout
                  wrap
                  align-center
                >
                  <v-flex
                    xs12
                    text-center
                  >
                    {{ item.quantity }}
                  </v-flex>
                </v-layout>
              </td>
              <td class="text-right">
                {{ item.price | currency }}
              </td>
              <td class="text-right">
                {{ (item.quantity * item.price) | currency }}
              </td>
              <!-- <td></td> -->
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td
                class="text-right subheading font-weight-bold"
                colspan="3"
              >
                Subtotal
              </td>
              <td class="text-right">
                {{ invoice.subtotal | currency }}
              </td>
            </tr>
            <tr>
              <td
                class="text-right subheading font-weight-bold"
                colspan="3"
              >
                Tax
              </td>
              <td class="text-right">
                {{ invoice.tax | currency }}
              </td>
            </tr>
            <tr>
              <td
                class="text-right subheading font-weight-bold"
                colspan="3"
              >
                Total
              </td>
              <td class="text-right">
                {{ invoice.total | currency }}
              </td>
            </tr>
          </tfoot>
        </table>
      </v-card>
    </template>
    
    <template #footer>
      <PrintSuppliesOrderDialog
        :invoices="[invoice]"
        :show="show"
      />
    </template>
  </FullPageLayout>
</template>

<script>
import PrintSuppliesOrderDialog from '@/components/shared/PrintSuppliesOrderDialog.vue';
export default {
  metaInfo() {
    return {
      title: `Supplies Order ${this.$route.params.id} | Stallion Express`
    }
  },
  components:{
    PrintSuppliesOrderDialog
  },
  data() {
    return {
      show: false,
      invoice: {},
      id: null,
      breadCrumbs: [
        {
          text: 'Home',
          exact: true,
          to: '/dashboard'
        },
        {
          text: 'Supplies',
          exact: true,
          to: '/supplies/store'
        },
        {
          text: 'Orders',
          exact: true,
          to: '/supplies/orders'
        },
        {
          text: this.$route.params.id,
          exact: true,
          to: '/supplies/orders/' + this.$route.params.id
        }
      ]
    };
  },
  computed: {},
  watch: {},
  mounted() {
    if (this.$route.query.print) {
      this.show = true;
    }
    this.id = this.$route.params.id;

    this.getInvoice();
  },
  methods: {
    getInvoice: function() {
      this.loading = true;

      this.$http
        .get(`/supplies-invoices/${this.id}`)
        .then(response => {
          this.invoice = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped>
.cart-table thead td,
.cart-table tbody td {
  border-top: none !important;
}
</style>
