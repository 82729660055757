<template>
  <div>
    <v-form @submit.prevent="submitSearch()">
      <v-container pa-0>
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-autocomplete
              v-model="filters.selectedRoles"
              :items="filters.roles"
              multiple
              item-text="name"
              item-value="name"
              label="Roles"
              return-object
              clearable
              @change="updateTable()"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-autocomplete
              v-model="filters.selectedPermissions"
              :items="filters.permissions"
              multiple
              item-text="name"
              item-value="name"
              label="Permissons"
              return-object
              clearable
              @change="updateTable()"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="search"
              @input="submitSearch()"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-data-table
      :headers="headers"
      :loading="loading"
      item-key="id"
      :items="items"
      :options.sync="options"
      class="table-striped"
      :server-items-length="totalItems"
      :footer-props="{
        'items-per-page-options': [50, 100, 200, 500]
      }"
      :items-per-page="25"
      @click:row="rowClick"
    >
      <template #item.roles="{ item }">
        {{ getExcerpt(item.roles) }}
      </template>

      <template #item.permissions="{ item }">
        {{ getExcerpt(item.permissions) }}
      </template>
    </v-data-table>

    <AssignRoleAndPermissionsToUserDialog
      v-model="showAssignToUser"
      :user="selectedUser"
      :roles="assignableRolesAndPermissions.roles"
      :permissions="assignableRolesAndPermissions.permissions"
      @reloadUsers="updateTable"
    />
  </div>
</template>

<script>
import AssignRoleAndPermissionsToUserDialog from './AssignRoleAndPermissionsToUserDialog.vue'
export default {
  components:{
    AssignRoleAndPermissionsToUserDialog
  },
  props: {
    filters: {
      type: Object,
      default() {
        return {
          selectedRoles: [],
          selectedPermissions: []
        };
      }
    },
    assignableRolesAndPermissions: {
      type: Object,
      default() {
        return {
          roles: [],
          permissions: []
        };
      }
    }
  },
  data() {
    return {
      search: null,
      tab: null,
      headers: [
        {
          text: 'ID',
          align: 'left',
          value: 'id'
        },
        {
          text: 'Username',
          align: 'left',
          value: 'username'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Business',
          value: 'business_name'
        },
        {
          text: 'Roles',
          value: 'roles',
          sortable: false
        },
        {
          text: 'Permissions',
          value: 'permissions',
          sortable: false
        }
      ],

      options: {
        sortBy: ['created_at'],
        sortDesc: [true]
      },

      totalItems: 0,
      items: [],

      loading: false,

      timer: null,
      cancelSource: null,
      showAssignToUser: false,
      selectedUser: {}
    };
  },

  watch: {
    '$route.query.action'() {
      this.updateTable();
    },
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    }
  },
  mounted() {
    this.updateTable();
  },

  methods: {
    getExcerpt(arr = [], key = 'name') {
      let names = [];
      for (var i = 0; i < arr.length; i++) {
        if (i >= 2) {
          names.push('...');
          break;
        }
        names.push(arr[i][key]);
      }
      return names.join(', ');
    },
    rowClick(item) {
      this.showAssignToUser = true;
      this.selectedUser = item;
    },
    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.options.page = 1;
      this.timer = setTimeout(function () {
        self.updateTable();
      }, ms);
    },
    updateTable() {
      this.totalItems = 0;
      this.items = [];

      this.getUsers().then((data) => {
        this.items = data.items;
        this.totalItems = data.total;
      });
    },
    getUsers() {
      // console.log(this.filters);
      this.loading = true;
      return new Promise((resolve) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let items = [];
        let total = 0;
        let pages = 0;

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        var params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sortBy[0],
          desc: sortDesc[0],
          search: this.search,
          roles: _.map(this.filters.selectedRoles, 'name'),
          permissions: _.map(this.filters.selectedPermissions, 'name')
        };

        this.$http
          .request(`/admin/roles-and-permissions/users`, {
            method: 'get',
            params: params,
            cancelToken: this.cancelSource.token
          })

          .then((response) => response.data)
          .then((response) => {
            // console.log(response);
            items = response.data;
            total = response.total;
            pages = response.last_page;

            this.loading = false;
            resolve({
              items,
              total,
              pages
            });
          });
      });
    }
  }
};
</script>
