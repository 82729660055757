<template>
  <div
    v-if="shipment && shipment.return_name"
    xs12
  >
    <h4 v-if="showHeader">
      Return
    </h4>
    <div v-if="shipment.return_name">
      <div v-if="shipment.return_name">
        {{ shipment.return_name }}
      </div>
      <div v-if="shipment.return_address1">
        {{ shipment.return_address1 }}
      </div>
      <div v-if="shipment.return_address2">
        {{ shipment.return_address2 }}
      </div>
      <div v-if="shipment.return_city">
        {{
          shipment.return_city +
            ', ' +
            shipment.return_province_code +
            ' ' +
            shipment.return_postal_code
        }}
      </div>

      <div v-if="shipment.return_country_code">
        {{ shipment.return_country_code }}
      </div>
      <div v-if="shipment.return_phone">
        Phone: {{ shipment.return_phone | phone }}
      </div>
    </div>

    <v-divider
      v-if="showDivider"
      class="details-divider"
    />
  </div>
</template>

<script>
export default {
  props: {
    shipment: {
      type: Object,
      default: null
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    showDivider: {
      type: Boolean,
      default: true
    }
  }
};
</script>
