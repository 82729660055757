<template>
  <StallionDialog
    v-model="dialog"
    :width="500"
    :disabled="disabled"
    title="Delete Shipments"
  >
    <slot
      :disabled="disabled"
      v-on="on"
    />

    <template #content>
      <v-layout column>
        <v-flex
          xs12
        >
          <div class="dialog-info">
            You are about to delete {{ shipments.length }}
            {{ shipments.length | pluralize(' shipment') }}. You will no
            longer be able to view them in your dashboard.
          </div>
        </v-flex>

        <!-- <div v-if="delete_loading"> -->
        <v-progress-linear v-model="valueDeterminate" />

        <v-flex
          xs12
          text-center
        >
          {{ number }} of {{ shipments.length }}
        </v-flex>
        <!-- </div> -->
      </v-layout>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        :loading="delete_loading"
        :disabled="delete_loading"
        @click="remove()"
      >
        <v-icon
          small
          dark
        >
          mdi-delete
        </v-icon>Delete
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    shipments: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false,
      delete_loading: false,
      number: 0
    };
  },
  computed: {
    valueDeterminate() {
      if (this.number > 0)
        return Math.round((this.number / this.shipments.length) * 100);
      else return 0;
    }
  },
  methods: {
    getExport() {
      this.loading1 = true;

      this.$http
        .get(
          `/export?export_type=${this.export_type}&start_date=${
            this.start_dropoff_date
          }&end_date=${this.end_dropoff_date}`
        )
        .then(response => {
          this.downloadFileUrl(response.data, this.export_type + '.csv', 'text/csv');
        })
        .catch(() => {})
        .then(() => {
          this.loading1 = false;
        });
    }
  }
};
</script>
