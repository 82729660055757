<template>
  <v-row>
    <v-col class="text-right">
      <v-btn
        small
       
        color="primary"
        
        @click="openManageColumnsDialog"
      >
        Columns
      </v-btn>
    </v-col>

    <manage-column-dialog
      v-model="showManageColumnsDialog"
      :table="table"
      :columns="columns"
      :update-column-handler="updateColumnHandler"
    />
  </v-row>
</template>

<script>
export default {
  props: {
    table: {
      type: String,
      required: true
    },
    columns: {
      type: Array,
      default: () => ([])
    },
    updateColumnHandler: {
      default: () => {},
      type: Function
    }
  },
  data() {
    return {
      showManageColumnsDialog: false
    }
  },
  methods: {
    openManageColumnsDialog() {
      this.showManageColumnsDialog = true
    }
  }
};
</script>
