import Vue from 'vue';
import { Validator } from 'vee-validate';
import VeeValidate from 'vee-validate';
import store from "../store";

const VeeValidateConfig = {
  aria: true,
  classNames: {},
  classes: false,
  delay: 0,
  dictionary: null,
  errorBagName: 'errs', // change if property conflicts
  events: 'input|blur',
  fieldsBagName: 'fields',
  i18n: null, // the vue-i18n plugin instance
  i18nRootKey: 'validations', // the nested key under which the validation messages will be located
  inject: true,
  locale: 'en',
  strict: true,
  validity: false
};

const isUnique = (value) => {
  return (
    axios
      // .post('/validate/email', {
      //   email: value
      // })
      .post('/validate/username', {
        username: value
      })
      .then((response) => {
        return {
          valid: response.data.valid,
          data: {
            message: response.data.message
          }
        };
      })
      .catch((err) => {
        return {
          valid: false,
          data: {
            message:
              err.response.statusText + '. Please try again later.' ||
              'Server Error! Please try again later.'
          }
        };
      })
  );
};

// The messages getter may also accept a third parameter that includes the data we returned earlier.
Validator.extend('unique', {
  validate: isUnique,
  getMessage: (field, params, data) => {
    return data.message;
  }
});

const userIdExists = (value) => {
  return axios
    .get(`/admin/users/${value}/exists`)
    .then((response) => {
      return {
        valid: response.data.valid,
        data: {
          message: response.data.message
        }
      };
    });
};

Validator.extend('userIdExists', {
  validate: userIdExists,
  getMessage: (field, params, data) => {
    return data.message;
  }
});
VeeValidate.Validator.extend('atLeastOne', {
  getMessage() {
    return `At least one item must be selected.`;
  },
  validate: () => {
    return this.value.some((list) => list == true);
  }
});

VeeValidate.Validator.extend('verify_password', {
  getMessage: () =>
    `The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character`,
  validate: (value) => {
    var strongRegex = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&()+,-:;<>_~|])(?=.{8,})'
    );
    return strongRegex.test(value);
  }
});

VeeValidate.Validator.extend('matches_auth_business_name', {
  getMessage: () =>
      `The value does not match the business name.`,
  validate: (value) => {
    return value === store.state.auth.user.business_name;
  }
});

VeeValidate.Validator.extend('nullable_email', {
  getMessage: () =>
      `Please enter a valid email address`,
  validate: (value) => {
    if (!value) {
      // Allow empty values
      return true;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value) || 'Please enter a valid email address';
  }
});

Vue.use(VeeValidate, VeeValidateConfig);
