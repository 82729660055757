<template>
  <StallionDialog
    v-model="show"
    :width="600"
    title="Archive Orders"
    :disabled="disabled"
  >
    <template #content>
      <v-layout column>
        <v-flex
          xs12
        >
          <div class="dialog-info">
            You are about to archive {{ orders.length }}
            {{ orders.length | pluralize(' order') }}.
          </div>
        </v-flex>
        <v-flex
          xs12
          text-center
          mt-3
        >
          <v-alert
            text
            type="warning"
          >
            Archiving orders prevents re-importing from your store and excludes them from future Stallion imports.
          </v-alert>
        </v-flex>
      </v-layout>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        :loading="delete_loading"
        :disabled="delete_loading"
        @click="remove()"
      >
        <v-icon
          
          dark
          left
        >
          mdi-archive-outline
        </v-icon>Archive
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    orders: {
      type: Array,
      default: () => []
    },

    disabled: {
      default: false,
      type: Boolean
    }
  },

  data() {
    return {
      delete_loading: false,
      show: false,
      number: 0
    };
  },
  methods: {
    startNew() {
      this.show = true;
    },
    remove: function() {
      console.log('remove');
      this.delete_loading = true;

      const ids = this.orders.map(a => a.id);

      this.$http
        .post('/orders/delete', ids)
        .then(() => {})
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.delete_loading = false;
          this.show = null;
          this.number = 0;

          Event.fire('update-orders');
        });
    }
  }
};
</script>
