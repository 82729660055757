export default {
  DEFAULT_TABLE_COLUMNS: {
    table_columns_client_shipments: [
      { value: 'name', visible: true },
      { value: 'order_id', visible: true },
      { value: 'postage_type_id', visible: true },
      { value: 'postage_rate', visible: true },
      { value: 'status_id', visible: true },
      { value: 'batch_id', visible: true },
      { value: 'closeout_id', visible: true },
      { value: 'created_at', visible: true }
    ],
    table_columns_client_batch_shipments: [
      { value: 'name', visible: true },
      { value: 'order_id', visible: true },
      { value: 'postage_type_id', visible: true },
      { value: 'package_type_id', visible: true },
      { value: 'postage_rate', visible: true },
      { value: 'status_id', visible: true },
      { value: 'package_contents', visible: true },
      { value: 'value', visible: true },
      { value: 'created_at', visible: true },
      { value: 'actions', visible: true }
    ],
    table_columns_client_orders: [
      { value: 'order_id', visible: true },
      { value: 'user_store.identifier', visible: true },
      { value: 'items_count', visible: true },
      { value: 'value', visible: true },
      // { value: 'shipment', visible: true },
      { value: 'country_code', visible: true },
      { value: 'order_at', visible: true }
    ],
    table_columns_import_from_file: [
      { value: 'action', visible: false },
      { value: 'row', visible: true },
      { value: 'sku', visible: true },
      { value: 'name', visible: true },
      { value: 'order_id', visible: true },
      { value: 'display_order_id', visible: false },
      { value: 'package_type_id', visible: true },
      { value: 'needs_postage', visible: true },
      { value: 'postage_type_id', visible: true },
      { value: 'value', visible: true },
      { value: 'weight', visible: true },
      { value: 'weight_unit', visible: true },
      { value: 'length', visible: true },
      { value: 'width', visible: true },
      { value: 'height', visible: true },
      { value: 'size_unit', visible: true },
      { value: 'package_contents', visible: true },
      { value: 'order_at', visible: true },
      { value: 'errors', visible: true }
    ],
    table_columns_import_from_store: [
      { value: 'action', visible: false },
      { value: 'row', visible: true },
      { value: 'sku', visible: true },
      { value: 'order_id', visible: true },
      { value: 'name', visible: true },
      { value: 'country_code', visible: true },
      { value: 'package_type_id', visible: true },
      { value: 'postage_type_id', visible: true },
      { value: 'shipping_cost', visible: true },
      { value: 'value', visible: true },
      { value: 'weight', visible: true },
      { value: 'dims', visible: true },
      { value: 'package_contents', visible: true },
      { value: 'order_at', visible: true },
      { value: 'sub_batch', visible: true }
    ]
  }
}
