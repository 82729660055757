// Constants for printer settings
export default {
    PRINT_FORMATS:{
        PDF: 'PDF',
        ZPL: 'ZPL',
    },
    DOCUMENT_FORMATS: {
        PICK_LIST: 'pick_list',
        PACKING_SLIP: 'packing_slip',
        LABEL: 'label_only',
        LABEL_WITH_PACKING_SLIP: 'label_with_packing_slip',
        ORDER_SUMMARY: 'order_summary'
    },
    DIMENSIONS: {
        FOUR_BY_SIX:'4"x6"',
        LETTER: '8.5"x11"',
    },
    DENSITIES: {
        DEFAULT: 'Default',
        // DPI_152: '152 DPI',
        DPI_203: '203 DPI',
        DPI_300: '300 DPI',
        // DPI_600: '600 DPI',
    },
    PRINT_ACTION: {
        PROMPT:  'Prompt',
        DOWNLOAD: 'Download',
        DIRECT: 'Direct',
    },
};
