<template>
  <SingleCardLayout>
    <div class="mt-4 text-center">
      <h1>
        Verify Your Phone Number
      </h1>
      <p class="mb-5">
        To continue registration, you’ll need to verify your account
        with a valid phone number. You will receive a text message
        with the verification code shortly.
      </p>
    </div>
    <div class="mt-4">
      <verify-phone />
    </div>
  </SingleCardLayout>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {}
};
</script>
<style>
#remember .v-input--selection-controls {
  margin-top: 16px;
  padding-top: 4px;
}

.v-application p {
  margin-bottom: 0px !important;
}

@media only screen and (min-width: 1440px) {
  .login-image {
    width: 400px !important;
  }

  .stallion-title {
    margin-bottom: 50px !important;
  }
}

.login-field {
  max-width: 400px;
}

@media only screen and (max-width: 960px) {
  .login-image {
    display: none !important;
  }

  .image-column {
    height: 40vh !important;
  }
}
</style>
