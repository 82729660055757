<template>
  <StallionDialog
    v-model="localShow"
    :width="500"
    :disabled="disabled"
    title="Add Stock"
  >
    <template #content>
      <v-layout column>
        <v-flex
          xs12
          text-center
          mt-4
          mb-12
        >
          <h4>Add stock to {{ supplies.length }} items</h4>
        </v-flex>
        <v-flex
          xs12
          text-center
          mt-4
          mb-12
        >
          <NumberInputSpinner
            v-model="amount"
            :min="0"
            :max="1000"
            button-class="btn-increment "
            :integer-only="true"
          />
        </v-flex>
      </v-layout>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        :loading="update_loading"
        :disabled="update_loading"
        @click="updateStatuses()"
      >
        Update
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
import NumberInputSpinner from 'vue-number-input-spinner';

export default {
  components: {
    NumberInputSpinner
  },
  props: {
    supplies: {
      type: Array,
      default: () => []
    },
    show: {
      default: false,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      update_loading: false,
      dowload_loading: false,
      statuses: [
        {
          id: 1,
          description: 'Unfulfilled'
        },
        {
          id: 2,
          description: 'Fulfilled'
        },
        {
          id: 3,
          description: 'Voided'
        }
      ],
      amount: 1,
      localShow: this.show // prevent mutating a prop directly
    };
  },
  mounted() {},

  methods: {
    addNew() {
      this.localShow = true
    },
    updateStatuses: function() {
      this.update_loading = true;

      // var ids = [];
      var self = this;
      this.supplies.forEach(function(item) {
        // item.quantity_in_stock = parseFloat(item.quantity_in_stock) + parseFloat(self.amount);
        item.employee_location_inventory.quantity_in_stock =
          parseFloat(item.employee_location_inventory.quantity_in_stock) +
          parseFloat(self.amount);
      });
      this.$http
        .post('/admin/supplies/update-all', {
          supplies: this.supplies
        })
        .then(() => {
          this.$notify({
            group: 'main',
            title: 'Inventory Added',
            text: `The inventory has been added`,
            duration: 5000
          });

          Event.fire('update-supplies');
        })
        .finally(() => {
          this.update_loading = false;
          // this.show = false;
          this.localShow = false;
        });
    }
  }
};
</script>
