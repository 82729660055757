<template>
  <v-container>
    <v-row>
      <v-col
        cols="4"
        md="4"
      >
        <v-autocomplete
          v-model="fieldReplacement"
          dense
          outlined
          label="Field Replacements"
          name="field_replacement"
          :items="fieldReplacements"
          :clearable="true"
          :disabled="!showFieldReplacements"
          @change="insertFieldReplacement(fieldReplacement)"
        />
      </v-col>

      <v-col
        cols="4"
        md="4"
      >
        <v-autocomplete
          v-model="programStatement"
          dense
          outlined
          label="Programming Statements"
          name="programming_statements"
          :items="programmingStatements"
          item-text="name"
          item-value="value"
          :clearable="true"
          :disabled="!showProgrammingStatements"
          @change="insertFieldReplacement(programStatement)"
        />
      </v-col>

      <v-col
        cols="4"
        md="4"
      >
        <v-autocomplete
          v-model="operatorField"
          dense
          outlined
          label="Programming Operators"
          name="programming_operators"
          :items="operators"
          :clearable="true"
          :disabled="!showOperators"
          @change="insertFieldReplacement(operatorField)"
        />
      </v-col>
    
      <v-col
        cols="12"
        md="12"
      >
        <prism-editor
          ref="templateEditor"
          v-model="content"
          class="my-editor"
          :highlight="highlighter"
          :line-numbers="true"
          :ignore-tab-key="false" 
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// ref: https://github.com/koca/vue-prism-editor
// ref: https://prismjs.com/#supported-languages
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css'; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
// import { highlight, languages } from 'prismjs/components/prism-core';
import Prism from 'prismjs';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism-tomorrow.css'; // import syntax highlighting styles


export default {
  components: {
    PrismEditor,
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: null
    },
    showFieldReplacements: {
      type: Boolean,
      default: true
    },
    fieldReplacements: {
      type: Array,
      default: () => ([])
    },
    showProgrammingStatements: {
      type: Boolean,
      default: true
    },
    programmingStatements: {
      type: Array,
      default: () => ([])
    },
    showOperators: {
      type: Boolean,
      default: true
    },
    operators: {
      type: Array,
      default: () => ([])
    },
    language: {
      type: String,
      default: 'html'
    }
  },
  data() {
    return {
      fieldReplacement: null,
      programStatement: null,
      operatorField: null
    };
  },
  computed: {
    content: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      },
  },
  methods: {
    highlighter(code) {
      // languages.<language> to return html with markup
      return Prism.highlight(code, Prism.languages[this.language]);
    },
    insertFieldReplacement(fieldValue) {
      if (!fieldValue) {
        return
      }

      const el = this.$refs.templateEditor.$el.querySelector("textarea");
      let cursorPos = el.selectionEnd;
      if (!this.content) {
        this.content = fieldValue;
        return
      }

      this.content = this.content.substring(0, cursorPos) + fieldValue +  this.content.substring(cursorPos);
      cursorPos += this.content.length;
      this.$nextTick(() => el.setSelectionRange(cursorPos, cursorPos));
    }
  }
};
</script>

<style>
  /* required class */
  .my-editor {
    /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
    background: #2d2d2d;
    color: #ccc;

    /* you must provide font-family font-size line-height. Example: */
    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 5px;
  }

  /* optional class for removing the outline */
  .prism-editor__textarea:focus {
    outline: none;
  }

  div.prism-editor-wrapper {
    min-height: 600px;
    max-height: 600px;
    overflow-y: auto;
  }
</style>
