<template>
  <div
    v-if="
      shipment &&
        (shipment.handling_fee || shipment.postage_rate || shipment.tax) > 0
    "
  >
    <h4 v-if="showHeader">
      Postage
    </h4>
    <div
      v-if="shipment.postage_type_id"
      class="my-1"
      v-html="$options.filters.postage(shipment)"
    />

    <!-- <div class="mb-3">
      {{ shipment.tier }}
    </div> -->

    <div
      v-if="shipment.payment_at"
      class="rate-block"
    >
      <div class="d-flex flex-row justify-space-between">
        <div class="shipment-title">
          Payment Date
        </div>
        <div>
          {{ shipment.payment_at | date_pretty }}
        </div>
      </div>
    </div>

    <div
      v-if="shipment.postage_rate"
      class="d-flex flex-row justify-space-between"
    >
      <div class="shipment-title">
        Postage Fee
      </div>

      <div>
        {{ shipment | combined_rate | currency }}
      </div>
    </div>

    
    <div
      v-if="shipment.insurance_cost"
      class="d-flex flex-row justify-space-between"
    >
      <div class="shipment-title">
        Stallion Protection
      </div>
      <div>{{ shipment.insurance_cost | currency }}</div>
    </div>

    <div
      v-if="shipment.tax"
      class="d-flex flex-row justify-space-between"
    >
      <div class="shipment-title">
        Tax
      </div>
      <div>{{ shipment.tax | currency }}</div>
    </div>

    <div class="d-flex flex-row justify-space-between">
      <div class="shipment-title font-weight-bold">
        Total
      </div>
      <div>
        {{ shipment.total_amt_paid | currency }}
      </div>
    </div>
    
    <v-divider
      v-if="showDivider"
      class="details-divider"
    />
  </div>
</template>

<script>
export default {
  props: {
    shipment: {
      type: Object,
      default: null
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    showDivider: {
      type: Boolean,
      default: true
    }
  }
};
</script>
