<template>
  <SidePanelLayout v-if="$auth.user">
    <template #side-panel>
      <admin-view-shipment-details :shipments="$store.state.main.selectedShipments" />
    </template>

    <template #default>
      <h1>Closeout {{ type | humanize }}</h1>
      <admin-shipment-table
        ref="shipmentTable"
        :admin-closeout-type-id="type_id"
        :current-location-id="$auth.user.employee_location_id"
        :url="'admin/shipments'"
      />
    </template>

    <template #footer>
      <v-btn
        v-if="can('closeouts.revert')"
        color="primary"
        :disabled="!revertable || loading"
        postnl
        @click="revert"
      >
        Revert
      </v-btn>

      <v-menu>
        <template #activator="{ on }">
          <v-btn
            v-if="$auth.user.employee_location.transfer_location && $auth.user.employee_location.transfer_location.sort_method == 'Manual'"
            color="primary"
            dark
            v-on="on"
            @click="dialog = true"
          >
            Transfer
          </v-btn>
        </template>
      </v-menu>

      <StallionDialog
        v-model="dialog"
        persistent
        :width="600"
        title="Transfer To Location"
      >
        <template #content>
          <v-container>
            <v-row>
              <v-col cols="12">
                <h2>
                  You are about to transfer all shipments to the
                  {{ $auth.user.employee_location?.transfer_location?.name }}
                  facility
                </h2>
              </v-col>
            </v-row>
          </v-container>
        </template>

        <template #actions>
          <v-btn
            color="primary"
            text
            :loading="loading2"
            :disabled="loading2"
            @click="transfer"
          >
            Save
          </v-btn>
        </template>
      </StallionDialog>

      <v-btn
        v-if="$auth.user.employee_location?.can_closeout == true && type_id != 5"
        color="primary"
        @click="closeout"
      >
        Closeout
      </v-btn>
    </template>
  </SidePanelLayout>
</template>

<script>
export default {
  props: {
    type: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      pending: false,
      loading: false,
      loading2: false,
      dialog: null,
      postage_types: [],
      needs_postage: null,
      status_id: null,
      type_id: null
    };
  },
  computed: {
    revertable() {
      if (this.$store.state.main.selectedShipments.length != 0) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    type: {
      immediate: true,
      handler(newValue) {
        console.log(newValue);
        if (newValue == 'canada-post') {
          this.type_id = 1;
        } else if (newValue == 'landmark') {
          this.type_id = 4;
        } else if (newValue == 'postnl') {
          this.type_id = 2;
        } else if (newValue == 'ups-canada') {
          this.type_id = 3;
        } else if (newValue == 'us') {
          this.type_id = 5;
        } else if (newValue == 'rivo') {
          this.type_id = 6;
        } else if (newValue == 'gsm') {
          this.type_id = 7;
        } else if (newValue == 'fleetoptics') {
          this.type_id = 8;
        } else if (newValue == 'uni-uni') {
          this.type_id = 9;
        } else if (newValue == 'ics') {
          this.type_id = 10;
        } else if (newValue == 'canpar') {
          this.type_id = 11;
        } else if (newValue == 'ecom') {
          this.type_id = 12;
        } else if (newValue == 'purolator') {
          this.type_id = 13;
        } 
      },
      deep: true
    }
  },
  methods: {
    setOptions() {
      console.log('set options');
    },
    async closeout() {
      const isConfirm = await swal({
        title: 'Are you sure?',
        text:
          'This will move all shipments to in-transit and perform a closeout with the carriers' +
          '.\n Do you wish to continue?',
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      });
      if (isConfirm) {
        this.loading = true;
        this.$http
          .post(`/admin/closeouts`, {
            type_id: this.type_id
          })
          .then((response) => {
            var res = response.data;
            if (res.success) {
              this.$router.push({
                name: 'admin-closeouts-show',
                params: {
                  id: res.closeout.id
                }
              });
            } else {
              swal({
                title: 'Error!',
                text: res.error,
                icon: 'error'
              });
            }
          })
          .catch((err) => {
            if (
              err.response.status === 422 &&
              typeof err.response.data.errors !== 'undefined'
            ) {
              const errors = err.response.data.errors;
              let message = '';

              for (var field in errors) {
                message += `${errors[field][0]}\n`;
              }

              this.errorMessage(message);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    async transfer() {
      const isConfirm = await swal({
        title: 'Are you sure?',
        text:
          "This will transfer all shipments to your region's main facility" +
          '.\n Do you wish to continue?',
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      });
      if (isConfirm) {
        this.loading2 = true;
        this.$http
          .post(`/admin/transfers`, {
            type_id: this.type_id
          })
          .then((response) => {
            var res = response.data;
            if (res.success) {
              this.$router.push({
                name: 'admin-closeouts-show',
                params: {
                  id: res.closeout.id
                }
              });
            } else {
              swal({
                title: 'Error!',
                text: res.error,
                icon: 'error'
              });
            }
          })
          .catch((err) => {
            if (
              err.response.status === 422 &&
              typeof err.response.data.errors !== 'undefined'
            ) {
              const errors = err.response.data.errors;
              let message = '';

              for (var field in errors) {
                message += `${errors[field][0]}\n`;
              }

              this.errorMessage(message);
            }
          })
          .finally(() => {
            this.loading2 = false;
          });
      }
    },
    revert() {
      var ids = this.$store.state.main.selectedShipments.map((x) => x.id);
      this.$http
        .post('/admin/closeouts/revert', { ids })
        .then(() => {
          Event.fire('admin-update-shipments');
        })
        .catch(() => {});
    }
  }
};
</script>
