<template>
  <StallionDialog
    v-model="dialog"
    persistent
    :width="800"
    :title="edit ? 'Edit Region' : 'Create Region'"
  >
    <template #content>
      <v-container>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="region.name"
              outlined
              dense
              label="Region Name"
              required
            />
          </v-col>

          <v-col cols="12">
            <v-select
              v-model="region.timezone"
              v-validate="'required'"
              :error-messages="errs.collect('timezone')"
              data-vv-name="timezone"
              outlined
              dense
              :items="timezones"
              label="Timezone"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <h2>Canada Return Address</h2>
          </v-col>

          <v-col cols="6">
            <!-- Address1 -->

            <v-text-field
              v-model="region.canada_address.address1"
              v-validate="'required'"
              outlined
              dense
              label="Address1"
              :error-messages="errs.collect('can_address1')"
              data-vv-name="can_address1"
            />
          </v-col>

          <!-- Address2 -->
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="region.canada_address.address2"
              disabled
              outlined
              dense
              label="Address2"
            />
          </v-col>

          <!-- City -->
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="region.canada_address.city"
              v-validate="'required'"
              outlined
              dense
              label="City"
              :error-messages="errs.collect('can_city')"
              data-vv-name="can_city"
            />
          </v-col>

          <!-- Province/State -->
          <v-col
            cols="12"
            md="6"
          >
            <v-autocomplete
              v-model="region.canada_address.province_code"
              v-validate="'required'"
              outlined
              dense
              :error-messages="errs.collect('province_code')"
              data-vv-name="province_code"
              :items="provinces"
              label="Province"
              item-text="name"
              item-value="code"
            />
          </v-col>

          <!-- Postal Code -->
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="region.canada_address.postal_code"
              v-validate="{
                required: true,
                regex: /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/
              }"
              outlined
              dense
              :error-messages="errs.collect('can_postal_code')"
              label="Postal Code"
              data-vv-name="can_postal_code"
            />
          </v-col>

          <!-- Country code -->
          <v-col
            cols="12"
            md="6"
          >
            <v-autocomplete
              v-model="region.canada_address.country_code"
              v-validate="{
                required: true,
                is: 'CA'
              }"
              outlined
              dense
              :error-messages="errs.collect('can_country_code')"
              data-vv-name="can_country_code"
              :items="countries"
              label="Country"
              item-text="name"
              item-value="code"
            />
          </v-col>

          <!-- Phone -->
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="region.canada_address.phone"
              v-validate="'required|integer'"
              type="number"
              outlined
              dense
              :error-messages="errs.collect('can_phone')"
              label="Phone"
              data-vv-name="can_phone"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <h2>US Return Address</h2>
          </v-col>

          <v-col cols="6">
            <!-- Address1 -->

            <v-text-field
              v-model="region.us_address.address1"
              v-validate="'required'"
              outlined
              dense
              :error-messages="errs.collect('us_address1')"
              label="Address1"
              data-vv-name="us_address1"
            />
          </v-col>

          <!-- Address2 -->
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="region.us_address.address2"
              disabled
              outlined
              dense
              label="Address2"
            />
          </v-col>

          <!-- City -->
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="region.us_address.city"
              v-validate="'required'"
              outlined
              dense
              :error-messages="errs.collect('us_city')"
              label="City"
              data-vv-name="us_city"
            />
          </v-col>

          <!-- Province/State -->
          <v-col
            cols="12"
            md="6"
          >
            <v-autocomplete
              v-model="region.us_address.province_code"
              v-validate="'required'"
              outlined
              :error-messages="errs.collect('state_code')"
              data-vv-name="state_code"
              :items="states"
              label="State"
              item-text="name"
              item-value="code"
              dense
            />
          </v-col>

          <!-- Postal Code -->
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="region.us_address.postal_code"
              v-validate="{
                required: true,
                regex: /(^\d{5}$)|(^\d{5}-\d{4}$)/
              }"
              outlined
              dense
              :error-messages="errs.collect('zip_code')"
              label="Zip Code"
              data-vv-name="zip_code"
            />
          </v-col>

          <!-- Country code -->
          <v-col
            cols="12"
            md="6"
          >
            <v-autocomplete
              v-model="region.us_address.country_code"
              v-validate="{
                required: true,
                is: 'US'
              }"
              outlined
              dense
              :error-messages="errs.collect('us_country_code')"
              data-vv-name="us_country_code"
              :items="countries"
              label="Country"
              item-text="name"
              item-value="code"
            />
          </v-col>

          <!-- Phone -->
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="region.us_address.phone"
              v-validate="'required|integer'"
              type="number"
              outlined
              dense
              :error-messages="errs.collect('us_phone')"
              label="Phone"
              data-vv-name="us_phone"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <h2>US Induction Address</h2>
          </v-col>

          <v-col cols="6">
            <!-- Address1 -->

            <v-text-field
              v-model="region.induction_address.address1"
              v-validate="'required'"
              outlined
              dense
              :error-messages="errs.collect('induction_address1')"
              label="Address1"
              data-vv-name="induction_address1"
            />
          </v-col>

          <!-- Address2 -->
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="region.induction_address.address2"
              disabled
              outlined
              dense
              label="Address2"
            />
          </v-col>

          <!-- City -->
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="region.induction_address.city"
              v-validate="'required'"
              outlined
              dense
              :error-messages="errs.collect('us_city')"
              label="City"
              data-vv-name="us_city"
            />
          </v-col>

          <!-- Province/State -->
          <v-col
            cols="12"
            md="6"
          >
            <v-autocomplete
              v-model="region.induction_address.province_code"
              v-validate="'required'"
              outlined
              :error-messages="errs.collect('state_code')"
              data-vv-name="state_code"
              :items="states"
              label="State"
              item-text="name"
              item-value="code"
              dense
            />
          </v-col>

          <!-- Postal Code -->
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="region.induction_address.postal_code"
              v-validate="{
                required: true,
                regex: /(^\d{5}$)|(^\d{5}-\d{4}$)/
              }"
              outlined
              dense
              :error-messages="errs.collect('zip_code')"
              label="Zip Code"
              data-vv-name="zip_code"
            />
          </v-col>

          <!-- Country code -->
          <v-col
            cols="12"
            md="6"
          >
            <v-autocomplete
              v-model="region.induction_address.country_code"
              v-validate="{
                required: true,
                is: 'US'
              }"
              outlined
              dense
              :error-messages="errs.collect('us_country_code')"
              data-vv-name="us_country_code"
              :items="countries"
              label="Country"
              item-text="name"
              item-value="code"
            />
          </v-col>

          <!-- Phone -->
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="region.induction_address.phone"
              v-validate="'required|integer'"
              type="number"
              outlined
              dense
              :error-messages="errs.collect('us_phone')"
              label="Phone"
              data-vv-name="us_phone"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <h2>Postages</h2>
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="region.enabled_postage_ids"
              v-validate="''"
              multiple
              :error-messages="errs.collect('postage_type')"
              data-vv-name="postage_type"
              :items="postageTypes"
              item-text="description"
              item-value="id"
              label="Enabled Postages"
              dense
              outlined
            />
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template #actions>
      <v-btn
        v-if="edit"
        color="primary"
        @click="updateRegion()"
      >
        Update
      </v-btn>
      <v-btn
        v-else
        color="primary"
        @click="saveRegion()"
      >
        Save
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
import CONSTANTS from '@/constants';
const REGION = {
  name: null,
  canada_address: {
    address1: null,
    address2: null,
    city: null,
    province_code: null,
    country_code: null,
    phone: null
  },
  us_address: {
    address1: null,
    address2: null,
    city: null,
    province_code: null,
    country_code: null,
    phone: null
  },
  induction_address: {
    address1: null,
    address2: null,
    city: null,
    province_code: null,
    country_code: null,
    phone: null
  },
  enabled_postage_ids: []
};

const TIMESZONES = [
  'America/St_Johns',
  'America/Halifax',
  'America/Blanc-Sablon',
  'America/Toronto',
  'America/Atikokan',
  'America/Winnipeg',
  'America/Regina',
  'America/Edmonton',
  'America/Creston',
  'America/Vancouver'
];

export default {
  data() {
    return {
      region: REGION,
      edit: false,
      dialog: false,
      timezones: TIMESZONES,
      postageTypes: [],
      countries: CONSTANTS.COUNTRIES,
      provinces: CONSTANTS.PROVINCES,
      states: CONSTANTS.STATES
    };
  },
  mounted() {
    this.getPostageTypes();
  },
  methods: {
    getPostageTypes() {
      this.$http
        .request(`/admin/postage-types`, {
          method: 'get'
        })
        .then(response => response.data)
        .then(response => {
          this.postageTypes = response;
        });
    },
    startNew() {
      this.dialog = true;
      this.region = Object.assign({}, REGION);
      this.edit = false;
      this.$validator.reset();
    },
    startEdit(region) {
      this.dialog = true;
      this.region = Object.assign({}, region);
      this.edit = true;
      this.$validator.reset();
    },
    saveRegion() {
      this.$validator.validate().then(result => {
        if (result) {
          this.$http
            .post(`/admin/regions`, this.region)
            .then(() => {
              this.dialog = false;
              this.$emit('update');
            })
            .catch(err => {
              if (
                err.response.status === 422 &&
                typeof err.response.data.errors !== 'undefined'
              ) {
                const errors = err.response.data.errors;
                let message = '';

                for (var field in errors) {
                  message += `${errors[field][0]}\n`;
                }

                this.errorMessage(message);
              }
            });
        }
      });
    },
    updateRegion() {
      this.$validator.validate().then(result => {
        if (result) {
          this.$http
            .put(`/admin/regions/${this.region.id}`, this.region)
            .then(() => {
              this.dialog = false;
              this.$emit('update');
            })
            .catch(err => {
              if (
                err.response.status === 422 &&
                typeof err.response.data.errors !== 'undefined'
              ) {
                const errors = err.response.data.errors;
                let message = '';

                for (var field in errors) {
                  message += `${errors[field][0]}\n`;
                }

                this.errorMessage(message);
              }
            });
        }
      });
    },
    removRegion(index, criterion) {
      if (criterion.id === null) {
        this.region.region_criteria.splice(index, 1);
      } else {
        this.$set(this.region.region_criteria[index], 'delete', true);
      }
    }
  }
};
</script>
