<template>
  <v-card
    class="pa-8"
    outlined
  >
    <h3>Import Shipments From a File</h3>
    <p>Upload a file to import shipments and buy postage.</p>
    <v-row class="pt-2">
      <v-col
        cols="12"
        md="8"
      >
        <v-file-input
          v-model="file"
          dusk="import_file"
          label="File input"
          accept=".txt, .csv, .xml"
          outlined
          dense
          @change="setFile"
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn
              v-show="imp.step == 1"
              color="primary"
              :disabled="uploadDisabled"
              :loading="uploadLoading"
              dusk="import_upload"
              v-on="on"
              @click="uploadFile()"
            >
              <v-icon small>
                mdi-upload
              </v-icon>Upload
            </v-btn>
          </template>
          <span>Submit your file to begin the import</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <div>
      <h3>Stallion Import Templates</h3>
      <p>Download and fill out one of our Stallion import templates:</p>
      <ul
        v-if="$auth.user?.enable_third_party_postage"
        class="my-1"
      >
        <li>
          <a
            download
            class="import-list"
            href="/templates/import/third_party/Stallion_Express_Import_Template.xlsx"
          >Default Stallion Template</a>
        </li>
        <li>
          <a
            download
            class="import-list"
            href="/templates/import/third_party/Stallion_Express_Combined_Address_Import_Template.xlsx"
          >Combined Address Template</a>
        </li>
      </ul>
      <ul
        v-else
        class="my-1"
      >
        <li>
          <a
            download
            class="import-list"
            href="/templates/import/Stallion_Express_Import_Template.xlsx"
          >Default Stallion Template</a>
        </li>
        <li>
          <a
            download
            class="import-list"
            href="/templates/import/Stallion_Express_Combined_Address_Import_Template.xlsx"
          >Combined Address Template</a>
        </li>
      </ul>
      <p>
        You can also export your shipments as a .csv file from the following
        stores and upload:
      </p>

      <v-row>
        <v-col cols="4">
          <ul class="mt-1">
            <li>
              <a
                download
                class="import-list"
                href="/templates/import/stores/Shopify_Template.csv"
              >Shopify</a>
            </li>
            <li>
              <a
                download
                class="import-list"
                href="/templates/import/stores/Ebay_Template.csv"
              >Ebay</a>
            </li>
            <li>
              <a
                download
                class="import-list"
                href="/templates/import/stores/Endicia_Template.csv"
              >Endicia</a>
            </li>
          </ul>
        </v-col>
        <v-col cols="4">
          <ul>
            <li>
              <a
                download
                class="import-list"
                href="/templates/import/stores/Stamps_Template.csv"
              >Stamps</a>
            </li>
            <li>
              <a
                download
                class="import-list"
                href="/templates/import/stores/Amazon_Template.txt"
              >Amazon</a>
            </li>
            <li>
              <a
                download
                class="import-list"
                href="/templates/import/stores/Etsy_Template.csv"
              >Etsy</a>
            </li>
          </ul>
        </v-col>
        <v-col cols="4">
          <ul>
            <li>
              <a
                download
                class="import-list"
                href="/templates/import/stores/Shipstation_Template.csv"
              >Shipstation</a>
            </li>
          </ul>
        </v-col>
      </v-row>
    </div>

    <div class="mt-3">
      <RecentUploadTable :user_id="$auth.user?.id" />
    </div>
  </v-card>
</template>

<script>
import RecentUploadTable from './RecentUploadTable.vue';
import { mapState } from 'vuex';
export default {
  components:{
    RecentUploadTable
  },
  data() {
    return {
      file: null,
      filename: '',
      form: new FormData(),
      uploadDisabled: false,
      uploadLoading: false
    };
  },
  computed: {
    ...mapState(['imp'])
  },
  watch: {},

  mounted() {},

  methods: {
    setFile() {
      if (this.file) {
        this.imp.file = this.file;
        this.uploadDisabled = false;
      } else {
        this.uploadDisabled = true;
      }
    },
    uploadFile() {
      this.form.append('import_file', this.imp.file);

      const msg = 'Loading shipments from file';
      this.$store.commit('imp/loaderShow', msg);

      this.$http
        .post('/shipments/upload', this.form)
        .then((res) => {
          this.type = res.data.type;

          const payload = {
            shipments: res.data.shipments,
            select_all: true,
            providers: [],
            type: 'file'
          };

          this.$store.commit('imp/selectionStart', payload);
        })
        .catch(() => {
          // if (err.response.status === 422) {
          //   this.errorMessage(err.response.data.errors);
          // }
        })
        .finally(() => {
          this.imp.file = null;

          this.$store.commit('imp/loaderHide');
        });
    }
  }
};
</script>
