<template>
  <div>
    <v-btn
      v-if="$auth.user?.hasOwnProperty('billing_type_id') && $auth.user.billing_type_id == 2"
      :disabled="!can(PERMISSIONS.ACCOUNT_MANAGEMENT)"
      class="pa-0 pt-2 ml-2 mr-2"
      text
    >
      Weekly Billing
    </v-btn>

    <v-btn
      v-else-if="$auth.user?.hasOwnProperty('billing_type_id') && $auth.user.billing_type_id == 3"
      :disabled="!can(PERMISSIONS.ACCOUNT_MANAGEMENT)"
      class="pa-0 pt-2 ml-2 mr-2"
      text
      color="#455a64"
      small
    >
      Monthly Billing
    </v-btn>

    <v-btn
      :disabled="!can(PERMISSIONS.ACCOUNT_MANAGEMENT)"
      color="#455a64"
      :ripple="false"
      text
      class="pt-2 ml-2 mr-2"
      @click="$router.push('/credits')"
    >
      <v-hover>
        <span style="color: #455a64">{{
          $auth.user?.credits | currency
        }}</span>
      </v-hover>
    </v-btn>
  </div>
</template>

<script>
import PERMISSIONS from '@/constants/client-permissions';
export default {
  data() {
    return {
      counts: null,
      unread: 0,
      notifications: [],
      first_letter: '',
      second_letter: '',
      dialog: false,
      drawer: null,
      dropdown: null,
      dropdown_s: null,
      message: null,
      model5: null,
      group_model: null,
      dense: null,
      rounded: null,
      text: null,
      PERMISSIONS: PERMISSIONS
    };
  },
  mounted() {
    Event.listen('payment-completed', () => {
      this.getUser();
    });

    Event.listen('get-credits', () => {
      this.$http.get('/auth/user').then((res) => {
        if (this.$auth.user !== null) {
          this.$auth.user.credits = res.data.data.credits;
        }
      });
    });
  }
};
</script>
