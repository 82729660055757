<template>
  <StallionDialog
    v-model="show"
    :width="500"
    :disabled="disabled"
    title="Request Void"
  >
    <template #content>
      <v-layout column>
        <v-flex
          xs12
        >
          <div class="dialog-info">
            You are about to request the void of {{ validShipments.length }}
            {{ validShipments.length | pluralize(' shipment') }}
          </div>
          <div class="dialog-info">
            Refunds will be processed after approval within 14
            days.
          </div>
        </v-flex>

        <v-flex
          v-if="show_warning"
          xs12
          text-center
        >
          <v-alert
            text
            type="warning"
          >
            One or more shipments belong to a batch that has been closed. Please remove the shipments you wish to void from their batch.
          </v-alert>
        </v-flex>

        <!-- <div v-if="delete_loading"> -->
        <!-- <v-progress-linear v-model="valueDeterminate"></v-progress-linear>

        <v-flex xs12 text-center
          >{{ number }} of {{ shipments.length }}</v-flex
        > -->
        <!-- </div> -->
      </v-layout>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        :loading="delete_loading"
        :disabled="delete_loading || validShipments.length === 0"
        @click="remove()"
      >
        Request
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    shipments: {
      type: Array,
      default: () => []
    },
    disabled: {
      default: false,
      type: Boolean
    },
    value: {
      type: Boolean,
      default: false
    },
    updateEvent: {
      type: Function,
      default: () => {
        this.$emit('update');
      }
    }
  },
  data() {
    return {
      delete_loading: false,
      number: 0,
      show_warning: false
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    validShipments: function() {
      return this.shipments.filter(shipment => {
        let shipVoid = shipment.is_voidable;
        if(this.$router.history.current.meta?.admin === true){
          shipVoid = true;
        }
        let valid =
          (shipment.status_id == 4 || shipment.status_id == 2) &&
          (this.$auth.user?.void_non_tracked_shipments || shipVoid);
        
        if (shipment.batch_id !== null && !this.hasOpenBatch(shipment)){
          valid = false;
          this.show_warning = true;
        }

        return valid;
      });
    }
  },
  methods: {
    hasOpenBatch(shipment){
      const ids = this.$store.getters['batches/openBatchIds'];
      return ids.includes(shipment.batch_id);
    },
    remove: async function() {
      console.log('remove');
      this.delete_loading = true;

      const ids = this.validShipments.map(a => a.id);

      let url = '/shipments/request-void';

      if(this.$router.history.current.meta?.admin === true){
        url = '/admin/shipments/request-void';
      }

      this.$http
        .post(url, ids)
        .then(() => {})
        .catch(() => {
          this.errorMessage('Error voiding the selected shipments');
        })
        .finally(() => {
          this.delete_loading = false;
          this.show = null;
          this.number = 0;

          this.updateEvent();
        });
    }
  }
};
</script>
