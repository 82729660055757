<template>
  <FullPageLayout title="Commission Payouts">
    <template #default>
      <commission-payouts />
    </template>
  </FullPageLayout>
</template>
  
<script>
import CommissionPayouts from  './commission-payouts-table.vue'

export default {
  components: {
    CommissionPayouts
  }
};
</script>
