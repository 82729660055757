<template>
  <SingleCardLayout>
    <v-container>
      <v-layout wrap>
        <v-flex
          xs12
          mt-12
          mb-12
        >
          <v-alert
            color="error"
            :value="true"
            icon="warning"
            transition="fade-transition"
          >
            Your account has been locked.
          </v-alert>
        </v-flex>
      </v-layout>

      <v-layout
        align-center
        class="mt-12"
      >
        <v-flex
          xs12
          text-center
        >
          <v-btn
            outline
            color="primary"
            @click.prevent="logout()"
          >
            <v-icon
              dark
              small
            >
              mdi-logout
            </v-icon>Logout
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </SingleCardLayout>
</template>

<script>
export default {
  data() {
    return {
      password: '',
      email: '',
      rememberMe: false,
      error: '',
      loading2: false
    };
  },

  methods: {}
};
</script>

<style>
#remember .v-input--selection-controls {
  margin-top: 16px;
  padding-top: 4px;
}
</style>
