<template>
  <v-row>
    <v-col cols="12">
      <user-settings-option
        v-for="(list, index) in settings"
        :key="index"
        :list="list"
      />
    </v-col>
    <v-col cols="12">
      <v-btn
        color="primary"
        :loading="processing"
        :disabled="settings.length == 0"
        @click="submit"
      >
        Update
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    settings: {
      type: Array,
      default: () => {
        [];
      }
    }
  },
  data() {
    return {
      processing: false
    };
  },
  mounted() {},
  methods: {
    submit() {
      this.processing = true;
      this.$http
        .post('/admin/system-settings', {
          data: this.settings
        })
        .then(() => {
          this.successMessage('Security settings have been saved');
        })
        .catch(() => {
          this.successMessage('Security settings have been saved');
        })
        .finally(() => {
          this.processing = false;
        });
    }
  }
};
</script>
