<template>
  <div>
    <v-card
      outlined
      class="pa-5"
      style="height: 100%;"
    >
      <h4>Tracking</h4>
      <v-form compact-form>
        <v-card-text class="pa-0">
          <v-row justify="space-between">
            <v-col
              cols="12"
              md="8"
              class="pt-8"
            >
              <v-text-field
                v-model="code"
                v-validate="'required'"
                label="Tracking Number"
                prepend-inner-icon="search"
                class="pa-0 ma-0"
                :error-messages="errs.collect('tracking_number')"
                data-vv-name="tracking_number"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
              class="pt-8 track-button"
              style="text-align:right;"
            >
              <v-btn
                color="primary"
                type="submit"
                outlined
                small
                class="pa-4"
                style="width: 100%;"
                @click.prevent="submit()"
              >
                Track
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      code: ''
    };
  },
  methods: {
    submit() {
      this.$validator.validate().then(result => {
        if (result) {
          this.$router.push({
            name: 'track',
            params: { code: this.code }
          });
        }
      });
    }
  }
};
</script>

<style scoped>
h4 {
  margin-bottom: 0px !important;
}

@media only screen and (max-width: 960px) {
  .track-button {
    text-align: left !important;
  }
}
</style>
