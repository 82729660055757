<template>
  <StallionDialog
    v-model="show"
    :width="800"
    :show-actions="false"
    transition="dialog-bottom-transition"
    title="My Addresses"
  >
    <template #content>
      <AddressTable
        ref="addressTable"
        :show-edit="false"
        @selectAddress="selectAddress"
      />
    </template>
  </StallionDialog>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import AddressTable from '../AddressTable.vue';

export default {
  components: {
    AddressTable
  },
  props: {
    outlined: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      show: false,
    };
  },
  methods: {
    resetInput() {
      this.loading = false;
      this.search = null
    },
    startNew() {
      this.show = true
      this.resetInput();
    },
    selectAddress(item) {
      this.$emit('selectAddressBook', _cloneDeep(item));
      this.show = false;
    }
  }
};
</script>

