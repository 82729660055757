<template>
  <StallionDialog
    v-model="show"
    :width="500"
    :disabled="disabled"
    persistent
    title="Edit Stock"
  >
    <template
      v-if="item"
      #content
    >
      <v-card-text>
        <v-layout column>
          <v-flex
            xs12
            text-center
            mt-4
            mb-12
          >
            <v-text-field
              v-model="total"
              type="number"
              label="Total Inventory"
            />

            <v-text-field
              type="number"
              disabled
              :value="item.region_location_inventory.pending_pickup"
              label="Allocated"
            />

            <!-- item.region_location_inventory.quantity_in_stock  -->
            <v-text-field
              :value="available"
              disabled
              type="number"
              label="Available Inventory"
            />

            <v-text-field
              v-model="item.units_per_container"
              type="number"
              label="Units per container"
            />

            <v-checkbox
              v-model="item.enabled"
              label="Enabled"
            />

            <v-text-field
              v-model="item.price"
              class="mt-4"
              label="Price"
            />
          </v-flex>
        </v-layout>
      </v-card-text>
    </template>

    
    <template #actions>
      <v-btn
        color="primary"
        :loading="update_loading"
        :disabled="update_loading"
        @click="updateStatuses()"
      >
        Update
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
const STOCK_ITEM = {
  id: null,
  region_location_inventory: {
    quantity_in_stock: 0,
    pending_pickup: null
  },
  units_per_container: 0
};

export default {
  props: {
    disabled: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      total: 0,
      show: false,
      update_loading: false,
      dowload_loading: false,
      item: STOCK_ITEM,
      statuses: [
        {
          id: 1,
          description: 'Unfulfilled'
        },
        {
          id: 2,
          description: 'Fulfilled'
        },
        {
          id: 3,
          description: 'Voided'
        }
      ],
      price: null,
      inventory: null
    };
  },
  computed: {
    available() {
      return this.total - this.item.region_location_inventory.pending_pickup;
    }
  },
  watch: {
    show(newValue) {
      if (newValue) {
        this.total =
          this.item.region_location_inventory.quantity_in_stock +
          this.item.region_location_inventory.pending_pickup;
      }
    }
  },
  methods: {
    openDialog(param) {
      this.show = true
      this.item = Object.assign(STOCK_ITEM, param);
    },
    updateStatuses: function () {
      this.update_loading = true;

      // var ids = [];

      this.$http
        .post('/admin/supplies/update-all', {
          supplies: [
            {
              enabled: this.item.enabled,
              units_per_container: this.item.units_per_container,
              price: this.item.price,
              region_location_inventory: {
                location_id: this.item.region_location_inventory.location_id,
                quantity_in_stock: this.available
              },
              id: this.item.id
            }
          ]
        })
        .then((response) => {
          console.log(response.data);
          this.$notify({
            group: 'main',
            title: 'Item Updated',
            text: `The supply item has been updated`,
            duration: 5000
          });

          Event.fire('update-supplies');
        })
        .finally(() => {
          this.update_loading = false;
          this.show = false;
        });
    },
    close() {
      this.show = null;
      Event.fire('update-supplies');
    }
  }
};
</script>
