<template>
  <FullPageLayout
    title="Automation Rules"
    desc="Automation rules help you set values for orders that you import from your store or from files."
  >
    <template #page-links>
      <order-links />
    </template>
    <template #default>
      <rule-table />
    </template>
  </FullPageLayout>
</template>

<script>
import RuleTable from './RuleTable.vue';
export default {
  metaInfo: {
    title: "Automation Rules | Stallion Express"
  },
  components: {
    RuleTable
  },
  data() {
    return {
     
    };
  },
  methods: {
   
  }
};
</script>
