<template>
  <v-row>
    <v-col cols="12">
      {{ scanType }}
      <v-form>
        <v-container>
          <v-row>
            <v-text-field
              ref="barcodeInput"
              v-model="barcode"
              label="Scan UPC or enter an SKU to continue"
              class="mr-2"
              outlined
              clearable
              dense
              hide-details
              :disabled="!canEdit || isOrderItemsVerified"
              :loading="loading"
              @keydown.enter.prevent="manualScan"
            />
  
            <v-btn
              v-show="enableVerification"
              color="primary"
              :disabled="!canEdit || isOrderItemsVerified"
              :loading="loading"
              @click="manualScan"
            >
              Verify Item
            </v-btn>


            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  color="primary"
                  @click="$emit('close')"
                  v-on="on"
                >
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              <span>Close and find another shipment </span>
            </v-tooltip>
          </v-row>
        </v-container>
      </v-form>
    </v-col>

    <v-col cols="12">
      <v-simple-table dense>
        <template #default>
          <thead>
            <tr>
              <th class="text-left pb-3 text-subtitle-1 font-weight-bold">
                Image
              </th>
              <th class="text-left pb-3 text-subtitle-1 font-weight-bold">
                Description
              </th>
              <th
                v-show="enableVerification"
                class="text-left pb-3 text-subtitle-1 font-weight-bold"
              >
                <span
                  :class="{
                    'info--text': canEdit && canVerifyAll,
                    '': !canEdit || !canVerifyAll
                  }"
                  :style="{ cursor: (canEdit && canVerifyAll) ? 'pointer' : 'default' }"
                  @click="(canEdit && canVerifyAll) ? verifyAll() : null"
                >
                  Verify All
                </span>
              </th>
              <th
                v-show="enableVerification"
                class="text-left pb-3 text-subtitle-1 font-weight-bold"
              >
                <span
                  :class="{
                    'error--text': canEdit && canCancelVerifyAll,
                    '': !canEdit || !canCancelVerifyAll
                  }"
                  :style="{ cursor: (canEdit && canCancelVerifyAll) ? 'pointer' : 'default' }"
                  @click="(canEdit && canCancelVerifyAll) ? cancelVerifyAll() : null"
                >
                  Clear All
                </span>
              </th>
              <th class="text-center pb-3 text-subtitle-1 font-weight-bold">
                Ordered
              </th>
              <th
                v-show="enableVerification"
                class="text-left pb-3 text-subtitle-1 font-weight-bold"
              >
                Scanned
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in (order.items || [])"
              :key="index"
            >
              <td class="text-left py-1">
                <v-img
                  v-if="item.img_url"
                  :src="item.img_url || 'https://cannamazoo.com/assets/defaults/img/default-product-img.jpg'"
                
                  min-height="75"
                  min-width="75"
                  max-height="75"
                  max-width="75"
                  aspect-ratio="1"
                />
                <v-icon
                  v-else
                  color="grey"
                  size="75"
                >
                  mdi-image
                </v-icon>
              </td>
              <td width="50%">
                <h4>{{ item.description }}</h4>
                <div>
                  <strong>SKU: </strong> {{ item.sku }}
                </div>
               
                <div>
                  <strong>UPC: </strong> <span v-if="item.upc">{{ item.upc }}</span> <span v-else>N/A</span>
                </div>
              </td>
              <td v-show="enableVerification">
                <v-btn
                  class="mt-n1"
                  large
                  color="info"
                  :disabled="!canEdit || !canVerifyItem(item)"
                  :loading="loading"
                  @click="verifyItem(index)"
                >
                  Verify
                </v-btn>
              </td>
              <td v-show="enableVerification">
                <v-btn
                  class="mt-n1"
                  large
                  color="red"
                  style="color: white"
                  
                  :disabled="!canEdit || !canCancelVerifyItem(item)"
                  :loading="loading"
                  @click="cancelVerifyItem(index)"
                >
                  Clear
                </v-btn>
              </td>
              <td class="text-center">
                <div style="line-height: 50px; font-size: 24px;">
                  {{ item.quantity }}
                </div>
              </td>
              <td v-show="enableVerification">
                <div
                  class="text-center"
                  style="background: #f9ac40; color: #fff; height: 50px; width: 50px; border-radius: 10%; line-height: 50px; font-size: 24px;"
                  :style="item.verified_quantity == item.quantity ? 'background: #4caf50;' : ''"
                >
                  {{ item.verified_quantity }}
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td
                :colspan="enableVerification ? 4 : 2"
                class="text-right text-subtitle-1 font-weight-bold py-7"
              >
                Total
              </td>
              <td>
                <div
                  style="line-height: 50px; font-size: 24px;"
                  class="text-center"
                >
                  {{ totalOrdered }}
                </div>
              </td>
              <td v-show="enableVerification">
                <div
                  class="text-center my-3"
                  style="background: #f9ac40; color: #fff; height: 50px; width: 50px; border-radius: 10%; line-height: 50px; font-size: 24px;"
                  :style="totalScanned == totalOrdered ? 'background: #4caf50;' : ''"
                >
                  {{ totalScanned }}
                </div>
              </td>
            </tr>
          </tfoot>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import PERMISSIONS from '@/constants/client-permissions';
import SCAN_CONSTANTS from '@/constants/scan';

export default {
  props: {
    order: {
      type: Object,
      required: true
    },
    scanType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      barcode: null,
      loading: false,
      originalItems: _cloneDeep(this.order?.items || []),
      lock: false
    };
  },
  computed: {
    enableVerification() {
      return this.scanType !== SCAN_CONSTANTS.SCAN_TO_PRINT;
    },
    isOrderItemsVerified() {
      return Boolean(this.order.all_items_verified);
    },
    canEdit() {
      // 3 = INCOMPLETE
      return this.order && this.order.status == 'Awaiting Shipment' && this.can(PERMISSIONS.ORDERS);
    },
    canVerifyAll() {
    return this.order && this.order.items && this.order.items.length > 0 && this.order.items.some(item => this.canVerifyItem(item));
  },
  canCancelVerifyAll() {
    return this.order && this.order.items && this.order.items.length > 0 && this.order.items.some(item => this.canCancelVerifyItem(item));
  },
   // New computed properties for totals
   totalOrdered() {
      return (this.order.items || []).reduce((sum, item) => sum + item.quantity, 0);
    },
    totalScanned() {
      return (this.order.items || []).reduce((sum, item) => sum + (item.verified_quantity || 0), 0);
    },
  },
  watch: {
    'order.items': function (newVal, oldVal) {
      console.log('test');
      if (oldVal && newVal) {
        this.originalItems = _cloneDeep(newVal);
      }
    }
  },
  created() {
    document.activeElement.blur();
    // Add barcode scan listener and pass the callback function
    if(!this.$barcodeScanner.hasListener()){
      this.$barcodeScanner.init(this.onBarcodeScanned);
    }
  },
  destroyed() {
    // Remove listener when component is destroyed
    if(this.$barcodeScanner.hasListener()){
      this.$barcodeScanner.destroy();
    }
  },
  mounted() {
    if(!this.$barcodeScanner.hasListener()){
      this.$barcodeScanner.init(this.onBarcodeScanned);
    }
  },
  methods: {
    verifyAll() {
      this.order.items.forEach((item) => {
        item.verified_quantity = item.quantity
      });

      this.updateItems();
    },
    canVerifyItem(item) {
      return !this.isOrderItemsVerified && (isNaN(item.verified_quantity) || item.verified_quantity < item.quantity)
    },
    verifyItem(index) {
      const item = this.order.items[index];
      if (item.verified_quantity < item.quantity) {
        item.verified_quantity += 1;
        this.updateItems();
      }
    },
    cancelVerifyAll() {
      this.order.items.forEach((item) => {
        item.verified_quantity = 0;
      });

      this.updateItems();
    },
    canCancelVerifyItem(item) {
      return item.verified_quantity > 0
    },
    cancelVerifyItem(index) {
      const item = this.order.items[index];
      if (item.verified_quantity > 0) {
        item.verified_quantity -= 1;
        this.updateItems();
      }
    },
    manualScan() {
      this.onBarcodeScanned(this.barcode);
    },
    // Create callback function to receive barcode when the scanner is already done
    async onBarcodeScanned(barcode) {
      console.log('barcode', barcode);
      if (!barcode || this.lock) {
      return;
    }

    this.lock = true;


      let itemIndex = -1;
      try {
        itemIndex = this.scan(barcode);
        if (!this.canVerifyItem(this.order.items[itemIndex])) {
          return;
        }

        this.verifyItem(itemIndex);
      } catch (err) {
        this.onError(err);
      
    } finally {
      this.lock = false;
      this.barcode = null;
    }
    },
    async updateItems() {
      try {
        await this.$http.post('/order-verification/' + this.order.id + '/items', {
          items: this.order.items
        })

        // update all_items_verified
        this.$set(this.order, 'all_items_verified', this.order.items.every(item => item.verified_quantity == item.quantity))
      } catch (err) {
        this.onError(err);

        // reset to original items
        this.$set(this.order, 'items', this.originalItems)
      }
    },
    scan(barcode) {
      barcode = barcode.toLowerCase();
      const settings = Object.values(this.$auth.user?.user_config_settings?.workflow_settings) || [];
      const scanKeys = settings.find(setting => setting.key === 'scan_to_verify_items_by')?.value || [];
      if (scanKeys.length == 0) {
        throw Error('Workflow settings not configured');
      }

      const itemIndex = (this.order?.items || []).findIndex((item) => {
        if (scanKeys.includes('SKU') && (item.sku || '').toLowerCase() == barcode) {
          this.playSuccessSound();
          return true;
        } else if (scanKeys.includes('UPC') && (item.upc || '').toLowerCase() == barcode) {
          this.playSuccessSound();
          return true;
        }
      });

      if (itemIndex != -1) {
        return itemIndex;
      }

      this.playErrorSound();

      throw Error('Item not found');
    },
    onError(err) {
      this.playErrorSound();
      this.$notify({
        group: 'main',
        text: err,
        duration: 3000,
        type: 'error'
      });
    },
  } 
};
</script>
