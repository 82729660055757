<template>
  <table
    v-if="order"
    class="order-table"
  >
    <thead>
      <tr>
        <th class="text-h4 text-left font-weight-bold">
          User
        </th>
        <th class="text-h4 text-left font-weight-bold">
          Barcode
        </th>
        <th class="text-h4 text-left font-weight-bold">
          Item
        </th>
        <th class="text-h4 text-left font-weight-bold">
          Rack
        </th>
        <!-- <th class="title text-left font-weight-bold">Image</th> -->
        <th class="text-h4 text-left font-weight-bold">
          Contents
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="item in order.returns"
        :key="item.id"
      >
        <td v-if="order.user">
          {{ order.user.business_name || order.user.name }}
        </td>
        <td v-else />
        <td>{{ item.barcode }}</td>
        <td>{{ item.unique_id }}</td>
        <td>{{ item.rack }}</td>
        <td>
          {{ item.item }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    order: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      loading: false,
      loading_cost: false,
      unit_cost: null,
      cost: null,
      tax: null,
      duties: null,
      total: null
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    value: function(val) {
      if (val === true) {
        this.getCost();
      }
    }
  },
  mounted() {},
  methods: {
    getCost() {
      this.loading_cost = true;
      var ids = this.returns.map(x => x.id);
      this.$http
        .post('/orders/cost', {
          ids
        })
        .then(response => response.data)
        .then(response => {
          this.unit_cost = response.unit_cost;
          this.cost = response.cost;
          this.tax = response.tax;
          this.duties = response.duties;
          this.total = response.total;
        })
        .catch(() => {
          this.errorMessage('We could not get a cost');

          this.show = false;
        })
        .finally(() => {
          this.loading_cost = false;
        });
    },

    newOrder: function() {
      if (!this.creditCheck(this.total)) {
        return null;
      }
      this.loading = true;

      var ids = this.returns.map(x => x.id);
      this.$http
        .post('/returns/order', {
          ids
        })
        .then(response => {
          var order = response.data.order;
          Event.fire('get-credits');

          Event.fire('admin-update-shipments');
          this.$router.push({
            name: 'user-order-view',
            params: {
              id: order.id
            },
            query: {
              print: true
            }
          });
        })
        .catch(() => {
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
