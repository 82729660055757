<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h3>Apply Presets</h3>
        {{ selected.length }} selected
      </v-col>

      <v-col
        class="d-flex mt-n2 ml-n2 justify-end"
      >
        <div>
          <v-select
            v-model="presetId"
            v-validate="''"
            class="px-2"
            data-vv-name="presetId"
            :error-messages="errs.collect('presetId')"
            :items="presets"
            item-text="preset_name"
            item-value="id"
            label="Presets"
            outlined
            :clearable="true"
            dense
            @change="applyPresetOnDropdown"
          />
        </div>
        <div>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                :disabled="!presetId || loading"
                large
                text
                color="primary"
                icon
                @click="applyPresetOnDropdown"
                v-on="on"
              >
                <v-icon
                  left
                  large
                >
                  mdi-content-save-check
                </v-icon>
              </v-btn>
            </template>
            <span>Apply Preset</span>
          </v-tooltip>
        </div>
        <div>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                icon
                large
                text
                :disabled="loading"
                color="primary"
                @click="openPresetsDialog()"
                v-on="on"
              >
                <v-icon
                  
                  large
                >
                  mdi-auto-upload
                </v-icon>
              </v-btn>
            </template>
            <span>Manage Presets</span>
          </v-tooltip>
        </div>
      </v-col>
    </v-row>

    <apply-preset-dialog
      ref="presetDialog"
      v-model="showPresetsDialog"
      dialog-label="Manage Presets"
      :shipments="shipments"
      @initializeHotkeys="initializeHotkeys"
      @update="onApplyPresets"
    />
  </v-container>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import ApplyPresetDialog from './BulkEdit.vue'

export default {
  components: {
    ApplyPresetDialog
  },
  props: {
    source: {
      type: String,
      default: 'shipments'
    },
    selected: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      quickshipHotkeyEvent: null,
      presetId: null,
      presets: [],
      shipments: [],
      loading: false,
      showPresetsDialog: false
    };
  },
  computed: {
    //
  },
  mounted() {
    this.getPresets();
  },
  methods: {
    async linkOrderShipment() {
      if (this.source == 'orders') {
        await this.openFromOrders(this.selected);
      } else {
        await this.openFromShipments(this.selected);
      }
    },
    async openPresetsDialog() {
      await this.linkOrderShipment();

      this.$nextTick(() => {
        this.showPresetsDialog = true;
      });
    },

    async applyPresetOnDropdown() {
      const preset = this.presets.find(
        (preset) => preset.id == this.presetId
      );

      if (!preset) {
        return;
      }

      await this.linkOrderShipment();
      this.$nextTick(function() {
        if (this.$refs.presetDialog) {
          this.loading = true;
          this.$refs.presetDialog.applyPresetOnDropdown(this.shipments, preset, () => {
            this.loading = false
          });
        }
      })
    },

    getPresets() {
      this.$http
        .get(`/import/presets`)
        .then((response) => response.data)
        .then((response) => {
          this.initializeHotkeys(response.presets);
        });
    },
    initializeHotkeys(presets) {
      this.presets = presets;

      // remove existing hotkey event listener
      if (this.quickshipHotkeyEvent) {
        window.removeEventListener('keydown', this.quickshipHotkeyEvent);
      }

      // initialize the hotkey event listener
      this.quickshipHotkeyEvent = (e) => {
        const isPressingHotkey = e.ctrlKey || e.metaKey;

        // loop presets and declare hotkey event
        presets.forEach(async (preset) => {
          const isWithHotkey = preset.hotkey !== null && preset.hotkey !== '';
          const hotkey = isNaN(preset.hotkey)
            ? preset.hotkey.toLowerCase()
            : preset.hotkey;

          if (isPressingHotkey && isWithHotkey && e.key == hotkey) {
            e.preventDefault();
            await this.linkOrderShipment();
            this.$nextTick(() => {
              if (this.$refs.presetDialog) {
                this.$refs.presetDialog.applyPresetOnHotkey(preset);
              }
            })
          }
        });

        // reserve hotkeys
        if (e.key == 'Escape') {
          e.preventDefault();
        }
      };

      // attach the hotkey event listener
      window.addEventListener('keydown', this.quickshipHotkeyEvent);
    },
    async onApplyPresets(payload) {
      try {
        this.loading = true;
        const url = this.source == 'orders' ? '/orders/getOrderByIds' : '/shipments/getShipmentByIds';
        const response = await this.$http.post(url, {
          ids: this.shipments.map(a => a.id)
        })

        this.$emit('update-shipments', response.data || []);
      } catch (e) {
        console.log(e)
        this.errorMessage('Could not create batch from orders')
      }

      this.loading = false;

      const presetName = payload.preset
        ? `(${payload.preset.preset_name})`
        : '';

      this.$notify({
        group: 'main',
        title: 'Shipments Saved',
        text: `Selected preset ${presetName} has been applied to the selected shipments.`,
        duration: 5000,
        type: 'success'
      });
    },
    async openFromShipments(selected) {
      this.shipments = _cloneDeep(selected);
    },
    async openFromOrders(selected) {
      this.loading = true
      try {
        const response = await this.$http
          .post('/shipments/quick-ship-order-labels', {
            ids: selected.map(a => a.id)
          })

        this.shipments = response.data
      } catch (e) {
        console.log(e)
        this.errorMessage('Could not create batch from orders')
      }
      this.loading = false
    }
  }
};
</script>
