<template>
  <v-card flat>
    <v-container class="px-0 py-0">
      <v-row>
        <v-col md="6">
          <h4>Import Presets</h4>
          <p class="mb-2">
            Setup your account's import presets
          </p>
        </v-col>
        <v-col md="6">
          <v-row class="float-right">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                  class="mx-2"
                  color="primary"
                  depressed
                  outlined
                  v-on="on"
                  @click="addPreset"
                >
                  <v-icon small>
                    mdi-plus
                  </v-icon>Add Preset
                </v-btn>
              </template>
              <span>Add a preset</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>

      <!-- <v-row>
        <v-col>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
            outlined
          />
        </v-col>
      </v-row> -->

      <v-row>
        <v-col md="12">
          <v-card outlined>
            <v-data-table
              ref="table"
              :headers="headers"
              :loading="loading"
              item-key="id"
              :search="search"
              :items="presets"
              class="table-striped"
            >
              <template
                v-if="presets.length > 0"
                #body="props"
              >
                <draggable
                  v-if="props.items.length > 0"
                  v-bind="dragOptions"
                  :list="presets"
                  tag="tbody"
                  @change="updateOrder"
                >
                  <transition-group
                    v-for="(item, index) in props.items"
                    :key="index"
                    tag="tr"
                    type="transition"
                    name="flip-list"
                    style="cursor: move;"
                  >
                    <td :key="`${index}preset_name`">
                      {{ item.preset_name }}
                    </td>

                    <td :key="`${index}hotkey`">
                      <!-- $hotkeyType from mixin.js  -->
                      <span v-if="item.hotkey !== null && item.hotkey !== ''">
                        {{ $hotkeyType() + ' + ' + item.hotkey }}
                      </span>
                    </td>

                    <td
                      :key="`${index}actions`" 
                      class="text-right"
                    >
                      <v-btn
                        small
                        color="primary"
                        text
                        @click="editPreset(item)"
                      >
                        Edit
                      </v-btn>
                    </td>
                  </transition-group>
                </draggable>
                <tbody v-else>
                  <tr>
                    <td>
                      No data availables
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <import-preset
      v-model="preset_dialog"
      :shipment.sync="presetItem"
      @save="getPresets"
      @remove="getPresets"
      @reloadPresets="getPresets"
    />
  </v-card>
</template>

<script>
import CONSTANTS from '@/constants';
import _isObject from 'lodash/isObject'
import _cloneDeep from 'lodash/cloneDeep'
import draggable from 'vuedraggable';

export default {
  components: {
    draggable
  },
  data() {
    return {
      search: null,
      headers: [
        {
          text: 'Name',
          align: 'left',
          value: 'preset_name',
          sortable: false
        },
        {
          text: 'Hotkey',
          align: 'left',
          value: 'hotkey',
          sortable: false
        },
        {
          text: 'Actions',
          align: 'right',
          value: 'actions',
          sortable: false
        }
      ],
      loading: false,
      presets: [],
      presetItem: _cloneDeep(this.getDefaultPresetItem()),
      preset_dialog: false,
      dragOptions: {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      },
    };
  },
  mounted() {
    this.getPresets();
  },
  methods: {
    updateOrder() {
      this.loading = true;
      this.$http
        .post(`/import/preset/update-order`, {
          presets: this.presets
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getDefaultPresetItem() {
      return {
        preset_name: null,
        preset_key: null,
        hotkey: null,
        id: null,
        data: _cloneDeep(CONSTANTS.DEFAULT_PRESET_ITEM)
      }
    },
    /**
     * Show preset dialog form
     */
    showPresetForm() {
      this.preset_dialog = true;
    },
    /**
     * Edit a preset
     */
    editPreset(item) {
      const preset = _cloneDeep(item);
      if (!_isObject(preset.data)) {
        preset.data = { ...CONSTANTS.DEFAULT_PRESET_ITEM }
      }

      if (!Array.isArray(preset.data.items)) {
        preset.data.items = []
      }

      this.presetItem = _cloneDeep(preset)

      this.showPresetForm();
    },
    /**
     * Initialize new preset object
     */
    addPreset() {
      this.presetItem = _cloneDeep(this.getDefaultPresetItem());
      this.showPresetForm();
    },
    /**
     * Retrieves the presets of the user
     */
    getPresets() {
      this.loading = true;
      this.$http
        .get(`/import/presets`)
        .then((response) => response.data)
        .then((response) => {
          this.presets = response.presets;
          this.correctPresetsDataTypes();
        })
        .catch((err) => {
          console.log(err.response);
          swal({
            title: 'Error!',
            text: 'An error occurred',
            icon: 'error'
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /**
     * Correct presets data types,
     * Only needed after fetch from endpoint
     * as some of data types might be on string
     * instead of it's correct data tyoes
     */
    correctPresetsDataTypes() {
      for (var index in this.presets) {
        if (
          Object.prototype.hasOwnProperty.call(
            this.presets,
            index
          )
        ) {
          var corrected = this.correctPropertiesTypes(
            this.presets[index]
          );
          this.presets[index] = corrected;
        }
      }
    },
    /**
     * Correct single data types
     *
     * @param      {any}  _object  The object
     * @return     {any}  Corrected data types
     */
    correctPropertiesTypes(_object) {
      for (var property in _object) {
        if (Object.prototype.hasOwnProperty.call(_object, property)) {
          var val = _object[property];
          /**
           * in case there are still data types malfunctions
           * add corrector here
           */
          if (val == 'true' || val == 'false') {
            _object[property] = val === 'true';
          }

          if (Number(val) == val) {
            _object[property] = Number(val);
          }
        }
      }
      return _object;
    }
  }
};
</script>
