<template>
  <v-card
    :loading="loading"
    outlined
  >
    <v-card-title> {{ title }}</v-card-title>
    <v-card-text>
      <pie-chart
        :height="200"
        :chart-data="formattedData"
        :options="pieOptions"
      />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: 'By Type'
    },
    typeColumn: {
      type: String,
      default: 'type'
    },
    dataColumn: {
      type: String,
      default: 'quantity'
    }
  },
  computed: {
    pieOptions() {
      return {
        title: {
          display: false,
          text: this.title,
          position: 'top'
        },
        rotation: -0.7 * Math.PI
      };
    },
    formattedData() {
      return {
        labels: this.data.map(a => this.humanize(a[this.typeColumn])),
        datasets: [
          {
            backgroundColor: this.data.map(() => this.getRandomColor()),
            data: this.data.map(a => a[this.dataColumn])
          }
        ]
      };
    }
  },
  methods: {
    getRandomColor() {
      var letters = '0123456789ABCDEF';
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }
  }
};
</script>
