<template>
  <v-app id="admin">
    <v-navigation-drawer
      v-model="drawer"
      dark
      app
      width="256"
    >
      <v-img
        style="width: 60%; margin: 15px"
        src="./images/StallionExpress-logo-white.png"
      />

      <admin-menu-list />
    </v-navigation-drawer>
    <v-app-bar
      :clipped-right="$vuetify.breakpoint.mdAndUp"
      height="55"
      app
      fixed
      dark
    >
      <v-toolbar-title>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
        <span
          v-if="$auth.user?.employee_location"
          class="hidden-sm-and-down"
        >{{ $auth.user.employee_location.name }} </span>
        <span
          v-else
          class="hidden-sm-and-down"
        >Location Not Set</span>
      </v-toolbar-title>
      <!-- <a class="navbar-logo" href>
        <span class="logo d-md-none d-lg-block"></span>
      </a>-->
      <v-spacer />

      <v-menu
        bottom
        open-on-hover
      >
        <template #activator="{ on }">
          <v-btn
            fab
            depressed
            small
            avatar
            color="white"
            v-on="on"
          >
            <v-avatar size="32">
              <span
                class="black--text text-h3"
                style="font-size: 16px !important"
              >{{ first_letter + second_letter }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            exact
            router
            to="/shipments"
          >
            <v-list-item-icon>
              <v-icon>mdi-arrow-left</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Back</v-list-item-title>
          </v-list-item>

          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon>logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-snackbar
      v-model="snackbar"
      top
      right
      color="success"
      :timeout="8000"
    >
      {{ message }}
      <v-btn
        text
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
    <v-main>
      <slot />
    </v-main>

    <newShipmentModal />
  </v-app>
</template>

<script>
import AdminMenuList from './NavLists/AdminMenuList.vue';

export default {
  components: {
    AdminMenuList
  },
  props: {
    source: {
      type: String,
      default: null
    }
  },
  data: () => ({
    snackbar: false,
    message: '',
    model2234: null,
    first_letter: '',
    second_letter: '',
    dialog: false,
    drawer: null,
    model224: null,
    model2224: null,
    model22: null,
    model4: null,
    model5: null,
    model_s: null,
    dropdown_s: null,
    model223: null,
    menu1: [
      {
        icon: 'mdi-account-circle',
        to: '/account-settings',
        text: 'Account'
      },
      {
        icon: 'fa-credit-card',
        to: '/credits',
        text: 'Credits'
      },
      {
        icon: 'mdi-file-document',
        to: '/invoices',
        text: 'Invoices'
      },
      {
        icon: 'fa-question',
        to: '/faq',
        text: 'FAQ'
      },
      {
        icon: 'mdi-dashboard',
        to: '/dashboard',
        text: 'Dashboard'
      },
      {
        icon: 'fa-unlock-alt',
        to: '/admin',
        text: 'Admin Panel'
      },
      {
        icon: 'mdi-logout',
        to: '/logout',
        text: 'Logout'
      }
    ],
    lists: [
      {
        title: 'Outbound Shipments',
        items: []
      }
    ],
    items: [
      {
        icon: 'mdi-package-variant-closed',
        'icon-alt': 'mdi-package-variant-closed',
        text: 'Shipments',

        model: false,
        children: [
          {
            icon: 'mdi-format-list-bulleted',
            text: 'All',
            to: '/admin/shipments'
          },

          {
            icon: 'mdi-circle-edit-outline',
            text: 'Incomplete',
            to: '/admin/shipments/incomplete'
          },
          {
            icon: 'mdi-pause-circle-outline',
            text: 'Pending',
            to: '/admin/shipments/pending'
          },
          {
            icon: 'mdi-cube-outline',
            text: 'Ready',
            to: '/admin/shipments/ready'
          },
          {
            icon: 'mdi-marker-check',
            text: 'Received',
            to: '/admin/shipments/received'
          },
          {
            icon: 'mdi-cube-send',
            text: 'In Transit',
            to: '/admin/shipments/in-transit'
          },
          {
            icon: 'mdi-check',
            text: 'Complete',
            to: '/admin/shipments/complete'
          },
          {
            icon: 'mdi-alert-circle-outline',
            text: 'Exception',
            to: '/admin/shipments/exception'
          },
          {
            icon: 'mdi-select',
            text: 'Void Requested',
            to: '/admin/shipments/void-requested'
          },
          {
            icon: 'mdi-select-off',
            text: 'Voided',
            to: '/admin/shipments/voided'
          },
          {
            icon: 'mdi-clock-alert-outline',
            text: 'Postage Expired',
            to: '/admin/shipments/postage-expired'
          }
        ]
      }
    ]
  }),
  computed: {},
  watch: {
    '$auth.loaded': {
      immediate: true,

      handler() {
        if (this.$auth.authenticated == true) {
          var words = this.$auth.user.name.split(' ');

          this.first_letter = words[0].charAt(0);

          if (words[1]) this.second_letter = words[1].charAt(0);
        }
      },
      deep: true
    }
  },
  mounted() {
    Event.listen('payment-completed', () => {
      this.getUser();
    });

    Event.listen('get-credits', () => {
      this.$http.get('/auth/user').then((res) => {
        if (this.$auth.user !== null) {
          this.$auth.user.credits = res.data.data.credits;
        }
      });
    });

    Event.listen('show-snackbar', (message) => {
      this.snackbar = true;
      this.message = message;
    });
  }
};
</script>

<style>
.v-list-item__title {
  font-size: 13px;
}
/* .v-list-item__title {
  color: black !important;
}

.v-list-group{
  color: black !important;
}*/
/* #admin #beacon-container{
  display: none !important;
} */
</style>
