<template>
  <div>
    <v-row
      v-if="criteria.length > 0"
      dense
    >
      <v-col
        cols="12"
        md="12"
      >
        <v-select
          v-model="field_local"
          outlined
          dense
          :items="criteria"
          label="Field"
        />
      </v-col>

      <v-col
        v-if="input != null"
        cols="12"
        md="12"
      >
        <v-autocomplete
          v-if="input.type == 'select'"
          v-model="value_local"
          outlined
          dense
          multiple
          :items="input.options"
          label="Value"
          :item-text="input.text"
          :item-value="input.key"
        />

        <v-textarea
          v-else
          v-model="value_local"
          outlined
          dense
          :error-messages="errs.collect('value_local')"
          :label="`List Values (${input.type}) `"
          data-vv-name="value_local"
          required
          placeholder="Press 'enter' for new line"
          hint="Enter values separated by a newline"
          permanent-hint
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FILTERS from '../../../shared/filters';
import { mapState } from 'vuex';
export default {
  components: {},
  props: {
    field: {
      type: String,
      default: null
    },
    value: {
      type: [String, Array],
      default: null
    },
    type: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      dialog: false,
      input: null
    };
  },
  computed: {
    ...mapState(['userStores']),
    criteria() {
      let criteria = this.type == 'pricing' ? FILTERS.PRICING_CRITERIA : FILTERS.CRITERIA

      criteria = criteria.filter((ct) => {
        return !['order_at', 'is_fba'].includes(ct.value)
      })

      // remove item_skus and item_names (they are handled differently)
      criteria = criteria.filter((ct) => {
        return !['item_skus', 'item_names'].includes(ct.value)
      })

      return criteria;
    },
    field_local: {
      get() {
        return this.field;
      },
      set(field) {
        this.value_local = null;
        this.$emit('update:field', field);
      }
    },
    value_local: {
      get() {
        if (this.input?.type == 'select') {
          return Array.isArray(this.value) ? this.value : [this.value]
        }

        return Array.isArray(this.value) ? this.value.join("\n") : this.value;
      },
      set(value) {
        if (value !== null && !Array.isArray(value)) {
          value = value.split("\n")
        }

        this.$emit('update:value', value);
      }
    }
  },
  watch: {
    field_local: {
      immediate: true,
      handler(val) {
        if (val) {
          const index = this.criteria.findIndex((x) => x.value == val);
          this.input = this.criteria[index].input;

          this.setCriteria()
        } else {
          this.value_local = null;
          this.input = null;
        }
      }
    }
  },
  methods: {
    setCriteria() {
      this.criteria.forEach((c) => {
        if (
          this.type == 'pricing' &&
          c.value === 'postage_type' &&
          !c.options
        ) {
          this.$http
            .get(`/admin/postage-types`)
            .then((response) => response.data)
            .then((response) => {
              c.input.options = response.map((postage) => {
                return {
                  id: postage.id.toString(),
                  description: postage.description
                };
              });
            });
        } else if (c.value === 'postage_type' && !c.options) {
          c.input.options = this.$store.getters['main/postage_types'](true).map((postage) => {
                return {
                  id: postage.id.toString(),
                  description: postage.description
                };
              });
        } else if (c.value === 'package_type' && !c.options) {
          c.input.options = this.$store.getters['main/package_types'].map((postage) => {
                return {
                  id: postage.id.toString(),
                  description: postage.description
                };
              });
        }else if (c.value === 'user_store_id' && !c.options) {
          this.$store.dispatch('userStores/getStores');
          const op = this.$store.getters['userStores/getStoreOptions'];

          const index = this.criteria.findIndex(
            (x) => x.value === 'user_store_id'
          );

          this.criteria[index].input.options = op
          // return (this.criteria[index].input.options = op);
        } else if (c.value === 'order_tags' && !c.options) {
          c.input.options = this.$store.getters['tags/filtered_tags'];
        }
      });
    }
  }
};
</script>
