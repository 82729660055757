<template>
  <StallionDialog
    v-model="dialog"
    persistent
    :width="800"
    title="Upload Return Details"
  >
    <template #content>
      <v-row>
        <v-col cols="12">
          <h3>Upload Return Details</h3>
          <p>
            You can use the return details template to upload and provide
            details of your returns
          </p>

          <a
            download
            class="import-list"
            href="/templates/returns/Return_Details_Template.csv"
          >Return Details Template</a>
        </v-col>
        <v-col cols="12">
          <v-file-input
            v-model="file"
            label="Return Details CSV"
            accept=".csv"
            outlined
            dense
            @change="setFile"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        />
      </v-row>
    </template>

    <template #actions>
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn
            color="primary"
            :disabled="uploadDisabled || uploadLoading"
            :loading="uploadLoading"
            v-on="on"
            @click="uploadFile()"
          >
            <v-icon small>
              mdi-upload
            </v-icon>Upload
          </v-btn>
        </template>
        <span>Submit your file to begin the import</span>
      </v-tooltip>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      dialog: false,
      file: null,
      uploadDisabled: true,
      uploadLoading: false
    };
  },
  methods: {
    setFile() {
      if (this.file) {
        this.uploadDisabled = false;
      } else {
        this.uploadDisabled = true;
      }
    },
    uploadFile() {
      const formData = new FormData();
      formData.append('import_file', this.file);
      this.uploadLoading = true;

      this.$http
        .post('/returns/provide-details/upload', formData)
        .then((response) => {
          const data = response.data;
          if (data.success) {
            this.successMessage(
              data.message || 'Return Details successfully imported!'
            );
            this.$emit('update-returns');
            this.dialog = false;
          } else {
            this.errorMessage(
              data.errors || 'Unknown error occured while uploading the file'
            );
          }
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.errorMessage(err.response.data.errors);
          } else if (err.response.status === 403) {
            this.errorMessage('This action is unauthorized.');
          } else {
            this.errorMessage('Unknown error occured while uploading the file');
          }
        })
        .finally(() => {
          this.file = null;
          this.uploadDisabled = true;
          this.uploadLoading = false;
        });
    },
    openDialog() {
      this.dialog = true;
    }
  }
};
</script>
