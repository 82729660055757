<template>
  <FullPageLayout title="Admin Closeouts">
    <template #default>
      <CloseoutsTable />
    </template>
  </FullPageLayout>
</template>

<script>
import CloseoutsTable from './CloseoutsTable.vue';
export default {
  components: {
    CloseoutsTable
  },
  data() {
    return {};
  }
};
</script>
