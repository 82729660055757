<template>
  <v-container>
    <v-layout wrap>
      <v-flex xs12>
        <h1>
          <i
            aria-hidden="true"
            class="mdi mdi-label"
          /> Print Labels for Non
          FBA Customers
        </h1>
      </v-flex>

      <v-flex
        xs12
        md6
        offset-md3
      >
        <!-- loading dialog -->
        <v-dialog
          v-model="dialog2"
          persistent
          width="300"
        >
          <v-card dark>
            <v-card-text>
              Geting Users
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              />
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="dialog"
          persistent
          width="300"
        >
          <v-card dark>
            <v-card-text>
              Generating Label
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              />
            </v-card-text>
          </v-card>
        </v-dialog>

        <!-- List -->
        <v-list
          v-if="users.length > 0"
          three-line
        >
          <template v-for="(user, index) in users">
            <v-list-item
              :key="user.id"
              ripple
              @click="null"
            >
              <v-list-item-avatar @click="selectRow(user)">
                <v-icon size="50">
                  account_circle
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content @click="selectRow(user)">
                <v-list-item-title>{{ user.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{
                    user.business_name
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider
              v-if="index != users.length - 1"
              :key="index"
              class="ma-0"
            />
          </template>
        </v-list>
        <v-card v-else-if="!dialog2 && users.length == 0">
          <v-card-text>
            <h2>No Non-FBA</h2>
          </v-card-text>
        </v-card>
      </v-flex>

      <!-- Choose print dialog -->
      <v-dialog
        v-if="selected"
        v-model="dialog3"
        max-width="400"
        persistent
      >
        <v-card>
          <v-card-title class="text-h3">
            How many Labels?
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-container>
                <v-layout wrap>
                  <v-flex
                    xs6
                    offset-xs3
                  >
                    <v-select
                      v-model="count"
                      :items="[
                        1,
                        2,
                        3,
                        4,
                        5,
                        6,
                        7,
                        8,
                        9,
                        10,
                        11,
                        12,
                        13,
                        14,
                        15,
                        16,
                        17,
                        18,
                        19,
                        20
                      ]"
                    />

                    <!-- <v-text-field v-model="count" min="1" type="number" /> -->
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="primary"
              text="text"
              @click="dialog3 = false"
            >
              Cancel
            </v-btn>
            <v-spacer />
            <v-btn
              color="primary"
              @click="select(selected, count)"
            >
              Print
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      users: [],
      selected: {
        users: []
      },

      dialog: false,
      dialog2: false,
      dialog3: false,
      count: 1
    };
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    getUsers() {
      this.dialog2 = true;
      this.$http
        .get(`/shipments/getcustomers`)
        .then(response => response.data)
        .then(response => {
          this.users = response;
          this.dialog2 = false;
        });
    },
    selectRow(user) {
      this.dialog3 = true;
      this.selected = user;
    },

    select(user, count = 1) {
      this.dialog3 = false;
      this.dialog = true;
      this.selected = user;
      // let newfile;

      this.$http({
        url: 'admin/users/printlabel/' + user.id,
        data: {
          count: count
        },
        method: 'POST'
      })
        .then(response => {
          this.printUrl(response.data);
        })
        .catch(() => {
          alert('error');
        })
        .finally(() => {
          this.dialog = false;
        });
    }
  }
};
</script>
