// AUTH
const Returns = () =>
  import(/* webpackChunkName: "auth" */ '../pages/returns-portal/index.vue');

const routes = [
  {
    path: '/r/p/:store',
    name: 'returns-portal',
    props: true,
    component: Returns,
    meta: {
      conditionalRoute: true,
      reg: true,
      layout: 'auth-layout'
    }
  }
];

export default routes;
