import PERMISSIONS from '../constants/client-permissions';

const LAYOUT = 'main-layout';

const Shipments = () => import('../pages/main/shipments/view/index.vue');

const ShipmentsScanToVerify = () => import('../pages/main/shipments-scan-to-verify/view/index.vue');

// const OrderScan = () => import('../pages/main/orders/Scan.vue');

// Batches
const Batches = () => import('../pages/main/batches/index.vue');
const BatchShow = () => import('../pages/main/batches/show.vue');

// Orders
const Orders = () => import('../pages/main/orders/index.vue');

// Products
const Products = () => import('../pages/main/products/index.vue');

const ReturnsIndex = () => import('../pages/main/returns/index.vue');
const ReturnsOrdersIndex = () =>
  import('../pages/main/return-orders/index.vue');
const ReturnsOrdersView = () => import('../pages/main/return-orders/view.vue');
const ReturnsAgree = () => import('../pages/main/returns/return-agree.vue');
const CARMAgree = () => import('../pages/main/returns/carm-agree.vue');

const ImportBeta = () => import('../pages/main/import/index.vue');

const Credit = () => import('../pages/main/credits/index.vue');

// User stuff
const AccountSettings = () => import('../pages/main/settings/index.vue');

const FAQ = () => import('../pages/main/user/faq.vue');
const Notifications = () =>
  import('../pages/main/notifications/index.vue');
const ReturnAddress = () => import('../pages/main/user/return-address.vue');
const Dashboard = () => import('../pages/main/dashboard/index.vue');
const Referrals = () => import('../pages/main/user/referral.vue');
const Track = () => import('../pages/main/track/index.vue');

const Transactions = () => import('../pages/main/credits/transactions.vue');
const Supplies = () => import('../pages/main/supplies/index.vue');
const SuppliesCheckout = () => import('../pages/main/supplies/checkout.vue');
const SuppliesOrdersShow = () => import('../pages/main/supplies/show.vue');
const SuppliesOrders = () => import('../pages/main/supplies/orders.vue');

const DropOffScan = () => import('../pages/main/drop-off/scan.vue');

const DropOffAnalytics = () => import('../pages/main/drop-off/analytics.vue');

const DropOffInvoices = () => import('../pages/main/drop-off/invoices.vue');

const PickupsIndex = () => import('../pages/main/pickups/index.vue');

const ClaimsIndex = () => import('../pages/main/claims/index.vue');

const ClaimsNew = () => import('../pages/main/claims/new.vue');

const Downloads = () => import('../pages/main/downloads/index.vue');

// Analytics
const Analytics = () => import('../pages/main/analytics/index.vue');

const FBA = () => import('../pages/main/fba/index.vue');

const MergeOrders = () => import('../pages/main/orders/merge-orders/index.vue');
const MergedOrders = () => import('../pages/main/orders/merge-orders/MergedOrders.vue');

let routes = [
  {
    path: '/',
    redirect: '/dashboard',
    meta: { reg: true, permission: PERMISSIONS.DEFAULT_ACCESS }
  },
  {
    path: '/analytics',
    name: 'analytics',
    component: Analytics,
    meta: { reg: true, permission: PERMISSIONS.ANALYTICS }
  },
  {
    path: '/filters',
    name: 'filters',
    component: () => import('../pages/main/filters/index.vue'),
    meta: { reg: true, permission: PERMISSIONS.AUTOMATION }
  },
  {
    path: '/list-criteria',
    name: 'list-criteria',
    component: () => import('../pages/main/list-criteria/index.vue'),
    meta: { reg: true, permission: PERMISSIONS.AUTOMATION }
  },
  {
    path: '/boxes',
    name: 'boxes',
    component: () => import('../pages/main/boxes/index.vue'),
    meta: { reg: true, permission: PERMISSIONS.AUTOMATION }
  },
  {
    path: '/automation-rules',
    name: 'automation-rules',
    component: () => import('../pages/main/automation-rules/index.vue'),
    meta: { reg: true, permission: PERMISSIONS.AUTOMATION }
  },
  {
    path: '/print-jobs',
    name: 'downloads',
    component: Downloads,
    meta: { reg: true, permission: PERMISSIONS.SHIPPING }
  },
  {
    path: '/claims/new',
    name: 'claims-new',
    component: ClaimsNew,
    meta: { reg: true, permission: PERMISSIONS.CLAIMS }
  },
  {
    path: '/claims',
    name: 'claims',
    component: ClaimsIndex,
    meta: { reg: true, permission: PERMISSIONS.CLAIMS }
  },
  {
    path: '/pickups',
    name: 'pickups',
    component: PickupsIndex,
    meta: { reg: true, permission: PERMISSIONS.PICKUPS }
  },
  {
    path: '/drop-off/scan',
    name: 'drop-off-scan',
    component: DropOffScan,
    meta: { reg: true, permission: PERMISSIONS.PRIMARY_ACCOUNT_HOLDER }
  },
  {
    path: '/drop-off/analytics',
    name: 'drop-off-analytics',
    component: DropOffAnalytics,
    meta: { reg: true, permission: PERMISSIONS.PRIMARY_ACCOUNT_HOLDER }
  },
  {
    path: '/drop-off/invoices',
    name: 'drop-off-invoices',
    component: DropOffInvoices,
    meta: { reg: true, permission: PERMISSIONS.PRIMARY_ACCOUNT_HOLDER }
  },
  {
    path: '/supplies/store',
    name: 'supplies-store',
    component: Supplies,
    meta: { reg: true, permission: PERMISSIONS.SUPPLIES }
  },
  {
    path: '/supplies/orders/:id',
    props: true,
    name: 'supplies-order',
    component: SuppliesOrdersShow,
    meta: { reg: true, permission: PERMISSIONS.SUPPLIES }
  },
  {
    path: '/supplies/orders',
    name: 'supplies-orders',
    component: SuppliesOrders,
    meta: { reg: true, permission: PERMISSIONS.SUPPLIES }
  },
  {
    path: '/supplies/checkout',
    name: 'supplies-checkout',
    component: SuppliesCheckout,
    meta: { reg: true, permission: PERMISSIONS.SUPPLIES }
  },
  {
    path: '/track/:code?',
    name: 'track',
    component: Track,
    meta: { reg: true, permission: PERMISSIONS.ORDERS }
  },
  {
    path: '/:transactional_type/:transactional_id/transactions',
    name: 'transactions',
    component: Transactions,
    meta: { reg: true, permission: PERMISSIONS.ACCOUNT_MANAGEMENT }
  },
  {
    path: '/credits',
    name: 'credits',
    component: Credit,
    meta: { permission: PERMISSIONS.ACCOUNT_MANAGEMENT }
  },

  {
    path: '/faq',
    name: 'faq',
    component: FAQ,
    meta: { permission: PERMISSIONS.DEFAULT_ACCESS }
  },
  {
    path: '/statements',
    name: 'statements',
    component: () => import('../pages/main/statements/index.vue'),
    meta: { permission: PERMISSIONS.ACCOUNT_MANAGEMENT }
  },
  {
    path: '/statements/:id',
    name: 'statement',
    component: () => import('../pages/main/statements/view.vue'),
    props: true,
    meta: { permission: PERMISSIONS.ACCOUNT_MANAGEMENT }
  },
  {
    path: '/batches',
    name: 'batches',
    component: Batches,
    meta: { permission: PERMISSIONS.ORDERS }
  },
  {
    path: '/orders',
    name: 'orders-show',
    component: Orders,
    props: true,
    meta: { permission: PERMISSIONS.ORDERS }
  },
  {
    path: '/scan',
    name: 'orders-scan',
    component: ShipmentsScanToVerify,
    props: true,
    meta: { permission: PERMISSIONS.ORDERS }
  },
  {
    path: '/products/:filter?',
    name: 'products',
    component: Products,
    props: true,
    meta: { permission: PERMISSIONS.PRODUCTS }
  },
  {
    path: '/batches/:id',
    name: 'batch',
    component: BatchShow,
    props: true,
    meta: { permission: PERMISSIONS.ORDERS }
  },
  {
    path: '/shipments',
    name: 'shipments',
    component: Shipments,
    props: true,
    meta: { permission: PERMISSIONS.ORDERS }
  },
  {
    path: '/import/:provider?',
    name: 'import',
    component: ImportBeta,
    props: true,
    meta: { permission: PERMISSIONS.SHIPPING }
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: Notifications,
    meta: { permission: PERMISSIONS.DEFAULT_ACCESS }
  },
  {
    path: '/account-settings',
    name: 'settings',
    component: AccountSettings,
    meta: { permission: PERMISSIONS.ACCOUNT_MANAGEMENT }
  },
  {
    path: '/return-address',
    name: 'return-address',
    component: ReturnAddress,
    meta: { permission: PERMISSIONS.SHIPPING }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: { permission: PERMISSIONS.DEFAULT_ACCESS }
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: Dashboard,
    meta: { permission: PERMISSIONS.DEFAULT_ACCESS }
  },
  {
    path: '/referrals',
    name: 'referrals',
    component: Referrals,
    meta: { auth: true, permission: PERMISSIONS.ACCOUNT_MANAGEMENT }
  },
  {
    path: '/returns/orders',
    name: 'return-invoices',
    meta: { permission: PERMISSIONS.SHIPPING },
    component: ReturnsOrdersIndex
  },
  {
    path: '/returns',
    name: 'returns',
    props: true,
    meta: { permission: PERMISSIONS.ORDERS },
    component: ReturnsIndex
  },
  {
    path: '/returns/orders/:id',
    name: 'return-invoice',
    props: true,
    meta: { permission: PERMISSIONS.SHIPPING },
    component: ReturnsOrdersView
  },

  {
    path: '/returns-agreement',
    name: 'returns-agreement',
    meta: { permission: PERMISSIONS.DEFAULT_ACCESS },
    component: ReturnsAgree
  },
  {
    path: '/carm-agreement',
    name: 'carm-agreement',
    meta: { permission: PERMISSIONS.DEFAULT_ACCESS },
    component: CARMAgree
  },
  {
    path: '/fba',
    name: 'fba',
    meta: { permission: PERMISSIONS.DEFAULT_ACCESS },
    component: FBA
  },
  {
    path: '/merge-orders',
    name: 'merge-orders',
    component: MergeOrders,
    props: true,
    meta: { permission: PERMISSIONS.ORDERS }
  },
  {
    path: '/merged-orders',
    name: 'merged-orders',
    component: MergedOrders,
    props: true,
    meta: { permission: PERMISSIONS.ORDERS }
  },
];

// set auth required and layout
for (var i = 0; i < routes.length; i++) {
  routes[i].meta = Object.assign(routes[i].meta, {
    auth: true,
    layout: LAYOUT
  });
}

export default routes;
