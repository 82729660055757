<template>
  <StallionDialog
    v-model="show"
    :width="500"
    transition="dialog-bottom-transition"
    :title="!edit ? 'Create Tax Identifier' : 'Update Tax Identifier'"
  >
    <template
      v-if="taxIdentifier"
      #content
    >
      <v-form @submit.prevent>
        <v-container>
          <v-row dense>
            <v-col cols="12">
              <v-select
                v-model="taxIdentifier.tax_type"
                :items="tax_types"
                label=" Type"
                outlined
              />
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="taxIdentifier.number"
                label="Number"
                :hint="
                  taxIdentifier.tax_type == 'IOSS' ? 'IM + 10 digits' : ''
                "
                outlined
              />
            </v-col>

            <v-col cols="12">
              <!-- COUNTRY -->
              <v-autocomplete
                v-model="taxIdentifier.issuing_authority"
                v-validate="'required'"
                :error-messages="errs.collect('issuing_authority')"
                data-vv-name="issuing_authority"
                :items="countries"
                label="Issuing Authority"
                item-text="name"
                item-value="code"
                :search-input.sync="search_country"
                outlined
                hint="What country provided you this number?"
              />
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="taxIdentifier.nickname"
                label="Nickname"
                outlined
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>

    <template
      v-if="taxIdentifier"
      #actions
    >
      <v-spacer />
      <v-btn
        v-if="edit"
        color="primary"
        class="px-7"
        :disabled="loading"
        :loading="loading"
        @click="updateTaxID"
      >
        Update
      </v-btn>
      <v-btn
        v-else
        color="primary"
        class="px-7"
        :disabled="loading"
        :loading="loading"
        @click="saveTaxID"
      >
        Save
      </v-btn>
    </template>
  </StallionDialog>
</template>
<script>
import CONSTANTS from '@/constants';

const taxIdentifier = {
  tax_type: null,
  nickname: null,
  number: null,
  id: null,
  issuing_authority: null
};
export default {
  data() {
    return {
      countries: CONSTANTS.COUNTRIES,
      loading: false,
      show: false,
      edit: false,
      tax_types: ['IOSS', 'VAT', 'EORI'],
      taxIdentifier: null,
      search_country: null
    };
  },
  methods: {
    startNew() {
      this.show = true;
      this.taxIdentifier = Object.assign({}, taxIdentifier);
      this.edit = false;
    },
    startEdit(tax_identifier) {
      this.show = true;
      this.taxIdentifier = Object.assign({}, tax_identifier);
      this.edit = true;
    },
    cancelEdit() {
      this.show = false;
      this.taxIdentifier = null;
    },
    saveTaxID() {
      this.loading = true;
      this.$http
        .post('/tax-identifiers', this.taxIdentifier)
        .then((response) => response.data)
        .then((response) => {
          if (response.success) {
            this.processSuccess(response, 'Tax ID has been stored');
          } else {
            this.errorMessage(response.message);
          }
        })
        .catch((err) => {
          this.processError(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateTaxID() {
      this.loading = true;
      this.$http
        .put(`/tax-identifiers/${this.taxIdentifier.id}`, this.taxIdentifier)
        .then((response) => response.data)
        .then((response) => {
          this.processSuccess(response, 'Tax ID has been updated');
        })
        .catch((err) => {
          this.processError(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    processSuccess(response, message) {
      swal({
        title: 'Success',
        text: message,
        icon: 'success'
      });
      this.cancelEdit();
      this.$emit('save');
    },
    processError(err) {
      if (
        err.response.status === 422 &&
        typeof err.response.data.errors !== 'undefined'
      ) {
        const errors = err.response.data.errors;
        let message = '';

        for (var field in errors) {
          message += `${errors[field][0]}\n`;
        }
        this.errorMessage(message);
      } else {
        this.errorMessage('An error occurred while processing your request');
      }
    }
  }
};
</script>
<style>
.v-messages {
  padding-top: 10px !important;
  /* padding-left: 33px !important; */
}

.v-input--checkbox {
  margin: 0 !important;
}
</style>
