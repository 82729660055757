<template>
  <FullPageLayout
    :title="`Hello ${$store.state.main.initials}, Welcome back!`"
  >
    <template #subtitle>
      Find answers to common questions at our
      <a
        style="font-weight: 700; text-decoration: none"
        href="https://help.stallionexpress.ca/"
        target="_blank"
      >Help Centre!</a>
    </template>
    <template #default>
      <v-row>
        <v-col
          cols="12"
          class="pb-0 mt-6"
        >
          <h4>Quick Links</h4>
        </v-col>

        <!-- Create Shipment -->
        <v-col
          cols="12"
          md="4"
        >
          <v-card
            outlined
            hover
            class="px-5 py-3 fill-height"
            :disabled="!can(PERMISSIONS.SHIPPING)"
            @click="newShipment"
          >
            <v-list-item class="px-0">
              <v-list-item-avatar
                size="45"
                tile
              >
                <v-img :src="asset('images/create-shipment.png')" />
              </v-list-item-avatar>
              <v-list-item-content>
                <h4 style="color: #f9ac40">
                  Create Shipment
                </h4>
                <p class="pt-2">
                  Create a new shipment using our shipment creation tool.
                </p>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>

        <!-- Import Shipments -->
        <v-col
          cols="12"
          md="4"
        >
          <v-card
            outlined
            router
            to="/import"
            hover
            class="px-5 py-3 fill-height"
            :disabled="!can(PERMISSIONS.SHIPPING)"
          >
            <v-list-item class="px-0">
              <v-list-item-avatar
                size="40"
                tile
              >
                <v-img :src="asset('images/import-shipment.png')" />
              </v-list-item-avatar>
              <v-list-item-content>
                <h4 style="color: #f9ac40">
                  Import Shipments
                </h4>
                <p class="pt-2">
                  Import shipments from a file or an online store.
                </p>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>

        <!-- Pickup Request -->
        <v-col
          cols="12"
          md="4"
        >
          <v-card
            outlined
            router
            to="/pickups"
            hover
            class="px-5 py-3 fill-height"
            :disabled="!can(PERMISSIONS.PICKUPS)"
          >
            <v-list-item class="px-0">
              <v-list-item-avatar
                size="50"
                tile
                style="width: 55px;"
              >
                <v-img
                
                  :src="asset('images/stallion-truck.png')"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <h4 style="color: #f9ac40">
                  Pickup Request
                </h4>
                <p class="pt-2">
                  Create a pickup request for your shipments.
                </p>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>

      <!-- My Stallion Dashboard -->
      <v-row>
        <v-col
          cols="12"
          class="pb-0"
        >
          <h4>My Stallion Dashboard</h4>
        </v-col>

        <v-col
          cols="12"
          md="8"
          class="pt-3"
        >
          <v-row>
            <!-- Tracking -->
            <v-col
              cols="12"
              md="6"
            >
              <track-widget />
            </v-col>

            <!-- Credits -->
            <!--can from mixin.js-->
            <v-col
              cols="12"
              md="6"
            >
              <v-card
                outlined
                class="pa-5"
                style="height: 100%"
              >
                <h4>Credits</h4>

                <v-container class="pa-0">
                  <v-row justify="space-between">
                    <v-col
                      cols="12"
                      md="7"
                      class="pt-3"
                    >
                      <h1
                        v-if="$auth.user"
                        style="font-size: 25px"
                      >
                        {{ $auth.user.credits | currency }}
                      </h1>
                      <p>Available Credits</p>
                    </v-col>
                    <v-col
                      cols="12"
                      md="5"
                      class="pt-3 pl-0 credits-button"
                      style="text-align: right"
                    >
                      <v-btn
                        :disabled="!can(PERMISSIONS.ACCOUNT_MANAGEMENT)"
                        color="primary"
                        class="pa-4 mt-5"
                        small
                        block
                        to="/credits"
                        outlined
                      >
                        View Credits
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <!-- Calculator -->
            <v-col cols="12">
              <v-card
                outlined
                class="py-6 px-6"
                :disabled="!can(PERMISSIONS.SHIPPING)"
              >
                <h4>Rate Calculator</h4>
                <p>Get an estimate with our shipping calculator!</p>
                <rates-widget />
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col
          cols="12"
          md="4"
          class="pt-3"
        >
          <!-- Blog -->
          <blog-widget />
        </v-col>
      </v-row>

      <!-- Account -->
      <v-row class="">
        <v-col
          cols="12"
          class="pb-0"
        >
          <h4>Account</h4>
        </v-col>

        <!-- Account Settings -->
        <!--isPrimaryAccountHolder from mixin.js-->
        <v-col
          cols="12"
          md="4"
        >
          <v-card
            outlined
            router
            to="/account-settings"
            hover
            class="px-5 py-3"
            fill-height
            :disabled="!can(PERMISSIONS.ACCOUNT_MANAGEMENT)"
            style="min-height:110px;"
          >
            <v-list-item class="px-0">
              <v-list-item-avatar
                size="50"
                tile
              >
                <v-img :src="asset('images/accountsettings.png')" />
              </v-list-item-avatar>
              <v-list-item-content>
                <h4 style="color: #f9ac40">
                  Account Settings
                </h4>
                <p class="pt-2">
                  View and update your account and contact details.
                </p>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>

        <!-- My US Address -->
        <v-col
          cols="12"
          md="4"
        >
          <v-card
            outlined
            router
            to="/return-address"
            hover
            class="px-5 py-3"
            :disabled="!can(PERMISSIONS.ACCOUNT_MANAGEMENT)"
            fill-height
            style="min-height:110px;"
          >
            <v-list-item class="px-0">
              <v-list-item-avatar
                size="40"
                tile
              >
                <v-img :src="asset('images/us-address.png')" />
              </v-list-item-avatar>
              <v-list-item-content>
                <h4 style="color: #f9ac40">
                  My US Address
                </h4>
                <p class="pt-2">
                  View your return address for new US shipments.
                </p>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>

        <!-- Claims -->
        <!--isPrimaryAccountHolder from mixin.js-->
        <v-col
          cols="12"
          md="4"
        >
          <v-card
            outlined
            router
            to="/claims"
            hover
            class="px-5 py-3"
            fill-height
            :disabled="!can(PERMISSIONS.CLAIMS)"
            style="min-height:110px;"
          >
            <v-list-item class="px-0">
              <v-list-item-avatar
                size="45"
                tile
              >
                <v-img
                  width="45"
                  :src="asset('images/stallion-package.png')"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <h4 style="color: #f9ac40">
                  Claims
                </h4>
                <p class="pt-2">
                  Make a claim on insured shipments.
                </p>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          mb="12"
          class="py-10"
        />
      </v-row>

      <!-- user -->
      <!-- <v-row>
        <v-col cols="12">
          <h2>Your Account</h2>
        </v-col>
        <v-col cols="12" md="6">
          <v-card router to="/account-settings" hover>
            <v-card-title class="headline">
              <v-icon left color="black">mdi-account-circle</v-icon>Account
              Details
            </v-card-title>
            <v-card-text
              >View and update your account and contact details.</v-card-text
            >
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card hover router to="/credits">
            <v-card-title class="headline">
              <v-icon left color="black">mdi-credit-card</v-icon>Credits
            </v-card-title>
            <v-card-text
              >Manage your credit balance and download history.</v-card-text
            >
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card hover router to="/invoices">
            <v-card-title class="headline">
              <v-icon left color="black">mdi-file-document</v-icon>Invoices
            </v-card-title>
            <v-card-text>View and download your monthly invoices.</v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card hover router to="/return-address">
            <v-card-title class="headline">
              <v-icon left color="black">mdi-map-marker</v-icon>Return Address
            </v-card-title>
            <v-card-text
              >View your return address for new US shipments.</v-card-text
            >
          </v-card>
        </v-col>
      </v-row> -->
    </template>
  </FullPageLayout>
</template>

<script>
import PERMISSIONS from '@/constants/client-permissions';
import BlogWidget from './BlogWidget.vue';
import RatesWidget from './RatesWidget.vue';
import TrackWidget from './TrackWidget.vue';
export default {
  metaInfo: {
    title: "Dashboard | Stallion Express"
  },
  components:{
    BlogWidget,
    RatesWidget,
    TrackWidget
  },
  data: function () {
    return {
      dense: null,
      rounded: null,
      PERMISSIONS: PERMISSIONS
    };
  },
  methods: {
    logout: function () {
      this.$http.post('/logout').then(
        () => {
          window.location.href = '/login';
          // window.location.reload();
        },
        () => {
          window.location.reload();
        }
      );
    },
    newShipment: function () {
      this.$store.state.singleFlow.single_dialog = true;

      Event.fire('edit-shipment', {
        shipment: null,
        step: 'recipient'
      });
    },
    pickupRequest: function () {
      this.$store.state.main.fba_request_dialog = true;
    }
  }
};
</script>

<style scoped>
.v-application p {
  margin-bottom: 0px;
}

.compact-form {
  transform: scale(0.8);
  transform-origin: left;
}

h4 {
  margin-bottom: 0px !important;
}

h1 {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

h2 {
  margin-bottom: 0px !important;
}

.no-background-hover::before {
  background-color: transparent !important;
}

@media only screen and (max-width: 960px) {
  .credits-button {
    text-align: left !important;
  }
}
</style>
