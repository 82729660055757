<template>
  <v-row dense>
    <v-col
      cols="12"
    >
      <span class="font-weight-bold"> Search By Status </span>
    </v-col>
  
    <v-col>
      <v-badge
        v-for="filter in filters"
        :key="filter.name"
        class="mb-3 mr-3"
        :content="filter?.count ?? null"
        color="primary"
        overlap
        :value="filter?.count > 0"
      >
        <v-btn
          color="grey darken-1"
          class="filter-button"
          :class="{'v-btn--active': f === filter.value}"
          small
          outlined
          exact
          @click="f = filter.value"
        >
          <v-icon
            small
            left
          >
            {{ filter.icon }}
          </v-icon>
          {{ filter.text }}
        </v-btn>
      </v-badge>
    </v-col>
  </v-row>
</template>
  
  <script>
  export default {
    props: {
      filters: {
        type: Array,
        default: () => {
          return [];
        }
      },
      value: {
        type: String,
        default: null
      }
    },
    computed: {
      f: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      },
    }
  };
  </script>
  <style scoped>
  .filter-button {
    text-transform: none;
    border-radius: 5px !important;
  }
  .filter-button.v-btn--active{
   box-shadow: -1px 3px 2px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 20%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
   background-color: #FFFFFF !important;
  }
  .filter-button:hover{
   box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 20%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
    background-color: #FFFFFF !important;
  }
  </style>
  