<template>
  <StallionDialog
    v-model="show"
    :width="500"
    persistent
    title="Request Returns"
  >
    <v-card>
      <v-layout column>
        <v-flex
          xs12
        >
          <div class="dialog-info">
            Request {{ returns.length }} Shipment Returns
          </div>
        </v-flex>
        <v-flex
          xs12
          text-center
        />
      </v-layout>
    </v-card>

    <template #actions>
      <v-btn
        color="primary"
        :loading="requestReturnLoading"
        :disabled="requestReturnLoading"
        @click="requestReturns()"
      >
        Confirm
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    returns: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      requestReturnLoading: false,
      dowload_loading: false,
      statuses: [],
      status: null
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  mounted() {
    // this.getReturnStatuses();
  },
  methods: {
    getReturnStatuses() {
      this.$http
        .get(`/return-statuses`)
        .then(response => response.data)
        .then(response => {
          this.statuses = response;
        });
    },
    requestReturns: function() {
      this.requestReturnLoading = true;

      var ids = [];
      this.returns.forEach(function(shipmentReturn) {
        if (shipmentReturn.service_id !== 2) ids.push(shipmentReturn.id);
      });
      this.$http
        .post('/admin/return/requests', {
          ids: ids
        })
        .then(response => {
          if (response.data.success) {
            this.successMessage('Shipment return billed successfully');
          }
        })
        .catch(() => {
        })
        .finally(() => {
          Event.fire('update-admin-returns');
          this.requestReturnLoading = false;
          this.show = false;
        });
    }
  }
};
</script>
