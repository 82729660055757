<template>
  <div>
    <v-simple-table dense>
      <template #default>
        <v-card
          v-if="loading"
          :loading="loading"
        >
          <v-card-text class="text-center">
            <h3>Loading Items...</h3>
          </v-card-text>
        </v-card>
  
        <template v-else>
          <thead>
            <tr>
              <th>
                <v-checkbox
                  v-model="selectAll"
                  :disabled="items.length == 0"
                  :indeterminate="selectAllIndeterminate"
                  @change="selectAllRow"
                />
              </th>
  
              <th
                v-for="header in filteredHeaders"
                :key="header.text"
                :class="header.class"
                @click.stop="onSort(header)"
              >
                {{ header.text }}
  
                <span v-if="sortable(header) && activeSort(header)">
                  <v-icon>
                    {{ sortIcon(header) }}
                  </v-icon>
                </span>
              </th>
  
              <th v-if="manageColumnTableKey">
                <!-- buildManageColumnHeaders is from mixin.js -->
                <stallion-table-settings
                  :table="manageColumnTableKey"
                  :columns="buildManageColumnHeaders(headers, manageColumnSettings)"
                  :update-column-handler="updateColumns"
                />
              </th>
            </tr>
          </thead>
  
          <tbody v-if="items.length > 0">
            <tr
              v-for="(item, index1) in items"
              :key="'tr' + index1"
              @click.stop="onRowClick(item)"
            >
              <td>
                <v-checkbox
                  :value="item._selected"
                  @click.stop="onRowClick(item, 'multiple')"
                />
              </td>
  
              <td
                v-for="(column, index2) in filteredHeaders"
                :key="'val' + index2"
              >
                <slot
                  :name="column.value"
                  :item="item"
                >
                  <div v-html="item[column.value]" />
                </slot>
              </td>
  
              <td />
            </tr>
          </tbody>
  
          <tbody v-else>
            <tr>
              <td :colspan="filteredHeaders.length + 2">
                <v-card-text class="text-center">
                  No data available
                </v-card-text>
              </td>
            </tr>
          </tbody>
        </template>
      </template>
    </v-simple-table>

    <v-card class="d-flex justify-end">
      <v-card-title>
        <v-row class="v-data-footer">
          <v-col>
            <span> Rows per page: </span>
          </v-col>

          <v-col>
            <v-select
              v-model="options.itemsPerPage"
              :items="rowsPerPage"
            />
          </v-col>

          <v-col>
            <span v-if="pagination && pagination.total > 0">
              {{ pagination.from }} - {{ pagination.to }} of {{ pagination.total }}
            </span>
            <span v-else> - </span>
          </v-col>

          <v-col cols="1">
            <v-btn
              icon
              :disabled="!pagination || !pagination.prev_page_url"
              @click="onPage(pagination.current_page - 1)"
            >
              <v-icon>
                mdi-chevron-left
              </v-icon>
            </v-btn>
          </v-col>

          <v-col cols="1">
            <v-btn
              icon
              :disabled="!pagination || !pagination.next_page_url"
              @click="onPage(pagination.current_page + 1)"
            >
              <v-icon>
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
  </div>
</template>
  
<script>

export default {
  props: {
    manageColumnTableKey: {
      type: String,
      default: null
    },
    headers: {
      type: Array,
      default() {return []} 
    },
    rowsPerPage: {
      type: Array,
      default() {
        return [50, 100, 200, 500]
      }
    },
    items: {
      type: Array,
      default() {return []} 
    },
    pagination: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default() {return {}}
    },
    itemKey: {
      type: String,
      default: 'id'
    },
    value: {
      type: Array,
      default() {return []} 
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      selectAll: false,
      manageColumnSettings: []
    }
  },

  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },

    rows: {
      get() {
        return this.items.map((item) => {
          return this.headerValues.map(header => item[header])
        });
      },
    },

    headerValues: {
      get() {
        return this.filteredHeaders.map((header) => header.value)
      },
    },

    filteredHeaders() {
      // filteredColumnHeaders is from mixin.js
      return this.manageColumnTableKey
        ? this.filteredColumnHeaders(this.headers, this.manageColumnSettings)
        : this.headers
    },

    selectAllIndeterminate() {
      const selected = this.items.filter((item) => {
        return item._selected
      })

      if (selected.length == 0) {
        return false
      }

      return selected.length != this.items.length
    }
  },


  watch: {},


  async created() {
    if (this.manageColumnTableKey) {
      // getManageColumnSettings is from mixin.js
      this.manageColumnSettings = await this.getManageColumnSettings(this.manageColumnTableKey)
    }
  },

  methods: {
    updateColumns(columns) {
      this.manageColumnSettings = columns
    },

    getSortField(header) {
      return header.sortField !== undefined ? header.sortField : header.value
    },

    sortable(header) {
      return header.sortable !== false
    },

    activeSort(header) {
      return this.options.sortBy && this.options.sortBy[0] == this.getSortField(header)
    },

    sortIcon() {
      return this.options.sortDesc && this.options.sortDesc[0] ? 'mdi-arrow-down' : 'mdi-arrow-up'
    },

    onSort(header) {
      if (this.sortable(header)) {
        this.options.sortBy = [this.getSortField(header)]
        this.options.sortDesc = this.options.sortDesc[0] == null ? [true] : [!this.options.sortDesc[0]]
      }
    },

    onPage(page) {
      this.options.page = page
    },

    setSelectedRows() {
      this.selected = this.items.filter((item) => {
        return item._selected
      })
    },

    onRowClick(item, type = 'single') {
      this.items.map((row) => {
        const match = row[this.itemKey] == item[this.itemKey]
        if (type == 'single') {
          row._selected = match
        } else {
          // multiple
          if (match) {
            row._selected = !item._selected
          }
        }
      })

      this.setSelectedRows()
    },

    selectAllRow() {
      this.items.map((item) => {
        item._selected = this.selectAll
      })

      this.setSelectedRows()
    },
  }
};
</script>