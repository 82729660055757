<template>
  <v-container
    grid-list-md
    mb-12
  >
    <v-layout wrap>
      <v-flex xs12>
        <h1>
          <i
            aria-hidden="true"
            class="mdi mdi-download"
          /> Export Inbound
        </h1>
      </v-flex>
    </v-layout>
    <div class="separator mb-4" />
    <v-layout wrap>
      <v-flex
        xs12
        md6
      >
        <v-card outlined>
          <v-container
            container--fluid
            grid-list-lg
          >
            <v-layout wrap>
              <v-flex xs12>
                <h1>
                  <i class="mdi mdi-download" />
                  <span>Export Inbound</span>
                </h1>

                <v-select
                  v-model="export_type"
                  outlined
                  prepend-inner-icon="mdi-file"
                  :items="['Border Connect']"
                  label="Type"
                />

                <v-layout
                  align-center
                  class="mt-4"
                >
                  <v-flex
                    xs12
                    text-center
                  >
                    <v-btn
                      :loading="loading1"
                      :disabled="loading1"
                      
                      color="primary"
                      @click="getExport"
                    >
                      Download
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>

      <v-flex
        v-if="can('convert-border-connect-inbound')"
        xs12
        md6
      >
        <v-card
          height="100%"
          outlined
        >
          <v-container
            container--fluid
            grid-list-lg
          >
            <v-layout wrap>
              <v-flex xs12>
                <h1>
                  <i class="mdi mdi-import" />
                  <span>Covert Border Connect</span>
                </h1>
                <v-text-field
                  v-model="trip_number"
                  v-validate="'required|max:50'"
                  :error-messages="errs.collect('trip_number')"
                  label="Trip Number"
                  data-vv-name="trip_number"
                  prefix="726G"
                  outlined
                  placeholder=" "
                />

                <v-text-field
                  v-model="port_of_entry"
                  v-validate="'required|max:50'"
                  :error-messages="errs.collect('port_of_entry')"
                  label="First Canadian Port of Entry"
                  data-vv-name="port_of_entry"
                  outlined
                />

                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template #activator="{ on }">
                    <v-text-field
                      v-model="date"
                      label="Estimated Arrival Date"
                      outlined
                      readonly
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="date"
                    @input="menu1 = false"
                  />
                </v-menu>

                <v-select
                  v-model="time"
                  outlined
                  :items="times"
                  label="Estimated Arrival Time"
                />

                <v-file-input
                  v-model="file"
                  v-validate="'required'"
                  accept=".csv, .xlsx, xls"
                  prepend-inner-icon="mdi-file"
                  prepend-icon
                  outlined
                  label="Border Connect File"
                  :error-messages="errs.collect('file')"
                  data-vv-name="file"
                />

                <v-layout
                  align-center
                  class="mt-4"
                >
                  <v-flex
                    xs12
                    text-center
                  >
                    <v-btn
                      :loading="loading2"
                      :disabled="loading2"
                      
                      color="primary"
                      @click="uploadFile"
                    >
                      Convert
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout>
      <v-flex xs12>
        <p
          v-for="(error, index) in errors"
          :key="index"
          class="alert alert-danger"
        >
          {{ error }}
        </p>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      trip_number: null,
      time: '11:00:00',
      date: moment().format('YYYY-MM-DD'),
      port_of_entry: '0427',
      export_type: 'Border Connect',
      file: null,
      form: new FormData(),
      form2: new FormData(),
      start_dropoff_date: moment().format('YYYY-MM-DD'),
      end_dropoff_date: moment().format('YYYY-MM-DD'),

      menu1: false,
      menu2: false,
      menu3: true,
      items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
      errors: [],
      loading1: false,
      loading2: false,
      loading3: false,
      file2: null,
      times: [
        { text: '1 AM', value: '1:00:00' },
        { text: '2 AM', value: '2:00:00' },
        { text: '3 AM', value: '3:00:00' },
        { text: '4 AM', value: '4:00:00' },
        { text: '5 AM', value: '5:00:00' },
        { text: '6 AM', value: '6:00:00' },
        { text: '7 AM', value: '7:00:00' },
        { text: '8 AM', value: '8:00:00' },
        { text: '9 AM', value: '9:00:00' },
        { text: '10 AM', value: '10:00:00' },
        { text: '11 AM', value: '11:00:00' },
        { text: '12 AM', value: '12:00:00' },
        { text: '1 PM', value: '13:00:00' },
        { text: '2 PM', value: '14:00:00' },
        { text: '3 PM', value: '15:00:00' },
        { text: '4 PM', value: '16:00:00' },
        { text: '5 PM', value: '17:00:00' },
        { text: '6 PM', value: '18:00:00' },
        { text: '7 PM', value: '19:00:00' },
        { text: '8 PM', value: '20:00:00' },
        { text: '9 PM', value: '21:00:00' },
        { text: '10 PM', value: '22:00:00' },
        { text: '11 PM', value: '23:00:00' },
        { text: '12 PM', value: '24:00:00' }
      ]
    };
  },

  methods: {
    customFormatter(date) {
      return moment(date).format('MMMM Do YYYY, h:mm:ss a');
    },
    uploadFile() {
      // upload file

      this.$validator.validate().then(result => {
        if (result) {
          this.loading2 = true;
          this.errors = [];
          this.form.append('import_file', this.file);

          this.form.append('trip_number', '726G' + this.trip_number);
          this.form.append('time', this.time);
          this.form.append('port_of_entry', this.port_of_entry);
          this.form.append('date', this.date);
          this.$http
            .post('/admin/convert-inbound', this.form)
            .then(
              res => {
                this.disable = false;
                if (res.data.success) {
                  const items = res.data.csvFile;
                  const replacer = (key, value) =>
                    value === null ? '' : value; // specify how you want to handle null values here
                  const header = Object.keys(items[0]);
                  let csv = items.map(row =>
                    header
                      .map(fieldName =>
                        JSON.stringify(row[fieldName], replacer)
                      )
                      .join(',')
                  );
                  csv.unshift(header.join(','));
                  csv = csv.join('\r\n');

                  console.log(csv);
                  let blob2 = new Blob([csv], {
                    type: 'text/csv'
                  });
                  let link2 = document.createElement('a');
                  link2.href = window.URL.createObjectURL(blob2);
                  link2.download = res.data.csvFileName;
                  link2.click();

                  var jsonse = JSON.stringify(res.data.jsonFile);
                  console.log(jsonse);
                  let blob = new Blob([jsonse], {
                    type: 'application/json'
                  });
                  let link = document.createElement('a');
                  link.href = window.URL.createObjectURL(blob);

                  link.download = res.data.jsonFileName;
                  link.click();
                } else {
                  this.errors = res.data.errors;
                  // swal({
                  //   title: "Error",
                  //   text: res.data.errors,
                  //   icon: "error"
                  // });
                }
              },
              err => {
                this.disable = false;
                swal({
                  title: 'Error',
                  text: err,
                  icon: 'error'
                });
              }
            )
            .then(() => {
              this.loading2 = false;
              this.file = null;
            });
        }
      });
    },
    setFile(e) {
      if (e.target.files.length) {
        this.file = e.target.files[0];
      }
    },

    setFile2(e) {
      if (e.target.files.length) {
        this.file2 = e.target.files[0];
      }
    },

    getExport() {
      this.loading1 = true;

      this.$http
        .get(
          `/admin/return-trips/export?export_type=${this.export_type}&start_date=${this.start_dropoff_date}&end_date=${this.end_dropoff_date}&user_email=${this.user_email}`
        )
        .then(response => {
          this.downloadFileUrl(response.data,
            this.export_type +
            ' (' +
            moment().format('YYYYmmdHHMMSS') +
            ').csv', 'text/csv');
        })
        .catch(err => {
          if (err.response.status === 404) {
            swal({
              title: 'Error',
              text: 'Nothing found',
              icon: 'error'
            });
          }
          
        })
        .finally(() => {
          this.loading1 = false;
        });
    }
  }
};
</script>
