<template>
  <FullPageLayout
    title="List Criteria"
  >
    <template #subtitle>
      Lists are most commonly used in setting up a rule to apply against a list of scenarios.
    </template>

    <template #page-links>
      <order-links />
    </template>
    <template #default>
      <list-criteria-table ref="listCriteriaTable" />
    </template>
  </FullPageLayout>
</template>

<script>
import ListCriteriaTable from './ListCriteriaTable.vue';
export default {
  metaInfo: {
    title: "List Criteria | Stallion Express"
  },
  components: {
      ListCriteriaTable
  },
  props: {},
  data() {
    return {};
  },
  methods: {}
};
</script>
