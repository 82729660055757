import Vue from 'vue';
// returns
Vue.component('ReturnDetails', () =>
  import(
     '../components/main/returns/return-details.vue'
  )
);
Vue.component('ReturnOrdersPrint', () =>
  import(
     '../components/main/orders/orders-print.vue'
  )
);

Vue.component('ShowDetailsMobile', () =>
  import(
     '../components/main/shipments/show-details-mobile.vue'
  )
);

Vue.component('ShipmentActions', () =>
  import(
     '../components/main/shipments/action-bar/ActionBar.vue'
  )
);

Vue.component('Print', () =>
  import(
     '../components/main/shipments/action-bar/PrintShipments.vue'
  )
);

// chart
Vue.component('HorizontalbarChart', () =>
  import(
     '../components/main/charts/horizontal-bar.vue'
  )
);
Vue.component('BarChart', () =>
  import( '../components/main/charts/bar.vue')
);
Vue.component('PieChart', () =>
  import( '../components/main/charts/pie.vue')
);
Vue.component('LineChart', () =>
  import( '../components/main/charts/line.vue')
);

Vue.component('TrackingDetails', () =>
  import(
     '../components/main/shipments/details/tracking-details.vue'
  )
);

Vue.component('ProtectionDetails', () =>
  import(  '../components/main/shipments/details/protection-details.vue')
);

Vue.component('PostageDetails', () =>
  import(
     '../components/main/shipments/details/postage-details.vue'
  )
);
Vue.component('PackageDetails', () =>
  import(
     '../components/main/shipments/details/package-details.vue'
  )
);
Vue.component('StoreDetails', () =>
  import(
     '../components/main/shipments/details/store-details.vue'
  )
);
Vue.component('RecipientDetails', () =>
  import(
     '../components/main/shipments/details/recipient-details.vue'
  )
);
Vue.component('ErrorDetails', () =>
  import(
     '../components/main/shipments/details/error-details.vue'
  )
);
Vue.component('AutomationDetails', () => import('../components/main/shipments/details/automation-details.vue'));
Vue.component('ReturnAddressDetails', () =>
  import(
     '../components/main/shipments/details/return-details.vue'
  )
);

Vue.component('RateDetails', () =>
  import(
     '../components/main/shipments/details/rate-details.vue'
  )
);
Vue.component('CustomsDetails', () =>
  import(
     '../components/main/shipments/details/customs-details.vue'
  )
);

Vue.component('AddressVerificationDetails', () => import('../components/main/shipments/details/address-verification-details.vue'));

Vue.component('VerifyPhone', () =>
  import(
     '../components/shared/VerifyPhone.vue'
  )
);

// const VueUploadComponent = require('vue-upload-component');
Vue.component('FileUpload', import('vue-upload-component'));

Vue.component('FullPageLayout', () =>
  import(
     '../components/layouts/FullPageLayout.vue'
  )
);

Vue.component('SingleCardLayout', () =>
  import('../components/layouts/SingleCardLayout.vue')
);

Vue.component('SidePanelLayout', () =>
  import(
     '../components/layouts/SidePanelLayout.vue')
);
Vue.component('StallionDialog', () =>
  import(
     '../components/shared/StallionDialog.vue'
  )
);

Vue.component('StallionTable', () =>
  import(
     '../components/shared/StallionTable.vue'
  )
);

Vue.component('StallionTableSettings', () =>
  import(
     '../components/shared/StallionTableSettings.vue'
  )
);

Vue.component('ManageColumnDialog', () =>
  import(
     '../components/shared/ManageColumnDialog.vue'
  )
);

Vue.component('UserFeedbackDialog', () =>
  import(
     '../components/shared/UserFeedbackDialog.vue'
  )
);

Vue.component('AddressBookQuickActionButtons', () =>
  import(
    '../pages/main/settings/tabs/AddressBook/QuickAction/Buttons.vue'
  )
);
