function initialState() {
  return {
    single_dialog: false,
    shipment: {
      id: null,
      weight_unit: 'lbs',
      size_unit: 'in',
      currency: 'USD',
      needs_postage: 1,
      is_fba: 0
    },
    rate: {

    },
    step: 1,
    processing: 0
  };
}

const singleFlow = {
  namespaced: true,
  state: initialState(),
  getters: {
    loading: state => {
      return state.processing > 0
    }
  },
  mutations: {
    next(state) {
      state.step++;
    },
    previous(state) {
      state.step--;
    },
    reset(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach(key => {
        state[key] = s[key];
      });
    },
  }
};

export default singleFlow;
