<template>
  <v-menu
    v-if="cart.itemsCount > 0"
    bottom
    :min-width="400"
    :rounded="rounded"
    offset-y
    class="pa-10"
  >
    <template #activator="{ on }">
      <v-btn
        class="ml-2 mr-2 btn-fix dense"
        icon
        depressed
        small
        :dense="true"
        color="#fff"
        v-on="on"
      >
        <v-badge
          bordered
          offset-x="5"
          offset-y="11"
          color="primary"
          :content="cart.itemsCount <= 99 ? cart.itemsCount : 99"
        >
          <v-icon color="blue-grey darken-2">
            mdi-cart-outline
          </v-icon>
        </v-badge>
      </v-btn>
    </template>

    <v-list
      subheader
      two-line
      dense
      class="pa-0"
    >
      <v-subheader class="font-weight-bold px-8 pt-5">
        Your Cart
      </v-subheader>
      <template v-for="(item, index) in cart.items">
        <v-list-item
          :key="'b' + index"
          ripple
          class="px-8"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
            <v-list-item-subtitle class="font-weight-bold">
              {{ item.purchase_quantity }} x
              {{ item.price | currency }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-icon>
            <span style="font-size: 0.8rem">
              {{ (item.purchase_quantity * item.price) | currency }}
            </span>
            <v-spacer />
          </v-list-item-icon>
        </v-list-item>
        <v-divider
          :key="'a' + index"
          class="ma-0"
        />
      </template>
      <v-row
        align-center
        justify="space-around"
        class="pt-6 pb-6 px-8 ma-0"
        style="width: 100%"
      >
        <v-btn
          small
          class="pa-4"
          color="primary"
          to="/supplies/checkout"
        >
          Checkout
        </v-btn>
        <v-flex text-right>
          <p class="ma-0 font-weight-bold">
            Subtotal: {{ cart.subtotal | currency }}
          </p>
        </v-flex>
      </v-row>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  data() {
    return {
      rounded: false
    };
  },
  computed: {
    cart: {
      get() {
        return this.$store.state.cart;
      },
      set(value) {
        this.$store.state.cart = value;
      }
    }
  },
};
</script>