<template>
  <StallionDialog
    v-model="add_dialog"
    persistent
    :width="600"
    title="Add Credits"
  >
    <template #content>
      <v-container grid-list-md>
        <v-layout wrap>
          <v-flex xs12>
            <v-autocomplete
              v-model="type_id"
              v-validate="'required'"
              :error-messages="errs.collect('history_type')"
              data-vv-name="history_type"
              :items="transaction_types"
              label="Type"
              item-text="name"
              item-value="id"
              :search-input.sync="searchInput2"
              outlined
              :hint="selected && selected.description"
            />
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="subtotal"
              v-validate="'required|decimal:2|min_value:0.01|max_value:20000'"
              label="Amount"
              type_id="number"
              :error-messages="errs.collect('subtotal')"
              data-vv-name="subtotal"
              required
              outlined
            />
          </v-flex>

          <v-flex xs12>
            <v-text-field
              v-model="description"
              v-validate="'required'"
              :error-messages="errs.collect('description')"
              data-vv-name="description"
              label="Description"
              outlined
            />
          </v-flex>
        </v-layout>
      </v-container>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        text
        :loading="loading"
        :disabled="loading"
        @click.native="editCredits()"
      >
        Save
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    user: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      type_id: null,
      module: 'Dashboard',
      menu1: '',
      add_dialog: false,
      subtotal: null,
      description: '',
      action: null,
      searchInput1: null,
      searchInput2: null,
      loading: false,
      disabled: false,

      transaction_types: [
        {
          id: 107,
          name: 'Goodwill Credits',
          description:
            'Give the customer credits for which payment was not recieved.'
        },
        {
          id: 108,
          name: 'Trial Credits',
          description:
            'Give the customer credits for which payment was not recieved for a trial.'
        }
      ]
    };
  },
  computed: {
    selected() {
      const index = this.transaction_types.findIndex(
        (x) => x.id === this.type_id
      );
      const category = this.transaction_types[index];

      return category;
    }
  },
  watch: {
    type_id() {
      this.calculate();
    },
    subtotal() {
      this.calculate();
    }
  },
  methods: {
    openDialog() {
      this.add_dialog = true;
    },
    calculate() {
      if (this.selected) {
        this.description =
            this.selected.name +
            ' for ' +
            this.$options.filters.currency(Number(this.subtotal));
      }
    },
    editCredits: function () {
      this.$validator.validate().then((result) => {
        if (result) {
          this.loading = true;
          this.$http
            .post(`/admin/transactions/add-credits`, {
              user_id: this.user.id,
              subtotal: this.subtotal,
              type_id: this.type_id,
              description: this.description
            })
            .then((res) => {
              if (res.data.success) {
                this.successMessage(
                  this.$options.filters.currency(Number(this.subtotal)) +
                    ' added to ' +
                    this.user.email
                );

                this.$emit('complete');

                this.close();
              } else {
                this.errorMessage();
              }
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    close() {
      this.add_dialog = false;
      this.subtotal = '';
      this.description = '';
      this.action = '';
      this.type_id = '';
      this.$validator.reset();
    }
  }
};
</script>
