<template>
  <StallionDialog
    v-model="show"
    :width="500"
    :disabled="disabled"
    persistent
    title="Update Shipment Status"
  >
    <template #content>
      <v-layout column>
        <v-flex
          xs12
        >
          <div class="dialog-info">
            Change the status of {{ shipments.length }} shipment
          </div>
        </v-flex>
        <v-flex
          xs12
          text-center
          mt-4
          mb-12
        >
          <v-select
            v-model="status"
            :items="statuses"
            item-value="id"
            item-text="description"
          />
        </v-flex>
      </v-layout>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        :loading="update_loading"
        :disabled="update_loading"
        @click="updateStatuses()"
      >
        Update
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    shipments: {
      type: Array,
      default: () => []
    },

    disabled: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      update_loading: false,
      dowload_loading: false,
      statuses: [],
      status: null,
      show: null
    };
  },
  mounted() {
    this.getShipmentStatuses();
  },
  methods: {
    openDialog() {
      this.show = true;
    },
    getShipmentStatuses() {
      this.$http
        .get(`/shipment-statuses`)
        .then(response => response.data)
        .then(response => {
          this.statuses = response.filter(this.displayStatus);
        });
    },
    displayStatus(status) {
      if ([11, 12].indexOf(status.id) !== -1) {
        return this.hasRole('super_admin');
      }
      return true;
    },
    updateStatuses: function() {
      this.update_loading = true;

      var ids = [];
      this.shipments.forEach(function(shipment) {
        ids.push(shipment.id);
      });
      this.$http
        .post('/admin/shipments/update-statuses', {
          ids: ids,
          status: this.status
        })
        .then(() => {
          Event.fire('admin-update-shipments');
        })
        .catch(() => {
        })
        .finally(() => {
          this.update_loading = false;
          this.show = false;
        });
    }
  }
};
</script>
