<template>
  <v-card
    class="mb-5 pa-8"
    outlined
    :disabled="!can(PERMISSIONS.ACCOUNT_MANAGEMENT) && !userStoreId"
  >
    <h3>Shipping Fulfillment</h3>
    <p>Adjust import settings.</p>

    <v-form @submit.prevent="updateUserFulfillment">
      <v-checkbox
        v-model="fulfillSettings.fulfill_shipments"
        label="Fulfill Imported Shipments"
      />

      <transition-group
        name="component-fade"
        mode="out-in"
      >
        <v-select
          v-if="fulfillSettings.fulfill_shipments"
          :key="1"
          v-model="fulfillSettings.fulfillment_delay"
          :items="times"
          item-text="text"
          item-value="value"
          label="Fulfill Orders"
        />

        <div
          v-if="
            fulfillSettings.fulfill_shipments &&
              fulfillSettings.fulfillment_delay == 0
          "
          :key="2"
        >
          <v-row>
            <v-col cols="1">
              <v-checkbox
                v-model="fulfillSettings.fulfillment_custom_delay.hours_enabled"
                label=""
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="fulfillSettings.fulfillment_custom_delay.hours"
                v-validate="'min_value:1'"
                prepend-icon="mdi-timer"
                label="Number of hour(s) after postage purchased"
                data-vv-name="hours"
                :error-messages="errs.collect('hours')"
                type="number"
                name="hours"
                data-vv-validate-on="blur"
                class="pr-2"
                :disabled="
                  !fulfillSettings.fulfillment_custom_delay.hours_enabled
                "
              />
            </v-col>
          </v-row>

          <v-row class="mt-n8">
            <v-col cols="1">
              <v-checkbox
                v-model="fulfillSettings.fulfillment_custom_delay.time_enabled"
                label=""
              />
            </v-col>
            <v-col>
              <v-menu
                ref="timeMenu"
                v-model="timeMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="
                  fulfillSettings.fulfillment_custom_delay.time
                "
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
                :disabled="
                  !fulfillSettings.fulfillment_custom_delay.time_enabled
                "
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="fulfillSettings.fulfillment_custom_delay.time"
                    label="At a specific time after postage purchased"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    :disabled="
                      !fulfillSettings.fulfillment_custom_delay.time_enabled
                    "
                    v-on="on"
                  />
                </template>
                <v-time-picker
                  v-if="timeMenu"
                  v-model="fulfillSettings.fulfillment_custom_delay.time"
                  full-width
                  :disabled="
                    !fulfillSettings.fulfillment_custom_delay.time_enabled
                  "
                  @click:minute="
                    $refs.timeMenu.save(
                      fulfillSettings.fulfillment_custom_delay.time
                    )
                  "
                />
              </v-menu>
            </v-col>
          </v-row>

          <hr>
        </div>

        <v-radio-group
          v-if="fulfillSettings.fulfill_shipments"
          :key="3"
          v-model="fulfillSettings.stallion_fulfillment"
          column
        >
          <template #label>
            <p class="font-weight-bold">
              Select the default tracking information you want to send to your
              stores (This can be overridden in settings for each carrier &
              store):
            </p>
          </template>
          <v-radio
            label="Stallion Express Tracking"
            :value="1"
            class="mt-4"
          >
            <template #label>
              <div>
                <p class="font-weight-bold">
                  Stallion Express Tracking
                </p>
                <p>Provides complete tracking through Stallion Express.</p>
              </div>
            </template>
          </v-radio>

          <v-radio
            label="Carrier Tracking"
            mt-12
            :value="0"
            class="mt-4"
          >
            <template #label>
              <div>
                <p class="font-weight-bold">
                  Carrier Tracking
                </p>
                <p>
                  Provides tracking information through the carrier when it
                  becomes available.
                </p>
              </div>
            </template>
          </v-radio>

          <v-alert
            v-if="changed"
            type="info"
            dense
          >
            These setting only affect newly created shipments. Existing
            shipments will maintain fulfillment settings at the time of
            creation.
          </v-alert>
        </v-radio-group>
      </transition-group>

      <v-row
        :justify="saveBtnJustify"
        class="mt-2"
      >
        <v-btn
          color="primary"
          :outlined="saveBtnOutlined"
          :loading="loading"
          :disabled="loading"
          type="submit"
        >
          {{ saveBtnText }}
        </v-btn>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import PERMISSIONS from '@/constants/client-permissions';
import _cloneDeep from 'lodash/cloneDeep';

export default {
  props: {
    settings: {
      type: Object,
      default: () => {}
    },
    userStoreId: {
      type: Number,
      default: null
    },
    saveBtnText: {
      type: String,
      default: 'Save'
    },
    saveBtnJustify: {
      type: String,
      default: 'end'
    },
    saveBtnOutlined: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      PERMISSIONS: PERMISSIONS,
      loading: false,
      times: [
        {
          value: null,
          text: 'When Postage Purchased'
        },
        {
          value: 1,
          text: 'When Postage Purchased Plus 1 Day'
        },
        {
          value: 2,
          text: 'When Postage Purchased Plus 2 Days'
        },
        {
          value: 3,
          text: 'When Postage Purchased Plus 3 Days'
        },
        {
          value: 4,
          text: 'When Postage Purchased Plus 4 Day'
        },
        {
          value: 5,
          text: 'When Postage Purchased Plus 5 Day'
        },
        // custom delay conditions
        {
          value: 0,
          text: 'When the first of these conditions is met'
        }
      ],
      changed: false,

      timeMenu: false,
      fulfillSettings: _cloneDeep(this.settings)
    };
  },

  watch: {
    settings: {
      handler: function () {
        this.fulfillSettings = _cloneDeep(this.settings);
      },
      deep: true,
      immediate: true
    }
  },

  mounted() {},

  methods: {
    async validateForm() {
      return await this.$validator.validate().then((result) => {
        return result ? true : false;
      });
    },
    async updateUserFulfillment() {
      if (!(await this.validateForm())) return;

      const payload = _cloneDeep(this.fulfillSettings);

      // remove custom delay if not available
      if (
        !this.fulfillSettings.fulfill_shipments ||
        this.fulfillSettings.fulfillment_delay != 0
      ) {
        payload.fulfillment_custom_delay = {
          hours_enabled: false,
          hours: null,
          time_enabled: false,
          time: null
        };
      } else {
        payload.fulfillment_custom_delay.hours = parseInt(
          payload.fulfillment_custom_delay.hours
        );
      }

      if (this.userStoreId) {
        this.updateStoreConfig(payload)
      } else {
        this.updateUserConfig(payload)
      }
    },

    updateUserConfig(payload) {
      this.loading = true;
      this.$http
        .post('/users/fulfillment-settings', payload)
        .then(() => {
          this.$notify({
            group: 'main',
            text: 'Fulfillment Settings has been updated.',
            duration: 3000,
            type: 'success'
          });
          this.changed = true;
          this.getUser();
        })
        .catch(() => {
          this.$notify({
            group: 'main',
            text: 'Something went wrong! Please try again.',
            duration: 3000,
            type: 'error'
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    updateStoreConfig(payload) {
      payload.user_store_id = this.userStoreId;
      this.loading = true;
      this.$http
        .post('/user-stores/fulfillment-settings', payload)
        .then(() => {
          this.successMessage('Fulfillment Settings has been updated.');
          this.changed = true;

          // @user-store-settings.vue
          this.$emit('updateFulfillmentSettings', payload);
        })
        .catch(() => {
          this.errorMessage('Something went wrong! Please try again.');
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
