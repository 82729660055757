<template>
  <StallionDialog
    v-model="dialog"
    :title="edit ? 'Edit Product' : 'Create Product'"
    :width="800"
  >
    <template #content>
      <v-container>
        <v-row dense>
          <v-col cols="12">
            <h2>Identifier</h2>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="product.sku"
              :disabled="edit"
              outlined
              dense
              label="SKU"
            />
          </v-col>

          <v-col
            cols="12"
            md="8"
          >
            <v-text-field
              v-model="product.name"
              outlined
              dense
              label="Name"
              required
            />
          </v-col>
          <v-col cols="4">
            <v-checkbox
              v-model="product.override_name"
              class="mt-1"
              :label="`Override Name`"
            />
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="product.value"
              outlined
              dense
              label="Value"
              type="number"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-autocomplete
              v-model="product.currency"
              outlined
              dense
              :items="$currencyAsFlatArray()"
              label="Currency"
            />
          </v-col>
          <v-col cols="4">
            <v-checkbox
              v-model="product.override_value"
              class="mt-1"
              :label="`Override Value`"
            />
          </v-col>

          <v-col cols="8">
            <v-text-field
              v-model="product.warehouse_location"
              outlined
              dense
              label="Warehouse Location"
            />
          </v-col>

          <v-col cols="4">
            <v-text-field
              v-model="product.upc"
              outlined
              dense
              label="UPC"
            />
          </v-col>
        </v-row>

        <v-row
          dense
          class="mt-3"
        >
          <v-col cols="12">
            <h2>Package Weight</h2>
            <p>
              This will overwrite the weight for the product in a shipment and
              results in the total weight being adjusted.
            </p>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="product.weight"
              outlined
              dense
              label="Weight"
              type="number"
            />
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="product.weight_unit"
              dense
              outlined
              :items="['lbs', 'kg', 'g', 'oz']"
              label="Weight Unit"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-checkbox
              v-model="product.override_weight"
              class="mt-1"
              :label="`Override Weight`"
            />
          </v-col>
        </v-row>

        <v-row
          dense
          class="mt-3"
        >
          <v-col cols="12">
            <h2>Package Dimensions</h2>
            <p>
              This only applies to shipments with quantity 1 of this product.
            </p>
          </v-col>

          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              v-model="product.length"
              outlined
              dense
              label="L"
              type="number"
            />
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              v-model="product.width"
              outlined
              dense
              label="W"
              type="number"
            />
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <v-text-field
              v-model="product.height"
              outlined
              dense
              label="H"
              type="number"
            />
          </v-col>

          <v-col
            cols="12"
            md="2"
          >
            <v-select
              v-model="product.size_unit"
              dense
              outlined
              :items="['in', 'cm']"
              label="Size Unit"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-checkbox
              v-model="product.override_dimensions"
              class="mt-1"
              :label="`Override Dimensions`"
            />
          </v-col>
        </v-row>

        <!-- <v-row dense>
            <v-col cols="12" md="3">
              <v-text-field
                outlined
                dense
                label="Length"
                v-model="product.length"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                outlined
                dense
                label="Width"
                v-model="product.width"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                outlined
                dense
                label="Height"
                v-model="product.height"
                type="number"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-select
                v-model="product.size_unit"
                label="Unit"
                :items="['cm', 'in']"
                dense
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12"> </v-col>
          </v-row> -->

        <v-row dense>
          <v-col cols="12">
            <h2>Customs Declaration</h2>
            <p>
              These settings will be used when providing customs information
              to carriers and will be displayed on the postage label.
            </p>
          </v-col>
         
          <v-col cols="12">
            <v-text-field
              v-model="product.customs_description"
              outlined
              dense
              label="Customs Description"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="product.hs_code"
              outlined
              dense
              label="HS Code"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-autocomplete
              v-model="product.country_of_origin"
              outlined
              dense
              :items="countries"
              label="Country of Origin"
              item-text="name"
              item-value="code"
            />
          </v-col>
        </v-row>
        <v-row
          dense
          class="mt-3"
        >
          <v-col cols="12">
            <h2>Tags</h2>
          </v-col>

          <v-autocomplete
            v-model="product_tags"
            outlined
            dense
            multiple
            :items="tags.tags"
            label="Order Tags"
            item-text="name"
            item-value="id"
          >
            <template #item="{ item, on, attrs }">
              <v-list-item
                :style="attrs.inputValue ? { backgroundColor: '#D3D3D3' } : ''"
                v-on="on"
              >
                <v-list-item-action>
                  <v-simple-checkbox
                    :value="attrs.inputValue"
                    color="primary"
                    :ripple="false"
                    v-on="on"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title
                    :style="{
                      backgroundColor: item.color_code,
                      padding: '10px',
                      borderRadius: '7px'
                    }"
                  >
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-row>
      </v-container>
    </template>

    <template #actions>
      <v-spacer />
      <v-btn
        v-if="edit"
        color="primary"
        @click="updateProduct()"
      >
        Update
      </v-btn>
      <v-btn
        v-else
        color="primary"
        @click="saveProduct()"
      >
        Save
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
import products from '../../../shared/products';
import CONSTANTS from '@/constants/index';
import { mapState } from 'vuex';

export default {
  components: {},
  data() {
    return {
      product: products.defaultProduct(),
      edit: false,
      dialog: false,
      countries: CONSTANTS.COUNTRIES,
    };
  },
  computed: {
    ...mapState(['tags']),
    product_tags: {
      get() {
        if (this.product.tag_ids) {
          return this.tags.tags.map((tag) => {
            if (this.product.tag_ids.includes(tag.id)) {
              return tag;
            }
          });
        }
        return [];
      },
      set(value) {
        this.product.tag_ids = value.filter((v) => typeof v !== 'undefined');
      }
    }
  },
  methods: {
    startNew() {
      this.dialog = true;
      this.product = Object.assign({}, products.defaultProduct());
      this.edit = false;
    },
    startEdit(product) {
      this.dialog = true;
      this.product = Object.assign(products.defaultProduct(), product);
      this.edit = true;
    },
    saveProduct() {
      this.$http
        .post(`/products`, this.product)
        .then(() => {
          this.dialog = false;
          this.$emit('add', this.product);
        })
        .catch((err) => {
          if (
            err.response.status === 422 &&
            typeof err.response.data.errors !== 'undefined'
          ) {
            const errors = err.response.data.errors;
            let message = '';

            for (var field in errors) {
              message += `${errors[field][0]}\n`;
            }

            this.errorMessage(message);
          } else if (err.response.status === 403) {
            this.errorMessage('This action is unauthorized.');
          }
        });
    },
    updateProduct() {
      this.$http
        .put(`/products/${this.product.id}`, this.product)
        .then(() => {
          this.dialog = false;
          this.$emit('update', this.product);
        })
        .catch((err) => {
          if (
            err.response.status === 422 &&
            typeof err.response.data.errors !== 'undefined'
          ) {
            const errors = err.response.data.errors;
            let message = '';

            for (var field in errors) {
              message += `${errors[field][0]}\n`;
            }

            this.errorMessage(message);
          } else if (err.response.status === 403) {
            this.errorMessage('This action is unauthorized.');
          }
        });
    },
    removFilter(index, criterion) {
      if (criterion.id === null) {
        this.product.filter_criteria.splice(index, 1);
      } else {
        this.$set(this.product.filter_criteria[index], 'delete', true);
      }
    }
  }
};
</script>
