<template>
  <FullPageLayout title="Analytics">
    <template #default>
      <v-layout wrap>
        <v-flex xs12>
          <filter-date-range />
        </v-flex>
        <v-flex xs12>
          <v-divider />
        </v-flex>
      </v-layout>

      <v-card outlined>
        <v-tabs
          v-model="tab"
          show-arrows
          icons-and-text
        >
          <v-tab
            v-for="option in options"
            :key="option.title"
          >
            {{ option.title }}<v-icon>{{ option.icon }}</v-icon>
          </v-tab>
        </v-tabs>
      </v-card>

      <v-tabs-items
        v-model="tab"
        class="mt-3"
      >
        <v-tab-item
          v-for="option in options"
          :key="option.title"
        >
          <v-layout wrap>
            <v-flex xs12>
              <analytics-overview
                :title="`Summary`"
                :loading="option.loading"
                :data="option.totals"
                :auto-format="option.autoFormat"
              />
            </v-flex>
            <v-flex
              v-for="(chart, index) in option.charts"
              :key="index"
              xs12
            >
              <by-type-table
                v-if="chart.type === 'by-type-table' && chart.data.length > 0"
                :title="chart.title"
                :loading="option.loading"
                :data="chart.data"
              />

              <by-type-chart
                v-if="chart.type === 'by-type-chart' && chart.data.length > 0"
                :title="chart.title"
                :loading="option.loading"
                :data="chart.data"
                :data-column="chart.dataColumn"
              />

              <by-date-chart
                v-if="chart.type === 'by-date-chart' && chart.data.length > 0"
                :title="chart.title"
                :loading="option.loading"
                :data="chart.data"
                :data-columns="chart.dataColumns"
              />
            </v-flex>
          </v-layout>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </FullPageLayout>
</template>

<script>
import FilterDateRange from '@/components/analytics/filter-date-range.vue';

import ByTypeTable from '@/components/analytics/shared/ByTypeTable.vue';
import ByTypeChart from '@/components/analytics/shared/ByTypeChart.vue';
import ByDateChart from '@/components/analytics/shared/ByDateChart.vue';
import AnalyticsOverview from '@/components/analytics/shared/AnalyticsOverview.vue';

export default {
  components: {
    FilterDateRange,
    ByTypeChart,
    ByTypeTable,
    ByDateChart,
    AnalyticsOverview
  },
  data() {
    return {
      tab: null
    };
  },
  computed: {
    options() {
      return [
        {
          title: 'Shipments',
          icon: 'mdi-package-variant-closed',
          totals: this.$store.state.analytics.shipments.totals,
          loading: this.$store.state.analytics.shipments.loading,
          autoFormat: true,
          charts: [
            {
              title: 'By Type',
              type: 'by-type-table',
              data: this.$store.state.analytics.shipments.byType
            },
            {
              title: 'Quantity By Type',
              type: 'by-type-chart',
              data: this.$store.state.analytics.shipments.byType,
              dataColumn: 'quantity'
            },
            {
              title: 'Quantity By Date',
              type: 'by-date-chart',
              data: this.$store.state.analytics.shipments.byDate,
              dataColumns: ['quantity']
            },
            {
              title: 'Margin By Type',
              type: 'by-type-chart',
              data: this.$store.state.analytics.shipments.byType,
              dataColumn: 'margin'
            },
            {
              title: 'Margin By Date',
              type: 'by-date-chart',
              data: this.$store.state.analytics.shipments.byDate,
              dataColumns: ['margin', 'conversion_margin', 'conversion_fee']
            }
          ]
        },
        {
          title: 'Protection',
          icon: 'mdi-marker-check',
          loading: this.$store.state.analytics.insurance.loading,
          totals: this.$store.state.analytics.insurance.totals,
          autoFormat: true,
          charts: [
            {
              title: 'By Type',
              type: 'by-type-table',
              data: this.$store.state.analytics.insurance.byType
            },
            {
              title: 'Quantity By Type',
              type: 'by-type-chart',
              data: this.$store.state.analytics.insurance.byType,
              dataColumn: 'quantity'
            },
            {
              title: 'Quantity By Date',
              type: 'by-date-chart',
              data: this.$store.state.analytics.insurance.byDate,
              dataColumns: ['quantity']
            },
            {
              title: 'Cost By Date',
              type: 'by-date-chart',
              data: this.$store.state.analytics.insurance.byDate,
              dataColumns: ['insurance_cost']
            }
          ]
        },
        {
          title: 'Claims',
          icon: ' mdi-chat-alert',
          loading: this.$store.state.analytics.claims.loading,
          totals: this.$store.state.analytics.claims.totals,
          autoFormat: true,
          charts: [
            {
              title: 'By Type',
              type: 'by-type-table',
              data: this.$store.state.analytics.claims.byType
            },
            {
              title: 'Quantity By Type',
              type: 'by-type-chart',
              data: this.$store.state.analytics.claims.byType,
              dataColumn: 'quantity'
            },
            {
              title: 'Payout By Type',
              type: 'by-type-chart',
              data: this.$store.state.analytics.claims.byType,
              dataColumn: 'payout'
            },
            {
              title: 'Payout By Date',
              type: 'by-date-chart',
              data: this.$store.state.analytics.claims.byDate,
              dataColumns: ['payout']
            }
            // {
            //   title: 'By Date',
            //   type: 'by-date-chart',
            //   data: this.$store.state.analytics.insurance.byDate,
            //   dataColumns: ['cost']
            // }
          ]
        },
        {
          title: 'Voids',
          icon: 'mdi-select-off',
          loading: this.$store.state.analytics.voids.loading,
          totals: this.$store.state.analytics.voids.totals,
          autoFormat: true,
          charts: [
            {
              title: 'By Type',
              type: 'by-type-table',
              data: this.$store.state.analytics.voids.byType
            },
            {
              title: 'Quantity By Type',
              type: 'by-type-chart',
              data: this.$store.state.analytics.voids.byType,
              dataColumn: 'quantity'
            },
            {
              title: 'Postage Fee By Date',
              type: 'by-date-chart',
              data: this.$store.state.analytics.voids.byDate,
              dataColumns: ['postage_fee']
            },
            {
              title: 'Quantity By Date',
              type: 'by-date-chart',
              data: this.$store.state.analytics.voids.byDate,
              dataColumns: ['quantity']
            }
          ]
        },

        {
          title: 'Expired',
          icon: 'mdi-clock-alert-outline',
          loading: this.$store.state.analytics.expired.loading,
          totals: this.$store.state.analytics.expired.totals,
          autoFormat: true,
          charts: [
            {
              title: 'By Type',
              type: 'by-type-table',
              data: this.$store.state.analytics.expired.byType
            },
            {
              title: 'Quantity By Type',
              type: 'by-type-chart',
              data: this.$store.state.analytics.expired.byType,
              dataColumn: 'quantity'
            },
            {
              title: 'Total Paid By Date',
              type: 'by-date-chart',
              data: this.$store.state.analytics.expired.byDate,
              dataColumns: ['total_amt_paid']
            },
            {
              title: 'Quantity By Date',
              type: 'by-date-chart',
              data: this.$store.state.analytics.expired.byDate,
              dataColumns: ['quantity']
            }
          ]
        },

        {
          title: 'Returns',
          icon: 'mdi-keyboard-backspace',
          loading: this.$store.state.analytics.returns.loading,
          totals: this.$store.state.analytics.returns.totals,
          autoFormat: true,
          charts: [
            {
              title: 'By Type',
              type: 'by-type-table',
              data: this.$store.state.analytics.returns.byType
            },
            {
              title: 'Quantity By Type',
              type: 'by-type-chart',
              data: this.$store.state.analytics.returns.byType,
              dataColumn: 'quantity'
            },
            {
              title: 'Quantity By Date',
              type: 'by-date-chart',
              data: this.$store.state.analytics.returns.byDate,
              dataColumns: ['quantity']
            }
          ]
        },
        {
          title: 'Supplies',
          icon: 'mdi-email-open-outline',
          loading: this.$store.state.analytics.supplies.loading,
          totals: this.$store.state.analytics.supplies.totals,
          autoFormat: true,
          charts: [
            {
              title: 'By Type',
              type: 'by-type-table',
              data: this.$store.state.analytics.supplies.byType
            },
            {
              title: 'Quantity By Type',
              type: 'by-type-chart',
              data: this.$store.state.analytics.supplies.byType,
              dataColumn: 'quantity'
            },
            {
              title: 'Subtotal By Date',
              type: 'by-date-chart',
              data: this.$store.state.analytics.supplies.byDate,
              dataColumns: ['subtotal']
            }
          ]
        },
        {
          title: 'Charges',
          icon: 'mdi-cash-usd-outline',
          loading: this.$store.state.analytics.charges.loading,
          totals: this.$store.state.analytics.charges.totals,
          autoFormat: true,
          charts: [
            {
              title: 'By Type',
              type: 'by-type-table',
              data: this.$store.state.analytics.charges.byType
            },
            {
              title: 'Quantity By Type',
              type: 'by-type-chart',
              data: this.$store.state.analytics.charges.byType,
              dataColumn: 'quantity'
            },
            {
              title: 'Quantity By Date',
              type: 'by-date-chart',
              data: this.$store.state.analytics.charges.byDate,
              dataColumns: ['quantity']
            },
            {
              title: 'Amount By Date',
              type: 'by-date-chart',
              data: this.$store.state.analytics.charges.byDate,
              dataColumns: ['amount']
            }
          ]
        },
        {
          title: 'Pickups',
          icon: 'mdi-truck',
          loading: this.$store.state.analytics.pickups.loading,
          totals: this.$store.state.analytics.pickups.totals,
          autoFormat: true,
          charts: [
            {
              title: 'Cost By Date',
              type: 'by-date-chart',
              data: this.$store.state.analytics.pickups.byDate,
              dataColumns: ['cost']
            },
            {
              title: 'Quantity By Date',
              type: 'by-date-chart',
              data: this.$store.state.analytics.pickups.byDate,
              dataColumns: ['quantity']
            }
          ]
        },

        // {
        //   title: 'Credits',
        //   icon: 'mdi-cash-usd-outline',
        //   loading: this.$store.state.analytics.credits.loading,
        //   totals: this.$store.state.analytics.credits.totals,
        //   byDate: this.$store.state.analytics.credits.byDate,
        //   byType: this.$store.state.analytics.credits.byType
        // },

        {
          title: 'Users',
          icon: 'mdi-account-multiple',
          loading: this.$store.state.analytics.users.loading,
          totals: this.$store.state.analytics.users.totals,
          autoFormat: false,
          charts: [
            {
              title: 'Top Users By Quantity',
              type: 'by-type-table',
              data: this.$store.state.analytics.users.byType
            },
            {
              title: 'Top Users By Quantity',
              type: 'by-type-chart',
              data: this.$store.state.analytics.users.byType,
              dataColumn: 'quantity'
            },
            {
              title: 'New Users By Date',
              type: 'by-date-chart',
              data: this.$store.state.analytics.users.byDate,
              dataColumns: ['quantity']
            }
          ]
        }
      ];
    }
  },
  created() {
    this.$store.dispatch('analytics/getData');
  }
};
</script>
