<template>
  <FullPageLayout title="Pricing Users">
    <template #default>
      <pricing-users-table />
    </template>
  </FullPageLayout>
</template>

<script>
import PricingUsersTable from './PricingUsersTable.vue';
export default {
  components: {
    PricingUsersTable
  },
  data() {
    return {
     
    };
  },
  methods: {
   
  }
};
</script>
