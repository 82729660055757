<template>
  <FullPageLayout 
    title="Drop-off Anayltics"
    desc="View analytics for shipments scanned at your location."
  >
    <template #default>
      <v-row>
        <v-col cols="12">
          <v-form @submit.prevent>
            <v-row align-center>
              <v-col
                cols="12"
                text-center
              >
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template #activator="{ on }">
                    <v-text-field
                      outlined
                      dense
                      :value="computedDateFormattedMomentjs"
                      label="Month"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="date"
                    type="month"
                    min="2018-08"
                    :max="endDate"
                    @input="menu = false"
                  />
                </v-menu>
              </v-col>
            </v-row>
          </v-form>
        </v-col>

        <v-col
          v-if="by_date.length > 0"
          cols="12"
        >
          <v-card outlined>
            <v-card-title>
              <v-row>
                <v-col>
                  <h2>Earnings Summary</h2>
                </v-col>
                <v-col cols="auto">
                  <strong>Total:</strong> {{ total | currency }}
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :headers="headers2"
                :items="by_type"
                :footer-props="{
                  'items-per-page-options': [50, 100, 200, 500]
                }"
              />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          v-if="by_date.length > 0"
          cols="12"
        >
          <v-card outlined>
            <v-card-title>
              <h2>Earnings By Day</h2>
            </v-card-title>
            <v-card-text>
              <line-chart
                :height="150"
                :chart-data="salesByDay"
              />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          v-if="by_date.length > 0"
          cols="12"
        >
          <v-card outlined>
            <v-card-title>
              <h2>Scans By Day</h2>
            </v-card-title>
            <v-card-text>
              <line-chart
                :height="150"
                :chart-data="scansByDay"
              />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          v-if="by_date.length > 0"
          cols="12"
        >
          <v-card outlined>
            <v-card-title>
              <h2>Scans By Type</h2>
            </v-card-title>
            <v-card-text>
              <pie-chart
                :height="150"
                :chart-data="scansByType"
                :options="pieOptions"
              />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          v-if="by_date.length == 0 && !loading_a"
          cols="12"
        >
          <v-card outlined>
            <v-card-text>
              <v-card-title>
                <v-row
                  justify-center
                  pt-4
                  pb-4
                >
                  <h2>No scans in {{ date }}</h2>
                </v-row>
              </v-card-title>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </FullPageLayout>
</template>

<script>
import moment from 'moment';

export default {
  metaInfo() {
    return {
      title: `Drop Off Analytics | Stallion Express`
    }
  },
  data() {
    return {
      loading_a: false,
      shipments: [],
      warnings: [],
      activeBtn: 1,
      showNav: true,
      barcodes: [],
      box: 0,
      selected: [],

      loading: false,
      errors: [],
      options: {
        sortBy: ['created_at'],
        sortDesc: [true]
      },
      salesByDay: [],
      scansByDay: [],
      scansByType: [],
      pieOptions: {
        title: {
          display: true,
          text: 'Scans By Type',
          position: 'top'
        },
        rotation: -0.7 * Math.PI
      },
      by_type: [],
      by_date: [],
      headers2: [
        {
          text: 'Type',
          value: 'type'
        },
        {
          text: 'Quantity',
          value: 'quantity'
        },
        {
          text: 'Commission',
          value: 'amount'
        }
      ],
      date: moment()
        // .subtract(1, "months")
        .format('YYYY-MM'),
      endDate: moment()
        // .subtract(1, "months")
        .format('YYYY-MM'),
      menu: false
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.date ? moment(this.date).format('MMMM YYYY') : '';
    },
    total() {
      if (this.by_type.length > 0)
        return this.by_type.reduce((a, b) => a + parseFloat(b.amount) || 0, 0);
      else return 0;
    }
  },
  watch: {
    date: function() {
      this.getMonthlySummary();
    }
  },
  mounted() {
    console.log('scan-page');
    this.getMonthlySummary();
  },

  methods: {
    getRandomColor() {
      var letters = '0123456789ABCDEF';
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    getMonthlySummary() {
      this.loading_a = true;
      this.$http
        .post(`/drop-off/analytics`, { date: this.date })
        .then(response => response.data)
        .then(response => {
          this.by_type = response.by_type;
          this.by_date = response.by_date;

          this.salesByDay = {
            labels: this.by_date.map(a => a.date),
            datasets: [
              {
                label: 'Commission',
                borderColor: '#9ccc65',
                data: this.by_date.map(a => a.amount)
              }
            ]
          };
          this.scansByDay = {
            labels: this.by_date.map(a => a.date),
            datasets: [
              {
                label: 'Quantity',
                borderColor: '#ff7043',
                data: this.by_date.map(a => a.quantity)
              }
            ]
          };

          this.scansByType = {
            labels: this.by_type.map(a => a.type),
            datasets: [
              {
                backgroundColor: this.by_type.map(() => this.getRandomColor()),
                data: this.by_type.map(a => a.quantity)
              }
            ]
          };
        })
        .finally(() => {
          this.loading_a = false;
        });
    }
  }
};
</script>
