<template>
  <v-row justify="center">
    <v-col
      cols="12"
      sm="8"
      md="6"
      mb-12
      mt-4
    >
      <v-card class="elevation-5 mb-3">
        <v-col
          col-4
          text-left
          style="padding-bottom: unset"
        >
          <v-btn
            large
            text
            style="
              padding: unset;
              justify-content: start;
              align-items: start;
              align-content: start;
              height: unset;
              width: unset;
              margin: 0;
              text-align: center;
              min-width: unset;
              margin: 0 5px 0 0;
              padding: 0.5rem;
            "
            @click="setStep('package_usps')"
          >
            <v-icon dark>
              mdi-arrow-left
            </v-icon> Back
          </v-btn>
        </v-col>
        <v-card-text style="margin: unset; padding-top: unset">
          <v-col
            cols="12"
            class="text-center"
            mb-3
            style="padding-top: unset"
          >
            <h2 class="font-weight-bold mt-4">
              Postage
            </h2>
          </v-col>
          <v-col
            v-if="shipment.needs_postage"
            cols="12"
          >
            <p>
              Estimates from various carriers based on the information you have
              inputted. Charge backs may apply as the carriers reassess the
              shipments.
            </p>
          </v-col>
          <v-col
            v-if="shipment.needs_postage"
            cols="12"
          >
            <p color="text-muted">
              Carriers reassess all package weight and dimensions.
              <a
                href="https://help.stallionexpress.ca/portal/en/kb/articles/postage-adjustments-and-other-carrier-fees-21-6-2024#Postage_Adjustments"
                target="_blank"
              >
                What are postage adjustments?
              </a>
            </p>
          </v-col>
          <v-col cols="12">
            <h2>Rates</h2>
          </v-col>

          <!-- NEEDS POSTAGE: FALSE -->
          <v-col
            v-if="shipment.needs_postage === false"
            cols="12"
          >
            <v-form>
              <v-container pa-0>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-select
                      v-model="shipment.postage_type_id"
                      v-validate="'required'"
                      :error-messages="errs.collect('postage_type')"
                      data-vv-name="postage_type"
                      :items="rates"
                      label="Postage Type"
                      item-text="postage_type"
                      item-value="postage_type_id"
                      @change="setFees"
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="shipment.tracking_code"
                      :error-messages="errs.collect('tracking_code')"
                      label="Tracking"
                      data-vv-name="tracking_code"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-col>

          <!-- NEEDS POSTAGE: TRUE -->
          <v-col
            v-else-if="rates && rates.length > 0"
            cols="12"
          >
            <rates-list
              :postage-rates="rates"
              :shipment="shipment"
              :update-rate-handler="updateShipmentPostage"
            />
          </v-col>
          <!-- NO RATES -->
          <v-col
            v-else
            cols="12"
          >
            <p>
              Sorry, no rates available. Please try refreshing the rates or
              check your recipient and package details.
            </p>
          </v-col>

          <!-- ADD ONS -->
          <v-col
            v-if="shipment.needs_postage && !hide_protection"
            cols="12"
          >
            <v-row>
              <v-col
                v-if="!['CA', 'US'].includes(shipment.country_code)"
                cols="12"
                class="mb-md-5"
              >
                <p>
                  There are no returns service for PostNL and APC. Returned
                  shipments are set to be disposed by the carrier if
                  undeliverable.
                </p>
              </v-col>

              <!-- INSURANCE -->
              <v-col
                v-if="shipment.needs_postage"
                cols="12"
              >
                <h2>Signature Confirmation</h2>
                <v-checkbox
                  v-model="shipment.signature_confirmation"
                  dense
                  label="Yes, I want signature confirmation"
                  @change="getRates()"
                />
              </v-col>
              <v-col cols="12">
                <h2>Stallion Protection</h2>
                <v-checkbox
                  v-model="shipment.insured"
                  dense
                  :hint="'test hint'"
                  @change="getRates()"
                >
                  <template #label>
                    <div>
                      {{ `Yes, I want Stallion Protection` }}
                    </div>
                  </template>
                </v-checkbox>

                <p>
                  Covers lost or damaged items in transit and gives you peace of
                  mind.<br>
                  <a
                    href="https://help.stallionexpress.ca/portal/en/kb/articles/stallion-protection-terms-conditions"
                    target="_blank"
                  >Read more.</a>
                </p>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            v-if="rate"
            cols="12"
          >
            <v-alert
              v-if="rate.insured_value > 0"
              dense
              outlined
              type="info"
            >
              <strong>Protected Value:</strong>
              {{ rate.insured_value | currency }} CAD
            </v-alert>
            <v-alert
              v-else
              dense
              outlined
              type="warning"
            >
              No Stallion Protection
            </v-alert>
          </v-col>

          <!-- SUMMARY -->
          <v-col
            v-if="rate"
            cols="12"
          >
            <h2>Summary</h2>
            <v-simple-table class="total-table">
              <template #default>
                <tbody>
                  <tr v-if="rate.base_rate">
                    <td>Rate</td>
                    <td>
                      {{ (rate.base_rate + rate.handling_fee) | currency }}
                    </td>
                  </tr>
                

                  <tr
                    v-for="(addon, index) in rate.add_ons"
                    :key="index"
                  >
                    <td>{{ addon.name }}</td>
                    <td>{{ addon.cost | currency }}</td>
                  </tr>

                  <tr v-if="rate.tax">
                    <td>Tax</td>
                    <td>{{ rate.tax | currency }}</td>
                  </tr>

                  <tr v-if="rate.duty_tax || rate.duty">
                    <td>Duty & Tax</td>
                    <td>{{ (parseFloat(rate.duty_tax) + parseFloat(rate.duty)) | currency }}</td>
                  </tr>

                  <tr>
                    <td>
                      <strong>Total</strong>
                    </td>
                    <td v-if="rate.total">
                      {{ rate.total | currency }}
                    </td>
                    <td v-else>
                      -
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>

         

          <!-- ACTIONS -->
          <v-row
            align-center
            class="mt-4 mb-4"
          >
            <v-col
              cols="12"
              class="text-center"
            >
              <v-btn
                large
                depressed
                color="primary"
                :disabled="shipment.postage_type_id === null"
                dusk="sf_postage_next"
                @click="saveRate()"
              >
                Next
                <v-icon dark>
                  mdi-arrow-right
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import RatesList from '../../shipments/rates-list.vue';
export default {
  components: {
    RatesList
  },
  data() {
    return {
      searchInput1: '',
      errors: {},

      rates: [],

      // rate: null,

      postage_types: {},
      tier: '',
      valid: false,
      hide_protection: false
    };
  },
  computed: {
    rate: {
      get() {
        return this.$store.state.singleFlow.rate;
      },
      set(value) {
        this.$store.state.singleFlow.rate = value;
      }
    },
    shipment: {
      get() {
        return this.$store.state.singleFlow.shipment;
      },
      set(value) {
        this.$store.state.singleFlow.shipment = value;
      }
    },
    step: {
      get() {
        return this.$store.state.singleFlow.step;
      },
      set(value) {
        this.$store.state.singleFlow.step = value;
      }
    },
    processing: {
      get() {
        return this.$store.state.singleFlow.processing;
      },
      set(value) {
        this.$store.state.singleFlow.processing = value;
      }
    }
  },
  watch: {
    step: {
      immediate: true,
      handler(newValue, oldValue) {
        if (
          (newValue == 5 && oldValue == 4) ||
          (newValue >= 5 && (oldValue == undefined || oldValue == 1))
        ) {
          if (!this.shipment.needs_postage) {
            this.shipment.insured = false;
          } else {
            this.shipment.insured = this.isUserSettingEnabled(
              'stallion_protection_by_default'
            ); // mixin at app.js
          }

          if (
            !this.shipment.country_code == 'US' ||
            !this.shipment.needs_postage
          ) {
            this.shipment.signature_confirmation = false;
          }

          this.getRates();
        }
      }
    },

    'shipment.postage_type_id': {
      immediate: true,
      handler() {
        // if (
        //   ([1, 27].includes(newValue) &&
        //     [17, 18].includes(this.shipment.package_type_id)) ||
        //   [40, 30].includes(newValue)
        // ) {
        //   this.shipment.insured = false;
        //   this.shipment.insured_value = null;
        //   this.hide_protection = true;
        // }
        // else{
        //   this.hide_protection = false;
        // }

        this.setFees();
      }
    }
  },
  mounted() {
    // window.addEventListener('keydown', (e) => {
    //   if (this.shipment.postage_type_id !== null && this.processing == 0 && this.step == 5 && e.key == 'Enter') {
    //     this.saveRate();
    //   }
    // });
  },
  methods: {
    setStep() {
      this.$store.commit('singleFlow/previous');
    },
    saveRate() {
      this.$http
        .put('/shipments/' + this.shipment.id, this.shipment)
        .then((res) => {
          this.shipment = res.data;
          // console.log(this.shipment);
          //check if needs postage is set to false and tracking code is null
          if (
            this.shipment.needs_postage == false &&
            (this.shipment.tracking_code == '' ||
              this.shipment.tracking_code == null)
          ) {
            this.shipment.missingTrackingNumber = true;
          } else {
            this.shipment.missingTrackingNumber = false;
          }

          this.$store.commit('singleFlow/next');
        });
    },
    getRates() {
      this.processing++;

      this.rates = []; // clear rates first to reset the UI
      this.$http
        .post('/shipments/rates', this.shipment)
        .then((res) => {
          if (res.data.errors) {
            this.errorMessage(res.data.errors);
          } else {
            this.rates = res.data.rates;
            this.rates.forEach(function (rate) {
              rate.postage_type_id = parseInt(rate.postage_type_id);
            });

            this.$nextTick(() => {
              var result = this.rates.map((a) => a.postage_type_id);
              if (!result.includes(this.shipment.postage_type_id)) {
                this.shipment.postage_type_id = null;
              }

              this.setFees();
              this.valid = true;
            })
          }
        })
        .catch((err) => {
          console.warn(err);
        })
        .finally(() => {
          this.processing--;
        });
    },
    setFees() {
      var filtered = this.rates.filter(
        (m) => m.postage_type_id === this.shipment.postage_type_id
      );

      if (filtered.length === 1) {
        this.rate = filtered[0];
      } else {
        this.rate = null;
      }
    },
    updateShipmentPostage(rate){
      this.shipment.postage_type_id =
          rate?.postage_type_id || null
      this.shipment.rate_id = rate?.id || null
    }
  }
};
</script>

<style scoped>
.total-table td:nth-child(2) {
  text-align: right;
}

.total-table tr:last-child {
  background-color: #eee;
}
</style>
