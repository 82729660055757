<template>
  <v-row>
    <!-- <v-col cols="12">
        <p class="mb-2">Change your settings for creating new shipments.</p>
      </v-col> -->
    <v-col
      cols="12"
      md="12"
    >
      <user-settings-option
        v-for="(list, index) in newSettings.batch_settings"
        :key="index"
        :list="list"
      />
    </v-col>
  
    <v-col cols="12">
      <v-btn
        color="primary"
        :loading="processing"
        @click="submit"
      >
        Update
      </v-btn>
    </v-col>
  </v-row>
</template>
  
  <script>
  import { handleLaravelValidationError } from '@/helpers/helper';
  
  export default {
    provide() {
      return {
        $validator: this.$validator
      };
    },
    data() {
      return {
        newSettings: {
          batch_settings: _.cloneDeep(
            this.$auth.user.user_config_settings.batch_settings
          )
        },
        processing: false
      };
    },
    mounted() {},
    methods: {
      async submit() {
        // $validateAll from mixin.js
        if (!(await this.$validateAll())) return;
  
        this.processing = true;
        this.$http
          .post('/users/updateUserConfigSettings', this.newSettings)
          .then((res) => {
            if (Object.prototype.hasOwnProperty.call(res.data, 'message')) {
              if (res.data.success) {
                this.successMessage('Your account changes have been saved');
                this.$auth.user.user_config_settings.batch_settings = _.cloneDeep(this.newSettings.batch_settings)
              } else {
                this.errorMessage('Your account changes have not been saved');
              }
            }
          })
          .catch((err) => {
            handleLaravelValidationError(err);
          })
          .finally(() => {
            this.processing = false;
          });
      }
    }
  };
  </script>
  