<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-btn
          color="primary"
          dark
          @click="$refs.regionDialog.startNew()"
        >
          Create Region
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table
          class="table-striped"
          :headers="headers"
          :items="items"
          :loading="loading"
          :items-per-page="10"
        >
          <template #item.created_at="{ item }">
            {{ item.created_at | date_pretty }}
          </template>
          <template #item.actions="{ item }">
            <!-- <v-btn small color="red" text @click="deleteRegion(item.id)"
                >Delete</v-btn
              > -->
            <v-btn
              small
              color="primary"
              text
              @click="$refs.regionDialog.startEdit(item)"
            >
              Edit
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <region-dialog
      ref="regionDialog"
      @update="updateTable()"
    />
  </div>
</template>
<script>
import RegionDialog from './RegionDialog.vue';
export default {
  components: {
    RegionDialog
  },
  data() {
    return {
      region: {},
      show_edit: false,
      loading: false,
      items: [],

      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Name', value: 'name' },
        { text: 'Timezone', value: 'timezone' },
        { text: 'Created At', value: 'created_at' },
        { text: 'Actions', value: 'actions', align: 'right', sortable: false }
      ]
    };
  },
  watch: {
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    }
  },

  mounted() {
    this.updateTable();
  },
  methods: {
    updateTable() {
      this.totalItems = 0;
      this.items = [];

      this.$http
        .request(`/admin/regions`, {
          method: 'get'
        })
        .then((response) => response.data)
        .then((response) => {
          this.items = response;
        });
    },
    async deleteRegion(id) {
      const isConfirm = await swal({
        title: 'Confirm Delete',
        text: 'Do you really want to delete this region?',
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      });

      if (isConfirm) {
        try {
          await this.$store.dispatch('regions/deleteRegion', id);
          this.updateTable();
        } catch (e) {
          console.log(e);
        }
      }
    },
    showUpdate(region) {
      this.show_edit = true;
      this.region = region;
    },
    showCreate() {
      this.show_edit = true;
      this.region = null;
    }
  }
};
</script>
