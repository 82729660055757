<template>
  <StallionDialog
    v-model="dialog"
    persistent
    :width="800"
    :title="edit ? 'Edit Location' : 'Create Location'"
  >
    <template #content>
      <v-container>
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="location.name"
              v-validate="'required'"
              data-vv-name="name"
              :error-messages="errs.collect('name')"
              outlined
              dense
              label="Name"
              required
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <h2>Address</h2>
          </v-col>

          <v-col cols="6">
            <!-- Address1 -->

            <v-text-field
              v-model="location.address1"
              v-validate="'required|max:50'"
              outlined
              dense
              :error-messages="errs.collect('address1')"
              label="Address1"
              data-vv-name="address1"
            />
          </v-col>

          <!-- Address2 -->
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="location.address2"
              v-validate
              outlined
              dense
              :error-messages="errs.collect('address2')"
              label="Address2"
              data-vv-name="address2"
            />
          </v-col>

          <!-- City -->
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="location.city"
              v-validate="'required'"
              outlined
              dense
              :error-messages="errs.collect('city')"
              label="City"
              data-vv-name="city"
            />
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-autocomplete
              ref="country"
              v-model="location.country_code"
              v-validate="{
                required: true,
                included: ['CA', 'US']
              }"
              outlined
              dense
              :error-messages="errs.collect('country_code')"
              data-vv-name="country_code"
              :items="countries"
              label="Country"
              item-text="name"
              item-value="code"
            />
          </v-col>

          <!-- Province/State -->
          <v-col
            cols="12"
            md="6"
          >
            <v-autocomplete
              v-model="location.province_code"
              v-validate="'required'"
              outlined
              dense
              :error-messages="errs.collect('province_code')"
              data-vv-name="province_code"
              :items="provinces"
              label="Province"
              item-text="name"
              item-value="code"
            />
          </v-col>

          <!-- Postal Code -->
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="location.postal_code"
              v-validate="{
                required: true,
                regex: regex
              }"
              outlined
              dense
              :error-messages="errs.collect('postal_code')"
              label="Postal Code"
              data-vv-name="postal_code"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h2>Status</h2>
          </v-col>
          <v-col cols="6">
            <v-checkbox
              v-model="location.active"
              label="Active"
            />
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="location.supplies_pickup_type"
              :items="['None', 'All', 'Business Only']"
              label="Supplies Pickup Type"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h2>Advanced</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-checkbox
              ref="company_location"
              v-model="location.company_location"
              label="Company Location"
            />
          </v-col>
          <v-col
            v-show="!location.company_location"
            cols="6"
            class="mt-md-2"
          >
            <v-text-field
              v-model="location.user_id"
              v-validate="'required_if:company_location,0,false'"
              outlined
              dense
              :error-messages="errs.collect('user_id')"
              label="User ID"
              data-vv-name="user_id"
            />
          </v-col>

          <v-col cols="6">
            <v-checkbox
              ref="is_pickup_location"
              v-model="location.is_pickup_location"
              label="Pickup Location"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-checkbox
              v-model="location.inventory_location"
              label="Inventory Location"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-show="location.company_location"
            cols="6"
          >
            <v-checkbox
              v-model="location.can_closeout"
              label="Closeout Enabled"
            />
          </v-col>
          <v-col
            v-show="location.company_location && !location.can_closeout"
            cols="6"
          >
            <v-select
              v-model="location.transfer_location_id"
              :error-messages="errs.collect('transfer_location')"
              data-vv-name="transfer_location"
              outlined
              dense
              :items="company_locations"
              item-text="name"
              item-value="id"
              label="Transfer Location"
              clearable
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              v-model="location.sort_method"
              :error-messages="errs.collect('sort_method')"
              data-vv-name="sort_method"
              label="Sort Method"
              :items="['Manual', 'NPI']"
              dense
              outlined
            />
          </v-col>
          <v-col
            v-show="location.company_location"
            cols="6"
          >
            <v-text-field
              v-model="location.pallet_identifier"
              v-validate="'required_if:company_location,1|max:3|min:3'"
              outlined
              dense
              :error-messages="errs.collect('pallet_identifier')"
              label="Pallet ID"
              data-vv-name="pallet_identifier"
            />
          </v-col>

          <v-col
           
            cols="6"
          >
            <v-select
              v-model="location.region_id"
              v-validate="'required_if:company_location,1'"
              :error-messages="errs.collect('region')"
              data-vv-name="region"
              outlined
              dense
              :items="regions"
              item-text="name"
              item-value="id"
              label="Region"
            />
          </v-col>
        </v-row>

        <v-row
          v-if="
            location.commission_settings && location.company_location == false
          "
          dense
        >
          <v-col cols="12">
            <h2>Commission</h2>
          </v-col>

         

          <v-col
            cols="6"
            md="6"
          >
            <v-select
              v-model="location.commission_settings.canada_rate_type"
              v-validate="'required'"
              :error-messages="errs.collect('type')"
              data-vv-name="type"
              :items="commissionRateTypes"
              label="Canada Commission Type"
              item-text="label"
              item-value="key"
              outlined
              dense
            />
          </v-col>

          <v-col
            cols="6"
            md="6"
          >
            <v-text-field
              v-model="location.commission_settings.canada_rate"
              v-validate="'max_value:1|min_value:0.01'"
              :label="`Canada Rate`"
              type_id="number"
              :error-messages="errs.collect('canada_rate')"
              data-vv-name="canada_rate"
              required
              outlined
              dense
            />
          </v-col>

          <v-col
            cols="6"
            md="6"
          >
            <v-select
              v-model="location.commission_settings.international_rate_type"
              v-validate="'required'"
              :error-messages="errs.collect('type')"
              data-vv-name="type"
              :items="commissionRateTypes"
              label="International Commission Type"
              item-text="label"
              item-value="key"
              outlined
              dense
            />
          </v-col>

          <v-col
            cols="6"
            md="6"
          >
            <v-text-field
              v-model="
                location.commission_settings.international_rate
              "
              v-validate="'max_value:1|min_value:0.01'"
              :label="`International Rate`"
              type_id="number"
              :error-messages="errs.collect('international_rate')"
              data-vv-name="international_rate"
              dense
              outlined
              type="number"
            />
          </v-col>

          <v-col
            cols="6"
            md="6"
          >
            <v-select
              v-model="location.commission_settings.us_rate_type"
              v-validate="'required'"
              :error-messages="errs.collect('type')"
              data-vv-name="type"
              :items="commissionRateTypes"
              label="US Commission Type"
              item-text="label"
              item-value="key"
              outlined
              dense
            />
          </v-col>

          <v-col
            cols="6"
            md="6"
          >
            <v-text-field
              v-model="location.commission_settings.us_rate"
              v-validate="'max_value:1|min_value:0.01'"
              :label="`US Rate`"
              type_id="number"
              :error-messages="errs.collect('us_rate')"
              data-vv-name="us_rate"
              dense
              outlined
              type="number"
            />
          </v-col>

          <!-- <v-col
            v-show="location.commission_settings.commission_type == 'Batch'"
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="location.commission_settings.closeout_flat_commission"
              v-validate="'max_value:5|min_value:0.01'"
              :error-messages="errs.collect('closeout_flat_commission')"
              data-vv-name="closeout_flat_commission"
              label="Per Closeout Commission"
              dense
              outlined
              type="number"
            />
          </v-col> -->
        </v-row>
      </v-container>
    </template>

    <template #actions>
      <v-btn
        v-if="edit"
        color="primary"
        :loading="loading"
        @click="updateLocation()"
      >
        Update
      </v-btn>
      <v-btn
        v-else
        color="primary"
        :loading="loading"
        @click="saveLocation()"
      >
        Save
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
import CONSTANTS from '@/constants';
import _cloneDeep from 'lodash/cloneDeep';

const LOCATION = {
  name: null,
  address1: null,
  address2: null,
  city: null,
  province_code: null,
  country_code: null,
  postal_code: null,
  can_closeout: false,
  region_id: null,
  pallet_identifier: null,
  transfer_location_id: null,
  company_location: false,
  active: false,
  user_id: null,
  inventory_location: 0,
  commission_settings: {
    international_rate_type: null,
    us_rate_type: null,
    canada_rate_type: null,
    us_rate: null,
    canada_rate: null,
    international_rate: null
  },
};
export default {
  data() {
    return {
      loading: false,
      location: LOCATION,
      edit: false,
      dialog: false,
      regions: [],
      company_locations: [],
      countries: CONSTANTS.COUNTRIES,
      commission_settings: {
        international_rate_type: null,
        us_rate_type: null,
        canada_rate_type: null,
        us_rate: null,
        canada_rate: null,
        international_rate: null
      },

      // mixin.js
      commissionRateTypes: this.$commissionRateTypes()
    };
  },
  computed: {
    provinces() {
      if (this.location.country_code == 'CA') {
        return CONSTANTS.PROVINCES;
      } else if (this.location.country_code == 'US') {
        return CONSTANTS.STATES;
      } else {
        return [];
      }
    },
    regex() {
      if (this.location.country_code == 'CA') {
        return /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
      } else if (this.location.country_code == 'US') {
        return /(^\d{5}$)|(^\d{5}-\d{4}$)/;
      } else {
        return null;
      }
    },
  },
  watch: {
    'location.company_location': {
      handler(newValue) {
        if (newValue === false) {
          this.location.transfer_location_id = null;
          this.location.pallet_identifier = null;
          this.location.can_closeout = false;
          if (!this.location.commission_settings) {
            this.location.commission_settings = { ...this.commission_settings };
          }
          this.$validator.reset();
        } else {
          // this.location.user_id = null;
          console.log('location.company_location true');
        }
      }
    },
    'location.can_closeout': {
      handler(newValue) {
        if (newValue == true) {
          console.log('location.can_closeout true');
          this.location.transfer_location_id = null;
          this.$validator.reset();
        } else {
          console.log('location.can_closeout false');
        }
      }
    }
  },
  mounted() {
    this.getRegions();
    this.getCompanyLocations();
  },
  methods: {
    getRegions() {
      this.$http
        .request(`/admin/regions`, {
          method: 'get'
        })
        .then((response) => response.data)
        .then((response) => {
          this.regions = response;
        });
    },
    getCompanyLocations() {
      var params = {
        company_location: true
      };

      this.$http
        .request(`/admin/dropoff-locations`, {
          method: 'get',
          params: params
        })
        .then((response) => response.data)
        .then((response) => {
          this.company_locations = response;
        });
    },
    startNew() {
      this.dialog = true;
      this.location = Object.assign({}, LOCATION);
      this.edit = false;
      this.$validator.reset();
    },
    startEdit(location) {
      this.dialog = true;
      // this.location = Object.assign({}, location);
      this.location = _cloneDeep(location);
      this.edit = true;
      this.$validator.reset();
    },
    saveLocation() {
      this.$validator.validate().then((result) => {
        if (result) {
          // if (
          //   !this.location.commission_settings ||
          //   !this.location.commission_settings.commission_type
          // ) {
          //   this.location.commission_settings = null;
          // }

          if (this.location.company_location) {
            this.location.commission_settings = null;
          }

          this.loading = true
          this.$http
            .post(`/admin/dropoff-locations`, this.location)
            .then(() => {
              this.dialog = false;
              this.$emit('update');
              this.successMessage('Location Created Successfully!');
            })
            .catch((err) => {
              this.onSaveError(err)
            })
            .finally(() => {
              this.loading = false
            });
        }
      });
    },
    updateLocation() {
      this.$validator.validate().then((result) => {
        if (result) {
          // if (
          //   !this.location.commission_settings ||
          //   !this.location.commission_settings.commission_type
          // ) {
          //   this.location.commission_settings = null;
          // }

          if (this.location.company_location) {
            this.location.commission_settings = null;
          }

          this.loading = true
          this.$http
            .put(`/admin/dropoff-locations/${this.location.id}`, this.location)
            .then(() => {
              this.dialog = false;
              this.$emit('update');
              this.successMessage('Location Updated Successfully!');
            })
            .catch((err) => {
              this.onSaveError(err)
            })
            .finally(() => {
              this.loading = false
            });
        } else {
          console.log(this.$validator.errors);
        }
      });
    },
    removLocation(index, criterion) {
      if (criterion.id === null) {
        this.location.location_criteria.splice(index, 1);
      } else {
        this.$set(this.location.location_criteria[index], 'delete', true);
      }
    },
    onSaveError(err) {
      let errMsg = 'Unknown Error Occured';
      if (
        err.response.status === 422 &&
        typeof err.response.data.errors !== 'undefined'
      ) {
        const errors = err.response.data.errors;
        errMsg = ''

        if (!Array.isArray(errors)) {
          errMsg = errors
        } else {
          for (var field in errors) {
            errMsg += `${errors[field][0]}\n`;
          }
        }
      }
      this.errorMessage(errMsg);
    }
  }
};
</script>
