<template>
  <StallionDialog
    v-model="show"
    persistent
    :width="800"
    title="New Claim"
  >
    <template #content>
      <v-card-text class="mt-5">
        <v-container>
          <claims-form
            ref="refClaimsForm"
            :shipment="shipment"
          />
        </v-container>
      </v-card-text>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        :loading="loading"
        @click="submit()"
      >
        Submit
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
import ClaimsForm from './claims-form.vue';

export default {
  components: {
    ClaimsForm
  },
  props: {
    value: {
      type: Boolean,
      default: true
    },
    shipment: {
      type: Object,
      default: () => ({})
    },

    updateShipmentHandler: {
      default: () => {},
      type: Function
    }
  },
  data() {
    return {
      loading: false
    };
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    async submit() {
      // execute the claims form reference actions
      const refsClaimsForm = this.$refs.refClaimsForm;
      if (!(await refsClaimsForm.validate())) return;

      this.loading = true;
      refsClaimsForm
        .submit()
        .then(() => {
          this.updateShipmentHandler();
          this.close();
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },

    close() {
      this.show = null;
    }
  }
};
</script>
