<template>
  <div>
    <v-row>
      <v-col>
        <p>
          Enable direct printing to print directly to your connected printer,
          bypassing the browser print dialog.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <SetupQz />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-switch
          v-model="easyPrint"
          label="Enable Direct Printing"
          @change="changeEasyPrint"
        />
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <h4>Default Printer</h4>
        <v-row>
          <v-col cols="6">
            <v-select
              v-model="selectedPrinter"
              :items="printers"
              label="Select Printer"
              :disabled="disableEasy"
              outlined
            />
          </v-col>
          <v-col cols="2">
            <v-btn
              outlined
              color="primary"
              large
              :disabled="disableEasy"
              @click="refreshPrinters"
            >
              <v-icon left>
                mdi-refresh
              </v-icon>
              Refresh
            </v-btn>
          </v-col>
        </v-row>
        <PrinterSettingsTable
          :printers="printers"
          @save-printer-settings="submit"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SetupQz from "../SetupQz.vue";
import PrinterSettingsTable from "@/pages/main/settings/tabs/PrinterSetting/PrinterSettingsTable.vue";

export default {
  name: "PrinterSettings",
  components: { PrinterSettingsTable, SetupQz },

  data() {
    return {
      printers: [],
      printerFormat: "PDF",
      printerFormats: ["PDF", "ZPL"],
      selectedPrinter: "",
      easyPrint: false,
    };
  },

  computed: {
    setup() {
      return !this.easyPrint;
    },
    disableEasy() {
      return !this.easyPrint;
    },
  },
  watch: {
    easyPrint: {
      handler: "submit",
      immediate: true,
    },
    selectedPrinter: {
      handler: "submit",
      immediate: true,
    },
  },

  async mounted() {
    await this.initializeQz();
    if (this.isPrinterInitialized()) {
      await this.refreshPrinters();
      this.easyPrint = true;
      this.selectedPrinter = this.$store.state.qz.currentPrinter || "";
      this.printerFormat = this.$store.state.qz.printerFormat || "PDF";
    }
  },

  methods: {
    async refreshPrinters() {
      if (this.isQzInitialized()) {
        this.printers = await this.fetchConnectedPrinters();
        // this.printers.unshift("Default");

        this.$notify({
          group: "main",
          title: "Printers Refreshed",
          text: "Printer list has been successfully updated",
          duration: 5000,
          type: "success",
        });
      } else {
        await this.initializeQz();
        if (this.isQzInitialized()) {
          await this.refreshPrinters();
        } else {
          this.$notify({
            group: "main",
            title: "Error",
            text: "Unable to initialize QZ Tray",
            duration: 5000,
            type: "error",
          });
        }
      }
    },

    submit() {
      Event.fire("save-printer-settings");
      if (this.selectedPrinter) {
        localStorage.setItem("printer", this.selectedPrinter);
        localStorage.setItem("printerFormat", this.printerFormat);
        this.$store.state.qz.currentPrinter = this.selectedPrinter;
        this.$store.state.qz.printerFormat = this.printerFormat;
      }
    },

    async changeEasyPrint() {
      if (this.easyPrint) {
        await this.refreshPrinters();
      } else {
        localStorage.removeItem("printer");
        localStorage.removeItem("printerFormat");
        this.selectedPrinter = "";
        this.printerFormat = "PDF";
      }
    },
  },
};
</script>
