<template>
  <v-dialog
    v-model="show"
    width="500"
  >
    <v-card>
      <v-card-title
        class="text-h3"
        primary-title
      >
        <span class="mdi mdi-printer" /> Cancel Pickups
      </v-card-title>

      <v-divider />

      <v-card-text>
        <v-layout column>
          <v-flex
            xs12
            text-center
            mt-4
            mb-12
          >
            <h4>
              Cancel the requested pickup requests.
            </h4>
          </v-flex>
        </v-layout>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn
          color="primary"
          text
          @click="show = null"
        >
          Close
        </v-btn>
        <v-spacer />

        <v-btn
          color="primary"
          :loading="loading"
          :disabled="loading"
          @click="cancel()"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    pickups: {
      type: Array,
      default: () => []
    },
    value: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loading: false
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  mounted() {},

  methods: {
    cancel: function() {
      this.loading = true;
      var ids = [];
      this.pickups.forEach(function(order) {
        ids.push(order.id);
      });

      this.$http({
        method: 'post',
        url: '/pickups/cancel',
        responseType: 'arraybuffer',
        data: { ids: ids }
      })
        .then(() => {})
        .catch(() => {
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
