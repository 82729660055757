import Vue from 'vue'

export function showSuccessMsg(msg, headingTxt, timeout) {
  if (typeof timeout == 'undefined') {
    timeout = 5000;
  }

  Vue.notify({
    heading: typeof headingTxt != 'undefined' ? headingTxt : 'Success',
    text: msg,
    position: 'top-right',
    loaderBg: '#26c6da',
    icon: 'success',
    hideAfter: timeout
  });
}

export function showErrorMsg(msg, headingTxt, timeout) {
  if (typeof timeout == 'undefined') {
    timeout = 5000;
  }

  Vue.notify({
    heading: typeof headingTxt != 'undefined' ? headingTxt : 'Error',
    text: msg,
    position: 'top-right',
    loaderBg: '#ff6849',
    icon: 'error',
    hideAfter: timeout
  });
}

export function handleErrorResponse(status, err) {
  switch (status) {
    case 422:
      if (
        typeof err != 'undefined' &&
        Object.prototype.hasOwnProperty.call(err, 'body')
      ) {
        $.each(err.body, (i, row) => {
          showErrorMsg(row[0]);
        });
      } else {
        showErrorMsg('The given data was invalid.');
      }
      break;
    case 401:
    case 419:
      showErrorMsg(
        'Sorry, your session seems to have expired. Please login again.'
      );
      break;
    default:
      showErrorMsg('Something went wrong while processing your request.');
      break;
  }
}

export function identifyParcelStatus(data) {
  if (data.status_id == '3')
    //Incomplete
    return '<span class="mdi mdi-alert-circle red"></span> Incomplete';
  if (data.status_id == '4')
    //Ready
    return '<span class="mdi mdi-check-circle green"></span> Ready';
  if (data.status_id == '2')
    //Postage_expired
    return '<span class="mdi mdi-star-circle" style="color: yellow"></span> Postage Expired';
  if (data.status_id == '1')
    //Unpaid
    return '<span class="mdi mdi-star-circle red"></span> Unpaid';
  if (data.status_id == '5')
    //Pending
    return '<span class="mdi mdi-pause-circle" style="color: orange"></span> Pending';
  if (data.status_id == '6')
    //Refunded
    return '<span class="mdi mdi-google-circles-group green"></span> Refunded';
  if (!data.status_id) return '<span>NA</span>';
}

export function getClientOS() {
  var os = null;

  if (navigator.appVersion.indexOf('Win') != -1) os = 'windows';
  if (navigator.appVersion.indexOf('Mac') != -1) os = 'mac';
  if (navigator.appVersion.indexOf('X11') != -1) os = 'unix';
  if (navigator.appVersion.indexOf('Linux') != -1) os = 'linux';

  return os;
}

/**
 * returns item qty based on the default user's employee_location_id or branch
 * @param {*} item
 * @param {*} user
 */
export function getItemQtyByUsersLocation(item, user) {
  if (!item || !user) return 0;
  let location_id = user.employee_location_id;
  let found = _.find(item.inventories || [], {
    branch_location_id: location_id
  });
  return found ? found.quantity_in_stock : 0;
}

/**
 * initialize item inventory in case the following scripts are not executed in the server
    php artisan db:seed --class=BranchLocationsSeeder
    php artisan UpdateUserBranchLocations
    php artisan UpdateSuppliesInventoryToSeparateLocations
    or if no employee_location_id is set
 * @param {*} item 
 */
export function initializeInventory(item) {
  ['branch_location_inventory', 'employee_location_inventory'].forEach(
    inventory => {
      if (!item[inventory]) {
        item[inventory] = {
          quantity_in_stock: 0,
          pending_pickup: 0
        };
      }
    }
  );
}

export function handleLaravelValidationError(error) {
  if (
    error.response &&
    error.response.status == 422 &&
    error.response.data.errors !== 'undefined'
  ) {
    const title = error.response.data.message || 'The given data was invalid.';
    let text = '';
    const errors = error.response.data.errors;
    for (const key in errors) {
      if (Object.prototype.hasOwnProperty.call(errors, key)) {
        errors[key].forEach(err => {
          text += err + '\n';
        });
      }
    }
    swal({
      title: title,
      text: text,
      icon: 'error'
    });
  } else {
    swal({
      title: 'Error!',
      text: 'An error occurred',
      icon: 'error'
    });
  }
}
