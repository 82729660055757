<template>
  <FullPageLayout :title="title">
    <template #default>
      <v-container
        v-if="commissionStructure"
        grid-list-md
      >
        <v-layout wrap>
          <v-flex xs6>
            <v-card outlined>
              <v-card-text>
                <b>ID:</b> {{ commissionStructure.id }}
                <br>
                <b>Type:</b> {{ commissionStructure.type }}
                <br>
                <b>Created At:</b> {{ commissionStructure.created_at | date_pretty }}
                <br>
              </v-card-text>
            </v-card>
          </v-flex>

          <v-flex
            v-if="commissionStructure.earner"
            xs6
          >
            <v-card outlined>
              <v-card-text>
                <b>Earner ID:</b> {{ commissionStructure.earner.id }}
                <br>
                <b>Email:</b> {{ commissionStructure.earner.email }}
                <br>
                <b>Name:</b> {{ commissionStructure.earner.name }}
                <br>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>

        <commissions-chart
          :commission-structure-id="commissionStructure.id"
        />
      </v-container>
    </template>
  </FullPageLayout>
</template>

<script>
import CommissionsChart from  './commissions-chart.vue'

export default {
  components: {
    CommissionsChart
  },
  data() {
    return {
      commissionStructure: null,
      loading: false
    }
  },
  computed: {
    title() {
      return 'Commission Structure - ' + this.$route.params.id
    }
  },
  mounted() {
    this.getCommissionStructure();
  },
  methods: {
    getCommissionStructure() {
      this.loading = true
      this.$http.get('/admin/commission-structures/' + this.$route.params.id)
        .then((response) => {
            this.commissionStructure = response.data;
          })
        .catch((err) => {
          console.log(err)
          this.errorMessage()
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
};
</script>
