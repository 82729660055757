<template>
  <StallionDialog
    v-model="show"
    :width="800"
    transition="dialog-bottom-transition"
    :title="!edit ? 'Create Address' : 'Update Address'"
  >
    <template #content>
      <v-form @submit.prevent>
        <v-container>
          <v-row dense>
            <v-col cols="6">
              <v-text-field
                v-model="addressBook.name"
                v-validate="'required'"
                :error-messages="errs.collect('name')"
                data-vv-name="name"
                label="Name"
                dense
                outlined
              />
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="addressBook.company"
                :error-messages="errs.collect('company')"
                data-vv-name="company"
                label="Company"
                dense
                outlined
              />
            </v-col>

            <v-col
              cols="6"
              md="6"
              sm="12"
            >
              <v-text-field
                id="AddressBookAddress1"
                v-model="addressBook.address1"
                v-validate="'required'"
                :error-messages="errs.collect('address_1')"
                data-vv-name="address_1"
                label="Address 1"
                dense
                outlined
              />
            </v-col>

            <v-col
              cols="6"
              md="6"
              sm="12"
            >
              <v-text-field
                ref="refAddress2"
                v-model="addressBook.address2"
                :error-messages="errs.collect('address_2')"
                data-vv-name="address_2"
                label="Address 2"
                dense
                outlined
              />
            </v-col>

            <v-col
              cols="6"
              md="6"
              sm="12"
            >
              <v-select
                v-model="addressBook.country_code"
                v-validate="'required'"
                :error-messages="errs.collect('country_code')"
                data-vv-name="country_code"
                label="Country Code"
                :items="countries"
                item-text="name"
                item-value="code"
                dense
                outlined
              />
            </v-col>

            <v-col
              cols="6"
              md="6"
              sm="12"
            >
              <v-text-field
                v-model="addressBook.city"
                v-validate="'required'"
                :error-messages="errs.collect('city')"
                data-vv-name="city"
                label="City"
                dense
                outlined
              />
            </v-col>

            <v-col
              cols="6"
              md="6"
              sm="12"
            >
              <v-autocomplete
                v-if="addressBook.country_code == 'CA'"
                v-model="addressBook.province_code"
                v-validate="'required|max:2'"
                clearable
                outlined
                dense
                :error-messages="errs.collect('province_code')"
                data-vv-name="province_code"
                :items="provinces"
                label="Province"
                item-text="name"
                item-value="code"
                dusk="sf_province"
              />

              <v-autocomplete
                v-else-if="addressBook.country_code == 'US'"
                v-model="addressBook.province_code"
                v-validate="'required|max:2'"
                clearable
                outlined
                :error-messages="errs.collect('province_code')"
                data-vv-name="province_code"
                :items="states"
                label="State"
                item-text="name"
                item-value="code"
                dense
              />

              <v-text-field
                v-else
                v-model="addressBook.province_code"
                clearable
                outlined
                dense
                label="Province/State"
              />
            </v-col>

            <v-col
              cols="6"
              md="6"
              sm="12"
            >
              <v-text-field
                v-model="addressBook.postal_code"
                v-validate="'required'"
                :error-messages="errs.collect('postal_code')"
                data-vv-name="postal_code"
                label="Postal Code"
                dense
                outlined
              />
            </v-col>

            <v-col
              cols="6"
              md="6"
              sm="12"
            >
              <v-text-field
                v-model="addressBook.email"
                v-validate="'email'"
                :error-messages="errs.collect('email')"
                data-vv-name="email"
                label="Email"
                dense
                outlined
              />
            </v-col>

            <v-col
              cols="6"
              md="6"
              sm="12"
            >
              <v-text-field
                v-model="addressBook.phone"
                v-validate="''"
                :error-messages="errs.collect('phone')"
                data-vv-name="phone"
                label="Phone"
                dense
                outlined
              />
            </v-col>

            <v-col
              cols="6"
              md="6"
              sm="12"
            >
              <v-switch
                v-model="addressBook.is_default"
                label="Set as default"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>

    <template
      #actions
    >
      <v-spacer />
      <v-btn
        v-if="edit"
        color="primary"
        class="px-7"
        :disabled="loading"
        :loading="loading"
        @click="updateAddress"
      >
        Update
      </v-btn>
      <v-btn
        v-else
        color="primary"
        class="px-7"
        :disabled="loading"
        :loading="loading"
        @click="saveAddress"
      >
        Save
      </v-btn>
    </template>
  </StallionDialog>
</template>
<script>
import CONSTANTS from '@/constants';

const ADDRESS_BOOK = {
  id: null,
  name: null,
  phone: null,
  address1: null,
  address2: null,
  city: null,
  province_code: null,
  country_code: null,
  postal_code: null,
  email: null,
  company: null,
  is_default: false
};

export default {
  data() {
    return {
      countries: CONSTANTS.COUNTRIES,
      provinces: CONSTANTS.PROVINCES,
      states: CONSTANTS.STATES,
      loading: false,
      show: false,
      edit: false,
      addressBook: Object.assign({}, ADDRESS_BOOK)
    };
  },
  methods: {
    initGoogleMap() {
      if (typeof google !== 'undefined') {
        try {
          var element = document.getElementById('AddressBookAddress1');

          // geocode instructs the Place Autocomplete service to return only geocoding results, rather than business results. Generally, you use this request to disambiguate results where the location specified may be indeterminate.
          // address instructs the Place Autocomplete service to return only geocoding results with a precise address. Generally, you use this request when you know the user will be looking for a fully specified address.
          // establishment instructs the Place Autocomplete service to return only business results.

          // var defaultBounds = new google.maps.LatLngBounds(
          //   new google.maps.LatLng(-55, -130),
          //   new google.maps.LatLng(-24, -49)
          // );

          const options = {
            // bounds: defaultBounds,
            // componentRestrictions: { country: 'us' },
            fields: ['address_components'],
            strictBounds: false,
            types: ['address']
          };

          var autocomplete = new google.maps.places.Autocomplete(
            element,
            options
          );

          autocomplete.addListener('place_changed', () => {
            // Get the place details from the autocomplete object.
            const place = autocomplete.getPlace();
            let address1 = '';
            let postcode = '';

            this.addressBook.address1 = null;
            this.addressBook.address2 = null;
            this.addressBook.city = null;
            this.addressBook.province_code = null;
            this.addressBook.country_code = null;
            this.addressBook.postal_code = null;

            // Get each component of the address from the place details,
            // and then fill-in the corresponding field on the form.
            // place.address_components are google.maps.GeocoderAddressComponent objects
            // which are documented at http://goo.gle/3l5i5Mr
            // console.log(place.address_components);
            if (!place.address_components || !Array.isArray(place.address_components)){
              return;
            }

            for (const component of place.address_components) {
              // @ts-ignore remove once typings fixed
              const componentType = component.types[0];

              switch (componentType) {
                case 'street_number': {
                  address1 = `${component.long_name} ${address1}`;
                  break;
                }

                case 'subpremise': {
                  this.addressBook.address2 = component.short_name;
                  break;
                }

                case 'route': {
                  address1 += component.short_name;
                  break;
                }

                case 'postal_code': {
                  postcode = `${component.long_name}${postcode}`;
                  break;
                }

                case 'postal_code_suffix': {
                  postcode = `${postcode}-${component.long_name}`;
                  break;
                }
                case 'locality':
                  this.addressBook.city = component.long_name;
                  break;
                case 'administrative_area_level_1': {
                  this.addressBook.province_code = component.short_name;
                  break;
                }
                case 'country':
                  this.addressBook.country_code = component.short_name;
                  break;
              }
            }

            this.addressBook.address1 = address1;
            this.addressBook.postal_code = postcode;
            // After filling the form with address components from the Autocomplete
            // prediction, set cursor focus on the second address line to encourage
            // entry of subpremise information such as apartment, unit, or floor number.
            // address2Field.focus();

            this.$refs.refAddress2.focus();
          });
        } catch (ex) {
          console.log(ex);
        }
      }
    },
    initDialog(address) {
      // NOTE: nextTIck will not work - will need to use setTimeout to wait for the element to be rendered
      setTimeout(() => {
        this.initGoogleMap()
      }, 100)
      this.$validator.reset();
      this.show = true;
      this.addressBook = Object.assign({}, {
        ...address,
        is_default: address.is_default ? true : false
      });
    },
    startNew() {
      this.initDialog(ADDRESS_BOOK);
      this.edit = false;
    },
    startEdit(address) {
      this.initDialog(address);
      this.edit = true;
    },
    cancelEdit() {
      this.show = false;
      this.addressBook = Object.assign({}, ADDRESS_BOOK)
    },
    async validateForm() {
      return await this.$validator.validate().then((result) => {
        return result ? true : false;
      });
    },
    async saveAddress() {
      if (!(await this.validateForm())) {
        return;
      }

      this.loading = true;
      this.$http
        .post('/address-books', this.addressBook)
        .then((response) => response.data)
        .then((response) => {
          if (response.success) {
            this.processSuccess(response, 'Address has been stored');
          } else {
            this.errorMessage(response.message);
          }
        })
        .catch((err) => {
          this.processError(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async updateAddress() {
      if (!(await this.validateForm())) {
        return;
      }

      this.loading = true;
      this.$http
        .put(`/address-books/${this.addressBook.id}`, this.addressBook)
        .then((response) => response.data)
        .then((response) => {
          this.processSuccess(response, 'Address has been updated');
        })
        .catch((err) => {
          this.processError(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    processSuccess(response, message) {
      swal({
        title: 'Success',
        text: message,
        icon: 'success'
      });
      this.$store.dispatch('main/getDefaultAddress');
      this.cancelEdit();
      this.$emit('save');
    },
    processError(err) {
      if (
        err.response.status === 422 &&
        typeof err.response.data.errors !== 'undefined'
      ) {
        const errors = err.response.data.errors;
        let message = '';

        for (var field in errors) {
          message += `${errors[field][0]}\n`;
        }
        this.errorMessage(message);
      } else {
        this.errorMessage('An error occurred while processing your request');
      }
    },
  }
};
</script>

<style>
#AddressBookAddress1::placeholder {
  visibility: hidden !important;
}
</style>
