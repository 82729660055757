<template>
  <StallionDialog
    v-model="show"
    :width="500"
    :disabled="disabled"
    persistent
    title="Status"
  >
    <template #content>
      <v-card-text>
        <v-layout column>
          <v-flex
            xs12
            text-center
            mt-4
            mb-12
          >
            <h4>Change the status of {{ invoices.length }} invoice</h4>
          </v-flex>
          <v-flex
            xs12
            text-center
            mt-4
            mb-12
          >
            <v-select
              v-model="status"
              :items="statuses"
              item-value="id"
              item-text="description"
            />
          </v-flex>
        </v-layout>
      </v-card-text>
    </template>
    <template #actions>
      <v-btn
        color="primary"
        :loading="update_loading"
        :disabled="update_loading"
        @click="updateStatuses()"
      >
        Update
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    invoices: {
      type: Array,
      default: () => []
    },
    disabled: {
      default: false,
      type: Boolean
    },
    autoStatus: {
      default: 0,
      type: Number
    }
  },

  data() {
    return {
      update_loading: false,
      dowload_loading: false,
      show: false,
      statuses: [
        {
          id: 6,
          description: 'Pending'
        },
        {
          id: 4,
          description: 'In-Transit'
        },
        {
          id: 5,
          description: 'Ready'
        },
        {
          id: 2,
          description: 'Complete'
        }
      ],
      status: null
    };
  },

  // mounted() {
  // this.getInvoiceStatuses();
  // },

  methods: {
    openDialog() {
      this.show = true;
    },
    updateStatuses: function() {
      this.update_loading = true;

      var ids = [];
      this.invoices.forEach(function(invoice) {
        ids.push(invoice.id);
      });
      this.$http
        .post('/admin/supplies-invoices/update-statuses', {
          ids: ids,
          status: this.status
        })
        .then(() => {
          Event.fire('update-supplies-orders');
          Event.fire('update-supplies-order-statuses');
        })
        .finally(() => {
          this.update_loading = false;
          this.show = false;
        });
    },
    close() {
      this.show = null;
      Event.fire('update-supplies');
    }
  }
};
</script>
