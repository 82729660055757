<template>
  <FullPageLayout title="Recurring Charges">
    <template #default>
      <recurring-charges-table />
    </template>
  </FullPageLayout>
</template>
  
<script>
import RecurringChargesTable from  '../../../components/admin/billing/recurring-charges-table.vue'

export default {
  components: {
    RecurringChargesTable
  }
};
</script>
