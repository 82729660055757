<template>
  <StallionDialog
    v-model="show"
    :width="500"
    :disabled="disabled"
    class="dialog-slot"
    title="Etsy"
  >
    <slot v-on="on" />

    <template #content>
      <v-row>
        <v-col
          cols="12"
          class="text-center"
        >
          You'll be redirected to Etsy to verify your account.
        </v-col>
      </v-row>
    </template>

    <template #actions>
      <v-btn
        type="submit"
        color="primary"
        :loading="print_loading"
        :disabled="print_loading"
        @click="connect()"
      >
        Connect
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },

  data() {
    return {
      print_loading: false,
      dowload_loading: false,
      shops: null,
      username: null
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    connect: function() {
      this.print_loading = true;
      this.$http
        .post(`/stores/etsy`, { shops: this.shops, username: this.username })
        .then(response => response.data)
        .then(response => {
          window.location = response.redirect;
        });
    }
  }
};
</script>
