<template>
  <FullPageLayout title="Supplies Order Scan">
    <template #default>
      <v-flex xs12>
        <v-form>
          <v-container>
            <v-row
              class="pb-2"
              style="justify-content: space-between"
            >
              <v-text-field
                v-model="barcode"
                label="Manual Entry"
                class="mr-2"
                outlined
                clearable
                dense
                hide-details
                @keydown.enter.prevent="manualScan"
              />

              <v-btn
                color="primary"
                @click="manualScan"
              >
                Submit
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-flex>
      <v-flex
        id="scan-page"
        xs12
      >
        <v-flex
          v-for="(item, index) in errors"
          :key="index"
          xs12
        >
          <v-alert
            class="pa-2"
            :value="true"
            dismissible
            type="error"
            transition="fade-transition"
          >
            {{ item }}
          </v-alert>
        </v-flex>
        <v-flex
          v-for="(item, index) in warnings"
          :key="index"
          xs12
        >
          <v-alert
            class="pa-2"
            :value="true"
            dismissible
            type="warning"
            transition="fade-transition"
          >
            {{ item }}
          </v-alert>
        </v-flex>

        <v-data-table
          :headers="headers"
          :items="invoices"
          :loading="loading"
          item-key="id"
          class="table-striped"
          no-data-text="Awaiting scanned items"
          :page.sync="pagination.page"
          :items-per-page.sync="pagination.itemsPerPage"
          :sort-by.sync="pagination.sortBy"
          :sort-desc.sync="pagination.descending"
          :footer-props="{
            'items-per-page-options': [50, 100, 200, 500]
          }"
        >
          <template #item.created_at="{ item }">
            {{ item.created_at | date_pretty }}
            <br>
            ({{ item.created_at | moment('from') }})
          </template>

          <template #item.quantity="{ item }">
            {{ item.items.length }}
          </template>
          <template #item.total="{ item }">
            {{ item.total | currency }}
          </template>
        </v-data-table>
      </v-flex>
    </template>
  </FullPageLayout>
</template>

<script>
export default {
  data() {
    return {
      invoices: [],
      warnings: [],
      activeBtn: 1,
      showNav: true,
      barcodes: [],
      box: 0,
      barcode: null,
      headers: [
        {
          text: 'Invoice ID',
          value: 'invoice_number'
        },
        {
          text: 'User',
          value: 'user.email'
        },
        {
          text: 'Status',
          value: 'status.description'
        },
        {
          text: '# Line Items',
          value: 'quantity'
        },
        {
          text: 'Total',
          value: 'total'
        },
        {
          text: 'Created At',
          value: 'created_at'
        }
      ],
      pagination: {
        page: 1,
        itemsPerPage: 25,
        sortBy: null,
        descending: false
      },
      loading: false,
      errors: [],
      status: null
    };
  },
  computed: {},
  created() {
    document.activeElement.blur();
    // Add barcode scan listener and pass the callback function
    this.$barcodeScanner.init(this.onBarcodeScanned);
  },
  destroyed() {
    // Remove listener when component is destroyed
    this.$barcodeScanner.destroy();
  },
  methods: {
    manualScan() {
      this.onBarcodeScanned(this.barcode);

      this.barcode = null;
    },
    test() {
      var x = document.activeElement.tagName;

      return x;
    },
    // Create callback function to receive barcode when the scanner is already done
    onBarcodeScanned(barcode) {
      console.log(barcode);

      this.$notify({
        group: 'main',
        title: 'Item Scanned',
        text: barcode,
        duration: 3000
      });

      let self = this;

      this.scan(barcode).then((res) => {
        console.log(res);

        self.invoices.push(res.invoice);
      });

      // do something...
    },
    // Reset to the last barcode before hitting enter (whatever anything in the input box)
    resetBarcode() {
      this.$barcodeScanner.getPreviousCode();
      // do something...
    },
    scan(barcode) {
      const params = {
        barcode,
        status_id: this.$route.params.status_id
      };
      return new Promise((resolve, reject) => {
        this.$http
          .post('/admin/supplies/scan/', params)
          .then((response) => response.data)
          .then((response) => {
            if (response.success) {
              this.playSuccessSound();
              resolve({
                invoice: response.invoice
              });
            } else {
              this.playErrorSound();
              this.errors.push(`${barcode}: ${response.error}`);
            }
          })
          .catch((err) => {
            this.playErrorSound();
            this.errors.push(`${barcode}: ${err}`);
            reject({});
          });
      });
    }
  }
};
</script>
