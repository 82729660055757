<template>
  <SidePanelLayout>
    <template #side-panel>
      <div class="mb-3">
        <h4>
          Old Values
        </h4>

        <div v-if="!Array.isArray(oldValues)">
          <div
            v-for="(value, propertyName) in oldValues"
            :key="propertyName"
          >
            <span class="property-title">{{ propertyName }}</span>
            <span
              v-if="typeof value === 'string' && value.includes('/')"
              class="float-right"
            >
              <a
                :href="value"
                target="_blank"
              >View</a>
            </span>
            <span
              v-else-if="value === null || value === ''"
              class="float-right"
            >-</span>
            <span
              v-else
              class="float-right"
            >{{ value }}</span>
          </div>
        </div>
        <div v-else>
          <span class="empty">Old Values are empty</span>
        </div>
      </div>

      <div>
        <h4>
          New Values
        </h4>

        <div v-if="!Array.isArray(newValues)">
          <div
            v-for="(value, propertyName) in newValues"
            :key="propertyName"
          >
            <span class="property-title">{{ propertyName }}</span>
            <span
              v-if="typeof value === 'string' && value.includes('/')"
              class="float-right"
            >
              <a
                :href="value"
                target="_blank"
              >View</a>
            </span>
            <span
              v-else-if="value === ''"
              class="float-right"
            >-</span>
            <span
              v-else
              class="float-right"
            >{{ value }}</span>
          </div>
        </div>
        <div v-else>
          <span class="empty">New Values are empty</span>
        </div>
      </div>
    </template>

    <template #default>
      <v-layout wrap>
        <v-flex xs12>
          <h1 class="mb-3 d-block">
            <span>Shipment History</span>
          </h1>
          <span>{{ $route.params.shipcode }}</span>
        </v-flex>

        <v-flex s12>
          <v-card
            class="elevation-0"
            style="border: 1px solid #e7e7e7;"
          >
            <v-data-table
              class="table-striped"
              :headers="headers"
              :items="items"
              :loading="loading"
              item-key="id"
              :server-items-length="totalItems"
              :footer-props="{
                'items-per-page-options': [50, 100, 200, 500]
              }"
              @click:row="rowClick"
            >
              <template #item.user_role="{ item }">
                <span v-if="item.user_role === 1">Admin</span>
                <span v-else-if="item.user_role === 2">US employee</span>
                <span v-else>Client</span>
              </template>
              <template #item.created_at="{ item }">
                {{ item.created_at | datetime_short }}
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-layout>
    </template>
  </SidePanelLayout>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      selected: true,
      items: [],
      drawer: true,
      loading: true,
      totalItems: 0,
      oldValues: {},
      newValues: {},
      headers: [
        {
          text: 'Id',
          value: 'audit_id',
          sortable: false
        },

        {
          text: 'User',
          value: 'user',
          sortable: false
        },
        {
          text: 'User Role',
          value: 'user_role',
          sortable: false
        },
        {
          text: 'Event',
          value: 'event',
          sortable: false
        },
        {
          text: 'Url',
          value: 'url',
          sortable: false
        },
        {
          text: 'Created At',
          value: 'created_at',
          sortable: false
        }
      ]
    };
  },
  created() {
    this.getShipmentHistory();
  },
  methods: {
    rowClick(item) {
      this.drawer = true;
      this.oldValues = item.old_values;
      this.newValues = item.new_values;
    },
    getShipmentHistory() {
      this.$http
        .get(`/admin/shipments/${this.$route.params.id}/history`)
        .then(response => response.data)
        .then(response => {
          if (response.success) {
            this.items = response.data;
            this.totalItems = response.data.length;
            this.loading = false;
            console.log(response);
          }
        });
    },
    viewValues(id) {
      this.drawer = true;
      const item = this.items.find(item => item.audit_id === id);
      this.oldValues = item.old_values;
      this.newValues = item.new_values;
    }
  }
};
</script>

<style scoped>
.value-title {
  color: black;
  padding: 4px;
  background-color: rgb(252, 183, 44);
  border-radius: 5px;
  box-shadow: 5px 5px 15px 1px rgb(163, 118, 28);
  margin: 35px 0px 10px 0px;
  display: inline-block;
}
.property-title {
  color: #9d9da6;
  font-size: 12px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.8px;
}
.empty {
  display: inline-block;
  margin-top: 20px;
}
</style>
