<template>
  <StallionDialog
    v-model="show"
    :width="500"
    persistent
    title="Add To Batch"
  >
    <template #content>
      <v-form>
        <v-container fluid>
          <v-row dense>
            <v-col cols="12">
              <div class="dialog-info">
                {{ items.length }} {{ items.length | pluralize(` ${type}`) }} ready to add to batch
              </div>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="batch_id"
                outlined
                :items="batches"
                label="Batches"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="name"
                label="Name (Optional)"
                clearable
                outlined
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>

    <template #actions>
      <v-btn
        :loading="loading"
        :disabled="loading"
        color="primary"
        class="px-7"
        @click="moveToBatch"
      >
        Save
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    shipments: {
      type: Array,
      default: () => []
    },
    orders: {
      type: Array,
      default: () => []
    },
    value: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'shipment'
    }
  },

  data() {
    return {
      batch_id: null,
      name: null,
      loading: false
    };
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    items() {
      return this.type === 'shipment' ? this.shipments : this.orders;
    },
    batches() {
      return this.$store.getters['batches/openBatchesOptions'];
    }
  },

  watch: {
    batch_id(val) {
      this.name = val ? this.batches.find(batch => batch.value === val)?.name || null : null;
    }
  },

  mounted() {
    this.$store.dispatch('batches/getOpenBatches');
    
    window.Event.listen('add-to-batch', (batchId) => {
      if (!this.items.length) {
        return;
      }

      this.batch_id = batchId;
      this.show = true;
    });
  },

  methods: {
    async moveToBatch() {
    

      const url = this.type === 'shipment' ? '/shipments/batch-add' : '/orders/batch-add';
      const ids = this.items.map(item => item.id);

      const countInBatchAlready = this.type === 'shipment'
        ? this.shipments.filter(item => item.batch_id).length
        : this.orders.filter(item => item.shipment && item.shipment.batch_id).length;

        if(countInBatchAlready > 0) {
          const confirm = await swal({
            title: 'Are you sure?',
            text: countInBatchAlready + ' ' + this.$options.filters.pluralize(countInBatchAlready, this.type) + ' already in a batch. Are you sure you want to move the selected items to the batch?',
            icon: 'warning',
            buttons: ['Cancel', 'Confirm']
          })

          if (!confirm) {
            this.loading = false;
            return;
          }
        }
   
      this.loading = true;
      
      try {
        await this.$http.post(url, { ids, batch_id: this.batch_id, name: this.name });

        this.show = false;
        this.$emit('update');
        await this.$store.dispatch('batches/getOpenBatches');
      } catch (error) {
        this.errorMessage('Could not move the selected items to the batch');
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
