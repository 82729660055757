<template>
  <SingleCardLayout>
    <v-container>
      <v-layout wrap>
        <v-flex
          xs12
          mt-12
          mb-12
        >
          <v-alert
            v-if="$auth.user.approval_status === 'Pending'"
            color="info"
            :value="true"
            icon="info"
            transition="fade-transition"
          >
            Your account is pending approval. You will receive an email once your account has been approved.
          </v-alert>
          <v-alert
            v-else-if="$auth.user.approval_status === 'Rejected'"
            color="error"
            :value="true"
            icon="warning"
            transition="fade-transition"
          >
            Your account has been rejected. Please contact support for more information.
          </v-alert>
        </v-flex>
      </v-layout>

      <v-layout
        align-center
        class="mt-12"
      >
        <v-flex
          xs12
          text-center
        >
          <v-btn
            outline
            color="primary"
            @click.prevent="logout()"
          >
            <v-icon
              dark
              small
            >
              mdi-logout
            </v-icon>Logout
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </SingleCardLayout>
</template>

<script>
export default {
  data() {
    return {
      password: '',
      email: '',
      rememberMe: false,
      error: '',
      loading2: false
    };
  },
  mounted() {
    this.getUser().then(() => {
      if (this.$auth.user.approval_status === 'Approved') {
        this.$router.push('/dashboard');
      }
    });
  },

  methods: {
  }
};
</script>

<style>
#remember .v-input--selection-controls {
  margin-top: 16px;
  padding-top: 4px;
}
</style>
