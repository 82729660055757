<template>
  <FullPageLayout title="Postage Adjustments">
    <template #default>
      <PostageAdjustment @submitted="handleSubmitted" />
      <PostageAdjustmentBatch ref="PostageAdjustmentBatch" />
    </template>
  </FullPageLayout>
</template>

<script>
import PostageAdjustment from './PostageAdjustment.vue'
import PostageAdjustmentBatch from './PostageAdjustmentBatch.vue'

export default {
  components: {
    PostageAdjustment,
    PostageAdjustmentBatch
  },
  data() {
    return {
      tab: null,
    };
  },
  methods: {
    handleSubmitted() {
      this.$refs.PostageAdjustmentBatch.updateTable();
    },
  },
};
</script>
