<template>
  <div>
    <StallionDialog
      v-model="show"
      :width="800"
      :title="bulkEditDialogLabel"
    >
      <template #content>
        <v-row dense>
          <v-col xs12>
            <v-radio-group
              v-model="row"
              row
            >
              <v-radio
                label="Custom"
                value="custom"
              />
              <v-radio
                label="Presets"
                value="presets"
              />
            </v-radio-group>
          </v-col>
        </v-row>
        <v-divider />

        <v-row
          v-if="row == 'presets'"
          dense
        >
          <!-- <v-col cols="12">
            <h2>Preset</h2>
          </v-col> -->

          <v-col cols="4">
            <v-select
              v-model="preset_id"
              v-validate="''"
              data-vv-name="preset_id"
              :error-messages="errs.collect('preset_id')"
              :items="presets"
              item-text="preset_name"
              item-value="id"
              label="Preset"
              outlined
              dense
            />
          </v-col>

          <v-col cols="4">
            <!-- $hotkeyType from mixin.js  -->
            <v-text-field
              v-model.trim="edit_hotkey"
              v-validate="'max:1'"
              :error-messages="errs.collect('edit_hotkey')"
              data-vv-name="edit_hotkey"
              :label="'Hotkey (' + $hotkeyType() + ' +)'"
              dense
              outlined
            />
          </v-col>

          <v-col cols="auto">
            <v-btn
              v-if="row == 'presets'"
              class="mr-2"
              color="primary"
              depressed
              :disabled="preset_id == null"
              :loading="loading"
              @click="updatePreset()"
            >
              <v-icon small>
                mdi-content-save
              </v-icon>
            </v-btn>

            <v-btn
              v-if="row == 'presets'"
              class="mr-2"
              color="red"
              depressed
              outlined
              :disabled="preset_id == null"
              :loading="loading"
              @click="deletePreset()"
            >
              <v-icon small>
                mdi-delete
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-divider />
          </v-col>
        </v-row>

        <v-row
          v-if="ship"
          dense
        >
          <v-col cols="12">
            <h2>Package</h2>
          </v-col>

          <v-col
            cols="12"
            md="12"
          >
            <v-select
              v-model="ship.package_type_id"
              v-validate="''"
              :error-messages="errs.collect('package_type')"
              data-vv-name="package_type"
              label="Package Type"
              :items="packageTypes"
              item-text="description"
              item-value="id"
              dense
              outlined
            />
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <v-text-field
              v-model="ship.length"
              v-validate="''"
              :error-messages="errs.collect('length')"
              data-vv-name="length"
              label="Length"
              dense
              outlined
              type="number"
            />
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <v-text-field
              v-model="ship.width"
              v-validate="''"
              :error-messages="errs.collect('width')"
              data-vv-name="width"
              label="Width"
              dense
              outlined
              type="number"
            />
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <v-text-field
              v-model="ship.height"
              v-validate="''"
              :error-messages="errs.collect('height')"
              data-vv-name="height"
              label="Height"
              dense
              outlined
              type="number"
            />
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <!-- $sizeUnits from mixin.js  -->
            <v-select
              v-model="ship.size_unit"
              v-validate="''"
              :error-messages="errs.collect('size_unit')"
              data-vv-name="size_unit"
              label="Unit"
              :items="$sizeUnits()"
              dense
              outlined
            />
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <v-text-field
              v-model="ship.weight"
              v-validate="''"
              :error-messages="errs.collect('weight')"
              data-vv-name="weight"
              label="Weight"
              dense
              outlined
              type="number"
            />
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <v-select
              v-model="ship.weight_unit"
              v-validate="''"
              :error-messages="errs.collect('weight_unit')"
              data-vv-name="weight_unit"
              label="Weight Unit"
              :items="['kg', 'g', 'oz', 'lbs']"
              dense
              outlined
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col
            v-for="(item, index) in ship?.items"
            :key="index"
            cols="6"
          >
            <v-card
              outlined
              class="pa-5"
            >
              <v-row dense>
                <v-col cols="6">
                  <strong>Line {{ index + 1 }}</strong>
                </v-col>
                <v-col
                  cols="6"
                  class="text-right"
                >
                  <v-btn
                    color="error"
                    x-small
                    depressed
                    @click="removeItem(index)"
                  >
                    Remove
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :ref="`sku_${index}`"
                    v-model="item.sku"
                    v-validate="''"
                    outlined
                    dense
                    :error-messages="errs.collect(`sku_${index}`)"
                    label="SKU"
                    :data-vv-name="`sku_${index}`"
                    required
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="item.description"
                    v-validate="'required'"
                    outlined
                    dense
                    :error-messages="
                      errs.collect(`description_${index}`)
                    "
                    label="Description"
                    :data-vv-name="`description_${index}`"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="item.quantity"
                    v-validate="'required|min_value:1|integer'"
                    outlined
                    dense
                    :error-messages="errs.collect(`quantity_${index}`)"
                    label="Quantity"
                    :data-vv-name="`quantity_${index}`"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="item.value"
                    v-validate="'required|min_value:0.00'"
                    outlined
                    dense
                    :error-messages="errs.collect('value')"
                    label="Unit Value"
                    data-vv-name="value"
                  />
                </v-col>
                <v-col cols="4">
                  <!-- $currencyAsFlatArray() from mixin.js  -->
                  <v-select
                    v-model="item.currency"
                    v-validate="''"
                    :error-messages="errs.collect(`currency_${index}`)"
                    :data-vv-name="`currency_${index}`"
                    label="Currency"
                    :items="$currencyAsFlatArray()"
                    dense
                    outlined
                  />
                </v-col>

                <v-col
                  v-if="
                    !['US', 'CA'].includes(
                      ship.country_code
                    )
                  "
                  cols="12"
                >
                  <v-text-field
                    :ref="`customs_description_${index}`"
                    v-model="item.customs_description"
                    v-validate="''"
                    outlined
                    dense
                    :error-messages="errs.collect(`customs_description_${index}`)"
                    label="Customs Description"
                    :data-vv-name="`customs_description_${index}`"
                    required
                  />
                </v-col>
                <v-col
                  v-if="
                    !['US', 'CA'].includes(
                      ship.country_code
                    )
                  "
                  cols="6"
                >
                  <v-text-field
                    v-model="item.hs_code"
                    v-validate="''"
                    outlined
                    dense
                    :error-messages="errs.collect(`hs_code_${index}`)"
                    label="HS Code"
                    :data-vv-name="`hs_code_${index}`"
                  />
                </v-col>
                <v-col
                  v-if="
                    !['US', 'CA'].includes(
                      ship.country_code
                    )
                  "
                  cols="6"
                >
                  <v-autocomplete
                    v-model="item.country_of_origin"
                    v-validate="''"
                    outlined
                    dense
                    :error-messages="
                      errs.collect(`country_of_origin_${index}`)
                    "
                    :data-vv-name="`country_of_origin_${index}`"
                    :items="countries"
                    label="Country of Origin"
                    item-text="name"
                    item-value="code"
                  />
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            class="text-right"
          >
            <v-btn
              color="primary"
              small
              depressed
              class="float-right"
              @click="addItem"
            >
              Add Line
            </v-btn>
          </v-col>
        </v-row>

        <v-row
          v-if="ship"
          dense
        >
          <v-col cols="12">
            <h2>Postage</h2>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-select
              v-model="ship.postage_type_id"
              v-validate="''"
              :error-messages="errs.collect('postage_type')"
              data-vv-name="postage_type"
              :items="postageTypes"
              item-text="description"
              item-value="id"
              label="Postage Type"
              dense
              outlined
            />
          </v-col>

          <v-col
            cols="12"
            sm="6"
          >
            <v-select
              v-model="ship.lowest_postage_ids"
              v-validate="''"
              :error-messages="errs.collect('cheapest_postage')"
              data-vv-name="cheapest_postage"
              :items="postageTypes"
              item-text="description"
              item-value="id"
              label="Cheapest Postage Type"
              dense
              outlined
              multiple
            />
          </v-col>

          <v-col
            v-if="$auth.user?.enable_third_party_postage"
            cols="12"
            sm="6"
            md="3"
          >
            <v-select
              v-model="ship.needs_postage"
              v-validate="''"
              :error-messages="errs.collect('needs_postage')"
              data-vv-name="needs_postage"
              :items="[
                { value: true, text: 'Yes' },
                { value: false, text: 'No' }
              ]"
              label="Need Postage?"
              dense
              outlined
            />
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <v-select
              v-model="ship.is_fba"
              v-validate="''"
              :error-messages="errs.collect('is_fba')"
              data-vv-name="is_fba"
              :items="[
                { value: true, text: 'Yes' },
                { value: false, text: 'No' }
              ]"
              label="Is FBA?"
              dense
              outlined
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-select
              v-model="ship.signature_confirmation"
              v-validate="''"
              :error-messages="errs.collect('signature')"
              data-vv-name="signature"
              :items="[
                { value: true, text: 'Yes' },
                { value: false, text: 'No' }
              ]"
              dense
              outlined
              label="Signature "
            />
          </v-col>

          <v-col
            cols="12"
            sm="6"
            md="4"
          >
            <v-select
              v-model="ship.insured"
              v-validate="''"
              :error-messages="errs.collect('stallion_protection')"
              data-vv-name="stallion_protection"
              :items="[
                { value: true, text: 'Yes' },
                { value: false, text: 'No' }
              ]"
              dense
              outlined
              label="Stallion Protection "
            />
          </v-col>

          <!-- <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-if="ship.needs_postage == false"
                    v-model="ship.tracking_code"
                    v-validate="''"
                    :error-messages="errs.collect('tracking_code')"
                    data-vv-name="tracking_code"
                    label="Tracking Number"
                  ></v-text-field>
                </v-col> -->
        </v-row>
        
        <v-row dense>
          <v-col cols="12">
            <v-btn
              v-if="row == 'custom'"
              class="mr-2"
              color="primary"
              :disabled="preset_id == 'custom'"
              depressed
              outlined
              @click="new_dialog = true"
            >
              <v-icon small>
                mdi-content-save
              </v-icon>Save as Preset
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template #actions>
        <v-btn
          color="primary"
          class="px-7"
          :loading="loading"
          @click="updateShipments()"
        >
          Apply
        </v-btn>
      </template>
    </StallionDialog>

    <StallionDialog
      v-model="new_dialog"
      :width="500"
      title="Add Preset"
    >
      <template #content>
        <v-text-field
          v-model="preset_name"
          v-validate="'required'"
          class="mt-3"
          :error-messages="errs.collect('preset_name')"
          data-vv-name="preset_name"
          label="Preset Name"
          outlined
        />

        <!-- $hotkeyType from mixin.js  -->
        <v-text-field
          v-model.trim="new_hotkey"
          v-validate="'max:1'"
          :error-messages="errs.collect('new_hotkey')"
          data-vv-name="new_hotkey"
          :label="'Hotkey (' + $hotkeyType() + ' +)'"
          dense
          outlined
        />
      </template>

      <template #actions>
        <v-btn
          color="primary"
          :loading="loading"
          @click="createPreset()"
        >
          Save
        </v-btn>
      </template>
    </StallionDialog>
  </div>
</template>

<script>
import _find from 'lodash/find';
import _isFunction from 'lodash/isFunction';
import CONSTANTS from '@/constants';

const editedItem = {
  preset_id: null,
  package_type_id: null,
  weight: null,
  weight_unit: null,
  length: null,
  width: null,
  height: null,
  size_unit: null,
  needs_postage: null,
  postage_type_id: null,
  tracking_code: null,
  additional_insurance: null,
  signature: null,
  insured: null,
  is_fba: null,
  signature_confirmation: null,
  items: null,
  lowest_postage_ids: [],

};
import { handleLaravelValidationError } from '../../../helpers/helper';
export default {
  components: {},
  props: {
    value: {
      type: Boolean,
      default: true
    },
    shipments: {
      default: () => [],
      type: Array
    },
    dialogLabel: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      loading: false,
      preset_name: null,
      new_dialog: false,
      row: 'presets',
      ship: null,

      preset_id: null,
      new_hotkey: null,
      edit_hotkey: null,
      presets: [],
      countries: CONSTANTS.COUNTRIES
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    editable_shipments() {
      return this.shipments.filter((r) => r.status_id == 3);
    },
    bulkEditDialogLabel() {
      return this.dialogLabel
        ? this.dialogLabel
        : `Bulk Edit (${this.editable_shipments.length})`;
    },
    packageTypes() {
      return this.$store.getters['main/package_types'].map((item) => {
        item.id = parseInt(item.id);
        return item;
      });
    },
    postageTypes() {
      return this.$store.getters['main/postage_types'](true).map((item) => {
        item.id = parseInt(item.id);
        return item;
      });
    }
  },
  watch: {
    show: {
      handler(value) {
        if(value){
          this.ship = null;
          this.ship = Object.assign({}, editedItem);

          this.preset_id = null;
          this.edit_hotkey = null;
          this.new_hotkey = null;
        }
       
      }
    },
    preset_id: {
      handler() {
        this.loadPreset();
      }
    },
    row: {
      handler(newValue) {
        if (newValue == 'custom') {
          this.reset();
        }
      }
    }
  },
  mounted() {
    this.getPresets();
  },

  methods: {
    /**
     * Will apply preset by selecting from dropdown
     * Will be called by the parent component via $refs
     * ex: QuickShipFormDialog@applyPresetOnDropdown
     */
    applyPresetOnDropdown(shipments, preset, callback) {
      // trigger the watcher to load the preset
      this.shipments = shipments
      this.ship = Object.assign({}, editedItem);
      this.preset_id = preset.id;

      this.$nextTick(function () {
        this.updateShipments(callback);
      })
    },

    /**
     * Will apply preset by pressing a hotkey
     * Will be called by the parent component via $refs
     * ex: QuickShipFormDialog@initializeHotkeys
     */
    applyPresetOnHotkey(preset) {
      // trigger the watcher to load the preset
      this.preset_id = preset.id;

      // add delay to load the preset before updating the shipment
      setTimeout(() => {
        this.updateShipments();
      }, 100);
    },

    /**
     * Apply the selected preset and update the shipment
     */
    updateShipments(callback) {
      const ids = this.editable_shipments.map((a) => a.id);

      this.loading = true;
      this.$http
        .post('/shipments/bulk-update', {
          ids,
          values: this.ship
        })
        .then(() => {
          // emit the update shipment handler of the parent component
          // ex: QuickShipFormDialog@onApplyPresets
          this.$emit('update', {
            shipment: this.ship,
            preset: _find(this.presets, { id: this.preset_id })
          });

          this.close();
        })
        .catch((err) => {
          handleLaravelValidationError(err);
        })
        .finally(() => {
          this.loading = false;

          if (_isFunction(callback)) {
            callback();
          }
        });
    },

    reset() {
      this.ship = Object.assign({}, CONSTANTS.DEFAULT_PRESET_ITEM);
      this.$validator.reset();
      this.postage_rates = [];
      this.preset_id = null;
      this.edit_hotkey = null;
      this.new_hotkey = null;
    },

    close() {
      this.reset();
      this.show = false;
    },

    /**
     * IMPORT PRESETS
     * below here contains all the methods for the presets
     */
    async getPresets() {
      await this.$http
        .get(`/import/presets`)
        .then((response) => response.data)
        .then((response) => {
          this.presets = response.presets;
          this.correctPresetsDataTypes();

          // emit event of the parent component
          // ex: QuickShipFormDialog@initializeHotkeys
          this.$emit('initializeHotkeys', this.presets);
        });
    },

    /**
     * Correct presets data types,
     * Only needed after fetch from endpoint
     * as some of data types might be on string
     * instead of it's correct data tyoes
     */
    correctPresetsDataTypes() {
      for (var index in this.presets) {
        if (Object.prototype.hasOwnProperty.call(this.presets, index)) {
          var corrected = this.correctPropertiesTypes(this.presets[index]);
          this.presets[index] = corrected;
        }
      }
    },

    /**
     * Correct single data types
     *
     * @param      {any}  _object  The object
     * @return     {any}  Corrected data types
     */
    correctPropertiesTypes(_object) {
      for (var property in _object) {
        if (Object.prototype.hasOwnProperty.call(_object, property)) {
          var val = _object[property];
          /**
           * in case there are still data types malfunctions
           * add corrector here
           */
          if (val == 'true' || val == 'false') {
            _object[property] = val === 'true';
          }

          if (Number(val) == val) {
            _object[property] = Number(val);
          }
        }
      }
      return _object;
    },

    /**
     * Apply a preset.
     */
    loadPreset() {
      if (!this.preset_id) return;

      const id = this.preset_id;
      const index = this.presets.findIndex((x) => x.id === id);
      const preset = this.presets[index];
      this.edit_hotkey = preset.hotkey;
      this.preset_name = preset.preset_name;

      const d = Object.assign({}, CONSTANTS.DEFAULT_PRESET_ITEM);
      this.ship = Object.assign(d, preset.data);
    },

    async createPreset() {
      if (!(await this.$validateAll())) {
        return;
      }

      try {
        const requestData = {
          preset_name: this.preset_name,
          hotkey: this.new_hotkey,
          data: this.ship
        };

        this.loading = true;

        const response = await this.$http.post('/import/preset', requestData);

        this.$notify({
          group: 'main',
          title: 'Preset Saved',
          text: 'This preset has been saved from our system.',
          duration: 5000,
          type: 'success'
        });

        await this.getPresets();

        this.row = 'presets';
        this.preset_id = response.data.preset.id;
        this.edit_hotkey = response.data.preset.hotkey;
        this.new_dialog = false;
      } catch (err) {
        let errMsg = 'We could not update this preset.';
        if (err.response && err.response.status === 422 && err.response.data.message) {
          errMsg = err.response.data.message;
        }

        this.$notify({
          group: 'main',
          title: 'Error saving preset',
          text: errMsg,
          duration: 5000,
          type: 'error'
        });
      } finally {
        this.loading = false;
      }
    },

    async updatePreset() {
      try {
        if (!(await this.$validateAll())) {
          throw new Error('Some of the fields are invalid!');
        }

        this.loading = true;

        await this.$http.put(`/import/preset/${this.preset_id}`, {
          data: this.ship,
          hotkey: this.edit_hotkey,
          preset_name: this.preset_name
        });

        await this.getPresets();

        this.successMessage('This preset has been updated.');
      } catch (err) {
        let errMsg = 'We could not update this preset.';
        if (err.response && err.response.status === 422 && err.response.data.message) {
          errMsg = err.response.data.message;
        }

        this.$notify({
          group: 'main',
          title: 'Error saving preset',
          text: errMsg,
          duration: 5000,
          type: 'error'
        });
      } finally {
        this.loading = false;
      }
    },

    deletePreset() {
      this.loading = true;
      this.$http
        .delete(`/import/preset/${this.preset_id}`)
        .then((response) => response.data)
        .then(() => {
          this.successMessage('This preset has been removed from our system.');
          this.getPresets();
          this.preset_id = null;
          this.edit_hotkey = null;
          this.ship = Object.assign({}, CONSTANTS.DEFAULT_PRESET_ITEM);
        })
        .catch(() => {
          this.errorMessage('We could not remove this preset.');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    removeItem(index) {
      this.ship.items.splice(index, 1);
    },
    addItem() {
      if(this.ship.items === null)
      {
        this.ship.items = [];
      } 
      this.ship.items.push({ ...CONSTANTS.SHIPMENT_ITEM });
    }
  }
};
</script>

<style scoped>
h2 {
  margin-bottom: 0px !important;
}

h4 {
  margin-bottom: 0px !important;
}
</style>
