<template>
  <div class="mt-2">
    <v-menu
      bottom
      open-on-click
      offset-y
      :rounded="rounded"
    >
      <template #activator="{ on }">
        <v-btn
          :ripple="false"
          avatar
          depressed
          small
          class="pa-1 ml-1 no-hover"
          style="background-color: #fff !important"
          v-on="on"
        >
          <v-avatar
            class="mr-2"
            color="#455a64"
            size="32"
          >
            <span
              class="white--text text-h3"
              style="font-size: 14px !important"
            >{{ $store.state.main.initials }}</span>
          </v-avatar>
          <v-icon
            color="#454545"
            small
          >
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </template>

      <v-list
        v-for="(group, index) in user_menu"
        :key="index"
        class="pa-0"
        :min-width="180"
        :rounded="rounded"
        :dense="dense"
      >
        <div
          v-for="item in group"
          :key="item.text"
        >
          <stallion-list-item
            :show-disabled="false"
            :icon-position="'right'"
            v-bind="item"
          />
        </div>

        <v-divider />
      </v-list>
    </v-menu>
    <UserFeedbackDialog v-model="show_feedback_dialog" />
  </div>
</template>

<script>
import StallionListItem from '../ListItems/StallionListItem.vue';
import PERMISSIONS from '@/constants/client-permissions';
export default {
  components: { StallionListItem },
  props: {},
  data() {
    return {
      show_feedback_dialog: false,
      dense: false,
      rounded: false,
      user_menu: [
        [
          {
            icon: null,
            to: {
              name: 'dashboard'
            },
            text: 'Dashboard',
            permission: null
          },
          {
            icon: null,
            to: {
              name: 'credits'
            },
            text: 'Credits'
          },
          {
            icon: null,
            to: {
              name: 'statements'
            },
            text: 'Statements'
          },
          {
            icon: null,
            to: {
              name: 'analytics'
            },
            text: 'Analytics'
          },

          {
            icon: null,
            to: {
              name: 'referrals'
            },
            text: 'Referrals'
          },
          {
            icon: null,
            to: {
              name: 'settings'
            },
            text: 'Settings'
          },


        ],
        [
          {
            icon: 'mdi-launch',
            href: 'https://help.stallionexpress.ca/',
            text: 'Help Center',
            permission: PERMISSIONS.DEFAULT_ACCESS
          },
          {
            icon: 'mdi-launch',
            href: 'https://stallionexpress.ca/locations/',
            text: 'Locations',
            permission: PERMISSIONS.DEFAULT_ACCESS
          },
          {
            icon: null,
            text: 'Report Issue',
            permission: PERMISSIONS.DEFAULT_ACCESS,
            click: () => {
              this.show_feedback_dialog = true;
            }
          }
        ],
        [
          {
            icon: null,
            to: {
              name: 'admin-dashboard'
            },
            text: 'Admin Panel'
          },
          {
            icon: null,
            click: this.logout,
            text: 'Logout',
            permission: PERMISSIONS.DEFAULT_ACCESS
          },

        ]
      ]
    };
  }
};
</script>
