<template>
  <div
    class="container"
  >
    <notifications
      group="announcement"
      position="top center"
      animation-type="velocity"
      :width="$vuetify.breakpoint.smAndDown ? '300px' : '600px'"
      :ignore-duplicates="true"
      :close-on-click="true"
    >
      <template
        slot="body"
        slot-scope="props"
      >
        <div class="stallion-announcement">
          <v-row>
            <v-col
              class="icon-container col-12 col-md-3"
            >
              <v-icon
                class="icon"
                x-large
              >
                mdi-bell-alert-outline
              </v-icon>
            </v-col>
            <v-col
              class="col-12 col-md-9"
              :style="$vuetify.breakpoint.smAndDown ? 'text-align: center' : ''"
            >
              <h3>
                {{ props.item.title }}
              </h3>

              <div
                class="d-block"
                v-html="getText(props.item.text)"
              />
              <v-btn
                small
                depressed
                style="margin-top: 20px"
                :color="'#f9ac40'"
                @click="announcementClicked(props.item.data.id)"
              >
                <span style="color: white">Ok</span>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </template>
    </notifications>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newPostTitle: '',
      newPostDesc: ''
    };
  },
  computed: {
    announcements: function(){
      return this.$store.getters['main/announcements'];
    }
  },
  watch: {
    'announcements' : function(newVal){
      if (newVal.unread.length == 0) {
        this.$notify({
          group: 'announcement',
          clean: true
        });
      } 
    }
  },
  created() {
    this.listenForAnnouncements();
  },
  methods: {
    getText(text){
      const bbCodeRegex = /\[url=(https?:\/\/|)([^\s]+)\](.*?)\[\/url\]/g;
      const urlRegex = /(?<!<a[^>]*?>)(https?:\/\/[^\s]+)(?![^<]*?<\/a>)/g;

      // Replace BBCode URLs, conditionally adding "http://" if no protocol is provided
      let html = text.replace(bbCodeRegex, (match, protocol, link, text) => {
        const href = protocol ? `${protocol}${link}` : `http://${link}`;
        return `<a target="_blank" href="${href}" style="color: #f9ac40">${text}</a>`;
      });

      // Replace remaining plain URLs only if they are not already inside <a> tags
      html = html.replace(urlRegex, '<a target="_blank" href="$1" style="color: #f9ac40">$1</a>');

      // Convert newlines to <br>
      html = html.replace(/(\n)/g, '<br>');

      return html;
    },
    listenForAnnouncements() {
      Event.listen('clear-announcements', () => {
        this.$notify({
          group: 'announcement',
          clean: true
        });
      })

    },
    async announcementClicked(id) {
      await this.$http.put('/announcements/acknowledge/' + id);
      const announcements = this.$store.getters['main/announcements'];

      const index = announcements.unread.findIndex((a) => a.id == id);
      announcements.unread.splice(index, 1);

      this.$notify({
        group: 'announcement',
        clean: true
      });

      this.$store.commit('main/updateAnnouncements', announcements);

      Event.fire('display-announcement');
    }
  }
};
</script>
