<template>
  <v-main style="height: 100%; background-color: rgba(249,172,64, .3);">
    <v-container
      container--fluid
      fill-height
    >
      <v-layout
        align-center
        justify-center
      >
        <v-card
          class="elevation-6"
          width="400px"
        >
          <v-card-text>
            <v-col
              cols="12"
              class="mb-5"
            >
              <v-row
                align-center
                justify-center
              >
                <v-col
                  xs8
                  sm8
                >
                  <img
                    class="mt-5 mx-auto d-block img-responsive"
                    :src="asset($store.state.main.authLogo)"
                    style="width: 80%"
                  >
                </v-col>
              </v-row>
            </v-col>
            <slot />
          </v-card-text>
        </v-card>
      </v-layout>
    </v-container>
  </v-main>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},

  methods: {}
};
</script>
