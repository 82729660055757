import axios from 'axios';

function initialState() {
  return {
    templates: [],
    loading: false
  };
}

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    templates: (state) => {
      return state.templates;
    }
  },
  actions: {
    getTemplates({ commit, state }) {
      state.loading = true;
      axios
        .get('/packing-slip-templates')
        .then((response) => {
          commit('updateTemplates', response.data);
        })
        .finally(() => {
          state.loading = false;
        });
    },
    async deleteTemplate({ dispatch }, id) {
      await axios.request(`/packing-slip-templates/${id}`, {
        method: 'delete'
      });
      dispatch('getTemplates');
    }
  },
  mutations: {
    reset(state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    updateTemplates(state, templates) {
      state.templates = templates;
    }
  }
};
