<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h2 class="font-weight-bold">
          Analytics
        </h2>
      </v-col>
    </v-row>

    <v-row v-show="!loading">
      <v-col>
        <div id="embeddingContainer" />
      </v-col>
    </v-row>

    <v-dialog
      v-model="loading"
      persistent
      width="300"
    >
      <v-card dark>
        <v-card-text>
          <v-layout
            justify-center
            class="pt-3"
          >
            Loading analytics dashboard...
            <atom-spinner
              :animation-duration="2000"
              :size="100"
              :color="'#f9ac40'"
            />
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';
import { AtomSpinner } from 'epic-spinners';
export default {
  metaInfo: {
    title: "Analytics | Stallion Express"
  },
  components: {
    AtomSpinner
  },
  data() {
    return {
      dashboard: null,
      loading: false
    };
  },
  created() {
    this.getUrl();
  },
  methods: {
    getUrl() {
      this.loading = true;

      this.$http
        .get(`/quicksight-url`)
        .then((response) => response.data)
        .then((response) => {
          const token = response;

          var options = {
            url: token,
            container: '#embeddingContainer',
            scrolling: 'auto',
            footerPaddingEnabled: true,
            height: 'AutoFit',
            iframeResizeOnSheetChange: true // use this option in combination with height: AutoFit, to allow iframe height to resize dynamically, based on sheet height, on changing sheets.
            // width: "1000px",
          };

          this.dashboard = embedDashboard(options);

          this.dashboard.on('load', () => {
            this.loading = false;
          });

          this.dashboard.on('error', () => {
            alert('Error loading analytics');
            this.loading = false;
          });
        })
        .catch(() => {
          this.loading = false;
        })
        .finally(() => {});
    }
  }
};
</script>
