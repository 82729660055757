<template>
  <StallionDialog
    v-model="show"
    :width="500"
    persistent
    :title="dialogTitle"
    @onDialogClosed="close"
  >
    <template #content>
      <v-layout wrap>
        <!-- Printer -->
        <v-flex xs12>
          <v-select
            v-model="localItem.printer"
            :items="printers"
            label="Printer"
          >
            <template #append-outer>
              <v-icon @click="fetchPrinterList">
                mdi-refresh
              </v-icon>
            </template>
          </v-select>
        </v-flex>
  
        <!-- Document Format -->
        <v-flex xs12>
          <v-select
            v-model="localItem.docFormat"
            :items="docFormats"
            label="Document Format"
          />
        </v-flex>
  
        <!-- Print Action -->
        <v-flex xs12>
          <v-select
            v-model="localItem.printAction"
            :items="printActions"
            label="Print Action"
          />
          <!-- <div class="text-caption">
            {{ printActionHint(localItem.printAction) }}
          </div> -->
        </v-flex>
  
        <!-- Density -->
        <v-flex xs12>
          <v-select
            v-model="localItem.density"
            :items="densities"
            label="Density (DPI)"
          />
        </v-flex>
  
        <!-- Packing Slip Template (Conditional) -->
        <v-flex
          v-if="hasPackingSlipTemplate"
          xs12
        >
          <v-select
            v-model="localItem.packing_slip_template_id"
            name="template"
            label="Packing Slip Template"
            :items="packingSlipTemplates"
            item-text="name"
            item-value="id"
            :clearable="true"
            placeholder="Default Packing Slip"
            persistent-placeholder
          />
        </v-flex>
  
        <!-- Sort By -->
        <v-flex xs12>
          <v-select
            v-model="localItem.sort_by"
            label="Sort By"
            :items="sortItems"
          />
        </v-flex>
  
        <!-- Sort Order -->
        <v-flex xs12>
          <v-select
            v-model="localItem.sort_order"
            label="Sort Order"
            :items="sortOrderItems"
          />
        </v-flex>
  
        <!-- Group by Filter (Conditional) -->
        <v-flex
          v-if="hasGroupByFilter"
          xs12
        >
          <v-switch
            v-model="localItem.group_by_filter"
            label="Group by Filter"
          />
        </v-flex>
      </v-layout>
    </template>
  
    <template #actions>
      <v-btn
        color="primary"
        class="px-4"
        @click="saveSettings"
      >
        <v-icon
          small
          dark
        >
          mdi-content-save
        </v-icon>
        Save
      </v-btn>
    </template>
  </StallionDialog>
</template>
  <script>
  import PRINTER from "@/constants/printer";
  
  const SORT_ORDER_ITEMS = [
    { text: 'Descending', value: 'desc' },
    { text: 'Ascending', value: 'asc' },
  ];
  
  export default {
    name: 'GenericSettingsDialog',
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      docType: {
        type: String,
        required: true,
      },
      item: {
        type: Object,
        required: true,
      },
    },
  
    data() {
      return {
        localItem: {},
        printers: [],
        docFormats: Object.values(PRINTER.DIMENSIONS),
        printActions: Object.values(PRINTER.PRINT_ACTION),
        densities: Object.values(PRINTER.DENSITIES),
        sortOrderItems: SORT_ORDER_ITEMS,
      };
    },
  
    computed: {
      show: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        },
      },
      dialogTitle() {
        const titles = {
          [PRINTER.DOCUMENT_FORMATS.LABEL]: 'Label Settings',
          [PRINTER.DOCUMENT_FORMATS.PACKING_SLIP]: 'Packing Slip Settings',
          [PRINTER.DOCUMENT_FORMATS.LABEL_WITH_PACKING_SLIP]: 'Label & Packing Slip Settings',
          [PRINTER.DOCUMENT_FORMATS.PICK_LIST]: 'Pick List Settings',
        };
        return titles[this.docType] || 'Settings';
      },
      hasGroupByFilter() {
        return [
          PRINTER.DOCUMENT_FORMATS.LABEL,
          PRINTER.DOCUMENT_FORMATS.LABEL_WITH_PACKING_SLIP,
        ].includes(this.docType);
      },
      hasPackingSlipTemplate() {
        return [
          PRINTER.DOCUMENT_FORMATS.PACKING_SLIP,
          PRINTER.DOCUMENT_FORMATS.LABEL_WITH_PACKING_SLIP,
        ].includes(this.docType);
      },
      packingSlipTemplates() {
        return this.$store.getters['packingSlipTemplates/templates'];
      },
      sortItems() {
        // Define different SORT_ITEMS based on docType
        switch (this.docType) {
          case PRINTER.DOCUMENT_FORMATS.PICK_LIST:
            return [
              { text: 'Created At', value: 'id' },
              { text: 'Order ID', value: 'order_id' },
              { text: 'SKU', value: 'sku' },
            ];
          default:
            return [
              { text: 'Created At', value: 'id' },
              { text: 'Order ID', value: 'order_id' },
              { text: 'SKU', value: 'sku' },
              { text: 'Recipient', value: 'name' },
              { text: 'Import Order', value: 'row' },
            ];
        }
      },
    },
  
    watch: {
      item: {
        immediate: true,
        handler(newItem) {
          this.localItem = { ...newItem };
        },
      },
      show(value) {
        if (value) {
            this.fetchPrinterList();
        }
      },
    },
  
    mounted() {
      this.fetchPrinterList();
    },
  
    methods: {
      saveSettings() {
        // Emit the updated item
        this.$emit('save', this.localItem);
        this.close();
      },
      close() {
        this.show = false;
      },
      async fetchPrinterList() {
        if (this.isQzInitialized()) {
          this.printers = await this.fetchConnectedPrinters();
        }
        this.printers.unshift('Default');
      },
      printActionHint(printAction) {
        const hints = {
          [PRINTER.PRINT_ACTION.DIRECT]:
            "Print directly to your printer via QZ Tray with your default sort settings",
          [PRINTER.PRINT_ACTION.PROMPT]:
            "Prompt to download or print the document",
          [PRINTER.PRINT_ACTION.DOWNLOAD]:
            "Download the document to your computer",
        };
        return hints[printAction] || "No print action selected";
      },
    },
  };
  </script>
  