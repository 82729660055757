<template>
  <div>
    <v-row
      v-if="item"
      dense
    >
      <v-col cols="12">
        <div class="d-flex flex-row justify-space-between">
          <h2>Return</h2>
          <router-link
            v-if="isAdminPanel"
            class="float-right ml-auto"
            router
            :to="{
              name: 'return-history',
              params: { id: item.id, barcode: item.barcode }
            }"
            target="_blank"
          >
            View History
          </router-link>
        </div>

        <div class="d-flex flex-row justify-space-between">
          <div class="shipment-title">
            Return ID
          </div>
          <div>{{ item.unique_id }}</div>
        </div>
        <div
          v-if="item.item"
          class="d-flex flex-row justify-space-between"
        >
          <div class="shipment-title">
            Status
          </div>
          <div v-html="$options.filters.return_status(item.status_id)" />
        </div>

        <div
          v-if="item.item"
          class="d-flex flex-row justify-space-between"
        >
          <div class="shipment-title">
            Service
          </div>
          <div v-html="$options.filters.return_service(item.service_id)" />
        </div>

        <div
          v-if="item.order_id"
          class="d-flex flex-row justify-space-between"
        >
          <div class="shipment-title">
            Order ID
          </div>
          <div>{{ item.order_id }}</div>
        </div>

        <div
          v-if="item.trip_number && can('access.admin')"
          class="d-flex flex-row justify-space-between"
        >
          <div class="shipment-title">
            Trip Number
          </div>
          <div>
            <router-link
              :to="{
                name: 'admin-return-trip-show',
                trip_number: { id: item.trip_number }
              }"
            >
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <span v-on="on">{{ item.trip_number }}</span>
                </template>
                <span>View Trip</span>
              </v-tooltip>
            </router-link>
          </div>
        </div>

        <div
          v-if="item.cargo_control_number && can('access.admin')"
          class="d-flex flex-row justify-space-between"
        >
          <div class="shipment-title">
            Cargo Number
          </div>
          <div>
            {{ item.cargo_control_number }}
          </div>
        </div>
        
        <div
          v-if="item.transactions && item.transactions.length > 0"
          class="text-center mt-4 mb-4"
        >
          <router-link

            router
            :to="{
              name: 'transactions',
              params: {
                transactional_id: item.order_id,
                transactional_type: 'return'
              }
            }"
          >
            View Transactions
          </router-link>
        </div>

        <v-divider class="mt-2" />
      </v-col>

      <v-col v-if="item.tracking_number">
        <div>
          <h4>
            Tracking Details
          </h4>
          <div class="d-flex flex-row justify-space-between">
            <div class="shipment-title">
              Number
            </div>
            <div>{{ item.tracking_number }}</div>
          </div>
        </div>
        <v-divider class="mt-2" />
      </v-col>

      <v-col
        v-if="item.shipment"
        cols="12"
      >
        <div>
          <h4>
            Shipment Details
          </h4>
          <div
            v-if="item.shipment.ship_code"
            class="d-flex flex-row justify-space-between"
          >
            <div class="shipment-title">
              Shipment ID
            </div>
            <div>{{ item.shipment.ship_code }}</div>
          </div>
          <div
            v-if="item.shipment.order_id"
            class="d-flex flex-row justify-space-between"
          >
            <div class="shipment-title">
              Order ID
            </div>
            <div>{{ item.shipment.order_id }}</div>
          </div>
        </div>
        <v-divider class="mt-2" />
      </v-col>

      <v-col cols="12">
        <div>
          <h4>
            Branch Details
          </h4>
          <div v-if="item.current_location">
            <span class="shipment-title">Current Branch </span>
            <span class="float-right">{{ item.current_location.name }}</span>
          </div>
          <div v-if="item.destination_location">
            <span class="shipment-title">Destination Branch </span>
            <span class="float-right">{{
              item.destination_location.name
            }}</span>
          </div>
        </div>
        <v-divider class="mt-2" />
      </v-col>
      <v-col cols="12">
        <h4>
          Contents
        </h4>
        <div
          v-if="item.barcode"
          class="d-flex flex-row justify-space-between"
        >
          <div class="shipment-title">
            Barcode
          </div>
          <div>
            {{ item.barcode }}
          </div>
        </div>

        <div
          v-if="item.weight"
          class="d-flex flex-row justify-space-between"
        >
          <div class="shipment-title">
            Weight
          </div>
          <div>{{ item.weight }}{{ item.weight_unit }}</div>
        </div>

        <div
          v-if="item.value && item.value !== $auth.user?.returns_default_value"
          class="d-flex flex-row justify-space-between"
        >
          <div class="shipment-title">
            Value
          </div>
          <div>
            {{ item.value | currency }}
          </div>
        </div>

        <div
          v-if="
            item.item && item.item !== $auth.user?.returns_default_contents
          "
          class="mt-3 d-flex flex-row justify-space-between"
        >
          <div>
            {{ item.item }}
          </div>
        </div>

        <v-divider class="mt-2" />
      </v-col>

      <v-col
        v-if="item.content_images.length > 0"
        cols="12"
      >
        <h4>Photos</h4>
        <v-col
          v-for="(image, index) in item.content_images"
          :key="index"
        >
          <v-tooltip bottom>
            <template #activator="{ on }">
              <a
                :href="image.full_image_url"
                target="_blank"
                v-on="on"
              >
                <v-card hover>
                  <v-img
                    contain
                    :src="image.full_image_url"
                    class="elevation-0"
                  >
                    <v-row
                      slot="placeholder"
                      fill-height
                      align-center
                      justify-center
                      ma-0
                    >
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      />
                    </v-row>
                  </v-img>
                </v-card>
              </a>
            </template>
            <span>View Image</span>
          </v-tooltip>
        </v-col>
      </v-col>

      <v-col
        v-else-if="canRequestImages && !isAdminPanel"
        cols="12"
        class="text-center"
      >
        <v-menu offset-y>
          <template #activator="{ on }">
            <v-btn
              v-if="item.images_required"
              outlined
              color="primary"
              v-on="on"
              @click="$refs.requestAdditionalPhotos.openDialog()"
            >
              Photos Requested
            </v-btn>

            <v-btn
              v-else-if="item.content_images.length == 0"
              outlined
              color="primary"
              v-on="on"
              @click="$refs.requestAdditionalPhotos.openDialog()"
            >
              Request Images
            </v-btn>
            <v-btn
              v-else
              outlined
              color="primary"
              v-on="on"
              @click="$refs.requestAdditionalPhotos.openDialog()"
            >
              Content Photos
            </v-btn>
          </template>
        </v-menu>
      </v-col>
      <v-col
        v-else-if="canRequestImages && isAdminPanel"
        cols="12"
        class="text-center"
      >
        <v-alert
          dense
          text
          type="info"
        >
          No requested Images
        </v-alert>
      </v-col>
      <v-col
        v-else
        cols="12"
        class="text-center"
      >
        <v-alert
          dense
          text
          type="success"
        >
          Images requested
        </v-alert>
        <v-menu v-if="isAdminPanel">
          <template #activator="{ on }">
            <v-btn
              class="ml-3"
              large
              color="primary"
              v-on="on"
              @click="$refs.uploadImages.openDialog()"
            >
              Add images
            </v-btn>
          </template>
        </v-menu>

        <upload-images
          ref="uploadImages"
          :shipment-return="item"
          :image-uploaded="imageUploaded"
        />
      </v-col>
    </v-row>
    <v-row
      v-else
      wrap
      pa-3
    >
      <v-col cols="12">
        <div
          class="text-center"
          style="padding: 100px 0 100px 0;"
        >
          <h2>{{ returns.length }} returns selected</h2>
        </div>
      </v-col>
    </v-row>

    <request-additional-photos
      v-if="item"
      ref="requestAdditionalPhotos"
      :cost="image_cost"
      :return-shipment="item"
      :update-image-status="updateImageStatus"
    />
  </div>
</template>

<script>
export default {
  props: {
    returns: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      item: null,
      loading: false,
      image_cost: null
    };
  },
  computed: {
    canRequestImages: function() {
      if (this.item) {
        return !this.item.images_required && !this.item.content_images.length;
      }
      return false;
    },
    isAdminPanel: function() {
      return this.$router.history.current.meta?.admin;
    }
  },
  watch: {
    returns: {
      immediate: true,
      handler(value) {
        if (value.length === 1) {
          console.log('tom test');
          this.item = this.returns[0];
          this.getReturn();
        } else {
          this.item = null;
        }
      }
    }
  },
  mounted(){
    this.getImageCost();
  },
  methods: {
    updateImageStatus() {
      if (this.item) {
        this.item.images_required = true;
      }
    },
    imageUploaded() {
      if (this.item) {
        this.item = null;
      }
    },
    getReturn() {
      this.cancelSearch();
      this.cancelSource = axios.CancelToken.source();
      this.item = null;
      this.loading = true;
      this.$http
        .get(`/returns/${this.returns[0].id}`, {
          cancelToken: this.cancelSource.token
        })
        .then(response => response.data)
        .then(response => {
          this.item = response;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancelSearch() {
      if (this.cancelSource) {
        this.cancelSource.cancel('Start new search, stop active search');
        console.log('cancel request done');
      }
    },
    getImageCost() {
      this.$http
      .get('returns/image-cost')
      .then(response => response.data)
      .then(response => {
        if (response.success) {
          this.image_cost = response.price;
        }
      });
    }
  }
};
</script>

<style scoped>
.shipment-title {
  color: #9d9da6;
  font-size: 12px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.8px;
}
</style>
