<template>
  <v-row dense>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :loading="loading"
        item-key="id"
        :items="items"
        :options.sync="options"
        class="table-striped"
        :server-items-length="totalItems"
        :footer-props="{
          'items-per-page-options': [50, 100, 200, 500]
        }"
        :items-per-page="25"
      >
        <template #item.amount="{ item }">
          {{ item.amount | currency }}
        </template>

        <template #item.period="{ item }">
          {{ item.start_date }} - {{ item.end_date }}
        </template>

        <template #item.paid="{ item }">
          <v-icon
            v-if="item.paid"
            color="green"
          >
            mdi-check-circle 
          </v-icon>
        </template>
        
        <!-- <template #item.paid_at="{ item }">
          <span v-if="item.paid_at">
            {{ item.paid_at | date_pretty }}
          </span>
        </template> -->

        <template #item.action="{ item }">
          <v-btn
            color="primary"
            download
            small
            @click.prevent="download(item.invoice_path)"
          >
            <v-icon
              small
              dark
            >
              mdi-download
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>
  
<script>

export default {
  data() {
    return {
      totalItems: 0,
      items: [],

      loading: false,
      search: null,

      timer: null,
      cancelSource: null,

      headers: [
        {
          text: 'ID',
          align: 'left',
          value: 'invoice_id',
          sortable: true
        },
        {
          text: 'Total',
          align: 'left',
          value: 'amount',
          sortable: true
        },
        {
          text: 'Period',
          align: 'left',
          value: 'period',
          sortable: false,
        },
        {
          text: 'Payout Method',
          align: 'left',
          value: 'payout_method',
          sortable: true
        },
        {
          text: 'Paid',
          align: 'left',
          value: 'paid',
          sortable: true
        },
        // {
        //   text: 'Paid At',
        //   align: 'left',
        //   value: 'paid_at',
        //   sortable: true
        // },
        {
          text: '',
          align: 'left',
          value: 'action',
          sortable: false
        }
      ],

      options: {
        sortBy: ['created_at'],
        sortDesc: [true]
      },
    }
  },

  watch: {
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    }
  },
  mounted() {
    this.getDataFromApi()
  },
  
  methods:{
    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.options.page = 1;
      this.timer = setTimeout(function () {
        self.updateTable();
      }, ms);
    },

    updateTable() {
      this.totalItems = 0;
      this.items = [];

      this.getDataFromApi().then((data) => {
        this.items = data.items;
        this.totalItems = data.total;
      });
    },

    getDataFromApi() {
      this.loading = true;
      return new Promise((resolve) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let items = [];
        let total = 0;
        let pages = 0;

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        var params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sortBy[0],
          desc: sortDesc[0],
          user_id: this.user?.id,
          search: this.search,
          is_active: this.is_active
        };

        this.$http
          .request(`/commission-payouts`, {
            method: 'get',
            params: params,
            cancelToken: this.cancelSource.token
          })

          .then((response) => response.data)
          .then((response) => {
            items = response.data;
            total = response.total;
            pages = response.last_page;

            this.loading = false;
            resolve({
              items,
              total,
              pages
            });
          });
      });
    },

    download(file) {
      this.$http({
        method: 'post',
        url: '/commission-payouts/invoices/download',
        data: {
          file: file
        }
      }).then(response => {
        this.downloadFileUrl(response.data,file);
      });
    }
  }
};
</script>
