const SCAN_TO_PRINT = 'Scan to Print';
const SCAN_TO_VERIFY = 'Scan to Verify';
const SCAN_TO_VERIFY_AND_PRINT = 'Scan to Verify & Print';

export default {
  SCAN_TO_PRINT: SCAN_TO_PRINT,
  SCAN_TO_VERIFY: SCAN_TO_VERIFY,
  SCAN_TO_VERIFY_AND_PRINT: SCAN_TO_VERIFY_AND_PRINT,
  SCAN_WORKFLOW: [
    SCAN_TO_PRINT,
    SCAN_TO_VERIFY,
    SCAN_TO_VERIFY_AND_PRINT
  ],
  VERIFY_SCAN_WORKFLOW: [
    SCAN_TO_VERIFY,
    SCAN_TO_VERIFY_AND_PRINT
  ],
  PRINT_SCAN_WORKFLOW: [
    SCAN_TO_PRINT,
    SCAN_TO_VERIFY_AND_PRINT
  ]
};
