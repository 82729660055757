<template>
  <v-dialog
    v-model="show"
    :fullscreen="showFullscreen"
    :width="width"
    :hide-overlay="showFullscreen"
    :transition="false"
    persistent
  >
    <v-card>
      <div
        v-if="loading == true"
        class="loading-overlay"
      >
        <div class="loading-content">
          <v-progress-circular
            :size="100"
            :width="10"
            indeterminate
            color="primary"
          />
        </div>
      </div>
  
      <v-toolbar
        v-else
        fixed
        dark
        color="primary"
        class="elevation-2 px-3"
      >
        <v-toolbar-title>
          {{ title }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          dark
          @click="show = null; $emit('onDialogClosed')"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
  
     
      <v-container
        :fluid="fluid"
        :class="fluid ? 'pa-0' : 'pa-5'"
      >
        <slot name="content" />
      </v-container>
      <v-container
        v-if="showActions"
        :fluid="fluid"
        class="px-6 py-4"
      >
        <v-divider class="my-3" />
      
        <div
          :class="[
            $vuetify.breakpoint.mdAndUp ? 'actions' : 'actions-mobile'
          ]"
        >
          <v-btn
            v-if="$vuetify.breakpoint.mdAndUp"
            color="primary"
            text
            @click="show = null; $emit('onDialogClosed')"
          >
            {{ cancelLabel }}
          </v-btn>
          <v-spacer />
          <slot name="actions" />
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>
  
<script>
  export default {
    props: {
      title: {
        type: String,
        default: ''
      },
      loading: {
        type: Boolean,
        default: false
      },
      value: {
        type: Boolean,
        default: false
      },
      fullscreen: {
        type: Boolean,
        default: false
      },
      width: {
        type: Number,
        default: 500
      },
      fluid: {
        type: Boolean,
        default: false
      },
      showActions: {
        type: Boolean,
        default: true
      },
      cancelLabel: {
        type: String,
        default: 'Cancel'
      }
    },
    computed: {
      show: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        }
      },
      showFullscreen() {
        return (
          this.$vuetify.breakpoint.mdAndUp === false || this.fullscreen === true
        );
      }
    },
  
    methods: {}
  };
  </script>
  <style>
  .actions {
    display: flex;
  }
  
  .actions .v-btn + .v-btn {
    margin-left: 10px !important;
  }
  
  .actions-mobile .v-btn {
    margin-bottom: 10px !important;
    display: flex;
    flex: 1 0 auto;
    min-width: 100% !important;
    max-width: none;
  }
  </style>  