<template>
  <div style="padding: 15px;">
    <v-row
      align="center"
    >
      <v-btn
        :loading="loading"  
        depressed
        style="cursor: pointer;"
        @click="updateSelected"
      >
        <span style="padding: 10px; display: inline-flex; align-items: center;">
          <span v-if="selectedOrdersCount > 0">
            <v-icon color="red">remove</v-icon>
            <span style="margin-left: 5px;">{{ selectedOrdersCount }} selected</span>
          </span>
          <span v-else>
            <v-icon color="primary">add</v-icon>
            <span style="margin-left: 5px;">{{ selectedOrdersCount }} selected</span>
          </span>
        </span>
      </v-btn>
      <v-btn
        :loading="loading"
        :disabled="shouldDisableMergeButton"
        color="primary"
        depressed
        @click="mergeOrders"
      >
        <v-icon>merge</v-icon>Merge
      </v-btn>
      <v-btn
        :loading="loading"
        depressed
        @click="showSettings"
      >
        <v-icon>settings</v-icon>
        Settings
      </v-btn>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-simple-table dense>
          <template #default>
            <thead>
              <tr>
                <th />
                <th>Order Number</th>
                <th>Total</th>
                <th># of items</th>
                <th>Total Quantity</th>
                <th>Ready to ship</th>
                <th>Order Date</th>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="(orders, data) in totalOrders"
              >
                <tr :key="`${data}-group`">
                  <td
                    colspan="7"
                    style="padding: 15px;"
                  >
                    <strong>{{ getMergeableOrdersDisplay(orders[0]) }}</strong>
                  </td>
                </tr>
                <tr
                  v-for="(order) in orders"
                  :key="order.id"
                >
                  <td>
                    <v-checkbox
                      v-model="checkBoxes[order.id]"
                      dense
                      color="primary"
                      @change="updateSelectedOrders(data, order)"
                    />
                  </td>
                  <td>#{{ order.display_order_id ?? order.order_id }}</td>
                  <td>{{ order.currency == "CAD" ? 'C$' : '$' }} {{ order.value }}</td>
                  <td>{{ order.items_count }}</td>
                  <td>{{ order.total_quantity }}</td>
                  <td>
                    <v-icon color="success">
                      mdi-check
                    </v-icon>
                  </td>
                  <td>{{ order.order_at }}</td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <StallionDialog
      v-model="settings"
      :width="800"
      :title="'Merge Orders Settings'"
    >
      <template #content>
        <v-layout column>
          <v-flex xs12>
            <v-container fluid>
              <v-card outlined>
                <v-card-title>
                  Select which fields to group the mergeable orders by
                </v-card-title>
                <v-card-text>
                  <v-select
                    v-model="filteredGroupByOrdersOptions.value"
                    class="card-actions"
                    data-vv-name="postage_type"
                    :items="filteredGroupByOrdersOptions.options"
                    item-text="name"
                    item-value="value"
                    chips
                    :disabled="loading"
                    outlined
                    hide-details
                    :multiple="true"
                  />
                </v-card-text>
              </v-card>
            </v-container>
          </v-flex>
        </v-layout>
      </template>

      <template #actions>
        <v-btn
          color="primary"
          :disabled="loading"
          @click="saveSettings"
        >
          <v-icon
            dark
            left
          >
            check
          </v-icon>Confirm
        </v-btn>
      </template>
    </StallionDialog>
  </div>
</template>

<script>

export default {
    data() {
        return {
            totalOrders: [],
            selectedOrders: {},
            checkBoxes: {},
            loading: false,
            settings: false,
            newSettings: {
              workflow_settings: _.cloneDeep(this.$auth.user.user_config_settings.workflow_settings)
            },
        };
    },

    computed: {
        shouldDisableMergeButton() {
            for (let address in this.selectedOrders) {
                if (this.selectedOrders[address].length >= 2) {
                return false;
                }
            }
            return true;
        },

        selectedOrdersCount() {
            return Object.keys(this.selectedOrders).reduce((acc, address) => {
                return acc + this.selectedOrders[address].length;
            }, 0);
        }
    },

    watch: {
      'newSettings.workflow_settings': {
          handler(newVal) {
              const keyToFilter = 'mergable_orders_group_by';
              const option = newVal.find(item => item.key === keyToFilter);
              this.filteredGroupByOrdersOptions = option ? option : null;
          },
          immediate: true
      },
    },

    mounted() {
        this.fetchOrders();
    },

    methods: {
      getMergeableOrdersDisplay(order) {

    const fields = ['name', 'address1', 'city', 'postal_code', 'province_code'];
    const displayParts = [];
    const options = this.filteredGroupByOrdersOptions.value;

    fields.forEach(field => {
      if (options.includes(field) && order[field]) {
        displayParts.push(order[field]);
      }
    });

    return displayParts.join(', ');
  },
        fetchOrders() {
            this.loading = true;
            this.selectedOrders = {};
            this.checkBoxes = {};
            
            this.$http
                .get('/orders/mergeable')
                .then(response => {
                    this.totalOrders = response.data.data;
                    this.initializeSelectedOrders();
                })
                .catch(() => {
                    // this.errorMessage('Could not fetch orders. Please try again.');
                }).finally(() => {
                    this.loading = false;
                });
        },
        
        mergeOrders() {
            //Filter selected orders and remove addresses with less than 2 selected orders
            const selectedOrders = Object.keys(this.selectedOrders).reduce((acc, address) => {
                if (this.selectedOrders[address].length >= 2) {
                    acc[address] = this.selectedOrders[address];
                }
                return acc;
            }, {});
            
            this.loading = true;
            this.$http
                .post('/orders/merge', { orders: selectedOrders })
                .then(() => {
                    this.successMessage('Orders merged successfully');
                    this.fetchOrders();
                })
                .catch(() => {
                    this.errorMessage('Could not merge orders. Please try again.');
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        updateSelectedOrders(address, order) {
            // Ensure selectedOrders[address] is initialized before pushing orderId
            if (!this.selectedOrders[address]) {
                this.$set(this.selectedOrders, address, []);
            }
            
            let index = this.selectedOrders[address].indexOf(order);
            if (index === -1) {
                this.selectedOrders[address].push(order);
            } else {
                this.selectedOrders[address].splice(index, 1);
            }
        },

        initializeSelectedOrders() {
            // Initialize selectedOrders based on totalOrders
            for (let address in this.totalOrders) {
                this.$set(this.selectedOrders, address, []);
            }
        },

        updateSelected() {
            if (this.selectedOrdersCount > 0) {
                // Clear selected orders and checkboxes
                this.initializeSelectedOrders();
                this.checkBoxes = {};
            }
            else {
                this.selectedOrders = {};

                // Loop through each address and select all orders
                Object.keys(this.totalOrders).forEach(address => {
                    // Copy all orders to selectedOrders[address]
                    this.$set(this.selectedOrders, address, this.totalOrders[address].slice());

                    // Check all checkboxes for each order
                    this.totalOrders[address].forEach(order => {
                        this.$set(this.checkBoxes, order.id, true);
                    });
                });
            }
        },

        showSettings() {
            this.settings = true;
        },

        saveSettings() {
          this.$validator.validate().then((result) => {
            if (result) {
              this.loading = true;
              this.$http
                .post('/users/updateUserConfigSettings', this.newSettings)
                .then((res) => {
                  if (Object.prototype.hasOwnProperty.call(res.data, 'message')) {
                    if (res.data.success) {
                      this.successMessage('Your account changes have been saved');
                      this.$auth.user.user_config_settings.workflow_settings = _.cloneDeep(this.newSettings.workflow_settings)
                    } else {
                      this.errorMessage('Your account changes have not been saved');
                    }
                  }
                })
                .finally(() => {
                  this.settings = false;
                  this.fetchOrders();
                });
            }
          });
        }
    }
};
</script>