<template>
  <div>
    <v-container
      grid-list-md
      mb-12
    >
      <v-layout>
        <v-flex xs12>
          <h1>
            <span> <i class="mdi mdi-pencil-lock" /> Roles and Permissions </span>
          </h1>
        </v-flex>
        
        <v-tabs
          v-model="tab"
        >
          <v-tab v-if="hasRole('super_admin')">
            Roles
          </v-tab>
          <v-tab>Users</v-tab>
        </v-tabs>
      </v-layout>
      
      <v-tabs-items v-model="tab">
        <v-tab-item v-if="hasRole('super_admin')">
          <UsersTable
            :assignable-roles-and-permissions="assignableRolesAndPermissions"
            :filters="filters"
          />
        </v-tab-item>
        <v-tab-item>
          <RolesTable
            :assignable-roles-and-permissions="assignableRolesAndPermissions"
            :filters="filters"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </div>
</template>

<script>
import UsersTable from './UsersTable.vue';
import RolesTable from './RolesTable.vue';
export default {
  components:{
    UsersTable,
    RolesTable,
  },
  data() {
    return {
      tab: null,
      filters: {
        selectedRoles: [],
        selectedPermissions: []
      },
      // filter super-admin and roles_and_permissions.manage
      assignableRolesAndPermissions: {
        roles: [],
        permissions: []
      },
    };
  },
  mounted() {
    this.getAllRolesAndPermissions();
  },
  methods: {
    getAllRolesAndPermissions() {
      this.$http
      .request(`/admin/roles-and-permissions`, {
        method: 'get'
      })
      .then(response => response.data)
      .then(response => {
        this.filters.roles = response.roles || [];
        this.filters.permissions = response.permissions || [];
        this.assignableRolesAndPermissions = {
          roles: this.filters.roles.filter(role => {
            // super-admin is not assignable to anyone. already pre seeded through migration
            return role.name != 'super-admin';
          }),
          permissions: this.filters.permissions
        };
      });
    },
  }
};
</script>
