<template>
  <SidePanelLayout>
    <template #side-panel>
      <admin-view-shipment-details
        style="font-size: 14px"
        :shipments="main.selectedShipments"
      />
    </template>

    <template #default>
      <admin-shipment-table
        :filter="filter"
        admin
      />
    </template>

    <template #footer>
      <v-menu offset-y>
        <template #activator="{ on, attrs }">
          <v-btn
            color="primary"
            v-bind="attrs"
            :disabled="!printable(main.selectedShipments)"
            v-on="on"
          >
            Print
            <v-icon right>
              mdi-chevron-up
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="buildPrinter('label_only')">
            <v-list-item-title>Label</v-list-item-title>
          </v-list-item>
          <v-list-item @click="buildPrinter('packing_slip')">
            <v-list-item-title>Packing Slip</v-list-item-title>
          </v-list-item>
          <v-list-item @click="buildPrinter('label_with_packing_slip')">
            <v-list-item-title>Label + Packing Slip</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <print
        v-model="show_print"
        :shipments="main.selectedShipments"
        :print-type="printType"
      />

      <v-menu v-if="can('shipments.edit')">
        <template #activator="{ on }">
          <v-btn
            color="primary"
            :disabled="main.selectedShipments.length == 0"
            outlined
            class="mx-2"
            v-on="on"
            @click="$refs.updatePostage.openDialog(main.selectedShipments)"
          >
            <v-icon
              small
              left
            >
              mdi-pencil
            </v-icon>
            Postage
          </v-btn>

          <v-btn
            color="primary"
            :disabled="main.selectedShipments.length == 0"
            outlined
            class="mx-2"
            v-on="on"
            @click="$refs.updateStatus.openDialog()"
          >
            <v-icon
              small
              left
            >
              mdi-pencil
            </v-icon>
            Status
          </v-btn>
        </template>
      </v-menu>

      <update-status
        ref="updateStatus"
        :shipments="main.selectedShipments"
        :disabled="main.selectedShipments.length == 0"
      />

      <update-postage
        ref="updatePostage"
        :shipments="main.selectedShipments"
        :disabled="main.selectedShipments.length == 0"
      />

      <v-btn
        color="primary"
        :disabled="!voidable"
        outlined
        class="mx-2"
        @click="show_void = true"
      >
        <v-icon left>
          mdi-select-off
        </v-icon>
        Void
      </v-btn>

      <void-shipments
        v-if="can('shipments.edit')"
        v-model="show_void"
        :shipments="main.selectedShipments"
        :update-event="update"
        :text="'Void'"
      />
    </template>
  </SidePanelLayout>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
export default {
  props: {
    filter: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      show_print: false,
      items: [
        {
          title: 'Home',
          icon: 'dashboard'
        },
        {
          title: 'About',
          icon: 'question_answer'
        }
      ],

      selected: [],
      pagination: {
        page: 1,
        itemsPerPage: 10,
        sortBy: 'created_at',
        descending: true
      },
      pagination2: {
        page: 1,
        itemsPerPage: 10,
        sortBy: 'created_at',
        descending: true
      },
      loading: false,
      totalDesserts: 0,
      headers: [
        {
          text: 'Email',
          align: 'left',

          value: 'email'
        },
        {
          text: 'Recipient',
          align: 'left',

          value: 'name'
        },
        {
          text: 'Country',
          value: 'country_code'
        },
        {
          text: 'Postage',
          value: 'postage_type_id'
        },
        {
          text: 'Rate',
          value: 'postage_rate'
        },
        {
          text: 'Status',
          value: 'status_id'
        },
        {
          text: 'Batch',
          value: 'batch_code'
        },
        {
          text: 'Created',
          value: 'created_at'
        }
        //  {
        //     text: 'Actions',
        //     value: 'actions'
        // }
      ],
      desserts: [],

      processing: false,
      shipments: {},
      selectedShipments: [],
      selectedShipment: null,
      shipmentsFilter: null,

      per_page: 10,
      search: '',
      start_date: moment()
        .subtract(1, 'years')
        .format('YYYY-MM-DD'),
      end_date: '', //moment().format('YYYY-MM-DD'),
      menu1: '',
      menu2: '',
      status: '',

      dialog: false,
      valueDeterminate: 0,
      timer: null,

      printType: 'label_only',
      show_void: false
    };
  },
  computed: {
    ...mapState(['main']),
    voidable() {
      if (
        this.main.selectedShipments.length != 0 &&
        this.main.selectedShipments.every(
          r => r.status_id == 4 || r.status_id == 2
        ) &&
        this.main.selectedShipments.every(r => !!r.needs_postage)
      ) {
        return true;
      } else {
        return false;
      }
    }
  },

  watch: {
    // filter: function(val) {
    //   this.getDataFromApi().then(data => {
    //     this.desserts = data.items;
    //     this.totalDesserts = data.total;
    //   });
    // }
    // pagination: {
    //   handler() {
    //     // console.log('changing');
    //     this.getDataFromApi().then(data => {
    //       this.desserts = data.items;
    //       this.totalDesserts = data.total;
    //     });
    //   },
    //   deep: true
    // }
  },
  mounted() {
    Event.listen('admin-update-shipments', () => {
      // console.log('admin-update-shipments');
      this.getDataFromApi().then(data => {
        this.desserts = data.items;
        this.totalDesserts = data.total;
      });
    });
  },

  methods: {
    rowClick(props) {
      if (!this.$vuetify.breakpoint.lgAndUp) {
        props.expanded = !props.expanded;
        // console.log('not');
      } else {
        // console.log(this.$vuetify.breakpoint.mdAndUp);
        this.selectedShipments = [props.item];
      }
    },
    update() {
      Event.fire('admin-update-shipments');
    },
    getDataFromApi() {
      this.loading = true;
      return new Promise(resolve => {
        const { sortBy, descending, page, itemsPerPage } = this.pagination;

        let items = [];
        let total = 0;
        this.$http
          .get(
            `/admin/shipments?page=${page}&per_page=${itemsPerPage}&sort_by=${sortBy}&desc=${descending}&status=${
              this.filter
            }&search=${this.search}&start_date=${this.start_date}&end_date=${
              this.end_date
            }`
          )
          .then(response => response.data)
          .then(response => {
            items = response.data;
            total = response.total;

            this.loading = false;
            resolve({
              items,
              total
            });
          });
      });
    },

    test() {
      this.$http
        .get('/admin/shipments')
        .then(response => {
          // console.log(response);
          // Setting the response data (Laravel Pagination data)
          this.shipments = response.data;
          this.pageDetails = response;
        })
        .catch(() => {});
    },
    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.pagination.page = 1;
      this.timer = setTimeout(function() {
        self.getDataFromApi().then(data => {
          self.desserts = data.items;
          self.totalDesserts = data.total;
          // self.numPages = data.pages
        });
      }, ms);
    },
    setPerPage(per_page) {
      this.per_page = per_page;
      this.getDataFromApi();
    },

    buildPrinter(type) {
      this.printType = type;
      this.show_print = true;
    }
  }
};
</script>
