<template>
  <v-container
    grid-list-md
    mb-12
  >
    <v-card outlined>
      <v-card-text>
        <v-form>
          <v-row dense>
            <v-col>
              <v-row dense>
                <v-col
                  cols="6"
                  md="6"
                >
                  <v-select
                    v-model="format"
                    v-validate="'required'"
                    clearable
                    :error-messages="errs.collect('format')"
                    data-vv-name="format"
                    label="Rate Card Format"
                    :items="['Rate Card (Customer)', 'Rate Card (Employee)']"
                    dense
                    outlined
                  />
                </v-col>

                <v-col
                  cols="6"
                  md="6"
                >
                  <user-search
                    v-model="userId"
                    label="Search User"
                  />
                </v-col>
              </v-row>
  
              <v-row dense>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <v-text-field
                    v-model="length"
                    v-validate="'required'"
                    clearable
                    :error-messages="errs.collect('length')"
                    data-vv-name="length"
                    label="Length"
                    dense
                    outlined
                    type="number"
                  />
                </v-col>
  
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <v-text-field
                    v-model="width"
                    v-validate="'required'"
                    clearable
                    :error-messages="errs.collect('width')"
                    data-vv-name="width"
                    label="Width"
                    dense
                    outlined
                    type="number"
                  />
                </v-col>
  
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <v-text-field
                    v-model="height"
                    v-validate="'required'"
                    clearable
                    :error-messages="errs.collect('height')"
                    data-vv-name="height"
                    label="Height"
                    dense
                    outlined
                    type="number"
                  />
                </v-col>
  
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <v-select
                    v-model="sizeUnit"
                    v-validate="'required'"
                    clearable
                    :error-messages="errs.collect('sizeUnit')"
                    data-vv-name="sizeUnit"
                    label="Unit"
                    :items="['cm', 'in']"
                    dense
                    outlined
                  />
                </v-col>
              </v-row>
  
              <v-row dense>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-model="postageTypeIds"
                    v-validate="'required'"
                    multiple
                    outlined
                    dense
                    :error-messages="errs.collect('postageTypeIds')"
                    data-vv-name="postageTypeIds"
                    prepend-inner-icon="mdi-postage-stamp"
                    :items="postageList"
                    item-value="id"
                    item-text="description"
                    label="Postage Type"
                    clearable
                  >
                    <template #selection="{ item, index }">
                      <span v-if="index === 0">
                        <span>{{ item.description }}</span>
                      </span>
                      <span
                        v-if="index === 1"
                        class="grey--text text-caption"
                      >
                        (+{{ postageTypeIds.length - 1 }} others)
                      </span>
                    </template>
                  </v-autocomplete>
                </v-col>
  
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-autocomplete
                    v-model="countryId"
                    v-validate="'required'"
                    :error-messages="errs.collect('countryId')"
                    data-vv-name="countryId"
                    outlined
                    dense
                    prepend-inner-icon="mdi-earth"
                    :items="['CA', 'US', 'INT']"
                    item-value="code"
                    item-text="name"
                    label="Country"
                    clearable
                  />
                </v-col>
  
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-select
                    v-model="packageTypeId"
                    v-validate="'required'"
                    clearable
                    :error-messages="errs.collect('packageTypeId')"
                    data-vv-name="packageTypeId"
                    label="Package Type"
                    :items="packageArray"
                    item-text="description"
                    item-value="id"
                    dense
                    outlined
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <v-combobox
                    v-model="weights"
                    :items="weightList"
                    :error-messages="errs.collect('weights')"
                    data-vv-name="weights"
                    label="Weights"
                    dense
                    outlined
                    clearable
                    item-text="text"
                    item-value="id"
                  />
                </v-col>
                 
                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                >
                  <v-select
                    v-model="weightUnit"
                    v-validate="'required'"
                    clearable
                    :error-messages="errs.collect('weightUnit')"
                    data-vv-name="weightUnit"
                    label="Weight Unit"
                    :items="['lbs', 'oz', 'kg', 'g']"
                    dense
                    outlined
                  />
                </v-col>
              </v-row>
  
              <v-row dense>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-btn
                    color="primary"
                    :disabled="loading"
                    :loading="loading"
                    @click="generateRates()"
                  >
                    Generate
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      postageList: [],
      format: null,
      userId: null,
      countryId: null,
      packageTypeId: 20, // Parcel
      postageTypeIds: null,
      width: 5,
      length: 5,
      height: 5,
      sizeUnit: 'in',
      weights: null,
      weightUnit: 'lbs',
      loading: false,
      weightList:[
        "0.125, 0.25, 0.5, 0.75, 1, 2, 3, 4, 5, 10, 20, 30, 40, 50",
        "1, 2, 3, 4, 5, 6, 7, 8, 9, 10",
        "50, 100, 150, 200, 250, 300, 350, 400, 450, 500"
      ]
    };
  },

  computed: {
    packageArray() {
      return this.$store.getters['main/package_types'].map((p) => {
        return {
          id: parseInt(p.id),
          description: p.description,
          classification: p.classification,
          package_provider: p.package_provider
        };
      });
    }
  },

  watch: {
    userId: function (val) {
      if (val) {
        console.log(val);
        this.postageTypeIds = null;
        this.getPostageTypes();
      }
    },
  },

  methods: {
    getPostageTypes() {
      this.loading = true;
      this.$http
        .get(`/admin/postage-types?user_id=${this.userId}`)
        .then((response) => response.data)
        .then((response) => {
          this.postageList = response;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async validateForm() {
      return await this.$validator.validate().then((result) => {
        return result ? true : false;
      });
    },

    async generateRates() {
      if (!(await this.validateForm())) {
        return;
      }

      this.loading = true;
      this.$http
        .post(`/admin/generate-rates`, {
          user_id: this.userId,
          format: this.format,
          postage_type_ids: this.postageTypeIds,
          country_id: this.countryId,
          package_type_id: this.packageTypeId,
          width: this.width,
          height: this.height,
          length: this.length,
          size_unit: this.sizeUnit,
          weights: this.weights,
          weight_unit: this.weightUnit,
        })
        .then((response) => response.data)
        .then((response) => {
          swal({
            title: 'Success',
            text: response.message,
            icon: 'success'
          });
        })
        .catch((err) => {
          let errMsg = 'Unknown Error Occured';
          if (err.response.status == 422) {
            errMsg = err.response.data.message;
          }

          this.errorMessage(errMsg);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
