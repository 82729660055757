<template>
  <v-row>
    <v-col md="12">
      <v-data-table
        
        hide-default-header 
        :headers="headers"
        :loading="loading"
        item-key="id"
        :items="addressBooks"
        class="table-striped"
        :server-items-length="totalItems"
        :options.sync="options"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, 200]
        }"
      >
        <template #top>
          <div class="pr-2 pl-2 pt-2 pb-2">
            <v-text-field
              v-model="search"
              label="Search"
              prepend-inner-icon="search"
              outlined
              clearable
              dense
              hide-details
              data-lpignore="true"
              @input="submitSearch()"
            />
          </div>
        </template>
        <template #item.address="{ item }">
          {{ getAddressInfo(item) }}
        </template>

        <template #item.is_default="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-icon
                v-if="item.is_default" 
                color="primary"
                v-on="on"
              >
                mdi-check-circle
              </v-icon>
            </template>
            <span>Default Address</span>
          </v-tooltip>
        </template>

        <template #item.actions="{ item }">
          <v-btn
            v-if="showSelect"
            :disabled="loading"
            small
            color="primary"
            text
            @click="$emit('selectAddress', item)"
          >
            Select
          </v-btn>

          <v-btn
            v-if="showEdit"
            :disabled="loading"
            small
            color="primary"
            text
            @click="$refs.addressDialog.startEdit(item)"
          >
            Edit
          </v-btn>
          
          <v-btn
            v-if="showDelete"
            :disabled="loading"
            small
            color="red"
            text
            @click="deleteAddress(item)"
          >
            Delete
          </v-btn>
        </template>
      </v-data-table>
    </v-col>

    <address-dialog
      ref="addressDialog"
      @save="updateTable"
    />
  </v-row>
</template>

<script>
import AddressDialog from './AddressDialog.vue';
export default {
  components: {
    AddressDialog
  },
  props: {
    outlined: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    showEdit: {
      type: Boolean,
      default: true,
    },
    showDelete: {
      type: Boolean,
      default: true,
    },
    showSelect: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      show: false,
      addressBooks: [],
      search: null,
      timer: null,
      options: {
        sortBy: ['created_at'],
        sortDesc: [true]
      },
      totalItems: 0,
      headers: [
        {
          text: 'Address',
          align: 'left',
          value: 'address',
          sortable: false
        },
        {
          text: 'Default',
          align: 'left',
          value: 'is_default',
          sortable: true
        },
        {
          text: '',
          align: 'right',
          value: 'actions',
          sortable: false
        }
      ]
    };
  },
  watch: {
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    }
  },
  methods: {
    getAddressInfo(item) {
      const address = [
        item.address1,
        item.address2,
        item.city,
        item.province_code,
        item.postal_code,
        item.country_code
      ].filter(Boolean).join(', ');

      const name = [item.name, item.company].filter(Boolean).join(' ');

      return name +  ' - ' + address
    },

    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.options.page = 1;
      this.timer = setTimeout(function () {
        self.updateTable();
      }, ms);
    },
    updateTable() {
      this.totalItems = 0;
      this.addressBooks = [];

      this.loading = true;
      this.getDataFromApi()
        .then((data) => {
          this.addressBooks = data.items;
          this.totalItems = data.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getDataFromApi() {
      return new Promise((resolve) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let items = [];
        let total = 0;
        let pages = 0;

        var params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sortBy[0],
          desc: sortDesc[0],
          search: this.search
        };

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        this.$http
          .request(`/address-books`, {
            method: 'get',
            params: params,
            cancelToken: this.cancelSource.token
          })
          .then((response) => response.data)
          .then((response) => {
            items = response.data;
            total = response.total;
            pages = response.last_page;

            resolve({
              items,
              total,
              pages
            });
          });
      });
    },
    async deleteAddress(item) {
      const isConfirm = await swal({
        title: 'Confirm Delete',
        text: 'Do you really want to delete this address?',
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      });

      if (isConfirm) {
        this.loading = true;
        try {
          await this.$http.delete(`/address-books/${item.id}`);
          swal({
            title: 'Success',
            text: 'Address successfully deleted',
            icon: 'success'
          });

          this.updateTable();

          this.$store.dispatch('main/getDefaultAddress');
        } catch (err) {
          swal({
            title: 'Error!',
            text: 'An error occurred',
            icon: 'error'
          });
        }

        this.loading = false;
      }
    }
  }
};
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
