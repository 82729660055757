import Vue from 'vue';
// import moment from 'moment-timezone';
// import moment from 'moment'

// Vue.use(moment);

import moment from 'moment';
window.moment = moment;
import VueMoment from 'vue-moment'
Vue.use(VueMoment);
/**
 * Date library momentjs
 * https://momentjs.com/
 */
// Vue.prototype.$moment = moment
