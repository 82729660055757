<template>
  <v-card outlined>
    <v-card-title>
      Commissions
    </v-card-title>

    <v-card-text>
      <v-tabs
        v-model="tabSelected"
        :center-active="$vuetify.breakpoint.mobile"
        :show-arrows="$vuetify.breakpoint.mobile"
        :left="!$vuetify.breakpoint.mobile"
        :vertical="!$vuetify.breakpoint.mobile"
        :class="{ 'my-tabs': !$vuetify.breakpoint.mobile }"
        class="elevation-0"
      >
        <v-tab
          v-for="tab in tabs"
          :key="tab.title + '1'"
        >
          <v-icon
            small
            left
          >
            {{ tab.icon }}
          </v-icon>
          <h4 class="settings-tab">
            {{ tab.title }}
          </h4>
        </v-tab>

        <v-tab-item
          v-for="tab in tabs"
          :key="tab.title + '2'"
        >
          <v-card
            flat
            class="settings-card"
          >
            <v-card-title>{{ tab.title }}</v-card-title>
            <v-card-text>
              <component :is="tab.component" />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card-text>
  </v-card>
</template>
  
<script>
import CommissionStructuresTable from  './commission-structures-table.vue'
import CommissionsChart from  './commissions-chart.vue'
import CommissionPayoutsTable from  './commission-payouts-table.vue'

export default {
  metaInfo: {
    title: 'Commissions | Stallion Express'
  },
  components: {
    CommissionStructuresTable,
    CommissionsChart,
    CommissionPayoutsTable
  },
  data() {
    return {
      tabSelected: null,
      tabs: [
        {
          title: 'Structure',
          icon: 'mdi-wallet-membership',
          component: 'commission-structures-table'
        },
        {
          title: 'Chart',
          icon: 'mdi-chart-line',
          component: 'commissions-chart'
        },
        {
          title: 'Payouts',
          icon: 'mdi-cash-clock',
          component: 'commission-payouts-table'
        },
      ]
    };
  }
};
</script>

<style scoped>
.settings-tab {
  font-size: 13px;
  font-weight: 700;
}

div.my-tabs [role='tab'] {
  justify-content: flex-start;
  padding-left: 25px;
  padding-right: 25px;
}

.settings-card {
  padding: 25px !important;
  border-left: 1px solid #e7e7e7;
  border-radius: 0 !important;
}

h4 {
  margin-bottom: 0px !important;
}

.v-application p {
  margin-bottom: 0px !important;
}

.v-application ul {
  padding-left: 0px !important;
}

input,
select {
  width: 100%;
}
</style>
