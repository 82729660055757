<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-btn
          color="primary"
          dark
          @click="$refs.locationDialog.startNew()"
        >
          Create Location
        </v-btn>
      </v-col>

      <v-col cols="6">
        <v-form @submit.prevent="updateTable()">
          <v-container pa-0>
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="search"
                  label="Search"
                  placeholder="Search by name"
                  append-icon="search"
                  @input="submitSearch()"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-col>

      <!-- add field to show active -->
      <v-col cols="3">
        <v-checkbox
          v-model="active"
          :label="active ? 'Active' : 'Inactive'"
          @change="updateTable()"
        />
      </v-col>
      <v-col cols="3">
        <v-checkbox
          v-model="company_location"
          :label="company_location ? 'Company Location' : 'Dropoff Location'"
          @change="updateTable()"
        />
      </v-col>

      <v-col cols="12">
        <v-data-table
          class="table-striped"
          :headers="headers"
          :items="items"
          :loading="loading"
          :items-per-page="10"
        >
          <template #item.can_closeout="{ item }">
            <v-icon v-if="item.can_closeout">
              mdi-check-circle-outline
            </v-icon>
            <v-icon v-else>
              mdi-close
            </v-icon>
          </template>

          <template #item.company_location="{ item }">
            <v-icon
              v-if="item.company_location"
            >
              mdi-check-circle-outline
            </v-icon>
            <v-icon v-else>
              mdi-close
            </v-icon>
          </template>

          <template #item.inventory_location="{ item }">
            <v-icon
              v-if="item.inventory_location"
            >
              mdi-check-circle-outline
            </v-icon>
            <v-icon v-else>
              mdi-close
            </v-icon>
          </template>

          <template #item.active="{ item }">
            <v-icon v-if="item.active">
              mdi-check-circle-outline
            </v-icon>
            <v-icon v-else>
              mdi-close
            </v-icon>
          </template>

          <template #item.created_at="{ item }">
            {{ item.created_at | date_pretty }}
          </template>
          <template #item.actions="{ item }">
            <!-- <v-btn small color="red" text @click="deleteLocation(item.id)"
                >Delete</v-btn
              > -->
            <v-btn
              small
              color="primary"
              text
              @click="$refs.locationDialog.startEdit(item)"
            >
              Edit
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <dropoff-location-dialog
      ref="locationDialog"
      @update="updateTable()"
    />
  </div>
</template>
<script>
import DropoffLocationDialog from './DropoffLocationDialog.vue';
export default {
  components: {
    DropoffLocationDialog
  },
  data() {
    return {
      location: {},
      show_edit: false,
      loading: false,
      items: [],
      search: null,
      active: true,
      company_location: false,

      headers: [
        { text: 'ID', value: 'id' },
        { text: 'User ID', value: 'user_id' },

        { text: 'Pallet ID', value: 'pallet_identifier' },
        { text: 'Name', value: 'name' },
        { text: 'Region', value: 'region.name' },

        { text: 'Transfer Location', value: 'transfer_location.name' },
        { text: 'Company Location', value: 'company_location' },
        { text: 'Inventory Location', value: 'inventory_location'},
        { text: 'Closeout Enabled', value: 'can_closeout' },
        { text: 'Active', value: 'active' },
        { text: 'Created At', value: 'created_at' },
        { text: 'Actions', value: 'actions', align: 'right', sortable: false }
      ]
    };
  },
  watch: {
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    }
  },

  mounted() {
    this.updateTable();
  },
  methods: {
    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.timer = setTimeout(function () {
        self.updateTable();
      }, ms);
    },
    updateTable() {
      this.totalItems = 0;
      this.items = [];

      this.$http
        .request(`/admin/dropoff-locations`, {
          method: 'get',
          params: {
            search: this.search,
            active: this.active,
            company_location: this.company_location
          }
        })
        .then((response) => response.data)
        .then((response) => {
          this.items = response;
        });
    },
    async deleteLocation(id) {
      const isConfirm = await swal({
        title: 'Confirm Delete',
        text: 'Do you really want to delete this location?',
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      });

      if (isConfirm) {
        try {
          await this.$store.dispatch('locations/deleteLocation', id);
          this.updateTable();
        } catch (e) {
          console.log(e);
        }
      }
    },
    showUpdate(location) {
      this.show_edit = true;
      this.location = location;
    },
    showCreate() {
      this.show_edit = true;
      this.location = null;
    }
  }
};
</script>
