<template>
  <div>
    <v-row dense>
      <v-col />
      <v-col cols="auto">
        <v-btn
          v-if="statementId && showSummary"
          color="primary"
          outlined
          small
          :disabled="summaryLoading"
          :loading="summaryLoading"
          @click="getStatementSummary(statementId)"
        >
          <v-icon
            small
            left
          >
            mdi-download
          </v-icon>
          Statement Summary
        </v-btn>

        <v-btn
          v-else-if="showSummary"
          color="primary"
          outlined
          small
          :disabled="summaryLoading"
          :loading="summaryLoading"
          @click="getPeriodSummary"
        >
          <v-icon
            small
            left
          >
            mdi-download
          </v-icon>
          Period Summary
        </v-btn>

        <v-btn
          v-if="statementId"
          color="primary"
          outlined
          small
          :disabled="transactionsLoading"
          :loading="transactionsLoading"
          @click="getStatementDetails(statementId)"
        >
          <v-icon
            small
            left
          >
            mdi-download
          </v-icon>
          Transactions
        </v-btn>

        <v-btn
          v-else
          color="primary"
          outlined
          small
          :disabled="transactionsLoading"
          :loading="transactionsLoading"
          @click="getTransactions"
        >
          <v-icon
            small
            left
          >
            mdi-download
          </v-icon>
          Transactions
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-if="showSearch"
      dense
    >
      <v-col>
        <v-form @submit.prevent>
          <!-- <v-container class="grey lighten-5"> -->
          <v-row dense>
            <v-col cols="3">
              <v-select
                v-model="dateOption"
                :items="dateOptions"
                outlined
                dense
                label="Date Range"
              />
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="search"
                label="Search"
                class="mr-2"
                outlined
                prepend-inner-icon="search"
                clearable
                dense
                hide-details
                data-lpignore="true"
                @input="submitSearch()"
              />
            </v-col>
            <v-col cols="3">
              <v-select
                v-model="category_ids"
                multiple
                dense
                label="Category"
                outlined
                :items="categories"
                item-value="id"
                item-text="name"
                @input="submitSearch()"
              />
            </v-col>
            <v-col cols="3">
              <v-select
                v-model="type_ids"
                multiple
                dense
                label="Type"
                outlined
                :items="types"
                item-value="id"
                item-text="name"
                @input="submitSearch()"
              />
            </v-col>
          </v-row>

          <!-- Advanced Filters -->
          <!-- <v-row class="pt-3" v-if="showAdvanceSearch" dense>
            <p class="font-weight-bold">Advanced Filters</p>
          </v-row> -->
          <v-row
            v-if="dateOption == 'Custom'"
            dense
          >
            <v-col
              cols="12"
              md="3"
            >
              <date-picker
                v-model="start_date"
                label="Start Date"
                :min-date="startDate"
                :max-date="endDate"
                @change="submitSearch"
              />
            </v-col>

            <v-col
              cols="12"
              md="3"
            >
              <date-picker
                v-model="end_date"
                label="End Date"
                :min-date="startDate"
                :max-date="endDate"
                @change="submitSearch"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          item-key="id"
          :options.sync="options"
          class="table-striped"
          :server-items-length="totalItems"
          :footer-props="{
            'items-per-page-options': [
              10, 50, 100, 200, 500
            ]
          }"
        >
          <template #item.id="{ item }">
            <a
              href="javascript:void(0)"
              @click="openDetails(item)"
            >
              {{ item.id }}</a>
          </template>

          <template #item.created_at="{ item }">
            {{ item.created_at | datetime_short }}
          </template>
          <template #item.end_balance="{ item }">
            {{ item.end_balance | currency }}
          </template>

          <template #item.debit="{ item }">
            <span
              v-if="item.action == 'subtract'"
            >-{{ item.total | currency }}</span>
          </template>

          <template #item.subtotal="{ item }">
            <span v-if="item.action == 'subtract'">-</span>{{ item.subtotal | currency }}
          </template>

          <template #item.tax="{ item }">
            <span v-if="item.tax > 0">
              <span v-if="item.action == 'subtract'">-</span>{{ item.tax | currency }}</span>
          </template>

          <template #item.credit="{ item }">
            <span
              v-if="item.action == 'add'"
            >+{{ item.total | currency }}</span>
          </template>

          <template #item.transactional_id="{ item }">
            <span v-if="item.transactional_type">
              <router-link
                router
                :to="{
                  name: 'transactions',
                  params: {
                    transactional_id: item.transactional_id,
                    transactional_type: transactionalTypeUrl(
                      item.transactional_type.name
                    )
                  }
                }"
              >
                {{ item.transactional_id }}
              </router-link>
            </span>

            <span v-else>
              {{ item.transactional_id }}
            </span>
          </template>

          <template #item.payment_method="{ item }">
            <span
              v-if="item.payment_method == 'PayPal'"
            >{{ item.payment_method }} ({{ item.paypal_email }})</span>
            <span
              v-else-if="item.payment_method == 'Visa'"
            >Credit Card (**** **** **** {{ item.card_last_four }})</span>
            <span v-else>{{ item.payment_method }}</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <StallionDialog
      v-if="selected"
      v-model="dialog"
      :width="600"
      :title="'Transaction '.concat(selected.id)"
    >
      <template #content>
        <v-row>
          <v-col cols="12">
            <h4>ID</h4>
            <p>{{ selected.id }}</p>
          </v-col>

          <v-col cols="12">
            <h4>Created At</h4>
            <p>{{ selected.created_at | datetime_short }}</p>
          </v-col>

          <v-col
            v-if="selected.category"
            cols="12"
          >
            <h4>Category</h4>
            <p>{{ selected.category.name }}</p>
          </v-col>

          <v-col
            v-if="selected.transactional_type"
            cols="12"
          >
            <h4>Reference Type</h4>
            <p>{{ selected.transactional_type.name }}</p>
          </v-col>
          <v-col
            v-if="selected.transactional_id"
            cols="12"
          >
            <h4>Reference ID</h4>
            <p>{{ selected.transactional_id }}</p>
          </v-col>

          <v-col cols="12">
            <h4>Description</h4>
            <p>{{ selected.description }}</p>
          </v-col>

          <v-col cols="12">
            <h4>Subtotal</h4>
            <p>
              <span v-if="selected.action == 'subtract'">-</span>{{ selected.subtotal | currency }}
            </p>
          </v-col>

          <v-col
            v-if="selected.gst > 0"
            cols="12"
          >
            <h4>GST</h4>
            <p>
              <span v-if="selected.action == 'subtract'">-</span>{{ selected.gst | currency }}
            </p>
          </v-col>

          <v-col
            v-if="selected.pst > 0"
            cols="12"
          >
            <h4>PST</h4>
            <p>
              <span v-if="selected.action == 'subtract'">-</span>{{ selected.pst | currency }}
            </p>
          </v-col>

          <v-col
            v-if="selected.hst > 0"
            cols="12"
          >
            <h4>HST</h4>
            <p>
              <span v-if="selected.action == 'subtract'">-</span>{{ selected.hst | currency }}
            </p>
          </v-col>

          <v-col
            v-if="selected.qst > 0"
            cols="12"
          >
            <h4>QST</h4>
            <p>
              <span v-if="selected.action == 'subtract'">-</span>{{ selected.qst | currency }}
            </p>
          </v-col>

          <v-col cols="12">
            <h4>Total</h4>
            <p>
              <span v-if="selected.action == 'subtract'">-</span>{{ selected.total | currency }}
            </p>
          </v-col>          
        </v-row>
      </template>
      
      <template #actions>
        <v-btn
          v-if="selected.payment_transaction_id"
          color="primary"
          block
          @click="getSingleInvoice(selected)"
        >
          <v-icon
            small
            left
          >
            mdi-download
          </v-icon>
          Credit Invoice
        </v-btn>
      </template>
    </StallionDialog>
  </div>
</template>

<script>
export default {
  props: {
    userId: {
      type: Number,
      required: true
    },
    transactionalId: {
      default: null,
      type: String
    },
    transactionalTypeId: {
      default: null,
      type: String
    },
    transactionalType: {
      default: null,
      type: String
    },
    isStatement: {
      default: false,
      type: Boolean
    },
    showSearch: {
      default: true,
      type: Boolean
    },
    showSummary: {
      default: true,
      type: Boolean
    },
    statementId: {
      default: null,
      type: [String, Number]
    },
    endDate: {
      default: null,
      type: [String, Date]
    },
    startDate: {
      default: null,
      type: [String, Date]
    },

  },
  data() {
    return {
      transactionsLoading: false,
      summaryLoading: false,
      dateOption: 30,
      dateOptions: [
        { text: 'Today', value: 'Today' },
        { text: 'Yesterday', value: 'Yesterday' },
        { text: '7 Days', value: 7 },
        { text: '30 Days', value: 30 },
        { text: '3 Months', value: 90 },
        { text: '12 Months', value: 360 },
        { text: 'Current Year', value: 'Current Year' },
        { text: 'Previous Year', value: 'Previous Year' },
        { text: 'Custom Range', value: 'Custom' }
      ],
      selected: false,
      dialog: false,
      showAdvanceSearch: false,
      category_ids: [],
      type_ids: [],
      search: null,
      date: moment().format('YYYY-MM'),
      menu: false,
      start_date: null,
      end_date: null,
      start_date_menu: null,
      end_date_menu: null,
      headers: [
        {
          text: 'ID',
          value: 'id',
          sortable: false
        },
        {
          text: 'Date',
          value: 'created_at',
          sortable: false
        },
        {
          text: 'Transaction Type',
          value: 'transaction_type.name',
          sortable: false
        },
        {
          text: 'Reference Type',
          value: 'transactional_type.name',
          sortable: false
        },
        // {
        //   text: 'Description',
        //   value: 'description'
        // },
        // {
        //   text: 'Payment Method',
        //   value: 'payment_method',
        //   class: 'hidden-sm-and-down'
        // },
        {
          text: 'Reference ID',
          value: 'transactional_id',
          class: 'hidden-sm-and-down',
          sortable: false
        },
        // {
        //   text: 'Type ID',
        //   value: 'type_id',
        //   class: 'hidden-sm-and-down'
        // },
        {
          text: 'Subtotal',
          value: 'subtotal',
          sortable: false,
          class: 'hidden-sm-and-down'
        },
        {
          text: 'Tax',
          value: 'tax',
          sortable: false,
          class: 'hidden-sm-and-down'
        },
        {
          text: 'Debit',
          value: 'debit',
          sortable: false,
          class: 'hidden-sm-and-down'
        },

        {
          text: 'Credit',
          value: 'credit',
          sortable: false,
          class: 'hidden-sm-and-down'
        },
        {
          text: 'Balance',
          value: 'end_balance',
          sortable: false
        }
      ],
      options: {
        sortBy: ['created_at'],
        sortDesc: [true],
        itemsPerPage: 10
      },
      loading: false,
      totalItems: 0,
      items: [],
      categories: [],
      types: []
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      if (this.date == moment().format('YYYY-MM')) {
        return 'Current';
      }
      return this.date ? moment(this.date).format('MMMM YYYY') : null;
    }
  },
  watch: {
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    },
    dateOption: {
      immediate: true,
      handler(val) {
        this.setDate(val);
      }
    },
    isStatement: {
      immediate: true,
      handler(val) {
        if (val) {
          this.setDate('custom');
          this.endDate = moment(this.endDate).format('YYYY-MM-DD');
          this.startDate = moment(this.startDate).format('YYYY-MM-DD');
        }
      }
    }
  },
  mounted() {
    this.getCategories();
    this.getTypes();
    Event.listen('payment-completed', () => {
      this.updateTable();
    });
  },
  methods: {
    transactionalTypeUrl(str) {
      return str.replace(/\s+/g, '-').toLowerCase();
    },
    setDate(val) {
      this.start_date = null;
      this.end_date = null;
      if (!this.showSearch) {
        return;
      }
      if (val == 'Today') {
        this.start_date = moment().format('YYYY-MM-DD');
        this.end_date = moment().format('YYYY-MM-DD');
      } else if (val == 'Yesterday') {
        this.start_date = moment().subtract(1, 'days').format('YYYY-MM-DD');
        this.end_date = moment().subtract(1, 'days').format('YYYY-MM-DD');
      } else if (val == 'Current Year') {
        this.start_date = moment().startOf('year').format('YYYY-MM-DD');
        this.end_date = moment().endOf('year').format('YYYY-MM-DD');
      } else if (val == 'Previous Year') {
        this.start_date = moment()
          .startOf('year')
          .subtract(1, 'year')
          .format('YYYY-MM-DD');
        this.end_date = moment()
          .endOf('year')
          .subtract(1, 'year')
          .format('YYYY-MM-DD');
      } else if (val > 0) {
        // this.start_date = moment().format('YYYY-MM-DD');
        this.start_date = moment().subtract(val, 'days').format('YYYY-MM-DD');
        this.end_date = moment().format('YYYY-MM-DD');
      }

      this.updateTable();
    },
    getCategories() {
      this.$http
        .get(`/transaction-categories`)
        .then((response) => response.data)
        .then((response) => {
          this.categories = response;
        });
    },
    getTypes() {
      this.$http
        .get(`/transaction-types`)
        .then((response) => response.data)
        .then((response) => {
          this.types = response;
        });
    },
    openDetails(item) {
      this.selected = item;
      this.dialog = true;
    },
    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.options.page = 1;
      this.timer = setTimeout(function () {
        self.updateTable();
      }, ms);
    },
    updateTable() {
      this.selected = [];
      this.totalItems = 0;
      this.items = [];

      this.getDataFromApi().then((data) => {
        this.items = data.items;
        this.totalItems = data.total;
      });
    },
    getParams() {
      var params = {
        transactional_id: this.transactionalId,
        transactional_type_id: this.transactionalTypeId,
        transactional_type: this.transactionalType,
        category_ids: this.category_ids,
        type_ids: this.type_ids,
        search: this.search,
        start_date: this.start_date,
        end_date: this.end_date,
        statement_id: this.statementId,
        user_id: this.userId
      };

      return params;
    },

    getDataFromApi() {
      this.loading = true;
      return new Promise((resolve) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let items = [];
        let total = 0;
        let pages = 0;

        const p = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sortBy[0],
          desc: sortDesc[0]
        };

        const params = Object.assign(p, this.getParams());

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        this.$http
          .request(`/transactions`, {
            method: 'get',
            params: params,
            cancelToken: this.cancelSource.token
          })
          .then((response) => response.data)
          .then((response) => {
            items = response.data;
            total = response.total;
            pages = response.last_page;

            this.loading = false;
            resolve({
              items,
              total,
              pages
            });
          });
      });
    },
    getTransactions() {
      this.transactionsLoading = true;

      const params = this.getParams();

      this.$http
        .request(`/transactions/download`, {
          method: 'get',
          params: params
        })
        .then((response) => {
          let name =
            'Stallion_Period_' +
            moment(this.start_date).format('Ymd') +
            '_to_' +
            moment(this.end_date).format('Ymd') +
            '_Transactions.csv';

          if (this.statementId) {
            name =
              'Stallion_Statement_' + this.statementId + '_Transactions.csv';
          }
          this.downloadFileUrl(response.data, name, 'text/csv');
        })
        .catch((err) => {
          if (err.response.status === 404) {
            this.errorMessage(
              'No transactions exist for this period.',
              'Nothing to Export'
            );
          } else {
            this.errorMessage();
          }
        })
        .finally(() => {
          this.transactionsLoading = false;
        });
    },
    getPeriodSummary() {
      this.summaryLoading = true;

      const params = this.getParams();

      this.$http
        .request('/transactions/summary', {
          method: 'get',
          params: params
        })
        .then((response) => {
          this.downloadFileUrl(response.data, 'Stallion_' +
              moment(this.start_date).format('Ymd') +
              '_to_' +
              moment(this.end_date).format('Ymd') +
              '_Summary.pdf'
          );
        })
        .catch(() => {
        })
        .finally(() => {
          this.summaryLoading = false;
        });
    },
    getSingleInvoice(trasaction) {
      this.$http
        .get('/users/credit-history-invoice/' + trasaction.id)
        .then((response) => {
          this.downloadFileUrl(response.data, 'Stallion_Payment_' + trasaction.payment_transaction_id + '.pdf'
          );
        })
        .catch((err) => {
          if (err.response.status === 500) {
            this.errorMessage('No invoice available for this month');
          }
        });
    }
  }
};
</script>
