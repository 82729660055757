<template>
  <StallionDialog
    v-model="show"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    :title="`${edit ? 'Edit': 'Add'} Provider Credential`"
  >
    <template #content>
      <v-container>
        <v-row>
          <v-col cols="12">
            <h2>User</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              v-model="credential.user_id"
              outlined
              :items="users"
              hide-selected
              label="Email"
              clearable
              class="pa-0"
              item-value="id"
              item-text="email"
              :disabled="edit"
              :error-messages="errs.collect('email')"
              data-vv-name="email"
            />
          </v-col>
          <v-divider />
        </v-row>
        <v-row>
          <v-col cols="12">
            <h2>Settings</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="credential.region_id"
              v-validate="'required_if:company_location,1'"
              :error-messages="errs.collect('region')"
              data-vv-name="region"
              outlined
              :items="regions"
              item-text="name"
              item-value="id"
              label="Region"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="credential.identifier"
              outlined
              type="string"
              :error-messages="errs.collect('identifier')"
              label="Identifier"
              data-vv-name="identifier"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="credential.subcategory"
              outlined
              type="string"
              :error-messages="errs.collect('subcategory')"
              label="Subcategory"
              data-vv-name="subcategory"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-autocomplete
              v-model="credential.postage_provider_id"
              v-validate="'required'"
              outlined
              :error-messages="errs.collect('postage_provider_id')"
              data-vv-name="postage_provider_id"
              :items="postage_providers_list"
              label="Postage Provider"
              :search-input.sync="auto_container.provider"
              item-text="name"
              item-value="id"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="credential.active"
              v-validate="'required'"
              outlined
              :error-messages="errs.collect('active')"
              data-vv-name="active"
              :items="active_list"
              label="Is Active"
           
              item-text="label"
              item-value="id"
            />
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="credential.return_enabled"
              v-validate="'required'"
              outlined
              :error-messages="errs.collect('return_enabled')"
              data-vv-name="return_enabled"
              :items="[ { value: 0, text: 'No' }, { value: 1, text: 'Yes' } ]"
              label="Return Credentials"
            />
          </v-col>
        </v-row>

        <!-- Credential editor -->
        <v-row>
          <v-col cols="12">
            <h2>Credential</h2>
          </v-col>
        </v-row>
        <v-row cv-if="">
          <v-col
            cols="12"
            md="12"
          >
            <JsonEditorVue
              ref="JsonEditor"
              v-model="credential.credentials"
            />
          </v-col>
        </v-row>
      </v-container>      
    </template>

    <template #actions>
      <v-btn
        v-if="edit"
        color="primary"
        :loading="loading"
        :disabled="loading"
        @click="update()"
      >
        Update
      </v-btn>
      <v-btn
        v-else
        color="primary"
        :loading="loading"
        :disabled="loading"
        @click="create()"
      >
        Create
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
import JsonEditorVue from 'json-editor-vue'
import constants from '@/constants/index';
export default {
  components: {
    JsonEditorVue
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    providerCredential: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    options: {
      height: '400px',
      mode: 'code',
      onEditable: function(node) {
        console.log('node', node);
        return true;
      }
    },
    auto_container: {
      provider: null,
      active: null
    },
    dialog: false,
    disabled: false,
    loading: false,
    users: [],
    user_custom_provider: {},
    regions: [],
    default: {
      id: null,
      user_id: null,
      region_id: null,
      identifer: null,
      postage_provider_id: null,
      active: 1,
      subcategory: null,
      credentials: [],
      return_enabled: 0
    },
    credential: {
      id: null,
      user_id: null,
      region_id: null,
      identifer: null,
      postage_provider_id: null,
      active: 1,
      subcategory: null,
      credentials: [],
      return_enabled: 0
    },
    postage_providers_list: constants.POSTAGE_PROVIDERS || [],
    active_list: [
      {
        id: 0,
        label: 'Inactive'
      },
      {
        id: 1,
        label: 'Active'
      }
    ],
    edit: false
  }),
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  watch: {
    value: function() {
      console.log(this.providerCredential);
      if (this.providerCredential) {
        Object.assign(this.credential, this.providerCredential);

        if (this.providerCredential.user_custom_provider) {
          this.credential.user_id = this.providerCredential.user_custom_provider.user_id;
        }

        this.edit = true;
      } else {
        Object.assign(this.credential, this.default);

        this.edit = false;
      }
    }
  },
  mounted() {
    this.getShipCodes();
    this.getRegions();
  },
  methods: {
    getRegions() {
      this.$http
        .request(`/admin/regions`, {
          method: 'get'
        })
        .then(response => response.data)
        .then(response => {
          this.regions = response;
        });
    },
    getShipCodes() {
      this.$http
        .get('/admin/users/all')
        .then(response => response.data)
        .then(response => {
          this.users = response;
        })
        .catch(() => {});
    },
    create() {
      this.$validator.validate().then(result => {
        if (result) {
          this.loading = true;
          this.errs.clear();

          this.$http
            .post(`/admin/provider/credentials`, this.credential)
            .then(response => response.data)
            .then(() => {
              Event.fire('update-provider-credentials');
              this.$notify({
                group: 'main',
                title: 'Created',
                text: 'Provider credential has been created sucessfully',
                duration: 5000,
                type: 'success'
              });
              this.show = false;
            })
            .catch(err => {
              this.addToErrorBag(err.response.data.errors);
              this.$notify({
                group: 'main',
                title: 'Error',
                text: 'An error occurred',
                duration: 5000,
                type: 'error'
              });
            })

            .finally(() => {
              this.loading = false;
            });
        }
      });
    },

    update() {
      this.$validator.validate().then(result => {
        if (result) {
          this.loading = true;
          // this.credential.credentials = this.onGetData();
          this.errs.clear();

          this.$http
            .patch(
              `/admin/provider/credentials/${this.credential.id}`,
              this.credential
            )
            .then(response => response.data)
            .then(() => {
              Event.fire('update-provider-credentials');
              this.$notify({
                group: 'main',
                title: 'Updated',
                text: 'Provider credential has been updated sucessfully',
                duration: 5000,
                type: 'success'
              });

              this.show = false;
            })
            .catch(err => {
              this.addToErrorBag(err.response.data.errors);
              this.$notify({
                group: 'main',
                title: 'Error',
                text: 'An error occurred',
                duration: 5000,
                type: 'error'
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },

    addToErrorBag(api_errors) {
      if ($.isEmptyObject(api_errors)) {
        return this;
      }
      let self = this;
      // let ServerErrors = [];
      $.each(api_errors, function(field, messages) {
        $.each(messages, function(id, message) {
          self.errs.add({
            field: field,
            msg: message
          });
        });
      });
    },

    onGetData() {
      var data = {};
      try {
        data = this.$refs.editor.editor.get();
        console.log(data);
      } catch (error) {
        console.log('get error:', error);
        data = {};
      }
      return data;
    },

    onError() {
      console.log('error');
    }
  }
};
</script>
