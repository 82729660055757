<template>
  <FullPageLayout
    title="Drop-off Scan"
    desc="Scan shipments received at your location to receive commission to be paid out monthly."
  >
    <template #default>
      <div v-if="$auth.user">
        <v-row
          v-if="$auth.user.scan_location != null"
          row
          wrap
        >
          <v-col
            v-if="$auth.user.scan_location != null"
            id="scan-page"
            cols="12"
          >
            <strong>Location: </strong> {{ $auth.user.scan_location.name }}<br>
            <strong>Address: </strong> {{ $auth.user.scan_location.address1 }},
            {{ $auth.user.scan_location.city }},
            {{ $auth.user.scan_location.province_code }}
            {{ $auth.user.scan_location.postal_code }}
          </v-col>

          <v-col
            v-if="$auth.user.scan_location.active"
            cols="12"
          >
            <v-form>
              <v-container>
                <v-row style="justify-content: space-between;">
                  <v-text-field
                    v-model="barcode"
                    label="Manual Entry"
                    class="mr-2"
                    outlined
                    clearable
                    dense
                    hide-details
                    @keydown.enter.prevent="manualScan"
                  />

                  <v-btn
                    color="primary"
                    @click="manualScan"
                  >
                    Submit
                  </v-btn>
                </v-row>
              </v-container>
            </v-form>
          </v-col>

          <v-col
            v-if="$auth.user.scan_location.active"
            id="scan-page"
            cols="12"
          >
            <v-alert
              v-for="(item, index) in errors"
              :key="index"
              dense
              :value="true"
              dismissible
              type="error"
              transition="fade-transition"
            >
              {{ item }}
            </v-alert>

            <v-alert
              v-for="(item, index) in warnings"
              :key="index"
              :value="true"
              dismissible
              type="warning"
              transition="fade-transition"
            >
              {{ item }}
            </v-alert>

        
            <v-data-table
              class="table-striped"
              :headers="headers"
              :items="shipments"
              :loading="loading"
              no-data-text="Awaiting scanned items"
              :page.sync="pagination.page"
              :items-per-page.sync="pagination.itemsPerPage"
              :sort-by.sync="pagination.sortBy"
              :sort-desc.sync="pagination.descending"
            >
              <template #item.tracking="{ item }">
                <div v-if="item.tracking_events.length > 0">
                  {{ item.tracking_events[0].status }}
                </div>
              </template>
              <template #item.created_at="{ item }">
                {{ item.created_at | datetime_short }}
              </template>
            </v-data-table>
          </v-col>

          <v-col
            v-else
            id="scan-page"
            cols="12"
          >
            <v-alert
              class="pa-5"
              outlined
              type="error"
              transition="fade-transition"
            >
              Your location is deactivated
            </v-alert>
          </v-col>
        </v-row>
        <v-row
          v-else
          row
          wrap
        >
          <v-col
            id="scan-page"
            cols="12"
          >
            <h2>Your location has not been registered.</h2>
          </v-col>
        </v-row>
      </div>
    </template>
  </FullPageLayout>
</template>

<script>
import moment from 'moment';

export default {
  metaInfo() {
    return {
      title: `Drop Off Scan | Stallion Express`
    }
  },
  data() {
    return {
      loading_a: false,
      shipments: [],
      warnings: [],
      activeBtn: 1,
      showNav: true,
      barcodes: [],
      box: 0,
      selected: [],
      headers: [
        {
          text: 'Ship Code',
          sortable: false,
          value: 'ship_code'
        },
        {
          text: 'Recipient',
          sortable: false,
          value: 'name'
        },
        {
          text: 'Country Code',
          sortable: false,
          value: 'country_code'
        },
        {
          text: 'Tracking Number',
          sortable: false,
          value: 'tracking_code'
        },
        {
          text: 'Created At',
          sortable: false,
          value: 'created_at'
        }
      ],
      pagination: {
        page: 1,
        itemsPerPage: 25,
        sortBy: null,
        descending: false
      },
      loading: false,
      errors: [],

      salesByDay: [],
      scansByDay: [],
      scansByType: [],
      pieOptions: {
        title: {
          display: true,
          text: 'Scans By Type',
          position: 'top'
        },
        rotation: -0.7 * Math.PI
      },
      summary: [],
      by_date: [],
      pagination2: {
        page: 1,
        itemsPerPage: 25,
        sortBy: 'quantity',
        descending: true
      },
      barcode: null,
      headers2: [
        {
          text: 'Type',
          value: 'type'
        },
        {
          text: 'Quantity',
          value: 'quantity'
        },
        {
          text: 'Earned',
          value: 'earned'
        }
      ],
      date: moment()
        // .subtract(1, "months")
        .format('YYYY-MM'),
      endDate: moment()
        // .subtract(1, "months")
        .format('YYYY-MM'),
      menu: false
    };
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.date ? moment(this.date).format('MMMM YYYY') : '';
    },
    total() {
      if (this.summary.length > 0)
        return this.summary.reduce((a, b) => a + parseFloat(b.earned) || 0, 0);
      else return 0;
    }
  },
  watch: {
    date: function() {
      this.getMonthlySummary();
    }
  },
  created() {
    document.activeElement.blur();
    // Add barcode scan listener and pass the callback function
    this.$barcodeScanner.init(this.onBarcodeScanned);
  },
  destroyed() {
    // Remove listener when component is destroyed
    this.$barcodeScanner.destroy();
  },
  mounted() {},

  methods: {
    manualScan() {
      this.onBarcodeScanned(this.barcode);

      this.barcode = null;
    },
    getRandomColor() {
      var letters = '0123456789ABCDEF';
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    // Create callback function to receive barcode when the scanner is already done
    onBarcodeScanned(barcode) {
      console.log(barcode);

      // this.$notify({
      //   group: 'main',
      //   title: 'Item Scanned',
      //   text: barcode,
      //   duration: 3000
      // });

      let self = this;

      this.scan(barcode).then(res => {
        console.log(res);
        var s = res.shipments;
        s.forEach(function(element) {
          self.shipments.push(element);
        });
      });

      // do something...
    },
    // Reset to the last barcode before hitting enter (whatever anything in the input box)
    resetBarcode() {
      this.$barcodeScanner.getPreviousCode();
      // do something...
    },
    scan(barcode) {
      return new Promise((resolve, reject) => {
        this.$http
          .post('/scan/' + barcode + '/received')
          .then(response => response.data)
          .then(response => {
            if (response.success == true) {
              this.playSuccessSound();
              resolve({
                shipments: response.shipments
              });
              if (response.voided > 0) {
                this.playErrorSound();
                this.warnings.push(
                  response.voided + ' shipments have been voided in closeout'
                );
                this.getMonthlySummary();
              }
            } else {
              this.playErrorSound();
              this.errors.push(response.error);
              reject({});
            }
          })
          .catch(err => {
            this.playErrorSound();
            console.log(err.response);
            this.errors.push('Server error');
            reject({});
          });
      });
    }
  }
};
</script>
