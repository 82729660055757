<template>
  <v-dialog
    v-model="show"
    persistent
    width="800"
    transition="dialog-bottom-transition"
    @keydown.esc="cancelEdit"
  >
    <v-card v-if="user">
      <v-toolbar
        dark
        color="primary"
        class="elevation-0 px-4"
      >
        <h2>
          <v-icon
            small
            left
          >
            {{ !edit ? 'mdi-plus' : 'mdi-pencil' }}
          </v-icon>{{ !edit ? 'Create' : 'Update' }} User
        </h2>
        <v-spacer />
        <v-btn
          icon
          dark
          @click="cancelEdit"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-form
          autocomplete="off"
          @submit.prevent
        >
          <v-container fluid>
            <v-tabs v-model="tab">
              <v-tab>Account</v-tab>
              <v-tab>Permissions</v-tab>
              <v-tab>Restrictions</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <!--Account-->
              <v-tab-item>
                <v-container>
                  <v-layout wrap>
                    <v-flex
                      xs12
                      md12
                      mt-5
                    >
                      <v-text-field
                        v-model="user.username"
                        v-validate="'required|min:5|max:191'"
                        outlined
                        label="Username"
                        data-vv-name="username"
                        :error-messages="errs.collect('username')"
                        type="username"
                        name="username"
                        data-vv-validate-on="blur"
                        class="pr-2"
                        :disabled="edit"
                      />
                    </v-flex>
                    <v-flex
                      xs12
                      md12
                    >
                      <v-text-field
                        v-model="user.name"
                        v-validate="'required'"
                        outlined
                        label="Name"
                        data-vv-name="name"
                        :error-messages="errs.collect('name')"
                        type="name"
                        name="name"
                        data-vv-validate-on="blur"
                        class="pr-2"
                      />
                    </v-flex>
                    <v-flex
                      xs12
                      md12
                    >
                      <v-text-field
                        v-model="user.email"
                        v-validate="'required|email'"
                        outlined
                        label="Email"
                        data-vv-name="email"
                        :error-messages="errs.collect('email')"
                        type="email"
                        name="email"
                        data-vv-validate-on="blur"
                        class="pr-2"
                      />
                    </v-flex>

                    <v-flex
                      v-if="edit"
                      xs12
                      md12
                    >
                      <v-text-field
                        ref="password"
                        v-model="user.password"
                        v-validate="'min:8|verify_password'"
                        outlined
                        label="Password"
                        data-vv-name="password"
                        :error-messages="errs.collect('password')"
                        type="password"
                        name="password"
                        data-vv-validate-on="blur"
                      />
                    </v-flex>

                    <v-flex
                      v-if="edit"
                      xs12
                      md12
                    >
                      <v-text-field
                        ref="confirm"
                        v-model="user.password_confirmation"
                        v-validate="'confirmed:password'"
                        outlined
                        label="Confirm Password"
                        data-vv-name="confirm"
                        :error-messages="errs.collect('confirm')"
                        type="password"
                        name="confirm"
                        data-vv-validate-on="blur"
                      />
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-tab-item>

              <!--Permissions-->
              <v-tab-item>
                <v-container fluid>
                  <v-row class="mt-3">
                    <v-col
                      v-for="(permission, index) in permissionOptions"
                      :key="index"
                      cols="6"
                    >
                      <v-checkbox
                        v-model="user.sub_user_settings.permissions"
                        :value="permission.key"
                        :label="permission.name"
                        :hint="permission.description"
                        persistent-hint
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <!--Restrictions-->
              <v-tab-item>
                <v-container>
                  <div
                    v-for="(list, index) in restrictionOptions"
                    :key="index"
                  >
                    <v-checkbox
                      v-model="user.sub_user_settings.restrictions"
                      :value="list.key"
                      :label="list.name"
                      cols="6"
                      md="6"
                    />
                    <span class="mr-4">{{ list.description }}</span>
                  </div>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-container>
        </v-form>
      </v-card-text>

      <v-divider />
      <v-card-actions class="py-4 px-8">
        <v-spacer />
        <v-btn
          outlined
          color="primary"
          class="px-5"
          @click="cancelEdit"
        >
          Cancel
        </v-btn>
        <v-btn
          v-if="edit"
          color="primary"
          class="px-7"
          :disabled="loading"
          :loading="loading"
          @click="updateUser"
        >
          Update
        </v-btn>
        <v-btn
          v-else
          color="primary"
          class="px-7"
          :disabled="loading"
          :loading="loading"
          @click="saveUser"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      tab: null,
      permissionOptions: [],
      restrictionOptions: [],
      edit: false,
      user: this.getDefaultUser(),
      show: false
    };
  },
  created() {
    this.getRestrictions();
    this.getPermissions();
  },
  methods: {
    startNew() {
      this.show = true;
      this.user = Object.assign({}, this.getDefaultUser());
      this.edit = false;
    },
    startEdit(user) {
      this.show = true;
      this.user = Object.assign(this.getDefaultUser(), user);
      this.edit = true;
    },
    getRestrictions() {
      this.$http
        .get('sub-user-restrictions')
        .then((response) => response.data)
        .then((response) => {
          this.restrictionOptions = response;
        });
    },
    getPermissions() {
      this.$http
        .get('sub-user-permissions')
        .then((response) => response.data)
        .then((response) => {
          this.permissionOptions = response;
        });
    },
    cancelEdit() {
      this.show = false;
      this.tab = null;
      this.user = null;

      console.log(this.user);
    },
    saveUser() {
      this.loading = true;
      this.$http
        .post('/sub-users', this.user)
        .then((response) => response.data)
        .then((response) => {
          this.processSuccess(response, 'User has been created');
        })
        .catch((err) => {
          this.processError(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateUser() {
      this.loading = true;
      this.$http
        .put(`/sub-users/${this.user.id}`, this.user)
        .then((response) => response.data)
        .then((response) => {
          this.processSuccess(response, 'User has been updated');
        })
        .catch((err) => {
          this.processError(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    processSuccess(response, message) {
      swal({
        title: 'Success',
        text: message,
        icon: 'success'
      });
      this.$emit('save');
      this.cancelEdit();
    },
    processError(err) {
      if (
        err.response.status === 422 &&
        typeof err.response.data.errors !== 'undefined'
      ) {
        const errors = err.response.data.errors;
        let message = '';

        for (var field in errors) {
          message += `${errors[field][0]}\n`;
        }
        this.errorMessage(message);
      } else {
        this.errorMessage('An error occurred while processing your request');
      }
    },
    getDefaultUser() {
      return {
        id: null,
        email: null,
        name: null,
        username: null,
        password: null,
        password_confirmation: null,
        sub_user_settings: {
          permissions: [],
          restrictions: []
        }
      };
    }
  }
};
</script>
<style>
.v-messages {
  padding-top: 10px !important;
  /* padding-left: 33px !important; */
}

.v-input--checkbox {
  margin: 0 !important;
}
</style>
