<template>
  <FullPageLayout
    title="Print Jobs"
  >
    <template #default>
      <downloads-table />
    </template>
    <template #footer />
  </FullPageLayout>
</template>

<script>
export default {

  data() {
    return {
    };
  },
  watch: {},
  mounted() {},
  methods: {}
};
</script>
