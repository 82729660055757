<template>
  <FullPageLayout title="Supplies Orders">
    <template #default>
      <v-layout>
        <v-flex xs12>
          <v-form>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  style="padding: 0 5px"
                >
                  <v-text-field
                    v-model="search"
                    outlined
                    pt-0
                    label="Search"
                    prepend-inner-icon="search"
                    clearable
                    class="pa-0"
                    dense
                    @input="submitSearch()"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                  style="padding: 0 5px"
                >
                  <user-search
                    v-model="user_id"
                    label="Search User"
                    @change="submitSearch()"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                  style="padding: 0 5px"
                >
                  <v-select
                    v-model="current_location_id"
                    outlined
                    dense
                    class="pa-0"
                    :items="locations"
                    item-text="name"
                    item-value="id"
                    label="Current Location"
                    clearable
                    @change="submitSearch()"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                  style="padding: 0 5px"
                >
                  <v-select
                    v-model="destination_location_id"
                    outlined
                    dense
                    class="pa-0"
                    :items="locations"
                    item-text="name"
                    item-value="id"
                    label="Destination Location"
                    clearable
                    @change="submitSearch()"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                  style="padding: 0 5px"
                >
                  <v-select
                    v-model="supply_statuses_selected"
                    outlined
                    dense
                    class="pa-0"
                    :items="supply_statuses"
                    item-value="id"
                    item-text="description"
                    label="Status"
                    multiple
                    clearable
                    @change="submitSearch()"
                  >
                    <template #selection="{ item, index }">
                      <span v-if="index === 0">
                        <span>{{ item.description }}</span>
                      </span>
                      <span
                        v-if="index === 1"
                        class="grey--text text-caption"
                      >(+{{
                        supply_statuses_selected.length - 1
                      }}
                        others)</span>
                    </template>
                  </v-select>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                  style="padding: 0 5px"
                >
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-model="start_date"
                        dense
                        outlined
                        pt-0
                        prepend-inner-icon="event"
                        label="Start Date"
                        class="pa-0"
                        readonly
                        v-on="on"
                      />
                    </template>

                    <v-date-picker
                      v-model="start_date"
                      @change="submitSearch()"
                      @input="menu1 = false"
                    />
                  </v-menu>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                  style="padding: 0 5px"
                >
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template #activator="{ on }">
                      <v-text-field
                        v-model="end_date"
                        outlined
                        dense
                        prepend-inner-icon="event"
                        label="End Date"
                        readonly
                        v-on="on"
                      />
                    </template>

                    <v-date-picker
                      v-model="end_date"
                      @change="submitSearch()"
                      @input="menu2 = false"
                    />
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-flex>
      </v-layout>

      <v-layout wrap>
        <v-flex xs12>
          <div v-if="condensed">
            <v-card-title>
              <span class="text-h3">Order Items</span>
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
                @input="submitSearch()"
              />
            </v-card-title>
          </div>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :loading="loading"
            show-select
            item-key="id"
            :items="items"
            :options.sync="options"
            class="table-striped"
            :server-items-length="totalItems"
            :footer-props="{
              'items-per-page-options': [
                50, 100, 200, 500
              ]
            }"
            :items-per-page="25"
            @click:row="rowClick"
          >
            <template #item.created_at="{ item }">
              {{ item.created_at | date_pretty }}
              <br>
              ({{ item.created_at | datetime_from }})
            </template>

            <template #item.user.name="{ item }">
              {{ item.user.business_name || item.user.name }}
            </template>

            <template #item.pickup_location.name="{ item }">
              <span>{{ getPickupLocation(item) }}</span>
            </template>

            <template #item.status.description="{ item }">
              <span>
                {{ item.status.description }}
              </span>
            </template>

            <template #item.quantity="{ item }">
              {{ item.items.length }}
            </template>
            <template #item.total="{ item }">
              {{ item.total | currency }}
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </template>
    <template #footer>
      <div class="d-flex">
        <PrintSuppliesOrderDialog
          :invoices="selected"
          :disabled="selected.length == 0"
        />

        &nbsp;
        <v-menu offset-y>
          <template #activator="{ on }">
            <v-btn
              color="primary"
              :disabled="!voidable"
              v-on="on"
              @click="$refs.suppliesVoid.openDialog()"
            >
              <v-icon left>
                mdi-package-variant-closed
              </v-icon>Void
            </v-btn>

            <v-btn
              v-if="can('supplies_orders.edit')"
              color="primary"
              :disabled="selected.length == 0"
              outlined
              class="mx-2"
              v-on="on"
              @click="$refs.update_supplies_order_status.openDialog()"
            >
              <v-icon
                small
                left
              >
                mdi-pencil
              </v-icon>Status
            </v-btn>
          </template>
        </v-menu>

        <UpdateSuppliesOrderStatusDialog
          ref="update_supplies_order_status"
          :invoices="selected"
          :auto_status="auto_status"
          :disabled="selected.length == 0"
        />

        &nbsp;
        <v-btn
          v-if="can('supplies_orders.mark_complete')"
          color="primary"
          :disabled="disableFulfillButton"
          :loading="fullfill_invoices_loading"
          @click="fulfillInvoices"
        >
          <v-icon left>
            mdi-thumb-up
          </v-icon>Complete
        </v-btn>
      </div>
      
      <VoidSuppliesOrderDialog
        ref="suppliesVoid"
        :disabled="!voidable"
        :invoices="selected"
      />
    </template>
  </FullPageLayout>
</template>

<script>
import UpdateSuppliesOrderStatusDialog from './UpdateSuppliesOrderStatusDialog.vue';
import VoidSuppliesOrderDialog from '@/components/shared/VoidSuppliesOrderDialog.vue';
import PrintSuppliesOrderDialog from '@/components/shared/PrintSuppliesOrderDialog.vue';
import SUPPLIES_CONSTANTS from '@/constants/supplies.js';

export default {
  components:{
    PrintSuppliesOrderDialog,
    VoidSuppliesOrderDialog,
    UpdateSuppliesOrderStatusDialog
  },
  props: {
    // admin stuff
    admin: {
      default: false,
      type: Boolean
    },
    invoiceId: {
      type: String,
      default: null
    },

    pending: {
      default: null,
      type: Boolean
    },
    condensed: {
      default: false,
      type: Boolean
    },
    itemsPerPage: {
      default: 10,
      type: Number
    }
  },
  data() {
    return {
      drawer: null,

      status_id: null,
      selected: [],
      options: {
        sortBy: ['created_at'],
        sortDesc: [true]
      },
      loading: false,
      totalItems: 0,
      items: [],
      fullfill_invoices_loading: false,
      supply_statuses: [],
      supply_statuses_selected: null,
      destination_location_id: null,
      current_location_id: null,
      searchID: null,
      searchEmail: null,

      headers: [
        {
          text: 'Invoice ID',
          value: 'invoice_number'
        },
        {
          text: 'User',
          value: 'user.email',
          sortable: false
        },
        {
          text: 'Name',
          value: 'user.name',
          sortable: false
        },
        {
          text: 'Current Location',
          sortable: false,
          value: 'current_location.name'
        },
        {
          text: 'Destination',
          sortable: false,
          value: 'pickup_location.name'
        },
        {
          text: 'Status',
          value: 'status.description',
          sortable: false
        },
        {
          text: '# Line Items',
          value: 'quantity',
          sortable: false
        },
        {
          text: 'Total',
          value: 'total'
        },
        {
          text: 'Created At',
          value: 'created_at'
        }
      ],

      search: null,
      start_date: null, //moment().subtract(1, 'years').format('YYYY-MM-DD'),
      end_date: null, //moment().format('YYYY-MM-DD'),
      menu1: null,
      menu2: null,
      timer: null,
      auto_status: 0,
      user_id: null,
      email: null,
      locations: []
    };
  },
  computed: {
    disableFulfillButton() {
      if (this.selected.length == 0) {
        return true;
      }
      // 2 = Fulfilled, 3 = Voided
      return this.selected.some((item) => {
        return [2, 3].includes(item.status_id);
      });
    },

    voidable() {
      if (
        this.selected.length > 0 &&
        this.selected.every((r) =>
          [
            SUPPLIES_CONSTANTS.SUPPLIES_STATUS.UNFULFILLED,
            SUPPLIES_CONSTANTS.SUPPLIES_STATUS.READY,
            SUPPLIES_CONSTANTS.SUPPLIES_STATUS.PENDING
          ].includes(r.status_id)
        )
      ) {
        return true;
      }

      return false;
    }
  },
  watch: {
    '$route.query': function () {
      this.updateTable();
    },
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    },
  },
  mounted() {
    this.getLocations();
    this.getStatuses();
    Event.listen('update-supplies-orders', () => {
      // this.selectedShipments = [];
      this.updateTable();
    });
  },
  methods: {
    rowClick(item) {
      this.$router.push({
        name: 'admin-supplies-show',
        params: {
          id: item.invoice_number
        }
      });
    },
    getLocations() {
      var params = {
        company_location: true
      };

      this.$http
        .request(`/admin/dropoff-locations`, {
          method: 'get',
          params: params
        })
        .then((response) => response.data)
        .then((response) => {
          this.locations = response;
        });
    },
    getStatuses() {
      this.$http
        .get(`/admin/supplies/statuses`)
        .then((response) => response.data)
        .then((response) => {
          this.supply_statuses = response;
          this.supply_statuses.push({ id: 6, description: 'Pending' });
        });
    },
    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.options.page = 1;
      this.timer = setTimeout(function () {
        self.updateTable();
      }, ms);
    },
    updateTable() {
      this.$store.state.main.selectedBatches = [];
      this.totalItems = 0;
      this.items = [];

      this.getDataFromApi().then((data) => {
        this.items = data.items;
        this.totalItems = data.total;
      });
    },
    getDataFromApi() {
      this.loading = true;
      this.selected = [];
      return new Promise((resolve) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let items = [];
        let total = 0;
        let pages = 0;

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        const queryStatus = this.$route.query.status_id
          ? [this.$route.query.status_id]
          : null;

        var params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sortBy[0],
          desc: sortDesc[0],
          search: this.search,
          start_date: this.start_date,
          end_date: this.end_date,
          status_id: this.supply_statuses_selected?.length
            ? this.supply_statuses_selected
            : queryStatus,
          delivery: this.$route.query.delivery,
          user_id: this.user_id,
          current_location_id: this.current_location_id,
          destination_location_id: this.destination_location_id
        };

        this.$http
          .request(`/admin/supplies-invoices`, {
            method: 'get',
            params: params,
            cancelToken: this.cancelSource.token
          })

          .then((response) => response.data)
          .then((response) => {
            items = response.data;
            total = response.total;
            pages = response.last_page;

            this.loading = false;
            resolve({
              items,
              total,
              pages
            });
          });
      });
    },
    getPickupLocation(order) {
      if (order.destination_address) {
        return 'Delivery';
      }
      if (order.pickup_location) {
        return order.pickup_location.name;
      }
      return this.$auth.user.branch_location.name;
    },

    /**
     * Apply fullfill status to selected invoices
     */
    fulfillInvoices() {
      this.fullfill_invoices_loading = true;
      console.log(this.$refs.update_supplies_order_status);
      this.$refs.update_supplies_order_status.status = 2;
      // this.$refs.update_supplies_order_status.show = true;
      this.$refs.update_supplies_order_status.updateStatuses();
      Event.listen('update-supplies-order-statuses', () => {
        this.fullfill_invoices_loading = false;
      });
    }
  }
};
</script>
