<template>
  <v-row>
    <StallionDialog
      v-model="dialog"
      persistent
      transition="dialog-bottom-transition"
      :width="400"
      title="Change Status"
    >
      <!-- <template #activator="{ on }">
        <v-btn
          x-small
          color="primary"
          dark
          v-on="on"
        >
          Change Status
        </v-btn>
      </template> -->

      <template #content>
        <v-form>
          <v-container
            container--fluid
            grid-list-lg
          >
            <v-layout wrap>
              <!-- FIELDS -->

              <v-flex xs12>
                <!-- <v-text-field v-model="coupon.return_request_image_price" label="Return Request Image" type="number" step="0.01" min="0" required></v-text-field> -->
                <v-select
                  v-model="selectStatus"
                  :items="statuses"
                  item-text="description"
                  item-value="id"
                  label="Status"
                  persistent-hint
                  return-object
                  single-line
                />
              </v-flex>
              <v-btn
                color="primary"
                :loading="loading"
                :disabled="disabled"
                @click.prevent="changeStatus()"
              >
                Confirm
              </v-btn>
              <!-- END FIELDS -->
            </v-layout>
          </v-container>
        </v-form>
      </template>
    </StallionDialog>
  </v-row>
</template>

<script>
export default {
  props: {
    statuses: {
      type: Array,
      default: () => []
    },
    id: {
      type: Number,
      default: 1
    }
  },
  data: () => ({
    dialog: false,
    disabled: false,
    loading: false,
    selectStatus: ''
  }),
  methods: {
    changeStatus() {
      this.loading = true;
      this.disabled = true;
      this.$http
        .put(`/return-statuses/${this.id}`, this.selectStatus)
        .then(res => {
          if (res.data.success) {
            this.successMessage(
              'Status successfully changed for return shipment.'
            );
          } else {
            this.errorMessage(res.data.error);
          }
        })
        .catch(() => {
        })
        .finally(() => {
          this.loading = false;
          this.disabled = false;
          this.dialog = false;
          this.$emit('updateReturns');
        });
    }
  }
};
</script>
