<template>
  <div
    v-if="order && order.items"
    xs12
  >
    <div class="d-flex flex-row justify-space-between">
      <h4>Items Summary</h4>
    </div>

    <div
      v-if="order.weight"
      class="d-flex flex-row justify-space-between"
    >
      <div class="shipment-title">
        # Items
      </div>
      <div>{{ order.items_count }}</div>
    </div>

    <div
      v-if="order.weight"
      class="d-flex flex-row justify-space-between"
    >
      <div class="shipment-title">
        Total Weight
      </div>
      <div>{{ order.weight }} {{ order.weight_unit }}</div>
    </div>

    <div
      v-if="order.value"
      class="d-flex flex-row justify-space-between"
    >
      <div class="shipment-title">
        Total Value
      </div>
      <div>{{ order.value | currency }} {{ order.currency }}</div>
    </div>
    <v-divider class="my-2" />

    <div
      v-for="item in order.items"
      :key="item.id"
    >
      <!-- <div class="d-flex flex-row justify-space-between">
        <h4>Item {{ index + 1 }}</h4>
      </div> -->

      <div class="d-flex flex-row justify-space-between">
        <h4>
          {{ item.title }}
        </h4>
      </div>

      <!-- <div class="d-flex flex-row justify-space-between">
        <div class="shipment-title">{{ item.title }}</div>
        <div></div>
      </div> -->

      <div
        v-if="item.sku"
        class="d-flex flex-row justify-space-between"
      >
        <div class="shipment-title">
          SKU
        </div>
        <div>{{ item.sku }}</div>
      </div>

      <div class="d-flex flex-row justify-space-between">
        <div class="shipment-title">
          Quantity
        </div>
        <div>{{ item.quantity }}</div>
      </div>

      <div
        v-if="item.weight"
        class="d-flex flex-row justify-space-between"
      >
        <div class="shipment-title">
          Weight
        </div>
        <div>{{ item.weight }} {{ item.weight_unit }}</div>
      </div>

      <div
        v-if="item.value"
        class="d-flex flex-row justify-space-between"
      >
        <div class="shipment-title">
          Value
        </div>
        <div>{{ item.value | currency }} {{ item.currency }}</div>
      </div>

      <v-divider class="my-2" />
    </div>

    <div
      v-if="order.shipping_cost"
      class="d-flex flex-row justify-space-between"
    >
      <h4>
        Shipping Cost
      </h4>
    </div>

    <div
      v-if="order.shipping_cost"
      class="d-flex flex-row justify-space-between"
    >
      <div class="shipment-title">
        Total
      </div>
      <div>{{ order.shipping_cost | currency }}</div>
    </div>

    <v-divider class="my-2" />
  </div>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      default: null
    }
  }
};
</script>
