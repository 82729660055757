<template>
  <StallionDialog
    v-model="dialog"
    :width="800"
    title="Confirm Order"
  >
    <template #content>
      <table class="cart-table">
        <thead>
          <tr>
            <th class="text-left font-weight-bold">
              Item
            </th>
            <th class="text-center font-weight-bold">
              Quantity
            </th>
            <th class="text-right font-weight-bold">
              Unit Price
            </th>
            <th class="text-right font-weight-bold px-3">
              Total
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in cart.items"
            :key="'checkout_' + item.id"
          >
            <td class="text-left pl-0">
              <h2>{{ item.name }}</h2>
            </td>

            <td class="text-center">
              {{ item.purchase_quantity }}
            </td>
            <td class="text-right">
              {{ item.price | currency }}
            </td>
            <td
              class="text-right"
            >
              {{ (item.purchase_quantity * item.price) | currency }}
            </td>
            <!-- <td props.item></td> -->
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td
              class="text-right subheading font-weight-bold"
              colspan="3"
            >
              Subtotal
            </td>
            <td class="text-right">
              {{ cart.subtotal | currency }}
            </td>
          </tr>
          <tr>
            <td
              class="text-right subheading font-weight-bold"
              colspan="3"
              props.item.
            >
              Tax
            </td>
            <td class="text-right">
              {{ cart.tax | currency }}
            </td>
          </tr>
          <tr>
            <td
              class="text-right subheading font-weight-bold"
              colspan="3"
              props.item.
            >
              Total
            </td>
            <td class="text-right">
              {{ cart.total | currency }}
            </td>
          </tr>
        </tfoot>
      </table>

   
      <v-row>
        <v-col cols="12">
          <v-textarea
            v-model="comment"
            style="height: 70%"
            outlined
            label="Comments"
            name="comment"
            hint="Add comments to your purchase"
          />
        </v-col>
      </v-row>
      
      <v-row>
        <v-col cols="12">
          <v-alert
            text
            type="warning"
          >
            Please confirm your pickup location before placing an order
          </v-alert>
        </v-col>
      </v-row>
    </template>

    <template #actions>
      <v-btn
      
        color="primary"
        :loading="loading"
        :disabled="loading"
        @click="closeoutNew()"
      >
        Pay Now
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
import { mapActions } from 'vuex';
// import NumberInputSpinner from 'vue-number-input-spinner';

export default {
  props: {
    pickupLocation: {
      type: Object,
      required: true
    },
    deliverSupplies: {
      type: Boolean,
      default: false
    },
    validate: {
      type: Function,
      default: () => {
        return true;
      }
    }
  },
  data() {
    return {
      dialog: null,
      loading: false,
      comment: null
    };
  },
  computed: {
    cart() {
      return this.$store.state.cart;
    }
  },
  watch: {
    dialog: function (val) {
      if (val) {
        if (!this.pickupLocation && !this.deliver_supplies) {
          this.validate().then((valid) => {
            if (!valid) {
              this.dialog = false;
            }
          });
        }
      }
    }
  },
  mounted() {
    // this.closeDialog();
  },
  methods: {
    ...mapActions(['openDialog', 'closeDialog', 'clearCart']),
    ...mapActions('cart', ['clearCart']),
    pay() {
      this.openDialog('asdasdas');
    },
    closeoutNew: function () {
      if (!this.creditCheck(this.cart.total)) {
        return null;
      }

      this.loading = true;

      this.$http
        .post('/supplies/purchase', {
          items: this.cart.items,
          comment: this.comment,
          pickup_location: this.pickupLocation,
          deliver_supplies: this.deliverSupplies
        })
        .then((response) => {
          var res = response.data;
          console.log(res);

          if (res.success) {
            Event.fire('get-credits');
            this.clearCart();
            this.$router.push({
              name: 'supplies-order',
              params: {
                id: res.supplies_invoice.invoice_number
              },
              query: {
                print: true
              }
            });
          } else {
            this.errorMessage(res.errors);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openDialog() {
      this.dialog = true;
    }
  }
};
</script>

<style scoped>
.cart-table thead td,
.cart-table tbody td {
  border-top: none !important;
}
</style>
