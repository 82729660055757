import Vue from 'vue';
import store from '../store';
const AuthPlugin = {};

AuthPlugin.install = function (Vue) {
  Vue.mixin({
    methods: {
      logout(redirect = true) {
        this.$http({
          method: 'post',
          url: '/auth/logout'
        }).then(() => {
          localStorage.removeItem('vuex');

          this.$store.dispatch('clearAll');

          if(redirect){
            this.$router.push({ path: '/login' });
          }
        });
      },
      login(username, password, remember = false, url = '/auth/login', redirect = '/') {
        return new Promise((resolve, reject) => {
          this.getCsrf().then(() => {
            this.$http
              .post(url, {
                username,
                password,
                remember
              })
              .then((res) => {
                if (res.data.group_token) {
                  this.$cookies.set(
                    'group-token',
                    res.data.group_token,
                    60 * 60 * 24
                  );
                }

                this.getUser().then(() => {
                  // IMPORTANT: set preferences here to fix issues on page reload
                  if (this.$auth.user?.preferences?.value) {
                    this.$store.commit(
                      'main/updatePreferences',
                      this.$auth.user.preferences.value
                    );
                  }

                  this.$router.push(redirect);
                });

                resolve(res.data);
              })
              .catch((res) => {
                reject(res);
              });
          });
        });
      },
      getCsrf() {
        return new Promise((resolve, reject) => {
          this.$http({
            method: 'get',
            url: '/sanctum/csrf-cookie',
            baseURL: '/'
          })
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      getUser() {
        return new Promise((resolve, reject) => {
          this.$http
            .get('/auth/user')
            .then((res) => {
              this.$store.commit('auth/setUser', res.data.data);
              this.$store.commit('auth/setAuthenticated', true);
              if(this.isScaleInitialized() || this.isPrinterInitialized()){
                this.initializeQz();
              }
              resolve(res.data.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    }
  });

  Vue.prototype.$auth = store.state.auth;
  Vue.auth = store.state.auth;
};

Vue.use(AuthPlugin);
