<template>
  <div>
    <div>
      <v-breadcrumbs
        large
        :items="[
          {
            text: 'Home',
            exact: true,
            to: '/dashboard'
          },
          {
            text: 'Returns',
            exact: true,
            to: '/returns'
          },

          {
            text: 'Orders',
            exact: true,
            to: '/returns/orders'
          },

          {
            text: id,
            exact: true,
            to: '/returns/orders/' + id
          }
        ]"
      />
    </div>
    <v-container class="mb-100">
      <v-row>
        <v-col cols="12">
          <h1 v-if="order">
            Order {{ order.id }}
          </h1>
        </v-col>
        <v-col cols="12">
          <v-card>
            <table
              v-if="order"
              class="order-table"
            >
              <thead>
                <tr>
                  <th class="text-h4 text-left font-weight-bold">
                    Item
                  </th>
                  <!-- <th class="title text-left font-weight-bold">Image</th> -->
                  <th class="text-h4 text-left font-weight-bold">
                    Contents
                  </th>
                  <th class="text-h4 text-left font-weight-bold">
                    Status
                  </th>

                  <th class="text-h4 text-right font-weight-bold">
                    Handling Fee
                  </th>
                  <th class="text-h4 text-right font-weight-bold">
                    Tax
                  </th>

                  <th class="text-h4 text-right font-weight-bold">
                    Subtotal
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in order.returns"
                  :key="item.id"
                >
                  <td>{{ item.unique_id }}</td>
                  <td>{{ item.item }}</td>
                  <td>{{ item.status.description }}</td>

                  <td class="text-right">
                    {{ item.subtotal | currency }}
                  </td>
                  <td class="text-right">
                    {{ item.tax | currency }}
                  </td>
                  <td class="text-right">
                    {{ item.total | currency }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td
                    class="text-right subheading font-weight-bold text-h4"
                    colspan="3"
                  >
                    Total
                  </td>

                  <td class="text-right">
                    {{ order.subtotal | currency }}
                  </td>
                  <td class="text-right">
                    {{ order.tax | currency }}
                  </td>

                  <td class="text-right text-h4">
                    {{ order.total | currency }}
                  </td>
                </tr>
              </tfoot>
            </table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <div class="text-left footer">
      <v-btn
        color="primary"
        @click="show = true"
      >
        <v-icon>mdi-printer</v-icon>Print
      </v-btn>

      <return-orders-print
        v-model="show"
        :orders="[order]"
      />
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: `Returns Order ${this.id} | Stallion Express`
    }
  },
  props: {
    id: {
      default: null,
      type: [Number, String]
    }
  },

  data() {
    return {
      order: null,
      show: false
    };
  },

  computed: {
    total_subtotal() {
      if (this.order.returns.length == 0) {
        return 0;
      }

      return parseFloat(
        this.order.returns.reduce((a, b) => a + b.subtotal, 0)
      ).toFixed(2);
    },
    total_subtotal_tax() {
      if (this.order.returns.length == 0) {
        return 0;
      }

      return parseFloat(
        this.order.returns.reduce((a, b) => a + b.tax, 0)
      ).toFixed(2);
    },

    total_duty_tax() {
      if (this.order.returns.length == 0) {
        return 0;
      }

      return parseFloat(
        this.order.returns.reduce((a, b) => a + b.vat, 0)
      ).toFixed(2);
    },
    total_duty() {
      if (this.order.returns.length == 0) {
        return 0;
      }

      return parseFloat(
        this.order.returns.reduce((a, b) => a + b.duty, 0)
      ).toFixed(2);
    }
  },
  watch: {},
  mounted() {
    this.getOrder();
    if (this.$route.query.print) {
      this.show = true;
    }
  },

  methods: {
    getOrder() {
      this.$http
        .get(`/return-invoices/${this.id}`)
        .then(response => {
          this.order = response.data;
        })
        .catch(() => {})
        .finally(() => {});
    }
  }
};
</script>
