<template>
  <FullPageLayout title="Batches">
    <template #default>
      <batches-table />
    </template>
  </FullPageLayout>
</template>

<script>
import BatchesTable from './BatchesTable.vue';

export default {
  components: {
    BatchesTable
  },
  props: {
    filter: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      drawer: null
    };
  },
  computed: {}
};
</script>
