const DefaultShipmentTemplate = {
  id: null,
  subject: null,
  type: null,
  is_active: true,
  css: `table {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  body {
    min-height: 8cm;
    min-width: 10.16cm;
    margin: 0;
    padding: 8px;
    font-size: 1rem;
    color: black;
    font-weight: 500;
  }

  .center {
    margin: auto;
    width: 50%;
    text-align: center;
  }

  .center-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  td {
    vertical-align: text-top;
  }

  td:nth-child(2) {
    padding-left: 10px
  }

  tfoot>tr>td {
    text-align: right;
  }

  tfoot>tr>td:first-child {
    font-weight: bold;
  }

  * {
    font-family: monospace;
  }`,
  body: `Hello [CUSTOMER NAME],
<br>
This is a sample template.`
}

export default DefaultShipmentTemplate
