<template>
  <v-row dense>
    <v-col cols="12">
      <v-form>
        <v-row dense>
          <v-col cols="12">
            <h4>Special Settings</h4>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-checkbox
              v-model="user.beta_tester"
              outlined
              dense
              label="Beta Tester"
            />
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <v-checkbox
              v-model="user.void_non_tracked_shipments"
              outlined
              dense
              label="Void Non Tracked Shipments"
            />
          </v-col>

          <!--Shipment Settings-->

          <v-col cols="12">
            <h4>Admin Settings</h4>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="user.billing_type_id"
              outlined
              dense
              data-vv-name="branch"
              :items="[
                {
                  text: 'Prepaid',
                  value: 1
                },
                {
                  text: 'Weekly',
                  value: 2
                },
                {
                  text: 'Monthly',
                  value: 3
                }
              ]"
              label="Billing Type"
            />
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="user.type_id"
              outlined
              dense
              :items="[
                {
                  text: 'Small Business',
                  value: 1
                },
                {
                  text: 'Enterprise',
                  value: 2
                },
                {
                  text: 'Reseller',
                  value: 3
                },
                {
                  text: 'Fulfillment',
                  value: 4
                },
                {
                  'text': 'Medium Business',
                  'value': 5
                }
              ]"
              label="User Type"
            />
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="user.manager_user_id"
              outlined
              dense
              data-vv-name="manager_user_id"
              :items="accountManagers"
              label="Account Manager"
              clearable
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col
            cols="12"
            text-center
          >
            <v-btn
              v-if="can('users.edit')"
              color="primary"
              :disabled="loading"
              :loading="loading"
              @click="updateUser()"
            >
              Update
              <v-icon dark>
                mdi-save
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import constants from '@/constants/index';
export default {
  props: {
    user: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      loading: false,
      branch: null,
      searchInput: null,
      searchInput2: null,
      countries: [
        {
          name: 'Canada',
          code: 'CA'
        }
      ],
      default_location_list: constants.DEFAULT_PICKUP_LOCATIONS,
      provinces: constants.PROVINCES,
      accountManagers: [],
      auto_request: 0
    };
  },
  watch: {
    user: {
      handler: function (val, oldVal) {
        if (Object.keys(oldVal).length !== 0) {
          this.disabled = false;
        }
      },
      deep: true
    }
  },
  async mounted() {
    this.getAccountManagers();
    const setting = this.user.settings.find(
      (s) => s.key == 'return_auto_request'
    );
    this.auto_request = setting ? setting.value : 0;
  },
  methods: {
    getAccountManagers() {
      this.$http.get('/admin/managers').then(
        (response) => {
          const users = response.data;
          this.accountManagers = [];

          for (let i = 0; i < users.length; i++) {
            const user = users[i];
            this.accountManagers.push({
              value: user.id,
              text: `${user.email} (${user.name})`
            });
          }
        },
        () => {
          // error callback
        }
      );
    },
    updateUser() {
      this.$validator.validate().then((result) => {
        if (result) {
          this.loading = true;
          this.disabled = true;
          this.user.auto_request_returns = this.auto_request;
          this.$http
            .post(`/admin/users/${this.user.id}/advanced-settings`, this.user)
            .then((res) => {
              if (res.data.success) {
                swal({
                  title: 'Success',
                  text: 'User updated successfully',
                  icon: 'success'
                });
              } else {
                swal({
                  title: 'Error',
                  text: res.data.errors
                    ? res.data.errors
                    : 'Something went wrong.',
                  icon: 'error'
                });
              }
            })
            .finally(() => {
              this.loading = false;
              this.disabled = false;
            });
        } else {
          swal({
            title: 'Error',
            text: 'Some of the form fields are invalid.',
            icon: 'error'
          });
        }
      });
    }
  }
};
</script>
