<template>
  <FullPageLayout title="Location Invoices">
    <template #default>
      <drop-off-invoices-table />
    </template>
  </FullPageLayout>
</template>

<script>
export default {
};
</script>
