<template>
  <!--isPrimaryAccountHolder from mixin.js-->
  <v-list v-if="group && can('CLIENT.PRIMARY_ACCOUNT_HOLDER')">
    <v-subheader style="height: 30px">
      Linked Accounts
    </v-subheader>

    <v-list-group
      v-model="group_model"
      prepend-icon="mdi-account-group"
    >
      <template #activator>
        <v-list-item-title>{{ group.name }}</v-list-item-title>
      </template>

      <!-- ALL -->

      <v-list-item
        v-for="user in group.users"
        :key="user.id"
        two-line
        :disabled="$auth.user.id === user.id"
        @click="loginAsUser(user)"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ user.business_name || user.name }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>

    <v-list-item
      v-show="!group_model"
      two-line
    >
      <v-list-item-content>
        <v-list-item-title>
          {{ $auth.user.business_name || $auth.user.name }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ $auth.user.email }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
  </v-list>
</template>

<script>
export default {
  data: () => ({
    group: null,
    group_model: false
  }),
  mounted() {
    if (this.can('CLIENT.PRIMARY_ACCOUNT_HOLDER')) {
      this.getUserGroup();
    }
  },
  methods: {
    getUserGroup: function () {
      this.$http
        .request(`users/${this.$auth.user.id}/user-group`, {
          method: 'get',
          params: {
            group_token: this.$cookies.get('group-token')
          }
        })
        .then((res) => {
          this.group = res.data;
        });
    },
    loginAsUser(user) {
      this.$auth.login({
        params: {
          id: user.id
        },
        success: function () {
          this.$store.dispatch('clearAll');

          var name = null;

          if (user.business_name) {
            name = user.business_name;
          } else {
            name = user.name;
          }
          this.group_model = null;

          swal({
            title: 'Account Change',
            text: `You're now logged in as ${name}`,
            icon: 'info'
          });
        },
        error: function (res) {
          this.errorMessage(`Could not log into that account`);

          if (res.response && res.response.status === 429) {
            try {
              this.error = res.response.data.errors.email[0];
            } catch (err) {
              this.error = 'Too many login attempts';
            }
          }
        },
        url: `users/${user.id}/login`,
        redirect: '/',
        fetchUser: true
      });
    }
  }
};
</script>
