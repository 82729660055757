<template>
  <v-list-group
    v-if="batches.open_batches.length > 0"
    :value="true"
  >
    <template #activator>
      <v-list-item-title>
        Active Batches ({{ batches.open_batches.length }})
      </v-list-item-title>
    </template>
    <v-list-item
      v-for="batch in batches.open_batches"
      :key="batch.id"
      dense
      router
      exact
      :to="'/batches/' + batch.id"
    >
      <v-list-item-content>
        <v-list-item-title>
          {{ batch.id }} ({{ batch.shipments_count
          }})
        </v-list-item-title>
        <v-list-item-subtitle
          class="text--primary"
          v-text="batch.name"
        />
      </v-list-item-content>


      <v-list-item-icon
        v-if="showAddToBatch"
        @click.prevent="addToBatch(batch.id)"
      >
        <v-icon>mdi-plus</v-icon>
      </v-list-item-icon>
    </v-list-item>
  </v-list-group>
</template>


<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(['batches']),
    cart: {
      get() {
        return this.$store.state.cart;
      },
      set(value) {
        this.$store.state.cart = value;
      }
    },
    isAdminPanel: function () {
      return this.$router.history.current.meta?.admin;
    },
    showAddToBatch() {
      // if on shipments page or orders page, show add to batch icon
      return this.$route.path.includes('shipments') || this.$route.path.includes('orders');
    }
  },

  mounted() {
    this.$store.dispatch('batches/getOpenBatches');
  },
  methods: {
    addToBatch(batchId) {
      window.Event.fire('add-to-batch', batchId);
    }
  }
};
</script>
