<template>
  <v-combobox
    v-if="multiSelect"
    v-model="value_local"
    :search-input.sync="searchInput"
    :items="accounts"
    :label="label"
    :dense="dense"
    :outlined="outlined"
    multiple
    clearable
    item-text="text"
    item-value="id"
    :disabled="disabled"
  />
  <v-autocomplete
    v-else
    v-model="value_local"
    :outlined="outlined"
    :dense="dense"
    prepend-inner-icon="mdi-account"
    table
    :items="accounts"
    :search-input.sync="searchInput"
    hide-selected
    :label="label"
    clearable
    item-text="text"
    item-value="id"
    :disabled="disabled"
    @blur="checkIfAccountExists"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: [Array, Number, Object],
      default: null
    },
    label: {
      type: String,
      default: 'User'
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    multiSelect: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchEmail: null,
      email: null,
      accounts: [],
      searchInput: null,
      cancelToken: null,
      searchTimeout: null,
    };
  },
  computed: {
    value_local: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
        this.$emit('change');
      }
    }
  },
  watch: {
    searchInput: function (newVal, oldVal) {
      // return if value in accounts
      if (this.accounts.find((user) => user.text === newVal)) {
        return;
      }

      console.log(newVal + ' ' + oldVal);
      if (newVal != oldVal) {
        if (this.searchTimeout) {
          clearTimeout(this.searchTimeout);
        }

        // Set a new timeout
        this.searchTimeout = setTimeout(() => {
          // this.accounts = [];
          this.fetchData({
            name: newVal
          });
        }, 300);
      }
    }
  },
  mounted() {
    if (this.value) {
      this.fetchData({
        id: this.value
      });
    }
  },
  methods: {
    fetchData(obj) {
      if (this.cancelToken) {
        this.cancelToken.cancel('Operation canceled due to new request.');
      }

      // Create a new CancelToken
      this.cancelToken = this.$http.CancelToken.source();

      if (typeof searchTerm !== 'object') {
        this.$http
          .get('/admin/accounts/list', {
            params: { name: obj?.name, id: obj?.id, limit: 20 },
            cancelToken: this.cancelToken.token // Pass the token here
          })
          .then((response) => response.data)
          .then((response) => {
            this.accounts = [];
            for (var i = 0; i < response.length; i++) {
              this.accounts.push({
                id: response[i].id,
                text: `${response[i].name} (${response[i].users_count} Users)`
              });
            }
          });
      }
    },
    checkIfAccountExists() {
      const inputValue = this.searchInput;
      if (inputValue && !this.accounts.find((user) => user.text === inputValue)) {
        this.createAccount(inputValue);
      }
    },
    createAccount(name) {
      this.$http
        .post('/admin/accounts', { name })
        .then((response) => response.data)
        .then((newAccount) => {
          this.accounts.push({
            id: newAccount.id,
            text: newAccount.name
          });
          this.value_local = newAccount.id;
        });
    }
  }
};
</script>
