<template>
  <v-card
    class="mb-5 pa-8"
    outlined
    :disabled="!can(PERMISSIONS.STORES)"
  >
    <h3>Connect to a Store</h3>
    <p class="mb-3">
      Integrate your online shop with Stallion Express! Need help? See our
      <a
        style="font-weight: 700; text-decoration: none"
        href="https://help.stallionexpress.ca/"
        target="_blank"
      >Help Centre</a>
    </p>

    <v-card
      class="d-flex justify-space-between"
      justify-center
      flat
    >
      <v-row dense>
        <v-col
          cols="12"
          md="6"
        >
          <v-hover v-slot="{ hover }">
            <v-btn
              :elevation="hover ? 3 : 0"
              color="#E0E0E0"
              block
              x-large
              outlined
              @click="ebayShow = true"
            >
              <v-icon
                size="60"
                class="mt-2"
              >
                $ebay-logo
              </v-icon>
              <ConnectEbayDialog v-model="ebayShow" />
            </v-btn>
          </v-hover>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-hover v-slot="{ hover }">
            <v-btn
              :elevation="hover ? 3 : 0"
              color="#E0E0E0"
              block
              x-large
              outlined
              @click="amazonShow = true"
            >
              <v-icon
                size="60"
                class="mt-2"
              >
                $amazon-logo
              </v-icon>
              <ConnectAmazonDialog v-model="amazonShow" />
            </v-btn>
          </v-hover>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-hover v-slot="{ hover }">
            <v-btn
              :elevation="hover ? 3 : 0"
              color="#E0E0E0"
              block
              x-large
              outlined
              @click="shopifyShow = true"
            >
              <v-icon size="75">
                $shopify-logo
              </v-icon>
              <ConnectShopifyDialog v-model="shopifyShow" />
            </v-btn>
          </v-hover>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-hover v-slot="{ hover }">
            <v-btn
              :elevation="hover ? 3 : 0"
              color="#E0E0E0"
              block
              x-large
              outlined
              @click="etsyShow = true"
            >
              <v-icon
                size="50"
                class="mt-2"
              >
                $etsy-logo
              </v-icon>
              <ConnectEtsyDialog v-model="etsyShow" />
            </v-btn>
          </v-hover>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-hover v-slot="{ hover }">
            <v-btn
              color="#E0E0E0"
              :elevation="hover ? 3 : 0"
              block
              x-large
              outlined
              @click="shipStationShow = true"
            >
              <v-icon size="85">
                $shipstation-logo
              </v-icon>
              <ConnectShipstationDialog v-model="shipStationShow" />
            </v-btn>
          </v-hover>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-hover v-slot="{ hover }">
            <v-btn
              color="#E0E0E0"
              :elevation="hover ? 3 : 0"
              block
              x-large
              outlined
              @click="bigcommerceShow = true"
            >
              <v-icon size="85">
                $bigcommerce-logo
              </v-icon>
              <ConnectBigcommerceDialog v-model="bigcommerceShow" />
            </v-btn>
          </v-hover>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-hover v-slot="{ hover }">
            <v-btn
              color="#E0E0E0"
              :elevation="hover ? 3 : 0"
              block
              x-large
              outlined
              @click="woocommerceShow = true"
            >
              <v-icon size="85">
                $woocommerce-logo
              </v-icon>
              <ConnectWoocommerceDialog v-model="woocommerceShow" />
            </v-btn>
          </v-hover>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-hover v-slot="{ hover }">
            <v-btn
              color="#E0E0E0"
              :elevation="hover ? 3 : 0"
              block
              x-large
              outlined
              @click="magentoShow = true"
            >
              <v-icon size="85">
                $magento-logo
              </v-icon>
              <ConnectMagentoDialog v-model="magentoShow" />
            </v-btn>
          </v-hover>
        </v-col>

        <v-col
          cols="6"
          class="6"
        >
          <v-hover v-slot="{ hover }">
            <v-btn
              color="#E0E0E0"
              :elevation="hover ? 3 : 0"
              block
              x-large
              outlined
              @click="walmartUsShow = true"
            >
              <v-icon size="85">
                $walmart-logo-us
              </v-icon>
              <ConnectWalmartUsDialog v-model="walmartUsShow" />
            </v-btn>
          </v-hover>
        </v-col>
        <v-col
          cols="6"
          class="6"
        >
          <v-hover v-slot="{ hover }">
            <v-btn
              color="#E0E0E0"
              :elevation="hover ? 3 : 0"
              block
              x-large
              outlined
              @click="walmartCaShow = true"
            >
              <v-icon size="85">
                $walmart-logo-ca
              </v-icon>
              <ConnectWalmartCaDialog v-model="walmartCaShow" />
            </v-btn>
          </v-hover>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <v-hover v-slot="{ hover }">
            <v-btn
              color="#E0E0E0"
              :elevation="hover ? 3 : 0"
              block
              x-large
              outlined
              @click="bandcampShow = true"
            >
              <v-icon size="85">
                $bandcamp-logo
              </v-icon>
              <bandcamp-connect v-model="bandcampShow" />
            </v-btn>
          </v-hover>
        </v-col>
      </v-row>
    </v-card>
    <!-- <p
      style="font-size: 11px"
      class="mt-3"
    >
      (The term 'Etsy' is a trademark of Etsy, Inc.) This application uses the
      Etsy API but is not endorsed or certified by Etsy, Inc.
    </p> -->
  </v-card>
</template>

<script>
import ConnectAmazonDialog from './ConnectStore/ConnectAmazonDialog.vue';
import ConnectBigcommerceDialog from './ConnectStore/ConnectBigcommerceDialog.vue';
import ConnectEbayDialog from './ConnectStore/ConnectEbayDialog.vue';
import ConnectEtsyDialog from './ConnectStore/ConnectEtsyDialog.vue';
import ConnectMagentoDialog from './ConnectStore/ConnectMagentoDialog.vue';
import ConnectShipstationDialog from './ConnectStore/ConnectShipstationDialog.vue';
import ConnectShopifyDialog from './ConnectStore/ConnectShopifyDialog.vue';
import ConnectWoocommerceDialog from './ConnectStore/ConnectWoocommerceDialog.vue';
import ConnectWalmartUsDialog from './ConnectStore/ConnectWalmartUsDialog.vue';
import ConnectWalmartCaDialog from './ConnectStore/ConnectWalmartCaDialog.vue';
import PERMISSIONS from '@/constants/client-permissions';

export default {
  components:{
    ConnectAmazonDialog,
    ConnectBigcommerceDialog,
    ConnectEbayDialog,
    ConnectEtsyDialog,
    ConnectMagentoDialog,
    ConnectShipstationDialog,
    ConnectShopifyDialog,
    ConnectWoocommerceDialog,
    ConnectWalmartUsDialog,
    ConnectWalmartCaDialog
  },
  props: {
    provider: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      ebayShow: null,
      amazonShow: null,
      shopifyShow: null,
      etsyShow: null,
      shipStationShow: null,
      bigcommerceShow: null,
      woocommerceShow: null,
      magentoShow: null,
      walmartUsShow: null,
      walmartCaShow: null,
      bandcampShow: null,
      PERMISSIONS: PERMISSIONS
    };
  },
  watch: {},

  mounted() {
    if (
      this.provider === 'shopify' ||
      this.provider === 'ebay' ||
      this.provider === 'etsy' ||
      this.provider === 'bigcommerce'
    ) {
      this.addStore();
    }
  },

  methods: {
    //   addStore() {
    //     alert('add store')
    //   this.msg = `Adding ${this.provider} to your account`;
    //   this.imp.dialog = true;
    //   console.log('test 12');
    //   this.$http
    //     .post(`/${this.provider}/callback`, this.$route.query)
    //     .then(response => response.data)
    //     .then(response => {
    //          console.log('test response');
    //       this.$store.dispatch('userStores/getStores');
    //       this.$notify({
    //         group: 'main',
    //         title: this.provider + ' added successfully',
    //         text:
    //           'Your account for ' +
    //           this.provider +
    //           ' has been added successfully.',
    //         duration: 5000,
    //         type: 'success'
    //       });
    //     })
    //     .catch(err => {
    //         console.log('test err');
    //          console.log('test err');
    //       if (err.response && err.response.status === 422) {
    //         this.$notify({
    //           group: 'main',
    //           title: 'Invalid Credentials',
    //           text: err.response.data.errors,
    //           duration: 5000,
    //           type: 'error'
    //         });
    //       } else {
    //         this.$notify({
    //           group: 'main',
    //           title: 'Error adding ' + this.provider + ' account',
    //           text: 'We could not connect to that account.',
    //           duration: 5000,
    //           type: 'error'
    //         });
    //       }
    //     })
    //     .finally(err => {
    //       this.imp.dialog = false;
    //     });
    // },
  }
};
</script>
