<template>
  <StallionDialog
    v-model="show"
    :width="500"
    :disabled="disabled"
    title="Create User Group"
  >
    <template #content>
      <v-form>
        <v-container fluid>
          <v-row align="center">
            <v-col cols="12">
              <v-autocomplete
                v-model="user_id"
                :items="users"
                hide-selected
                label="Master Account Email"
                clearable
                class="pa-0"
                item-value="id"
                item-text="email"
              />
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="name"
                v-validate="'required'"
                :error-messages="errs.collect('name')"
                data-vv-name="name"
                label="Group Name"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        :loading="loading"
        :disabled="loading"
        @click="submit()"
      >
        Save
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loading: false,
      name: null,
      user_id: null,
      users: []
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  mounted() {
    this.getShipCodes();
  },

  methods: {
    getShipCodes() {
      this.$http
        .get('/admin/users/all')
        .then(response => response.data)
        .then(response => {
          this.users = response;
        })
        .catch(() => {});
    },
    submit() {
      this.loading = true;
      this.$http
        .post('/admin/user-groups', {
          name: this.name,
          user_id: this.user_id
        })
        .then(response => response.data)
        .then(() => {
          Event.fire('update-user-groups');
          this.show = false;
        })
        .catch(() => {
          alert('error');
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
