<template>
  <div>
    <v-row dense>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          item-key="id"
          :options.sync="options"
          class="table-striped"
          :server-items-length="totalItems"
          :footer-props="{
            'items-per-page-options': [
              10, 50, 100, 200, 500
            ]
          }"
          @click:row="rowClick"
        >
          <template #item.id="{ item }">
            <router-link
              router
              :to="{
                name: 'statement',
                params: {
                  id: item.id
                }
              }"
            >
              {{ item.id }}
            </router-link>
          </template>

          <template #item.start_date="{ item }">
            {{ item.start_date | date_pretty }}
          </template>
          <template #item.end_date="{ item }">
            {{ item.end_date | date_pretty }}
          </template>

          <template #item.open_balance="{ item }">
            {{ item.open_balance | currency }}
          </template>
          <template #item.close_balance="{ item }">
            {{ item.close_balance | currency }}
          </template>
          <template #item.payment_total="{ item }">
            {{ item.payment_total | currency }}
          </template>
          <template #item.charge_total="{ item }">
            {{ item.charge_total | currency }}
          </template>
          <!-- <template #item.actions="{ item }">
              <v-btn
                icon
                color="primary"
                @click="getStatementSummary(item.id)"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template> -->
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    userId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      transactionsLoading: false,
      summaryLoading: false,
      dateOption: 30,
      dateOptions: [
        { text: 'Today', value: 'Today' },
        { text: 'Yesterday', value: 'Yesterday' },
        { text: '7 Days', value: 7 },
        { text: '30 Days', value: 30 },
        { text: '3 Months', value: 90 },
        { text: '12 Months', value: 360 },
        { text: 'Current Year', value: 'Current Year' },
        { text: 'Previous Year', value: 'Previous Year' },
        { text: 'Custom Range', value: 'Custom' }
      ],
      selected: false,
      dialog: false,
      showAdvanceSearch: false,
      category_ids: [],
      search: null,
      date: moment().format('YYYY-MM'),
      menu: false,
      start_date: null,
      end_date: null,
      start_date_menu: null,
      end_date_menu: null,
      headers: [
        {
          text: 'ID',
          value: 'id',
          sortable: false
        },
        // {
        //   text: 'Date',
        //   value: 'end_date_link',
        //   sortable: false
        // },
        // {
        //   text: 'Period',
        //   value: 'period',
        //   sortable: false
        // }
        {
          text: 'Start Date',
          value: 'start_date',
          sortable: false
        },
        {
          text: 'End Date',
          value: 'end_date',
          sortable: false
        },
        {
          text: 'Open Balance',
          value: 'open_balance',
          sortable: false
        },
        {
          text: 'Payments',
          value: 'payment_total',
          sortable: false
        },
        {
          text: 'Charges',
          value: 'charge_total',
          sortable: false
        },
        {
          text: 'Close Balance',
          value: 'close_balance',
          sortable: false
        },
        {
          text: '',
          value: 'actions',
          sortable: false
        }
      ],
      options: {
        sortBy: ['created_at'],
        sortDesc: [true],
        itemsPerPage: 10
      },
      loading: false,
      totalItems: 0,
      items: [],
      categories: []
    };
  },

  watch: {
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    }
  },
  methods: {
    rowClick(item) {
      this.$router.push({
        name: 'statement',
        params: {
          id: item.id
        }
      });
    },
    openDetails(item) {
      this.selected = item;
      this.dialog = true;
    },
    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.options.page = 1;
      this.timer = setTimeout(function () {
        self.updateTable();
      }, ms);
    },
    updateTable() {
      this.selected = [];
      this.totalItems = 0;
      this.items = [];

      this.getDataFromApi().then((data) => {
        this.items = data.items;
        this.totalItems = data.total;
      });
    },
    getParams() {
      let end_date = this.end_date;
      let start_date = this.start_date;

      if (this.isStatement) {
        start_date = moment(this.date).startOf('month').format('YYYY-MM-DD');
        end_date = moment(this.date).endOf('month').format('YYYY-MM-DD');
      }

      var params = {
        transactional_id: this.transactionalId,
        transactional_type_id: this.transactional_type_id,
        category_ids: this.category_ids,
        search: this.search,
        start_date: start_date,
        end_date: end_date,
        user_id: this.userId
      };

      return params;
    },

    getDataFromApi() {
      this.loading = true;
      return new Promise((resolve) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let items = [];
        let total = 0;
        let pages = 0;

        const p = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sortBy[0],
          desc: sortDesc[0]
        };

        const params = Object.assign(p, this.getParams());

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        this.$http
          .request(`/statements`, {
            method: 'get',
            params: params,
            cancelToken: this.cancelSource.token
          })
          .then((response) => response.data)
          .then((response) => {
            items = response.data;
            total = response.total;
            pages = response.last_page;

            this.loading = false;
            resolve({
              items,
              total,
              pages
            });
          });
      });
    }
  }
};
</script>
