<template>
  <StallionDialog
    v-model="dialog"
    persistent
    :width="800"
    :title="edit ? 'Edit Commission Structure' : 'Create Commission Structure'"
    fullscreen
  >
    <template #content>
      <v-container>
        <v-row
          v-for="(structure, index) in structures"
          :key="index"
          dense
        >
          <v-col style="max-width: 25px; padding-right: 10px">
            <div class="float-left mt-3 mr-3">
              {{ ++index }}
            </div>
          </v-col>

          <v-col>
            <v-select
              v-model="structure.group"
              v-validate="'required'"
              :error-messages="errs.collect('group')"
              data-vv-name="group"
              :items="commissionGroup"
              label="Commission Type"
              item-text="label"
              item-value="key"
              outlined
              dense
              :disabled="edit"
              @change="onChangeCommissionType(structure)"
            />
          </v-col>

          <v-col>
            <user-search
              v-model="structure.earner_id"
              v-validate="'required'"
              :disabled="edit"
              data-vv-name="user_id"
              :label="isEmployeeCommission(structure) ? 'Employee' : 'Earner'"
              :error-messages="errs.collect('user_id')"
              :clearable="false"
              :admin-only="isEmployeeCommission(structure)"
            />
          </v-col>

          <v-col>
            <user-search
              v-model="structure.client_id"
              v-validate="'required'"
              :disabled="edit"
              data-vv-name="client_id"
              label="Client"
              :error-messages="errs.collect('client_id')"
              :clearable="false"
            />
          </v-col>

          <v-col>
            <v-text-field
              v-model="structure.amount"
              v-validate="'required|decimal:2|min_value:0.01|max_value:100'"
              label="Commission Percentage"
              type_id="number"
              :error-messages="errs.collect('amount')"
              data-vv-name="amount"
              required
              outlined
              dense
            />
          </v-col>

          <!-- <v-col>
            <v-select
              v-model="structure.type"
              label="Type"
              :items="['Percent', 'Fixed']"
              outlined
              dense
              data-vv-name="type"
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="structure.amount"
              v-validate="'required'"
              data-vv-name="amount"
              label="Amount"
              outlined
              dense
            />
          </v-col> -->

          <v-col>
            <date-picker
              v-model="structure.start_date"
              v-validate="'required'"
              data-vv-name="start_date"
              label="Start Date"
              format="YYYY-MM-DD"
              @change="structure.end_date = getEndDate(structure.start_date)"
            />
          </v-col>

          <v-col>
            <date-picker
              v-model="structure.end_date"
              v-validate="isEmployeeCommission(structure) ? 'required' : ''"
              data-vv-name="end_date"
              :disabled="isEmployeeCommission(structure)"
              label="End Date"
              format="YYYY-MM-DD"
            />
          </v-col>

          <v-btn
            icon
            color="red"
            class="mt-1 mx-0"
            :disabled="edit"
            @click="removeRow(index)"
          >
            <v-icon small>
              mdi-delete
            </v-icon>
          </v-btn>
          <v-btn
            icon
            color="primary"
            class="mt-1 mx-0"
            :disabled="edit"
            @click="duplicateRow(index)"
          >
            <v-icon small>
              mdi-content-copy
            </v-icon>
          </v-btn>
        </v-row>

        <v-btn
          color="primary"
          small
          flat
          class="float-right mr-5 mt-3"
          :disabled="edit"
          @click="addRow()"
        >
          Add Row
        </v-btn>
      </v-container>
    </template>

    <template #actions>
      <v-btn
        v-if="edit"
        color="primary"
        :loading="loading"
        @click="update()"
      >
        Update
      </v-btn>
      <v-btn
        v-else
        color="primary"
        :loading="loading"
        @click="save()"
      >
        Save
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
const EMPLOYEE_PERCENT_AMOUNT = 40;
const CLIENT_PERCENT_AMOUNT = 20;
const STRUCTURE = {
  earner_id: null,
  client_id: null,
  type: 'Percent',
  amount: EMPLOYEE_PERCENT_AMOUNT,
  start_date: null,
  end_date: null,
  group: 'Employee'
};

export default {
  data() {
    return {
      loading: false,
      edit: false,
      dialog: false,
      region: {},
      structures: []
    };
  },
  computed: {
    commissionGroup() {
      return this.$commissionGroup();
    }
  },
  mounted() {},
  methods: {
    clearTest() {
      console.log(123, 'clearTest');
    },
    isEmployeeCommission(structure) {
      return structure.group == 'Employee';
    },
    isClientCommission(structure) {
      return structure.group == 'Client';
    },
    onChangeCommissionType(structure) {
      if (this.isEmployeeCommission(structure)) {
        structure.amount = EMPLOYEE_PERCENT_AMOUNT;
        structure.type = 'Percent';
      } else {
        structure.earner_id = null;
        structure.amount = CLIENT_PERCENT_AMOUNT;
        structure.type = 'Percent';
      }
    },
    getEndDate(date) {
      return moment(date).add(90, 'days').format('YYYY-MM-DD');
    },
    addRow() {
      this.structures.push(Object.assign({}, STRUCTURE));
    },
    removeRow(index) {
      console.log(index);
      this.structures.splice(index - 1, 1);
    },
    duplicateRow(index) {
      this.structures.push(Object.assign({}, this.structures[index - 1]));
    },
    startNew() {
      this.dialog = true;
      this.structures = [];
      this.addRow();
      this.edit = false;
      this.$validator.reset();
    },
    startEdit(structure) {
      this.dialog = true;
      this.structures = [];

      this.structures.push(Object.assign({}, STRUCTURE, structure));

      this.edit = true;
      this.$validator.reset();
    },
    save() {
      this.$validator.validate().then((result) => {
        if (result) {
          this.loading = true;
          this.$http
            .post(`/admin/commission-structures`, {
              structures: this.structures
            })
            .then(() => {
              this.dialog = false;
              this.$emit('update');
            })
            .catch((err) => {
              this.onError(err)
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    update() {
      this.$validator.validate().then((result) => {
        if (result) {
          this.loading = true;
          // update one structure only
          const structure = this.structures[0];
          this.$http
            .put(`/admin/commission-structures/${structure.id}`, {
              structures: [structure]
            })
            .then(() => {
              this.dialog = false;
              this.$emit('update');
            })
            .catch((err) => {
              this.onError(err)
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    onError(err) {
      let message = 'Unknown Error Occured';
      if (
        err.response.status === 422 &&
        typeof err.response.data.errors !== 'undefined'
      ) {
        const errors = err.response.data.errors;
        message = '';

        for (var field in errors) {
          message += `${errors[field][0]}\n`;
        }
      }

      this.errorMessage(message);
    }
  }
};
</script>
