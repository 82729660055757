<template>
  <v-row v-if="store">
    <v-col cols="12">
      <v-card elevation="0">
        <v-card-text>
          <v-row>
            <v-col class="text-left">
              <v-btn
                :loading="loading"
                :disabled="loading"
                color="primary"
                name="login-button"
                x-large
                :block="$vuetify.breakpoint.smAndDown"
                @click="printLabel"
              >
                Print Label
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-alert
                
                type="success"
                outlined
              >
                Return label has been created!
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="text-h5">
                Shipment Information
              </div>
            </v-col>
            <v-col cols="12">
              <v-simple-table>
                <template #default>
                  <tbody>
                    <tr>
                      <td><strong>Tracking Number:</strong></td>
                      <td>
                        <a
                          :href="returnLabel.tracking_url"
                          target="_blank"
                        >{{ returnLabel.tracking_code }}</a>
                      </td>
                    </tr>
                    <tr>
                      <td><strong>Order ID:</strong></td>
                      <td>{{ returnLabel.display_order_id || returnLabel.order_id }}</td>
                    </tr>
                    <tr>
                      <td><strong>Status:</strong></td>
                      <td v-html="$options.filters.status(returnLabel.status_id)" />
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <div class="text-h5">
                Returned Items
              </div>
            </v-col>
            <v-col cols="12">
              <v-simple-table>
                <template #default>
                  <tbody>
                    <tr>
                      <td>
                        <strong>Items</strong>
                      </td>
                      <td>
                        <strong>Price</strong>
                      </td>
                      <td>
                        <strong>Quantity</strong>
                      </td>
                      <td>
                        <strong>Return Reason</strong>
                      </td>
                    </tr>
                    <tr
                      v-for="item in returnLabel.items"
                      :key="item.id"
                    >
                      <td>
                        {{ item.description }}
                      </td>
                      <td>
                        {{ item.value | currency }} {{ item.currency }}
                      </td>
                      <td>
                        {{ item.quantity }}
                      </td>
                      <td>
                        {{ item.note }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
       

          <v-row class="mb-1">
            <v-col>
              <v-btn
                outlined
                :block="$vuetify.breakpoint.smAndDown"
                color="primary"
                
                @click="reset()"
              >
                Search another order
              </v-btn>
            </v-col>
          </v-row>
        
          <v-row>
            <v-col cols="12">
              <div class="text-h5">
                Instructions
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="3"
            >
              <v-card
                class="return-label-steps"
                outlined
                hover
              >
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h3 class="text-h6">
                        Step 1
                      </h3>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <p>
                        Print the label provided.
                      </p>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-icon
                        x-large
                        color="primary"
                      >
                        mdi-printer
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              sm="3"
            >
              <v-card
                outlined
                class="return-label-steps"
                hover
              >
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h3 class="text-h6">
                        Step 2
                      </h3>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <p> Place items in original or similar packaging.</p>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="d-flex flex-column justify-end">
                      <v-icon
                        x-large
                        color="primary"
                      >
                        mdi-package-variant
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              sm="3"
            >
              <v-card
                outlined
                class="return-label-steps"
                hover
              >
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h3 class="text-h6">
                        Step 3
                      </h3>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <p>
                        Secure the package and affix the return label.
                      </p>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-icon
                        x-large
                        color="primary"
                      >
                        mdi-package-check
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col
              cols="12"
              sm="3"
            >
              <v-card
                outlined
                class="return-label-steps"
                hover
              >
                <v-card-text>
                  <v-row>
                    <v-col>
                      <h3 class="text-h6">
                        Step 4
                      </h3>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <p>
                        Take the package to the nearest carrier location.
                      </p>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-icon
                        x-large
                        color="primary"
                      >
                        mdi-truck
                      </v-icon>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  
    

    <print
      ref="print"
      v-model="showPrint"
      :shipments="[returnLabel]"
    />
  </v-row>
</template>

<script>
export default {
  props: {
    store: {
      type: Object,
      default: () => ({})
    },
    lookupData: {
      type: Object,
      default: () => ({})
    },
    returnLabel: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false,
      showPrint: false
    };
  },
  computed: {
    isPrintable() {
      return this.returnLabel && this.is_printable(this.returnLabel)
    },
  },

  mounted() {},

  methods: {
    reset() {
      this.$emit('reset');
    },

    getShipment() {
      if (!this.store) {
        return;
      }

      this.loading = true;
      this.$http
        .get(
          `/returns-portal/${this.store.returns_portal_code}/shipment/${this.returnLabel.ship_code}?order_id=${this.lookupData.order_id}&postal_code=${this.lookupData.postal_code}`
        )
        .then((res) => {
          const data = res.data;
          if (!data.success) {
            throw 404;
          }

          this.$emit('goToReturnLabel', {
            shipment: data.shipment
          });
        })
        .catch((error) => {
          console.error(error);
          this.$router.push({ name: '404' });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    printLabel() {
      this.showPrint = true;
     
    }
  }
};
</script>

<style scoped lang="scss">
.return-label-steps {
  min-height: 100%;
  text-align: center !important;

  p{
    min-height: 40px;
  }
}
</style>
