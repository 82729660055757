<template>
  <v-row dense>
    <v-col>
      <v-form @submit.prevent="updateTable()">
        <v-container pa-0>
          <v-row>
            <v-col cols="4">
              <user-search
                v-model="user_id"
                v-validate="'required'"
                data-vv-name="user_id"
                label="Earner"
                :error-messages="errs.collect('user_id')"
                @input="updateTable()"
              />
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="payout_method"
                clearable
                label="Payout Method"
                :items="['Cheque', 'Credits']"
                outlined
                dense
                @input="updateTable()"
              />
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="paid"
                label="Paid"
                clearable
                :items="[
                  {
                    text: 'Yes',
                    value: true
                  },
                  {
                    text: 'No',
                    value: false
                  }
                ]"
                outlined
                dense
                @input="updateTable()"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-col>

    <v-col cols="12">
      <v-btn
        color="primary"
        :disabled="selected.length === 0"
        @click="markSelectAsPaid"
      >
        Mark As Paid
      </v-btn>
    </v-col>

    <v-col cols="12">
      <v-data-table
        v-model="selected"
        :headers="headers"
        :loading="loading"
        item-key="id"
        :items="items"
        :options.sync="options"
        class="table-striped"
        :server-items-length="totalItems"
        :footer-props="{
          'items-per-page-options': [50, 100, 200, 500]
        }"
        :items-per-page="25"
        show-select
      >
        <template #item.user="{ item }">
          <span v-if="item.user">
            {{ displayUser(item.user) }}
          </span>
        </template>

        <template #item.amount="{ item }">
          {{ item.amount | currency }}
        </template>

        <template #item.period="{ item }">
          {{ item.start_date | date_pretty }} <br>{{
            item.end_date | date_pretty
          }}
        </template>

        <template #item.paid_at="{ item }">
          <span v-if="item.paid_at">
            {{ item.paid_at | date_pretty }}
          </span>
        </template>

        <template #item.paid="{ item }">
          <v-icon
            v-if="item.paid"
            color="green"
          >
            mdi-check-circle
          </v-icon>
        </template>

        <!-- <template #item.paid_at="{ item }">
          <span v-if="item.paid_at">
            {{ item.paid_at | date_pretty }}
          </span>
        </template> -->

        <!-- <template #item.action="{ item }">
          <v-btn
            v-if="item.payout_method == 'Cheque' && !item.paid"
            color="primary"
            small
            text
            :loading="loading"
            @click.stop="markAsPaid(item)"
          >
            Mark as Paid
          </v-btn>
        </template> -->

        <template #item.invoice_path="{ item }">
          <v-btn
            icon
            color="primary"
            download
            small
            @click.prevent="download(item.invoice_path)"
          >
            <v-icon dark>
              mdi-download
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
    <v-col cols="12">
      <div>
        <strong>Total Earned:</strong> {{ total_earned | currency }}<br>
      </div>
      
      <ol>
        <li
          v-for="item in selected"
          :key="item.id"
        >
          {{ item.end_date | date_month_year }} - {{ item.user.email }} earned  {{ item.amount | currency }} from
          {{ item.commission_structure.client.business_name || item.commission_structure.client.name }}
        </li>
      </ol>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      totalItems: 0,
      items: [],

      loading: false,
      search: null,

      timer: null,
      cancelSource: null,

      user_id: null,
      payout_method: 'Cheque',
      paid: null,

      selected: [],

      headers: [
        {
          text: 'ID',
          align: 'left',
          value: 'invoice_id',
          sortable: true
        },
        {
          text: 'Earner',
          align: 'left',
          value: 'user.email',
          sortable: false
        },
        {
          text: 'Client',
          align: 'left',
          value: 'commission_structure.client.email',
          sortable: false
        },
        {
          text: 'Total',
          align: 'left',
          value: 'amount',
          sortable: true
        },
        {
          text: 'Period',
          align: 'left',
          value: 'period',
          sortable: false
        },
        {
          text: 'Payout Method',
          align: 'left',
          value: 'payout_method',
          sortable: true
        },
        {
          text: 'Paid',
          align: 'left',
          value: 'paid',
          sortable: true
        },
        {
          text: 'Paid At',
          align: 'left',
          value: 'paid_at',
          sortable: true
        },
        {
          text: '',
          value: 'action',
          sortable: false
        },
        {
          text: '',
          value: 'invoice_path',
          sortable: false
        }
      ],

      options: {
        sortBy: ['created_at'],
        sortDesc: [true]
      }
    };
  },
  computed: {
    total_earned() {
      if (this.selected.length > 0) {
        return this.selected.reduce((acc, item) => {
          console.log(item.amount);
          // Ensure item.amount is treated as a number
          return acc + Number(item.amount);
        }, 0);
      } else {
        return 0;
      }
    }
  },

  watch: {
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    }
  },
  mounted() {
    this.getDataFromApi();
  },

  methods: {
    displayUser(user) {
      return `${user.id} - ${user.email}`;
    },

    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.options.page = 1;
      this.timer = setTimeout(function () {
        self.updateTable();
      }, ms);
    },

    updateTable() {
      this.totalItems = 0;
      this.items = [];

      this.getDataFromApi().then((data) => {
        this.items = data.items;
        this.totalItems = data.total;
      });
    },

    getDataFromApi() {
      this.loading = true;
      return new Promise((resolve) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let items = [];
        let total = 0;
        let pages = 0;

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        var params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sortBy[0],
          desc: sortDesc[0],
          user_id: this.user_id,
          search: this.search,
          payout_method: this.payout_method,
          paid: this.paid
        };

        this.$http
          .request(`/admin/commission-payouts`, {
            method: 'get',
            params: params,
            cancelToken: this.cancelSource.token
          })

          .then((response) => response.data)
          .then((response) => {
            items = response.data;
            total = response.total;
            pages = response.last_page;

            this.loading = false;
            resolve({
              items,
              total,
              pages
            });
          });
      });
    },

    async markAsPaid(item) {
      const isConfirm = await swal({
        title: 'Confirm Paid',
        text: 'Do you really want to mark this payout as paid?',
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      });

      if (isConfirm) {
        this.loading = true;
        try {
          await axios.request(`/admin/commission-payouts/${item.id}/paid`, {
            method: 'post'
          });

          this.successMessage('Successfully Mark as Paid.');
          this.updateTable();
        } catch (err) {
          this.errorMessage(
            err?.response?.data?.message || 'An unknown error occurred'
          );
        }

        this.loading = false;
      }
    },

    async markSelectAsPaid() {
      const isConfirm = await swal({
        title: 'Confirm Paid',
        text: 'Do you really want to mark these payouts as paid?',
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      });

      if (isConfirm) {
        this.loading = true;
        try {
          this.selected.forEach(async (item) => {
            await axios.request(`/admin/commission-payouts/${item.id}/paid`, {
              method: 'post'
            });
          });

          this.successMessage('Successfully Mark as Paid.');
          this.updateTable();
        } catch (err) {
          this.errorMessage(
            err?.response?.data?.message || 'An unknown error occurred'
          );
        }

        this.loading = false;
      }
    },

    download(file) {
      this.$http({
        method: 'post',
        url: '/commission-payouts/invoices/download',
        data: {
          file: file
        }
      }).then((response) => {
        this.downloadFileUrl(response.data, file);
      });
    }
  }
};
</script>
