<template>
  <div v-if="order && order.name">
    <div class="d-flex flex-row justify-space-between">
      <h4>Recipient</h4>
      <!-- <span v-if="order.status_id == 3">
        <a
          class="float-right"
          href="javascript:void(0)"
          v-on:click="editShipment(order, 'recipient')"
          >Edit</a
        >
      </span> -->
    </div>


    <div>{{ order.name }}</div>
    <div v-if="order.company">
      {{ order.company }}
    </div>
    <div>{{ order.address1 }}</div>
    <div v-if="order.address2">
      {{ order.address2 }}
    </div>
    <div>
      {{
        order.city +
          ', ' +
          order.province_code +
          ', ' +
          order.postal_code +
          ', ' +
          order.country_code
      }}
    </div>
    <div v-if="order.phone">
      Phone: {{ order.phone }}
    </div>
    <div
      v-if="order.email"
      class="mt-3"
    >
      {{ order.email }}
    </div>
    <div v-if="order.customer_id">
      Customer ID: {{ order.customer_id }}
    </div>
    <v-divider class="details-divider" />
  </div>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      default: null
    }
  }
};
</script>
