<template>
  <SidePanelLayout title="Shipments">
    <!-- <div>
      <v-breadcrumbs
        large
        :items="[
        {
          text: 'Home',
          exact: true,
          to: '/dashboard',
        },
        {
          text: 'Shipments',
          exact: true,
          to: '/shipments',
        },
         {
          text: filter_formated   ,
          exact: true,
          to: '/shipments/'+filter,
        },
     
      ]"
      ></v-breadcrumbs>
    </div>-->

    <template #side-panel>
      <view-shipment-details
        style="font-size: 14px"
        :shipments="selected"
      />
    </template>

    <template #page-links>
      <v-col cols="auto">
        <router-link
          to="/batches"
          :class="{ disabled: !can(PERMISSIONS.SHIPPING) }"
        >
          <!--<v-icon small color="primary">mdi-group</v-icon> -->Batches
        </router-link>
        |
        <router-link to="/print-jobs">
          Print Jobs
        </router-link>
      </v-col>
    </template>

    <template #default>
      <shipment-table
        ref="shipmentTable"
        v-model="selected"
      />
    </template>

    <template #footer>
      <shipment-actions
        :shipments="selected"
        @update="$refs.shipmentTable.updateTable()"
      />
    </template>
  </SidePanelLayout>
</template>

<script>
import PERMISSIONS from '@/constants/client-permissions';
export default {
  metaInfo: {
    title: "Shipments | Stallion Express"
  },
  props: {
    filter: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      selected: [].default,
      PERMISSIONS: PERMISSIONS
    };
  },
  computed: {
    filter_formated() {
      return this.$options.filters.capitalize(this.filter);
    }
  }
};
</script>
