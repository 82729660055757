<template>
  <v-row dense>
    <v-col cols="12">
      <v-form @submit.prevent="updateTable()">
        <v-container pa-0>
          <v-layout wrap>
            <v-flex xs12>
              <v-select
                v-model="is_active"
                clearable
                label="Status"
                item-text="text"
                item-value="value"
                :items="[
                  {
                    value: 1,
                    text: 'Active'
                  },
                  {
                    value: 0,
                    text: 'In-Active'
                  }
                ]"
                @change="updateTable()"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-col>

    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :loading="loading"
        item-key="id"
        :items="items"
        :options.sync="options"
        class="table-striped"
        :server-items-length="totalItems"
        :footer-props="{
          'items-per-page-options': [50, 100, 200, 500]
        }"
        :items-per-page="25"
      >
        <template #item.client="{ item }">
          <span v-if="item.client">
            {{ item.client | client }}
          </span>
        </template>

        <template #item.total_earned="{ item }">
          {{ item.total_earned | currency }}
        </template>

        <template #item.amount="{ item }">
          <span v-if="item.type == 'Fixed'">
            {{ item.amount | currency }}
          </span>
          <span v-else>
            {{ item.amount }}%
          </span>
        </template>

        <template #item.start_date="{ item }">
          <span v-if="item.start_date">
            {{ item.start_date | date_pretty }}
          </span>
        </template>

        <template #item.end_date="{ item }">
          <span v-if="item.end_date">
            {{ item.end_date | date_pretty }}
          </span>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>
  
<script>
export default {
  data() {
    return {
      totalItems: 0,
      items: [],

      loading: false,
      search: null,
      is_active: null,

      timer: null,
      cancelSource: null,

      headers: [
      {
          text: 'ID',
          align: 'left',
          value: 'id',
        },
        {
          text: '# Shipments',
          align: 'left',
          value: 'total_shipments',
          sortable: false
        },
        {
          text: 'Client',
          align: 'left',
          value: 'client',
          sortable: false
        },
        {
          text: 'Total Earned',
          align: 'left',
          value: 'total_earned',
          sortable: false
        },
        {
          text: 'Amount',
          align: 'left',
          value: 'amount',
          sortable: true
        },
        {
          text: 'Start Date',
          align: 'left',
          value: 'start_date',
          sortable: true
        },
        {
          text: 'End Date',
          align: 'left',
          value: 'end_date',
          sortable: true
        }
      ],

      options: {
        sortBy: ['created_at'],
        sortDesc: [true]
      },
    }
  },

  watch: {
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    }
  },
  mounted() {
    this.getDataFromApi()
  },
  
  methods:{
    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.options.page = 1;
      this.timer = setTimeout(function () {
        self.updateTable();
      }, ms);
    },

    updateTable() {
      this.totalItems = 0;
      this.items = [];

      this.getDataFromApi().then((data) => {
        this.items = data.items;
        this.totalItems = data.total;
      });
    },

    getDataFromApi() {
      this.loading = true;
      return new Promise((resolve) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let items = [];
        let total = 0;
        let pages = 0;

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        var params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sortBy[0],
          desc: sortDesc[0],
          user_id: this.user?.id,
          search: this.search,
          is_active: this.is_active
        };

        this.$http
          .request(`/commission-structures`, {
            method: 'get',
            params: params,
            cancelToken: this.cancelSource.token
          })

          .then((response) => response.data)
          .then((response) => {
            items = response.data;
            total = response.total;
            pages = response.last_page;

            this.loading = false;
            resolve({
              items,
              total,
              pages
            });
          });
      });
    }
  }
};
</script>
