<template>
  <SidePanelLayout title="Orders">
    <template #side-panel>
      <order-details :orders="selected" />
      <!-- <view-order-details style="font-size: 14px" :selected="main.selectedselected"></view-order-details> -->
    </template>

    <template #page-links>
      <v-col class="text-right">
        <order-links />
      </v-col>
    </template>

    <template #footer>
      <v-btn
        color="primary"
        :disabled="!canCreateLabelsFromOrders(selected) || !can(PERMISSIONS.SHIPPING)"
        @click="openCreateLabelsDialogFromOrders"
      >
        Process
      </v-btn>

      <v-btn
        color="primary"
        :disabled="!canEditBatch || !can(PERMISSIONS.SHIPPING)"
        @click="show_batch = true"
      >
        <v-icon small>
          mdi-plus
        </v-icon> Batch
      </v-btn>

      <!--isPrimaryAccountHolder from mixin.js-->
      <v-menu offset-y>
        <template #activator="{ on, attrs }">
          <v-btn
            color="primary"
            v-bind="attrs"
            :disabled="selected.length === 0"
            v-on="on"
          >
            Print
            <v-icon right>
              mdi-chevron-up
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            :disabled="selected.length === 0"
            @click="printSelected(PRINTER.DOCUMENT_FORMATS.PACKING_SLIP)"
          >
            <v-list-item-title>Packing Slip</v-list-item-title>
          </v-list-item>

          <v-list-item
            :disabled="selected.length === 0"
            @click="printSelected(PRINTER.DOCUMENT_FORMATS.PICK_LIST)"
          >
            <v-list-item-title>Pick List</v-list-item-title>
          </v-list-item>


          <v-list-item
            :disabled="selected.length === 0"
            @click="printSelected(PRINTER.DOCUMENT_FORMATS.ORDER_SUMMARY)"
          >
            <v-list-item-title>Order Summary</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>


      <v-menu offset-y>
        <template #activator="{ on, attrs }">
          <v-btn
            color="primary"
            v-bind="attrs"
            :disabled="selected.length === 0"
            v-on="on"
          >
            Other
            <v-icon right>
              mdi-chevron-up
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            :disabled="selected.length === 0 || !can(PERMISSIONS.ORDERS) || selected.length > 1"
            @click="show_split = true"
          >
            <v-list-item-icon>
              <v-icon>alt_route</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Split</v-list-item-title>
          </v-list-item>

          <v-list-item
            :disabled="selected.length === 0 || !can(PERMISSIONS.ORDERS)"
            @click="show_duplicate = true"
          >
            <v-list-item-icon>
              <v-icon>mdi-content-copy</v-icon>
            </v-list-item-icon>
            <v-list-item-title> Duplicate</v-list-item-title>
          </v-list-item>

          <v-list-item
            :disabled="selected.length === 0"
            @click="$refs.deleteOrders.startNew()"
          >
            <v-list-item-icon>
              <v-icon>mdi-archive-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title> Archive</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>

    <template #default>
      <orders-table
        ref="ordertable"
        v-model="selected"
        :filter="filter"
        @filter-changed="updateFilter"
      >
        <template #top-buttons>
          <v-menu v-if="filter === 'On Hold' || filter === 'Awaiting Shipment'">
            <template #activator="{ on, attrs }">
              <!-- Hold/Unhold Buttons -->
              <v-btn
                v-show="filter === 'Awaiting Shipment'"
                color="primary"
                :disabled="selected.length === 0 || selected[0].status !== 'Awaiting Shipment' || update_loading"
                v-bind="attrs"
                :loading="update_loading"
                small
                
                v-on="on"
                @click="updateStatus('On Hold')"
              >
                Hold
              </v-btn>

              <v-btn
                v-show="filter === 'On Hold'"
                color="primary"
                :disabled="selected.length === 0 || selected[0].status !== 'On Hold' || update_loading"
                v-bind="attrs"
                :loading="update_loading"
                small
                
                v-on="on"
                @click="updateStatus('Awaiting Shipment')"
              >
                Remove Hold
              </v-btn>
            </template>
          </v-menu>
        </template>
      </orders-table>
      <print-packing-slips
        ref="printPackingSlipRef"
        v-model="show_print"
        :items="selected"
        :type="'orders'"
        @update="emitUpdate()"
      />

      <print-product-pick-list
        ref="printPickListRef"
        v-model="show_product_pick_list"
        :items="selected"
        :type="'orders'"
        @update="emitUpdate()"
      />

      <print-order-summary
        ref="printOrderSummaryRef"
        v-model="show_order_summary"
        :items="selected"
        :type="'orders'"
        @update="emitUpdate()"
      />

      <delete-orders
        ref="deleteOrders"
        :orders="selected"
        :disabled="selected.length === 0"
      />

      <duplicate-orders
        v-model="show_duplicate"
        :orders="selected"
        @update="$refs.ordertable.updateTable()"
      />

      <split-order
        v-model="show_split"
        :order="selected[0]"
        @update="$refs.ordertable.updateTable()"
      />

      <create-labels
        ref="refCreateLabels"
        v-model="show_create_labels"
        @update="$refs.ordertable.updateTable()"
      />

      <batch-add
        v-model="show_batch"
        :orders="selected"
        type="order"
        @update="$refs.ordertable.updateTable()"
      />
    </template>
  </SidePanelLayout>
</template>

<script>
import { mapState } from 'vuex';
import CreateLabels from '../../../components/main/shipments/action-bar/CreateLabels/MainDialog.vue';
import OrdersTable from './OrdersTable.vue';
import OrderDetails from './details/OrderDetails.vue';
import DeleteOrders from './DeleteOrders.vue';
import PrintPackingSlips from '@/components/main/shipments/action-bar/PrintPackingSlips.vue'
import PrintProductPickList from '@/components/main/shipments/action-bar/PrintProductPickList.vue'
import PrintOrderSummary from '@/components/main/shipments/action-bar/PrintOrderSummary.vue'
import PERMISSIONS from '@/constants/client-permissions'
import DuplicateOrders from '@/components/main/orders/DuplicateOrders.vue'
import SplitOrder from '@/components/main/orders/SplitOrder.vue'
import PRINTER from "@/constants/printer";
import BatchAdd from '@/components/main/shipments/action-bar/BatchAdd.vue'

export default {
  metaInfo: {
    title: "Orders | Stallion Express"
  },
  components: {
    OrdersTable,
    DeleteOrders,
    OrderDetails,
    PrintPackingSlips,
    PrintProductPickList,
    PrintOrderSummary,
    DuplicateOrders,
    SplitOrder,
    CreateLabels,
    BatchAdd
  },
  data() {
    return {
      show: false,
      filter: 'Awaiting Shipment',
      update_loading: false,
      selected: [],
      show_print: false,
      show_duplicate: false,
      show_split: false,
      show_product_pick_list: false,
      show_order_summary: false,
      show_create_labels: false,
      PERMISSIONS: PERMISSIONS,
      show_batch: false
    };
  },
  computed: {
    ...mapState(['main']),
    filter_formated() {
      return this.$options.filters.capitalize(this.filter);
    },
    PRINTER() {
      return PRINTER
    },
    canEditBatch() {
      return this.selected.length > 0 && this.can(PERMISSIONS.SHIPPING);
    }
  },

  methods: {
    updateFilter(filter) {
      this.filter = filter;
    },
    openCreateLabelsDialogFromOrders() {
      this.$refs.refCreateLabels.openFromOrders(this.selected);
    },
    printSelected(type) {
      const typeConfig = {
        [PRINTER.DOCUMENT_FORMATS.PICK_LIST]: {
          ref: 'printPickListRef',
          setDataMethod: 'setPrintingData',
          getMethod: 'getPickList',
          showPromptProperty: 'show_product_pick_list',
        },
        [PRINTER.DOCUMENT_FORMATS.PACKING_SLIP]: {
          ref: 'printPackingSlipRef',
          setDataMethod: 'setPackingSlipData',
          getMethod: 'getPackingSlips',
          showPromptProperty: 'show_print',
        },
        [PRINTER.DOCUMENT_FORMATS.ORDER_SUMMARY]: {
          ref: 'printOrderSummaryRef',
          setDataMethod: 'setOrderSumamryData',
          getMethod: 'getOrderSummary',
          showPromptProperty: 'show_order_summary',
        },
      };

      const config = typeConfig[type];
      if (!config) return;

      if (this.$store.state.qz.qzStatus) {
        this.$store.dispatch('qz/updateCurrentDocType', type);
        const settings = this.getPrinterSettingData(false, type);
        if (config.setDataMethod && settings) {
          this.$refs[config.ref][config.setDataMethod](settings);
        }

        const printAction = this.getPrintTo(type);

        if (printAction === PRINTER.PRINT_ACTION.PROMPT) {
          this[config.showPromptProperty] = true;
        } else if (printAction === PRINTER.PRINT_ACTION.DOWNLOAD) {
          this.$refs[config.ref][config.getMethod]('download');
        } else {
          this.$refs[config.ref][config.getMethod]('print');
        }
      } else {
        this[config.showPromptProperty] = true;
      }
    },
    updateStatus(status) {
      this.update_loading = true;

      const ids = this.selected.map(order => order.id);

      this.$http
        .post('orders/update-status', {
          ids: ids,
          status
        })
        .then((response) => {
          if (this.$refs.ordertable) {
            this.$refs.ordertable.updateTable(); // Replace this with the actual method to refresh the table
          } // Emit an update event after success

          if (response.data.success === false) {
            this.errorMessage(response.data.message || 'Order status update failed');
          } else {
            this.successMessage(response.data.message || 'Order status updated successfully');
          }
        })
        .finally(() => {
          this.update_loading = false;
          this.show = false;
        });
    }
  }
};
</script>
